import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';

export enum ActionNames {
    GET_ALL_THERAPIES = '[Therapy] Get all Therapies',
    GET_ALL_THERAPIES_UPDATE_SUCCESS = '[Therapy] Get all Therapies Update Success',
    GET_ALL_THERAPIES_OVERRIDE_SUCCESS = '[Therapy] Get all Therapies Override Success',
    GET_ALL_THERAPIES_FAILED = '[Therapy] Get all Therapies Failed',
    SET_SEARCH_QUERY = '[Therapy] Set Search Query Therapies',

    INCREASE_PAGE_NUMBER = '[Therapy] Increase Page Number',
}

export const Actions = {
    getAllTherapies: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_THERAPIES, data),
    getAllTherapiesUpdateSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_THERAPIES_UPDATE_SUCCESS, data),
    getAllTherapiesOverrideSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_THERAPIES_OVERRIDE_SUCCESS, data),
    getAllTherapiesFailed: () => createAction(ActionNames.GET_ALL_THERAPIES_FAILED),
    setSearchQuery: (query: string) => createAction(ActionNames.SET_SEARCH_QUERY, query),

    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllTherapiesType = ActionsOfType<Actions, ActionNames.GET_ALL_THERAPIES>;
