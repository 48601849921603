import { put, call, takeEvery } from 'redux-saga/effects';
import * as NotificationsActions from '../../actions/notifications.actions';
import NotificationApi from '../../../api/notification/NotificationApi';

function* markAsRead() {
    try {
        yield call(NotificationApi.markAsRead);
        yield put({ type: NotificationsActions.NotificationsActionNames.MARK_AS_READ_SUCCESSFULL });
    } catch (e) {
        yield put({ type: NotificationsActions.NotificationsActionNames.MARK_AS_READ_FAILED });
    }
}

function* getNotificationsByPatient(action: NotificationsActions.getNotificationsByPatientType) {
    try {
        const response = yield call(NotificationApi.getPatientNotifications, action.payload);
        if (action.payload.data.overrideRecords) {
            yield put({
                type: NotificationsActions.NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_OVERRIDE_SUCCESS,
                payload: response,
            });
        } else {
            yield put({
                type: NotificationsActions.NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_UPDATE_SUCCESS,
                payload: response,
            });
        }
    } catch (e) {
        yield put({ type: NotificationsActions.NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_FAILED });
    }
}

function* markOneAsRead(action: NotificationsActions.markOneAsReadType) {
    yield call(NotificationApi.markOneAsRead, action.payload);
}

export default function* LabSagas() {
    yield takeEvery(NotificationsActions.NotificationsActionNames.MARK_AS_READ, markAsRead);
    yield takeEvery(NotificationsActions.NotificationsActionNames.MARK_ONE_AS_READ, markOneAsRead);
    yield takeEvery(
        NotificationsActions.NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT,
        getNotificationsByPatient
    );
}
