import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import TextInput from '../../../shared/input/TextInput';
import { PatientModel } from '../../../models/responses/patient.response';
import moment from 'moment';
import { toTitleCase } from '../../../helpers/stringHelpers';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { PatientStepClassName } from '../../tour/constants/patientStepsClassNames';
import { UserModel } from '../../../models/responses/user.response';
import { RoleOptions } from '../../../constants/roleOptions';
import { withRouter, RouteComponentProps } from 'react-router';

const Wrapper = styled.div`
    padding: 20px 0 40px;
`;

const InputWrapper = styled.div``;

const FormWrapper = styled.div`
    & > div {
        margin-bottom: 12px !important;
    }
    & > div:last-child {
        margin-bottom: 0 !important;
    }
`;

interface InjectedProps {
    patient: PatientModel | null;
}

interface StoreModel {
    isTourVisible: boolean;
    user: UserModel | null;
}

const createStyles = makeStyles({
    label: {
        display: 'block',
        fontSize: '10px',
        color: colors.gray1,
        marginLeft: '12px',
        marginTop: '3px',
    },
});

const mockPatient: PatientModel = {
    firstName: 'John',
    lastName: 'Smith',
    email: 'j.smith@mail.com',
    id: 0,
    avatarThumbnailUrl: '',
    avatarUrl: '',
    birthDate: '',
    city: '',
    gender: '',
    phone: '',
    state: '',
    streetAddress1: '',
    streetAddress2: '',
    zip: '',
    linkingStatus: null,
};

const ProfileInfoPatientView: React.FC<InjectedProps & StoreModel & RouteComponentProps<any>> = props => {
    const classes = createStyles(props);
    const { patient, isTourVisible, user, history } = props;

    const patientInfo = useMemo(() => {
        if (isTourVisible) {
            return mockPatient;
        } else {
            return patient;
        }
    }, [isTourVisible, patient]);

    useEffect(() => {
        if (user && user.role === RoleOptions.PATIENT && !isTourVisible) {
            history.push('/');
        }
    }, [user, isTourVisible, history]);

    return (
        <SizeControllerWrapper>
            <Wrapper>
                <FormWrapper>
                    {isTourVisible && (
                        <div className={PatientStepClassName.PROFILE}>
                            <Typography variant={'button'}>
                                {patientInfo!.firstName} {patientInfo!.lastName}
                            </Typography>
                        </div>
                    )}
                    {patientInfo && (
                        <>
                            <InputWrapper>
                                <TextInput
                                    placeholder={''}
                                    value={patientInfo.gender ? toTitleCase(patientInfo.gender) : 'Empty'}
                                    color={patientInfo.gender ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    Gender
                                </Typography>
                            </InputWrapper>

                            <InputWrapper>
                                <TextInput
                                    placeholder={''}
                                    value={patientInfo.phone ? patientInfo.phone : 'Empty'}
                                    color={patientInfo.phone ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    Phone
                                </Typography>
                            </InputWrapper>

                            <InputWrapper>
                                <TextInput
                                    placeholder={''}
                                    value={
                                        patientInfo.birthDate
                                            ? moment(patientInfo.birthDate).format('MM.DD.YYYY')
                                            : 'Empty'
                                    }
                                    color={patientInfo.birthDate ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    MM/DD/YYYY
                                </Typography>
                            </InputWrapper>

                            <InputWrapper>
                                <TextInput
                                    placeholder={'Address 1'}
                                    value={patientInfo.streetAddress1 ? patientInfo.streetAddress1 : 'Empty'}
                                    color={patientInfo.streetAddress1 ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    Address 1
                                </Typography>
                            </InputWrapper>

                            <InputWrapper>
                                <TextInput
                                    placeholder={'Address 2'}
                                    value={patientInfo.streetAddress2 ? patientInfo.streetAddress2 : 'Empty'}
                                    color={patientInfo.streetAddress2 ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    Address 2
                                </Typography>
                            </InputWrapper>

                            <InputWrapper>
                                <TextInput
                                    placeholder={'City'}
                                    value={patientInfo.city ? patientInfo.city : 'Empty'}
                                    color={patientInfo.city ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    City
                                </Typography>
                            </InputWrapper>

                            <InputWrapper>
                                <TextInput
                                    placeholder={'State'}
                                    value={patientInfo.state ? patientInfo.state : 'Empty'}
                                    color={patientInfo.state ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    State
                                </Typography>
                            </InputWrapper>

                            <InputWrapper>
                                <TextInput
                                    placeholder={'Zip'}
                                    value={patientInfo.zip ? patientInfo.zip : 'Empty'}
                                    color={patientInfo.zip ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    Zip
                                </Typography>
                            </InputWrapper>
                            <InputWrapper>
                                <TextInput
                                    placeholder={'Email'}
                                    value={patientInfo.email}
                                    color={patientInfo.email ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    Email
                                </Typography>
                            </InputWrapper>
                        </>
                    )}
                </FormWrapper>
            </Wrapper>
        </SizeControllerWrapper>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        isTourVisible: store.tour.isTourVisible,
        user: store.authorization.user,
    }),
    () => ({})
)(withRouter(ProfileInfoPatientView));
