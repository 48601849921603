import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CardWithBigShadow } from '../../../shared/card/CardWithBigShadow';
import { makeStyles, Typography } from '@material-ui/core';
import { IMeasurementSystemConfig } from '../dtos/unitConfig.interface';
import { Form, InputType, WeightManagementFormType } from '../ui/formBuilder/form';
import { MeasurementSystem, UserModel } from '../../../models/responses/user.response';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions } from '../../../store/actions/patient.actions';
import * as fromRoot from '../../../store/reducers';
import RequestState from '../../../constants/requestState';
import LoadingTransparent from '../../shared/LoadingTransparent';
import WeightManagementSettingsSnackbar from './WeightManagementSettingsSnackbar';
import { Actions as AuthActions } from '../../../store/actions/auth.actions';

const createStyles = makeStyles({
    heading: {
        fontSize: '21px',
        display: 'block',
        textAlign: 'center',
    },
});

interface DispatchModel {
    setupMeasurementSystem: typeof Actions.setupMeasurementSystem;
    resetStatusVariables: typeof Actions.resetStatusVariables;
    getCurrentUser: typeof AuthActions.getCurrentUser;
}

interface StoreModel {
    loadingSetupMeasurementSystem: RequestState;
    user: UserModel | null;
}

type PropsTypes = DispatchModel & StoreModel;

const WeightManagementSettings: React.FC<PropsTypes> = props => {
    const { setupMeasurementSystem, loadingSetupMeasurementSystem, resetStatusVariables, user, getCurrentUser } = props;
    const classes = createStyles(props);
    const [data, setData] = useState<IMeasurementSystemConfig>({
        measurementSystem: MeasurementSystem.IMPERIAL,
    });

    useEffect(() => {
        if (user) {
            setData({ measurementSystem: user.measurementSystem });
        }
    }, [user]);

    const submit = useCallback(() => {
        if (user && user.measurementSystem !== data.measurementSystem) {
            setupMeasurementSystem(data);
        }
    }, [user, data, setupMeasurementSystem]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    const isLoading = useMemo(() => {
        return !user || loadingSetupMeasurementSystem === RequestState.SENDING;
    }, [user, loadingSetupMeasurementSystem]);

    useEffect(() => {
        if (loadingSetupMeasurementSystem === RequestState.SENT_SUCCESS) {
            getCurrentUser();
        }
    }, [loadingSetupMeasurementSystem, getCurrentUser]);

    return (
        <>
            {isLoading && <LoadingTransparent />}
            {user && (
                <CardWithBigShadow styles={{ width: '85%', marginTop: '36px' }}>
                    <Typography variant={'button'} className={classes.heading}>
                        Weight Management
                    </Typography>
                    <Form<IMeasurementSystemConfig>
                        data={data}
                        type={WeightManagementFormType.LARGE}
                        fields={{
                            measurementSystem: {
                                label: 'Measurement System',
                                index: 0,
                                fieldType: InputType.RADIO,

                                radioButtonValues: [
                                    {
                                        label: 'Imperial',
                                        value: MeasurementSystem.IMPERIAL,
                                    },
                                    {
                                        label: 'Metric',
                                        value: MeasurementSystem.METRIC,
                                    },
                                ],
                            },
                        }}
                        onChange={data => {
                            setData(data);
                        }}
                        onSubmit={submit}
                    />
                </CardWithBigShadow>
            )}
            <WeightManagementSettingsSnackbar />
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        loadingSetupMeasurementSystem: store.patient.loadingSetupMeasurementSystem,
        user: store.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setupMeasurementSystem: bindActionCreators(Actions.setupMeasurementSystem, dispatch),
        resetStatusVariables: bindActionCreators(Actions.resetStatusVariables, dispatch),
        getCurrentUser: bindActionCreators(AuthActions.getCurrentUser, dispatch),
    })
)(WeightManagementSettings);
