import React, { useCallback, useMemo } from 'react';
import { Tab, Tabs, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme/theme';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import Badge from './Badge';
import { UserModel } from '../../../models/responses/user.response';
import { RoleOptions } from '../../../constants/roleOptions';
import { withRouter, RouteComponentProps } from 'react-router';
import { getPaymentRouting } from '../../../constants/tabRouting/getPaymentRouting';
import { PatientStepClassName } from '../../tour/constants/patientStepsClassNames';

const createStyles = makeStyles({
    headerIcon: {
        marginRight: '8px',
        marginBottom: '-3px',
    },
    fab: {
        position: 'fixed',
        right: '15px',
        bottom: '3.5%',
        backgroundColor: colors.redMain,
        boxShadow: theme.boxShadowRed,
        '&:hover': {
            backgroundColor: colors.redMain,
        },
        '&:active': {
            backgroundColor: colors.redMain,
            boxShadow: theme.boxShadowRed,
        },
    },
    tabs: {
        height: '42px !important',
        minHeight: '42px !important',
        paddingTop: '4px',
        '& div': {
            justifyContent: 'space-around',
        },
    },
    tab: {
        height: '30px',
        minWidth: '10px',
        padding: '6px 24px !important',
        opacity: '1 !important',
    },
    selectedTab: {
        '& span': {
            color: colors.textPrimary,
        },
    },
    indicator: {
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: '100%',
            left: 'calc(50% - 4px)',
            borderBottom: `3px solid ${colors.redMain}`,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            width: 0,
            height: 0,
        },
    },
    tabText: {
        fontSize: '12px !important',
        color: colors.textSecondary,
    },
});

const TabsWrapper = styled.div`
    background-color: ${colors.gray2};
    height: 60px;
`;

const TabWrapper = styled.div``;

interface InjectedProps {
    activeTab: number;
    setActiveTab: (activeTab: number) => void;
    oneTime: number;
    subscription: number;
    user: UserModel | null;
}

type PropsTypes = InjectedProps & RouteComponentProps<any>;

const PaymentTabs: React.FC<PropsTypes> = props => {
    const { activeTab, setActiveTab, oneTime, subscription, user } = props;

    const classes = createStyles(props);

    const paymentRouting = useMemo(() => {
        if (user) {
            return getPaymentRouting(user);
        } else return null;
    }, [user]);

    const getLocationValue = useCallback(
        (value: number) => {
            if (paymentRouting) {
                switch (value) {
                    case paymentRouting[0].tab: {
                        return paymentRouting[0].path;
                    }
                    case paymentRouting[1].tab: {
                        return paymentRouting[1].path;
                    }
                    case paymentRouting[2].tab: {
                        return paymentRouting[2].path;
                    }
                    default:
                        return paymentRouting[0].path;
                }
            } else return '';
        },
        [paymentRouting]
    );

    return (
        <TabsWrapper>
            <SizeControllerWrapper>
                <Tabs
                    value={activeTab}
                    onChange={(evt, newValue: number) => {
                        setActiveTab(newValue);
                        props.history.push(getLocationValue(newValue));
                    }}
                    indicatorColor="primary"
                    classes={{ root: classes.tabs, indicator: classes.indicator }}
                >
                    <Tab
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        label={
                            <TabWrapper>
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    One - time
                                </Typography>
                                {!!oneTime && <Badge>{oneTime}</Badge>}
                            </TabWrapper>
                        }
                    />
                    <Tab
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        label={
                            <TabWrapper>
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Subscriptions
                                </Typography>
                                {!!subscription && <Badge>{subscription}</Badge>}
                            </TabWrapper>
                        }
                    />
                    {user && user.role === RoleOptions.PROVIDER && (
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <TabWrapper>
                                    <Typography variant="button" classes={{ root: classes.tabText }}>
                                        Account
                                    </Typography>
                                </TabWrapper>
                            }
                        />
                    )}
                    {user && user.role === RoleOptions.PATIENT && (
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <TabWrapper>
                                    <div className={PatientStepClassName.PAYMENT}>
                                        <Typography variant="button" classes={{ root: classes.tabText }}>
                                            Settings
                                        </Typography>
                                    </div>
                                </TabWrapper>
                            }
                        />
                    )}
                </Tabs>
            </SizeControllerWrapper>
        </TabsWrapper>
    );
};

export default withRouter(PaymentTabs);
