import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Fade, Slide} from "@material-ui/core";
import drawerBackground from '../../static/images/landing/main/halfLogoBackground.svg';
import {colors} from '../../theme/colors';
import SidebarMenuUnAuth from "./SidebarMenuUnAuth";
import {UserModel} from "../../models/responses/user.response";
import SidebarMenuAuth from "./SidebarMenuAuth";

const Wrapper = styled.div`
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Drawer = styled.div<any>`
  width: 80%;
  height: 100%;
  position: absolute;
  overflow: auto;
  z-index: 2;
  background-color: white;
  background-image: url(${drawerBackground});
  background-repeat: no-repeat;
  background-position: ${props => props.isUser ? 'right 168px' : 'right 45px'};
`;

const DrawerWrapper = styled.div<any>`
  width: 100vw;
  height: ${props => props.visible ? 'calc(100vh - 80px)' : 0};
  position: absolute;
  overflow: hidden;
  z-index: 1;
  background-color: ${colors.drawerBackground};
`;

interface InjectedProps {
    removeCurrentUser: () => void,
    resetStatusVariables: () => void,
    user?: UserModel | null;
    open: boolean;
    closeSidebar: () => void;
    goToPage: (path: string) => void;
}

type PropsTypes =
    & InjectedProps;

const SidebarMenuMobile: React.FC<PropsTypes> = (props) => {

    const node = useRef<HTMLDivElement>();
    const drawer = useRef<HTMLDivElement>();

    useEffect(() => {
        if (props.open) {
            document.addEventListener("mousedown", handleClickOutsideDrawer);
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDrawer);
            document.body.style.overflow = 'scroll';
        };
    });

    const handleClickOutsideDrawer = (e: any) => {
        if (node.current && node.current.contains(e.target)) {
            if (drawer.current && drawer.current.contains(e.target)) {
                return;
            }
            props.closeSidebar();
        }
    };

    return (
        <Fade in={props.open}>
            <DrawerWrapper visible={props.open} ref={node}>
                <Slide direction="right" in={props.open} mountOnEnter unmountOnExit>
                    <Drawer ref={drawer} isUser={!!props.user}>
                        <Wrapper>
                            {props.user && props.user.id ?
                                <SidebarMenuAuth
                                    goToPage={props.goToPage}
                                    closeSidebar={props.closeSidebar}
                                    removeCurrentUser={props.removeCurrentUser}
                                    resetStatusVariables={props.resetStatusVariables}
                                    user={props.user}
                                /> :
                                <SidebarMenuUnAuth
                                    goToPage={props.goToPage}
                                />
                            }
                        </Wrapper>
                    </Drawer>
                </Slide>
            </DrawerWrapper>
        </Fade>
    )
};

export default SidebarMenuMobile;