import React, { useCallback } from 'react';
import { Avatar, Button, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import forwardIcon from '../../../static/icons/forwardIcon.svg';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';

const useStyles = makeStyles({
    listItem: {
        padding: 0,
    },
    nameText: {
        fontSize: '14px',
        color: colors.textPrimary,
    },
    typeText: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'capitalize',
    },
    avatar: {
        border: `1px solid ${colors.gray3}`,
        backgroundColor: colors.gray3,
        width: '38px',
        height: '38px',
    },
    avatarWrapper: {
        minWidth: 'auto',
        marginRight: '10px',
    },
    forwardIcon: {
        padding: '0 !important',
        minWidth: '25px',
        height: '25px',
    },
    myProviderText: {
        fontSize: '14px',
        color: colors.gray1,
        marginLeft: '4px',
    },
});

const TextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: -6px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface InjectedProps {
    lab: BaseDropdownItem;
    redirectToLab? : (id: number) => void;
}

type PropsTypes = InjectedProps;

const LabListItem: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);
    const {redirectToLab, lab} = props;

    const SecondaryTypographyComponent = useCallback(
        () => (
            <Typography variant="body1" classes={{ root: classes.typeText }}>
                Address 1
            </Typography>
        ),
        [classes.typeText]
    );

    const PrimaryTextComponent = useCallback(
        () => (
            <TextWrapper>
                <Typography variant="button" classes={{ root: classes.nameText }}>
                    {props.lab.name + ' '}
                </Typography>
            </TextWrapper>
        ),
        [classes.nameText, props.lab.name]
    );

    const redirect = useCallback(()=>{
        if(redirectToLab && lab.id) {
            redirectToLab(lab.id)
        }
    }, [lab, redirectToLab]);

    return (
        <ListItem classes={{ root: classes.listItem }} button onClick={redirect}>
            <SizeControllerWrapper>
                <Wrapper>
                    <ListItemAvatar classes={{ root: classes.avatarWrapper }}>
                        <Avatar classes={{ root: classes.avatar }}>
                            <img src={''} alt="" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={PrimaryTextComponent()} secondary={SecondaryTypographyComponent()} />
                    <Button classes={{ root: classes.forwardIcon }}>
                        <img src={forwardIcon} alt="" />
                    </Button>
                </Wrapper>
            </SizeControllerWrapper>
        </ListItem>
    );
};

export default LabListItem;
