import { WeightManagementActions } from '../../actions/weightManagement.actions';
import { WeightRecordResponse } from '../../../models/responses/weightRecord.response';

export interface IPagingData<T = any> {
    result: Array<T>;
    total: number;
}

export enum WeightManagementLoadingState {
    IDLE = 'IDLE',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    LOADING = 'LOADING',
    FETCHING_LIST_SUCCESS = 'FETCHING_LIST_SUCCESS'
}

export class WeightStateModel {
    weightList: WeightRecordResponse[] = [];
    pagingData: IPagingData<WeightRecordResponse> = {
        result: [],
        total: 0,
    };
    loadingState: WeightManagementLoadingState = WeightManagementLoadingState.IDLE;
}

const initialState = new WeightStateModel();

export default function WeightManagementReducer(
    state: WeightStateModel = initialState,
    action: { type: WeightManagementActions, data: any },
): WeightStateModel {
    switch (action.type) {
        case WeightManagementActions.FETCH_ALL_WEIGHT_RECORDS: {
            const data = action.data as IPagingData<WeightRecordResponse>;
            return {
                ...state,
                weightList: data.result,
                pagingData: data,
                loadingState: WeightManagementLoadingState.FETCHING_LIST_SUCCESS
            }
        }
        case WeightManagementActions.DELETE_WEIGHT_RECORD:
            return {
                ...state,
                weightList: state.weightList.filter(record => record.id !== action.data)
            }
        case WeightManagementActions.EDIT_WEIGHT_RECORD:
            return {
                ...state,
                weightList: state.weightList.map(record => record.id === action.data.id ? action.data : record),
            }
        case WeightManagementActions.CHANGE_LOADING_STATE:
            return {
                ...state,
                loadingState: action.data
            }
        case WeightManagementActions.FETCH_WEIGHT_RECORD_BY_ID: {
            const found = state.weightList.find(record => record.id);
            let weightRecordsList: WeightRecordResponse[] = [];
            if (found) {
                weightRecordsList = state.weightList.map(record => record.id === action.data.id ? action.data : record);
            } else {
                weightRecordsList = [
                    ...state.weightList,
                    action.data,
                ];
            }
            return {
                ...state,
                weightList: weightRecordsList,
            };
        }
        case WeightManagementActions.CREATE_WEIGHT_RECORD:
            return {
                ...state,
                weightList: [
                    ...state.weightList,
                    action.data
                ]
            }
        default:
            return state;
    }
}
