import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CardElementStyle } from '../../../constants/invoice/cardElementStyle';
import Snackbar, { SnackbarTypes } from '../../../shared/snackbars/snackbar';
import CancelLink from '../../../shared/link/CancelLink';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { connect } from 'react-redux';
import * as cardManagementActions from '../../../store/actions/cardManagement.actions';
import * as fromRoot from '../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../../shared/Loading';
import RequestState from '../../../constants/requestState';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';

const Wrapper = styled.div`
    padding: 32px 0;
    width: 90%;
    margin: 0 auto;
`;

const BtnGroup = styled.div`
    display: flex;
    margin-top: 8px;
    align-items: baseline;

    & > div {
        margin-left: 16px;
    }
`;

const createStyles = makeStyles({
    info: {
        margin: '32px 0 -8px',
        color: colors.textSecondary,
        fontSize: '12px',
    },
});

interface InjectedProps {
    onClose: () => void;
    onAddCardAndImmediatePayment?: (paymentMethodId: string) => void;
}

interface StoreModel {
    clientSecret: string;
    clientSecretLoading: RequestState;
}

interface DispatchModel {
    getClientSecret: typeof cardManagementActions.Actions.getClientSecret;
    resetStatusVariables: typeof cardManagementActions.Actions.resetStatusVariables;
    setAddCardLoadingState: typeof cardManagementActions.Actions.setAddCardLoadingState;
}

type PropsTypes = InjectedProps & StoreModel & DispatchModel;

const AddCard: React.FC<PropsTypes> = props => {
    const {
        onClose,
        getClientSecret,
        clientSecret,
        clientSecretLoading,
        resetStatusVariables,
        setAddCardLoadingState,
        onAddCardAndImmediatePayment,
    } = props;
    const classes = createStyles(props);
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [stripeProcessing, setStripeProcessing] = useState(false);

    const isLoading = useMemo(() => {
        return stripeProcessing || clientSecretLoading === RequestState.SENDING;
    }, [clientSecretLoading, stripeProcessing]);

    const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null);

    const prepareForCardSaving = useCallback(() => {
        setAddCardLoadingState(RequestState.SENDING);
        getClientSecret();
    }, [getClientSecret, setAddCardLoadingState]);

    const saveCard = useCallback(
        async (clientSecret: string) => {
            if (!stripe || !elements) {
                console.log('not stripe or elements');
                return;
            }
            setStripeProcessing(true);
            const card = elements.getElement(CardElement);
            if (!card) {
                throw new Error('Confirm card setup - card is null');
            }

            const result = await stripe.confirmCardSetup(clientSecret!, {
                payment_method: { card },
            });

            if (result.error) {
                setError(result.error.message ? result.error.message : 'Unknown Error');
                setStripeProcessing(false);
                setAddCardLoadingState(RequestState.SENT_FAILED);
            } else {
                setSuccess(true);
                setStripeProcessing(false);
                setAddCardLoadingState(RequestState.SENT_SUCCESS);
                if (onAddCardAndImmediatePayment && result && result.setupIntent && result.setupIntent.payment_method) {
                    onAddCardAndImmediatePayment(result.setupIntent.payment_method);
                }
                onClose();
            }
        },
        [onClose, stripe, elements, setAddCardLoadingState, onAddCardAndImmediatePayment]
    );

    useEffect(() => {
        if (clientSecret) {
            saveCard(clientSecret);
        }
    }, [clientSecret, saveCard]);

    useEffect(() => {
        if (clientSecretLoading === RequestState.SENT_FAILED || clientSecretLoading === RequestState.SENT_SUCCESS) {
            resetStatusVariables();
        }
    }, [clientSecretLoading, resetStatusVariables]);

    useEffect(() => {
        if (success) {
            timerRef.current = setTimeout(() => {
                setSuccess(false);
            }, 5000);
        }
    }, [success]);

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <Wrapper>
            <CardElement options={CardElementStyle} />

            {isLoading ? (
                <Loading smallSize />
            ) : (
                <>
                    {onAddCardAndImmediatePayment && (
                        <Typography variant={'body2'} classes={{ root: classes.info }}>
                            The purchase will be with proceeded with this card automatically.
                        </Typography>
                    )}
                    <BtnGroup>
                        <PrimaryButton onClick={prepareForCardSaving} size={'small'}>
                            Save
                        </PrimaryButton>
                        <CancelLink onClick={onClose} />
                    </BtnGroup>
                </>
            )}

            {error && (
                <Snackbar
                    type={SnackbarTypes.ERROR}
                    message={error}
                    open
                    variant="success"
                    onClose={() => {
                        setError(null);
                    }}
                />
            )}

            {success && (
                <Snackbar
                    type={SnackbarTypes.SUCCESS}
                    message={'A new card was created'}
                    open
                    variant="success"
                    onClose={() => {
                        setSuccess(false);
                    }}
                />
            )}
        </Wrapper>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        clientSecret: store.cardManagement.clientSecret,
        clientSecretLoading: store.cardManagement.getClientSecretLoading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getClientSecret: bindActionCreators(cardManagementActions.Actions.getClientSecret, dispatch),
        resetStatusVariables: bindActionCreators(cardManagementActions.Actions.resetStatusVariables, dispatch),
        setAddCardLoadingState: bindActionCreators(cardManagementActions.Actions.setAddCardLoadingState, dispatch),
    })
)(AddCard);
