import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import CustomLink from '../link/CustomLink';
import { makeStyles, Typography } from '@material-ui/core';
import {useLocation} from "react-use";
import {RouteComponentProps, withRouter} from "react-router";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: ${colors.redMain};
`;

const LinkWrapper = styled.div`
    margin-left: 5vw;
`;

interface Item {
    text: string;
    link: string;
}

const createStyles = makeStyles({
    text: {
        color: colors.white,
        textTransform: 'uppercase',
        fontSize: '12px',
        lineHeight: '18px',
    },
    selected: {
        color: colors.white,
        textTransform: 'uppercase',
        fontSize: '12px',
        lineHeight: '18px',
        textDecoration: 'underline'
    }
});

const MenuNavDesktop: React.FC<{ items: Item[] } & RouteComponentProps<any>> = props => {
    const classes = createStyles(props);

    const state = useLocation();
    const [activeLink, setActiveLink] = useState(props.location.pathname);

    useEffect(() => {
        state.pathname && setActiveLink(state.pathname);
    }, [state.pathname]);

    return (
        <Wrapper>
            {props.items.map((item, index) => (
                <LinkWrapper key={index}>
                    <CustomLink to={item.link} key={index}>
                        <Typography variant={'button'} classes={{ root: activeLink.trim() === item.link ? classes.selected : classes.text }}>
                            {item.text}
                        </Typography>
                    </CustomLink>
                </LinkWrapper>
            ))}
        </Wrapper>
    );
};

export default withRouter(MenuNavDesktop);
