import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';

export enum ActionNames {
    GET_ALL_APPLICATION_TYPES = '[Application Type] Get all Application Types',
    GET_ALL_APPLICATION_TYPES_UPDATE_SUCCESS = '[Application Type] Get all Application Types Update Success',
    GET_ALL_APPLICATION_TYPES_OVERRIDE_SUCCESS = '[Application Type] Get all Application Types Override Success',
    GET_ALL_APPLICATION_TYPES_FAILED = '[Application Type] Get all Application Types Failed',
    SET_SEARCH_QUERY = '[Application Type] Set Search Query Application Types',

    INCREASE_PAGE_NUMBER = '[Application Type] Increase Page Number',
}

export const Actions = {
    getAllApplicationTypes: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_APPLICATION_TYPES, data),
    getAllApplicationTypesUpdateSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_APPLICATION_TYPES_UPDATE_SUCCESS, data),
    getAllApplicationTypesOverrideSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_APPLICATION_TYPES_OVERRIDE_SUCCESS, data),
    getAllApplicationTypesFailed: () => createAction(ActionNames.GET_ALL_APPLICATION_TYPES_FAILED),
    setSearchQuery: (query: string) => createAction(ActionNames.SET_SEARCH_QUERY, query),

    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllApplicationTypesType = ActionsOfType<Actions, ActionNames.GET_ALL_APPLICATION_TYPES>;
