import React, { useCallback } from 'react';
import { Button, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import forwardIcon from '../../../static/icons/forwardIcon.svg';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import CustomLink from '../../../shared/link/CustomLink';
import { BloodworkResponse } from '../../../models/responses/bloodwork.response';
import bloodworkListItemIcon from '../../../static/icons/bloodworkListItemIcon.svg';
import { formatDate } from '../../../helpers/dateFormatHelper';

const useStyles = makeStyles({
    listItem: {
        padding: 0,
        margin: '8px 0',
    },
    nameText: {
        fontSize: '14px',
        color: colors.textPrimary,
    },
    typeText: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'capitalize',
    },
    avatar: {
        border: `1px solid ${colors.gray3}`,
        backgroundColor: colors.gray3,
        width: '38px',
        height: '38px',
    },
    avatarWrapper: {
        minWidth: 'auto',
        marginRight: '10px',
        border: `1px solid ${colors.gray1}`,
        borderRadius: '30px',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    forwardIcon: {
        padding: '0 !important',
        minWidth: '25px',
        height: '25px',
    },
    myProviderText: {
        fontSize: '14px',
        color: colors.gray1,
        marginLeft: '4px',
    },
});

const TextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: -6px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface InjectedProps {
    bloodwork: BloodworkResponse;
    patientId?: number;
}

type PropsTypes = InjectedProps;

const BloodworkReportListItem: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);

    const ListItemTextPrimary = useCallback(
        () => (
            <TextWrapper>
                <Typography variant="button" classes={{ root: classes.nameText }}>
                    {formatDate(props.bloodwork.reportDate) + ' Report '}
                </Typography>
            </TextWrapper>
        ),
        [props.bloodwork.reportDate, classes.nameText]
    );

    const ListItemTextSecondary = useCallback(
        () => (
            <Typography variant="body1" classes={{ root: classes.typeText }}>
                Bloodwork
            </Typography>
        ),
        [classes.typeText]
    );

    return (
        <CustomLink
            to={
                props.patientId
                    ? `/bloodwork/${props.bloodwork.labWorkId}/${props.patientId}`
                    : `/bloodwork/${props.bloodwork.labWorkId}`
            }
        >
            <ListItem classes={{ root: classes.listItem }} button>
                <SizeControllerWrapper>
                    <Wrapper>
                        <ListItemAvatar classes={{ root: classes.avatarWrapper }}>
                            <img src={bloodworkListItemIcon} alt="" />
                        </ListItemAvatar>
                        <ListItemText primary={ListItemTextPrimary()} secondary={ListItemTextSecondary()} />

                        <Button classes={{ root: classes.forwardIcon }}>
                            <img src={forwardIcon} alt="" />
                        </Button>
                    </Wrapper>
                </SizeControllerWrapper>
            </ListItem>
        </CustomLink>
    );
};

export default BloodworkReportListItem;
