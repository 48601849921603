import {put, call, takeEvery} from 'redux-saga/effects';
import * as prescriptionActions from '../../actions/prescription.actions';
import * as pharmaciesActions from '../../actions/pharmacies.actions';
import * as therapiesActions from '../../actions/therapies.actions';
import * as applicationTypeActions from '../../actions/applicationType.actions';
import * as drugActions from '../../actions/drug.actions';
import PrescriptionApi from "../../../api/prescription/PrescriptionApi";

function* getAllPrescriptionsPatient(action: prescriptionActions.getAllPrescriptionsPatientType) {
    try {
        const response = yield call(PrescriptionApi.getAllPrescriptionsPatient, action.payload);
        if (action.payload.overrideRecords) {
            yield put({
                type: prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_OVERRIDE_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({
                type: prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_UPDATE_SUCCESS,
                payload: response.data
            });
        }
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_FAILED})
    }
}

function* getAllPrescriptionsProvider(action: prescriptionActions.getAllPrescriptionsProviderType) {
    try {
        const response = yield call(PrescriptionApi.getAllPrescriptionsProvider, action.payload);
        if (action.payload.overrideRecords) {
            yield put({
                type: prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_OVERRIDE_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({
                type: prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_UPDATE_SUCCESS,
                payload: response.data
            });
        }
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_FAILED})
    }
}

function* getPrescriptionByIdPatient(action: prescriptionActions.getPrescriptionByIdPatientType) {
    try {
        const response = yield call(PrescriptionApi.getPrescriptionByIdPatient, action.payload);
        yield put({type: prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_FAILED})
    }
}

function* getPrescriptionByIdProvider(action: prescriptionActions.getPrescriptionByIdProviderType) {
    try {
        const response = yield call(PrescriptionApi.getPrescriptionByIdProvider, action.payload);
        yield put({type: prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_FAILED})
    }
}

function* getAllPharmacies(action: pharmaciesActions.getAllPharmaciesType) {
    try {
        const response = yield call(PrescriptionApi.getAllPharmacies, action.payload);

        if (action.payload.overrideRecords) {
            yield put({
                type: pharmaciesActions.ActionNames.GET_ALL_PHARMACIES_OVERRIDE_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({type: pharmaciesActions.ActionNames.GET_ALL_PHARMACIES_UPDATE_SUCCESS, payload: response.data});
        }
    } catch (e) {
        yield put({type: pharmaciesActions.ActionNames.GET_ALL_PHARMACIES_FAILED})
    }
}

function* getAllDrugs(action: drugActions.getAllDrugsType) {
    try {
        const response = yield call(PrescriptionApi.getAllDrugs, action.payload);

        if (action.payload.overrideRecords) {
            yield put({
                type: drugActions.ActionNames.GET_ALL_DRUGS_OVERRIDE_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({type: drugActions.ActionNames.GET_ALL_DRUGS_UPDATE_SUCCESS, payload: response.data});
        }
    } catch (e) {
        yield put({type: drugActions.ActionNames.GET_ALL_DRUGS_FAILED})
    }
}


function* getAllTherapies(action: therapiesActions.getAllTherapiesType) {
    try {
        const response = yield call(PrescriptionApi.getAllTherapies, action.payload);

        if (action.payload.overrideRecords) {
            yield put({
                type: therapiesActions.ActionNames.GET_ALL_THERAPIES_OVERRIDE_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({type: therapiesActions.ActionNames.GET_ALL_THERAPIES_UPDATE_SUCCESS, payload: response.data});
        }
    } catch (e) {
        yield put({type: therapiesActions.ActionNames.GET_ALL_THERAPIES_FAILED})
    }
}

function* getAllApplicationTypes(action: applicationTypeActions.getAllApplicationTypesType) {
    try {
        const response = yield call(PrescriptionApi.getAllApplicationTypes, action.payload);

        if (action.payload.overrideRecords) {
            yield put({
                type: applicationTypeActions.ActionNames.GET_ALL_APPLICATION_TYPES_OVERRIDE_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({
                type: applicationTypeActions.ActionNames.GET_ALL_APPLICATION_TYPES_UPDATE_SUCCESS,
                payload: response.data
            });
        }
    } catch (e) {
        yield put({type: applicationTypeActions.ActionNames.GET_ALL_APPLICATION_TYPES_FAILED})
    }
}

function* addPrescriptionPatient(action: prescriptionActions.addPrescriptionPatientType) {
    try {
        const response = yield call(PrescriptionApi.addPrescriptionPatient, action.payload);
        yield put({type: prescriptionActions.ActionNames.ADD_PRESCRIPTION_PATIENT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.ADD_PRESCRIPTION_PATIENT_FAILED})
    }
}

function* addPrescriptionProvider(action: prescriptionActions.addPrescriptionProviderType) {
    try {
        const response = yield call(PrescriptionApi.addPrescriptionProvider, action.payload);
        yield put({type: prescriptionActions.ActionNames.ADD_PRESCRIPTION_PROVIDER_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.ADD_PRESCRIPTION_PROVIDER_FAILED})
    }
}

function* updatePrescriptionPatient(action: prescriptionActions.updatePrescriptionPatientType) {
    try {
        const response = yield call(PrescriptionApi.updatePrescriptionPatient, action.payload);
        yield put({type: prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PATIENT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PATIENT_FAILED})
    }
}

function* updatePrescriptionProvider(action: prescriptionActions.updatePrescriptionProviderType) {
    try {
        const response = yield call(PrescriptionApi.updatePrescriptionProvider, action.payload);
        yield put({type: prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PROVIDER_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PROVIDER_FAILED});
    }
}

function* removePrescriptionPatient(action: prescriptionActions.removePrescriptionPatientType) {
    try {
        const response = yield call(PrescriptionApi.removePrescriptionPatient, action.payload);
        yield put({type: prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PATIENT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PATIENT_FAILED});
    }
}

function* removePrescriptionProvider(action: prescriptionActions.removePrescriptionProviderType) {
    try {
        const response = yield call(PrescriptionApi.removePrescriptionProvider, action.payload);
        yield put({type: prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PROVIDER_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PROVIDER_FAILED});
    }
}

export default function* PrescriptionSagas() {
    yield takeEvery(prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_PATIENT, getAllPrescriptionsPatient);
    yield takeEvery(prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_PROVIDER, getAllPrescriptionsProvider);

    yield takeEvery(prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_PATIENT, getPrescriptionByIdPatient);
    yield takeEvery(prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_PROVIDER, getPrescriptionByIdProvider);

    yield takeEvery(prescriptionActions.ActionNames.ADD_PRESCRIPTION_PATIENT, addPrescriptionPatient);
    yield takeEvery(prescriptionActions.ActionNames.ADD_PRESCRIPTION_PROVIDER, addPrescriptionProvider);

    yield takeEvery(prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PATIENT, updatePrescriptionPatient);
    yield takeEvery(prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PROVIDER, updatePrescriptionProvider);

    yield takeEvery(prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PATIENT, removePrescriptionPatient);
    yield takeEvery(prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PROVIDER, removePrescriptionProvider);

    yield takeEvery(pharmaciesActions.ActionNames.GET_ALL_PHARMACIES, getAllPharmacies);
    yield takeEvery(therapiesActions.ActionNames.GET_ALL_THERAPIES, getAllTherapies);
    yield takeEvery(applicationTypeActions.ActionNames.GET_ALL_APPLICATION_TYPES, getAllApplicationTypes);
    yield takeEvery(drugActions.ActionNames.GET_ALL_DRUGS, getAllDrugs);
}