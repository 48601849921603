export const PaymentRoutingPatient = [
    { path: '/payments/one-time', tab: 0 },
    { path: '/payments/subscriptions', tab: 1 },
    { path: '/payments/settings', tab: 2 },
];

export const PaymentRoutingProvider = [
    { path: '/payments/one-time', tab: 0 },
    { path: '/payments/subscriptions', tab: 1 },
    { path: '/payments/my-account', tab: 2 },
];

export const SearchRoutingForProvider = [
    { path: '/search/patients', tab: 0 },
    { path: '/search/labs', tab: 1 },
    { path: '/search/pharmacies', tab: 2 },
];

export const SearchRoutingForPatient = [
    { path: '/search/providers', tab: 0 },
    { path: '/search/labs', tab: 1 },
    { path: '/search/pharmacies', tab: 2 },
];

export const ProviderRouting = [
    { path: '/provider/my-links', tab: 0 },
    { path: '/provider/pending', tab: 1 },
    { path: '/provider/invited', tab: 2 },
];

export const DosageRouting = [
    { path: '/dosage/active', tab: 0 },
    { path: '/dosage/future', tab: 1 },
    { path: '/dosage/past', tab: 2 },
];
