import * as labActions from '../../actions/lab.actions';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { LabModel } from '../../../models/responses/lab.model';
import RequestState from '../../../constants/requestState';

export class StateModel {
    allLabs: BaseDropdownItem[] = [];
    page: number = 0;
    currentProvider: BaseDropdownItem | null = null;
    totalLabs: number | null = null;
    loading: boolean = false;
    searchQuery: string = '';
    currentLab: LabModel | null;
    saveLabsRequest: RequestState = RequestState.UNSENT;
    updateLabsRequest: RequestState = RequestState.UNSENT;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: labActions.Actions) {
    switch (action.type) {
        case labActions.ActionNames.GET_ALL_LABS: {
            return {
                ...state,
                loading: true,
            };
        }

        case labActions.ActionNames.GET_ALL_LABS_UPDATE_SUCCESS: {
            const isEmptyString = state.searchQuery.trim().length === 0;
            const newLabsList = state.allLabs.concat(action.payload.result);
            const isRedundant = newLabsList.length > action.payload.total;
            return {
                ...state,
                loading: false,
                totalLabs: action.payload.total,
                allLabs: isEmptyString && !isRedundant ? newLabsList : action.payload.result,
            };
        }

        case labActions.ActionNames.GET_ALL_LABS_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loading: false,
                totalLabs: action.payload.total,
                allLabs: action.payload.result,
            };
        }

        case labActions.ActionNames.GET_ALL_LABS_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case labActions.ActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case labActions.ActionNames.SET_FILTER_QUERY: {
            const isEmptyString = action.payload.trim().length === 0;
            return {
                ...state,
                searchQuery: action.payload,
                loading: isEmptyString ? false : state.loading,
                page: 0,
                allLabs: isEmptyString ? [] : state.allLabs,
                totalLabs: 0,
            };
        }

        case labActions.ActionNames.GET_LAB_BY_ID: {
            return {
                ...state,
                loading: true,
            };
        }
        case labActions.ActionNames.GET_LAB_BY_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                currentLab: action.payload,
            };
        }
        case labActions.ActionNames.GET_LAB_BY_ID_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }
        case labActions.ActionNames.SAVE_LABS: {
            return {
                ...state,
                saveLabsRequest: RequestState.SENDING,
            };
        }
        case labActions.ActionNames.SAVE_LABS_SUCCESS: {
            return {
                ...state,
                saveLabsRequest: RequestState.SENT_SUCCESS,
            };
        }
        case labActions.ActionNames.SAVE_LABS_FAILED: {
            return {
                ...state,
                saveLabsRequest: RequestState.SENT_FAILED,
            };
        }

        case labActions.ActionNames.UPDATE_LABS: {
            return {
                ...state,
                updateLabsRequest: RequestState.SENDING,
            };
        }
        case labActions.ActionNames.UPDATE_LABS_SUCCESS: {
            return {
                ...state,
                updateLabsRequest: RequestState.SENT_SUCCESS,
            };
        }
        case labActions.ActionNames.UPDATE_LABS_FAILED: {
            return {
                ...state,
                updateLabsRequest: RequestState.SENT_FAILED,
            };
        }

        case labActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                saveLabsRequest: RequestState.UNSENT,
                updateLabsRequest: RequestState.UNSENT,
            };
        }

        default:
            return state;
    }
}
