import React from 'react';
import {makeStyles, Tooltip} from '@material-ui/core';

const createStyles = makeStyles({
    tooltip: {
        fontSize: '12px',
        textAlign: 'center',
        padding: '10px',
    }
});

const TooltipShared:React.FC<{title: string, children: any}> = (props) => {
    const classes = createStyles(props);
    const {title} = props;
    return (
        <Tooltip title={title} classes={{tooltip: classes.tooltip}}>
            {props.children}
        </Tooltip>
    );
};

export default TooltipShared;
