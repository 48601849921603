import {ActionsOfType, ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {BloodtargetReponce} from "../../models/responses/bloodtarget.reponce";
import {AllEntitiesByPagesDto} from "../../models/dto/allEntitiesByPages.dto";
import {BloodTargetRow} from "../../pages/bloodTarget/AddBloodTargets";

export enum ActionNames {
    GET_ALL_BLOOD_TARGETS = '[Blood Target] Get All Blood Targets',
    GET_ALL_BLOOD_TARGETS_OVERRIDE_SUCCESS = '[Blood Target] Get All Blood Targets Override Success',
    GET_ALL_BLOOD_TARGETS_UPDATE_SUCCESS = '[Blood Target] Get All Blood Targets Update Success',
    GET_ALL_BLOOD_TARGETS_FAILED = '[Blood Target] Get All Blood Targets Failed',

    SET_SEARCH_QUERY = '[Blood Target] Set Search Query',
    INCREASE_PAGE_NUMBER = '[Blood Target] Increase Page Number',

    ADD_BLOOD_TARGETS = '[Blood Target] Add Blood Targets',
    ADD_BLOOD_TARGETS_SUCCESS = '[Blood Target] Add Blood Targets Success',
    ADD_BLOOD_TARGETS_FAILED = '[Blood Target] Add Blood Targets Failed',

    GET_BLOOD_TARGET_BY_ID = '[Blood Target] Get Blood Target By Id',
    GET_BLOOD_TARGET_BY_ID_SUCCESS = '[Blood Target] Get Blood Target By Id Success',
    GET_BLOOD_TARGET_BY_ID_FAILED = '[Blood Target] Get Blood Target By Id Failed',

    EDIT_BLOOD_TARGET = '[Blood Target] Edit Blood Target',
    EDIT_BLOOD_TARGET_SUCCESS = '[Blood Target] Edit Blood Target Success',
    EDIT_BLOOD_TARGET_FAILED = '[Blood Target] Edit Blood Target Failed',

    REMOVE_BLOOD_TARGET = '[Blood Target] Remove Blood Target',
    REMOVE_BLOOD_TARGET_SUCCESS = '[Blood Target] Remove Blood Target Success',
    REMOVE_BLOOD_TARGET_FAILED = '[Blood Target] Remove Blood Target Failed',

    RESET_STATUS_VARIABLES =  '[Blood Target] Reset Status Variables',
}

export const Actions = {
    getAllBloodTargets: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_BLOOD_TARGETS, data),
    getAllBloodTargetsUpdateSuccess: (data: { result: BloodtargetReponce[], total: number }) => createAction(ActionNames.GET_ALL_BLOOD_TARGETS_UPDATE_SUCCESS, data),
    getAllBloodTargetsOverrideSuccess: (data: { result: BloodtargetReponce[], total: number }) => createAction(ActionNames.GET_ALL_BLOOD_TARGETS_OVERRIDE_SUCCESS, data),
    getAllBloodTargetsFailed: () => createAction(ActionNames.GET_ALL_BLOOD_TARGETS_FAILED),
    setSearchQuery: (query: string) => createAction(ActionNames.SET_SEARCH_QUERY, query),

    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER),

    addBloodTargets: (data: BloodTargetRow[]) => createAction(ActionNames.ADD_BLOOD_TARGETS,data),
    addBloodTargetsSuccess: () => createAction(ActionNames.ADD_BLOOD_TARGETS_SUCCESS),
    addBloodTargetsFailed: () => createAction(ActionNames.ADD_BLOOD_TARGETS_FAILED),

    getBloodTargetById: (id: number) => createAction(ActionNames.GET_BLOOD_TARGET_BY_ID, id),
    getBloodTargetByIdSuccess: (target: BloodtargetReponce) => createAction(ActionNames.GET_BLOOD_TARGET_BY_ID_SUCCESS, target),
    getBloodTargetByIdFailed: () => createAction(ActionNames.GET_BLOOD_TARGET_BY_ID_FAILED),

    editBloodTarget: (data: BloodtargetReponce[]) => createAction(ActionNames.EDIT_BLOOD_TARGET, data),
    editBloodTargetSuccess: () => createAction(ActionNames.EDIT_BLOOD_TARGET_SUCCESS),
    editBloodTargetFailed: () => createAction(ActionNames.EDIT_BLOOD_TARGET_FAILED),

    removeBloodTarget: (id: number) => createAction(ActionNames.REMOVE_BLOOD_TARGET, id),
    removeBloodTargetSuccess: () => createAction(ActionNames.REMOVE_BLOOD_TARGET_SUCCESS),
    removeBloodTargetFailed: () => createAction(ActionNames.REMOVE_BLOOD_TARGET_FAILED),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllBloodTargetsType = ActionsOfType<Actions, ActionNames.GET_ALL_BLOOD_TARGETS>;
export type addBloodTargetsType = ActionsOfType<Actions, ActionNames.ADD_BLOOD_TARGETS>;
export type getBloodTargetByIdType = ActionsOfType<Actions, ActionNames.GET_BLOOD_TARGET_BY_ID>;
export type editBloodTargetType = ActionsOfType<Actions, ActionNames.EDIT_BLOOD_TARGET>;
export type removeBloodTargetType = ActionsOfType<Actions, ActionNames.REMOVE_BLOOD_TARGET>;