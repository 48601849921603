import React, { useCallback, useEffect, useState } from 'react';
import { ProviderMyPatientActions } from '../../store/actions/providerMyPatients.actions';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import styled from 'styled-components';
import addPatient from '../../static/icons/addPatient.svg';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fade, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers/index';
import HeaderSearch from '../../shared/header/HeaderSearch';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import InvitedPatientsList from './InvitedPatientsList';
import LinkedPatientsList from './LinkedPatientsList';
import PendingPatientsList from './PendingPatientsList';
import { ProviderRouting } from '../../constants/tabRouting/routing';

interface DispatchModel {
    setFilterQueryLinkedPatients: typeof ProviderMyPatientActions.setFilterQueryLinkedPatients;
    setFilterQueryPendingPatients: typeof ProviderMyPatientActions.setFilterQueryPendingPatients;
    setFilterQueryInvitedPatients: typeof ProviderMyPatientActions.setFilterQueryInvitedPatients;
    resetStatusVariables: typeof PatientActions.resetStatusVariables;
}

interface StoreModel {
    searchQueryLinkedPatients: string;
    searchQueryPendingPatients: string;
    searchQueryInvitedPatients: string;
}

type PropsTypes = RouteComponentProps<any> & StoreModel & DispatchModel;

const createStyles = makeStyles({
    addPatientIcon: {
        position: 'absolute',
        cursor: 'pointer',
        right: '8%',
        top: '11px',
    },
    listHeadingText: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
    tabText: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
    tabs: {
        minHeight: '32px',
        backgroundColor: colors.gray2,
        padding: '0 7% 15px',
        '& div': {
            justifyContent: 'space-around',
        },
    },
    tab: {
        minHeight: '20px',
        height: '30px',
        minWidth: '10px',
        padding: '6px 16px',
    },
    selectedTab: {
        '& span': {
            color: colors.textPrimary,
        },
    },
    indicator: {
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: '100%',
            left: 'calc(50% - 4px)',

            borderBottom: `3px solid ${colors.redMain}`,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            width: 0,
            height: 0,
        },
    },
});

const HeaderWrapper = styled.div`
    position: relative;
`;

const SearchWrapper = styled.div`
    background-color: ${colors.gray2};
    padding-bottom: 10px;
`;

function ProviderMyLinks(props: PropsTypes) {
    const classes = createStyles(props);
    const [tabIndex, setTabIndex] = useState(0);

    const {
        setFilterQueryLinkedPatients,
        setFilterQueryPendingPatients,
        setFilterQueryInvitedPatients,
        searchQueryLinkedPatients,
        searchQueryInvitedPatients,
        searchQueryPendingPatients,
    } = props;

    const changeTab = (newValue: number) => {
        setTabIndex(newValue);
        props.history.push(ProviderRouting[newValue].path);
    };

    useEffect(() => {
        switch (props.history.location.pathname) {
            case ProviderRouting[0].path: {
                setTabIndex(ProviderRouting[0].tab);
                return;
            }
            case ProviderRouting[1].path: {
                setTabIndex(ProviderRouting[1].tab);
                return;
            }
            case ProviderRouting[2].path: {
                setTabIndex(ProviderRouting[2].tab);
                return;
            }
            default: {
                setTabIndex(0);
            }
        }
    }, [props.history.location.pathname]);

    const setSearchQuery = useCallback(
        (searchQuery: string) => {
            switch (tabIndex) {
                case 0: {
                    searchQueryLinkedPatients !== searchQuery && setFilterQueryLinkedPatients(searchQuery);
                    return;
                }
                case 1: {
                    searchQueryPendingPatients !== searchQuery && setFilterQueryPendingPatients(searchQuery);
                    return;
                }
                case 2: {
                    searchQueryInvitedPatients !== searchQuery && setFilterQueryInvitedPatients(searchQuery);
                    return;
                }
                default: {
                    return null;
                }
            }
        },
        [
            tabIndex,
            searchQueryLinkedPatients,
            searchQueryInvitedPatients,
            searchQueryPendingPatients,
            setFilterQueryLinkedPatients,
            setFilterQueryPendingPatients,
            setFilterQueryInvitedPatients,
        ]
    );

    return (
        <>
            <div>
                <HeaderWrapper>
                    <HeaderHeadingWithLink>My Patients</HeaderHeadingWithLink>
                    <img
                        src={addPatient}
                        alt=""
                        onClick={() => props.history.push('/provider/invite-patient')}
                        className={classes.addPatientIcon}
                    />
                </HeaderWrapper>

                <SearchWrapper>
                    <SizeControllerWrapper>
                        <HeaderSearch
                            changeHandler={(value: string) => {
                                setSearchQuery(value);
                            }}
                        />
                    </SizeControllerWrapper>
                </SearchWrapper>

                <Tabs
                    value={tabIndex}
                    onChange={(event: React.ChangeEvent<{}>, newValue: number) => changeTab(newValue)}
                    indicatorColor="primary"
                    classes={{ root: classes.tabs, indicator: classes.indicator }}
                >
                    <Tab
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        label={
                            <>
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Linked
                                </Typography>
                            </>
                        }
                    />
                    <Tab
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        label={
                            <>
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Pending
                                </Typography>
                            </>
                        }
                    />
                    <Tab
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        label={
                            <>
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Invited
                                </Typography>
                            </>
                        }
                    />
                </Tabs>

                {tabIndex === 0 && (
                    <Fade in={tabIndex === 0} timeout={{ enter: 500, exit: 500 }}>
                        <LinkedPatientsList />
                    </Fade>
                )}

                {tabIndex === 1 && (
                    <Fade in={tabIndex === 1} timeout={{ enter: 500, exit: 500 }}>
                        <PendingPatientsList />
                    </Fade>
                )}

                {tabIndex === 2 && (
                    <Fade in={tabIndex === 2} timeout={{ enter: 500, exit: 500 }}>
                        <InvitedPatientsList />
                    </Fade>
                )}
            </div>
        </>
    );
}

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        searchQueryLinkedPatients: state.providerMyPatients.searchQueryLinkedPatients,
        searchQueryPendingPatients: state.providerMyPatients.searchQueryPendingPatients,
        searchQueryInvitedPatients: state.providerMyPatients.searchQueryInvitedPatients,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setFilterQueryLinkedPatients: bindActionCreators(
            ProviderMyPatientActions.setFilterQueryLinkedPatients,
            dispatch
        ),
        setFilterQueryPendingPatients: bindActionCreators(
            ProviderMyPatientActions.setFilterQueryPendingPatients,
            dispatch
        ),
        setFilterQueryInvitedPatients: bindActionCreators(
            ProviderMyPatientActions.setFilterQueryInvitedPatients,
            dispatch
        ),
        resetStatusVariables: bindActionCreators(PatientActions.resetStatusVariables, dispatch),
    })
)(withRouter(ProviderMyLinks));
