import React, { useCallback, useEffect, useRef, useState } from 'react';
import HeaderTitle from '../../shared/HeaderTitle';
import TextInput from '../../shared/input/TextInput';
import styled from 'styled-components';
import { validateSync } from 'class-validator';
import { InvitePatientDto } from '../../models/dto/invitePatientDto';
import fillClassInstanceWithValues from '../../shared/utils/fillClassInstanceWithValues';
import PrimaryButton from '../../shared/button/PrimaryButton';
import PatientApi from '../../api/patient/PatientApi';
import LoadingTransparent from '../shared/LoadingTransparent';
import Snackbar, { SnackbarTypes } from '../../shared/snackbars/snackbar';
import { HttpRequestError } from '../../errors/errors';
import { GenderOptions } from '../../constants/genderOptions';
import DropdownInput from '../../shared/input/DropdownInput';
import { FormControl, FormHelperText } from '@material-ui/core';
import PhoneNumberInput from '../../shared/input/PhoneNumberInput';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import halfLogoBackground from '../../static/images/landing/main/halfLogoBackground.svg';
import { useDebounce } from 'use-debounce';
import {TYPED_ENV} from "../../helpers/typedEnv";

const Container = styled.div`
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    & > div {
        margin-bottom: 22px !important;
        position: relative;
    }
`;

const InputWrapper = styled(FormControl)`
    width: 100%;
    margin: 20px auto;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 30px 0 55px;
`;

const ErrorWrapper = styled.div`
    position: absolute;
`;

const BackgroundWrapper = styled.div`
    background-image: url(${halfLogoBackground});
    background-repeat: no-repeat;
    background-position: right 45%;
    background-size: 53%;
`;

enum SendInviteStatus {
    UNSENT,
    LOADING,
    FAILED,
    SUCCEEDED,
}

const newPatientInitialState = {
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    phone: '',
    streetAddress1: '',
    streetAddress2: '',
    zip: '',
    city: '',
    state: '',
    isBeingEdited: new Set(),
};

interface ValidationErrorsMap {
    [p: string]: string;
}

const phoneInputEmptyValue = '+ (   )    .   .   ';

const requiredFields = ['firstName', 'lastName', 'gender', 'email'];
const optionalFields = ['phone', 'streetAddress1', 'streetAddress2', 'city', 'state', 'zip'];

function InvitePatient() {
    const [newPatient, setNewPatientDetails] = useState(newPatientInitialState);

    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    const [resultingStatusMsg, setResultingStatusMsg] = useState('');
    const [status, setStatus] = useState(SendInviteStatus.UNSENT);

    const formRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        validateForm(newPatient);
    }, [newPatient]);

    useEffect(() => {
        return () => {
            setValidationErrorsMap({});
            newPatient.isBeingEdited = new Set();
        };
    }, [newPatient]);

    const validateForm = (user: any, isOnSubmit = false) => {
        const item = fillClassInstanceWithValues<InvitePatientDto>(InvitePatientDto, {
            ...user,
            redirectUrl: `${TYPED_ENV.REACT_APP_BASE_URL}/patient/accept-invite`,
        });
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                    map[err.property] = Object.entries(err.constraints)
                        .map(([, value]) => value)
                        .join(', ');
                }
            });
            setValidationErrorsMap(map);
        }
        setValidationErrorsMap(map);
        return map;
    };

    const isValid = () => {
        const map = validateForm(newPatient, true);
        let valid = true;
        requiredFields.forEach(field => {
            if (map.hasOwnProperty(field)) {
                valid = false;
            }
        });
        optionalFields.forEach(field => {
            if (map.hasOwnProperty(field) && newPatient.isBeingEdited.has(field)) {
                valid = false;
            }
        });
        if (valid) {
            setValidationErrorsMap({});
        }
        return valid;
    };

    const changePatient = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUser = {
            ...newPatient,
            [e.target.id]: e.target.value,
            isBeingEdited: newPatient.isBeingEdited.add(e.target.id),
        };
        if (e.target.value.length === 0) {
            newUser.isBeingEdited.delete(e.target.id);
        }
        setNewPatientDetails(newUser);
    };

    const setPatientGender = (gender: string) => {
        const newUser = {
            ...newPatient,
            gender,
            isBeingEdited: newPatient.isBeingEdited.add(gender),
        };
        setNewPatientDetails(newUser);
    };

    const sendForm = async () => {
        if (isValid()) {
            try {
                setStatus(SendInviteStatus.LOADING);
                await PatientApi.invitePatient({ ...newPatient, redirectUrl: `${TYPED_ENV.REACT_APP_BASE_URL}/patient/accept-invite`, });
                setStatus(SendInviteStatus.SUCCEEDED);
                setValidationErrorsMap({});
                window.scrollTo(0, 0);
                newPatientInitialState.isBeingEdited = new Set();
                setNewPatientDetails(newPatientInitialState);
                setResultingStatusMsg(`${newPatient.firstName} ${newPatient.lastName} was successfully invited.`);
            } catch (err) {
                setStatus(SendInviteStatus.FAILED);
                if (err instanceof HttpRequestError) {
                    setResultingStatusMsg(`${err.message}`);
                } else {
                    setResultingStatusMsg('Something went wrong...');
                }
            }
        }
    };

    const hideSnackbar = useCallback(() => {
        setStatus(SendInviteStatus.UNSENT);
    }, []);

    const renderError = (field: keyof ValidationErrorsMap) => {
        if (!!validationErrorsMap[field]) {
            return (
                <ErrorWrapper>
                    <FormHelperText id="component-error-text">
                        {validationErrorsMap[field].split(',')[0]}
                    </FormHelperText>
                </ErrorWrapper>
            );
        }
    };

    const setNextFieldFocus = () => {
        //find the index of gender dropdown and set focused next field
        if (formRef.current && dropdownRef.current) {
            // @ts-ignore
            const button = dropdownRef.current.getElementsByTagName('button')[0];
            const form = formRef.current;
            // @ts-ignore
            const formArray = [...formRef.current];
            const index = formArray.indexOf(button);
            // @ts-ignore
            form.elements[index + 1].focus();
        }
    };

    const handleEnter = (event: any) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const formArray = [...event.target.form];
            const index = formArray.indexOf(event.target);
            //check if next element is button
            if (index === formArray.length - 1) {
                sendForm();
            } else {
                form.elements[index + 1].focus();
            }
            event.preventDefault();
        }
    };

    return (
        <>
            <HeaderTitle text="New Patient" />
            <BackgroundWrapper>
                <SizeControllerWrapper>
                    <form ref={formRef}>
                        <Container>
                            <InputWrapper error={!!validation.firstName}>
                                <TextInput
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        changePatient(evt);
                                    }}
                                    inputProps={{ id: 'firstName' }}
                                    value={newPatient.firstName}
                                    placeholder={'First Name *'}
                                    error={!!validation.firstName}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.firstName && renderError('firstName')}
                            </InputWrapper>

                            <InputWrapper error={!!validation.lastName}>
                                <TextInput
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        changePatient(evt);
                                    }}
                                    inputProps={{ id: 'lastName' }}
                                    value={newPatient.lastName}
                                    placeholder={'Last Name *'}
                                    error={!!validation.lastName}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.lastName && renderError('lastName')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.gender}>
                                <div ref={dropdownRef}>
                                    <DropdownInput
                                        reset={status === SendInviteStatus.SUCCEEDED}
                                        placeholder={'Gender *'}
                                        options={GenderOptions}
                                        value={newPatient.gender}
                                        onChange={value => {
                                            // @ts-ignore
                                            setPatientGender(value);
                                            setNextFieldFocus();
                                        }}
                                        error={!!validation.gender}
                                        onKeyDown={(evt: any) => handleEnter(evt)}
                                    />
                                </div>
                                {!!validation.gender && renderError('gender')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.phone}>
                                <PhoneNumberInput
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        if (
                                            evt.target.value !== phoneInputEmptyValue ||
                                            newPatient.isBeingEdited.has('phone')
                                        ) {
                                            evt.type ==='change' && changePatient(evt);
                                        }
                                    }}
                                    inputProps={{ id: 'phone' }}
                                    value={newPatient.phone}
                                    placeholder={'+ (000) 000.000.000'}
                                    error={!!validation.phone && newPatient.isBeingEdited.has('phone')}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.phone && newPatient.isBeingEdited.has('phone') && renderError('phone')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.streetAddress1}>
                                <TextInput
                                    onChange={changePatient}
                                    inputProps={{ id: 'streetAddress1' }}
                                    value={newPatient.streetAddress1}
                                    placeholder={'Address 1'}
                                    error={
                                        !!validation.streetAddress1 && newPatient.isBeingEdited.has('streetAddress1')
                                    }
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.streetAddress1 &&
                                    newPatient.isBeingEdited.has('streetAddress1') &&
                                    renderError('streetAddress1')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.streetAddress2}>
                                <TextInput
                                    onChange={changePatient}
                                    inputProps={{ id: 'streetAddress2' }}
                                    value={newPatient.streetAddress2}
                                    placeholder={'Address 2'}
                                    error={
                                        !!validation.streetAddress2 && newPatient.isBeingEdited.has('streetAddress2')
                                    }
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.streetAddress2 &&
                                    newPatient.isBeingEdited.has('streetAddress2') &&
                                    renderError('streetAddress2')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.city}>
                                <TextInput
                                    onChange={changePatient}
                                    inputProps={{ id: 'city' }}
                                    value={newPatient.city}
                                    placeholder={'City'}
                                    error={!!validation.city && newPatient.isBeingEdited.has('city')}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.city && newPatient.isBeingEdited.has('city') && renderError('city')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.state}>
                                <TextInput
                                    onChange={changePatient}
                                    inputProps={{ id: 'state' }}
                                    value={newPatient.state}
                                    placeholder={'State'}
                                    error={!!validation.state && newPatient.isBeingEdited.has('state')}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.state && newPatient.isBeingEdited.has('state') && renderError('state')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.zip}>
                                <TextInput
                                    onChange={changePatient}
                                    inputProps={{ id: 'zip' }}
                                    value={newPatient.zip}
                                    placeholder={'Zip'}
                                    error={!!validation.zip && newPatient.isBeingEdited.has('zip')}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.zip && newPatient.isBeingEdited.has('zip') && renderError('zip')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.email}>
                                <TextInput
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        //@ts-ignore
                                        changePatient({ target: { id: 'email', value: evt.target.value.trim() } });
                                    }}
                                    inputProps={{ id: 'email' }}
                                    value={newPatient.email}
                                    placeholder={'Email *'}
                                    error={!!validation.email}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                />
                                {!!validation.email && renderError('email')}
                            </InputWrapper>
                        </Container>
                    </form>
                    <ButtonWrapper>
                        <PrimaryButton onClick={() => sendForm()}>Send Invite</PrimaryButton>
                    </ButtonWrapper>
                </SizeControllerWrapper>
            </BackgroundWrapper>

            {status === SendInviteStatus.LOADING && <LoadingTransparent />}
            {status === SendInviteStatus.SUCCEEDED && (
                <Snackbar
                    type={SnackbarTypes.SUCCESS}
                    message={resultingStatusMsg}
                    open
                    variant="success"
                    onClose={hideSnackbar}
                />
            )}
        </>
    );
}

export default InvitePatient;
