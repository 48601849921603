import { restAxios } from '../restAxios';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { labApiValid } from '../../models/dtoValidation/labApi.response';
import { LabModel } from '../../models/responses/lab.model';

class LabApi {
    @HandleAsyncErrors()
    static async getAllLabs(data: AllEntitiesByPagesDto) {
        const res = await restAxios.get(`private/labs?page=${data.page}&size=${data.size}&sort=
            ${data.sort && encodeURIComponent(data.sort)}&searchQuery=${data.searchQuery}`);
        return new labApiValid(res.data);
    }

    @HandleAsyncErrors()
    static async getLabById(id: number) {
        const res = await restAxios.get(`private/labs/${id}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async saveLabs(data: LabModel[]) {
        const res = await restAxios.post(`private/labs`, data);
        return res.data;
    }

    @HandleAsyncErrors()
    static async updateLabs(data: LabModel[]) {
        const res = await restAxios.put(`private/labs`, data);
        return res.data;
    }
}

export default LabApi;
