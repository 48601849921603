import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import styled from 'styled-components';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { colors } from '../../../theme/colors';
import LoadingTransparent from '../../shared/LoadingTransparent';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { connect } from 'react-redux';
import { Actions as PatientActions } from '../../../store/actions/patient.actions';
import { Actions as ProviderActions } from '../../../store/actions/provider.actions';
import * as fromRoot from '../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserModel } from '../../../models/responses/user.response';
import { RoleOptions } from '../../../constants/roleOptions';
import { mediaQuery } from '../../../constants/device';
import RequestState from '../../../constants/requestState';

const styles = createStyles({
    backLink: {
        color: colors.redMain,
        textDecoration: 'underline',
        textDecorationColor: colors.redMain,
        marginTop: '12px',
        cursor: 'pointer',
    },
    text: {
        color: colors.textSecondary,
        fontSize: '14px',
        marginBottom: '8px',
    },
    reactCropV: {
        margin: '0 auto',
        height: '100%',
        '& > div': {
            height: '100%',
            '& img': {
                height: '100%',
            },
        },
    },
    reactCropH: {
        margin: '0 auto',
        width: '100%',
        '& > div': {
            width: '100%',
            '& img': {
                width: '100%',
            },
        },
    },
});

const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12% 0;
    align-items: center;
    justify-content: space-between;
    height: 30%;
    ${mediaQuery.laptop} {
        padding: 0;
        margin: 22px 0 12px;
    }
`;

const CropWrapper = styled.div`
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.gray1};
    overflow: hidden;
    ${mediaQuery.tablet} {
        height: 500px;
    }
    ${mediaQuery.laptop} {
        height: 100%;
    }
`;

const AbsoluteWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 1;
    top: 0;
    background-color: ${colors.white};
`;

const Wrapper = styled.div`
    height: calc(100vh - 90px);
    ${mediaQuery.laptop} {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

const CropSizeWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    ${mediaQuery.laptop} {
        width: 100%;
        margin: 0 auto;
    }
`;

type StoreModel = {
    user: UserModel | null;
    updatingAvatarPatient: RequestState | null;
    updatingAvatarProvider: RequestState | null;
};

type DispatchModel = {
    uploadAvatarPatient: typeof PatientActions.uploadAvatar;
    uploadAvatarProvider: typeof ProviderActions.uploadAvatar;
};

type InjectedProps = {
    src: string;
    file: any;
    orientation: 'h' | 'v' | null;
    closeDialog: () => void;
    originalSize: { h: number; w: number };
};

type PropsTypes = DispatchModel & StoreModel & InjectedProps & WithStyles<typeof styles> & RouteComponentProps<any>;

const CropAvatar: React.FC<PropsTypes> = props => {
    const { updatingAvatarPatient, updatingAvatarProvider, user, uploadAvatarPatient, uploadAvatarProvider } = props;

    const width =
        props.originalSize.w > props.originalSize.h ? (props.originalSize.h / props.originalSize.w) * 100 : 100;
    const height =
        props.originalSize.h > props.originalSize.w ? (props.originalSize.w / props.originalSize.h) * 100 : 100;
    const x = width === 100 ? 0 : (100 - width) / 2;
    const y = height === 100 ? 0 : (100 - height) / 2;

    const [crop, setCrop] = useState<any>({
        unit: '%',
        width,
        height,
        y,
        x,
        aspect: 1,
    });

    const [factor, setFactor] = useState(1);
    const [loading, setLoading] = useState(true);

    const onImageLoaded = useCallback(() => {
        setLoading(false);
        setFactor(document.getElementsByClassName('ReactCrop__image')[0].clientWidth / props.originalSize.w);
    }, [props.originalSize.w]);

    const onCropChange = useCallback(
        (crop: Crop) => {
            setCrop(crop);
        },
        []
    );

    const saveChanges = useCallback(() => {
        setLoading(true);

        const newAvatar = {
            file: props.file,
            height: Math.floor(crop.height / factor),
            width: Math.floor(crop.width / factor),
            x: Math.floor(crop.x / factor),
            y: Math.floor(crop.y / factor),
        };
        if (user && user.role === RoleOptions.PATIENT) {
            uploadAvatarPatient(newAvatar);
        } else if (user && user.role === RoleOptions.PROVIDER) {
            uploadAvatarProvider(newAvatar);
        }
    }, [crop, props.file, factor, user, uploadAvatarPatient, uploadAvatarProvider]);

    useEffect(() => {
        if (
            updatingAvatarPatient === RequestState.SENT_SUCCESS ||
            updatingAvatarProvider === RequestState.SENT_SUCCESS
        ) {
            props.history.push('/profile/editOn');
        }
    }, [updatingAvatarPatient, updatingAvatarProvider, props.history]);

    const { classes } = props;

    return (
        <>
            <AbsoluteWrapper>
                <Wrapper>
                    <CropWrapper>
                        <CropSizeWrapper>
                            <ReactCrop
                                src={props.src}
                                crop={crop}
                                onImageLoaded={onImageLoaded}
                                onChange={onCropChange}
                                minWidth={50}
                                minHeight={50}
                                keepSelection={true}
                                circularCrop={true}
                                className={
                                    props.orientation && props.orientation === 'v'
                                        ? classes.reactCropV
                                        : classes.reactCropH
                                }
                            />
                        </CropSizeWrapper>
                    </CropWrapper>
                        <BottomWrapper>
                            <Typography variant="body1" classes={{ root: classes.text }}>
                                Scale and Crop Photo
                            </Typography>
                            <PrimaryButton onClick={saveChanges}>Done</PrimaryButton>
                            <Typography
                                variant="button"
                                classes={{ root: classes.backLink }}
                                onClick={props.closeDialog}
                            >
                                Cancel
                            </Typography>
                        </BottomWrapper>
                </Wrapper>
            </AbsoluteWrapper>

            {(loading ||
                updatingAvatarPatient === RequestState.SENDING ||
                updatingAvatarProvider === RequestState.SENDING) && <LoadingTransparent />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
        updatingAvatarPatient: state.patient.updatingAvatar,
        updatingAvatarProvider: state.provider.updatingAvatar,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        uploadAvatarPatient: bindActionCreators(PatientActions.uploadAvatar, dispatch),
        uploadAvatarProvider: bindActionCreators(ProviderActions.uploadAvatar, dispatch),
    })
)(withStyles(styles)(withRouter(CropAvatar)));
