import React, { useCallback, useEffect, useMemo, useState } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import TextInput from '../../shared/input/TextInput';
import PrimaryButton from '../../shared/button/PrimaryButton';
import CancelLink from '../../shared/link/CancelLink';
import styled from 'styled-components';
import { mediaQuery } from '../../constants/device';
import { connect } from 'react-redux';
import { Actions as PanelTypeActions } from '../../store/actions/panelType.actions';
import RequestState from '../../constants/requestState';
import LoadingTransparent from '../shared/LoadingTransparent';
import { bindActionCreators, Dispatch } from 'redux';
import * as fromRoot from '../../store/reducers';
import { withRouter, RouteComponentProps } from 'react-router';
import {PanelTypeModel} from "../../models/responses/panelType.model";

const Wrapper = styled.div`
    height: calc(100vh - 178px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0 0;
    ${mediaQuery.laptop} {
        height: calc(100vh - 236px);
    }
`;

const InputWrapper = styled.div`
    width: 100%;
`;

interface StoreModel {
    addPanelTypeRequest: RequestState;
    newPanel: PanelTypeModel | null;
}
interface DispatchModel {
    addPanelType: typeof PanelTypeActions.addPanelType;
    resetStatusVariables: typeof PanelTypeActions.resetStatusVariables;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const AddPanelTypes: React.FC<PropsTypes> = props => {
    const { addPanelType, addPanelTypeRequest, resetStatusVariables, newPanel } = props;
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);

    const isNameValid = useMemo(() => {
        return name.length > 0;
    }, [name]);

    const save = useCallback(() => {
        if (isNameValid) {
            addPanelType({ name });
        } else {
            setNameValid(false);
        }
    }, [name, isNameValid, addPanelType]);

    useEffect(() => {
        if (addPanelTypeRequest === RequestState.SENT_SUCCESS && newPanel) {
            props.history.push(`/admin/panel-types/${newPanel.id}`);
        }
    }, [addPanelTypeRequest, props.history, newPanel]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    return (
        <>
            <HeaderHeadingWithLink>Add Panel Type</HeaderHeadingWithLink>
            {addPanelTypeRequest === RequestState.SENDING && <LoadingTransparent />}
            <SizeControllerWrapper>
                <Wrapper>
                    <InputWrapper>
                        <TextInput
                            placeholder={'Panel Type Name'}
                            value={name}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                setNameValid(true);
                                setName(evt.target.value);
                            }}
                            error={!nameValid}
                        />
                    </InputWrapper>
                    <div>
                        <PrimaryButton onClick={save}>Save Panel Type</PrimaryButton>
                        <CancelLink />
                    </div>
                </Wrapper>
            </SizeControllerWrapper>
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        addPanelTypeRequest: state.panelType.addPanelTypeRequest,
        newPanel: state.panelType.newPanel,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        addPanelType: bindActionCreators(PanelTypeActions.addPanelType, dispatch),
        resetStatusVariables: bindActionCreators(PanelTypeActions.resetStatusVariables, dispatch),
    })
)(withRouter(AddPanelTypes));
