import { put, call, takeEvery } from 'redux-saga/effects';
import * as adminStatisticsActions from '../../actions/adminStatistics';
import AdminStatisticsApi from '../../../api/adminStatistics/AdminStatisticsApi';
import { typedCall } from '../../../shared/utils/typedYieldWrapper';

function* getAdminStatistics() {
    try {
        const value = yield call(AdminStatisticsApi.getAdminStatistics);
        const response = typedCall(AdminStatisticsApi.getAdminStatistics, value);
        yield put({ type: adminStatisticsActions.ActionNames.GET_ADMIN_STATISTICS_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: adminStatisticsActions.ActionNames.GET_ADMIN_STATISTICS_FAILED });
    }
}

export default function* AnalyticsSagas() {
    yield takeEvery(adminStatisticsActions.ActionNames.GET_ADMIN_STATISTICS, getAdminStatistics);
}
