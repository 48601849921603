import * as notificationsActions from '../../actions/notifications.actions';
import { CompatClient } from '@stomp/stompjs/esm5/compatibility/compat-client';
import { NotificationModel } from '../../../models/responses/notification.responce';
import { NotificationsActionNames } from '../../actions/notifications.actions';

export class StateModel {
    connectionInstance: CompatClient | null;
    notifications: NotificationModel[] = [];
    hasUnreadNotifications: boolean = false;
    selectedPatientId: number | null = null;

    filteredNotifications: NotificationModel[] = [];
    loading: boolean = false;
    page: number = 0;
    total: number | null = null;
}

const checkIfHasUndreadNotifications = (notifications: NotificationModel[]) => notifications.some(n => !n.viewed);

export const initialState = new StateModel();

export function reducer(state = initialState, action: notificationsActions.NotificationActions) {
    switch (action.type) {
        case notificationsActions.NotificationsActionNames.WS_CONNECTION_OPENED: {
            return {
                ...state,
                connectionInstance: action.payload,
            };
        }

        case notificationsActions.NotificationsActionNames.WS_RECEIVED_NOTIFICATION: {
            const notifications = [...state.notifications, ...action.payload];

            return {
                ...state,
                notifications,
                hasUnreadNotifications: checkIfHasUndreadNotifications(notifications),
            };
        }

        case NotificationsActionNames.MARK_AS_READ: {
            return {
                ...state,
            };
        }

        case NotificationsActionNames.MARK_AS_READ_SUCCESSFULL: {
            const notifications = state.notifications.map(n => {
                return {
                    ...n,
                    viewed: n.viewed = new Date().toISOString(),
                };
            });
            return {
                ...state,
                notifications,
                hasUnreadNotifications: checkIfHasUndreadNotifications(notifications),
            };
        }

        case NotificationsActionNames.MARK_ONE_AS_READ: {
            const notifications = state.notifications.map(n => {
                return {
                    ...n,
                    viewed: n.id === action.payload ? (n.viewed = new Date().toISOString()) : n.viewed,
                };
            });
            return {
                ...state,
                notifications,
                hasUnreadNotifications: checkIfHasUndreadNotifications(notifications),
            };
        }

        case NotificationsActionNames.SET_PATIENT: {
            return {
                ...state,
                selectedPatientId: action.payload,
            };
        }

        case NotificationsActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT: {
            return {
                ...state,
                loading: true,
            };
        }

        case NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loading: false,
                total: action.payload.total,
                filteredNotifications: action.payload.result,
            };
        }

        case NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_UPDATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                total: action.payload.total,
                filteredNotifications: state.filteredNotifications.concat(action.payload.result),
            };
        }

        case NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case NotificationsActionNames.RESET_PATIENT_NOTIFICATIONS: {
            return {
                ...state,
                filteredNotifications: [],
                total: 0,
                page: 0,
            }
        }

        default:
            return state;
    }
}
