import React, { useCallback, useEffect, useRef, useState } from 'react';
import { List, makeStyles, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../shared/Loading';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as LabworkActions } from '../../../store/actions/labwork.actions';
import { colors } from '../../../theme/colors';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import BloodworkReportListItem from './BloodworkReportListItem';
import { SortOptions } from '../../../constants/sortOptions';
import { BloodworkResponse } from '../../../models/responses/bloodwork.response';
import { UserModel } from '../../../models/responses/user.response';
import { RoleOptions } from '../../../constants/roleOptions';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../../constants/layout';

const useStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '16px',
        color: colors.textSecondary,
        marginTop: '20px',
    },
});

type StoreModel = {
    loading: boolean;
    allBloodworks: BloodworkResponse[];
    totalBloodworks: number | null;
    page: number;
    user: UserModel | null;
};

type DispatchModel = {
    getAllBloodLabWorkPatient: typeof LabworkActions.getAllBloodLabWorkPatient;
    getAllBloodLabWorkProvider: typeof LabworkActions.getAllBloodLabWorkProvider;
    increasePageNumber: typeof LabworkActions.increasePageNumber;
    resetAllBloodLabWorks: typeof LabworkActions.resetAllBloodLabWorks;
};

interface InjectedProps {
    patientId?: number;
}

type PropsTypes = InjectedProps & StoreModel & DispatchModel;

const BloodworkReportsList: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);

    const {
        page,
        loading,
        allBloodworks,
        totalBloodworks,
        user,
        getAllBloodLabWorkPatient,
        getAllBloodLabWorkProvider,
        increasePageNumber,
        resetAllBloodLabWorks,
        patientId,
    } = props;

    const [loadingInitial, setLoadingInitial] = useState(false);
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [height, setHeight] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            const isLaptop = window.innerHeight >= 1024;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef]);

    const loadData = useCallback(() => {
        if (user && user.role === RoleOptions.PATIENT) {
            getAllBloodLabWorkPatient({
                page: page,
                size: 20,
                sort: SortOptions.REPORT_DATE_DESCENDING,
                searchQuery: '',
            });
        }
        if (user && user.role === RoleOptions.PROVIDER && patientId) {
            getAllBloodLabWorkProvider(
                {
                    page: page,
                    size: 20,
                    sort: SortOptions.REPORT_DATE_DESCENDING,
                    searchQuery: '',
                },
                patientId
            );
        }
    }, [user, page, getAllBloodLabWorkProvider, getAllBloodLabWorkPatient, patientId]);

    useEffect(() => {
        loadData();
        setInitialLoaded(true);
    }, [loadData]);

    useEffect(() => {
        if (!loading && initialLoaded && page * 20 >= allBloodworks.length && allBloodworks.length !== 0) {
            loadData();
        }
    }, [page, allBloodworks, initialLoaded, loading, loadData]);

    useEffect(() => {
        return () => {
            resetAllBloodLabWorks();
            setLoadingInitial(true);
        };
    }, [resetAllBloodLabWorks]);

    const getMoreBloodwork = useCallback(() => {
        increasePageNumber();
    }, [increasePageNumber]);

    return (
        <>
            {!!allBloodworks.length && (
                <List classes={{ root: classes.list }} ref={listRef}>
                    <InfiniteScroll
                        dataLength={allBloodworks.length}
                        next={getMoreBloodwork}
                        height={height}
                        hasMore={totalBloodworks ? allBloodworks.length < totalBloodworks : false}
                        loader={<Loading smallSize />}
                        className={classes.scrollContainer}
                    >
                        {allBloodworks.map((bloodwork, index) => (
                            <div key={index}>
                                <BloodworkReportListItem patientId={patientId} bloodwork={bloodwork} />
                            </div>
                        ))}
                    </InfiniteScroll>
                </List>
            )}

            {!loading && allBloodworks.length === 0 && (
                <SizeControllerWrapper>
                    <Typography variant="body1" classes={{ root: classes.noData }}>
                        No data found.
                    </Typography>
                </SizeControllerWrapper>
            )}

            {loadingInitial && loading && <Loading smallSize />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        allBloodworks: state.labwork.allBloodworks,
        totalBloodworks: state.labwork.totalBloodworks,
        loading: state.labwork.loading,
        page: state.labwork.page,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllBloodLabWorkPatient: bindActionCreators(LabworkActions.getAllBloodLabWorkPatient, dispatch),
        getAllBloodLabWorkProvider: bindActionCreators(LabworkActions.getAllBloodLabWorkProvider, dispatch),
        increasePageNumber: bindActionCreators(LabworkActions.increasePageNumber, dispatch),
        resetAllBloodLabWorks: bindActionCreators(LabworkActions.resetAllBloodLabWorks, dispatch),
    })
)(BloodworkReportsList);
