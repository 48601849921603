import React, { useCallback, useState } from 'react';
import FabButton from '../../shared/button/FabButton';
import styled from 'styled-components';
import { Fade, Typography } from '@material-ui/core';
import ProviderListSearchPage from '../patient/components/ProviderListSearchPage';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as ProviderActions } from '../../store/actions/provider.actions';
import SearchAndTabsShared from '../shared/SearchAndTabsShared';

const Wrapper = styled.div`
    padding: 32px;
`;

interface DispatchModel {
    setFilterQueryProvider: typeof ProviderActions.setFilterQuery;
}

type PropsTypes = DispatchModel;

const ProvidersList: React.FC<PropsTypes> = props => {
    const [activeTab, setActiveTab] = useState(0);

    const { setFilterQueryProvider } = props;

    const setFilterQuery = useCallback(
        (value: string) => {
            if (activeTab === 0) {
                setFilterQueryProvider(value);
            } else {
                //TODO
                // setFilterQueryProvider(value);
            }
        },
        [activeTab, setFilterQueryProvider]
    );

    return (
        <>
            <SearchAndTabsShared
                setSearchQuery={setFilterQuery}
                tab={activeTab}
                setTab={(value: number) => setActiveTab(value)}
                tabs={['Providers', 'Archived providers']}
            />

            {activeTab === 0 && (
                <Fade in={activeTab === 0} timeout={{ enter: 500, exit: 500 }}>
                    <ProviderListSearchPage />
                </Fade>
            )}
            {activeTab === 1 && (
                <Fade in={activeTab === 1} timeout={{ enter: 500, exit: 500 }}>
                    <Wrapper>
                        <Typography variant={'body1'}>The feature is in progress</Typography>
                    </Wrapper>
                </Fade>
            )}

            <FabButton redirectUrl={`/admin/providers/add-provider`} />
        </>
    );
};

export default connect(
    () => ({}),
    (dispatch: Dispatch): DispatchModel => ({
        setFilterQueryProvider: bindActionCreators(ProviderActions.setFilterQuery, dispatch),
    })
)(ProvidersList);
