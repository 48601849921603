export const DefaultPageSize = 10;

export const shouldFetchData = (
    isLoading: boolean,
    pageNumber: number,
    dataLength: number,
    isInitialLoaded: boolean,
    pageSize: number = DefaultPageSize,
) => {
    return !isLoading && pageNumber * pageSize >= dataLength && dataLength !== 0 && isInitialLoaded;
};

export const hasMoreData = (total: number | null, dataLength: number) => {
    return total ? dataLength < total : false;
};

export const isNoDataToFetch = (total: number | null, loading : boolean, initialLoaded: boolean) => {
    return !total && !loading && initialLoaded;
};