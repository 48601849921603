import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import map from '../../../../static/images/landing/about/PlymouthGoogleMap.png';
import { makeStyles, Typography } from '@material-ui/core';
import { mediaQuery } from '../../../../constants/device';

const Wrapper = styled.div`
    padding-top: 100px;
    background: ${colors.white};
    margin-bottom: 70px;
`;

const Map = styled.div`
    background: url(${map}) no-repeat;
    background-size: cover;
    height: 500px;
    ${mediaQuery.tablet} {
        width: 60%;
    }
`;

const ContractInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 360px;
    width: 90%;
    margin-top: -180px;
    background-color: ${colors.redMain};
    ${mediaQuery.tablet} {
        margin-left: 50%;
        margin-top: -550px;
        width: auto;
        height: 500px;
    }
`;

const SimpleWrap = styled.div`
    width: 85%;
    padding-top: 30px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
`;

const createStyles = makeStyles({
    header: {
        width: '77%',
        fontSize: '24px',
        color: `${colors.white}`,
    },
    bolt: {
        width: '20%',
        color: `${colors.white}`,
        textTransform: 'uppercase',
    },
    thin: {
        width: '60%',
        color: `${colors.white}`,
    },
});

const MapMobile = () => {
    const classes = createStyles();
    return (
        <Wrapper>
            <Map />
            <ContractInfo>
                <Typography variant="h2" classes={{ root: classes.header }}>
                    Contact Information
                </Typography>
                <SimpleWrap>
                    <Typography variant="button" classes={{ root: classes.bolt }}>
                        Address
                    </Typography>
                    <Typography variant="body1" classes={{ root: classes.thin }}>
                        10215 Split Rock Ct., Orient,  OH 43146
                    </Typography>
                </SimpleWrap>
                <SimpleWrap>
                    <Typography variant="button" classes={{ root: classes.bolt }}>
                        Email
                    </Typography>
                    <Typography variant="button" classes={{ root: classes.thin }}>
                        info@upmed.io
                    </Typography>
                </SimpleWrap>
            </ContractInfo>
        </Wrapper>
    );
};

export default MapMobile;
