import React, { useCallback, useEffect, useState } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { createStyles, FormControl, FormHelperText, Typography, withStyles, WithStyles } from '@material-ui/core';
import TextInput from '../../shared/input/TextInput';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as AuthActions } from '../../store/actions/auth.actions';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import LoadingTransparent from './SignUp';
import queryString from 'query-string';
import RequestState from '../../constants/requestState';
import { ValidationErrorsMap } from '../../models/validation/validationErrorsMap';
import { useDebounce } from 'use-debounce';
import fillClassInstanceWithValues from '../../shared/utils/fillClassInstanceWithValues';
import { validateSync } from 'class-validator';
import { SetNewPasswordDto } from '../../models/validation/SetNewPasswordValidation';

const styles = createStyles({
    heading: {
        fontSize: '24px',
    },
    hintText: {
        fontSize: '14px',
        color: colors.textSecondary,
        marginTop: '28px',
    },
    registerText: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    forgotPasswordLink: {
        textDecorationColor: colors.textPrimary,
    },
    registerLinkText: {
        color: colors.redMain,
        fontSize: '14px',
        marginLeft: '5px',
        '&:hover': {
            color: colors.redDark,
        },
    },
});

const BackgroundWrapper = styled.div`
    padding: 80px 0 57px;
    height: calc(100vh - 227px);

    @media screen and (orientation: landscape) {
        height: calc(100vh - 140px);
        padding: 28px 0 16px;
    }
`;

const Wrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 7% 35% 33%;
    align-items: end;
    margin: 0 auto;
    text-align: center;
    @media screen and (orientation: landscape) {
        grid-template-rows: 13% 48% 35%;
    }
`;

const ButtonWrapper = styled.div`
    width: fit-content;
    margin: 0 auto;
`;

const ErrorWrapper = styled.div`
    position: absolute;
`;

const InputWrapper = styled(FormControl)`
    padding-bottom: 25px !important;
`;

type StateModel = {
    loading: boolean;
    passwordChanged: RequestState;
};

type DispatchModel = {
    changePassword: typeof AuthActions.changePassword;
};

type PropsTypes = DispatchModel & StateModel & WithStyles<typeof styles> & RouteComponentProps<any>;

const userInitialState = {
    password: '',
    passwordConfirm: '',
    isBeingEdited: new Set(),
};

const ChangePassword: React.FC<PropsTypes> = props => {
    const [user, setUser] = useState(userInitialState);

    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    const isConfirmPasswordValid = useCallback(() => {
        if (user.password.length && user.passwordConfirm.length) {
            return user.password === user.passwordConfirm;
        }
        return true;
    },[user]);

    const validateForm = useCallback(
        (user: any, isOnSubmit = false) => {
            const item = fillClassInstanceWithValues<SetNewPasswordDto>(SetNewPasswordDto, user);
            const validationErrors = validateSync(item);
            const map: ValidationErrorsMap = {};
            if (validationErrors.length) {
                validationErrors.forEach(err => {
                    if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                        map[err.property] = Object.entries(err.constraints)
                            .map(([, value]) => value)
                            .join(', ');
                    }
                });
            }
            const newMap = isConfirmPasswordValid() ? map : { ...map, passwordConfirm: 'Passwords should be equal' };
            setValidationErrorsMap(newMap);
            return newMap;
        },
        [isConfirmPasswordValid]
    );

    useEffect(() => {
        validateForm(user);
    }, [user, validateForm]);

    const isValid = () => {
        const map = validateForm(user, true);
        return Object.entries(map).length === 0;
    };

    const changeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUser = {
            ...user,
            [e.target.id]: e.target.value,
            isBeingEdited: user.isBeingEdited.add(e.target.id),
        };
        if (e.target.value.length === 0) {
            newUser.isBeingEdited.delete(e.target.id);
        }
        setUser(newUser);
    };

    useEffect(() => {
        if (props.passwordChanged !== RequestState.UNSENT && props.passwordChanged !== RequestState.SENDING) {
            props.passwordChanged === RequestState.SENT_SUCCESS && props.history.push('/password-changed');
        }
    }, [props.passwordChanged, props.history]);

    const sendForm = () => {
        if (isValid()) {
            const token = queryString.parse(props.location.search).resetToken;
            if (!!token && typeof token === 'string') {
                props.changePassword({
                    newPassword: user.password,
                    resetToken: token,
                });
            }
        }
    };

    const handleEnter = (event: any) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const formArray = [...event.target.form];
            const index = formArray.indexOf(event.target);
            //check if next element is button
            if (index === formArray.length - 1) {
                sendForm();
            } else {
                form.elements[index + 1].focus();
            }
            event.preventDefault();
        }
    };

    const renderError = (field: keyof ValidationErrorsMap) => {
        if (!!validationErrorsMap[field]) {
            return (
                <ErrorWrapper>
                    <FormHelperText id="component-error-text">
                        {validationErrorsMap[field].split(',')[0]}
                    </FormHelperText>
                </ErrorWrapper>
            );
        }
    };

    const { classes } = props;
    return (
        <>
            <BackgroundWrapper>
                <SizeControllerWrapper>
                    <Wrapper>
                        <Typography variant="h2" classes={{ root: classes.heading }}>
                            Change Password
                        </Typography>
                        <form>
                            <InputWrapper error={!!validation.password}>
                                <TextInput
                                    placeholder={'New Password'}
                                    value={user.password}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        changeUser(evt);
                                    }}
                                    inputProps={{ id: 'password' }}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                    error={!!validation.password}
                                    password
                                />
                                {!!validation.password && renderError('password')}
                            </InputWrapper>
                            <InputWrapper error={!!validation.passwordConfirm}>
                                <TextInput
                                    placeholder={'Confirm Password'}
                                    value={user.passwordConfirm}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        changeUser(evt);
                                    }}
                                    inputProps={{ id: 'passwordConfirm' }}
                                    onKeyDown={(evt: any) => handleEnter(evt)}
                                    error={!!validation.passwordConfirm}
                                    password
                                />
                                {!!validation.passwordConfirm && renderError('passwordConfirm')}
                            </InputWrapper>
                        </form>
                        <ButtonWrapper>
                            <PrimaryButton onClick={sendForm}>Update Password</PrimaryButton>
                        </ButtonWrapper>
                    </Wrapper>
                </SizeControllerWrapper>
            </BackgroundWrapper>

            {props.loading && <LoadingTransparent />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.authorization.loading,
        passwordChanged: state.authorization.passwordChanged,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        changePassword: bindActionCreators(AuthActions.changePassword, dispatch),
    })
)(withStyles(styles)(withRouter(ChangePassword)));
