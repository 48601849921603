import {ActionsOfType, ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {PatientModel} from "../../models/responses/patient.response";
import {AllEntitiesByPagesDto} from "../../models/dto/allEntitiesByPages.dto";

export enum ProviderMyPatientsActionNames {
    PROVIDER_GET_ALL_MY_PATIENTS = '[Provider My Patients] Get All My Patients',
    PROVIDER_GET_ALL_MY_PATIENTS_UPDATE_SUCCESS = '[Provider My Patients] Get All My Patients Update Success',
    PROVIDER_GET_ALL_MY_PATIENTS_OVERRIDE_SUCCESS = '[Provider My Patients] Get All My Patients Override Success',
    PROVIDER_GET_ALL_MY_PATIENTS_FAILED = '[Provider My Patients] Get All My Patients Failed',

    PROVIDER_GET_MY_PENDING_PATIENTS = '[Provider My Patients] Get My Pending Patients',
    PROVIDER_GET_MY_PENDING_PATIENTS_UPDATE_SUCCESS = '[Provider My Patients] Get My Pending Patients Update Success',
    PROVIDER_GET_MY_PENDING_PATIENTS_OVERRIDE_SUCCESS = '[Provider My Patients] Get My Pending Patients Override Success',
    PROVIDER_GET_MY_PENDING_PATIENTS_FAILED = '[Provider My Patients] Get My Pending Patients Failed',

    PROVIDER_GET_MY_INVITED_PATIENTS = '[Provider My Patients] Get My Invited Patients',
    PROVIDER_GET_MY_INVITED_PATIENTS_UPDATE_SUCCESS = '[Provider My Patients] Get My Invited Patients Update Success',
    PROVIDER_GET_MY_INVITED_PATIENTS_OVERRIDE_SUCCESS = '[Provider My Patients] Get My Invited Patients Override Success',
    PROVIDER_GET_MY_INVITED_PATIENTS_FAILED = '[Provider My Patients] Get My Pending Invited Failed',

    INCREASE_PAGE_NUMBER_LINKED_PATIENTS = '[Provider My Patients] Increase Page Number Linked Patients',
    INCREASE_PAGE_NUMBER_PENDING_PATIENTS = '[Provider My Patients] Increase Page Number Pending Patients',
    INCREASE_PAGE_NUMBER_INVITED_PATIENTS = '[Provider My Patients] Increase Page Number Invited Patients',

    SET_FILTER_QUERY_LINKED_PATIENTS = '[Provider My Patients] Set Filter Query Linked Patients',
    SET_FILTER_QUERY_PENDING_PATIENTS = '[Provider My Patients] Set Filter Query Pending Patients',
    SET_FILTER_QUERY_INVITED_PATIENTS = '[Provider My Patients] Set Filter Query Invited Patients',

    SET_PATIENT_PAGE_TAB_INDEX = '[Provider My Patients] Set Patient Page Tab Index',
}

export const ProviderMyPatientActions = {
    getAllLinkedPatients: (data: AllEntitiesByPagesDto) => createAction(ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS, data),
    getAllLinkedPatientsUpdateSuccess: (data: PatientModel[], total: number) =>
        createAction(ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS_UPDATE_SUCCESS, {data, total}),
    getAllLinkedPatientsOverrideSuccess: (data: PatientModel[], total: number) =>
        createAction(ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS_OVERRIDE_SUCCESS, {data, total}),
    getAllLinkedPatientsFailed: () => createAction(ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS_FAILED),
    
    getAllPendingPatients: (data: AllEntitiesByPagesDto) => createAction(ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS, data),
    getAllPendingPatientsUpdateSuccess: (data: PatientModel[], total: number) =>
        createAction(ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS_UPDATE_SUCCESS, {data, total}),
    getAllPendingPatientsOverrideSuccess: (data: PatientModel[], total: number) =>
        createAction(ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS_OVERRIDE_SUCCESS, {data, total}),
    getAllPendingPatientsFailed: () => createAction(ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS_FAILED),

    getAllInvitedPatients: (data: AllEntitiesByPagesDto) => createAction(ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS, data),
    getAllInvitedPatientsUpdateSuccess: (data: PatientModel[], total: number) =>
        createAction(ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS_UPDATE_SUCCESS, {data, total}),
    getAllInvitedPatientsOverrideSuccess: (data: PatientModel[], total: number) =>
        createAction(ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS_OVERRIDE_SUCCESS, {data, total}),
    getAllInvitedPatientsFailed: () => createAction(ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS_FAILED),

    increasePageNumberLinkedPatients: () => createAction(ProviderMyPatientsActionNames.INCREASE_PAGE_NUMBER_LINKED_PATIENTS),
    increasePageNumberPendingPatients: () => createAction(ProviderMyPatientsActionNames.INCREASE_PAGE_NUMBER_PENDING_PATIENTS),
    increasePageNumberInvitedPatients: () => createAction(ProviderMyPatientsActionNames.INCREASE_PAGE_NUMBER_INVITED_PATIENTS),

    setFilterQueryLinkedPatients: (searchQuery: string) => createAction(ProviderMyPatientsActionNames.SET_FILTER_QUERY_LINKED_PATIENTS, searchQuery),
    setFilterQueryPendingPatients: (searchQuery: string) => createAction(ProviderMyPatientsActionNames.SET_FILTER_QUERY_PENDING_PATIENTS, searchQuery),
    setFilterQueryInvitedPatients: (searchQuery: string) => createAction(ProviderMyPatientsActionNames.SET_FILTER_QUERY_INVITED_PATIENTS, searchQuery),

    setPatientPageTabIndex: (tabIndex: number) => createAction(ProviderMyPatientsActionNames.SET_PATIENT_PAGE_TAB_INDEX, tabIndex),
};

export type ProviderMyPatientActions = ActionsUnion<typeof ProviderMyPatientActions>;

export type getAllLinkedPatientsType = ActionsOfType<ProviderMyPatientActions, ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS>;
export type getAllPendingPatientsType = ActionsOfType<ProviderMyPatientActions, ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS>;
export type getAllInvitedPatientsType = ActionsOfType<ProviderMyPatientActions, ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS>;
