import React, {useCallback} from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import styled from "styled-components";
import defaultAvatar from "../../../static/icons/defaultAvatar.svg";
import {ProviderModel} from "../../../models/responses/provider.response";
import AvatarShared from "../../shared/AvatarShared";
import {colors} from "../../../theme/colors";
import {PatientModel} from "../../../models/responses/patient.response";

const AvatarBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
`;

const createStyles = makeStyles({
    providerName: {
        fontSize: '14px',
        lineHeight: '16px',
        color: colors.textSecondary,
        marginLeft: '14px',
    },
});

const UserAvatar: React.FC<{user: ProviderModel | PatientModel}> = (props) => {

    const {user} = props;
    const classes = createStyles(props);

    const getAvatar = useCallback(() => {
        return user.avatarThumbnailUrl ? user.avatarThumbnailUrl : defaultAvatar;
    }, [user.avatarThumbnailUrl]);

    return (
        <AvatarBlock>
            <AvatarShared img={getAvatar()} />
            <Typography variant={'body1'} classes={{ root: classes.providerName }}>
                {`${user.firstName} ${user.lastName}`}
            </Typography>
        </AvatarBlock>
    )
};

export default UserAvatar;