export enum PatientStepClassName {
    WELCOME = 'welcome-t',
    PROFILE = 'profile-t',
    CHOOSE_PROVIDER = 'choose-provider-t',
    VIEW_PROVIDER = 'view-provider-t',
    SEND_REQUEST = 'send-request-t',
    NOTIFICATION = 'notification-t',
    NOTIFICATIONS = 'notifications-t',
    PAYMENT = 'payment-t',
    FINISH = 'finish-t',
}