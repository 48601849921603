import {displayingInvoiceType} from "../../shared/utils/nameForTypeInvoice";

export enum InvoiceType {
    ONE_TIME = 'ONE_TIME',
    SUBSCRIPTIONS = 'SUBSCRIPTIONS',
}
export const InvoiceTypes = [
    { name: displayingInvoiceType(InvoiceType.ONE_TIME), id: 0 },
    { name: displayingInvoiceType(InvoiceType.SUBSCRIPTIONS), id: 1 },
];
