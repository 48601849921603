import {ActionsOfType, ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {SignUpDTO} from "../../models/dto/signUp.dto";
import {VerificationEmailDto} from "../../models/dto/verificationEmail.dto";
import {SignInDTO} from "../../models/dto/signIn.dto";
import {SignInModel} from "../../models/responses/signIn.response";
import {resetPasswordDTO} from "../../models/dto/resetPassword.dto";
import {newPasswordDTO} from "../../models/dto/newPassword.dto";
import {UserModel} from "../../models/responses/user.response";
import AuthState from "../../constants/authState";
import {AcceptInvitationDto} from "../../models/dto/acceptInvitation.dto";
import { ChangePasswordDto } from '../../models/dto/changePassword.dto';

export enum ActionNames {

    SET_AUTH_STATUS = '[Authorizations] Set Auth Status',

    SIGN_UP = '[Authorization] Sign Up',
    SIGN_UP_SUCCESS = '[Authorization] Sign Up Success',
    SIGN_UP_FAILED = '[Authorization] Sign Up Failed',

    VERIFY_EMAIL = '[Authorization] Verify Email',
    VERIFY_EMAIL_SUCCESS = '[Authorization] Verify Email Success',
    VERIFY_EMAIL_FAILED = '[Authorization] Verify Email Failed',

    RESEND_VERIFICATION_LINK = '[Authorization] Resend Verification Link',
    RESEND_VERIFICATION_LINK_SUCCESS = '[Authorization] Resend Verification Link Success',
    RESEND_VERIFICATION_LINK_FAILED = '[Authorization] Resend Verification Link Failed',

    SIGN_IN =  '[Authorization] Sign In',
    SIGN_IN_SUCCESS =  '[Authorization] Sign In Success',
    SIGN_IN_FAILED =  '[Authorization] Sign In Failed',

    RESET_PASSWORD = '[Authorization] Reset Password',
    RESET_PASSWORD_SUCCESS = '[Authorization] Reset Password Success',
    RESET_PASSWORD_FAILED = '[Authorization] Reset Password Failed',

    CHANGE_PASSWORD = '[Authorization] Change Password',
    CHANGE_PASSWORD_SUCCESS = '[Authorization] Change Password Success',
    CHANGE_PASSWORD_FAILED = '[Authorization] Change Password Failed',

    ACCEPT_INVITATION = '[Authorization] Accept Invitation',
    ACCEPT_INVITATION_SUCCESS = '[Authorization] Accept Invitation Success',
    ACCEPT_INVITATION_FAILED = '[Authorization] Accept Invitation Failed',

    RESET_STATUS_VARIABLES = '[Authorization] Reset Status Variables',

    GET_CURRENT_USER = '[Authorization] Get Current User',
    SET_CURRENT_USER = '[Authorization] SET_CURRENT_USER',
    GET_CURRENT_USER_FAILED = '[Authorization] Get Current User Failed',

    CHANGE_CURRENT_PASSWORD = '[Authorization] Change Current Password',
    CHANGE_CURRENT_PASSWORD_SUCCESS = '[Authorization] Change Current Password Success',
    CHANGE_CURRENT_PASSWORD_FAILED = '[Authorization] Change Current Password Failed',

    REMOVE_CURRENT_USER = '[Authorization] REMOVE_CURRENT_USER',
}

export const Actions = {
    setAuthStatus: (status: AuthState) => createAction(ActionNames.SET_AUTH_STATUS, status),

    signUp: (user: SignUpDTO) => createAction(ActionNames.SIGN_UP, user),
    signUpSuccess: () => createAction(ActionNames.SIGN_UP_SUCCESS),
    signUpFailed: () => createAction(ActionNames.SIGN_UP_FAILED),

    verifyEmail: (verificationToken: VerificationEmailDto) => createAction(ActionNames.VERIFY_EMAIL, verificationToken),
    verifyEmailSuccess: (user: UserModel) => createAction(ActionNames.VERIFY_EMAIL_SUCCESS, user),
    verifyEmailFailed: () => createAction(ActionNames.VERIFY_EMAIL_FAILED),

    resendVerificationLink: (email: string) => createAction(ActionNames.RESEND_VERIFICATION_LINK, email),
    resendVerificationLinkSuccess: () => createAction(ActionNames.RESEND_VERIFICATION_LINK_SUCCESS),
    resendVerificationLinkFailed: () => createAction(ActionNames.RESEND_VERIFICATION_LINK_FAILED),

    signIn: (user: SignInDTO) => createAction(ActionNames.SIGN_IN, user),
    signInSuccess: (data: SignInModel) => createAction(ActionNames.SIGN_IN_SUCCESS, data),
    signInFailed: () => createAction(ActionNames.SIGN_IN_FAILED),

    resetPassword: (data: resetPasswordDTO) => createAction(ActionNames.RESET_PASSWORD, data),
    resetPasswordSuccess: () => createAction(ActionNames.RESET_PASSWORD_SUCCESS),
    resetPasswordFalied: () => createAction(ActionNames.RESET_PASSWORD_FAILED),

    changePassword: (data: newPasswordDTO) => createAction(ActionNames.CHANGE_PASSWORD, data),
    changePasswordSuccess: () => createAction(ActionNames.CHANGE_PASSWORD_SUCCESS),
    changePasswordFailed: () => createAction(ActionNames.CHANGE_PASSWORD_FAILED),

    acceptInvitation: (data: AcceptInvitationDto) => createAction(ActionNames.ACCEPT_INVITATION, data),
    acceptInvitationSuccess: () => createAction(ActionNames.ACCEPT_INVITATION_SUCCESS),
    acceptInvitationFailed: () => createAction(ActionNames.ACCEPT_INVITATION_FAILED),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),

    getCurrentUser: () => createAction(ActionNames.GET_CURRENT_USER),
    setCurrentUser: (user: UserModel) => createAction(ActionNames.SET_CURRENT_USER, user),
    getCurrentUserFailed: () => createAction(ActionNames.GET_CURRENT_USER_FAILED),

    changeCurrentPassword: (data: ChangePasswordDto) => createAction(ActionNames.CHANGE_CURRENT_PASSWORD, data),
    changeCurrentPasswordSuccess: () => createAction(ActionNames.CHANGE_CURRENT_PASSWORD_SUCCESS),
    changeCurrentPasswordFailed: () => createAction(ActionNames.CHANGE_CURRENT_PASSWORD_FAILED),

    removeCurrentUser: () => createAction(ActionNames.REMOVE_CURRENT_USER),
};

export type Actions = ActionsUnion<typeof Actions>;

export type signUpType = ActionsOfType<Actions, ActionNames.SIGN_UP>;
export type verifyEmailType = ActionsOfType<Actions, ActionNames.VERIFY_EMAIL>;
export type resendVerificationLink = ActionsOfType<Actions, ActionNames.RESEND_VERIFICATION_LINK>;
export type signInType = ActionsOfType<Actions, ActionNames.SIGN_IN>;
export type resetPassword = ActionsOfType<Actions, ActionNames.RESET_PASSWORD>;
export type changePassword = ActionsOfType<Actions, ActionNames.CHANGE_PASSWORD>;
export type acceptInvitationType = ActionsOfType<Actions, ActionNames.ACCEPT_INVITATION>;
export type resetStatusVariables = ActionsOfType<Actions, ActionNames.RESET_STATUS_VARIABLES>;
export type setCurrentUserType = ActionsOfType<Actions, ActionNames.SET_CURRENT_USER>;
export type chnageCurrentPasswordType = ActionsOfType<Actions, ActionNames.CHANGE_CURRENT_PASSWORD>;
