import { put, call, takeEvery } from 'redux-saga/effects';
import * as panelTypeActions from '../../actions/panelType.actions';
import PanelTypeApi from "../../../api/panelType/PanelTypeApi";

function* getPanelTypes() {
    try {
        const response = yield call(PanelTypeApi.getPanelTypes);
        yield put({ type: panelTypeActions.ActionNames.GET_PANEL_TYPES_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: panelTypeActions.ActionNames.GET_PANEL_TYPES_FAILED });
    }
}

function* getPanelTypesWithTargets() {
    try {
        const response = yield call(PanelTypeApi.getPanelTypesWithBloodTargets);
        yield put({ type: panelTypeActions.ActionNames.GET_PANEL_TYPES_WITH_TARGETS_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: panelTypeActions.ActionNames.GET_PANEL_TYPES_WITH_TARGETS_FAILED });
    }
}

function* editPanelType(action: panelTypeActions.editPanelTypeType) {
    try {
        const response = yield call(PanelTypeApi.editPanelType, action.payload);
        yield put({ type: panelTypeActions.ActionNames.EDIT_PANEL_TYPE_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: panelTypeActions.ActionNames.EDIT_PANEL_TYPE_FAILED });
    }
}

function* removePanelType(action: panelTypeActions.removePanelTypeType) {
    try {
        const response = yield call(PanelTypeApi.removePanelType, action.payload);
        yield put({ type: panelTypeActions.ActionNames.REMOVE_PANEL_TYPE_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: panelTypeActions.ActionNames.REMOVE_PANEL_TYPE_FAILED });
    }
}

function* addPanelType(action: panelTypeActions.addPanelTypeType) {
    try {
        const response = yield call(PanelTypeApi.addPanelType, action.payload);
        yield put({ type: panelTypeActions.ActionNames.ADD_PANEL_TYPE_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: panelTypeActions.ActionNames.ADD_PANEL_TYPE_FAILED });
    }
}


export default function* BloodTargetSagas() {
    yield takeEvery(panelTypeActions.ActionNames.GET_PANEL_TYPES, getPanelTypes);
    yield takeEvery(panelTypeActions.ActionNames.GET_PANEL_TYPES_WITH_TARGETS, getPanelTypesWithTargets);
    yield takeEvery(panelTypeActions.ActionNames.EDIT_PANEL_TYPE, editPanelType);
    yield takeEvery(panelTypeActions.ActionNames.REMOVE_PANEL_TYPE, removePanelType);
    yield takeEvery(panelTypeActions.ActionNames.ADD_PANEL_TYPE, addPanelType);
}
