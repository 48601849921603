import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { CompatClient } from '@stomp/stompjs/esm5/compatibility/compat-client';
import { NotificationModel } from '../../models/responses/notification.responce';
import { GetPatientNotificationsDto } from '../../models/dto/getPatientNotifications.dto';

export enum NotificationsActionNames {
    WS_RECEIVED_NOTIFICATION = '[WS Notifications] Received',

    MARK_AS_READ = '[WS Notifications] Mark As Read',
    MARK_AS_READ_SUCCESSFULL = '[WS Notifications] Mark As Read Successful',
    MARK_AS_READ_FAILED = '[WS Notifications] Mark As Read Failed',

    MARK_ONE_AS_READ = '[WS Notifications] Mark One As Read',

    WS_CONNECTION_OPENED = '[WS Notifications] Opened',

    SET_PATIENT = '[WS Notifications] Set Patient',

    GET_NOTIFICATIONS_BY_PATIENT = '[WS Notifications] Get Notifications By Patient',
    GET_NOTIFICATIONS_BY_PATIENT_OVERRIDE_SUCCESS = '[WS Notifications] Get Notifications By Patient Override Success',
    GET_NOTIFICATIONS_BY_PATIENT_UPDATE_SUCCESS = '[WS Notifications] Get Notifications By Patient Update Success',
    GET_NOTIFICATIONS_BY_PATIENT_FAILED = '[WS Notifications] Get Notifications By Patient Failed',

    RESET_PATIENT_NOTIFICATIONS = '[WS Notifications] Reset Patient Notifications',

    INCREASE_PAGE_NUMBER = '[WS Notifications] Increase Page Number',
}

export const NotificationActions = {
    wsReceived: (msg: NotificationModel[]) => createAction(NotificationsActionNames.WS_RECEIVED_NOTIFICATION, msg),
    markAsRead: () => createAction(NotificationsActionNames.MARK_AS_READ),
    markAsReadSuccessful: () => createAction(NotificationsActionNames.MARK_AS_READ_SUCCESSFULL),
    markAsReadFailed: () => createAction(NotificationsActionNames.MARK_AS_READ_FAILED),

    markOneAsRead: (id: number) => createAction(NotificationsActionNames.MARK_ONE_AS_READ, id),

    openedWsConnection: (data: CompatClient) => createAction(NotificationsActionNames.WS_CONNECTION_OPENED, data),

    setPatient: (data: number | null) => createAction(NotificationsActionNames.SET_PATIENT, data),

    getNotificationsByPatient: (data: GetPatientNotificationsDto) =>
        createAction(NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT, data),
    getNotificationsByPatientOverrideSuccess: (data: {result: NotificationModel[], total: number}) =>
        createAction(NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_OVERRIDE_SUCCESS, data),
    getNotificationsByPatientUpdateSuccess: (data: {result: NotificationModel[], total: number}) =>
        createAction(NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_UPDATE_SUCCESS, data),
    getNotificationsByPatientFailed: () => createAction(NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT_FAILED),

    resetPatientNotifications: () => createAction(NotificationsActionNames.RESET_PATIENT_NOTIFICATIONS),

    increasePageNumber: () => createAction(NotificationsActionNames.INCREASE_PAGE_NUMBER),
};

export type NotificationActions = ActionsUnion<typeof NotificationActions>;

export type wsReceived = ActionsOfType<NotificationActions, NotificationsActionNames.WS_RECEIVED_NOTIFICATION>;
export type markAsRead = ActionsOfType<NotificationActions, NotificationsActionNames.MARK_AS_READ>;
export type markOneAsReadType = ActionsOfType<NotificationActions, NotificationsActionNames.MARK_ONE_AS_READ>;
export type getNotificationsByPatientType = ActionsOfType<
    NotificationActions,
    NotificationsActionNames.GET_NOTIFICATIONS_BY_PATIENT
>;
