import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { Actions as BloodTargetActions } from '../../../store/actions/bloodTarget.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { BloodtargetReponce } from '../../../models/responses/bloodtarget.reponce';
import DropdownInputListBloodTarget from '../DropdownInputListBloodTargets';
import { useDebounce } from 'use-debounce';
import { DefaultPageSize, shouldFetchData } from '../../../constants/dataFetching/allEntitiesByPages';
import { useCompare } from '../../../helpers/usePreviousHelper';
import {Row} from "../../../pages/bloodwork/AddBloodwork";

interface InjectedProps {
    onChange: (value: BloodtargetReponce | string | null) => void;
    label: string;
    rowId: number;
    existingOptions: Row[];
}

interface StateModel {
    loading: boolean;
    allBloodTargets: BloodtargetReponce[];
    totalBloodTargets: number | null;
    page: number;
    searchQuery: string;
}

interface DispatchModel {
    increasePageNumber: typeof BloodTargetActions.increasePageNumber;
    getAllBloodTargets: typeof BloodTargetActions.getAllBloodTargets;
    setFilterQuery: typeof BloodTargetActions.setSearchQuery;
}

type PropsTypes = InjectedProps & DispatchModel & StateModel;

const DropdownInputBloodTargetListWrapper: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);

    const {
        totalBloodTargets,
        rowId,
        getAllBloodTargets,
        existingOptions,
        allBloodTargets,
        searchQuery,
        increasePageNumber,
        page,
        label,
        setFilterQuery,
        loading,
        onChange,
    } = props;

    const [localSearchQuery] = useDebounce(searchQuery, 300);
    const isSearchQueryChanged = useCompare(localSearchQuery);

    const loadData = useCallback(
        (page: number, size: number, sort: string, searchQuery: string, overrideRecords: boolean) => {
            getAllBloodTargets({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllBloodTargets]
    );

    useEffect(() => {
        setFilterQuery('');
        loadData(0, DefaultPageSize, '', '', true);
        setInitialLoaded(true);
    }, [setFilterQuery, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(loading, page, allBloodTargets.length, initialLoaded, DefaultPageSize) ||
            isSearchQueryChanged
        ) {
            loadData(page, 10, '', localSearchQuery, false);
        }
    }, [page, loadData, loading, allBloodTargets, initialLoaded, localSearchQuery, isSearchQueryChanged]);

    return (
        <DropdownInputListBloodTarget
            onChange={onChange}
            rowId={rowId}
            existingOptions={existingOptions}
            label={label}
            loading={loading}
            allEntities={allBloodTargets}
            totalEntities={totalBloodTargets}
            searchQuery={searchQuery}
            increasePageNumber={increasePageNumber}
            setFilterQuery={setFilterQuery}
        />
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.bloodTarget.loading,
        allBloodTargets: state.bloodTarget.allBloodTargets,
        totalBloodTargets: state.bloodTarget.totalBloodTargets,
        page: state.bloodTarget.page,
        searchQuery: state.bloodTarget.searchQuery,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllBloodTargets: bindActionCreators(BloodTargetActions.getAllBloodTargets, dispatch),
        increasePageNumber: bindActionCreators(BloodTargetActions.increasePageNumber, dispatch),
        setFilterQuery: bindActionCreators(BloodTargetActions.setSearchQuery, dispatch),
    })
)(DropdownInputBloodTargetListWrapper);
