import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProviderMyPatientActions } from '../../store/actions/providerMyPatients.actions';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import ScrollableList from '../../shared/scrollable-list/ScrollableList';
import { PatientModel } from '../../models/responses/patient.response';
import { SortOptions } from '../../constants/sortOptions';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers/index';
import NoPatients from './NoPatients';
import PatientTypes from '../../constants/patientTypes';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../constants/layout';
import {DefaultPageSize, shouldFetchData} from "../../constants/dataFetching/allEntitiesByPages";

interface DispatchModel {
    increasePageNumberPendingPatients: typeof ProviderMyPatientActions.increasePageNumberPendingPatients;
    getAllPendingPatients: typeof ProviderMyPatientActions.getAllPendingPatients;
    setFilterQueryPendingPatients: typeof ProviderMyPatientActions.setFilterQueryPendingPatients;
    resetStatusVariables: typeof PatientActions.resetStatusVariables;
}

interface StoreModel {
    myPendingPatients: PatientModel[];
    totalPendingPatients: number;
    pagePendingPatients: number;
    searchQueryPendingPatients: string;
    loading: boolean;
}

type PropsTypes = StoreModel & DispatchModel;

function PendingPatientsList(props: PropsTypes) {
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);
    const {
        loading,
        resetStatusVariables,
        getAllPendingPatients,
        increasePageNumberPendingPatients,
        myPendingPatients,
        pagePendingPatients,
        searchQueryPendingPatients,
        setFilterQueryPendingPatients,
        totalPendingPatients,
    } = props;

    const loadPendingPatients = useCallback(
        (page: number, size: number, sort: SortOptions, q: string, override: boolean) => {
            getAllPendingPatients({
                page,
                size,
                sort,
                searchQuery: q,
                overrideRecords: override,
            });
        },
        [getAllPendingPatients]
    );

    useEffect(() => {
        resetStatusVariables();
        setFilterQueryPendingPatients('');
        loadPendingPatients(0, 10, SortOptions.LAST_NAME_ASCENDING, '', true);
        setInitialLoaded(true);
    }, [resetStatusVariables, setFilterQueryPendingPatients, loadPendingPatients]);

    useEffect(() => {
        if (shouldFetchData(loading, pagePendingPatients, myPendingPatients.length, initialLoaded, DefaultPageSize) ||
            (searchQueryPendingPatients && initialLoaded)
        ) {
            loadPendingPatients(
                pagePendingPatients,
                DefaultPageSize,
                SortOptions.LAST_NAME_ASCENDING,
                searchQueryPendingPatients,
                false
            );
        }
    }, [
        pagePendingPatients,
        searchQueryPendingPatients,
        loadPendingPatients,
        loading,
        initialLoaded,
        myPendingPatients,
    ]);

    const getMorePendingPatients = () => {
        increasePageNumberPendingPatients();
    };

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    return (
        <>
            {!loading && totalPendingPatients === 0 && !searchQueryPendingPatients.length ? (
                <NoPatients type={PatientTypes.PENDING} height={height} />
            ) : (
                <ScrollableList
                    isLoading={loading}
                    totalRecordsNumber={totalPendingPatients}
                    data={myPendingPatients}
                    nextAction={loading ? () => {} : getMorePendingPatients}
                    height={height}
                    type={'patient'}
                />
            )}
        </>
    );
}

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        myPendingPatients: state.providerMyPatients.myPendingPatients,
        totalPendingPatients: state.providerMyPatients.totalPendingPatients,
        pagePendingPatients: state.providerMyPatients.pagePendingPatients,
        searchQueryPendingPatients: state.providerMyPatients.searchQueryPendingPatients,
        loading: state.providerMyPatients.loading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllPendingPatients: bindActionCreators(ProviderMyPatientActions.getAllPendingPatients, dispatch),
        setFilterQueryPendingPatients: bindActionCreators(
            ProviderMyPatientActions.setFilterQueryPendingPatients,
            dispatch
        ),
        increasePageNumberPendingPatients: bindActionCreators(
            ProviderMyPatientActions.increasePageNumberPendingPatients,
            dispatch
        ),
        resetStatusVariables: bindActionCreators(PatientActions.resetStatusVariables, dispatch),
    })
)(PendingPatientsList);
