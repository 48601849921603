import { restAxios } from '../restAxios';
import { SignUpDTO } from '../../models/dto/signUp.dto';
import { SignInDTO } from '../../models/dto/signIn.dto';
import { resetPasswordDTO } from '../../models/dto/resetPassword.dto';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { AcceptInvitationDto } from '../../models/dto/acceptInvitation.dto';
import { SignUpValid } from '../../models/dtoValidation/signUp.response';
import { ChangePasswordDto } from '../../models/dto/changePassword.dto';

const signInResponsesMap = new Map<number, string>();
signInResponsesMap.set(500, 'Something went wrong');
signInResponsesMap.set(404, 'Not Found');

class AuthApi {
    @HandleAsyncErrors()
    static async getCurrentUser() {
        const res = await restAxios.get('auth/validate-token');
        return new SignUpValid(res);
    }

    @HandleAsyncErrors({ handleOnlyHttpErrors: false, messageMapping: signInResponsesMap, ignoreError: false })
    static async signUp(body: SignUpDTO) {
        const res = await restAxios.post('auth/sign-up', body);
        return new SignUpValid(res);
    }

    @HandleAsyncErrors()
    static async verifyEmail(body: any) {
        return await restAxios.put('auth/email-verification', body);
    }

    @HandleAsyncErrors()
    static async resendVerificationLink(email: string) {
        return await restAxios.get(`auth/resend-verification-email?email=${email}`);
    }

    @HandleAsyncErrors({ handleOnlyHttpErrors: false, messageMapping: signInResponsesMap, ignoreError: false })
    static async signIn(body: SignInDTO) {
        return await restAxios.post('auth/sign-in', body);
    }

    @HandleAsyncErrors()
    static async resetPassword(body: resetPasswordDTO) {
        return await restAxios.post('auth/reset-password', body);
    }

    @HandleAsyncErrors()
    static async changePassword(body: any) {
        return await restAxios.post('auth/new-password', body);
    }

    @HandleAsyncErrors()
    static async acceptInvitation(body: AcceptInvitationDto) {
        return await restAxios.put('auth/accept-invitation', body);
    }

    @HandleAsyncErrors()
    static async changeCurrentPassword(body: ChangePasswordDto) {
        return await restAxios.post('private/auth/change-password', body);
    }
}

export default AuthApi;
