import React from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { CircularProgress, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';
import styled from 'styled-components';

const styles = createStyles({
    spinner: {
        margin: '0 auto',
        color: colors.redMain,
        height: '50px !important',
        width: '50px !important',
    },
});

const Wrapper = styled.div<any>`
    height: ${props => (props.smallSize ? '200px' : '100vh')};
    display: flex;
    align-items: center;
`;

interface InjectedProps {
    smallSize?: boolean;
    notCentered?: boolean;
}

type PropsTypes = InjectedProps & WithStyles<typeof styles>;

const Loading: React.FC<PropsTypes> = props => {
    const { classes, notCentered } = props;
    return notCentered ? (
        <Wrapper smallSize={props.smallSize}>
            <CircularProgress classes={{ root: classes.spinner }} />
        </Wrapper>
    ) : (
        <SizeControllerWrapper>
            <Wrapper smallSize={props.smallSize}>
                <CircularProgress classes={{ root: classes.spinner }} />
            </Wrapper>
        </SizeControllerWrapper>
    );
};

export default withStyles(styles)(Loading);
