import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import MainPage from '../pages/landing/main/MainPage';
import SignIn from '../pages/auth/SignIn';
import VerificationEmailLinkSent from '../pages/auth/success/VerificationEmailLinkSent';
import SignUp from '../pages/auth/SignUp';
import RecoverPassword from '../pages/auth/RecoverPassword';
import EmailVerified from '../pages/auth/success/EmailVerified';
import EmailVerificationPage from '../pages/auth/EmailVerificationPage';
import ResetPasswordLinkSent from '../pages/auth/success/ResetPasswordLinkSent';
import ChangePassword from '../pages/auth/ChangePassword';
import PasswordChanged from '../pages/auth/success/PasswordChanged';
import AcceptInvite from '../pages/auth/AcceptInvitationPage';
import ProviderPage from '../pages/landing/provider/ProviderPage';
import PatientPage from '../pages/landing/patient/PateintPage';
import Solutions from '../pages/landing/solutions/Solutions';
import Resources from '../pages/landing/resources/Resources';
import AboutPage from '../pages/landing/about/AboutPage';
import Header from '../shared/header/Header';
import SidebarMenu from '../shared/sidebar/SidebarMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../constants/device';

interface Props {
    sidebarOpen: boolean;
    toggleSidebar: () => void;
}

type PropsTypes = Props;

const UnauthorizedRoutes: React.FC<PropsTypes> = ({ sidebarOpen, toggleSidebar }) => {
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    return (
        <>
            <Header />
            {sidebarOpen && !isLaptop && <SidebarMenu open={sidebarOpen} closeSidebar={toggleSidebar} />}
            <Switch>
                <Route exact path={'/'} component={MainPage} />
                <Route exact path={'/about'} component={AboutPage} />
                <Route exact path={'/provider'} component={ProviderPage} />
                <Route exact path={'/patient'} component={PatientPage} />
                <Route exact path={'/solutions'} component={Solutions} />
                <Route exact path={'/resources'} component={Resources} />

                <Route exact path={'/sign-in'} component={SignIn} />
                <Route exact path={'/sign-up'} component={SignUp} />

                <Route exact path={'/email-sent'} component={VerificationEmailLinkSent} />
                <Route exact path={'/reset-password-sent'} component={ResetPasswordLinkSent} />
                <Route exact path={'/password-changed'} component={PasswordChanged} />

                <Route exact path={'/change-password'} component={ChangePassword} />

                <Route exact path={'/recover-password'} component={RecoverPassword} />
                <Route exact path={'/email-verified'} component={EmailVerified} />
                <Route exact path={'/email-verification'} component={EmailVerificationPage} />

                <Route exact path={'/patient/accept-invite'} component={AcceptInvite} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </>
    );
};

export default UnauthorizedRoutes;