import React, { useEffect, useMemo } from 'react';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers/index';
import { Actions as InvoiceActions } from '../../../store/actions/invoice.actions';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../../shared/Loading';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

const createStyles = makeStyles({
    link: {
        textDecoration: 'none',
    },
});

const Wrapper = styled.div`
    margin-top: 32px;
`;

interface StoreModel {
    connectedAccountLink: string | null;
    connectedAccountLinkLoading: boolean;
}

interface DispatchModel {
    getConnectedAccountLink: typeof InvoiceActions.getConnectedAccountLink;
}

type PropsTypes = StoreModel & DispatchModel;

const ProviderAccount: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const { connectedAccountLink, connectedAccountLinkLoading, getConnectedAccountLink } = props;

    useEffect(() => {
        getConnectedAccountLink();
    }, [getConnectedAccountLink]);

    const canRedirect = useMemo(() => {
        return !connectedAccountLinkLoading && !!connectedAccountLink;
    }, [connectedAccountLinkLoading, connectedAccountLink]);

    return (
        <div>
            {connectedAccountLinkLoading && <Loading smallSize />}
            {canRedirect && (
                <Wrapper>
                    <SizeControllerWrapper>
                        <a
                            href={connectedAccountLink!}
                            rel="noopener noreferrer nofollow"
                            target="_blank"
                            className={classes.link}
                        >
                            <PrimaryButton >{props.children}</PrimaryButton>
                        </a>
                    </SizeControllerWrapper>
                </Wrapper>
            )}
        </div>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        connectedAccountLink: state.invoice.connectedAccountLink,
        connectedAccountLinkLoading: state.invoice.connectedAccountLinkLoading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getConnectedAccountLink: bindActionCreators(InvoiceActions.getConnectedAccountLink, dispatch),
    })
)(ProviderAccount);
