import * as analyticsActions from '../../actions/analytics.actions';
import { AnalyticsModel } from '../../../models/responses/analytics.responce';
import { DrugInAnalytics } from '../../../models/responses/drugInAnalytics.model';
import { BloodTargetInAnalytics } from '../../../models/responses/bloodworkInAnalytics.model';
import { BloodworkResponse } from '../../../models/responses/bloodwork.response';
import { GetPrescriptionByIdDto } from '../../../models/dto/getPrescriptionById.dto';
import { BloodTargetGraphData, BloodTargetInGraphModel } from '../../../models/responses/bloodTargetInGraph.model';
import moment from 'moment';
import randomColor from 'randomcolor';
import RequestState from '../../../constants/requestState';

export interface DataRange {
    startDate: string;
    endDate: string;
}

export const SELECT_ALL = 'Select all';

export class StateModel {
    analyticsInfo: AnalyticsModel | null = null;
    analyticsResult: AnalyticsModel | null = null;
    loadingRequestState: RequestState = RequestState.UNSENT;
    drugsList: DrugInAnalytics[] = [];
    bloodTargetsList: BloodTargetInAnalytics[] = [];

    drugsListResult: DrugInAnalytics[] = [];
    bloodTargetsListResult: BloodTargetInGraphModel | null = null;
    showAnalytics: boolean = false;

    date: DataRange | null = null;
}

export const initialState = new StateModel();

const convertToDrugInAnalytics = (
    prescriptions: GetPrescriptionByIdDto[],
    date: DataRange | null,
    leaveDuplicates: boolean = false
) => {
    let drugsList: DrugInAnalytics[] = [];
    prescriptions.forEach(prescription =>
        prescription.prescriptionComponentList.forEach(component => {
            if (component && component.drug) {
                const sameItems = drugsList.filter(t => t.id === component.drug!.id);
                (!sameItems.length || leaveDuplicates) &&
                    drugsList.push({
                        id: component.drug.id,
                        name: component.drug.name,
                        date: [
                            moment(new Date(prescription.startDate)).format('x'),
                            prescription.endDate
                                ? moment(new Date(prescription.endDate)).format('x')
                                : date
                                ? date.endDate
                                : 'Invalid Date',
                        ],
                    });
            }
        })
    );
    drugsList.sort((a, b) => a.name!.localeCompare(b.name!));
    if (drugsList.length) {
        drugsList = [{ id: 0, name: SELECT_ALL, date: [] }, ...drugsList];
    }
    return drugsList;
};

const convertToBloodTargetInAnalytics = (bloodWorkReports: BloodworkResponse[], leaveDuplicates: boolean = false) => {
    let bloodTargetsList: BloodTargetInAnalytics[] = [];
    bloodWorkReports.forEach(bloodwork =>
        bloodwork.panels.forEach(panel => {
            panel.targetList.forEach(target => {
                const sameItems = bloodTargetsList.filter(t => t.id === target.id);
                (!sameItems.length || leaveDuplicates) &&
                    bloodTargetsList.push({
                        id: target.id,
                        name: target.target,
                        label: target.label,
                        date: moment(new Date(bloodwork.reportDate)).format('x'),
                        value: panel.currentResult[target.target],
                    });
            });
        })
    );
    bloodTargetsList.sort((a, b) => a.name.localeCompare(b.name));
    if (bloodTargetsList.length) {
        bloodTargetsList = [{ id: 0, name: SELECT_ALL, date: '', label: '', value: 0 }, ...bloodTargetsList];
    }
    return bloodTargetsList;
};

const convertToBloodTargetInGraph = (
    bloodWorkReports: BloodTargetInAnalytics[],
    chosenTargets: BloodTargetInAnalytics[]
) => {
    let targetsToShow: BloodTargetInAnalytics[] = [];

    chosenTargets.forEach(chosen => {
        const items = bloodWorkReports.filter(t => t.id === chosen.id);
        targetsToShow = targetsToShow.concat(items);
    });

    const allDates = new Set<string>();
    const allTargets = new Set<string>();
    targetsToShow.forEach(t => allDates.add(t.date));
    targetsToShow.forEach(t => allTargets.add(t.name));

    const graphData: BloodTargetGraphData[] = [];

    allDates.forEach(date => {
        const targets = targetsToShow.filter(t => t.date === date);
        if (targets.length) {
            const allTargetsInDate = { date: date };
            targets.forEach(target => {
                // @ts-ignore
                allTargetsInDate[target.name] = target.value;
            });

            graphData.push(allTargetsInDate);
        }
    });

    const targetsNames = Array.from(allTargets);
    const targets = targetsNames.map(t => ({ name: t, color: randomColor({ luminosity: 'dark' }) }));

    return {
        graphData: graphData,
        targets,
    };
};

export function reducer(state = initialState, action: analyticsActions.Actions) {
    switch (action.type) {
        case analyticsActions.ActionNames.GET_ANALYTICS_PATIENT: {
            return {
                ...state,
                loadingRequestState: RequestState.SENDING,
                date: {
                    startDate: moment(new Date(action.payload.startDate)).format('x'),
                    endDate: moment(new Date(action.payload.endDate)).format('x'),
                },
            };
        }

        case analyticsActions.ActionNames.GET_ANALYTICS_PATIENT_SUCCESS: {
            return {
                ...state,
                loadingRequestState: RequestState.SENT_SUCCESS,
                analyticsInfo: action.payload,
                drugsList: convertToDrugInAnalytics(action.payload.prescriptions, state.date),
                bloodTargetsList: convertToBloodTargetInAnalytics(action.payload.bloodWorkReports),
            };
        }

        case analyticsActions.ActionNames.GET_ANALYTICS_PATIENT_FAILED: {
            return {
                ...state,
                loadingRequestState: RequestState.UNSENT,
                date: null,
            };
        }

        case analyticsActions.ActionNames.GET_ANALYTICS_RESULT: {
            let bloodTargetsListResult = state.bloodTargetsListResult;
            if (state.analyticsInfo) {
                const targets = convertToBloodTargetInAnalytics(state.analyticsInfo.bloodWorkReports, true);
                bloodTargetsListResult = convertToBloodTargetInGraph(targets, action.payload.bloodTargetsList);
            }

            return {
                ...state,
                drugsListResult: action.payload.drugsList,
                bloodTargetsListResult,
                showAnalytics: true,
            };
        }

        case analyticsActions.ActionNames.CLEAR_ANALYTICS: {
            return {
                ...state,
                showAnalytics: false,
                analyticsInfo: null,
                analyticsResult: null,
                drugsList: [],
                bloodTargetsList: [],
                drugsListResult: [],
                bloodTargetsListResult: null,
            };
        }

        case analyticsActions.ActionNames.CLEAR_ANALYTICS_DATE: {
            return {
                ...state,
                date: null,
            };
        }

        case analyticsActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                loadingRequestState: RequestState.UNSENT,
            };
        }

        default:
            return state;
    }
}
