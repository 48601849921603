import * as cardManagementActions from '../../actions/cardManagement.actions';
import RequestState from '../../../constants/requestState';
import { CardModel } from '../../../models/responses/card.model';

export class StateModel {
    cardsList: CardModel[] = [];
    clientSecret: string = '';

    getClientSecretLoading: RequestState = RequestState.UNSENT;
    cardsListLoading: RequestState = RequestState.UNSENT;
    cardDeleteLoading: RequestState = RequestState.UNSENT;
    cardAddLoading: RequestState = RequestState.UNSENT;
    cardSetDefaultLoading: RequestState = RequestState.UNSENT;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: cardManagementActions.Actions) {
    switch (action.type) {
        case cardManagementActions.ActionNames.GET_CLIENT_SECRET: {
            return {
                ...state,
                getClientSecretLoading: RequestState.SENDING,
            };
        }
        case cardManagementActions.ActionNames.GET_CLIENT_SECRET_SUCCESS: {
            return {
                ...state,
                clientSecret: action.payload.clientSecret,
                getClientSecretLoading: RequestState.SENT_SUCCESS,
            };
        }
        case cardManagementActions.ActionNames.GET_CLIENT_SECRET_FAILED: {
            return {
                ...state,
                getClientSecretLoading: RequestState.SENT_FAILED,
            };
        }

        case cardManagementActions.ActionNames.GET_ALL_CARDS: {
            return {
                ...state,
                cardsListLoading: RequestState.SENDING,
            };
        }

        case cardManagementActions.ActionNames.GET_ALL_CARDS_SUCCESS: {
            return {
                ...state,
                cardsListLoading: RequestState.SENT_SUCCESS,
                cardsList: action.payload,
            };
        }

        case cardManagementActions.ActionNames.GET_ALL_CARDS_FAILED: {
            return {
                ...state,
                cardsListLoading: RequestState.SENT_FAILED,
            };
        }

        case cardManagementActions.ActionNames.DELETE_CARD: {
            return {
                ...state,
                cardDeleteLoading: RequestState.SENDING,
            };
        }

        case cardManagementActions.ActionNames.DELETE_CARD_SUCCESS: {
            return {
                ...state,
                cardDeleteLoading: RequestState.SENT_SUCCESS,
            };
        }

        case cardManagementActions.ActionNames.DELETE_CARD_FAILED: {
            return {
                ...state,
                cardDeleteLoading: RequestState.SENT_FAILED,
            };
        }

        case cardManagementActions.ActionNames.SET_ADD_CARD_LOADING_STATE: {
            return {
                ...state,
                cardAddLoading: action.payload,
            };
        }

        case cardManagementActions.ActionNames.MAKE_CARD_DEFAULT: {
            return {
                ...state,
                cardSetDefaultLoading: RequestState.SENDING,
            };
        }

        case cardManagementActions.ActionNames.MAKE_CARD_DEFAULT_FAILED: {
            return {
                ...state,
                cardSetDefaultLoading: RequestState.SENT_FAILED,
            };
        }

        case cardManagementActions.ActionNames.MAKE_CARD_DEFAULT_SUCCESS: {
            return {
                ...state,
                cardSetDefaultLoading: RequestState.SENT_SUCCESS,
            };
        }

        case cardManagementActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                getClientSecretLoading: RequestState.UNSENT,
                cardDeleteLoading: RequestState.UNSENT,
                cardAddLoading: RequestState.UNSENT,
                cardSetDefaultLoading: RequestState.UNSENT,
                clientSecret: '',
            };
        }
        default:
            return state;
    }
}
