import { put, call, takeEvery } from 'redux-saga/effects';
import * as providerActions from '../../actions/provider.actions';
import ProviderApi from '../../../api/provider/ProviderApi';

function* getAllProviders(action: providerActions.getAllProvidersType) {
    try {
        const response = yield call(ProviderApi.getAllProviders, action.payload);
        if (action.payload.overrideRecords) {
            yield put({ type: providerActions.ActionNames.GET_ALL_PROVIDERS_OVERRIDE_SUCCESS, payload: response.data });
        } else {
            yield put({ type: providerActions.ActionNames.GET_ALL_PROVIDERS_UPDATE_SUCCESS, payload: response.data });
        }
    } catch (e) {
        yield put({ type: providerActions.ActionNames.GET_ALL_PROVIDERS_FAILED });
    }
}

function* getProviderById(action: providerActions.getProviderByIdType) {
    try {
        const response = yield call(ProviderApi.getProviderById, action.payload);
        yield put({ type: providerActions.ActionNames.GET_PROVIDER_BY_ID_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.GET_PROVIDER_BY_ID_FAILED });
    }
}

function* getProvider() {
    try {
        const response = yield call(ProviderApi.getProvider);
        yield put({ type: providerActions.ActionNames.GET_PROVIDER_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.GET_PROVIDER_FAILED });
    }
}

function* updateProvider(action: providerActions.updateProviderType) {
    try {
        const response = yield call(ProviderApi.updateProvider, action.payload);
        yield put({ type: providerActions.ActionNames.UPDATE_PROVIDER_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.UPDATE_PROVIDER_FAILED });
    }
}

function* updateProviders(action: providerActions.updateProvidersType) {
    try {
        const response = yield call(ProviderApi.updateProviders, action.payload);
        yield put({ type: providerActions.ActionNames.UPDATE_PROVIDERS_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.UPDATE_PROVIDERS_FAILED });
    }
}

function* uploadAvatar(action: providerActions.uploadAvatarType) {
    try {
        const response = yield call(ProviderApi.uploadAvatar, action.payload);
        yield put({ type: providerActions.ActionNames.UPLOAD_AVATAR_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.UPLOAD_AVATAR_FAILED });
    }
}

function* requestProvider(action: providerActions.requestProviderType) {
    try {
        yield call(ProviderApi.requestProvider, action.payload);
        yield put({ type: providerActions.ActionNames.REQUEST_PROVIDER_SUCCESS });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.REQUEST_PROVIDER_FAILED });
    }
}

function* cancelRequestProvider() {
    try {
        yield call(ProviderApi.cancelRequestProvider);
        yield put({ type: providerActions.ActionNames.CANCEL_REQUEST_PROVIDER_SUCCESS });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.CANCEL_REQUEST_PROVIDER_FAILED });
    }
}

function* saveProviders(action: providerActions.saveProvidersType) {
    try {
        yield call(ProviderApi.saveProviders, action.payload);
        yield put({ type: providerActions.ActionNames.SAVE_PROVIDERS_SUCCESS });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.SAVE_PROVIDERS_FAILED });
    }
}

function* getNewPassword(action: providerActions.getNewPasswordProviderType) {
    try {
        yield call(ProviderApi.getNewPasswordProvider, action.payload);
        yield put({ type: providerActions.ActionNames.GET_NEW_PASSWORD_PROVIDER_SUCCESS });
    } catch (e) {
        yield put({ type: providerActions.ActionNames.GET_NEW_PASSWORD_PROVIDER_FAILED });
    }
}

export default function* ProviderSagas() {
    yield takeEvery(providerActions.ActionNames.GET_ALL_PROVIDERS, getAllProviders);
    yield takeEvery(providerActions.ActionNames.GET_PROVIDER_BY_ID, getProviderById);
    yield takeEvery(providerActions.ActionNames.GET_PROVIDER, getProvider);
    yield takeEvery(providerActions.ActionNames.UPDATE_PROVIDER, updateProvider);
    yield takeEvery(providerActions.ActionNames.UPDATE_PROVIDERS, updateProviders);
    yield takeEvery(providerActions.ActionNames.UPLOAD_AVATAR, uploadAvatar);
    yield takeEvery(providerActions.ActionNames.REQUEST_PROVIDER, requestProvider);
    yield takeEvery(providerActions.ActionNames.CANCEL_REQUEST_PROVIDER, cancelRequestProvider);
    yield takeEvery(providerActions.ActionNames.SAVE_PROVIDERS, saveProviders);
    yield takeEvery(providerActions.ActionNames.GET_NEW_PASSWORD_PROVIDER, getNewPassword);
}
