import React from 'react';
import ProviderListItem from '../../pages/patient/components/ProviderListItem';
import PatientListItem from '../../pages/patient/components/PatientListItem';

interface InjectedProps {
    type: 'provider' | 'patient';
    entity: any;
    showStatusIcons?: boolean;
}

type PropsTypes = InjectedProps;

const ScrollableListItem: React.FC<PropsTypes> = props => {
    switch (props.type) {
        case 'provider':
            return <ProviderListItem provider={props.entity} />;
        case 'patient':
            return <PatientListItem patient={props.entity} showStatusIcons={props.showStatusIcons} />;
        default:
            return <></>;
    }
};

export default ScrollableListItem;
