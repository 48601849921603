import React from 'react';
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import RegularText from '../../shared/RegularText';
import PrimaryButton from '../../../../shared/button/PrimaryButton';
import { mediaQuery } from '../../../../constants/device';

const Wrapper = styled.div`
    padding: 22px 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mediaQuery.tablet} {
        align-items: flex-start;
    }
`;

const Heading = styled.div`
    display: flex;
    align-items: center;
    ${mediaQuery.tablet} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const TextWrapper = styled.div`
    margin-top: 30px;
    ${mediaQuery.tablet} {
        margin-top: 12px;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 38px;
`;

interface InjectedProps {
    icon: string;
    heading: string;
    text: string;
    buttonText?: string;
}

type PropsTypes = InjectedProps;

const createStyles = makeStyles(theme => ({
    heading: {
        fontSize: '30px',
        lineHeight: '36px',
    },
    icon: {
        marginRight: '18px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '32px',
        },
    },
}));

const SideIconWithText: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    const { icon, heading, text, buttonText } = props;
    return (
        <Wrapper>
            <Heading>
                <img src={icon} alt="" className={classes.icon} />
                <Typography variant={'button'} classes={{ root: classes.heading }}>
                    {heading}
                </Typography>
            </Heading>
            <TextWrapper>
                <RegularText>{text}</RegularText>
            </TextWrapper>
            {buttonText && (
                <ButtonWrapper>
                    <PrimaryButton>{buttonText}</PrimaryButton>
                </ButtonWrapper>
            )}
        </Wrapper>
    );
};

export default SideIconWithText;
