import * as DrugsActions from '../../actions/drug.actions';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';

export class StateModel {
    allDrugs: BaseDropdownItem[] = [];
    page: number = 0;
    totalDrugs: number | null = null;
    loading: boolean = false;
    searchQuery: string = '';
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: DrugsActions.Actions) {
    switch (action.type) {
        case DrugsActions.ActionNames.GET_ALL_DRUGS: {
            return {
                ...state,
                loading: true,
            };
        }

        case DrugsActions.ActionNames.GET_ALL_DRUGS_UPDATE_SUCCESS: {
            const isEmptyString = state.searchQuery.trim().length === 0;
            const newLabsList = state.allDrugs.concat(action.payload.result);
            const isRedundant = newLabsList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalDrugs: action.payload.total,
                allDrugs: isEmptyString && !isRedundant ? newLabsList : action.payload.result,
            };
        }

        case DrugsActions.ActionNames.GET_ALL_DRUGS_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loading: false,
                totalDrugs: action.payload.total,
                allDrugs: action.payload.result,
            };
        }

        case DrugsActions.ActionNames.GET_ALL_DRUGS_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case DrugsActions.ActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case DrugsActions.ActionNames.SET_SEARCH_QUERY: {
            const isEmptyString = action.payload.trim().length === 0;
            return {
                ...state,
                searchQuery: action.payload,
                loading: isEmptyString ? false : state.loading,
                page: 0,
                allDrugs: isEmptyString ? [] : state.allDrugs,
                totalDrugs: 0,
            };
        }

        default:
            return state;
    }
}
