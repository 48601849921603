import React, {useEffect} from 'react';
import Footer from "../../../shared/Footer";
import SolutionsHeaderBlock from "./components/SolutionsHeaderBlock";
import TextWithBackgroundBlockGray from "./components/TextWithBackgroundBlockGray";
import heartbeatIcon from '../../../static/images/landing/solutions/heartbeatIcon.svg';
import bloodDonationIcon from '../../../static/images/landing/solutions/bloodDonationIcon.svg';
import medicalHistoryIcon from '../../../static/images/landing/solutions/medicalHistoryIcon.svg';
import {makeStyles, Typography} from "@material-ui/core";
import styled from "styled-components";
import resourcesCardWithTopImage from "../../../static/images/landing/solutions/resourcesCardWithTopImage.png";
import {colors} from "../../../theme/colors";
import solutionsCarousel3 from "../../../static/images/landing/solutions/solutionsCarousel3.png";
import solutionsCarousel4 from "../../../static/images/landing/solutions/solutionsCarousel4.jpg";
import solutionsCarousel1 from "../../../static/images/landing/solutions/solutionsCarousel1.jpg";
import solutionsCarousel2 from "../../../static/images/landing/solutions/solutionsCarousel2.jpg";
import SharedCarousel from "../shared/SharedCarousel";
import LandingBigHeading from "../../shared/LandingBigHeading";
import LandingRedUppercaseHeading from "../../shared/LandingRedUppercaseHeading";
import laboratotyCardWithTopImageMobile from "../../../static/images/landing/solutions/laboratotyCardWithTopImageMobile.png";
import laboratotyCardWithTopImageDesktop from "../../../static/images/landing/solutions/laboratotyCardWithTopImageDesktop.png";
import feedbackAvatar from "../../../static/images/landing/solutions/feedbackAvatar.png";
import {mediaQuery} from "../../../constants/device";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import RegularText from "../shared/RegularText";
import CardWithTopImageRed from "../../../shared/card/CardWithTopImageRed";
import {ImgSide} from "../../../shared/card/CardWithTopImageGray";

const useStyles = makeStyles({
    heading: {
        fontSize: '30px',
        lineHeight: '36px',
    },
    headingRedBlock: {
        fontSize: '24px',
        lineHeight: '36px',
        color: colors.white,
        display: 'block',
        marginBottom: '36px',
    }
});

const TextWithBackgroundBlockGrayWrapper = styled.div`
  position: relative;
  background-color: ${colors.gray2};
  padding-bottom: 60px;
  > div {
    padding-bottom: 40px;
  }
  ${mediaQuery.tablet}{
       padding: 0 12%;
  }
  ${mediaQuery.laptop}{
      display: flex;
      padding: 0 2% 120px;
  }
  ${mediaQuery.laptopL}{
      padding: 0 12% 120px;
  }
`;

const RedBackground = styled.div`
  background-color: ${colors.redMain};
  height: 40px;
`;

const HeadingWrapper = styled.div`
  margin: 100px 0 33px;
`;

const CarouselWrapper = styled.div`
  margin-bottom: 100px;
`;

const HeadingWrapperGrayBackground = styled.div`
  padding: 60px 0 21px;
  background-color: ${colors.gray2};
`;

const CarouselWrapperBackground = styled.div`
  background-color: ${colors.gray2};
  padding-bottom: 84px;
  ${mediaQuery.tablet}{
     padding: 0 5% 84px;
  }
  ${mediaQuery.laptopL}{
     padding: 0 12% 84px;
  }
`;

const HeadingGrayBackground = styled.div`
  background-color: ${colors.gray2};
`;

const CarouselBlockWrapper = styled.div`
  ${mediaQuery.laptop}{
    margin: 0 5%;
  }
   ${mediaQuery.laptopL}{
    margin: 0 12%;
  }
`;

const CarouselText = styled.div`
 ${mediaQuery.laptop}{
    width: 920px;
    margin: 0 auto;
  }
`;

const TextWrapper = styled.div`
  text-align: center;
  margin: 10px 20px 0;
  ${mediaQuery.laptop}{
    margin: 0;
  }
`;

const GrayBackground = styled.div` 
  height: 500px;
  width: 100%;
  background-color: ${colors.gray2};
  position: absolute;  
  bottom: 0;
  left: 0;
  z-index: -1;
  margin-bottom: -360px;
`;

const CarouselSizeWrapper = styled.div`
  
`;

const Solutions: React.FC = (props) => {
    const classes = useStyles(props);

    const isTablet = useMediaQuery(mediaQuery.tablet);

    const laboratotyCardWithTopImage = isTablet ? laboratotyCardWithTopImageDesktop : laboratotyCardWithTopImageMobile;

    useEffect(() => {
        window.scroll(0, 0)
    }, []);

    return (
        <>
            <SolutionsHeaderBlock/>
            <TextWithBackgroundBlockGrayWrapper>
                <TextWithBackgroundBlockGray
                    icon={heartbeatIcon}
                    text={'Direct patient messaging, payment and simplified inventory, treatment and results tracking are all included in your service'}
                    buttonText={'Patient'}
                    path={'/patient'}
                >
                    <Typography variant={'button'}
                                classes={{root: classes.heading}}>Streamlined <br/> Patient <br/> Communication</Typography>
                </TextWithBackgroundBlockGray>

                <TextWithBackgroundBlockGray
                    icon={medicalHistoryIcon}
                    text={'Stored messaging center, patient reminders, treatment reminders, inventory management and payment services are all included in a simple to access and manage portal.'}
                    buttonText={'Provider'}
                    path={'/provider'}
                >
                    <Typography variant={'button'} classes={{root: classes.heading}}>Easy Provider
                        Management</Typography>
                </TextWithBackgroundBlockGray>

                <TextWithBackgroundBlockGray
                    icon={bloodDonationIcon}
                    text={'Providing patient and provider with the resources to better communicate, track and discuss the results of alternative medical supplementation.'}
                    buttonText={'Resources'}
                    path={'/resources'}
                >
                    <Typography variant={'button'} classes={{root: classes.heading}}>Resources
                        to <br/> streamline <br/> processes</Typography>
                </TextWithBackgroundBlockGray>
                {isTablet &&
                <GrayBackground/>
                }
            </TextWithBackgroundBlockGrayWrapper>

            <CardWithTopImageRed
                imageMargin
                text='Imagine a world where all of these concerns are addressed and managed in a simple to use app based system…'
                img={resourcesCardWithTopImage}
                backgroundColor={colors.gray2}
                imgSide={ImgSide.right}
            >
                <Typography variant={'button'} classes={{root: classes.headingRedBlock}}>
                    Communication with your provider becoming more and more difficult?
                    Having trouble managing your patients, their care, inventory needs, payment processes and bloodwork?
                </Typography>
            </CardWithTopImageRed>
            {!isTablet &&
            <RedBackground/>
            }

            <CarouselBlockWrapper>
                <CarouselText>
                    <HeadingWrapper>
                        <LandingRedUppercaseHeading>How Can We Help</LandingRedUppercaseHeading>
                    </HeadingWrapper>
                    <LandingBigHeading size={'small'}>Better Management Means Better Care</LandingBigHeading>
                    <TextWrapper>
                        <RegularText>
                            Patients want three things from their providers: good communication, accurate treatment and ongoing tracking of results.
                            We help deliver all of those, streamlining the most difficult pieces of the alternative medicinal marketplace.
                        </RegularText>
                    </TextWrapper>
                </CarouselText>

                <CarouselWrapper>
                    <SharedCarousel
                        isTablet={isTablet}
                        textFontSize={16}
                        itemsInDesktop={3}
                        partialVisibility
                        items={[
                            {
                                text: '',
                                authorName: '',
                                img: solutionsCarousel3,
                            },
                            {
                                text: '',
                                authorName: '',
                                img: solutionsCarousel4,
                            },
                            {
                                text: '',
                                authorName: '',
                                img: solutionsCarousel1,
                            },
                            {
                                text: '',
                                authorName: '',
                                img: solutionsCarousel2,
                            },
                        ]}/>
                </CarouselWrapper>
            </CarouselBlockWrapper>

            <CardWithTopImageRed
                text={'There are dozens of labs, report formats, data options and acronyms for results. Equally, there are few options to consolidate those into an easily readable, sharable or ' +
                'diagnosable format. ' +
                'Managing all of this shouldn’t fall to the patient or provider. There should be a tool to help streamline all of these processes… Oh wait, now there is!'}
                img={laboratotyCardWithTopImage}
                backgroundColor={colors.white}
                imgSide={ImgSide.left}
            >
                <Typography variant={'button'} classes={{root: classes.headingRedBlock}}>
                    Laboratory
                </Typography>
            </CardWithTopImageRed>
            {!isTablet &&
            <RedBackground/>
            }

            <HeadingWrapperGrayBackground>
                <LandingRedUppercaseHeading>Our Provider Says</LandingRedUppercaseHeading>
            </HeadingWrapperGrayBackground>
            <HeadingGrayBackground>
                <LandingBigHeading>Feedback</LandingBigHeading>
            </HeadingGrayBackground>

            <CarouselWrapperBackground>
                <CarouselSizeWrapper>
                <SharedCarousel
                    centeredDesktop
                    isTablet={isTablet}
                    textFontSize={24}
                    items={[
                        {
                            text: 'UPmed has provided me with a tool that better organizes my staff and better serves my patients. It is easy to utilize for' +
                                ' both my team and patients, and has enough flexibility to customize to our needs as an alternative health provider.' +
                                ' It has dramatically streamlined all operational and treatment related aspects of my practice.',
                            authorName: '“Dr. Roy Korth”',
                            avatar: feedbackAvatar,
                        },
                    ]}/>
                </CarouselSizeWrapper>
            </CarouselWrapperBackground>
            <Footer/>
        </>
    )
};

export default Solutions;