import React, { useCallback, useEffect, useMemo } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import PrimaryButton from '../../shared/button/PrimaryButton';
import AvatarProfileBigShared from '../shared/AvatarProfileBigShared';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { mediaQuery } from '../../constants/device';
import { makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers/index';
import { Actions as LabAction } from '../../store/actions/lab.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import Loading from '../shared/Loading';
import { LabModel } from '../../models/responses/lab.model';
import { colors } from '../../theme/colors';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16% 0;
    min-height: calc(100vh - 260px);
    height: auto;

    ${mediaQuery.tablet} {
        height: calc(100vh - 370px);
        min-height: calc(100vh - 370px);
    }

    ${mediaQuery.laptop} {
        padding: 60px 0 53px;
        min-height: 77%;
        height: 77%;
    }
`;

const LabInfo = styled.div`
    text-align: center;
`;

const Address = styled.div`
    padding: 8px 0;
`;

const ButtonWrapper = styled.div`
    margin-top: 32px;
`;

const createStyles = makeStyles({
    name: {
        fontSize: '18px',
        marginTop: '32px',
        display: 'block',
    },
    address: {
        fontSize: '14px',
        display: 'block',
    },
    info: {
        fontSize: '14px',
    },
    link: {
        color: colors.redMain,
        display: 'block',
        cursor: 'pointer',
        paddingBottom: '4px',
    },
});

interface StateModel {
    loading: boolean;
    currentLab: LabModel | null;
    user: UserModel | null;
}

interface DispatchModel {
    getLabById: typeof LabAction.getLabById;
}

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const LabInfoPage: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const { loading, currentLab, getLabById, user } = props;

    useEffect(() => {
        if (props.match.params.id) {
            getLabById(props.match.params.id);
        }
    }, [props.match.params.id, getLabById]);

    const followLink = useCallback(() => {
        currentLab && window.open(currentLab.website, '_blank');
    }, [currentLab]);

    const redirectToEdit = useCallback(() => {
        if (currentLab) {
            props.history.push(`/admin/lab-edit/${currentLab.id}`);
        }
    }, [props.history, currentLab]);

    const backLink = useMemo(() => {
        if (user && user.role === RoleOptions.ADMIN) {
            return '/admin/labs';
        }
        return '/search/labs';
    }, [user]);

    return (
        <>
            <HeaderHeadingWithLink goBackLink={backLink}>{currentLab && `${currentLab.name}.`}</HeaderHeadingWithLink>
            <SizeControllerWrapper>
                {!loading && currentLab ? (
                    <Wrapper>
                        <LabInfo>
                            <AvatarProfileBigShared avatar={null} editMode={false} isDefaultAvatar />
                            <Typography variant="button" classes={{ root: classes.name }}>
                                {currentLab.name}
                            </Typography>
                            <Address>
                                <Typography variant="button" classes={{ root: classes.address }}>
                                    {currentLab.streetAddress1}
                                </Typography>
                                <Typography variant="button" classes={{ root: classes.address }}>
                                    {currentLab.streetAddress1}
                                </Typography>
                                <Typography variant="button" classes={{ root: classes.address }}>
                                    {currentLab.city} {currentLab.state && `, ${currentLab.state}`}{' '}
                                    {currentLab.zip && `, ${currentLab.zip}`}
                                </Typography>
                            </Address>

                            <Typography variant="button" classes={{ root: classes.link }} onClick={followLink}>
                                {currentLab.website}
                            </Typography>

                            <Typography variant={'body1'} classes={{ root: classes.info }}>
                                {currentLab.email && `Email: ${currentLab.email}`}
                            </Typography>
                            <Typography variant={'body1'} classes={{ root: classes.info }}>
                                {currentLab.phone && `Phone: ${currentLab.phone}`}
                            </Typography>
                        </LabInfo>

                        {user && user.role === RoleOptions.ADMIN && (
                            <ButtonWrapper>
                                <PrimaryButton size={'medium'} onClick={redirectToEdit}>
                                    Edit Info
                                </PrimaryButton>
                            </ButtonWrapper>
                        )}
                    </Wrapper>
                ) : (
                    <Loading />
                )}
            </SizeControllerWrapper>
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.lab.loading,
        currentLab: state.lab.currentLab,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getLabById: bindActionCreators(LabAction.getLabById, dispatch),
    })
)(withRouter(LabInfoPage));
