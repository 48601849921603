import React from 'react';
import BlockHeader from '../BlockHeader';
import ActiveSubscriptionListItem from './ActiveSubscriptionListItem';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import { UserModel } from '../../../../models/responses/user.response';
import NoData from '../NoData';
import { RoleOptions } from '../../../../constants/roleOptions';
import { convertCentsToDollars } from '../../../../helpers/convertMoney';
import { templateDisplayingDollar } from '../../../../shared/utils/templateDisplayingDollar';

const ActiveSubscriptionBlock: React.FC<{
    invoices: InvoiceModel[];
    patientId?: number | null;
    user?: UserModel | null;
    totalAmountInvoiceRecurringSubscriptionAllPatientsActive?: number;
    totalAmountRecurringSubscriptionPatient?: number;
}> = ({
    invoices,
    patientId,
    totalAmountInvoiceRecurringSubscriptionAllPatientsActive,
    totalAmountRecurringSubscriptionPatient,
    user,
}) => {
    return (
        <>
            {user && user.role === RoleOptions.PATIENT ? (
                <BlockHeader>
                    <div>Active</div>
                </BlockHeader>
            ) : (
                <BlockHeader>
                    <div>Active</div>
                    <div>
                        Total :{' '}
                        {totalAmountInvoiceRecurringSubscriptionAllPatientsActive !== undefined &&
                            templateDisplayingDollar(
                                convertCentsToDollars(totalAmountInvoiceRecurringSubscriptionAllPatientsActive)
                            )}
                    </div>
                </BlockHeader>
            )}
            {invoices.length > 0 ? (
                invoices.map((invoice, index) => (
                    <ActiveSubscriptionListItem key={index} index={index} invoice={invoice} patientId={patientId} />
                ))
            ) : (
                <NoData />
            )}
        </>
    );
};

export default ActiveSubscriptionBlock;
