import React from 'react';
import NewPaymentListItem from './NewPaymentListItem';
import BlockHeader from '../BlockHeader';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import NoData from '../NoData';
import { convertCentsToDollars } from '../../../../helpers/convertMoney';
import { templateDisplayingDollar } from '../../../../shared/utils/templateDisplayingDollar';

const ToBePaidBlock: React.FC<{
    invoices: InvoiceModel[];
    patientId?: number | null;
    totalAmountInvoiceOneTimeRequested: number;
}> = ({ invoices, patientId, totalAmountInvoiceOneTimeRequested }) => {
    return (
        <>
            <BlockHeader>
                <div>To be paid</div>
                <div>Total : {templateDisplayingDollar(convertCentsToDollars(totalAmountInvoiceOneTimeRequested))}</div>
            </BlockHeader>
            {invoices.length > 0 ? (
                invoices.map((invoice, index) => (
                    <NewPaymentListItem key={index} index={index} invoice={invoice} patientId={patientId} />
                ))
            ) : (
                <NoData />
            )}
        </>
    );
};

export default ToBePaidBlock;
