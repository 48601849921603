import React, { useMemo } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { makeStyles, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import halfLogoBackground from '../../static/images/landing/main/halfLogoBackground.svg';
import { mediaQuery } from '../../constants/device';
import CustomLink from '../../shared/link/CustomLink';
import { PaymentRoutingPatient } from '../../constants/tabRouting/routing';

const createStyles = makeStyles( {
    text: {
        fontSize: '18px',
        width: '55%',
        margin: '0 auto',
        lineHeight: '32px',
        textAlign: 'center',
    },
    back: {
        color: colors.redMain,
    },
    email: {
        fontSize: '18px',
    },
});

const BackgroundWrapper = styled.div`
    height: calc(100vh - 90px);
    background-image: url(${halfLogoBackground});
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-position: right 27%;
    ${mediaQuery.laptop} {
        height: 100%;
    }
`;

const Wrapper = styled.div`
    height: 25%;
    padding: 40% 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

type PropsTypes = RouteComponentProps<any>;

enum invoiceStatus {
    success = 'success',
    fail = 'fail',
}

const PaymentResult: React.FC<PropsTypes> = props => {
    const status = useMemo(() => {
        const values = queryString.parse(props.location.search);
        switch (values.status) {
            case invoiceStatus.success: {
                return invoiceStatus.success;
            }
            case invoiceStatus.fail: {
                return invoiceStatus.fail;
            }
            default:
                props.history.push('/');
        }
    }, [props.location.search, props.history]);

    const classes = createStyles(props);
    return (
        <BackgroundWrapper>
            <SizeControllerWrapper>
                <Wrapper>
                    {status === invoiceStatus.success ? (
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            Your payment has been successfully processed.
                        </Typography>
                    ) : (
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            There was an issue with your payment. Please try again later or contact the support service
                            <br />{' '}
                            <Typography variant={'button'} classes={{ root: classes.email }}>
                                support@mail.com
                            </Typography>
                            .
                        </Typography>
                    )}

                    <CustomLink to={PaymentRoutingPatient[0].path} underlineColor={colors.redMain}>
                        <Typography variant={'button'} classes={{ root: classes.back }}>
                            Back to Payments
                        </Typography>
                    </CustomLink>
                </Wrapper>
            </SizeControllerWrapper>
        </BackgroundWrapper>
    );
};

export default withRouter(PaymentResult);
