import React, {useCallback, useEffect, useState} from 'react';
import DosageList from './DosageList';
import { DosageStatus } from '../../../constants/dosageStatus';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as PrescriptionActions } from '../../../store/actions/prescription.actions';
import { UserModel } from '../../../models/responses/user.response';
import { RoleOptions } from '../../../constants/roleOptions';
import {DefaultPageSize, shouldFetchData} from "../../../constants/dataFetching/allEntitiesByPages";

interface StoreModel {
    loading: boolean;
    allPrescriptions: any[];
    page: number;
    totalPrescriptions: number | null;
    user: UserModel | null;
}

interface DispatchModel {
    increasePageNumber: typeof PrescriptionActions.increasePageNumber;
    getAllPrescriptionsPatient: typeof PrescriptionActions.getAllPrescriptionsPatient;
    getAllPrescriptionsProvider: typeof PrescriptionActions.getAllPrescriptionsProvider;
}

interface InjectedProps {
    dosageStatus: DosageStatus;
    patientId: number;
}

type PropsTypes = InjectedProps & StoreModel & DispatchModel;

const DosageListCurrentStatus: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);

    const {
        page,
        loading,
        increasePageNumber,
        user,
        patientId,
        allPrescriptions,
        dosageStatus,
        getAllPrescriptionsPatient,
        getAllPrescriptionsProvider,
        totalPrescriptions,
    } = props;

    const loadData = useCallback((page: number, size: number, sort: string, overrideRecords: boolean) => {
        if (user && user.role === RoleOptions.PATIENT) {
            getAllPrescriptionsPatient({
                page,
                size,
                sort,
                patientId: patientId,
                isPrivate: false,
                status: dosageStatus,
                overrideRecords: overrideRecords,
            });
        }
        if (user && user.role === RoleOptions.PROVIDER) {
            getAllPrescriptionsProvider({
                page,
                size,
                sort,
                patientId: patientId,
                isPrivate: false,
                status: dosageStatus,
                overrideRecords: overrideRecords,
            });
        }
    },[getAllPrescriptionsPatient, getAllPrescriptionsProvider, user, dosageStatus, patientId]);

    useEffect(() => {
        loadData(0, DefaultPageSize, '', true);
        setInitialLoaded(true);
    }, [loadData]);

    useEffect(() => {
        if (shouldFetchData(loading, page, allPrescriptions.length, initialLoaded, DefaultPageSize)) {
            loadData(page, DefaultPageSize, '', false);
        }
    }, [page, loading, allPrescriptions, initialLoaded, loadData]);


    return (
        <DosageList
            loading={loading}
            allPrescriptions={allPrescriptions}
            totalPrescriptions={totalPrescriptions}
            page={page}
            increasePageNumber={increasePageNumber}
            role={user && user.role}
            patientId={patientId}
        />
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        loading: state.prescription.loading,
        allPrescriptions: state.prescription.allPrescriptions,
        totalPrescriptions: state.prescription.totalPrescriptions,
        page: state.prescription.page,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        increasePageNumber: bindActionCreators(PrescriptionActions.increasePageNumber, dispatch),
        getAllPrescriptionsPatient: bindActionCreators(PrescriptionActions.getAllPrescriptionsPatient, dispatch),
        getAllPrescriptionsProvider: bindActionCreators(PrescriptionActions.getAllPrescriptionsProvider, dispatch),
    })
)(DosageListCurrentStatus);
