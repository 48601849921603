import React, { useMemo } from 'react';
import { InputTypeSelector } from '../ui/formBuilder/inputTypeSelector';
import { CmToFeetAndInch, FeetToCm, InchToCm } from '../../../shared/utils/converter/length-converter';
import { makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { IRenderableFormField } from '../ui/formBuilder/form';

const DoubleInputWrapper = styled.div`
    display: flex;
`;

const RightContentWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 35px;
    margin-top: 25px;
`;

const createStyles = makeStyles({
    inputWrapper: {
        marginTop: -20,
        width: '65%',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
    },
    inputWrapperLarge: {},
    unitText: {
        fontSize: '12px',
    },
});

interface InjectedProps {
    isDefaultFormType: boolean;
    field: IRenderableFormField;
    readonly: boolean;
    onChange: (value: number) => void;
}

const HeightFormImperialSystem: React.FC<InjectedProps> = props => {
    const classes = createStyles(props);
    const { field, isDefaultFormType, readonly, onChange } = props;

    const value = useMemo(() => {
        return CmToFeetAndInch(field.value);
    }, [field]);

    return (
        <DoubleInputWrapper>
            <div className={isDefaultFormType ? classes.inputWrapper : classes.inputWrapperLarge}>
                {InputTypeSelector<any>({
                    fieldData: { ...field, value: value.feet.toString() },
                    readonly,
                    type: field.fieldType,
                    isValid: field.valid,
                    onChange: newValue => {
                        if (onChange) {
                            onChange(InchToCm(value.inch) + FeetToCm(Number(newValue)));
                        }
                    },
                })}
                <RightContentWrapper>
                    {field.unit && (
                        <Typography variant={'button'} className={classes.unitText}>
                            Ft
                        </Typography>
                    )}
                </RightContentWrapper>
            </div>
            <div className={isDefaultFormType ? classes.inputWrapper : classes.inputWrapperLarge}>
                {InputTypeSelector<any>({
                    fieldData: { ...field, value: value.inch.toString() },
                    readonly,
                    type: field.fieldType,
                    isValid: field.valid,
                    onChange: newValue => {
                        if (onChange) {
                            onChange(FeetToCm(value.feet) + InchToCm(Number(newValue)));
                        }
                    },
                })}
                <RightContentWrapper>
                    <Typography variant={'button'} className={classes.unitText}>
                        Inch
                    </Typography>
                </RightContentWrapper>
            </div>
        </DoubleInputWrapper>
    );
};

export default HeightFormImperialSystem;
