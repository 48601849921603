import React, { useMemo } from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    ListItem,
    makeStyles,
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import expandDropdownGrayUp from '../../../../static/icons/expandDropdownGrayUp.svg';
import { mediaQuery } from '../../../../constants/device';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import UserAvatar from '../UserAvatar';
import { connect } from 'react-redux';
import * as fromRoot from '../../../../store/reducers';
import { UserModel } from '../../../../models/responses/user.response';
import { RoleOptions } from '../../../../constants/roleOptions';
import { InvoiceStatus } from '../../../../constants/invoice/invoiceStatus';
import {convertCentsToDollars} from "../../../../helpers/convertMoney";
import {templateDisplayingDollar} from "../../../../shared/utils/templateDisplayingDollar";
import {formatDate} from "../../../../helpers/dateFormatHelper";

const createStyles = makeStyles({
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
    },
    price: {
        fontSize: '15px',
        color: colors.textSecondary,
    },
    name: {
        fontSize: '14px',
        color: colors.textPrimary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text: {
        fontSize: '14px',
        lineHeight: '16px',
        color: colors.textSecondary,
    },
    panel: (props: { index: number; user: UserModel | null, isViewPage?: boolean }) => ({
        boxShadow: 'none',
        backgroundColor: props.index % 2 === 0 ? colors.white : colors.gray2,
        margin: 0,
        borderBottom: props.isViewPage ? 'none' : `1px solid ${colors.accordionGray}`,
    }),
    panelSummary: (props: { user: UserModel | null }) => ({
        alignItems: props.user && props.user.role === RoleOptions.PROVIDER ? 'flex-start' : 'center',
    }),
    expandIconProvider: {
        marginTop: '16px',
        position: 'absolute',
        right: '24px',
    },
    expandIconPatient: {},
    info: {
        fontSize: '14px',
        marginRight: '6px',
    },
    panelDetails: {
        paddingTop: 0,
        flexDirection: 'column',
    },
    payDate: {
        color: colors.textSecondary,
        fontSize: '14px',
        marginRight: '14px',
        textAlign: 'right',
    },
    rejectedText: {
        color: colors.redMain,
    },
});

interface InjectedProps {
    invoice: InvoiceModel;
    index: number;
    patientId?: number | null;
    isViewPage?: boolean;
}

interface StoreModel {
    user: UserModel | null;
}

type PropsTypes = InjectedProps & StoreModel;

const DateWrapper = styled.div`
    ${mediaQuery.lessMobileS} {
        display: none;
    }
`;

const TextBlock = styled.div`
    margin-bottom: 4px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
`;

const DetailsCroppedString = styled.div`
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DetailsString = styled.div`
    margin-bottom: 12px;
`;

const Patient = styled.div``;

const HistoryListItem: React.FC<PropsTypes> = props => {
    const { invoice, patientId, user, isViewPage } = props;
    const classes = createStyles(props);

    const [expanded, setExpanded] = React.useState<string | false>(isViewPage ? 'panel' : false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const isExpanded = useMemo(() => {
        return isViewPage ? isViewPage : expanded === 'panel';
    }, [expanded, isViewPage]);

    const isProvider = useMemo(() => {
        return user && user.role === RoleOptions.PROVIDER;
    }, [user]);

    return (
        <>
            <ExpansionPanel classes={{ root: classes.panel }} expanded={isExpanded} onChange={handleChange('panel')}>
                <ExpansionPanelSummary
                    expandIcon={!isViewPage && <img src={expandDropdownGrayUp} alt="" />}
                    classes={{
                        root: classes.panelSummary,
                        expandIcon:
                            user && user.role === RoleOptions.PROVIDER
                                ? classes.expandIconProvider
                                : classes.expandIconPatient,
                    }}
                >
                    <ListItem
                        classes={{ root: classes.listItem }}
                        style={{ height: isProvider ? (!patientId ? '120px' : '80px') : '80px' }}
                    >
                        <ContentWrapper>
                            {!patientId && isProvider && (
                                <Patient>
                                    <UserAvatar user={invoice.patient} />
                                </Patient>
                            )}
                            <Info>
                                <DateWrapper>
                                    {(invoice.status === InvoiceStatus.PAID ||
                                        invoice.status === InvoiceStatus.INACTIVE) && (
                                        <Typography variant={'body2'} classes={{ root: classes.name }}>
                                            {formatDate(invoice.lastPaymentDate!)}
                                        </Typography>
                                    )}
                                    {!invoice.lastPaymentDate && invoice.status === InvoiceStatus.REJECTED && (
                                        <Typography variant={'body1'} classes={{ root: classes.rejectedText }}>
                                            Rejected
                                        </Typography>
                                    )}
                                    {invoice.status === InvoiceStatus.FAILED && (
                                        <Typography variant={'body1'} classes={{ root: classes.rejectedText }}>
                                            Failed
                                        </Typography>
                                    )}
                                </DateWrapper>
                                <Typography variant={'button'} classes={{ root: classes.price }}>
                                    {templateDisplayingDollar(convertCentsToDollars(invoice.amount))}
                                </Typography>
                            </Info>
                            {!isExpanded && (
                                <DetailsCroppedString>
                                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                                        <Typography variant={'button'} classes={{ root: classes.info }}>
                                            Details:
                                        </Typography>
                                        {invoice.description}
                                    </Typography>
                                </DetailsCroppedString>
                            )}
                        </ContentWrapper>
                    </ListItem>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                    {!isProvider && <UserAvatar user={invoice.provider} />}
                    {isExpanded && (
                        <TextBlock>
                            <DetailsString>
                                <Typography variant={'body1'} classes={{ root: classes.text }}>
                                    <Typography variant={'button'} classes={{ root: classes.info }}>
                                        Details:
                                    </Typography>
                                    {invoice.description}
                                </Typography>
                            </DetailsString>
                        </TextBlock>
                    )}
                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                        <Typography variant={'button'} classes={{ root: classes.info }}>
                            Create date:
                        </Typography>
                        {formatDate(invoice.createdDate)}
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    () => ({})
)(HistoryListItem);
