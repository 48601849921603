import React, { useEffect, useRef, useState } from 'react';
import { List, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import Loading from '../../shared/Loading';
import DosageListItem from './DosageListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GetPrescriptionByIdDto } from '../../../models/dto/getPrescriptionById.dto';
import { RoleOptions } from '../../../constants/roleOptions';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../../constants/layout';

const useStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '16px',
        color: colors.textSecondary,
        marginTop: '20px',
    },
});

interface InjectedProps {
    loading: boolean;
    allPrescriptions: GetPrescriptionByIdDto[];
    totalPrescriptions: number | null;
    page: number;
    increasePageNumber: () => void;
    role: RoleOptions | null;
    patientId: number | null;
}

type PropsTypes = InjectedProps;

const DosageList: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);

    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    const getMore = () => {
        props.increasePageNumber();
    };

    return (
        <>
            {!props.loading && props.allPrescriptions.length === 0 && (
                <SizeControllerWrapper>
                    <Typography variant="body1" classes={{ root: classes.noData }}>
                        No data found.
                    </Typography>
                </SizeControllerWrapper>
            )}

            {props.loading && props.page === 0 && <Loading smallSize />}

            {!!props.allPrescriptions.length && !props.loading  && (
                <List classes={{ root: classes.list }} ref={listRef}>
                    <InfiniteScroll
                        dataLength={props.allPrescriptions.length}
                        next={getMore}
                        height={height}
                        hasMore={
                            props.totalPrescriptions ? props.allPrescriptions.length < props.totalPrescriptions : false
                        }
                        loader={<Loading smallSize />}
                        className={classes.scrollContainer}
                    >
                        {props.allPrescriptions.map((prescription, index) => {
                            return (
                                <div key={index}>
                                    <DosageListItem
                                        key={index}
                                        prescription={prescription}
                                        patientId={props.patientId}
                                        role={props.role}
                                    />
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                </List>
            )}
        </>
    );
};

export default DosageList;
