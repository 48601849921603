import React from 'react';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { Button, makeStyles } from '@material-ui/core';
import { colors } from '../../../theme/colors';

const createStyles = makeStyles({
    button: {
        width: '30px',
        minWidth: 'auto',
        height: 'auto',
        background: colors.white,
        border: `1px solid ${colors.redMain}`,
        padding: '5px 24px 5px !important',
        '&:hover': {
            backgroundColor: `${colors.redLight} !important`,
            color: colors.white,
            '& span':{
                '& svg':{
                    color: colors.white,
                }
            }
        },
        '&:active': {
            backgroundColor:  colors.redDark,
            color: colors.white,
        },
    },
    delete: {
        color: colors.redMain,

    },
});

interface InjectedProps {
    onClick?: (evt?: any) => void;
}

type PropsTypes = InjectedProps;

const RemoveButton: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    return (
        <Button classes={{ root: classes.button }}>
            <DeleteOutlineRoundedIcon classes={{ root: classes.delete }} />
        </Button>
    );
};

export default RemoveButton;
