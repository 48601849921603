interface NotificationBaseModel {
    id: number;
    viewed: null | string;
    createdDate: string;
}

export interface Provider_PatientRequestModel extends NotificationBaseModel {
    type: NotificationTypes.PATIENT_REQUEST;
    recipientId: number;
    patientId: number;
    patientFirstName: string;
    patientLastName: string;
    patientAvatarThumbNailUrl: null;
}

export interface Provider_MyPatientRequestsModel extends NotificationBaseModel {
    type:
        | NotificationTypes.CHANGE_PROVIDER
        | NotificationTypes.ANOTHER_PROVIDER_ACCEPT
        | NotificationTypes.ANOTHER_PROVIDER_DECLINE;
    recipientId: number;
    patientId: number;
    patientFirstName: string;
    patientLastName: string;
    patientAvatarThumbNailUrl: null;
    providerId: number;
    providerFirstName: string;
    providerLastName: string;
    providerAvatarThumbNailUrl: null;
}

export interface Patient_ProviderRequestResultModel extends NotificationBaseModel {
    type: NotificationTypes.PROVIDER_ACCEPT | NotificationTypes.PROVIDER_DECLINE;
    recipientId: number;
    providerId: number;
    providerFirstName: string;
    providerLastName: string;
    providerAvatarThumbNailUrl: null;
}

interface Patient_PaymentModel extends NotificationBaseModel {
    type: NotificationTypes.INVOICE_FAIL | NotificationTypes.INVOICE_REQUIRES_ACTION | NotificationTypes.INVOICE_NEW;
    providerId: number;
    invoiceId: number;
    providerFirstName: string;
    providerLastName: string;
    invoiceDescription: string;
    providerAvatarThumbNailUrl: null;
    amount: number;
}

export interface Provider_PaymentModel extends NotificationBaseModel {
    type:
        | NotificationTypes.INVOICE_PAID
        | NotificationTypes.INVOICE_REJECTED
        | NotificationTypes.INVOICE_ACTIVE
        | NotificationTypes.INVOICE_INACTIVE;
    patientId: number;
    invoiceId: number;
    patientFirstName: string;
    patientLastName: string;
    invoiceDescription: string;
    patientAvatarThumbNailUrl: null;
    amount: number;
}

export interface Provider_PrescriptionModel extends NotificationBaseModel {
    type: NotificationTypes.NEW_PRESCRIPTION;
    recipientId: number;
    patientId: number;
    patientFirstName: string;
    patientLastName: string;
    patientAvatarThumbNailUrl: string;
    prescriptionId: number;
}

export interface Provider_BloodworkOutOfRangeModel extends NotificationBaseModel {
    type: NotificationTypes.LABWORK_OUT_OF_RANGE;
    recipientId: number;
    patientId: number;
    patientFirstName: string;
    patientLastName: string;
    patientAvatarThumbNailUrl: string;
    labWorkId: number;
}

export enum NotificationTypes {
    PATIENT_REQUEST = 'PATIENT_REQUEST',
    CHANGE_PROVIDER = 'CHANGE_PROVIDER',
    ANOTHER_PROVIDER_ACCEPT = 'ANOTHER_PROVIDER_ACCEPT',
    ANOTHER_PROVIDER_DECLINE = 'ANOTHER_PROVIDER_DECLINE',
    PROVIDER_ACCEPT = 'PROVIDER_ACCEPT',
    PROVIDER_DECLINE = 'PROVIDER_DECLINE',

    INVOICE_FAIL = 'INVOICE_FAIL',
    INVOICE_REQUIRES_ACTION = 'INVOICE_REQUIRES_ACTION',
    INVOICE_NEW = 'INVOICE_NEW',

    INVOICE_PAID = 'INVOICE_PAID',
    INVOICE_REJECTED = 'INVOICE_REJECTED',
    INVOICE_ACTIVE = 'INVOICE_ACTIVE',
    INVOICE_INACTIVE = 'INVOICE_INACTIVE',

    NEW_PRESCRIPTION = 'NEW_PRESCRIPTION',

    LABWORK_OUT_OF_RANGE = 'LABWORK_OUT_OF_RANGE',
}

export type NotificationModel =
    | Provider_PatientRequestModel
    | Provider_MyPatientRequestsModel
    | Patient_ProviderRequestResultModel
    | Provider_PaymentModel
    | Patient_PaymentModel
    | Provider_PrescriptionModel
    | Provider_BloodworkOutOfRangeModel;
