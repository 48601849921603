import React, { useCallback, useEffect } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import SearchBlock from './components/SearchBlock';
import { Fade, Typography } from '@material-ui/core';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import * as fromRoot from '../../store/reducers';
import ProviderListSearchPage from './components/ProviderListSearchPage';
import { RoleOptions } from '../../constants/roleOptions';
import { UserModel } from '../../models/responses/user.response';
import Loading from '../shared/Loading';
import LabsListSearchPage from '../patient/components/LabListSearchPage';
import AllPatientsList from '../provider/AllPatientsList';
import { RouteComponentProps, withRouter } from 'react-router';
import { SearchRoutingForPatient, SearchRoutingForProvider } from '../../constants/tabRouting/routing';

type StateModel = {
    tabIndex: number;
    user: UserModel | null;
};

type DispatchModel = {
    setTabIndex: typeof PatientActions.setTabIndex;
};

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const Search: React.FC<PropsTypes> = props => {
    const { user, setTabIndex, tabIndex, history } = props;

    useEffect(() => {
        return () => {
            setTabIndex(0);
        };
    }, [setTabIndex]);

    useEffect(() => {
        switch (history.location.pathname) {
            case SearchRoutingForProvider[0].path: {
                setTabIndex(SearchRoutingForProvider[0].tab);
                break;
            }
            case SearchRoutingForPatient[0].path: {
                setTabIndex(SearchRoutingForPatient[0].tab);
                break;
            }
            case SearchRoutingForProvider[1].path: {
                setTabIndex(SearchRoutingForProvider[1].tab);
                break;
            }
            case SearchRoutingForProvider[2].path: {
                setTabIndex(SearchRoutingForProvider[2].tab);
                break;
            }
            default: {
                setTabIndex(0);
            }
        }
    }, [history, setTabIndex]);

    const getLocationValue = useCallback(
        (value: number) => {
            switch (value) {
                case SearchRoutingForProvider[0].tab: {
                    if ((user && user.role) === RoleOptions.PATIENT) {
                        return SearchRoutingForPatient[0].path;
                    } else {
                        return SearchRoutingForProvider[0].path;
                    }
                }
                case SearchRoutingForProvider[1].tab: {
                    return SearchRoutingForProvider[1].path;
                }
                case SearchRoutingForProvider[2].tab: {
                    return SearchRoutingForProvider[2].path;
                }
                default:
                    return SearchRoutingForProvider[0].path;
            }
        },
        [user]
    );

    const redirectToLab = useCallback(
        (labId: number) => {
            history.push(`/lab/${labId}`);
        },
        [history]
    );

    return (
        <>
            {!user && <Loading />}
            {user && (
                <>
                    {user.role === RoleOptions.PATIENT ? (
                        <HeaderHeadingWithLink>Providers / Labs / Pharmacies</HeaderHeadingWithLink>
                    ) : (
                        <HeaderHeadingWithLink>Patients / Labs / Pharmacies</HeaderHeadingWithLink>
                    )}

                    <SearchBlock
                        activeTab={tabIndex}
                        onChangeTab={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setTabIndex(newValue);
                            history.push(getLocationValue(newValue));
                        }}
                        userRole={user.role}
                    />

                    {tabIndex === 0 && (
                        <Fade in={tabIndex === 0} timeout={{ enter: 500, exit: 500 }}>
                            {user.role === RoleOptions.PATIENT ? <ProviderListSearchPage /> : <AllPatientsList />}
                        </Fade>
                    )}

                    {tabIndex === 1 && (
                        <Fade in={tabIndex === 1} timeout={{ enter: 500, exit: 500 }}>
                            <LabsListSearchPage redirectToLab={redirectToLab} />
                        </Fade>
                    )}

                    {tabIndex === 2 && (
                        <Fade in={tabIndex === 2} timeout={{ enter: 500, exit: 500 }}>
                            <div>
                                <SizeControllerWrapper>
                                    <Typography variant="body1">Coming soon.</Typography>
                                </SizeControllerWrapper>
                            </div>
                        </Fade>
                    )}
                </>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        tabIndex: state.patient.tabIndex,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setTabIndex: bindActionCreators(PatientActions.setTabIndex, dispatch),
    })
)(withRouter(Search));
