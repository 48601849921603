import React, { useCallback } from 'react';
import styled from 'styled-components';
import backIcon from '../static/icons/backIcon.svg';
import { Button, createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../theme/colors';
import SizeControllerWrapper from './SizeControllerWrapper';
import { RouteComponentProps, withRouter } from 'react-router';
import { GetAppOutlined } from '@material-ui/icons';

const styles = createStyles({
    button: {
        minWidth: '35px',
        height: '35px',
        padding: '0 !important',
        position: 'absolute',
        left: '-12px',
        top: '8px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
        },
    },
    heading: {
        fontSize: '18px',
        color: colors.white,
    },
    img: {
        height: '18px',
    },
});

const BackgroundWrapper = styled.div`
    width: 100%;
    height: 50px;
    background-color: ${colors.redDark};
`;

const Wrapper = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface InjectedProps {
    goBackLink?: string;
    fileUrl?: string;
}

type PropsTypes = InjectedProps & WithStyles<typeof styles> & RouteComponentProps<any>;

const HeaderHeadingWithLink: React.FC<PropsTypes> = props => {
    const { classes, fileUrl } = props;

    const getFile = useCallback(() => {
        fileUrl && window.open(fileUrl, '_blank');
    }, [fileUrl]);

    return (
        <>
            <BackgroundWrapper>
                <SizeControllerWrapper>
                    <Wrapper>
                        <Button
                            classes={{ root: classes.button }}
                            onClick={() =>
                                props.goBackLink ? props.history.push(props.goBackLink) : props.history.goBack()
                            }
                        >
                            <img src={backIcon} alt="" className={classes.img} />
                        </Button>
                        <Typography variant="h2" classes={{ root: classes.heading }}>
                            {props.children}
                        </Typography>
                        {fileUrl &&
                        <Button
                            classes={{root: classes.button}}
                            style={{left: 'auto', right: '-12px'}}
                            onClick={getFile}
                        >
                            <GetAppOutlined color={'secondary'}/>
                        </Button>
                        }
                    </Wrapper>
                </SizeControllerWrapper>
            </BackgroundWrapper>
        </>
    );
};

export default withStyles(styles)(withRouter(HeaderHeadingWithLink));
