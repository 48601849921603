import React from 'react';
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../../theme/colors';
import halfLogoBackground from '../../../../static/images/landing/main/halfLogoBackground.svg';
import SizeControllerWrapper from '../../../../shared/SizeControllerWrapper';
import { mediaQuery } from '../../../../constants/device';

const BackgroundWrapper = styled.div`
    padding: 12vh 0;
    background-image: url(${halfLogoBackground});
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-position: right 75%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    ${mediaQuery.laptop} {
        width: 50%;
        margin: 0 auto;
    }
`;

const ContactWrapper = styled.div`
    display: flex;
`;

const createStyles = makeStyles({
    heading: {
        fontSize: '18px',
        lineHeight: '27px',
        color: colors.textPrimary,
        textAlign: 'center',
        marginBottom: '46px',
    },
    text: {
        fontSize: '14px',
        lineHeight: '18px',
        color: colors.textSecondary,
        textAlign: 'center',
    },
    contact: {
        fontSize: '14px',
        lineHeight: '18px',
        color: colors.textPrimary,
        textAlign: 'center',
    },
});

const ComingSoonPage: React.FC = props => {
    const classes = createStyles(props);

    return (
        <BackgroundWrapper>
            <SizeControllerWrapper>
                <Wrapper>
                    <Typography variant={'button'} classes={{ root: classes.heading }}>
                        Welcome to UPmed, <br />
                        we're excited <br />
                        you've found us....
                    </Typography>
                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                        We're counting down the minutes until launch and are currently beta testing to ensure that this
                        site lives up to all the hype and serves all constituencies to a level that we are comfortable
                        staking our brand and reputation on.
                    </Typography>
                    <br />
                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                        If you have any questions about our product, launch schedules, etc., feel free to reach out to
                    </Typography>

                    <ContactWrapper>
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            Josh (
                        </Typography>
                        <Typography variant={'body1'} classes={{ root: classes.contact }}>
                            josh@upmed.io
                        </Typography>
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            )
                        </Typography>
                    </ContactWrapper>

                    <ContactWrapper>
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            or JC (
                        </Typography>
                        <Typography variant={'body1'} classes={{ root: classes.contact }}>
                            jcramey@upmed.io
                        </Typography>
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            )
                        </Typography>
                    </ContactWrapper>
                </Wrapper>
            </SizeControllerWrapper>
        </BackgroundWrapper>
    );
};

export default ComingSoonPage;
