import React, { useCallback } from 'react';
import upmedFooterLogo from '../static/images/upmedFooterLogo.svg';
import socialFacebook from '../static/icons/socialFacebook.svg';
import socialInstagram from '../static/icons/socialInstagram.svg';
import socialLinkedIn from '../static/icons/socialLinkedIn.png';
import styled from 'styled-components';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import SizeControllerWrapper from './SizeControllerWrapper';
import { colors } from '../theme/colors';
import { withRouter, RouteComponentProps } from 'react-router';
import { mediaQuery } from '../constants/device';

const createStyles = makeStyles(theme => ({
    divider: {
        width: '100%',
        height: '1px',
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: colors.gray1,
    },
    copyright: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
    logo: {
        marginTop: '94px',
        [theme.breakpoints.up('lg')]: {
            margin: 0,
        },
    },
    menuItem: {
        fontSize: '12px',
        textTransform: 'uppercase',
        color: colors.textPrimary,
        marginBottom: '40px',
        display: 'block',
        cursor: 'pointer',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'left',
        },
    },
    subMenuItem: {
        fontSize: '14px',
        lineHeight: '28px',
        color: colors.textSecondary,
        cursor: 'pointer',
    },
    linkLinkedIn: {
        width: '26px',
        opacity: '0.7',
        cursor: 'pointer',
    },
    link: {
        cursor: 'pointer',
    },
}));

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 28px;
    ${mediaQuery.laptop} {
        height: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const BottomLinks = styled.div`
    width: 179px;
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
`;

const DropdownWrapper = styled.div`
    text-align: center;
    padding: 56px 0 0;

    ${mediaQuery.laptop} {
        width: 60%;
        margin-right: 100px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid ${colors.grayUnderline};
        padding: 30px 20px;
    }
`;

const BottomWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
    ${mediaQuery.laptop} {
        margin: 0 12%;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
`;

const Wrapper = styled.div`
    ${mediaQuery.laptop} {
        height: 500px;
    }
`;

const ItemWrapper = styled.div``;

const menuItems = [
    { name: 'solutions', link: '/solutions' },
    { name: 'resources', link: '/resources' },
    // { name: 'features', link: '/' },
    { name: 'patient', link: '/patient' },
    { name: 'provider', link: '/provider' },
    { name: 'about us', link: '/about' },
];

type PropsTypes = RouteComponentProps<any>;

const Footer: React.FC<PropsTypes> = props => {
    const goToPage = (path: string) => {
        if (props.match.path === path) {
        } else {
            props.history.push(path);
        }
    };

    const openLink = useCallback((link: string) => {
        window.open(link, '_blank');
    }, []);

    const classes = createStyles(props);
    return (
        <Wrapper>
            <SizeControllerWrapper>
                <ContentWrapper>
                    <img src={upmedFooterLogo} alt="" className={classes.logo} />
                    <DropdownWrapper>
                        {menuItems.map((menu, index) => (
                            <ItemWrapper key={index}>
                                <Typography
                                    variant={'button'}
                                    classes={{ root: classes.menuItem }}
                                    onClick={() => goToPage(menu.link)}
                                >
                                    {menu.name}
                                </Typography>
                            </ItemWrapper>
                        ))}
                    </DropdownWrapper>
                </ContentWrapper>
            </SizeControllerWrapper>
            <BottomWrapper>
                <Divider classes={{ root: classes.divider }} />
                <BottomLinks>
                    <img
                        src={socialFacebook}
                        alt=""
                        className={classes.link}
                        onClick={() => openLink('https://www.facebook.com/upmedmgmt')}
                    />
                    <img
                        src={socialLinkedIn}
                        alt=""
                        className={classes.linkLinkedIn}
                        onClick={() => openLink('https://www.linkedin.com/company/upmedmgmt')}
                    />
                    <img
                        src={socialInstagram}
                        alt=""
                        className={classes.link}
                        onClick={() => openLink('https://www.instagram.com/upmedllc')}
                    />
                </BottomLinks>
                <Typography variant="body1" classes={{ root: classes.copyright }}>
                    Copyright © 2019 UpMed
                </Typography>
            </BottomWrapper>
        </Wrapper>
    );
};

export default withRouter(Footer);
