import React from 'react';
import { createStyles, Divider, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import AvatarShared from '../shared/AvatarShared';
import CustomLink from '../../shared/link/CustomLink';
import moment from 'moment';
import { NotificationModel, NotificationTypes } from '../../models/responses/notification.responce';
import patientAvatar from '../../static/icons/defaultAvatar.svg';
import providerAvatar from '../../static/images/provider/providerAvatar.svg';
import { convertCentsToDollars } from '../../helpers/convertMoney';
import styled from 'styled-components';

const AvatarWrapper = styled.div`
    margin-right: 10px;
`;

const useStyles = makeStyles(() =>
    createStyles({
        headingNew: {
            fontSize: '14px',
            color: colors.redMain,
            fontWeight: 600,
        },
        headingOld: {
            fontSize: '14px',
            color: colors.textPrimary,
        },
        dateText: {
            color: colors.textSecondary,
            fontSize: '10px',
        },
    })
);

interface InjectedProps {
    notification: NotificationModel;
    markOneAsRead: (id: number) => void;
}

const NotificationListItem: React.FC<InjectedProps> = props => {
    const { notification, markOneAsRead } = props;
    const classes = useStyles();

    const getAvatar = (notification: NotificationModel) => {
        switch (notification.type) {
            case NotificationTypes.PATIENT_REQUEST:
            case NotificationTypes.INVOICE_PAID:
            case NotificationTypes.INVOICE_REJECTED:
            case NotificationTypes.INVOICE_ACTIVE:
            case NotificationTypes.INVOICE_INACTIVE:
            case NotificationTypes.NEW_PRESCRIPTION:
            case NotificationTypes.LABWORK_OUT_OF_RANGE: {
                return `${
                    !!notification.patientAvatarThumbNailUrl ? notification.patientAvatarThumbNailUrl : patientAvatar
                }`;
            }
            case NotificationTypes.PROVIDER_ACCEPT:
            case NotificationTypes.PROVIDER_DECLINE:
            case NotificationTypes.CHANGE_PROVIDER:
            case NotificationTypes.ANOTHER_PROVIDER_ACCEPT:
            case NotificationTypes.ANOTHER_PROVIDER_DECLINE:
            case NotificationTypes.INVOICE_NEW:
            case NotificationTypes.INVOICE_REQUIRES_ACTION:
            case NotificationTypes.INVOICE_FAIL: {
                return `${
                    !!notification.providerAvatarThumbNailUrl ? notification.providerAvatarThumbNailUrl : providerAvatar
                }`;
            }
            default:
                return '';
        }
    };

    const getHeading = (notification: NotificationModel) => {
        switch (notification.type) {
            case NotificationTypes.PATIENT_REQUEST: {
                return `${notification.patientFirstName} ${notification.patientLastName} sent Request`;
            }
            case NotificationTypes.PROVIDER_ACCEPT: {
                return `${notification.providerFirstName} ${notification.providerLastName} accepted Request`;
            }
            case NotificationTypes.PROVIDER_DECLINE: {
                return `${notification.providerFirstName} ${notification.providerLastName} declined Request`;
            }
            case NotificationTypes.CHANGE_PROVIDER: {
                return `${notification.patientFirstName} ${notification.patientLastName} sent request to provider ${notification.providerFirstName} ${notification.providerLastName}`;
            }
            case NotificationTypes.ANOTHER_PROVIDER_DECLINE: {
                return `${notification.providerFirstName} ${notification.providerLastName} declined ${notification.patientFirstName} ${notification.patientLastName}'s request `;
            }
            case NotificationTypes.ANOTHER_PROVIDER_ACCEPT: {
                return `${notification.providerFirstName} ${notification.providerLastName} accepted ${notification.patientFirstName} ${notification.patientLastName}'s request `;
            }
            case NotificationTypes.INVOICE_NEW: {
                return `${notification.providerFirstName} ${
                    notification.providerLastName
                } created new invoice $${convertCentsToDollars(notification.amount)}`;
            }
            case NotificationTypes.INVOICE_REQUIRES_ACTION: {
                return `Action required for invoice $${convertCentsToDollars(notification.amount)} created by ${
                    notification.providerFirstName
                } ${notification.providerLastName}`;
            }
            case NotificationTypes.INVOICE_FAIL: {
                return `Failed invoice payment $${convertCentsToDollars(notification.amount)} created by ${
                    notification.providerFirstName
                } ${notification.providerLastName}`;
            }
            case NotificationTypes.INVOICE_PAID: {
                return `${notification.patientFirstName} ${notification.patientLastName} paid $${convertCentsToDollars(
                    notification.amount
                )} invoice`;
            }
            case NotificationTypes.INVOICE_REJECTED: {
                return `${notification.patientFirstName} ${
                    notification.patientLastName
                } rejected $${convertCentsToDollars(notification.amount)} invoice`;
            }
            case NotificationTypes.INVOICE_ACTIVE: {
                return `${notification.patientFirstName} ${notification.patientLastName} paid $${convertCentsToDollars(
                    notification.amount
                )} subscription`;
            }
            case NotificationTypes.INVOICE_INACTIVE: {
                return `${notification.patientFirstName} ${
                    notification.patientLastName
                } canceled $${convertCentsToDollars(notification.amount)} subscription`;
            }
            case NotificationTypes.NEW_PRESCRIPTION: {
                return `Patient ${notification.patientFirstName} ${notification.patientLastName} added new Dosage`;
            }
            case NotificationTypes.LABWORK_OUT_OF_RANGE: {
                return `Patient ${notification.patientFirstName} ${notification.patientLastName} has Bloodwork out of range`;
            }
            default:
                return 'unknown';
        }
    };

    const getRedirectUrl = (notification: NotificationModel) => {
        switch (notification.type) {
            case NotificationTypes.PATIENT_REQUEST: {
                return `/patient-request/${notification.patientId}`;
            }
            case NotificationTypes.PROVIDER_ACCEPT:
            case NotificationTypes.PROVIDER_DECLINE:
            case NotificationTypes.ANOTHER_PROVIDER_ACCEPT:
            case NotificationTypes.ANOTHER_PROVIDER_DECLINE: {
                return `/provider/${notification.providerId}`;
            }
            case NotificationTypes.CHANGE_PROVIDER: {
                return `/profile-patient/${notification.patientId}`;
            }
            case NotificationTypes.INVOICE_NEW:
            case NotificationTypes.INVOICE_REQUIRES_ACTION:
            case NotificationTypes.INVOICE_FAIL: {
                return `/payments/view/${notification.invoiceId}`;
            }
            case NotificationTypes.INVOICE_PAID:
            case NotificationTypes.INVOICE_REJECTED:
            case NotificationTypes.INVOICE_ACTIVE:
            case NotificationTypes.INVOICE_INACTIVE: {
                return `/payments/view/${notification.invoiceId}/${notification.patientId}`;
            }
            case NotificationTypes.NEW_PRESCRIPTION: {
                return `/prescription/${notification.prescriptionId}/${notification.patientId}`;
            }
            case NotificationTypes.LABWORK_OUT_OF_RANGE: {
                return `/bloodwork/${notification.labWorkId}/${notification.patientId}`;
            }
            default:
                return '';
        }
    };

    return (
        <>
            <CustomLink to={getRedirectUrl(notification)}>
                <ListItem
                    button
                    onClick={() => markOneAsRead(notification.id)}
                    style={{
                        backgroundColor: notification.viewed ? colors.white : colors.gray2,
                        opacity: notification.viewed ? 0.7 : 1,
                        padding: '0px 10px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    alignItems="flex-start"
                >
                    <AvatarWrapper>
                        <AvatarShared img={getAvatar(notification)} />
                    </AvatarWrapper>
                    <ListItemText
                        primary={
                            <Typography
                                variant="body1"
                                classes={{
                                    root: notification.viewed ? classes.headingOld : classes.headingNew,
                                }}
                            >
                                {getHeading(notification)}
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body1" classes={{ root: classes.dateText }}>
                                {moment(notification.createdDate).format('h:mma  MM.DD.YYYY')}{' '}
                            </Typography>
                        }
                    />
                </ListItem>
            </CustomLink>
            <Divider />
        </>
    );
};

export default NotificationListItem;
