import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme/colors';
import { createStyles, Typography, WithStyles, withStyles } from '@material-ui/core';
import SizeControllerWrapper from './SizeControllerWrapper';

const Header = styled.div`
    background-color: ${colors.gray1};
    height: 80px;
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const styles = createStyles({
    heading: {
        fontSize: '24px',
        lineHeight: '36px',
    },
});

interface Props extends WithStyles<typeof styles> {
    text: string;
}

function HeaderTitle(props: Props) {
    return (
        <Header>
            <SizeControllerWrapper>
                <TextWrapper>
                    <Typography variant="h2" classes={{ root: props.classes.heading }}>
                        {props.text}
                    </Typography>
                </TextWrapper>
            </SizeControllerWrapper>
        </Header>
    );
}

export default withStyles(styles)(HeaderTitle);
