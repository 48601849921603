import React, { useCallback, useMemo } from 'react';
import { Button, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import forwardIcon from '../../static/icons/forwardIcon.svg';
import { BloodtargetReponce } from '../../models/responses/bloodtarget.reponce';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers/index';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import { PublicOutlined } from '@material-ui/icons';

const createStyles = makeStyles({
    listItem: {
        padding: 0,
    },
    nameText: {
        fontSize: '14px',
        color: colors.textPrimary,
    },
    typeText: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'capitalize',
    },
    avatarWrapper: {
        minWidth: 'auto',
        marginRight: '10px',
        position: 'relative',
    },
    forwardIcon: {
        padding: '0 !important',
        minWidth: '25px',
        height: '25px',
    },
    globalIcon: {
        opacity: 0.4,
        width: '20px',
        marginRight: '4px',
    },
});

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: -6px;
`;

interface InjectedProps {
    target: BloodtargetReponce;
}

interface StoreModel {
    user?: UserModel | null;
}

const BloodTargetListItem: React.FC<InjectedProps & RouteComponentProps<any> & StoreModel> = props => {
    const { target, user } = props;

    const redirect = useCallback(() => {
        if (user) {
            props.history.push(
                user.role === RoleOptions.ADMIN ? `/admin/bloodtarget/${target.id}` : `/bloodtarget/${target.id}`
            );
        }
    }, [props.history, target, user]);

    const isLocked = useMemo(() => {
        if (user) {
            return user.role !== RoleOptions.ADMIN && user.id !== target.createdBy;
        }
        return true;
    }, [user, target]);

    const classes = createStyles(props);
    return (
        <ListItem classes={{ root: classes.listItem }} button onClick={redirect}>
            <SizeControllerWrapper>
                <Wrapper>
                    <ListItemText
                        primary={
                            <TextWrapper>
                                <Typography variant="button" classes={{ root: classes.nameText }}>
                                    {`${target.target}  - ${target.bloodTargetPanelType}`}
                                </Typography>
                            </TextWrapper>
                        }
                        secondary={
                            <Typography variant="body1" classes={{ root: classes.typeText }}>
                                {`Male: ${target.maleMinRange} - ${target.maleMaxRange} | Female: ${target.femaleMinRange} - ${target.femaleMaxRange}`}
                            </Typography>
                        }
                    />
                    {isLocked && <PublicOutlined className={classes.globalIcon} />}
                    <Button classes={{ root: classes.forwardIcon }}>
                        <img src={forwardIcon} alt="" />
                    </Button>
                </Wrapper>
            </SizeControllerWrapper>
        </ListItem>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    null
)(withRouter(BloodTargetListItem));
