import React from 'react';
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../../theme/colors';
import SizeControllerWrapper from '../../../../shared/SizeControllerWrapper';
import { mediaQuery } from '../../../../constants/device';
import { RouteComponentProps, withRouter } from 'react-router';

const BackgroundWrapper = styled.div`
    width: 100%;
    background: ${colors.redMain};
    height: 364px;
    padding: 78px 0 40px 0;
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;

    ${mediaQuery.tablet} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const TextWrapper = styled.div`
    ${mediaQuery.tablet} {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
`;

const createStyles = makeStyles(theme => ({
    heading: {
        color: colors.white,
        fontSize: '30px',
        lineHeight: '36px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '40px',
        },
    },
    text: {
        color: colors.white,
        fontSize: '16px',
    },
    learnMore: {
        color: colors.white,
        fontSize: '14px',
        cursor: 'pointer',
    },
    imgIcon: {
        [theme.breakpoints.up('sm')]: {
            height: '150px',
            width: '150px',
        },
        [theme.breakpoints.up('md')]: {
            height: '200px',
            width: '200px',
            marginRight: '55px',
        },
    },
}));

interface InjectedProps {
    icon: string;
    heading: string;
    text: string;
    link?: string;
}

type PropsTypes = InjectedProps & RouteComponentProps;

const RedInfoBlock: React.FC<PropsTypes> = props => {
    const { icon, heading, text, link } = props;
    const classes = createStyles(props);
    return (
        <BackgroundWrapper>
            <SizeControllerWrapper>
                <Wrapper>
                    <img src={icon} className={classes.imgIcon} alt="" />
                    <TextWrapper>
                        <Typography variant={'button'} classes={{ root: classes.heading }}>
                            {heading}
                        </Typography>
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            {text}
                        </Typography>
                        {link && (
                            <Typography
                                variant={'button'}
                                classes={{ root: classes.learnMore }}
                                onClick={() => {
                                    link && window.scrollTo(0,0);
                                    props.history.push(link);
                                }}
                            >
                                Learn more
                            </Typography>
                        )}
                    </TextWrapper>
                </Wrapper>
            </SizeControllerWrapper>
        </BackgroundWrapper>
    );
};

export default withRouter(RedInfoBlock);
