import React from 'react';
import { Dialog, makeStyles, Typography } from '@material-ui/core';
import theme from '../../../theme/theme';
import { colors } from '../../../theme/colors';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import SecondaryButton from '../../../shared/button/SecondaryButton';
import styled from 'styled-components';
import CustomLink from '../../../shared/link/CustomLink';

const createStyles = makeStyles({
    dialogPaper: {
        height: '36%',
        boxShadow: theme.boxShadowCard,
        padding: '19px',
        borderRadius: '30px',
        backgroundColor: colors.white,
        margin: '0',
        width: '73%',
        top: '6vh',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    dialogText: {
        fontSize: '16px',
        lineHeight: '24p',
    },
});

interface InjectedProps {
    open: boolean;
    close: any;
}

const ButtonBlock = styled.div`
    height: 135px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ChangeProviderDialog: React.FC<InjectedProps> = props => {
    const classes = createStyles(props);
    return (
        <Dialog open={props.open} classes={{ paper: classes.dialogPaper }} onClose={props.close}>
            <Typography variant="button" classes={{ root: classes.dialogText }}>
                Are you sure you want <br />
                to change provider?
            </Typography>
            <ButtonBlock>
                <CustomLink to="/provider-linking">
                    <PrimaryButton>Yes</PrimaryButton>
                </CustomLink>
                <SecondaryButton onClick={props.close}>No</SecondaryButton>
            </ButtonBlock>
        </Dialog>
    );
};

export default ChangeProviderDialog;
