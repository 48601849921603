import React, { useCallback, useEffect, useMemo } from 'react';
import { List } from '@material-ui/core';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../shared/Loading';
import { RouteComponentProps } from 'react-router-dom';
import weighManagementIcon from '../../../static/icons/weight-white-icon.svg';
import HeaderHeadingWithLink from '../../../shared/HeaderHeadingWithLink';
import WeightListItem from '../ui/weightListItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateModel } from '../../../store/reducers';
import { UserModel } from '../../../models/responses/user.response';
import {
    changeWeightManagementLoadingState,
    fetchAllWeightRecords,
} from '../../../store/actions/weightManagement.actions';
import { WeightManagementLoadingState, WeightStateModel } from '../../../store/reducers/weight';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { WeightManagementRoutes } from '../enums/routes';
import NoInfoAvailable from './NoInfoAvailable';
import { Actions as PatientActions } from '../../../store/actions/patient.actions';

const ListWrapper = styled.div`
    display: block;
    height: 80%;
`;

const BtnWrapper = styled.div`
    display: flex;
    height: 20%;
    justify-content: center;
`;

const Wrapper = styled.div`
    display: flex;
    img {
        margin: 0 6px 6px 0;
    }
`;

interface IProps extends RouteComponentProps {}

const CREATE_WEIGHT_RECORD_PATH = `${WeightManagementRoutes.BASE_ROUTE}${WeightManagementRoutes.CREATE}`;

export function WeightRecordsList({ history: { push } }: IProps) {
    const user = useSelector<RootStateModel, UserModel>(state => state.authorization.user!);
    const loadingState = useSelector<RootStateModel, WeightManagementLoadingState>(
        state => state.weightManagement.loadingState
    );

    const { weightList, pagingData } = useSelector<RootStateModel, WeightStateModel>(state => state.weightManagement);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(PatientActions.getPatient());
    }, [dispatch]);

    const goToCreate = useCallback(() => {
        push(CREATE_WEIGHT_RECORD_PATH);
    }, [push]);

    useEffect(() => {
        dispatch(fetchAllWeightRecords());
        dispatch(changeWeightManagementLoadingState(WeightManagementLoadingState.IDLE));
    }, [dispatch]);

    const isNoData = useMemo(() => {
        return !weightList.length && loadingState === WeightManagementLoadingState.FETCHING_LIST_SUCCESS;
    }, [weightList, loadingState]);

    return (
        <>
            <HeaderHeadingWithLink>
                <Wrapper>
                    <img width={20} src={weighManagementIcon} alt="" />
                    <span>Weight Management</span>
                </Wrapper>
            </HeaderHeadingWithLink>
            {isNoData && <NoInfoAvailable />}
            {!!weightList.length && (
                <>
                    <ListWrapper>
                        <List>
                            <InfiniteScroll
                                dataLength={pagingData.total}
                                next={() => {}}
                                hasMore={false}
                                loader={<Loading />}
                            >
                                {weightList.map(record => (
                                    <WeightListItem user={user} key={record.id} weightRecord={record} />
                                ))}
                            </InfiniteScroll>
                        </List>
                    </ListWrapper>

                    <BtnWrapper>
                        <PrimaryButton onClick={goToCreate}>Add New Record</PrimaryButton>
                    </BtnWrapper>
                </>
            )}
        </>
    );
}
