import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, ListItem, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import profile from '../../static/icons/sidebar/profile.png';
import linking from '../../static/icons/sidebar/linking.png';
import search from '../../static/icons/sidebar/search.png';
import payments from '../../static/icons/sidebar/payments.png';
import bloodwork from '../../static/icons/sidebar/bloodwork.png';
import panelTypes from '../../static/icons/sidebar/panelType.svg';
import bloodTarget from '../../static/icons/sidebar/bloodTargets.svg';
import dosage from '../../static/icons/sidebar/dosage.png';
import weight from '../../static/icons/sidebar/weight.png';
import analytics from '../../static/icons/sidebar/analytics.png';
import wizard from '../../static/icons/sidebar/wizard.png';
import settings from '../../static/icons/sidebar/settings.png';
import info from '../../static/icons/sidebar/info.png';
import myPatients from '../../static/icons/sidebar/myPatients.png';
import homeIcon from '../../static/icons/sidebar/homeIcon.png';
import linkingIcon from '../../static/icons/sidebar/linkingIcon.png';
import signOut from '../../static/icons/sidebar/signOut.png';
import DropdownMenuMobile from '../input/DropdownMenuMobile';
import { RoleOptions } from '../../constants/roleOptions';
import { mediaQuery } from '../../constants/device';
import HeaderAvatar from '../header/HeaderAvatar';
import { UserModel } from '../../models/responses/user.response';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers/index';
import * as invoiceAction from '../../store/actions/invoice.actions';
import * as tourActions from '../../store/actions/tour.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { InvoiceModel } from '../../models/responses/invoice.model';
import { InvoiceStatus } from '../../constants/invoice/invoiceStatus';
import Badge from '../../pages/payment/components/Badge';
import { PatientModel } from '../../models/responses/patient.response';
import { ProviderModel } from '../../models/responses/provider.response';
import {
    DosageRouting,
    PaymentRoutingPatient,
    PaymentRoutingProvider,
    ProviderRouting,
    SearchRoutingForPatient,
    SearchRoutingForProvider,
} from '../../constants/tabRouting/routing';
import { MenuNavItems } from '../../constants/menuNavItems';
import { useLocation } from 'react-use';
import { WeightManagementRoutes } from '../../pages/weightManagement/enums/routes';
import { PatientStepClassName } from '../../pages/tour/constants/patientStepsClassNames';

const createStyles = makeStyles(theme => ({
    menuItem: {
        fontSize: '14px',
        textTransform: 'uppercase',
        color: colors.textPrimary,
        marginLeft: '24px',
        marginTop: '3px',
        display: 'block',
    },
    menuItemRed: {
        fontSize: '14px',
        textTransform: 'uppercase',
        color: colors.redMain,
        marginLeft: '24px',
        marginTop: '3px',
        display: 'block',
    },
    menuItemGray: {
        fontSize: '14px',
        textTransform: 'uppercase',
        color: colors.textSecondary,
        marginLeft: '24px',
        marginTop: '3px',
        display: 'block',
    },
    listItem: {
        padding: '12px 28px',
    },
    selected: {
        backgroundColor: 'transparent !important',
        '& >span': {
            color: `${colors.redMain} !important`,
        },
    },
    divider: {
        background: 'rgba(0, 0, 0, 0.08)',
        height: '2px',
        marginLeft: '20px',
        width: '200px',
        [theme.breakpoints.up('md')]: {
            width: '300px',
            marginLeft: 0,
        },
    },
    img: {
        width: '24px',
    },
    nameText: {
        fontSize: '18px',
        lineHeight: '20px',
    },
    roundButton: {
        width: '60px',
        height: '60px',
        minWidth: '60px',
        borderRadius: '30px',
    },
    comingSoonBadge: {
        padding: '0 8px !important',
        minWidth: 'auto',
        background: 'rgba(225,225,225,0.8)',
        height: '25px',
        marginLeft: '8px',
        fontSize: '10px',
    },
    adminText: {
        fontSize: '10px',
        color: colors.textSecondary,
    },
}));

const DropdownOptionsWrapper = styled.div`
    width: 100%;
    max-width: 315px;
    padding: 24px 0;
    text-align: center;

    ${mediaQuery.laptop} {
        padding: 30px;
        width: 300px;
    }
`;

const GeneralProfileHeader = styled.div`
    display: none;
    ${mediaQuery.laptop} {
        display: flex;
        align-items: center;
        padding: 30px 0 0 52px;
    }
`;

const InfoWrapper = styled.div`
    margin-left: 10px;
`;

const AdminBadge = styled.div`
    width: 53px;
    background: #f3f3f3;
    box-sizing: border-box;
    border-radius: 200px;
    margin-left: 9px;
    text-align: center;
`;

const BadgeWrapper = styled.div`
    position: absolute;
    right: 116px;
    top: 11px;
`;

interface StoreModel {
    countOneTimeRequested: number;
    recurringSubscription: InvoiceModel[];
    patient: PatientModel | null;
    provider: ProviderModel | null;
}

interface DispatchModel {
    getOneTimeRequestedPatientCount: typeof invoiceAction.Actions.getOneTimeRequestedPatientCount;
    getAllRecurringSubscriptionPatient: typeof invoiceAction.Actions.getAllRecurringSubscriptionPatient;
    setTourVisible: typeof tourActions.Actions.setTourVisible;
}

interface InjectedProps {
    user: UserModel;
    goToPage: (path: string) => void;
    closeSidebar: () => void;
    removeCurrentUser: () => void;
    resetStatusVariables: () => void;
}

type PropsTypes = RouteComponentProps<any> & InjectedProps & StoreModel & DispatchModel;

const SidebarMenuAuth: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    const [activeLink, setActiveLink] = useState(props.location.pathname);

    const state = useLocation();

    const {
        getAllRecurringSubscriptionPatient,
        user,
        getOneTimeRequestedPatientCount,
        recurringSubscription,
        countOneTimeRequested,
        closeSidebar,
        goToPage,
        setTourVisible,
        history,
    } = props;

    useEffect(() => {
        if (user && user.role === RoleOptions.PATIENT) {
            getOneTimeRequestedPatientCount();
            getAllRecurringSubscriptionPatient();
        }
    }, [user, getOneTimeRequestedPatientCount, getAllRecurringSubscriptionPatient]);

    const goToProfile = useCallback(() => {
        if (!history.location.pathname.includes('profile')) {
            if (user) {
                history.push(user.role === RoleOptions.ADMIN ? '/admin/profile' : '/profile');
            }
        }
        closeSidebar();
    }, [user, history, closeSidebar]);

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.location.pathname = '';
    };

    const paymentNumber = useMemo(() => {
        const recurringNumber = recurringSubscription.filter(s => s.status === InvoiceStatus.REQUESTED).length;
        const total = countOneTimeRequested + recurringNumber;
        return total >= 10 ? '9+' : total;
    }, [countOneTimeRequested, recurringSubscription]);

    const redirect = useCallback(
        (path: string) => {
            goToPage(path);
        },
        [goToPage]
    );

    useEffect(() => {
        state.pathname && setActiveLink(state.pathname);
    }, [state.pathname]);

    const CustomListItem: React.FC<{ link: string; redirect: any; disabled?: boolean }> = props => {
        const { link, redirect, disabled } = props;
        return (
            <ListItem
                disabled={disabled}
                selected={activeLink.includes(link)}
                classes={{ root: classes.listItem, selected: classes.selected }}
                button
                onClick={redirect}
            >
                {props.children}
            </ListItem>
        );
    };

    const CustomMenuItem: React.FC<{ text: string; img: string }> = props => {
        const { img, text } = props;
        return (
            <>
                <img src={img} alt="" className={classes.img} />
                <Typography variant="button" classes={{ root: classes.menuItem }}>
                    {text}
                </Typography>
            </>
        );
    };

    const setTour = useCallback(() => {
        setTourVisible(true);
    }, [setTourVisible]);

    const getSidebarContent = useCallback(() => {
        switch (user.role) {
            case RoleOptions.PATIENT: {
                return (
                    <>
                        <DropdownOptionsWrapper>
                            <CustomListItem link={'profile'} redirect={goToProfile}>
                                <CustomMenuItem text={'profile'} img={profile} />
                            </CustomListItem>

                            <CustomListItem link={'provider-status'} redirect={() => redirect('/provider-status')}>
                                <CustomMenuItem text={'my provider'} img={linking} />
                            </CustomListItem>

                            <CustomListItem link={'search'} redirect={() => redirect(SearchRoutingForPatient[0].path)}>
                                <CustomMenuItem text={'search'} img={search} />
                            </CustomListItem>

                            <CustomListItem link={'payments'} redirect={() => redirect(PaymentRoutingPatient[0].path)}>
                                <CustomMenuItem text={'payments'} img={payments} />
                                <BadgeWrapper>{!!paymentNumber && <Badge>{paymentNumber}</Badge>}</BadgeWrapper>
                            </CustomListItem>

                            <Divider classes={{ root: classes.divider }} />

                            <ListItem
                                selected={activeLink.includes('bloodwork') || activeLink === '/upload-results'}
                                classes={{ root: classes.listItem, selected: classes.selected }}
                                button
                                onClick={() => redirect('/bloodwork-reports')}
                            >
                                <CustomMenuItem text={'bloodwork'} img={bloodwork} />
                            </ListItem>

                            <CustomListItem link={'bloodtargets'} redirect={() => redirect('/bloodtargets')}>
                                <CustomMenuItem text={'blood targets'} img={bloodTarget} />
                            </CustomListItem>

                            <ListItem
                                selected={activeLink.includes('dosage') || activeLink.includes('add-prescription')}
                                classes={{ root: classes.listItem, selected: classes.selected }}
                                button
                                onClick={() => redirect(DosageRouting[0].path)}
                            >
                                <CustomMenuItem text={'dosage'} img={dosage} />
                            </ListItem>

                            <CustomListItem
                                link={'weight'}
                                redirect={() => redirect(WeightManagementRoutes.BASE_ROUTE)}
                            >
                                <CustomMenuItem text={'weight'} img={weight} />
                            </CustomListItem>

                            <CustomListItem link={'analytics'} redirect={() => redirect('/analytics')}>
                                <CustomMenuItem text={'analytics'} img={analytics} />
                            </CustomListItem>

                            <Divider classes={{ root: classes.divider }} />

                            <DropdownMenuMobile
                                goToPage={goToPage}
                                icon={info}
                                buttonText={'info'}
                                options={MenuNavItems}
                            />

                            <div className={PatientStepClassName.FINISH}>
                                <CustomListItem link={'wizard'} redirect={setTour}>
                                    <CustomMenuItem text={'wizard help'} img={wizard} />
                                </CustomListItem>
                            </div>
                            <CustomListItem link={'settings'} redirect={() => redirect('/settings')}>
                                <CustomMenuItem text={'settings'} img={settings} />
                            </CustomListItem>

                            <CustomListItem link={'logout'} redirect={logOut}>
                                <CustomMenuItem text={'signOut'} img={signOut} />
                            </CustomListItem>
                        </DropdownOptionsWrapper>
                    </>
                );
            }
            case RoleOptions.PROVIDER: {
                return (
                    <DropdownOptionsWrapper>
                        <ListItem
                            selected={activeLink.includes('profile') && !activeLink.includes('profile-patient')}
                            classes={{ root: classes.listItem, selected: classes.selected }}
                            button
                            onClick={() => redirect('/profile')}
                        >
                            <CustomMenuItem text={'profile'} img={profile} />
                        </ListItem>

                        <CustomListItem link={'provider'} redirect={() => redirect(ProviderRouting[0].path)}>
                            <CustomMenuItem text={'my patients'} img={myPatients} />
                        </CustomListItem>

                        <CustomListItem link={'search'} redirect={() => redirect(SearchRoutingForProvider[0].path)}>
                            <CustomMenuItem text={'search'} img={search} />
                        </CustomListItem>

                        <ListItem
                            selected={
                                activeLink.includes('payments') ||
                                activeLink.includes('create-merchant') ||
                                activeLink.includes('setup-merchant') ||
                                activeLink.includes('created-merchant')
                            }
                            classes={{ root: classes.listItem, selected: classes.selected }}
                            button
                            onClick={() => redirect(PaymentRoutingProvider[0].path)}
                        >
                            <CustomMenuItem text={'payments'} img={payments} />
                        </ListItem>

                        <Divider classes={{ root: classes.divider }} />

                        <DropdownMenuMobile
                            goToPage={goToPage}
                            icon={info}
                            buttonText={'info'}
                            options={MenuNavItems}
                        />

                        <CustomListItem link={'logout'} redirect={logOut}>
                            <CustomMenuItem text={'signOut'} img={signOut} />
                        </CustomListItem>
                    </DropdownOptionsWrapper>
                );
            }
            case RoleOptions.ADMIN: {
                return (
                    <DropdownOptionsWrapper>
                        <CustomListItem link={'admin/labs'} redirect={() => redirect('/admin/labs')}>
                            <CustomMenuItem text={'labs'} img={homeIcon} />
                        </CustomListItem>

                        <CustomListItem link={'admin/providers'} redirect={() => redirect('/admin/providers')}>
                            <CustomMenuItem text={'providers'} img={linkingIcon} />
                        </CustomListItem>

                        <CustomListItem link={'admin/search'} redirect={null} disabled>
                            <CustomMenuItem text={'search'} img={search} />
                        </CustomListItem>

                        <Divider classes={{ root: classes.divider }} />

                        <CustomListItem link={'admin/payments'} redirect={null} disabled>
                            <CustomMenuItem text={'payments'} img={payments} />
                        </CustomListItem>

                        <CustomListItem link={'admin/bloodtargets'} redirect={() => redirect('/admin/bloodtargets')}>
                            <CustomMenuItem text={'blood targets'} img={bloodTarget} />
                        </CustomListItem>

                        <CustomListItem link={'admin/panel-types'} redirect={() => redirect('/admin/panel-types')}>
                            <CustomMenuItem text={'panel types'} img={panelTypes} />
                        </CustomListItem>

                        <Divider classes={{ root: classes.divider }} />

                        <DropdownMenuMobile
                            goToPage={goToPage}
                            icon={info}
                            buttonText={'info'}
                            options={MenuNavItems}
                        />

                        <CustomListItem link={'admin/settings'} redirect={null} disabled>
                            <CustomMenuItem text={'settings'} img={settings} />
                        </CustomListItem>

                        <CustomListItem link={'logout'} redirect={logOut}>
                            <CustomMenuItem text={'signOut'} img={signOut} />
                        </CustomListItem>
                    </DropdownOptionsWrapper>
                );
            }
            default:
                return null;
        }
    }, [user, activeLink, classes, goToProfile, paymentNumber, redirect, goToPage, setTour]);

    return (
        <>
            <GeneralProfileHeader>
                <Button classes={{ root: classes.roundButton }} onClick={goToProfile}>
                    <HeaderAvatar user={user} />
                </Button>

                <InfoWrapper>
                    <Typography variant={'button'} classes={{ root: classes.nameText }}>
                        {user.firstName} {user.lastName}
                    </Typography>
                </InfoWrapper>

                {user.role === RoleOptions.ADMIN && (
                    <AdminBadge>
                        <Typography variant="body1" classes={{ root: classes.adminText }}>
                            Admin
                        </Typography>
                    </AdminBadge>
                )}
            </GeneralProfileHeader>
            {getSidebarContent()}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        countOneTimeRequested: state.invoice.countOneTimeRequested,
        recurringSubscription: state.invoice.recurringSubscriptionPatient,
        patient: state.patient.patient,
        provider: state.provider.currentProvider,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getOneTimeRequestedPatientCount: bindActionCreators(
            invoiceAction.Actions.getOneTimeRequestedPatientCount,
            dispatch
        ),
        getAllRecurringSubscriptionPatient: bindActionCreators(
            invoiceAction.Actions.getAllRecurringSubscriptionPatient,
            dispatch
        ),
        setTourVisible: bindActionCreators(tourActions.Actions.setTourVisible, dispatch),
    })
)(withRouter(SidebarMenuAuth));
