import React, { useCallback, useState } from 'react';
import { InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import successAdornment from '../../static/icons/successAdornment.svg';
import passVisible from '../../static/icons/passVisible.svg';
import passNotVisible from '../../static/icons/passNotVisible.svg';

const useStyles = makeStyles({
    root: (props: { color?: string }) => ({
        '& input': {
            fontSize: '12px',
            color: props.color ? props.color : colors.textPrimary,
            padding: '11px 12px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
        '& textarea': {
            fontSize: '12px',
            color: props.color ? props.color : colors.textPrimary,
            padding: '11px 12px',
        },
    }),
    underline: (props: { color?: string; error?: string }) => ({
        '&:after': {
            borderBottom: `1px solid ${
                props.color ? props.color : props.error ? colors.redMain : colors.grayUnderline
            } !important`,
        },
        '&:before': {
            borderBottom: `1px solid ${props.color ? props.color : colors.grayUnderline} !important`,
        },
        '&:hover:not($error):before': {
            borderBottom: `1px solid ${props.color ? props.color : colors.grayUnderline} !important`,
        },
    }),
    error: {
        '&:after': {
            borderBottom: `1px solid ${colors.redLight} !important`,
        },
    },
    endAdornment: {
        marginRight: '10px',
    },
    passwordIcon: {
        cursor: 'pointer',
    },
    disabled: (props: { color?: string }) => ({
        color: props.color ? props.color : `${colors.textSecondary} !important`,
    }),
    label: (props: { color?: string }) => ({
        color: props.color ? props.color : colors.textSecondary,
        fontSize: '12px',
        marginLeft: '7px',
    }),
});

interface InjectedProps {
    placeholder: string;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    onBlur?: () => void;
    onFocus?: () => void;
    error?: boolean | null;
    success?: boolean | null;
    password?: boolean;
    disabled?: boolean;
    inputProps?: any;
    onKeyDown?: (evt: any) => void;
    number?: boolean;
    noUnderline?: boolean;
    isInDropdown?: boolean;
    color?: string;
    adornment?: any;
    rows?: number;
    style?: any;
}

type PropsTypes = InjectedProps;

const TextInput: React.FC<PropsTypes> = props => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const classes = useStyles(props);

    const TextFieldLabelComponent = useCallback(
        () => (
            <Typography variant={'button'} classes={{ root: classes.label }}>
                {props.placeholder}
            </Typography>
        ),
        [classes.label, props.placeholder]
    );

    return (
        <TextField
            style={props.style}
            label={TextFieldLabelComponent()}
            InputProps={{
                classes: {
                    root: classes.root,
                    underline: classes.underline,
                    error: classes.error,
                    disabled: classes.disabled,
                },
                endAdornment:
                    props.success && !props.password ? (
                        <InputAdornment position="end" classes={{ root: classes.endAdornment }}>
                            <img src={successAdornment} alt="" />
                        </InputAdornment>
                    ) : props.password ? (
                        <InputAdornment position="end" classes={{ root: classes.endAdornment }}>
                            <img
                                src={passwordVisible ? passVisible : passNotVisible}
                                alt=""
                                className={classes.passwordIcon}
                                onClick={() => setPasswordVisible(!passwordVisible)}
                            />
                        </InputAdornment>
                    ) : props.adornment ? (
                        props.adornment
                    ) : null,
                ...(props.inputProps ? props.inputProps : {}),
            }}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            value={props.value}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            error={!!props.error}
            type={!props.password ? (props.number ? 'number' : 'text') : passwordVisible ? 'text' : 'password'}
            disabled={props.disabled}
            multiline={!!props.rows}
            rows={props.rows && props.rows}
            rowsMax={6}
        />
    );
};

export default TextInput;
