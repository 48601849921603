import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NewBlock from './new/NewBlock';
import { InvoiceModel } from '../../../models/responses/invoice.model';
import { connect } from 'react-redux';
import { Actions as InvoiceActions } from '../../../store/actions/invoice.actions';
import { bindActionCreators, Dispatch } from 'redux';
import * as fromRoot from '../../../store/reducers/index';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import styled from 'styled-components';
import Loading from '../../shared/Loading';
import RequestState from '../../../constants/requestState';
import { UserModel } from '../../../models/responses/user.response';
import {DefaultPageSize, shouldFetchData} from "../../../constants/dataFetching/allEntitiesByPages";

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

interface InjectedProps {
    canFetchPaymentData: boolean;
    user: UserModel | null;
}

interface StoreModel {
    recurringSubscriptionAllPatientsRequested: InvoiceModel[];
    pageNumber: number;
    totalInvoice: number | null;
    loadingRecurringSubscription: boolean;
    deleteRequest: RequestState;
    totalAmountInvoiceRecurringSubscriptionAllPatientsRequested: number;
}

interface DispatchModel {
    getAllRecurringSubscription: typeof InvoiceActions.getAllRecurringSubscriptionProviderAllPatientsRequested;
    increasePageNumber: typeof InvoiceActions.increasePageNumberAllRecurringSubscriptionProviderAllPatientsRequested;
}

type PropsTypes = StoreModel & DispatchModel & InjectedProps;

const RecurringSubscriptionAllPatientsRequested: React.FC<PropsTypes> = props => {
    const {
        recurringSubscriptionAllPatientsRequested,
        pageNumber,
        totalInvoice,
        loadingRecurringSubscription,
        getAllRecurringSubscription,
        increasePageNumber,
        canFetchPaymentData,
        deleteRequest,
        user,
        totalAmountInvoiceRecurringSubscriptionAllPatientsRequested,
    } = props;

    const [initialLoaded, setInitialLoaded] = useState(false);

    const loadData = useCallback((page, overrideRecords: boolean) => {
        getAllRecurringSubscription({
            size: DefaultPageSize,
            overrideRecords,
            page,
        });
    }, [getAllRecurringSubscription]);

    useEffect(() => {
        if (canFetchPaymentData) {
            loadData(0, true);
            setInitialLoaded(true);
        }
    }, [deleteRequest, canFetchPaymentData, loadData]);

    useEffect(() => {
        if ( shouldFetchData(loadingRecurringSubscription, pageNumber, recurringSubscriptionAllPatientsRequested.length, initialLoaded)) {
            loadData(pageNumber, false);
        }
    }, [loadingRecurringSubscription, pageNumber, recurringSubscriptionAllPatientsRequested, initialLoaded, loadData]);

    const loadMore = useCallback(() => {
        increasePageNumber();
    }, [increasePageNumber]);

    const hasMore = useMemo(() => {
        return totalInvoice ? recurringSubscriptionAllPatientsRequested.length < totalInvoice : false;
    }, [totalInvoice, recurringSubscriptionAllPatientsRequested]);

    return (
        <>
            <NewBlock user={user} invoices={recurringSubscriptionAllPatientsRequested} totalAmountInvoiceRecurringSubscriptionAllPatientsRequested={totalAmountInvoiceRecurringSubscriptionAllPatientsRequested}/>

            {hasMore && !loadingRecurringSubscription && (
                <ButtonWrapper>
                    <PrimaryButton onClick={loadMore}>Load More</PrimaryButton>
                </ButtonWrapper>
            )}

            {loadingRecurringSubscription && <Loading smallSize />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        recurringSubscriptionAllPatientsRequested: state.invoice.recurringSubscriptionAllPatientsRequested,
        pageNumber: state.invoice.pageNumberRecurringSubscriptionAllPatientsRequested,
        totalInvoice: state.invoice.totalInvoiceRecurringSubscriptionAllPatientsRequested,
        loadingRecurringSubscription: state.invoice.loadingRecurringSubscription,
        deleteRequest: state.invoice.deleteRequest,
        totalAmountInvoiceRecurringSubscriptionAllPatientsRequested: state.invoice.totalAmountInvoiceRecurringSubscriptionAllPatientsRequested,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllRecurringSubscription: bindActionCreators(
            InvoiceActions.getAllRecurringSubscriptionProviderAllPatientsRequested,
            dispatch
        ),
        increasePageNumber: bindActionCreators(
            InvoiceActions.increasePageNumberAllRecurringSubscriptionProviderAllPatientsRequested,
            dispatch
        ),
    })
)(RecurringSubscriptionAllPatientsRequested);
