import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { restAxios } from '../restAxios';
import { CreateWeightRecord } from '../../models/dto/createWeightRecord';
import { IPagingData } from '../../store/reducers/weight';
import { WeightRecordResponse } from '../../models/responses/weightRecord.response';

export default class WeightManagementApi {
    @HandleAsyncErrors()
    static async fetchById(id: number): Promise<WeightRecordResponse> {
        return (await restAxios.get(`private/weights/${id}`)).data;
    }

    @HandleAsyncErrors()
    static async fetchAll(): Promise<IPagingData<WeightRecordResponse>> {
        return (await restAxios.get('private/weights')).data;
    }

    @HandleAsyncErrors()
    static async createWeightRecord(data: CreateWeightRecord): Promise<WeightRecordResponse> {
        return (await restAxios.post('private/weights', data)).data;
    }

    @HandleAsyncErrors()
    static async editWeightRecord(data: CreateWeightRecord & {id: number}): Promise<WeightRecordResponse> {
        return (await restAxios.put(`private/weights/${data.id}`, data)).data;
    }

    @HandleAsyncErrors()
    static async deleteWeightRecord(id: number) {
        return (await restAxios.delete(`private/weights/${id}`)).data;
    }
}
