import React, { useCallback, useEffect, useRef, useState } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { FormControl, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import TextInput from '../../shared/input/TextInput';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { colors } from '../../theme/colors';
import CustomLink from '../../shared/link/CustomLink';
import DropdownInput from '../../shared/input/DropdownInput';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as AuthActions } from '../../store/actions/auth.actions';
import LoadingTransparent from '../shared/LoadingTransparent';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import RequestState from '../../constants/requestState';
import halfLogoBackground from '../../static/images/landing/main/halfLogoBackground.svg';
import { GenderOptions } from '../../constants/genderOptions';
import { ValidationErrorsMap } from '../../models/validation/validationErrorsMap';
import fillClassInstanceWithValues from '../../shared/utils/fillClassInstanceWithValues';
import { validateSync } from 'class-validator';
import { SignUpDto } from '../../models/validation/signUpValidation';
import { useDebounce } from 'use-debounce';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import Footer from '../../shared/Footer';
import authBackground from '../../static/images/authBackground.png';
import theme from '../../theme/theme';
import { TYPED_ENV } from '../../helpers/typedEnv';

const createStyles = makeStyles(theme => ({
    heading: {
        fontSize: '36px',
        margin: '0 auto',
        width: 'fit-content',
        marginBottom: '22px',
    },
    labelHelper: {
        width: 'fit-content',
        margin: '9px auto 30px',
        fontSize: '12px',
        color: colors.textSecondary,
    },
    registerLink: {
        textDecorationColor: colors.redMain,
    },
    registerText: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    forgotPasswordLink: {
        textDecorationColor: colors.textPrimary,
    },
    loginLinkText: {
        color: colors.redMain,
        fontSize: '14px',
        marginRight: '5px',
        '&:hover': {
            color: colors.redDark,
        },
    },
    form: {
        [theme.breakpoints.up('md')]: {
            width: '60%',
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            width: '350px',
        },
    },
}));

const Wrapper = styled.div`
    margin: 80px auto 37px;
`;

const InputWrapper = styled(FormControl)`
    padding-bottom: 25px !important;
`;

const FormWrapper = styled.div``;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 37px;
`;

const BackgroundWrapper = styled.div`
    background-image: url(${halfLogoBackground});
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-position: right 27%;

    ${mediaQuery.laptop} {
        width: 47%;
        display: flex;
        justify-content: center;
        padding: 0 0 40px;
        margin: 100px auto 30px;
        background-color: ${colors.white};
        box-shadow: ${theme.authCardBoxShadow};
    }
`;

const ErrorWrapper = styled.div`
    position: absolute;
`;

const BackgroundWrapperDesktop = styled.div`
    ${mediaQuery.laptop} {
        background-image: url(${authBackground});
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: -80px;
        padding-top: 90px;
        padding-bottom: 40px;
        min-height: calc(100vh - 90px);
    }
`;

interface StoreModel {
    loading: boolean;
    emailVerificationSent: RequestState;
}

interface DispatchModel {
    signUp: typeof AuthActions.signUp;
}

type PropsTypes = RouteComponentProps<any> & StoreModel & DispatchModel;

const userInitialState = {
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    password: '',
    passwordConfirm: '',
    isBeingEdited: new Set(),
};

const SignUp: React.FC<PropsTypes> = props => {
    const [user, setUser] = useState(userInitialState);
    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    const formRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        return () => {
            setValidationErrorsMap({});
            userInitialState.isBeingEdited = new Set();
        };
    }, []);

    const isConfirmPasswordValid = useCallback(
        (isOnSubmit: boolean) => {
            if ((user.password.length && user.isBeingEdited.has('passwordConfirm')) || isOnSubmit) {
                return user.password === user.passwordConfirm;
            }
            return true;
        },
        [user]
    );

    const validateForm = useCallback(
        (user: any, isOnSubmit = false) => {
            const item = fillClassInstanceWithValues<SignUpDto>(SignUpDto, user);
            const validationErrors = validateSync(item);
            const map: ValidationErrorsMap = {};
            if (validationErrors.length) {
                validationErrors.forEach(err => {
                    if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                        map[err.property] = Object.entries(err.constraints)
                            .map(([, value]) => value)
                            .join(', ');
                    }
                });
            }

            const newMap = isConfirmPasswordValid(isOnSubmit)
                ? map
                : { ...map, passwordConfirm: 'Passwords should be equal' };
            setValidationErrorsMap(newMap);
            return newMap;
        },
        [isConfirmPasswordValid]
    );

    useEffect(() => {
        validateForm(user);
    }, [user, validateForm]);

    const isValid = () => {
        const map = validateForm(user, true);
        return Object.entries(map).length === 0;
    };

    const changeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUser = {
            ...user,
            [e.target.id]: e.target.value,
            isBeingEdited: user.isBeingEdited.add(e.target.id),
        };
        if (e.target.value.length === 0 && e.target.id !== 'passwordConfirm') {
            newUser.isBeingEdited.delete(e.target.id);
        }
        setUser(newUser);
    };

    useEffect(() => {
        if (
            props.emailVerificationSent !== RequestState.UNSENT &&
            props.emailVerificationSent !== RequestState.SENDING
        ) {
            props.emailVerificationSent === RequestState.SENT_SUCCESS && props.history.push('/email-sent');
        }
    }, [props.emailVerificationSent, props.history]);

    const sendForm = () => {
        if (isValid()) {
            props.signUp({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                gender: user.gender,
                password: user.password,
                redirectUrl: `${TYPED_ENV.REACT_APP_BASE_URL}/`,
            });
        }
    };

    const setNextFieldFocus = () => {
        //find the index of gender dropdown and set focused next field
        if (formRef.current && dropdownRef.current) {
            // @ts-ignore
            const button = dropdownRef.current.getElementsByTagName('button')[0];
            const form = formRef.current;
            // @ts-ignore
            const formArray = [...formRef.current];
            const index = formArray.indexOf(button);
            // @ts-ignore
            form.elements[index + 1].focus();
        }
    };

    const handleEnter = (event: any) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const formArray = [...event.target.form];
            const index = formArray.indexOf(event.target);
            //check if next element is button
            if (index === formArray.length - 1) {
                sendForm();
            } else {
                form.elements[index + 1].focus();
            }
            event.preventDefault();
        }
    };

    const renderError = (field: keyof ValidationErrorsMap) => {
        if (!!validationErrorsMap[field]) {
            return (
                <ErrorWrapper>
                    <FormHelperText id="component-error-text">
                        {validationErrorsMap[field].split(',')[0]}
                    </FormHelperText>
                </ErrorWrapper>
            );
        }
    };

    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const classes = createStyles(props);
    return (
        <>
            <BackgroundWrapperDesktop>
                <BackgroundWrapper>
                    <SizeControllerWrapper>
                        <Wrapper>
                            <Typography variant="h2" classes={{ root: classes.heading }}>
                                Sign Up
                            </Typography>
                            <form ref={formRef} className={classes.form}>
                                <FormWrapper>
                                    <InputWrapper error={!!validation.firstName}>
                                        <TextInput
                                            placeholder={'First Name'}
                                            value={user.firstName}
                                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                                changeUser(evt);
                                            }}
                                            inputProps={{ id: 'firstName' }}
                                            onKeyDown={(evt: any) => handleEnter(evt)}
                                            error={!!validation.firstName}
                                        />
                                        {!!validation.firstName && renderError('firstName')}
                                    </InputWrapper>

                                    <InputWrapper error={!!validation.lastName}>
                                        <TextInput
                                            placeholder={'Last Name'}
                                            value={user.lastName}
                                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                                changeUser(evt);
                                            }}
                                            inputProps={{ id: 'lastName' }}
                                            onKeyDown={(evt: any) => handleEnter(evt)}
                                            error={!!validation.lastName}
                                        />
                                        {!!validation.lastName && renderError('lastName')}
                                    </InputWrapper>

                                    <InputWrapper error={!!validation.gender}>
                                        <div ref={dropdownRef}>
                                            <DropdownInput
                                                placeholder={'Gender'}
                                                options={GenderOptions}
                                                value={user.gender}
                                                onChange={value => {
                                                    // @ts-ignore
                                                    changeUser({ target: { id: 'gender', value: value } });
                                                    setNextFieldFocus();
                                                }}
                                                onKeyDown={(evt: any) => handleEnter(evt)}
                                                error={!!validation.gender}
                                            />
                                        </div>
                                        {!!validation.gender && renderError('gender')}
                                    </InputWrapper>
                                    <InputWrapper error={!!validation.email}>
                                        <TextInput
                                            placeholder={'Email'}
                                            value={user.email}
                                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                                //@ts-ignore
                                                changeUser({ target: { id: 'email', value: evt.target.value.trim() } });
                                            }}
                                            inputProps={{ id: 'email' }}
                                            onKeyDown={(evt: any) => handleEnter(evt)}
                                            error={!!validation.email}
                                        />
                                        {!!validation.email && renderError('email')}
                                    </InputWrapper>
                                    <InputWrapper error={!!validation.password}>
                                        <TextInput
                                            placeholder={'Password'}
                                            value={user.password}
                                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                                changeUser(evt);
                                            }}
                                            inputProps={{ id: 'password' }}
                                            onKeyDown={(evt: any) => handleEnter(evt)}
                                            error={!!validation.password}
                                            password
                                        />
                                        {!!validation.password && renderError('password')}
                                    </InputWrapper>
                                    <InputWrapper error={!!validation.passwordConfirm}>
                                        <TextInput
                                            placeholder={'Confirm Password'}
                                            value={user.passwordConfirm}
                                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                                changeUser(evt);
                                            }}
                                            inputProps={{ id: 'passwordConfirm' }}
                                            onKeyDown={(evt: any) => handleEnter(evt)}
                                            error={!!validation.passwordConfirm}
                                            password
                                        />
                                        {!!validation.passwordConfirm && renderError('passwordConfirm')}
                                    </InputWrapper>
                                </FormWrapper>
                            </form>
                            <Typography variant="body1" classes={{ root: classes.labelHelper }}>
                                *All fields are required
                            </Typography>
                            <ButtonWrapper>
                                <PrimaryButton onClick={sendForm}>Sign Up</PrimaryButton>
                            </ButtonWrapper>

                            <LinkWrapper>
                                <CustomLink to={'/sign-in'} underlineColor={colors.redMain}>
                                    <Typography variant="button" classes={{ root: classes.loginLinkText }}>
                                        Sign In
                                    </Typography>
                                </CustomLink>
                                <Typography variant="body2" classes={{ root: classes.registerText }}>
                                    with Your Account
                                </Typography>
                            </LinkWrapper>
                        </Wrapper>
                    </SizeControllerWrapper>
                </BackgroundWrapper>
            </BackgroundWrapperDesktop>

            {props.loading && <LoadingTransparent />}

            {isLaptop && <Footer />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        loading: state.authorization.loading,
        emailVerificationSent: state.authorization.verificationEmailSent,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        signUp: bindActionCreators(AuthActions.signUp, dispatch),
    })
)(withRouter(SignUp));
