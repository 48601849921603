import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import TextInput from '../../shared/input/TextInput';
import AutocompleteSingle from '../../shared/input/AutocompleteSingle';
import { mediaQuery } from '../../constants/device';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { PanelTypeModel } from '../../models/responses/panelType.model';
import { Actions as BloodTargetActions } from '../../store/actions/bloodTarget.actions';
import { Actions as PanelTypeActions } from '../../store/actions/panelType.actions';
import { connect } from 'react-redux';
import * as rootStateModel from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import LoadingTransparent from '../shared/LoadingTransparent';
import { BloodtargetReponce } from '../../models/responses/bloodtarget.reponce';
import { DosageUnitOptions } from '../../constants/dosage/dosageUnitOptions';
import { ValidationErrorsMap } from '../../models/validation/validationErrorsMap';
import { useDebounce } from 'use-debounce';
import { FormFields } from './AddBloodTargetRow';
import fillClassInstanceWithValues from '../../shared/utils/fillClassInstanceWithValues';
import { AddBloodTargetDto } from '../../models/dto/AddBloodTarget.dto';
import { validateSync } from 'class-validator';
import Snackbar, { SnackbarTypes } from '../../shared/snackbars/snackbar';
import RequestState from '../../constants/requestState';
import SecondaryButton from '../../shared/button/SecondaryButton';
import ConfirmationDialog from '../payment/components/ConfirmationDialog';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import CancelLink from '../../shared/link/CancelLink';
import { BloodTargetRow } from './AddBloodTargets';
import rangeValid from '../../shared/utils/validation/range-validation';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';

const Form = styled.div`
    > div {
        margin-bottom: 16px !important;
    }
    align-self: stretch;
    padding: 0 0 24px;
`;

const TwoInputsWrapper = styled.div`
    ${mediaQuery.mobileL} {
        display: flex;
        justify-content: space-between;
    }
    > div {
        margin-bottom: 16px !important;
        ${mediaQuery.mobileL} {
            width: 45%;
            margin-bottom: 0 !important;
        }
    }
`;

const AutocompleteSingleWrapper = styled.div<{ disabled: boolean }>`
    opacity: ${props => (props.disabled ? 0.7 : 1)};
    margin-top: 16px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 60px 0;
    min-height: calc(100vh - 260px);
    height: auto;

    ${mediaQuery.tablet} {
        height: calc(100vh - 370px);
        min-height: calc(100vh - 370px);
    }

    ${mediaQuery.laptop} {
        padding: 60px 0 53px;
        min-height: 77%;
        height: 77%;
    }
`;

const ButtonGroup = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

    > button:first-child {
        margin-bottom: 16px;
    }

    ${mediaQuery.laptop} {
        flex-direction: row;

        > button:first-child {
            margin-bottom: 0;
        }
    }
`;

const createStyles = makeStyles(theme => ({
    lockText: {
        color: colors.textSecondary,
        textAlign: 'center',
        width: '80%',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '-70px',
            width: '100%',
        },
    },
}));

interface StateModel {
    panelTypes: PanelTypeModel[];
    panelTypesLoading: boolean;
    target: BloodtargetReponce | null;
    targetLoading: boolean;
    editBloodTargetsRequest: RequestState;
    removeBloodTargetsRequest: RequestState;
    user: UserModel | null;
}

interface DispatchModel {
    getPanelType: typeof PanelTypeActions.getPanelType;
    getBloodTargetById: typeof BloodTargetActions.getBloodTargetById;
    editBloodTarget: typeof BloodTargetActions.editBloodTarget;
    resetStatusVariables: typeof BloodTargetActions.resetStatusVariables;
    removeBloodTarget: typeof BloodTargetActions.removeBloodTarget;
}

type PropsTypes = RouteComponentProps<any> & StateModel & DispatchModel;

const BloodTargetInfoPage: React.FC<PropsTypes> = props => {
    const {
        getPanelType,
        getBloodTargetById,
        panelTypes,
        panelTypesLoading,
        targetLoading,
        target,
        editBloodTarget,
        resetStatusVariables,
        editBloodTargetsRequest,
        removeBloodTarget,
        removeBloodTargetsRequest,
        user,
        match,
        history,
    } = props;

    const classes = createStyles(props);

    const [targetToEdit, setTargetToEdit] = useState();
    const [error, setError] = useState(false);
    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

    const [femaleRangeValid, setFemaleRangeValid] = useState(true);
    const [maleRangeValid, setMaleRangeValid] = useState(true);

    const validateMinMaxValues = useCallback((row: BloodTargetRow) => {
        if (row.femaleMinRange.length && row.femaleMaxRange.length) {
            rangeValid(row.femaleMinRange, row.femaleMaxRange) ? setFemaleRangeValid(true) : setFemaleRangeValid(false);
        }
        if (row.maleMinRange.length && row.maleMaxRange.length) {
            rangeValid(row.maleMinRange, row.maleMaxRange) ? setMaleRangeValid(true) : setMaleRangeValid(false);
        }
    }, []);

    useEffect(() => {
        getBloodTargetById(match.params.id);
        getPanelType();
    }, [getPanelType, getBloodTargetById, match]);

    useEffect(() => {
        target &&
            setTargetToEdit({
                ...target,
                isBeingEdited: new Set<string>(),
                femaleMinRange: target.femaleMinRange ? target.femaleMinRange.toString() : '',
                femaleMaxRange: target.femaleMaxRange ? target.femaleMaxRange.toString() : '',
                maleMinRange: target.maleMinRange ? target.maleMinRange.toString() : '',
                maleMaxRange: target.maleMaxRange ? target.maleMaxRange.toString() : '',
            });
    }, [target]);

    const changeForm = (formField: string, value: string | number | null) => {
        setError(false);
        setTargetToEdit({
            ...targetToEdit,
            [formField]: value,
            isBeingEdited: targetToEdit.isBeingEdited.add(formField),
        });
    };

    const isEditable = useMemo(() => {
        return match.url.includes('edit');
    }, [match]);

    const goToEditBloodTarget = useMemo(() => {
        if (user) {
            return user.role === RoleOptions.ADMIN
                ? `/admin/bloodtarget-edit/${match.params.id}`
                : `/bloodtarget-edit/${match.params.id}`;
        }
        return '';
    }, [user, match]);

    const goToEdit = useCallback(() => {
        if (user) {
            history.push(goToEditBloodTarget);
        }
    }, [history, user, goToEditBloodTarget]);

    useEffect(() => {
        if (!!targetToEdit) {
            validateForm(targetToEdit);
            validateMinMaxValues(targetToEdit);
        }
    }, [targetToEdit, validateMinMaxValues]);

    const validateForm = (user: any, isOnSubmit = false) => {
        const item = fillClassInstanceWithValues<AddBloodTargetDto>(AddBloodTargetDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                    map[err.property] = Object.entries(err.constraints)
                        .map(([, value]) => value)
                        .join(', ');
                }
            });
            setValidationErrorsMap(map);
        }
        setValidationErrorsMap(map);
        return map;
    };

    const isFormValid = useCallback(() => {
        let valid = true;
        const map = validateForm(targetToEdit, true);
        if (
            Object.keys(map).length !== 0 ||
            !rangeValid(targetToEdit.femaleMinRange, targetToEdit.femaleMaxRange) ||
            !rangeValid(targetToEdit.maleMinRange, targetToEdit.maleMaxRange)
        ) {
            valid = false;
        }
        return valid;
    }, [targetToEdit]);

    const saveInfo = useCallback(() => {
        if (isFormValid()) {
            editBloodTarget([
                {
                    ...targetToEdit,
                    femaleMinRange: Number(targetToEdit.femaleMinRange),
                    femaleMaxRange: Number(targetToEdit.femaleMaxRange),
                    maleMinRange: Number(targetToEdit.maleMinRange),
                    maleMaxRange: Number(targetToEdit.maleMaxRange),
                },
            ]);
        } else {
            setError(true);
        }
    }, [targetToEdit, editBloodTarget, isFormValid]);

    const openConfirmationDialog = useCallback(() => {
        setOpenRemoveDialog(true);
    }, []);

    const remove = useCallback(() => {
        removeBloodTarget(match.params.id);
    }, [removeBloodTarget, match]);

    const goToCurrentBloodTarget = useMemo(() => {
        if (user) {
            return user.role === RoleOptions.ADMIN
                ? `/admin/bloodtarget/${match.params.id}`
                : `/bloodtarget/${match.params.id}`;
        }
        return '';
    }, [user, match]);

    useEffect(() => {
        if (user) {
            editBloodTargetsRequest === RequestState.SENT_SUCCESS && history.push(goToCurrentBloodTarget);
        }
    }, [editBloodTargetsRequest, history, match, user, goToCurrentBloodTarget]);

    const goToBloodTargets = useMemo(() => {
        if (user) {
            return user.role === RoleOptions.ADMIN ? '/admin/bloodtargets' : '/bloodtargets';
        }
        return '';
    }, [user]);

    useEffect(() => {
        if (user) {
            removeBloodTargetsRequest === RequestState.SENT_SUCCESS && history.push(goToBloodTargets);
        }
    }, [removeBloodTargetsRequest, history, user, goToBloodTargets]);

    const isLoading = useMemo(() => {
        return (
            panelTypesLoading ||
            targetLoading ||
            editBloodTargetsRequest === RequestState.SENDING ||
            removeBloodTargetsRequest === RequestState.SENDING
        );
    }, [panelTypesLoading, targetLoading, editBloodTargetsRequest, removeBloodTargetsRequest]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    const canEditTarget = useMemo(() => {
        if (user && target) {
            return user.role === RoleOptions.ADMIN ? true : target.createdBy === user.id;
        }
        return false;
    }, [user, target]);

    return (
        <>
            <HeaderHeadingWithLink goBackLink={goToBloodTargets}>
                {target && `Target ${target.target}.`}
            </HeaderHeadingWithLink>
            {isLoading && <LoadingTransparent />}
            {!isLoading && panelTypes && targetToEdit && (
                <SizeControllerWrapper>
                    <Wrapper>
                        <Form>
                            <TwoInputsWrapper>
                                <TextInput
                                    placeholder={'Target'}
                                    value={targetToEdit.target}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        changeForm(FormFields.target, evt.target.value)
                                    }
                                    disabled={!isEditable}
                                    error={!!validation.target}
                                />
                                <TextInput
                                    placeholder={'Label'}
                                    value={targetToEdit.label.trim()}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        changeForm(FormFields.label, evt.target.value)
                                    }
                                    disabled={!isEditable}
                                    error={!!validation.label}
                                />
                            </TwoInputsWrapper>

                            <AutocompleteSingleWrapper disabled={!isEditable}>
                                <AutocompleteSingle
                                    value={targetToEdit.bloodTargetPanelTypeId}
                                    options={panelTypes}
                                    label={'Panel Type'}
                                    onChange={(value: BaseDropdownItem | null) => {
                                        changeForm(FormFields.bloodTargetPanelTypeId, value ? value.id : '');
                                    }}
                                    error={!!validation.bloodTargetPanelTypeId}
                                    disabled={!isEditable}
                                />
                            </AutocompleteSingleWrapper>

                            <TwoInputsWrapper>
                                <TextInput
                                    placeholder={'Female Min'}
                                    value={targetToEdit.femaleMinRange}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        changeForm(FormFields.femaleMinRange, evt.target.value)
                                    }
                                    number
                                    error={!!validation.femaleMinRange || !femaleRangeValid}
                                    disabled={!isEditable}
                                />
                                <TextInput
                                    placeholder={'Female max'}
                                    value={targetToEdit.femaleMaxRange}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        changeForm(FormFields.femaleMaxRange, evt.target.value)
                                    }
                                    number
                                    error={!!validation.femaleMaxRange || !femaleRangeValid}
                                    disabled={!isEditable}
                                />
                            </TwoInputsWrapper>

                            <TwoInputsWrapper>
                                <TextInput
                                    placeholder={'Male Min'}
                                    value={targetToEdit.maleMinRange}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        changeForm(FormFields.maleMinRange, evt.target.value)
                                    }
                                    number
                                    error={!!validation.maleMinRange || !maleRangeValid}
                                    disabled={!isEditable}
                                />
                                <TextInput
                                    placeholder={'Male max'}
                                    value={targetToEdit.maleMaxRange}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        changeForm(FormFields.maleMaxRange, evt.target.value)
                                    }
                                    number
                                    error={!!validation.maleMaxRange || !maleRangeValid}
                                    disabled={!isEditable}
                                />
                            </TwoInputsWrapper>
                            <AutocompleteSingleWrapper disabled={!isEditable}>
                                <AutocompleteSingle
                                    value={targetToEdit.unit}
                                    options={DosageUnitOptions}
                                    label={'Unit'}
                                    onChange={(value: BaseDropdownItem | null) => {
                                        changeForm(FormFields.unit, value ? value.id : '');
                                    }}
                                    isOptionIdString
                                    disabled={!isEditable}
                                    error={!!validation.unit}
                                />
                            </AutocompleteSingleWrapper>
                        </Form>
                        <ButtonGroup>
                            {canEditTarget ? (
                                <>
                                    <SecondaryButton onClick={openConfirmationDialog}>Remove Target</SecondaryButton>
                                    {isEditable ? (
                                        <PrimaryButton onClick={saveInfo}>Save Info</PrimaryButton>
                                    ) : (
                                        <PrimaryButton onClick={goToEdit}>Edit Info</PrimaryButton>
                                    )}
                                </>
                            ) : (
                                <Typography variant={'body1'} classes={{ root: classes.lockText }}>
                                    You are unable to edit this global blood target.
                                </Typography>
                            )}
                        </ButtonGroup>
                        <CancelLink link={goToBloodTargets} />
                    </Wrapper>
                </SizeControllerWrapper>
            )}

            <Snackbar
                message={'Fill all the fields in the form'}
                open={error}
                variant={'error'}
                type={SnackbarTypes.ERROR}
            />

            {openRemoveDialog && (
                <ConfirmationDialog
                    open={openRemoveDialog}
                    close={() => setOpenRemoveDialog(false)}
                    text={'Are you sure you want to remove this target?'}
                    yesAction={remove}
                />
            )}
        </>
    );
};

export default connect(
    (state: rootStateModel.RootStateModel): StateModel => ({
        panelTypesLoading: state.panelType.panelTypesLoading,
        panelTypes: state.panelType.panelTypes,
        target: state.bloodTarget.currentTarget,
        targetLoading: state.bloodTarget.currentTargetLoading,
        editBloodTargetsRequest: state.bloodTarget.editBloodTargetsRequest,
        removeBloodTargetsRequest: state.bloodTarget.removeBloodTargetsRequest,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getPanelType: bindActionCreators(PanelTypeActions.getPanelType, dispatch),
        getBloodTargetById: bindActionCreators(BloodTargetActions.getBloodTargetById, dispatch),
        resetStatusVariables: bindActionCreators(BloodTargetActions.resetStatusVariables, dispatch),
        editBloodTarget: bindActionCreators(BloodTargetActions.editBloodTarget, dispatch),
        removeBloodTarget: bindActionCreators(BloodTargetActions.removeBloodTarget, dispatch),
    })
)(withRouter(BloodTargetInfoPage));
