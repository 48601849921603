import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InvoiceModel } from '../../../models/responses/invoice.model';
import { connect } from 'react-redux';
import { Actions as InvoiceActions } from '../../../store/actions/invoice.actions';
import { bindActionCreators, Dispatch } from 'redux';
import * as fromRoot from '../../../store/reducers/index';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import styled from 'styled-components';
import Loading from '../../shared/Loading';
import ToBePaidBlock from './new/ToBePaidBlock';
import RequestState from '../../../constants/requestState';
import { DefaultPageSize, hasMoreData, shouldFetchData } from '../../../constants/dataFetching/allEntitiesByPages';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

interface StoreModel {
    allInvoices: InvoiceModel[];
    pageNumber: number;
    totalInvoice: number | null;
    loadingOneTimeRequested: boolean;
    rejectRequest: RequestState;
    totalAmountInvoiceOneTimeRequested: number;
}

interface DispatchModel {
    getAllAllOneTime: typeof InvoiceActions.getAllOneTimeRequestedPatient;
    increasePageNumber: typeof InvoiceActions.increasePageNumberOneTimeRequestedPatient;
}

type PropsTypes = StoreModel & DispatchModel;

const OneTimeRequestedPatient: React.FC<PropsTypes> = props => {
    const {
        allInvoices,
        pageNumber,
        totalInvoice,
        loadingOneTimeRequested,
        getAllAllOneTime,
        increasePageNumber,
        rejectRequest,
        totalAmountInvoiceOneTimeRequested = 0,
    } = props;

    const [initialLoaded, setInitialLoaded] = useState(false);

    const loadData = useCallback(
        (page, overrideRecords: boolean) => {
            getAllAllOneTime({
                size: DefaultPageSize,
                overrideRecords,
                page,
            });
        },
        [getAllAllOneTime]
    );

    useEffect(() => {
        loadData(0, true);
        setInitialLoaded(true);
    }, [rejectRequest, loadData]);

    useEffect(() => {
        if (shouldFetchData(loadingOneTimeRequested, pageNumber, allInvoices.length, initialLoaded)) {
            loadData(pageNumber, false);
        }
    }, [loadingOneTimeRequested, pageNumber, allInvoices, initialLoaded, loadData]);

    const loadMore = useCallback(() => {
        increasePageNumber();
    }, [increasePageNumber]);

    const hasMore = useMemo(() => {
        return hasMoreData(totalInvoice, allInvoices.length);
    }, [totalInvoice, allInvoices]);

    return (
        <>
            <ToBePaidBlock
                invoices={allInvoices}
                totalAmountInvoiceOneTimeRequested={totalAmountInvoiceOneTimeRequested}
            />

            {hasMore && !loadingOneTimeRequested && (
                <ButtonWrapper>
                    <PrimaryButton onClick={loadMore}>Load More</PrimaryButton>
                </ButtonWrapper>
            )}

            {loadingOneTimeRequested && <Loading smallSize />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        allInvoices: state.invoice.invoiceOneTimeRequested,
        pageNumber: state.invoice.pageNumberInvoiceOneTimeRequested,
        totalInvoice: state.invoice.totalInvoiceOneTimeRequested,
        loadingOneTimeRequested: state.invoice.loadingOneTimeRequested,
        rejectRequest: state.invoice.rejectRequest,
        totalAmountInvoiceOneTimeRequested: state.invoice.totalAmountInvoiceOneTimeRequested,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllAllOneTime: bindActionCreators(InvoiceActions.getAllOneTimeRequestedPatient, dispatch),
        increasePageNumber: bindActionCreators(InvoiceActions.increasePageNumberOneTimeRequestedPatient, dispatch),
    })
)(OneTimeRequestedPatient);
