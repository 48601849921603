import * as prescriptionActions from '../../actions/prescription.actions';
import RequestState from "../../../constants/requestState";
import {GetPrescriptionByIdDto} from "../../../models/dto/getPrescriptionById.dto";
import {PrescriptionComponentModel} from "../../../models/responses/prescriptionComponentModel.responce";

export interface Prescription {
    id: number,
    name: string,
    createdBy: number,
    pharmacyId: number,
    startDate: string,
    endDate: string,
    prescriptionComponentList: PrescriptionComponentModel[]
}

export class StateModel {
    allPrescriptions: GetPrescriptionByIdDto[] = [];
    newPrescription: PrescriptionComponentModel[] = [];
    currentPrescription: GetPrescriptionByIdDto | null = null;
    page: number = 0;
    totalPrescriptions: number | null = null;
    loading: boolean = false;
    prescriptionAddingStatus: RequestState = RequestState.UNSENT;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: prescriptionActions.Actions) {
    switch (action.type) {

        case prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_PATIENT: {
            return {
                ...state,
                loading: true,
            }
        }

        case prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_PROVIDER: {
            return {
                ...state,
                loading: true,
            }
        }


        case prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_UPDATE_SUCCESS: {

            // const newList = state.allPrescriptions.concat(action.payload.result);
            // const isRedundant = newList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalPrescriptions: action.payload.total,
                allPrescriptions: state.allPrescriptions.concat(action.payload.result)
            }
        }

        case prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_OVERRIDE_SUCCESS: {

            // const newList = state.allPrescriptions.concat(action.payload.result);
            // const isRedundant = newList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalPrescriptions: action.payload.total,
                allPrescriptions: action.payload.result,
            }
        }

        case prescriptionActions.ActionNames.GET_ALL_PRESCRIPTIONS_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }

        case prescriptionActions.ActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            }
        }

        case prescriptionActions.ActionNames.ADD_PRESCRIPTION_COMPONENT_NEW: {
            return {
                ...state,
                newPrescription: [...state.newPrescription, action.payload]
            }
        }

        case prescriptionActions.ActionNames.ADD_PRESCRIPTION_COMPONENT_UPDATE: {
            const currentPrescription = state.currentPrescription ? {
                ...state.currentPrescription,
                prescriptionComponentList: [...state.currentPrescription.prescriptionComponentList, action.payload]
            } : state.currentPrescription;

            return {
                ...state,
                currentPrescription,
            }
        }

        case prescriptionActions.ActionNames.SAVE_EDITED_PRESCRIPTION_COMPONENT_NEW: {
            const componentToEdit = state.newPrescription.filter(component => component.id === action.payload.id)[0];
            const index = state.newPrescription.indexOf(componentToEdit);
            const newPrescription = [...state.newPrescription];
            newPrescription[index] = action.payload;
            return {
                ...state,
                newPrescription,
            }
        }

        case prescriptionActions.ActionNames.SAVE_EDITED_PRESCRIPTION_COMPONENT_UPDATE: {
            let currentPrescription: Prescription | null = state.currentPrescription ? {...state.currentPrescription} : null;
            if (state.currentPrescription && currentPrescription) {
                const componentToEdit = state.currentPrescription.prescriptionComponentList.filter(component => component.id === action.payload.id)[0];
                const index = state.currentPrescription.prescriptionComponentList.indexOf(componentToEdit);
                currentPrescription.prescriptionComponentList[index] = {...action.payload};
            }

            return {
                ...state,
                currentPrescription,
            }
        }


        case prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_PATIENT: {
            return {
                ...state,
                loading: true,
            }
        }

        case prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_PROVIDER: {
            return {
                ...state,
                loading: true,
            }
        }

        case prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_SUCCESS: {

            return {
                ...state,
                loading: false,
                currentPrescription: action.payload,
            }
        }

        case prescriptionActions.ActionNames.GET_PRESCRIPTION_BY_ID_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }

        case prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_COMPONENT_NEW: {
            const componentToDelete = state.newPrescription.filter(component => component.id === action.payload)[0];
            const index = state.newPrescription.indexOf(componentToDelete);
            const newPrescription = index >= 0 ? [...state.newPrescription.slice(0, index), ...state.newPrescription.slice(index + 1)] : state.newPrescription;
            return {
                ...state,
                newPrescription,
            }
        }

        case prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_COMPONENT_UPDATE: {
            let currentPrescription: Prescription | null = state.currentPrescription ? {...state.currentPrescription} : null;

            if (state.currentPrescription && currentPrescription) {
                const componentToDelete =
                    state.currentPrescription.prescriptionComponentList.filter(component => component.id === action.payload)[0];
                const index = state.currentPrescription.prescriptionComponentList.indexOf(componentToDelete);
                if (index >= 0) {
                    currentPrescription = {
                        ...currentPrescription,
                        prescriptionComponentList:
                            [...state.currentPrescription.prescriptionComponentList.slice(0, index), ...state.currentPrescription.prescriptionComponentList.slice(index + 1)]
                    }
                }
            }

            return {
                ...state,
                currentPrescription,
            }
        }

        case prescriptionActions.ActionNames.EDIT_PRESCRIPTION_COMPONENT_NEW: {
            const newPrescription = state.newPrescription.map((prescription) =>
                prescription.id === action.payload ? {...prescription, isBeingEdited: true} : prescription);

            return {
                ...state,
                newPrescription,
            }
        }

        case prescriptionActions.ActionNames.CANCEL_EDIT_PRESCRIPTION_COMPONENT_NEW: {
            const newPrescription = state.newPrescription.map((prescription) =>
                prescription.id === action.payload ? {...prescription, isBeingEdited: false} : prescription);

            return {
                ...state,
                newPrescription,
            }
        }

        case prescriptionActions.ActionNames.EDIT_PRESCRIPTION_COMPONENT_UPDATE: {
            const currentPrescription = state.currentPrescription ?
                {
                    ...state.currentPrescription,
                    prescriptionComponentList: state.currentPrescription.prescriptionComponentList.map((prescription) =>
                        prescription.id === action.payload ? {
                            ...prescription,
                            isBeingEdited: true
                        } : prescription)
                } : state.currentPrescription;

            return {
                ...state,
                currentPrescription,
            }
        }

        case prescriptionActions.ActionNames.CANCEL_EDIT_PRESCRIPTION_COMPONENT_UPDATE: {
            const currentPrescription = state.currentPrescription ?
                {
                    ...state.currentPrescription,
                    prescriptionComponentList: state.currentPrescription.prescriptionComponentList.map((prescription) =>
                        prescription.id === action.payload ? {
                            ...prescription,
                            isBeingEdited: false
                        } : prescription)
                } : state.currentPrescription;

            return {
                ...state,
                currentPrescription,
            }
        }

        case prescriptionActions.ActionNames.ADD_PRESCRIPTION_PATIENT: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENDING
            }
        }

        case prescriptionActions.ActionNames.ADD_PRESCRIPTION_PATIENT_SUCCESS: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_SUCCESS
            }
        }

        case prescriptionActions.ActionNames.ADD_PRESCRIPTION_PATIENT_FAILED: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_FAILED
            }
        }


        case prescriptionActions.ActionNames.ADD_PRESCRIPTION_PROVIDER: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENDING
            }
        }

        case prescriptionActions.ActionNames.ADD_PRESCRIPTION_PROVIDER_SUCCESS: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_SUCCESS
            }
        }

        case prescriptionActions.ActionNames.ADD_PRESCRIPTION_PROVIDER_FAILED: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_FAILED
            }
        }

        case prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PATIENT: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENDING
            }
        }

        case prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PATIENT_SUCCESS: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_SUCCESS
            }
        }

        case prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PATIENT_FAILED: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_FAILED
            }
        }

        case prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PROVIDER: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENDING
            }
        }

        case prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PROVIDER_SUCCESS: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_SUCCESS
            }
        }

        case prescriptionActions.ActionNames.UPDATE_PRESCRIPTION_PROVIDER_FAILED: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_FAILED
            }
        }

        case prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PATIENT: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENDING
            }
        }

        case prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PATIENT_SUCCESS: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_SUCCESS
            }
        }

        case prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PATIENT_FAILED: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_FAILED
            }
        }

        case prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PROVIDER: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENDING
            }
        }

        case prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PROVIDER_SUCCESS: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_SUCCESS
            }
        }

        case prescriptionActions.ActionNames.REMOVE_PRESCRIPTION_PROVIDER_FAILED: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.SENT_FAILED
            }
        }

        case prescriptionActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                prescriptionAddingStatus: RequestState.UNSENT,
                newPrescription: [],
                currentPrescription: null,
            }
        }

        default:
            return state;
    }
}