import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';

const createStyles = makeStyles({
    heading: {
        fontSize: '16px'
    },
    text: {
        color: colors.gray4,
    },
});

const TourStep: React.FC<{ heading: string; text: string }> = props => {
    const classes = createStyles(props);
    const { heading, text } = props;
    return (
        <div>
            <Typography variant={'button'} className={classes.heading}>
                {heading}
            </Typography>
            <Typography variant={'body1'} className={classes.text}>
                {text}
            </Typography>
        </div>
    );
};

export default TourStep;
