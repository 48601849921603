import React, { useEffect } from 'react';
import { createStyles, List, makeStyles, Theme, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { NotificationActions as NotificationsActions } from '../../store/actions/notifications.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { colors } from '../../theme/colors';
import { NotificationModel } from '../../models/responses/notification.responce';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { mediaQuery } from '../../constants/device';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';
import DropdownInputPatientListWrapper from '../../shared/input/dropdownAutocompleteWrappers/DropdownInputLinkedPatientListWrapper';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { RoleOptions } from '../../constants/roleOptions';
import { UserModel } from '../../models/responses/user.response';
import NotificationListItem from './NotificationListItem';
import SelectedPatientNotifications from './SelectedPatientNotifications';
import { PatientStepClassName } from '../tour/constants/patientStepsClassNames';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: sticky;
    padding: 15px 0;
    bottom: -3px;
    width: 100%;
    background: ${colors.white};

    ${mediaQuery.mobileLandscape} {
        padding: 10px 0;
        & button {
            height: 50px;
        }
    }
`;

const Wrapper = styled.div`
    position: relative;
    height: calc(100vh - 105px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${mediaQuery.laptop} {
        height: calc(100vh - 152px);
    }
`;

const InputWrapper = styled.div`
    padding: 8px 0 42px;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            marginBottom: '20px',
        },
        inline: {
            display: 'inline',
        },
        avatar: {
            height: '26px',
            width: '26px',
        },
        avatarWrapper: {
            height: '40px',
            width: '40px',
            border: `1px solid ${colors.textSecondary}`,
            backgroundColor: colors.white,
            borderRadius: '30px',
            minWidth: '40px',
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            marginTop: 0,
        },
        headingNew: {
            fontSize: '14px',
            color: colors.redMain,
            fontWeight: 600,
        },
        headingOld: {
            fontSize: '14px',
            color: colors.textPrimary,
        },
        dateText: {
            color: colors.textSecondary,
            fontSize: '10px',
        },
        heading: {
            fontSize: '24px',
            textAlign: 'center',
            margin: '15px',
        },
    })
);

interface StateToPropsModel {
    notifications: NotificationModel[];
    user: UserModel | null;
    selectedPatientId: number | null;
}

interface DispatchModel {
    markAsRead: typeof NotificationsActions.markAsRead;
    markOneAsRead: typeof NotificationsActions.markOneAsRead;
    setPatient: typeof NotificationsActions.setPatient;
    resetPatientNotifications: typeof NotificationsActions.resetPatientNotifications;
}

interface Props extends StateToPropsModel, DispatchModel {}

function Notifications(props: Props) {
    const { notifications, user, setPatient, markOneAsRead, selectedPatientId, resetPatientNotifications } = props;
    const classes = useStyles();

    useEffect(() => {
        if (!selectedPatientId) {
            resetPatientNotifications();
        }
    }, [selectedPatientId, resetPatientNotifications]);

    return (
        <>
            {notifications.length ? (
                <>
                    <Wrapper>
                        <div>
                            <div className={PatientStepClassName.NOTIFICATIONS}>
                                <Typography variant="h2" classes={{ root: classes.heading }}>
                                    Notifications
                                </Typography>
                            </div>
                            {user && user.role === RoleOptions.PROVIDER && (
                                <InputWrapper>
                                    <SizeControllerWrapper>
                                        <DropdownInputPatientListWrapper
                                            label={'Patient'}
                                            onChange={(entity: BaseDropdownItem | null) => {
                                                setPatient(entity ? entity.id : entity);
                                            }}
                                        />
                                    </SizeControllerWrapper>
                                </InputWrapper>
                            )}

                            {selectedPatientId ? (
                                <SelectedPatientNotifications />
                            ) : (
                                <List className={classes.root}>
                                    {notifications
                                        .slice()
                                        .reverse()
                                        .map((notification: NotificationModel) => (
                                            <NotificationListItem
                                                key={notification.id}
                                                markOneAsRead={markOneAsRead}
                                                notification={notification}
                                            />
                                        ))}
                                </List>
                            )}
                        </div>

                        <ButtonWrapper>
                            <PrimaryButton onClick={() => props.markAsRead()}>Mark all as Read</PrimaryButton>
                        </ButtonWrapper>
                    </Wrapper>
                </>
            ) : (
                <div className={PatientStepClassName.NOTIFICATIONS}>
                    <Typography style={{ textAlign: 'center', margin: '20px auto' }} variant="h6">
                        No new notifications yet.
                    </Typography>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (store: fromRoot.RootStateModel): StateToPropsModel => ({
    notifications: store.notifications.notifications,
    selectedPatientId: store.notifications.selectedPatientId,
    user: store.authorization.user,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchModel => ({
    markAsRead: bindActionCreators(NotificationsActions.markAsRead, dispatch),
    markOneAsRead: bindActionCreators(NotificationsActions.markOneAsRead, dispatch),
    setPatient: bindActionCreators(NotificationsActions.setPatient, dispatch),
    resetPatientNotifications: bindActionCreators(NotificationsActions.resetPatientNotifications, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
