import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './theme/matTheme';
import AppRouting from './App.routing';
import './helpers/typedEnv';
import store from './store/store';
import { createGenerateClassName, StylesProvider } from '@material-ui/styles';
import { TYPED_ENV } from './helpers/typedEnv';
import * as Sentry from '@sentry/react';
import ErrorBoundary from './errors/ErrorBoundary';
// import './mock/index';

const generateClassName = createGenerateClassName({
    productionPrefix: 'um',
});

const App: React.FC = () => {
    Sentry.init({
        dsn: TYPED_ENV.REACT_APP_SENTRY_DSN,
    });

    return (
        <Provider store={store}>
            <StylesProvider generateClassName={generateClassName}>
                <MuiThemeProvider theme={theme}>
                    <BrowserRouter>
                        <ErrorBoundary>
                            <AppRouting />
                        </ErrorBoundary>
                    </BrowserRouter>
                </MuiThemeProvider>
            </StylesProvider>
        </Provider>
    );
};

export default App;
