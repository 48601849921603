import React from 'react';
import { connect } from 'react-redux';
import ProfileHeader from './components/ProfileHeader';
import { UserModel } from '../../models/responses/user.response';
import * as fromRoot from '../../store/reducers/index';
import Loading from '../shared/Loading';
import TextInput from '../../shared/input/TextInput';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import PrimaryButton from '../../shared/button/PrimaryButton';
import ChangePasswordButton from '../changePassword/ChangePasswordButton';
import ChangeCurrentPasswordSnackbar from '../changePassword/ChnageCurrentPasswordSnackbar';

interface StoreModel {
    user: UserModel | null;
}

const FormWrapper = styled.div`
    > div {
        margin-bottom: 16px !important;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 42px 0;
`;

const ProfileInfoAdmin: React.FC<StoreModel> = props => {
    const { user } = props;

    return (
        <>
            {!user && <Loading smallSize />}
            {user && (
                <SizeControllerWrapper>
                    <ProfileHeader editMode={false} isDefaultAvatar={true} text={''} avatar={null} name={'Admin'} />

                    <FormWrapper>
                        <TextInput value={`${user.firstName} ${user.lastName}`} placeholder={'Name'} disabled />
                        <TextInput value={user.email} placeholder={'Email'} disabled />
                    </FormWrapper>

                    <ButtonWrapper>
                        <PrimaryButton disabled>Edit Profile</PrimaryButton>
                    </ButtonWrapper>
                    <ChangePasswordButton />
                </SizeControllerWrapper>
            )}
            <ChangeCurrentPasswordSnackbar />
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    () => ({})
)(ProfileInfoAdmin);
