import React from 'react';
import { makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import { BloodworkTabs } from '../../../constants/tabRouting/bloodworkTabs';

const createStyles = makeStyles(theme => ({
    tabText: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
    tabs: {
        minHeight: '32px',
        padding: '13px 0 11px',
        backgroundColor: colors.gray2,
        '& > div': {
            justifyContent: 'space-between',
            overflow: 'scroll !important',
            '&::-webkit-scrollbar': {
                display: 'none',
                height: 0,
                width: 0,
            },
        },
        [theme.breakpoints.up('lg')]: {
            padding: '13px 32px 11px',
        },
    },
    tab: {
        minHeight: '20px',
        height: '30px',
        minWidth: '10px',
        padding: '6px 16px 8px',
    },
    selectedTab: {
        '& span': {
            color: colors.textPrimary,
        },
    },
    indicator: {
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: '100%',
            left: 'calc(50% - 4px)',
            borderBottom: `3px solid ${colors.redMain}`,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            width: 0,
            height: 0,
        },
    },
    flexContainer: {
        justifyContent: 'space-between',
    },
    invisibleTab: {
        width: '0',
        minWidth: '0',
        padding: '0',
        height: '0',
        minHeight: '20px',
    },
}));

interface InjectedProps {
    tabName: BloodworkTabs;
    setTabName: (tab: BloodworkTabs) => void;
    isPreviousReport: boolean;
}

type PropsTypes = InjectedProps;

const ResultTabs: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    return (
        <Tabs
            value={props.tabName}
            onChange={(event: React.ChangeEvent<{}>, newValue: BloodworkTabs) =>{
                props.setTabName(newValue)
            } }
            indicatorColor="primary"
            classes={{ root: classes.tabs, indicator: classes.indicator, flexContainer: classes.flexContainer }}
        >
            <Tab
                value={BloodworkTabs.REF_RANGE}
                classes={{ root: classes.tab, selected: classes.selectedTab }}
                label={
                    <Typography variant="button" classes={{ root: classes.tabText }}>
                        Reference Range
                    </Typography>
                }
            />
            <Tab
                value={BloodworkTabs.CURRENT}
                classes={{ root: classes.tab, selected: classes.selectedTab }}
                label={
                    <Typography variant="button" classes={{ root: classes.tabText }}>
                        Current Result
                    </Typography>
                }
            />
            {props.isPreviousReport && (
                <Tab
                    value={BloodworkTabs.PREVIOUS}
                    classes={{
                        root: classes.tab,
                        selected: classes.selectedTab,
                    }}
                    label={
                        <Typography variant="button" classes={{ root: classes.tabText }}>
                            Previous Result
                        </Typography>
                    }
                />
            )}
            <Tab
                value={BloodworkTabs.CHANGE}
                classes={{ root: classes.tab, selected: classes.selectedTab }}
                label={
                    <Typography variant="button" classes={{ root: classes.tabText }}>
                        Change
                    </Typography>
                }
            />
        </Tabs>
    );
};

export default ResultTabs;
