import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PaymentTabs from './components/PaymentTabs';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import { Fade } from '@material-ui/core';
import HistoryBlock from './components/history/HistoryBlock';
import { connect } from 'react-redux';
import { InvoiceModel } from '../../models/responses/invoice.model';
import * as fromRoot from '../../store/reducers';
import * as invoiceAction from '../../store/actions/invoice.actions';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../shared/Loading';
import RecurringSubscriptionOnePatient from './components/RecurringSubscriptionOnePatient';
import InactiveBlock from './components/history/InactiveBlock';
import { InvoiceStatus } from '../../constants/invoice/invoiceStatus';
import RequestState from '../../constants/requestState';
import Snackbar, { SnackbarTypes } from '../../shared/snackbars/snackbar';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserModel } from '../../models/responses/user.response';
import { PaymentRoutingPatient } from '../../constants/tabRouting/routing';
import OneTimeRequestedPatient from './components/OneTimeRequestedPatient';
import SettingsPatient from './client-management/SettingsPatient';

interface StateModel {
    loadingOneTimeRequested: boolean;
    invoiceOneTimeRequested: InvoiceModel[];
    totalAmountRecurringSubscriptionPatient: number;

    recurringSubscription: InvoiceModel[];
    loadingRecurringSubscription: boolean;

    rejectRequest: RequestState;
    unsubscribeInvoiceRequest: RequestState;

    user: UserModel | null;

    countOneTimeRequested: number;
}

interface DispatchModel {
    getAllRecurringSubscriptionPatient: typeof invoiceAction.Actions.getAllRecurringSubscriptionPatient;
    resetArchived: typeof invoiceAction.Actions.resetArchived;
    resetStatusVariables: typeof invoiceAction.Actions.resetStatusVariables;
    getAllRecurringSubscriptionPatientSuccess: typeof invoiceAction.Actions.getAllRecurringSubscriptionPatientSuccess;
    getOneTimeRequestedPatientCount: typeof invoiceAction.Actions.getOneTimeRequestedPatientCount;
}

type PropsTypes = DispatchModel & StateModel & RouteComponentProps<any>;

const PaymentPatient: React.FC<PropsTypes> = props => {
    const [activeTab, setActiveTab] = useState(0);

    const [initialLoaded, setInitialLoaded] = useState(false);

    const rejectMessage = 'The invoice was rejected';
    const unsubscribeMessage = 'The subscription was canceled';

    const {
        loadingOneTimeRequested,
        recurringSubscription,
        loadingRecurringSubscription,
        getAllRecurringSubscriptionPatient,
        resetArchived,
        rejectRequest,
        unsubscribeInvoiceRequest,
        resetStatusVariables,
        user,
        getOneTimeRequestedPatientCount,
        countOneTimeRequested,
    } = props;

    useEffect(() => {
        switch (props.history.location.pathname) {
            case PaymentRoutingPatient[0].path: {
                setActiveTab(PaymentRoutingPatient[0].tab);
                break;
            }
            case PaymentRoutingPatient[1].path: {
                setActiveTab(PaymentRoutingPatient[1].tab);
                break;
            }
            case PaymentRoutingPatient[2].path: {
                setActiveTab(PaymentRoutingPatient[2].tab);
                break;
            }
            default: {
                setActiveTab(0);
            }
        }
    }, [props.history.location.pathname]);

    const setTab = useCallback(
        (newActiveTab: number) => {
            if (activeTab !== newActiveTab) {
                resetArchived();
                setActiveTab(activeTab);
            }
        },
        [resetArchived, activeTab]
    );

    useEffect(() => {
        switch (props.history.location.pathname) {
            case PaymentRoutingPatient[0].path: {
                setActiveTab(PaymentRoutingPatient[0].tab);
                return;
            }
            case PaymentRoutingPatient[1].path: {
                setActiveTab(PaymentRoutingPatient[1].tab);
                return;
            }
            case PaymentRoutingPatient[2].path: {
                setActiveTab(PaymentRoutingPatient[2].tab);
                return;
            }
            default: {
                setActiveTab(0);
            }
        }
    }, [props.history.location.pathname]);

    useEffect(() => {
        if (
            (rejectRequest !== RequestState.SENDING && (rejectRequest !== RequestState.UNSENT || !initialLoaded)) ||
            (unsubscribeInvoiceRequest !== RequestState.SENDING &&
                (unsubscribeInvoiceRequest !== RequestState.UNSENT || !initialLoaded))
        ) {
            getOneTimeRequestedPatientCount();
            getAllRecurringSubscriptionPatient();
            !initialLoaded && setInitialLoaded(true);
            resetStatusVariables();
        }
    }, [
        rejectRequest,
        unsubscribeInvoiceRequest,
        getAllRecurringSubscriptionPatient,
        initialLoaded,
        resetStatusVariables,
        getOneTimeRequestedPatientCount,
    ]);

    const loadingData = useMemo(() => {
        return loadingOneTimeRequested && loadingRecurringSubscription;
    }, [loadingOneTimeRequested, loadingRecurringSubscription]);

    const recurringSubscriptionNumber = useMemo(() => {
        return recurringSubscription.filter(s => s.status === InvoiceStatus.REQUESTED).length;
    }, [recurringSubscription]);

    const hideSnackbar = useCallback(() => {
        resetStatusVariables();
    }, [resetStatusVariables]);

    useEffect(() => {
        switch (props.history.location.pathname) {
            case PaymentRoutingPatient[0].path: {
                setActiveTab(PaymentRoutingPatient[0].tab);
                break;
            }
            case PaymentRoutingPatient[1].path: {
                setActiveTab(PaymentRoutingPatient[1].tab);
                break;
            }
            case PaymentRoutingPatient[2].path: {
                setActiveTab(PaymentRoutingPatient[2].tab);
                break;
            }
            default: {
                setActiveTab(0);
            }
        }
    }, [props.history.location.pathname]);

    return (
        <>
            <HeaderHeadingWithLink>Payments</HeaderHeadingWithLink>
            <PaymentTabs
                activeTab={activeTab}
                setActiveTab={setTab}
                oneTime={countOneTimeRequested}
                subscription={recurringSubscriptionNumber}
                user={user}
            />

            {loadingData && <Loading />}

            {activeTab === 0 && (
                <Fade in={activeTab === 0} timeout={{ enter: 500, exit: 500 }}>
                    <>
                        <OneTimeRequestedPatient />
                        <HistoryBlock />
                    </>
                </Fade>
            )}

            {activeTab === 1 && (
                <Fade in={activeTab === 1} timeout={{ enter: 500, exit: 500 }}>
                    <>
                        <RecurringSubscriptionOnePatient user={user} invoices={recurringSubscription} />
                        <InactiveBlock />
                    </>
                </Fade>
            )}

            {activeTab === 2 && (
                <Fade in={activeTab === 2} timeout={{ enter: 500, exit: 500 }}>
                    <SettingsPatient />
                </Fade>
            )}

            {rejectRequest === RequestState.SENT_SUCCESS && (
                <Snackbar
                    type={SnackbarTypes.SUCCESS}
                    message={rejectMessage}
                    open
                    variant="success"
                    onClose={hideSnackbar}
                />
            )}

            {unsubscribeInvoiceRequest === RequestState.SENT_SUCCESS && (
                <Snackbar
                    type={SnackbarTypes.SUCCESS}
                    message={unsubscribeMessage}
                    open
                    variant="success"
                    onClose={hideSnackbar}
                />
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loadingOneTimeRequested: state.invoice.loadingOneTimeRequested,
        invoiceOneTimeRequested: state.invoice.invoiceOneTimeRequested,
        recurringSubscription: state.invoice.recurringSubscriptionPatient,
        loadingRecurringSubscription: state.invoice.loadingRecurringSubscription,
        rejectRequest: state.invoice.rejectRequest,
        unsubscribeInvoiceRequest: state.invoice.unsubscribeInvoiceRequest,
        user: state.authorization.user,
        totalAmountRecurringSubscriptionPatient: state.invoice.totalAmountRecurringSubscriptionPatient,
        countOneTimeRequested: state.invoice.countOneTimeRequested,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllRecurringSubscriptionPatient: bindActionCreators(
            invoiceAction.Actions.getAllRecurringSubscriptionPatient,
            dispatch
        ),
        resetArchived: bindActionCreators(invoiceAction.Actions.resetArchived, dispatch),
        resetStatusVariables: bindActionCreators(invoiceAction.Actions.resetStatusVariables, dispatch),
        getAllRecurringSubscriptionPatientSuccess: bindActionCreators(
            invoiceAction.Actions.getAllRecurringSubscriptionPatientSuccess,
            dispatch
        ),
        getOneTimeRequestedPatientCount: bindActionCreators(
            invoiceAction.Actions.getOneTimeRequestedPatientCount,
            dispatch
        ),
    })
)(withRouter(PaymentPatient));
