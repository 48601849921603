import React from 'react';
import styled from 'styled-components';
import VectorIcon from '../../../../static/images/landing/about/Vector.png';
import { colors } from '../../../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import { mediaQuery } from '../../../../constants/device';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 500px;
    margin-top: 30px;

    background: ${colors.redMain};
`;

const Icon = styled.div`
    width: 57px;
    height: 80px;
    margin-top: 60px;

    background: url(${VectorIcon}) no-repeat;
    background-size: 57px 80px;

    ${mediaQuery.tablet} {
        position: absolute;
        height: 400px;
        width: 23%;
        align-self: baseline;
        margin-top: 5%;
        margin-left: 5%;
        background-size: contain;
    }
`;

const createStyles = makeStyles({
    small: {
        width: '250px',
        left: '27px',
        marginTop: '50px',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '18px',
        color: `${colors.white}`,
    },
    large: {
        width: '299px',
        height: '39px',
        marginTop: '10px',
        fontSize: '36px',
        lineHeight: '54px',
        color: `${colors.white}`,
    },
});

const ServicesMobile = () => {
    const classes = createStyles();
    return (
        <Wrapper>
            <Icon />
            <Typography variant="button" classes={{ root: classes.small }}>
                UPMED SERVICE – TERMS AND CONDITIONS
            </Typography>
            <Typography variant="h2" classes={{ root: classes.large }}>
                UPmed Services
            </Typography>
        </Wrapper>
    );
};

export default ServicesMobile;
