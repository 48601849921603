import React from 'react';
import { Button, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { Close, Menu } from '@material-ui/icons';
import { colors } from '../../theme/colors';

const styles = createStyles({
    root: {
        minWidth: 'auto',
        padding: '23px 21px !important',
        borderRadius: '30px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    label: {
        width: '17.5px',
        height: '14.69px',
        color: colors.redMain,
    },
});

interface InjectedProps {
    open: boolean;
    onClickMenu: () => void;
}

type PropsType = InjectedProps & WithStyles<typeof styles>;

const MenuButton: React.FC<PropsType> = props => {
    const { classes } = props;
    return (
        <Button classes={{ root: classes.root, label: classes.label }} onClick={props.onClickMenu}>
            {props.open ? <Close /> : <Menu />}
        </Button>
    );
};

export default withStyles(styles)(MenuButton);
