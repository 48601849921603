import {ActionsOfType, ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {GetAnalyticsPatientDto} from "../../models/dto/getAnalyticsPatient.dto";
import {AnalyticsModel} from "../../models/responses/analytics.responce";
import {AnalyticsResultModel} from "../../models/responses/analyticsResult.model";

export enum ActionNames {
    GET_ANALYTICS_PATIENT = '[Analytics] Get Analytics Patient',
    GET_ANALYTICS_PATIENT_SUCCESS = '[Analytics] Get Analytics Patient Success',
    GET_ANALYTICS_PATIENT_FAILED = '[Analytics] Get Analytics Patient Failed',

    GET_ANALYTICS_RESULT = '[Analytics] Get Analytics Result',
    CLEAR_ANALYTICS = '[Analytics] Clear Analytics',
    CLEAR_ANALYTICS_DATE = '[Analytics] Clear Analytics Date',

    RESET_STATUS_VARIABLES = '[Analytics] Reset Status Variables',
}

export const Actions = {
    getAnalyticsPatient: (data: GetAnalyticsPatientDto) => createAction(ActionNames.GET_ANALYTICS_PATIENT, data),
    getAnalyticsPatientSuccess: (data: AnalyticsModel) => createAction(ActionNames.GET_ANALYTICS_PATIENT_SUCCESS, data),
    getAnalyticsPatientFailed: () => createAction(ActionNames.GET_ANALYTICS_PATIENT_FAILED),

    getAnalyticsResult: (data: AnalyticsResultModel) => createAction(ActionNames.GET_ANALYTICS_RESULT, data),
    clearAnalytics: () => createAction(ActionNames.CLEAR_ANALYTICS),
    clearAnalyticsDate: () => createAction(ActionNames.CLEAR_ANALYTICS_DATE),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAnalyticsPatientType = ActionsOfType<Actions, ActionNames.GET_ANALYTICS_PATIENT>;