import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';
import {LabModel} from "../../models/responses/lab.model";

export enum ActionNames {
    GET_ALL_LABS = '[Lab] Get All Labs',
    GET_ALL_LABS_UPDATE_SUCCESS = '[Lab] Get All Labs Update Success',
    GET_ALL_LABS_OVERRIDE_SUCCESS = '[Lab] Get All Labs Override Success',
    GET_ALL_LABS_FAILED = '[Lab] Get All Labs Failed',

    INCREASE_PAGE_NUMBER = '[Lab] Increase Page Number',
    SET_FILTER_QUERY = '[Lab] Set Filter Query',

    GET_LAB_BY_ID = '[Lab] Get Lab By Id',
    GET_LAB_BY_ID_SUCCESS = '[Lab] Get Lab By Id Success',
    GET_LAB_BY_ID_FAILED = '[Lab] Get Lab By Id Failed',

    SAVE_LABS = '[Lab] Save Labs',
    SAVE_LABS_SUCCESS = '[Lab] Save Labs Success',
    SAVE_LABS_FAILED = '[Lab] Save Labs Failed',

    UPDATE_LABS = '[Lab] Update Labs',
    UPDATE_LABS_SUCCESS = '[Lab] Update Labs Success',
    UPDATE_LABS_FAILED = '[Lab] Update Labs Failed',

    RESET_STATUS_VARIABLES = '[Labs] Request Status Variables'
}

export const Actions = {
    getAllLabs: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_LABS, data),
    getAllLabsUpdateSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_LABS_UPDATE_SUCCESS, data),
    getAllLabsOverrideSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_LABS_OVERRIDE_SUCCESS, data),
    getAllLabsFailed: () => createAction(ActionNames.GET_ALL_LABS_FAILED),

    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER),
    setFilterQuery: (searchQuery: string) => createAction(ActionNames.SET_FILTER_QUERY, searchQuery),

    getLabById: (id: number) => createAction(ActionNames.GET_LAB_BY_ID, id),
    getLabByIdSucces: (lab: LabModel) => createAction(ActionNames.GET_LAB_BY_ID_SUCCESS, lab),
    getLabByIdFailed: () => createAction(ActionNames.GET_LAB_BY_ID_FAILED),

    saveLabs: (data:LabModel[]) => createAction(ActionNames.SAVE_LABS, data),
    saveLabsSuccess: () => createAction(ActionNames.SAVE_LABS_SUCCESS),
    saveLabsFailed: () => createAction(ActionNames.SAVE_LABS_FAILED),

    updateLabs: (data:LabModel[]) => createAction(ActionNames.UPDATE_LABS, data),
    updateLabsSuccess: () => createAction(ActionNames.UPDATE_LABS_SUCCESS),
    updateLabsFailed: () => createAction(ActionNames.UPDATE_LABS_FAILED),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllLabsType = ActionsOfType<Actions, ActionNames.GET_ALL_LABS>;
export type getLabByIdType = ActionsOfType<Actions, ActionNames.GET_LAB_BY_ID>;
export type saveLabsType = ActionsOfType<Actions, ActionNames.SAVE_LABS>;
export type updateLabsType = ActionsOfType<Actions, ActionNames.UPDATE_LABS>;
