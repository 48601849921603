import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import { FormControl, FormHelperText, ListItem, makeStyles, Typography } from '@material-ui/core';
import TextInput from '../../../shared/input/TextInput';
import { colors } from '../../../theme/colors';
import PhoneNumberInput from '../../../shared/input/PhoneNumberInput';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import arrowRight from '../../../static/icons/arrowRight.svg';
import { RouteComponentProps, withRouter } from 'react-router';
import fillClassInstanceWithValues from '../../../shared/utils/fillClassInstanceWithValues';
import { validateSync } from 'class-validator';
import { ValidationErrorsMap } from '../../../models/validation/validationErrorsMap';
import { useDebounce } from 'use-debounce';
import { ProviderModel } from '../../../models/responses/provider.response';
import { ProviderProfileDto } from '../../../models/validation/providerProfileValidation';
import { mediaQuery } from '../../../constants/device';
import ChangePasswordButton from '../../changePassword/ChangePasswordButton';

const useStyles = makeStyles({
    label: {
        display: 'block',
        fontSize: '10px',
        color: colors.gray1,
        marginLeft: '12px',
        marginTop: '3px',
    },
    arrowRightIcon: {
        position: 'absolute',
        right: '14px',
        top: '18px',
    },
    listItemButton: {
        padding: 0,
    },
});

const BackgroundWrapper = styled.div``;

const Wrapper = styled.div`
    padding: 20px 14px 55px;
    ${mediaQuery.laptop} {
        width: 400px;
        margin: 0 104px;
    }
`;

const InputWrapper = styled(FormControl)`
    padding-bottom: 25px !important;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;

const FormWrapper = styled.div`
    & > div:last-child {
        margin-bottom: 0 !important;
    }
`;

const InputButtonWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const EditModeLabel = styled.div<any>`
    position: absolute;
    visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

const ErrorWrapper = styled.div`
    position: absolute;
`;

interface InjectedProps {
    provider: ProviderModel | null;
    updateProvider: (provider: any) => void;
    setEditModeOff: () => void;
    setEditModeOn: () => void;
    resetStatusVariables: () => void;
    editMode: boolean;
    connectedAccountLink: string | null;
}

type PropsTypes = RouteComponentProps<any> & InjectedProps;

const phoneInputEmptyValue = '+ (   )    .   .   ';
const requiredFields = ['firstName', 'lastName'];
const optionalFields = ['phone', 'businessName', 'address'];

const initialState = {
    firstName: '',
    lastName: '',
    phone: '',
    businessName: '',
    address: '',
    isBeingEdited: new Set<string>(),
};

const ProfileInfoProvider: React.FC<PropsTypes> = props => {
    const [newProfile, setNewProfile] = useState(initialState);

    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    const { resetStatusVariables, provider } = props;

    useEffect(() => {
        setNewProfile({
            firstName: provider ? provider.firstName : '',
            lastName: provider ? provider.lastName : '',
            phone: provider && provider.phone ? provider.phone : '',
            address: provider && provider.address ? provider.address : '',
            businessName: provider && provider.businessName ? provider.businessName : '',
            isBeingEdited: new Set(),
        });
    }, [provider]);

    useEffect(() => {
        validateForm(newProfile);
    }, [newProfile]);

    useEffect(() => {
        return () => {
            setValidationErrorsMap({});
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    const sendForm = () => {
        if (isValid()) {
            if (props.provider && props.editMode) {
                props.updateProvider({
                    firstName: newProfile.firstName,
                    lastName: newProfile.lastName,
                    businessName: newProfile.businessName,
                    address: newProfile.address,
                    phone: newProfile.phone,
                });
                setValidationErrorsMap({});
                props.setEditModeOff();
                window.scrollTo(0, 0);
                props.history.push('/profile');
            }
        }
    };

    const validateForm = (user: any, isOnSubmit = false) => {
        const item = fillClassInstanceWithValues<ProviderProfileDto>(ProviderProfileDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                    map[err.property] = Object.entries(err.constraints)
                        .map(([, value]) => value)
                        .join(', ');
                }
            });
            setValidationErrorsMap(map);
        }
        setValidationErrorsMap(map);
        return map;
    };

    const changeProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUser = {
            ...newProfile,
            [e.target.id]: e.target.value,
            isBeingEdited: newProfile.isBeingEdited.add(e.target.id),
        };
        if (e.target.value.length === 0 && optionalFields.includes(e.target.id)) {
            newUser.isBeingEdited.delete(e.target.id);
        }
        setNewProfile(newUser);
    };

    const isValid = () => {
        const map = validateForm(newProfile, true);
        let valid = true;
        requiredFields.forEach(field => {
            if (map.hasOwnProperty(field)) {
                valid = false;
            }
        });
        optionalFields.forEach(field => {
            if (map.hasOwnProperty(field) && newProfile.isBeingEdited.has(field)) {
                valid = false;
            }
        });
        if (valid) {
            setValidationErrorsMap({});
        }
        return valid;
    };

    const renderError = (field: keyof ValidationErrorsMap) => {
        if (!!validationErrorsMap[field]) {
            return (
                <ErrorWrapper>
                    <FormHelperText id="component-error-text">
                        {validationErrorsMap[field].split(',')[0]}
                    </FormHelperText>
                </ErrorWrapper>
            );
        }
    };

    const classes = useStyles(props);

    return (
        <BackgroundWrapper>
            <SizeControllerWrapper>
                <Wrapper>
                    <FormWrapper>
                        {props.editMode && (
                            <>
                                <InputWrapper error={!!validation.firstName}>
                                    <TextInput
                                        placeholder={'First Name'}
                                        value={newProfile.firstName}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                            changeProfile(evt);
                                        }}
                                        inputProps={{ id: 'firstName' }}
                                        error={!!validation.firstName}
                                        disabled={!props.editMode}
                                    />
                                    {!!validation.firstName ? (
                                        renderError('firstName')
                                    ) : (
                                        <EditModeLabel show={props.editMode}>
                                            <Typography variant="button" classes={{ root: classes.label }}>
                                                First Name
                                            </Typography>
                                        </EditModeLabel>
                                    )}
                                </InputWrapper>

                                <InputWrapper error={!!validation.lastName}>
                                    <TextInput
                                        placeholder={'Last Name'}
                                        value={newProfile.lastName}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                            changeProfile(evt);
                                        }}
                                        inputProps={{ id: 'lastName' }}
                                        error={!!validation.lastName}
                                        disabled={!props.editMode}
                                    />
                                    {!!validation.lastName ? (
                                        renderError('lastName')
                                    ) : (
                                        <EditModeLabel show={props.editMode}>
                                            <Typography variant="button" classes={{ root: classes.label }}>
                                                Last Name
                                            </Typography>
                                        </EditModeLabel>
                                    )}
                                </InputWrapper>
                            </>
                        )}

                        {!props.editMode && props.provider && (
                            <InputWrapper>
                                <TextInput
                                    placeholder={'Business Name'}
                                    value={props.provider ? props.provider.businessName : 'Empty'}
                                    onChange={() => {}}
                                    disabled
                                />
                            </InputWrapper>
                        )}

                        {props.editMode && (
                            <InputWrapper error={!!validation.businessName}>
                                <TextInput
                                    placeholder={'Business Name'}
                                    value={newProfile.businessName}
                                    inputProps={{ id: 'businessName' }}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        changeProfile(evt);
                                    }}
                                    error={!!validation.businessName}
                                />
                            </InputWrapper>
                        )}

                        {props.editMode && (
                            <InputWrapper error={!!validation.phone}>
                                <PhoneNumberInput
                                    placeholder={'+ (000) 000.0000'}
                                    value={newProfile.phone}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        if (
                                            evt.target.value !== phoneInputEmptyValue ||
                                            newProfile.isBeingEdited.has('phone')
                                        ) {
                                            changeProfile(evt);
                                        }
                                    }}
                                    inputProps={{ id: 'phone' }}
                                    error={!!validation.phone && newProfile.isBeingEdited.has('phone')}
                                    disabled={!props.editMode}
                                />
                                {!!validation.phone && newProfile.isBeingEdited.has('phone') ? (
                                    renderError('phone')
                                ) : (
                                    <EditModeLabel show={props.editMode}>
                                        <Typography variant="button" classes={{ root: classes.label }}>
                                            Phone
                                        </Typography>
                                    </EditModeLabel>
                                )}
                            </InputWrapper>
                        )}

                        {!props.editMode && props.provider && (
                            <InputWrapper>
                                <TextInput
                                    placeholder={'Phone'}
                                    value={props.provider.phone ? props.provider.phone : 'Empty'}
                                    color={props.provider.phone ? colors.textSecondary : colors.grayUnderline}
                                    disabled
                                />
                            </InputWrapper>
                        )}

                        {!props.editMode && props.provider && (
                            <InputWrapper>
                                <TextInput
                                    placeholder={'Address'}
                                    value={props.provider ? props.provider.address : 'Empty'}
                                    onChange={() => {}}
                                    disabled
                                />
                            </InputWrapper>
                        )}

                        {props.editMode && (
                            <InputWrapper error={!!validation.address}>
                                <TextInput
                                    placeholder={'Address'}
                                    value={newProfile.address}
                                    inputProps={{ id: 'address' }}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        changeProfile(evt);
                                    }}
                                    error={!!validation.address}
                                />
                            </InputWrapper>
                        )}

                        {(props.editMode || (props.provider && props.provider.email)) && (
                            <InputWrapper>
                                <TextInput
                                    placeholder={'Email'}
                                    value={props.provider ? props.provider.email : ''}
                                    onChange={() => {}}
                                    disabled
                                />
                                <EditModeLabel show={props.editMode}>
                                    <Typography variant="button" classes={{ root: classes.label }}>
                                        Email
                                    </Typography>
                                </EditModeLabel>
                            </InputWrapper>
                        )}

                        <ListItem button classes={{ root: classes.listItemButton }}>
                            <InputButtonWrapper>
                                <TextInput placeholder={'Lab'} value={'Alpha.Lab'} disabled />
                                <img src={arrowRight} alt="" className={classes.arrowRightIcon} />
                            </InputButtonWrapper>
                        </ListItem>
                    </FormWrapper>
                    <ButtonWrapper>
                        <PrimaryButton onClick={props.editMode ? sendForm : props.setEditModeOn}>
                            {props.editMode ? 'Save Profile' : 'Edit Profile'}
                        </PrimaryButton>
                    </ButtonWrapper>
                    {!props.editMode && <ChangePasswordButton />}
                </Wrapper>
            </SizeControllerWrapper>
        </BackgroundWrapper>
    );
};

export default withRouter(ProfileInfoProvider);
