import { roundToTwoPoints } from './round';

const INCH_IN_CM = 2.54;
const FOOT_IN_CM = 30.48;

export interface FeetInchModel {
    feet: number;
    inch: number;
}

export const InchToCm = (inch: number) => {
    return roundToTwoPoints(inch * INCH_IN_CM);
};

export const CmToInch = (cm: number) => {
    return roundToTwoPoints(cm / INCH_IN_CM);
};

export const CmToFeetAndInch = (cm: number): FeetInchModel => {
    const feet = Math.floor(cm / FOOT_IN_CM);
    const leftoversCm = cm - FeetToCm(feet);
    const inch = CmToInch(leftoversCm);
    return { feet, inch };
};

export const FeetToCm = (feet: number) => {
    return roundToTwoPoints(feet * FOOT_IN_CM);
};
