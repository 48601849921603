import React, { useMemo } from 'react';
import { Button, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import forwardIcon from '../../../static/icons/forwardIcon.svg';
import { ProviderModel } from '../../../models/responses/provider.response';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import CustomLink from '../../../shared/link/CustomLink';
import { AcceptProviderStatus } from '../../../constants/acceptProviderStatus';
import providerPendingIcon from '../../../static/icons/provider/providerPendingIcon.svg';
import providerLinkedIcon from '../../../static/icons/provider/providerLinkedIcon.svg';
import AvatarShared from '../../shared/AvatarShared';
import { connect } from 'react-redux';
import { UserModel } from '../../../models/responses/user.response';
import * as fromRoot from '../../../store/reducers/index';
import {RoleOptions} from "../../../constants/roleOptions";

const useStyles = makeStyles({
    listItem: {
        padding: 0,
    },
    nameText: {
        fontSize: '14px',
        color: colors.textPrimary,
    },
    typeText: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'capitalize',
    },
    avatarWrapper: {
        minWidth: 'auto',
        marginRight: '10px',
        position: 'relative',
    },
    forwardIcon: {
        padding: '0 !important',
        minWidth: '25px',
        height: '25px',
    },
});

const TextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: -6px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;
const PendingIconWrapper = styled.div`
    width: 14px;
    height: 14px;
    background: ${colors.textSecondary};
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
`;

const ApprovedIconWrapper = styled.div`
    width: 14px;
    height: 14px;
    background: ${colors.redMain};
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
`;

interface StoreModel {
    user?: null | UserModel;
}

interface InjectedProps {
    provider: ProviderModel;
}

type PropsTypes = InjectedProps & StoreModel;

const ProviderListItem: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);
    const { user, provider } = props;

    const redirectLink = useMemo(() => {
        if (user) {
            return user.role === RoleOptions.ADMIN ? `/admin/provider/${provider.id}` : `/provider/${provider.id}`;
        }
        return '';
    }, [user, provider]);

    return (
        <CustomLink to={redirectLink}>
            <ListItem classes={{ root: classes.listItem }} button>
                <SizeControllerWrapper>
                    <Wrapper>
                        <ListItemAvatar classes={{ root: classes.avatarWrapper }}>
                            <>
                                <AvatarShared img={provider.avatarThumbnailUrl} />
                                {provider.status === AcceptProviderStatus.APPROVED && (
                                    <ApprovedIconWrapper>
                                        <img src={providerLinkedIcon} alt="" />
                                    </ApprovedIconWrapper>
                                )}
                                {provider.status === AcceptProviderStatus.PENDING && (
                                    <PendingIconWrapper>
                                        <img src={providerPendingIcon} alt="" />
                                    </PendingIconWrapper>
                                )}
                            </>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <TextWrapper>
                                    <Typography variant="button" classes={{ root: classes.nameText }}>
                                        {provider.firstName} {provider.lastName}{' '}
                                    </Typography>
                                </TextWrapper>
                            }
                            secondary={
                                <Typography variant="body1" classes={{ root: classes.typeText }}>
                                    doctor Second class
                                </Typography>
                            }
                        />

                        <Button classes={{ root: classes.forwardIcon }}>
                            <img src={forwardIcon} alt="" />
                        </Button>
                    </Wrapper>
                </SizeControllerWrapper>
            </ListItem>
        </CustomLink>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        user: store.authorization.user,
    }),
    null
)(ProviderListItem);
