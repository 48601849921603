import { IsOptional, IsString, MaxLength, MinLength } from 'class-validator';
import { registerDecorator, ValidationOptions } from 'class-validator';

export function isPhoneNumberValid(validationOptions?: ValidationOptions) {
    return function(object: Object, propertyName: string) {
        registerDecorator({
            name: 'isPhoneNumberValid',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: {
                validate(value: string) {
                    return (value.trim().match(/ /g) || []).length === 2 && value.trim().length === 16;
                },
            },
        });
    };
}

export class ProviderProfileDto {
    @IsString()
    @MinLength(1, { message: 'First Name cannot be empty' })
    @MaxLength(30, { message: 'First Name is too long' })
    firstName: string;

    @IsString()
    @MinLength(1, { message: 'Last Name cannot be empty' })
    @MaxLength(30, { message: 'Last Name is too long' })
    lastName: string;

    @IsOptional()
    @IsString()
    @isPhoneNumberValid({ message: 'Phone is not valid' })
    phone?: string;
}
