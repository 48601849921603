import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as LabAction } from '../../store/actions/lab.actions';
import { RouteComponentProps, withRouter } from 'react-router';
import { LabModel } from '../../models/responses/lab.model';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import styled from 'styled-components';
import AvatarProfileBigShared from '../shared/AvatarProfileBigShared';
import TextInput from '../../shared/input/TextInput';
import { FormFields } from './AddLabRow';
import PhoneNumberInput from '../../shared/input/PhoneNumberInput';
import PrimaryButton from '../../shared/button/PrimaryButton';
import RequestState from '../../constants/requestState';
import LoadingTransparent from '../shared/LoadingTransparent';

const Wrapper = styled.div`
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FormWrapper = styled.div`
    margin: 32px 0;
    align-self: stretch;
    > div {
        margin-bottom: 16px !important;
    }
`;

interface StateModel {
    loading: boolean;
    currentLab: LabModel | null;
    updateLabsRequest: RequestState;
}

interface DispatchModel {
    getLabById: typeof LabAction.getLabById;
    updateLabs: typeof LabAction.updateLabs;
    resetStatusVariables: typeof LabAction.resetStatusVariables;
}

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const EditLab: React.FC<PropsTypes> = props => {
    const { loading, currentLab, getLabById, updateLabsRequest, updateLabs, resetStatusVariables, match, history } = props;

    const [lab, setLab] = useState();

    const changeForm = (formField: string, value: string) => {
        setLab({ ...lab, [formField]: value });
    };

    useEffect(() => {
        setLab(currentLab);
    }, [currentLab]);

    useEffect(() => {
        if (match.params.id) {
            getLabById(match.params.id);
        }
    }, [match, getLabById]);

    const isLoading = useMemo(() => {
        return loading && updateLabsRequest === RequestState.SENDING;
    }, [loading, updateLabsRequest]);

    const update = useCallback(() => {
        updateLabs([lab]);
    }, [updateLabs, lab]);

    useEffect(() => {
        updateLabsRequest === RequestState.SENT_SUCCESS && history.push(`/admin/lab/${match.params.id}`);
    }, [updateLabsRequest, history, match]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    return (
        <>
            <HeaderHeadingWithLink>Edit Lab</HeaderHeadingWithLink>
            {isLoading && <LoadingTransparent />}
            {lab && !loading && (
                <SizeControllerWrapper>
                    <Wrapper>
                        <AvatarProfileBigShared avatar={null} editMode={false} isDefaultAvatar />
                        <FormWrapper>
                            <TextInput
                                placeholder={'Name'}
                                value={lab.name}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.name, evt.target.value)
                                }
                            />
                            <TextInput
                                placeholder={'Website'}
                                value={lab.website}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.website, evt.target.value)
                                }
                            />
                            <PhoneNumberInput
                                placeholder={'+ (000) 000.0000'}
                                value={lab.phone}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeForm(FormFields.phone, evt.target.value);
                                }}
                            />
                            <TextInput
                                placeholder={'Email'}
                                value={lab.email}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.email, evt.target.value)
                                }
                            />
                            <TextInput
                                placeholder={'Street Address 1'}
                                value={lab.streetAddress1}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.streetAddress1, evt.target.value)
                                }
                            />
                            <TextInput
                                placeholder={'Street Address2'}
                                value={lab.streetAddress2}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.streetAddress2, evt.target.value)
                                }
                            />
                            <TextInput
                                placeholder={'City'}
                                value={lab.city}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.city, evt.target.value)
                                }
                            />
                            <TextInput
                                placeholder={'State'}
                                value={lab.state}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.state, evt.target.value)
                                }
                            />
                            <TextInput
                                placeholder={'Zip'}
                                value={lab.zip}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.zip, evt.target.value)
                                }
                            />
                        </FormWrapper>
                        <PrimaryButton onClick={update}>Save</PrimaryButton>
                    </Wrapper>
                </SizeControllerWrapper>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.lab.loading,
        currentLab: state.lab.currentLab,
        updateLabsRequest: state.lab.updateLabsRequest,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getLabById: bindActionCreators(LabAction.getLabById, dispatch),
        updateLabs: bindActionCreators(LabAction.updateLabs, dispatch),
        resetStatusVariables: bindActionCreators(LabAction.resetStatusVariables, dispatch),
    })
)(withRouter(EditLab));
