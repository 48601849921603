export const DosageUnitOptions = [
    { id: 'AU', name: 'AU' },
    { id: 'AU_ML', name: 'AU/mL' },
    { id: 'BAR', name: 'bar' },
    { id: 'BAU', name: 'BAU' },
    { id: 'BAU_ML', name: 'BAU/mL' },
    { id: 'BEAD', name: 'bead' },
    { id: 'BU', name: 'BU' },
    { id: 'CAPSULE', name: 'capsule' },
    { id: 'CCID_50', name: 'CCID_50' },
    { id: 'CELLULAR_SHEET', name: 'cellular sheet' },
    { id: 'CI', name: 'Ci' },
    { id: 'CLOTH', name: 'cloth' },
    { id: 'CM_2', name: 'cm^2' },
    { id: 'D_AG_U', name: "D'ag'U" },
    { id: 'DISC', name: 'disc' },
    { id: 'DL', name: 'dL' },
    { id: 'DOUCHE', name: 'douche' },
    { id: 'DROP', name: 'drop' },
    { id: 'FFU', name: 'FFU' },
    { id: 'G', name: 'g' },
    { id: 'GLOBULE', name: 'globule' },
    { id: 'GRANULE', name: 'granule' },
    { id: 'GUM', name: 'gum' },
    { id: 'HP_C', name: 'hp_C' },
    { id: 'HP_M', name: 'hp_M' },
    { id: 'HP_Q', name: 'hp_Q' },
    { id: 'HP_X', name: 'hp_X' },
    { id: 'IU', name: 'IU' },
    { id: 'IU_L', name: 'IU/L' },
    { id: 'IU_ML', name: 'IU/mL' },
    { id: 'KP_C', name: 'kp_C' },
    { id: 'L', name: 'L' },
    { id: 'LF', name: 'Lf' },
    { id: 'LFU_ML', name: 'LfU/mL' },
    { id: 'LOZENGE', name: 'lozenge' },
    { id: 'MCI', name: 'mCi' },
    { id: 'MCI_ML', name: 'mCi/mL' },
    { id: 'MEQ', name: 'mEq' },
    { id: 'MG', name: 'mg' },
    { id: 'MG_ACTUAT', name: 'mg/actuat' },
    { id: 'MG_HR', name: 'mg/hr' },
    { id: 'MG_MG', name: 'mg/mg' },
    { id: 'MG_ML', name: 'mg/mL' },
    { id: 'ML', name: 'mL' },
    { id: 'MMOL', name: 'mmol' },
    { id: 'MOL', name: 'mol' },
    { id: 'MU', name: 'mU' },
    { id: 'NG', name: 'ng' },
    { id: 'NG_DL', name: 'ng/dl' },
    { id: 'NMOL', name: 'nmol' },
    { id: 'ORGANISMS', name: 'organisms' },
    { id: 'PATILLE', name: 'pastille' },
    { id: 'PATCH', name: 'patch' },
    { id: 'PELLET', name: 'pellet' },
    { id: 'PFU', name: 'PFU' },
    { id: 'PG_ML', name: 'pg/ml' },
    { id: 'PILL', name: 'pill' },
    { id: 'PNU', name: 'PNU' },
    { id: 'PNU_ML', name: 'PNU/mL' },
    { id: 'POUCH', name: 'pouch' },
    { id: 'PUFF', name: 'puff' },
    { id: 'RING', name: 'ring' },
    { id: 'SALVE', name: 'salve' },
    { id: 'STICK', name: 'stick' },
    { id: 'STRIP', name: 'strip' },
    { id: 'SUPPOSITORY', name: 'suppository' },
    { id: 'SWAB', name: 'swab' },
    { id: 'TABLET', name: 'tablet' },
    { id: 'TAMPON', name: 'tampon' },
    { id: 'TAPE', name: 'tape' },
    { id: 'TBSP', name: 'tbsp' },
    { id: 'TCID_50', name: 'TCID_50' },
    { id: 'TSP', name: 'tsp' },
    { id: 'U', name: 'U' },
    { id: 'UCI', name: 'uCi' },
    { id: 'UG', name: 'ug' },
    { id: 'UG_ML', name: 'ug/mL' },
    { id: 'UL', name: 'uL' },
    { id: 'UMOL', name: 'umol' },
    { id: 'UNT', name: 'unt' },
    { id: 'UNT_ML', name: 'unt/mL' },
    { id: 'USP_U', name: "USP'U" },
    { id: 'VIAL', name: 'vial' },
    { id: 'WAFER', name: 'wafer' },
    { id: 'X', name: 'X' },
];
