import React, { useCallback } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { colors } from '../../theme/colors';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 28px;
`;

const createStyles = makeStyles({
    cancel: {
        fontSize: '14px',
        color: colors.grayUnderline,
        textDecoration: 'underline',
        textDecorationColor: colors.grayUnderline,
        cursor: 'pointer',
    },
});

const CancelLink: React.FC<{ link?: string; onClick?: () => void } & RouteComponentProps<any>> = props => {
    const { link, onClick } = props;
    const classes = createStyles(props);

    const redirect = useCallback(() => {
        if (onClick) {
            onClick();
            return;
        }
        link ? props.history.push(link) : props.history.goBack();
    }, [link, onClick, props.history]);

    return (
        <Wrapper>
            <Typography variant="button" classes={{ root: classes.cancel }} onClick={redirect}>
                Cancel
            </Typography>
        </Wrapper>
    );
};

export default withRouter(CancelLink);
