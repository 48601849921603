import React, {useEffect} from 'react';
import Footer from "../../../shared/Footer";
import InfoWithVideoLink from "../shared/InfoWithVideoLink";
import resourcesHeaderMobile from "../../../static/images/landing/resources/resourcesHeaderMobile.png";
import resourcesHeaderDesktop from "../../../static/images/landing/resources/resourcesHeaderDesktop.png";
import {RoleOptions} from "../../../constants/roleOptions";
import {makeStyles, Typography} from "@material-ui/core";
import {colors} from "../../../theme/colors";
// import LandingBigHeading from "../../shared/LandingBigHeading";
// import LandingRedUppercaseHeading from "../../shared/LandingRedUppercaseHeading";
import styled from "styled-components";
// import NewsComponent from "./componets/NewsComponent";
// import newsImg1 from '../../../static/images/landing/resources/newsImg1.png';
// import newsImg2 from '../../../static/images/landing/resources/newsImg2.png';
// import newsImg3 from '../../../static/images/landing/resources/newsImg3.png';
// import resourceCardWithTopImage from '../../../static/images/landing/resources/resourceCardWithTopImage.png';
import PrimaryButton from "../../../shared/button/PrimaryButton";
// import CardWithTopImageList, {CardColor} from "../../../shared/card/CardWithTopImageList";
import CaseStudyComponent from "./componets/CaseStudyComponent";
import caseStudy1 from '../../../static/images/landing/resources/caseStudy1.png';
import {ImgSide} from "../../../shared/card/CardWithTopImageGray";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {mediaQuery} from "../../../constants/device";

const createStyles = makeStyles({
    infoWithVideoLinkText: {
        fontSize: '20px',
        lineHeight: '35px',
        color: colors.white,
        display: 'block',
    },
    headingRedBlock: {}
});

// const RedHeaderWrapper = styled.div`
//    margin: 100px 0 12px;
//    ${mediaQuery.tablet}{
//       margin: 0 0 12px;
//    }
// `;
//
// const NewsWrapper = styled.div`
//   margin-top: 46px;
//   margin-bottom: 65px;
//   > div{
//   margin-bottom: 56px;
//   }
//   ${mediaQuery.tablet}{
//     margin-bottom: 100px;
//   }
// `;
//
// const ButtonWrapper = styled.div`
//   text-align: center;
// `;

const ButtonWrapperWithBackground = styled.div`
  position: relative;
  text-align: center;
  ${mediaQuery.tablet}{
    width: 65%;
  }
`;

const BackgroundWrapper = styled.div`
  background-color: ${colors.gray2};
  padding: 58px 0  78px;
`;

const WhiteSpace = styled.div`
  width: 5%;
  background-color: white;
  height: 200px;
  position: absolute;
  top: 0;
  right: 0;
  ${mediaQuery.tablet}{
    display: none;
  }
`;

// const NewsComponentWrapper = styled.div`
//   ${mediaQuery.tablet}{
//     padding: 0 5%;
//     display: flex;
//   }
//   ${mediaQuery.laptopL}{
//     padding: 5% 12%;
//     display: flex;
//   }
// `;

const CaseStudyComponentsWrapper = styled.div`
  ${mediaQuery.tablet}{
    position: relative;
  }
`;

const DesktopBackground = styled.div`
  ${mediaQuery.tablet}{
    position: absolute;
    width: 65%;
    height: 100%;
    background-color: ${colors.gray2};
    z-index: -1;
  }
`;

const Resources: React.FC = (props) => {
    const classes = createStyles(props);

    useEffect(() => {
        window.scroll(0, 0)
    }, []);

    const isTablet = useMediaQuery(mediaQuery.tablet);

    const infoWithVideoLinkMainResources = isTablet ? resourcesHeaderDesktop : resourcesHeaderMobile;

    return (
        <>
            <InfoWithVideoLink
                role={RoleOptions.PATIENT}
                img={infoWithVideoLinkMainResources}
                imgSide={ImgSide.right}
            >
                <Typography variant='button' classes={{root: classes.infoWithVideoLinkText}}>
                    Track your doctor specific treatment, along with any additional supplementation you’re managing on
                    your own.
                </Typography>
            </InfoWithVideoLink>

            {/*<RedHeaderWrapper>*/}
                {/*<LandingRedUppercaseHeading>Industry Bites</LandingRedUppercaseHeading>*/}
            {/*</RedHeaderWrapper>*/}

            {/*<LandingBigHeading>News</LandingBigHeading>*/}

            {/*<NewsWrapper>*/}
                {/*<NewsComponentWrapper>*/}
                    {/*<NewsComponent*/}
                        {/*img={newsImg1}*/}
                        {/*heading={'Lorem ipsum dolor sit amet dolor sit amet'}*/}
                        {/*text={'Tracking the impact of supplementation over a long period of time has always been extremely difficult. UPmed has made it easy.'}*/}
                        {/*date={'August 14, 2019'}*/}
                    {/*/>*/}
                    {/*<NewsComponent*/}
                        {/*img={newsImg2}*/}
                        {/*heading={'Lorem ipsum dolor sit amet dolor sit amet'}*/}
                        {/*text={'Tracking the impact of supplementation over a long period of time has always been extremely difficult. UPmed has made it easy.'}*/}
                        {/*date={'August 14, 2019'}*/}
                    {/*/>*/}

                    {/*<NewsComponent*/}
                        {/*img={newsImg3}*/}
                        {/*heading={'Lorem ipsum dolor sit amet dolor sit amet'}*/}
                        {/*text={'Tracking the impact of supplementation over a long period of time has always been extremely difficult. UPmed has made it easy.'}*/}
                        {/*date={'August 14, 2019'}*/}
                    {/*/>*/}
                {/*</NewsComponentWrapper>*/}
                {/*<ButtonWrapper>*/}
                    {/*<PrimaryButton>Load more</PrimaryButton>*/}
                {/*</ButtonWrapper>*/}

            {/*</NewsWrapper>*/}

            {/*<CardWithTopImageList*/}
                {/*imageMargin*/}
                {/*img={resourceCardWithTopImage}*/}
                {/*color={CardColor.red}*/}
                {/*backgroundColor={colors.white}*/}
                {/*list={[*/}
                    {/*{*/}
                        {/*text: 'Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam.' +*/}
                            {/*' In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel. ',*/}
                        {/*heading: 'Vestibulum lacinia arcu eget',*/}
                        {/*path: '',*/}
                    {/*},*/}
                    {/*{*/}
                        {/*text: 'Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam.' +*/}
                            {/*' In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel. ',*/}
                        {/*heading: 'Class aptent taciti sociosqu',*/}
                        {/*path: '',*/}
                    {/*},*/}
                    {/*{*/}
                        {/*text: 'Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam.' +*/}
                            {/*' In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel. ',*/}
                        {/*heading: 'Quisque cursus, metus vitae',*/}
                        {/*path: '',*/}
                    {/*},*/}
                {/*]}*/}
            {/*/>*/}

            <CaseStudyComponentsWrapper>
                <DesktopBackground/>
                <CaseStudyComponent
                    redHeading={'Case study'}
                    heading={'Our goal is to improve communication between provider and patient'}
                    text={'“The alternative health model is a largely underappreciated industry.' +
                    ' Both patient and provider are given few tools to effectively manage treatment, ' +
                    'adjust dosages or fully understand the patient’s entire health narrative. ' +
                    'UPmed strives to fill in the gap, to bridge the information divide and provide ' +
                    'a tool that both clinician  and patient can utilize to better understand the ' +
                    'far-reaching benefits of an alternative and holistic approach to health optimization.”'}
                    author={'John Andersen, MD'}
                    date={'January 25, 2019'}
                    img={caseStudy1}
                />
            </CaseStudyComponentsWrapper>

            <ButtonWrapperWithBackground>
                <BackgroundWrapper>
                    <PrimaryButton>Load more</PrimaryButton>
                </BackgroundWrapper>
                <WhiteSpace/>
            </ButtonWrapperWithBackground>

            <Footer/>
        </>
    )
};

export default Resources;