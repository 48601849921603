import { restAxios } from '../restAxios';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';

class AdminStatisticsApi {

    @HandleAsyncErrors()
    static async getAdminStatistics() {
        const response =  await restAxios.get('private/statistics');
        return response.data;
    }
}

export default AdminStatisticsApi;
