import React, {useEffect} from 'react';
import infoWithVideoLinkProviderMobile
    from "../../../static/images/landing/provider/infoWithVideoLinkProviderMobile.png";
import infoWithVideoLinkProviderDesktop
    from "../../../static/images/landing/provider/infoWithVideoLinkProviderDesktop.png";
import InfoWithVideoLink from "../shared/InfoWithVideoLink";
import styled from "styled-components";
import {makeStyles, Typography} from "@material-ui/core";
import {colors} from "../../../theme/colors";
import LandingRedUppercaseHeading from "../../shared/LandingRedUppercaseHeading";
import LandingBigHeading from "../../shared/LandingBigHeading";
import SharedCarousel from "../shared/SharedCarousel";
import patient1carousel from '../../../static/images/landing/solutions/solutionsCarousel3.png';
import patient2carousel from '../../../static/images/landing/solutions/solutionsCarousel4.jpg';
import patient3carousel from '../../../static/images/landing/solutions/solutionsCarousel2.jpg';
import RedInfoBlock from "../main/components/RedInfoBlock";
import compositeReportingIcon from '../../../static/images/landing/provider/compositeReportingIcon.svg'
import comprehensiveInputIcon from '../../../static/images/landing/provider/comprehensiveInputIcon.svg'
import customerTrackingIcon from '../../../static/images/landing/provider/customerTrackingIcon.svg'
import reportingImgProviderPage from '../../../static/images/landing/provider/reportingImgProviderPage.png';
import convenienceIcon from '../../../static/images/landing/provider/convenienceIcon.svg';
import Footer from "../../../shared/Footer";
import {RoleOptions} from "../../../constants/roleOptions";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {mediaQuery, size} from "../../../constants/device";
import RegularText from "../shared/RegularText";
import RedInfoBlockWithLearnMore from "../main/components/RedInfoBlockWithLearnMore";
import CardWithTopImageGray, {ImgSide} from "../../../shared/card/CardWithTopImageGray";


const HeadingWrapper = styled.div`
  margin: 105px 0 21px;
  ${mediaQuery.laptop}{
    margin: 0 0 21px;
  }
`;


const createStyles = makeStyles({
    infoWithVideoLinkText: {
        fontSize: '24px',
        lineHeight: '45px',
        color: colors.white,
        display: 'block',
    },
    heading: {
        fontSize: '48px',
        lineHeight: '36px',
        color: colors.textPrimary,
        display: 'block',
        marginBottom: '36px',
        marginTop: '28px',
    }
});

const CarouselWrapper = styled.div`
  margin-bottom: 100px;
  height: 500px;
`;

const GrayBackground = styled.div`
  background-color: ${colors.gray2};
  height: 60px;
`;

const RedBackground = styled.div`
  background-color: ${colors.redMain};
  height: 60px;
`;

const TextWrapper = styled.div`
  text-align: center;
  margin: 0 20px;
  ${mediaQuery.laptop}{
    margin: 0;
  }
`;

const CarouselBlockWrapper = styled.div`
  ${mediaQuery.laptop}{
    margin: 0 5%;
  }
   ${mediaQuery.laptopL}{
    margin: 0 12%;
  }
`;

const CarouselText = styled.div`
 ${mediaQuery.laptop}{
    width: 800px;
    margin: 0 auto;
  }
`;

const RedInfoBlockWithLearnMoreWrapper = styled.div`
  ${`@media (max-width: ${size.laptop})`}{
      >div:last-child {
        padding-bottom: 60px;
      }
      >div:first-child {
        padding-top: 60px;
      }
  }
  ${mediaQuery.laptop}{
    background-color: ${colors.redMain};
     padding: 0 5%;
  }
  ${mediaQuery.laptop}{
    display: flex;
    flex-direction: row;
  }
  ${mediaQuery.laptopL}{
    padding: 0 12%;
  }
`;

const ProviderPage: React.FC = (props) => {
    const classes = createStyles(props);

    useEffect(() => {
        window.scroll({top: 0, left: 0})
    }, []);

    const isLaptop = useMediaQuery(mediaQuery.laptop);
    const isTablet = useMediaQuery(mediaQuery.tablet);

    const infoWithVideoLinkProviderImg = isLaptop ? infoWithVideoLinkProviderDesktop : infoWithVideoLinkProviderMobile;

    return (
        <>
            <InfoWithVideoLink
                role={RoleOptions.PROVIDER}
                img={infoWithVideoLinkProviderImg}
                imgSide={ImgSide.left}
            >
                <Typography variant='button' classes={{root: classes.infoWithVideoLinkText}}>
                    Simplifying patient management so you can focus on patient care
                </Typography>
            </InfoWithVideoLink>

            <CarouselBlockWrapper>
                <CarouselText>
                    <HeadingWrapper>
                        <LandingRedUppercaseHeading>The lifeblood of your practice</LandingRedUppercaseHeading>
                    </HeadingWrapper>
                    <LandingBigHeading>Your Patients</LandingBigHeading>
                    <TextWrapper>
                        <RegularText>
                            The alternative supplementation marketplace is full of unique challenges not present in a
                            traditional
                            patient care delivery model. Your patients deserve the best opportunity to monitor
                            treatment,
                            outcomes,
                            ongoing testing requirements and interaction with their provider. UPmed streamlines all of
                            these
                            in
                            a simple
                            to navigate and manage interface.
                        </RegularText>
                    </TextWrapper>
                </CarouselText>

                <CarouselWrapper>
                    <SharedCarousel
                        isTablet={isTablet}
                        textFontSize={16}
                        itemsInDesktop={3}
                        partialVisibility
                        items={[
                            {
                                text: '',
                                authorName: '',
                                img: patient2carousel,
                            },
                            {
                                text: '',
                                authorName: '',
                                img: patient3carousel,
                            },
                            {
                                text: '',
                                authorName: '',
                                img: patient1carousel,
                            }
                        ]}/>
                </CarouselWrapper>
            </CarouselBlockWrapper>

            <RedInfoBlockWithLearnMoreWrapper>
                <RedInfoBlockWithLearnMore
                    link={'/sign-up'}
                    icon={customerTrackingIcon}
                    heading={'Customized Tracking'}
                    text={'Custom inputs allow you to incorporate external supplementation that you may' +
                    ' be incorporating to give you, the patient, a better view of your total health picture.'}
                />
                <RedInfoBlockWithLearnMore
                    link={'/sign-up'}
                    icon={comprehensiveInputIcon}
                    heading={'Comprehensive Input'}
                    text={'Include your prescribed lab results as well as any external labwork your patient' +
                    ' has had performed along the way. Our goal is to show you your patients full history of treatment and results.'}
                />
                <RedInfoBlockWithLearnMore
                    link={'/sign-up'}
                    icon={compositeReportingIcon}
                    heading={'Composite Reporting'}
                    text={'Include your prescribed lab results as well as any external labwork your patient has had' +
                    ' performed along the way. Our goal is to show you your patients full history of treatment and results.'}
                />
            </RedInfoBlockWithLearnMoreWrapper>

            <CardWithTopImageGray
                text="Set the parameters for your tracking needs while allowing the addition of customized  supplementation
                to your providers prescribed treatment. Gain comprehensive reporting  options allowing for better maintenance
                and ongoing continuity of treatment. See the effects of RX adjustment, and your self - directed supplementation on your
                bloodwork, and have the ability to course correct when necessary."
                img={reportingImgProviderPage}
                imgSide={ImgSide.right}
            >
                <Typography variant={'button'} classes={{root: classes.heading}}>Reporting</Typography>
            </CardWithTopImageGray>
            {!isTablet &&
            <GrayBackground/>
            }
            <RedInfoBlock
                icon={convenienceIcon}
                heading={'Convenience'}
                text={'App based with a full web interface, we make patient communication easy, with' +
                ' notifications for both provider and patient of necessary benchmarks. IE – Bloodwork, ' +
                'RX adjustments, appointments, etc. Keep track and ensure that your messages are heard and your ' +
                'patients are informed. In other words, reduce your liability.'}
            />
            <RedBackground/>

            <Footer/>
        </>
    );
};

export default ProviderPage;