import React, { useCallback, useEffect, useMemo, useState } from 'react';
import HeaderHeadingWithLink from '../../../shared/HeaderHeadingWithLink';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers/index';
import { ProviderModel } from '../../../models/responses/provider.response';
import { Actions as ProviderActions } from '../../../store/actions/provider.actions';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import AvatarProfileBigShared from '../../shared/AvatarProfileBigShared';
import PhoneNumberInput from '../../../shared/input/PhoneNumberInput';
import TextInput from '../../../shared/input/TextInput';
import { FormFields } from './AddProviderRow';
import LoadingTransparent from '../../shared/LoadingTransparent';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { ValidationErrorsMap } from '../../../models/validation/validationErrorsMap';
import { useDebounce } from 'use-debounce';
import fillClassInstanceWithValues from '../../../shared/utils/fillClassInstanceWithValues';
import { AddProviderDto } from '../../../models/dto/addProvider.dto';
import { validateSync } from 'class-validator';
import Snackbar, { SnackbarTypes } from '../../../shared/snackbars/snackbar';
import RequestState from '../../../constants/requestState';
import SecondaryButton from '../../../shared/button/SecondaryButton';
import { mediaQuery } from '../../../constants/device';

const Wrapper = styled.div`
    padding: 36px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Form = styled.div`
    > div {
        margin-bottom: 16px !important;
    }
    align-self: stretch;
    padding: 24px 0;
`;

const ButtonGroup = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

    > button:first-child {
        margin-bottom: 16px;
    }

    ${mediaQuery.laptop} {
        flex-direction: row;

        > button:first-child {
            margin-bottom: 0;
        }
    }
`;

interface StoreModel {
    currentProvider: ProviderModel | null;
    loading: boolean;
    updateProviderRequest: RequestState;
    sendNewPasswordRequest: RequestState;
}

interface DispatchModel {
    getProviderById: typeof ProviderActions.getProviderById;
    updateProviders: typeof ProviderActions.updateProviders;
    resetStatusVariables: typeof ProviderActions.resetStatusVariables;
    getNewPasswordProvider: typeof ProviderActions.getNewPasswordProvider;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const ProviderInfo: React.FC<PropsTypes> = props => {
    const {
        loading,
        updateProviderRequest,
        currentProvider,
        getProviderById,
        updateProviders,
        resetStatusVariables,
        getNewPasswordProvider,
        sendNewPasswordRequest,
    } = props;
    const [provider, setProvider] = useState();

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    useEffect(() => {
        if (!!provider) {
            validateForm(provider);
        }
    }, [provider]);

    const validateForm = (user: any, isOnSubmit = false) => {
        const item = fillClassInstanceWithValues<AddProviderDto>(AddProviderDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                    map[err.property] = Object.entries(err.constraints)
                        .map(([, value]) => value)
                        .join(', ');
                }
            });
            setValidationErrorsMap(map);
        }
        setValidationErrorsMap(map);
        return map;
    };

    const changeForm = (formField: string, value: string | Date | null) => {
        setError(false);
        setProvider({ ...provider, [formField]: value, isBeingEdited: provider.isBeingEdited.add(formField) });
    };

    useEffect(() => {
        if (props.match.params.id) {
            getProviderById(props.match.params.id);
        }
    }, [props.match, getProviderById]);

    useEffect(() => {
        currentProvider &&
            setProvider({
                ...currentProvider,
                isBeingEdited: new Set<string>(),
            });
    }, [currentProvider]);

    const isEditable = useMemo(() => {
        return props.match.url.includes('edit');
    }, [props.match]);

    const goToEdit = useCallback(() => {
        props.history.push(`/admin/provider-edit/${props.match.params.id}`);
    }, [props.history, props.match]);

    const sendNewPassword = useCallback(() => {
        if (currentProvider) {
            getNewPasswordProvider(currentProvider.id);
        }
    }, [currentProvider, getNewPasswordProvider]);

    const isFormValid = useCallback(() => {
        let valid = true;
        const map = validateForm(provider, true);
        if (Object.keys(map).length !== 0) {
            valid = false;
        }
        return valid;
    }, [provider]);

    const saveInfo = useCallback(() => {
        if (isFormValid()) {
            updateProviders([{ ...provider}]);
        } else {
            setError(true);
        }
    }, [provider, isFormValid, updateProviders]);

    useEffect(() => {
        updateProviderRequest === RequestState.SENT_SUCCESS &&
            props.history.push(`/admin/provider/${props.match.params.id}`);
    }, [updateProviderRequest, props.history, props.match]);

    useEffect(() => {
        if (sendNewPasswordRequest === RequestState.SENT_SUCCESS) {
            setSuccess(true);
            const timer = setTimeout(() => {
                setSuccess(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [sendNewPasswordRequest]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    const isLoading = useMemo(() => {
        return (
            loading || updateProviderRequest === RequestState.SENDING || sendNewPasswordRequest === RequestState.SENDING
        );
    }, [loading, updateProviderRequest, sendNewPasswordRequest]);

    return (
        <>
            <HeaderHeadingWithLink goBackLink={'/admin/providers'}>
                {currentProvider && `Provider ${currentProvider.firstName} ${currentProvider.lastName}.`}
            </HeaderHeadingWithLink>
            {isLoading && <LoadingTransparent />}
            {provider && currentProvider && (
                <SizeControllerWrapper>
                    <Wrapper>
                        <AvatarProfileBigShared
                            isDefaultAvatar={false}
                            editMode={false}
                            avatar={currentProvider.avatarThumbnailUrl}
                        />
                        <Form>
                            <TextInput
                                placeholder={'First Name'}
                                value={provider.firstName}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.firstName, evt.target.value)
                                }
                                disabled={!isEditable}
                                error={!!validation.firstName}
                            />

                            <TextInput
                                placeholder={'Last Name'}
                                value={provider.lastName}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.lastName, evt.target.value)
                                }
                                disabled={!isEditable}
                                error={!!validation.lastName}
                            />

                            <TextInput
                                placeholder={'Email'}
                                value={provider.email}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.email, evt.target.value)
                                }
                                disabled={!isEditable}
                                error={!!validation.email}
                            />

                            <TextInput
                                placeholder={'Address'}
                                value={provider.address ? provider.address : ''}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.address, evt.target.value)
                                }
                                disabled={!isEditable}
                                error={!!validation.address}
                            />

                            <TextInput
                                placeholder={'Business Name'}
                                value={provider.businessName ? provider.businessName : ''}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    changeForm(FormFields.businessName, evt.target.value)
                                }
                                disabled={!isEditable}
                                error={!!validation.businessName}
                            />

                            <PhoneNumberInput
                                placeholder={'+ (000) 000.0000'}
                                value={provider.phone}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeForm(FormFields.phone, evt.target.value);
                                }}
                                disabled={!isEditable}
                                error={!!validation.phone}
                            />
                        </Form>
                        {isEditable ? (
                            <PrimaryButton onClick={saveInfo}>Save Info</PrimaryButton>
                        ) : (
                            <ButtonGroup>
                                <SecondaryButton onClick={sendNewPassword}>Send new password</SecondaryButton>
                                <PrimaryButton onClick={goToEdit}>Edit Info</PrimaryButton>
                            </ButtonGroup>
                        )}
                    </Wrapper>
                </SizeControllerWrapper>
            )}
            <Snackbar
                message={'Fill all the fields in the form'}
                open={error}
                variant={'error'}
                type={SnackbarTypes.ERROR}
            />

            <Snackbar
                message={'The new password has been sent to the provider'}
                open={success && !isEditable}
                variant={'success'}
                type={SnackbarTypes.SUCCESS}
            />
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        currentProvider: store.provider.currentProvider,
        updateProviderRequest: store.provider.updateProviderRequest,
        sendNewPasswordRequest: store.provider.sendNewPasswordRequest,
        loading: store.provider.loading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getProviderById: bindActionCreators(ProviderActions.getProviderById, dispatch),
        updateProviders: bindActionCreators(ProviderActions.updateProviders, dispatch),
        resetStatusVariables: bindActionCreators(ProviderActions.resetStatusVariables, dispatch),
        getNewPasswordProvider: bindActionCreators(ProviderActions.getNewPasswordProvider, dispatch),
    })
)(withRouter(ProviderInfo));
