import React from 'react';
import { DropdownInputDataTypes } from '../../constants/dropdownInputDataTypes';
import DropdownInputLabsListWrapper from './dropdownAutocompleteWrappers/DropdownInputLabsListWrapper';
import DropdownInputTherapiesListWrapper from './dropdownAutocompleteWrappers/DropdownInputTherapiesListWrapper';
import DropdownInputApplicationTypesListWrapper from './dropdownAutocompleteWrappers/DropdownInputApplicationTypesListWrapper';
import DropdownInputDrugListWrapper from './dropdownAutocompleteWrappers/DropdownInputDrugListWrapper';
import DropdownInputBloodTargetListWrapper from './dropdownAutocompleteWrappers/DropdownInputBloodTargetListWrapper';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';

interface InjectedProps {
    type: DropdownInputDataTypes;
    onChange: (entity: BaseDropdownItem | null) => void;
    rowId?: number;
    existingOptions?: any;
    onChangeCustom?: any;
    value?: BaseDropdownItem | null;
    label: string;
    error?: boolean;
    customValue?: boolean;
}

type PropsTypes = InjectedProps;

const DropdownInputEntitiesWrapper: React.FC<PropsTypes> = props => {
    return (
        <>
            {props.type === DropdownInputDataTypes.LAB && (
                <DropdownInputLabsListWrapper onChange={props.onChange} label={props.label} error={props.error} value={props.value}/>
            )}

            {props.type === DropdownInputDataTypes.THERAPY && (
                <DropdownInputTherapiesListWrapper value={props.value} onChange={props.onChange} label={props.label} />
            )}

            {props.type === DropdownInputDataTypes.DRUG && (
                <DropdownInputDrugListWrapper
                    value={props.value}
                    onChange={props.onChange}
                    label={props.label}
                    customValue
                />
            )}

            {props.type === DropdownInputDataTypes.APPLICATION_TYPE && (
                <DropdownInputApplicationTypesListWrapper
                    value={props.value}
                    onChange={props.onChange}
                    label={props.label}
                />
            )}

            {props.type === DropdownInputDataTypes.BLOOD_TARGET && (
                <DropdownInputBloodTargetListWrapper
                    rowId={props.rowId!}
                    existingOptions={props.existingOptions}
                    label={props.label}
                    onChange={props.onChangeCustom}
                />
            )}
        </>
    );
};

export default DropdownInputEntitiesWrapper;
