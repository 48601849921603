import React from 'react';
import {createStyles, withStyles, WithStyles} from "@material-ui/core";
import {colors} from '../../theme/colors';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {connect} from "react-redux";
import {UserModel} from "../../models/responses/user.response";
import * as fromRoot from "../../store/reducers";
import {Actions as AuthActions} from "../../store/actions/auth.actions";
import {bindActionCreators, Dispatch} from "redux";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {mediaQuery} from "../../constants/device";
import SidebarMenuMobile from "./SidebarMenuMobile";
import SidebarMenuDesktop from "./SidebarMenuDesktop";

const styles = createStyles({
    phoneNumber: {
        fontSize: '12px',
        marginBottom: '35px'
    },
    menuItem: {
        fontSize: '12px',
        textTransform: 'uppercase',
        color: colors.textPrimary,
        marginBottom: '40px',
        display: 'block',
    }
});

interface StoreModel {
    user?: UserModel | null;
}

interface DispatchModel {
    removeCurrentUser: typeof AuthActions.removeCurrentUser,
    resetStatusVariables: typeof AuthActions.resetStatusVariables,
}

interface InjectedProps {
    open: boolean;
    closeSidebar: () => void;
}

type PropsTypes =
    & RouteComponentProps<any>
    & WithStyles<typeof styles>
    & StoreModel
    & DispatchModel
    & InjectedProps;

const SidebarMenu: React.FC<PropsTypes> = (props) => {

    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const goToPage = (path: string) => {
        props.history.push(path);
        props.closeSidebar();
    };

    return isLaptop ?
        <SidebarMenuDesktop
            goToPage={goToPage}
            user={props.user}
            removeCurrentUser={props.removeCurrentUser}
            resetStatusVariables={props.resetStatusVariables}
        />
        : <SidebarMenuMobile
            user={props.user}
            goToPage={goToPage}
            removeCurrentUser={props.removeCurrentUser}
            resetStatusVariables={props.resetStatusVariables}
            closeSidebar={props.closeSidebar}
            open={props.open}
        />
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        removeCurrentUser: bindActionCreators(AuthActions.removeCurrentUser, dispatch),
        resetStatusVariables: bindActionCreators(AuthActions.resetStatusVariables, dispatch),
    }),
)(withStyles(styles)(withRouter(SidebarMenu)));