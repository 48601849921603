import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { NotificationActions as NotificationsActions } from '../../store/actions/notifications.actions';
import { DefaultPageSize, shouldFetchData } from '../../constants/dataFetching/allEntitiesByPages';
import { NotificationModel } from '../../models/responses/notification.responce';
import NotificationList from './NotificationList';

interface StoreModel {
    selectedPatientId: number | null;
    filteredNotifications: NotificationModel[];
    loading: boolean;
    page: number;
    total: null | number;
}

interface DispatchModel {
    getNotificationsByPatient: typeof NotificationsActions.getNotificationsByPatient;
    markOneAsRead: typeof NotificationsActions.markOneAsRead;
    increasePageNumber: typeof NotificationsActions.increasePageNumber;
}

type PropsTypes = StoreModel & DispatchModel;

const SelectedPatientNotifications: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);
    const {
        getNotificationsByPatient,
        selectedPatientId,
        page,
        loading,
        filteredNotifications,
        markOneAsRead,
        total,
        increasePageNumber,
    } = props;

    const loadData = useCallback(
        (page: number, size: number, sort: string, overrideRecords: boolean) => {
            if (selectedPatientId) {
                getNotificationsByPatient({
                    patientId: selectedPatientId,
                    data: { page, size, sort, overrideRecords },
                });
            }
        },
        [getNotificationsByPatient, selectedPatientId]
    );

    useEffect(() => {
        loadData(0, DefaultPageSize, '', true);
        setInitialLoaded(true);
    }, [loadData]);

    useEffect(() => {
        if (shouldFetchData(loading, page, filteredNotifications.length, initialLoaded, DefaultPageSize)) {
            loadData(page, DefaultPageSize, '', false);
        }
    }, [page, loading, filteredNotifications, initialLoaded, loadData]);


    return (
        <NotificationList
            markOneAsRead={markOneAsRead}
            page={page}
            loading={loading}
            allNotifications={filteredNotifications}
            total={total}
            increasePageNumber={increasePageNumber}
        />
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        selectedPatientId: store.notifications.selectedPatientId,
        loading: store.notifications.loading,
        page: store.notifications.page,
        filteredNotifications: store.notifications.filteredNotifications,
        total: store.notifications.total,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getNotificationsByPatient: bindActionCreators(NotificationsActions.getNotificationsByPatient, dispatch),
        markOneAsRead: bindActionCreators(NotificationsActions.markOneAsRead, dispatch),
        increasePageNumber: bindActionCreators(NotificationsActions.increasePageNumber, dispatch),
    })
)(SelectedPatientNotifications);
