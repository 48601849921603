export enum UnitWeightManagement {
    FEET = 'ft',
    INCH = 'inch',
    CM = 'cm',

    KG = 'kg',
    LBS = 'lbs',

    PERCENT = '%',
    YEARS = 'years',
}