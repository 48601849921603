import React, { useCallback } from 'react';
import { WeightManagementRoutes } from '../enums/routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BlockingModal } from '../ui/blockingModal';

const PROCEED_LINK = `${WeightManagementRoutes.BASE_ROUTE}${WeightManagementRoutes.CREATE}`;

export interface IProps extends RouteComponentProps {}

const NoInfoAvailable: React.FC<IProps> = ({ history }) => {
    const proceedToUnitSetup = useCallback(() => {
        history.push(PROCEED_LINK);
    }, [history]);

    return (
        <BlockingModal
            btnClickAction={proceedToUnitSetup}
            modalName="WEIGHT"
            messageTitle="You don't have info"
            messageSubtitle="Add info about your weight"
            btnText="Add info about the weight"
        />
    );
};

export default withRouter(NoInfoAvailable);
