import React, { ReactNode } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../../../shared/button/PrimaryButton';

const createStyles = makeStyles({
    headerIcon: {
        margin: '0 8px -8px 0',
    },
    wrapper: {
        display: 'flex',
        width: '50%',
        marginLeft: '25%',
        height: '100%',
        marginTop: '20%',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
});

export interface IProps {
    modalName: string;
    messageTitle: string;
    messageSubtitle: ReactNode;
    btnClickAction: () => void;
    btnText: string;
}

export function BlockingModal ({ modalName, messageTitle, btnClickAction, btnText, messageSubtitle }: IProps) {
    const classes = createStyles();

    return (
        <>
            <div className={classes.wrapper}>
                <Typography variant="h2">
                    {messageTitle}
                </Typography>
                <Typography style={{marginTop: 20}} variant={'body2'}>
                    {messageSubtitle}
                </Typography>
                <PrimaryButton style={{marginTop: 20}} onClick={btnClickAction}>
                    {btnText}
                </PrimaryButton>
            </div>
        </>
    )
}
