import React from 'react';
import { Dialog, makeStyles, Typography } from '@material-ui/core';
import theme from '../../../theme/theme';
import { colors } from '../../../theme/colors';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import styled from 'styled-components';
import CancelLink from '../../../shared/link/CancelLink';

const createStyles = makeStyles(themeMaterial => ({
    dialogPaper: {
        height: '36%',
        boxShadow: theme.boxShadowCard,
        padding: '25px',
        borderRadius: '30px',
        backgroundColor: colors.white,
        margin: '0',
        width: '76%',
        top: '3vh',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-evenly',
        [themeMaterial.breakpoints.down('sm')]: {
            height: '48%',
        },
    },
    dialogText: {
        lineHeight: '28px',
        fontSize: '18px',
        whiteSpace: 'pre-line',
    },
}));

interface InjectedProps {
    close: () => void;
    open: boolean;
    text: string;
    yesAction: any;
}

type PropsTypes = InjectedProps;

const ButtonBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ConfirmationDialog: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    const { close, open, text, yesAction } = props;

    return (
        <>
            <Dialog open={open} classes={{ paper: classes.dialogPaper }} onClose={close}>
                <Typography variant="button" classes={{ root: classes.dialogText }}>
                    {text}
                </Typography>
                <ButtonBlock>
                    <PrimaryButton size={'large'} onClick={yesAction}>
                        Yes
                    </PrimaryButton>
                    <CancelLink onClick={close}/>
                </ButtonBlock>
            </Dialog>
        </>
    );
};

export default ConfirmationDialog;
