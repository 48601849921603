import React, { useState } from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { colors } from '../../theme/colors';
import errorAdornment from '../../static/icons/errorAdornment.svg';
import successAdornment from '../../static/icons/successAdornment.svg';
import passNotVisible from '../../static/icons/passVisible.svg';
import passVisible from '../../static/icons/passNotVisible.svg';

const useStyles = makeStyles({
    root: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            padding: '11px 0 11px 11px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
    },
    underline: {
        '&:after': {
            borderBottom: `1px solid transparent !important`,
        },
        '&:before': {
            borderBottom: `1px solid transparent !important`,
        },
        '&:hover:not($error):before': {
            borderBottom: `1px solid transparent !important`,
        },
    },
    error: {
        '&:after': {
            borderBottom: `1px solid ${colors.redLight}!important`,
        },
    },
    endAdornment: {
        marginRight: '10px',
    },
    passwordIcon: {
        cursor: 'pointer',
    },
    disabled: {
        color: `${colors.textSecondary} !important`,
    },
    label: {
        color: colors.textSecondary,
        fontSize: '12px',
        marginLeft: '12px',
    },
});

interface InjectedProps {
    placeholder: string;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    onBlur?: () => void;
    onFocus?: () => void;
    error?: boolean | null;
    success?: boolean | null;
    password?: boolean;
    disabled?: boolean;
    inputProps?: any;
    onKeyDown?: (evt: any) => void;
    number?: boolean;
    noUnderline?: boolean;
}

type PropsTypes = InjectedProps;

const TextInputBare: React.FC<PropsTypes> = props => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const classes = useStyles(props);

    return (
        <TextField
            placeholder={props.placeholder}
            InputProps={{
                classes: {
                    root: classes.root,
                    underline: classes.underline,
                    error: classes.error,
                    disabled: classes.disabled,
                    // label: classes.label,
                },
                endAdornment:
                    props.error && !props.password ? (
                        <InputAdornment position="end" classes={{ root: classes.endAdornment }}>
                            <img src={errorAdornment} alt="" />
                        </InputAdornment>
                    ) : props.success && !props.password ? (
                        <InputAdornment position="end" classes={{ root: classes.endAdornment }}>
                            <img src={successAdornment} alt="" />
                        </InputAdornment>
                    ) : props.password ? (
                        <InputAdornment position="end" classes={{ root: classes.endAdornment }}>
                            <img
                                src={passwordVisible ? passVisible : passNotVisible}
                                alt=""
                                className={classes.passwordIcon}
                                onClick={() => setPasswordVisible(!passwordVisible)}
                            />
                        </InputAdornment>
                    ) : null,
                ...(props.inputProps ? props.inputProps : {}),
            }}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            value={props.value}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            error={!!props.error}
            type={!props.password ? (props.number ? 'number' : 'text') : passwordVisible ? 'text' : 'password'}
            disabled={props.disabled}
        />
    );
};

export default TextInputBare;
