import { ThunkAction } from 'redux-thunk';
import { RootStateModel } from '../reducers';
import { Action } from '@martin_hotell/rex-tils/types/redux/types';
import WeightManagementApi from '../../api/weightManagement';
import { CreateWeightRecord } from '../../models/dto/createWeightRecord';
import { WeightManagementLoadingState } from '../reducers/weight';
import { SnackbarActionNames } from './snackbar.actions';
import { SnackbarTypes } from '../../shared/snackbars/snackbar';

export enum WeightManagementActions {
    FETCH_ALL_WEIGHT_RECORDS = 'WEIGHT_MANAGEMENT/FETCH_ALL_WEIGHT_RECORDS',
    FETCH_WEIGHT_RECORD_BY_ID = 'WEIGHT_MANAGEMENT/FETCH_WEIGHT_RECORD_BY_ID',
    CREATE_WEIGHT_RECORD = 'WEIGHT_MANAGEMENT/CREATE_WEIGHT_RECORD',
    EDIT_WEIGHT_RECORD = 'WEIGHT_MANAGEMENT/EDIT_WEIGHT_RECORD',
    DELETE_WEIGHT_RECORD = 'WEIGHT_MANAGEMENT/DELETE_WEIGHT_RECORD',
    CHANGE_LOADING_STATE = 'WEIGHT_MANAGEMENT/CHANGE_LOADING_STATE'
}


export const fetchWeightRecordById = (
    id: number
): ThunkAction<void, RootStateModel, unknown, Action<string>> => async dispatch => {
    const weightRecord = await WeightManagementApi.fetchById(id);
    dispatch({
        type: WeightManagementActions.FETCH_WEIGHT_RECORD_BY_ID,
        data: weightRecord
    });
}

export const fetchAllWeightRecords = (): ThunkAction<void, RootStateModel, unknown, Action<string>> => async dispatch => {
    const weightRecords = await WeightManagementApi.fetchAll();
    dispatch({
        type: WeightManagementActions.FETCH_ALL_WEIGHT_RECORDS,
        data: weightRecords
    });
}

export const createWeightRecord = (weightRecord: CreateWeightRecord): ThunkAction<void, RootStateModel, unknown, Action<string>> => async (dispatch, getState) => {
    const { counter } = getState().snackbars;
    const newRecord = await WeightManagementApi.createWeightRecord(weightRecord);
    dispatch({
        type: WeightManagementActions.CREATE_WEIGHT_RECORD,
        data: newRecord,
    });
    dispatch({
        type: WeightManagementActions.CHANGE_LOADING_STATE,
        data: WeightManagementLoadingState.SUCCESS
    })
    dispatch({
        type: SnackbarActionNames.SNACKBAR_ADD,
        payload: {
            title: 'This record was successfully created',
            text: '',
            type: SnackbarTypes.SUCCESS,
            id: counter,
        }
    });
    setTimeout(() => {
        dispatch({
            type: SnackbarActionNames.SNACKBAR_REMOVE,
            payload: counter,
        })
    }, 5000);
}

export const changeWeightManagementLoadingState = (status: WeightManagementLoadingState): ThunkAction<void, RootStateModel, unknown, Action<string>> => async dispatch => {
    dispatch({
        type: WeightManagementActions.CHANGE_LOADING_STATE,
        data: status
    })
}

export const deleteWeightRecord = (id: number): ThunkAction<void, RootStateModel, unknown, Action<string>> => async (dispatch, getState) => {
    const { counter } = getState().snackbars;
    await WeightManagementApi.deleteWeightRecord(id);
    dispatch({
        type: SnackbarActionNames.SNACKBAR_ADD,
        payload: {
            title: 'This record was successfully deleted',
            text: '',
            type: SnackbarTypes.SUCCESS,
            id: counter,
        }
    });
    dispatch({
        type: WeightManagementActions.DELETE_WEIGHT_RECORD,
        data: id,
    });
    setTimeout(() => {
        dispatch({
            type: SnackbarActionNames.SNACKBAR_REMOVE,
            payload: counter,
        })
    }, 5000);
}

export const editWeightRecord = (weightRecord: CreateWeightRecord & {id: number}): ThunkAction<void, RootStateModel, unknown, Action<string>> => async (dispatch, getState) => {
    const { counter  } = getState().snackbars;
    try {
        dispatch({
            type: WeightManagementActions.CHANGE_LOADING_STATE,
            data: WeightManagementLoadingState.LOADING
        });
        const newRecord = await WeightManagementApi.editWeightRecord(weightRecord);
        dispatch({
            type: WeightManagementActions.EDIT_WEIGHT_RECORD,
            data: newRecord
        });
        dispatch({
            type: SnackbarActionNames.SNACKBAR_ADD,
            payload: {
                title: 'Successfully Updated',
                text: '',
                type: SnackbarTypes.SUCCESS,
                id: counter,
            }
        });
        dispatch({
            type: WeightManagementActions.CHANGE_LOADING_STATE,
            data: WeightManagementLoadingState.SUCCESS,
        });
        setTimeout(() => {
            dispatch({
                type: SnackbarActionNames.SNACKBAR_REMOVE,
                payload: counter,
            })
        }, 5000);
    } catch (err) {
        dispatch({
            type: WeightManagementActions.CHANGE_LOADING_STATE,
            data: WeightManagementLoadingState.ERROR
        })
    }
}
