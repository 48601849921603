import {AdminStatisticsModel} from "../../../models/responses/adminStatistics";
import * as adminStatisticsActions from "../../actions/adminStatistics";

export class StateModel {
    statistics: AdminStatisticsModel | null;
    loadingStatistics: boolean = false;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: adminStatisticsActions.Actions) {
    switch (action.type) {
        case adminStatisticsActions.ActionNames.GET_ADMIN_STATISTICS: {
            return {
                ...state,
                loadingStatistics: true,
            }
        }
        case adminStatisticsActions.ActionNames.GET_ADMIN_STATISTICS_SUCCESS: {
            return {
                ...state,
                loadingStatistics: false,
                statistics: action.payload,
            }
        }
        case adminStatisticsActions.ActionNames.GET_ADMIN_STATISTICS_FAILED: {
            return {
                ...state,
                loadingStatistics: false,
            }
        }
        default:
            return state;
    }
}