import React, {useEffect, useState} from 'react';
import {ClickAwayListener, List, ListItem, makeStyles, Typography} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import {colors} from "../../theme/colors";
import SizeControllerWrapper from "../SizeControllerWrapper";
import dropdownExpandDown from "../../static/icons/expandDropdownGrayDown.svg";
import dropdownExpandUp from "../../static/icons/expandDropdownGrayUp.svg";
import styled from "styled-components";
import {BloodtargetReponce} from "../../models/responses/bloodtarget.reponce";
import TextInputBare from "./TextInputBare";
import {Row} from "../../pages/bloodwork/AddBloodwork";

const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
`;


const useStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        }
    },
    noData: {
        fontSize: '14px',
        color: colors.textSecondary,
        marginTop: '20px',
        marginLeft: '12px',
        textAlign: 'left',
    },
    listItem: {},
    labName: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    selected: {
        backgroundColor: `${colors.gray2} !important`,
        color: colors.redLight,
    },
    menu: {
        position: 'absolute',
        width: '100%',
        right: 0,
        padding: 0,
        marginTop: '-2px',
        borderRadius: 0,
        boxShadow: '0px 10px 30px rgba(102, 107, 128, 0.5)',
        zIndex: 2,
        overflow: 'scroll',
        background: colors.white,
    },
    menuNoData: {
        height: '65px',
        position: 'absolute',
        width: '100%',
        marginTop: '-2px',
        borderRadius: 0,
        boxShadow: '0px 10px 30px rgba(102, 107, 128, 0.5)',
        zIndex: 2,
        background: colors.white,
    },
    inputIcon: {
        position: 'absolute',
        right: '16px',
        top: '20px'
    }
});

interface InjectedProps {
    onChange: (value: BloodtargetReponce | string | null) => void;
    rowId: number;
    existingOptions: Row[],
    label: string;
    openIcon?: boolean;
    loading: boolean,
    allEntities: BloodtargetReponce[],
    totalEntities: number | null,
    searchQuery: string,
    increasePageNumber: any,
    setFilterQuery: any,
}

type PropsTypes =
    & InjectedProps;

const DropdownInputListBloodTarget: React.FC<PropsTypes> = (props) => {

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [labName, setLabName] = useState('');

    // const [bloodTargets, setBloodTargets] = useState<BloodtargetReponce[]>([]);
    //
    // useEffect(() => {
    //     setBloodTargets(getBloodTargets());
    // }, [props.allEntities]);
    //
    // const getBloodTargets = () => {
    //     const otherRows = props.existingOptions.filter(row => row.id !== props.rowId);
    //     let bloodTargets = props.allEntities;
    //     otherRows.forEach(row => {
    //         const currentTarget = bloodTargets.filter((target) => target.target === row.target)[0];
    //         const index = bloodTargets.indexOf(currentTarget);
    //         if (index >= 0) {
    //             bloodTargets = [...bloodTargets.slice(0, index), ...bloodTargets.slice(index + 1)]
    //         }
    //     });
    //
    //     return bloodTargets;
    // };

    useEffect(() => {
        if (props.allEntities.length > 0) {
            setSelectedIndex(0);
        }
    }, [props.allEntities.length]);

    const closeMenu = () => {
        setOpen(false);
    };

    const getMoreLabs = () => {
        props.increasePageNumber();
    };

    const classes = useStyles(props);

    const getScrollableListHeight = () => {
        const listItemHeight = 40;
        return props.allEntities.length <= 6 ? props.allEntities.length * listItemHeight : 240;
    };

    const changeLabName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setOpen(true);
        props.setFilterQuery(evt.target.value);
        setLabName(evt.target.value);
        const target = props.allEntities.filter(target => target.target.toLowerCase() === evt.target.value.toLowerCase().trim())[0];
        if(target){
            props.onChange(target);
            setLabName(target.target);
        }else{
            props.onChange(null);
        }

        if(evt.target.value.trim().length === 0){
            props.onChange(null);
        }
    };

    const handleEnter = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        //enter
        if (evt.keyCode === 13) {
            if (props.allEntities.length > 0) {
                const entity = props.allEntities[selectedIndex];
                setLabName(entity.target);
                props.onChange(entity);
                closeMenu();
            }
        }
        //key up
        if (evt.keyCode === 38) {
            if (selectedIndex - 1 >= 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        }

        //key down
        if (evt.keyCode === 40) {
            if (selectedIndex + 1 < props.allEntities.length) {
                setSelectedIndex(selectedIndex + 1);
            }
        }
    };

    return (
        <DropdownWrapper>
            <ClickAwayListener onClickAway={closeMenu}>
                <div>
                    <InputWrapper>
                        <TextInputBare
                            placeholder={props.label}
                            value={labName}
                            onChange={changeLabName}
                            onFocus={() => setOpen(true)}
                            onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => handleEnter(evt)}
                        />
                        {props.openIcon &&
                        <img src={open ? dropdownExpandDown : dropdownExpandUp} alt="" className={classes.inputIcon}/>
                        }
                    </InputWrapper>

                    {open &&
                    <div>
                        {!props.loading && props.allEntities.length === 0 &&
                        <List classes={{root: classes.menuNoData}}>
                            <SizeControllerWrapper>
                                <Typography variant='body1' classes={{root: classes.noData}}>No options.</Typography>
                            </SizeControllerWrapper>
                        </List>
                        }

                        {props.allEntities.length > 0 &&
                        <List classes={{root: classes.menu}}>
                            <InfiniteScroll
                                dataLength={props.allEntities.length}
                                next={getMoreLabs}
                                height={getScrollableListHeight()}
                                hasMore={props.totalEntities ? props.allEntities.length < props.totalEntities : false}
                                loader={<>Loading</>}
                                className={classes.scrollContainer}
                            >
                                {props.allEntities.map((entity, index) => (
                                    <ListItem key={index}
                                              classes={{
                                                  root: classes.listItem,
                                                  selected: classes.selected,
                                              }}
                                              onClick={() => {
                                                  setSelectedIndex(index);
                                                  props.onChange(entity);
                                                  setLabName(entity.target);
                                                  closeMenu();
                                              }}
                                              selected={index === selectedIndex}
                                    >
                                        <Typography variant='body1'
                                                    classes={{root: classes.labName}}>{entity.target}</Typography>
                                    </ListItem>
                                ))}
                            </InfiniteScroll>
                        </List>
                        }
                    </div>
                    }
                </div>
            </ClickAwayListener>
        </DropdownWrapper>
    )
};


export default DropdownInputListBloodTarget;