import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 22%;
`;

const createStyles = makeStyles({
    text: {
        fontSize: '24px',
        lineHeight: '36px',
        marginBottom: '28px',
        textAlign: 'center',
    },
});

interface InjectedProps {
    onClick: () => void
}

const NoCard: React.FC<InjectedProps> = props => {
    const classes = createStyles(props);
    const {onClick} = props;

    return (
        <Wrapper>
            <Typography variant={'h2'} classes={{ root: classes.text }}>
                Link cards to your profile to reuse them for payments
            </Typography>
            <PrimaryButton onClick={onClick}>Add Card</PrimaryButton>
        </Wrapper>
    );
};

export default NoCard;
