import { v } from 'overshom-valid';
import { AnyValidator } from '../../shared/utils/validation';

const BloodtargetReponceValid = v.Object({
    bloodTargetPanelType: v.String(),
    bloodTargetPanelTypeId: v.Number(),
    femaleMaxRange: v.Number().optional(),
    femaleMinRange: v.Number().optional(),
    id: v.Number(),
    label: v.String(),
    target: v.String(),
    maleMaxRange: v.Number().optional(),
    maleMinRange: v.Number().optional(),
    unit: v.String(),
});

const PanelResponseValid = v.Object({
    type: v.String(),
    targetList: v.Array(BloodtargetReponceValid),
    change: new AnyValidator(),
    currentResult: new AnyValidator(),
    percentageChange: new AnyValidator(),
    previousResult: new AnyValidator(),
});

const DrugModelValid = v.Object({
    id: v.Number(),
    name: v.String(),
});

const PrescriptionComponentModelValid = v.Object({
    dosage: v.Number(),
    dosageUnit: v.String(),
    drugApplicationType: DrugModelValid,
    drug: DrugModelValid,
    endDate: v.String().optional(),
    frequencyType: v.String(),
    frequencyValue: v.Number(),
    potency: v.Number(),
    potencyUnit: v.String(),
    quantity: v.Number(),
    quantityUnit: v.String(),
    startDate: v.String(),
    therapyList: v.Array(DrugModelValid),
    isBeingEdited: v.Boolean().optional(),
    id: v.Number(),
});

export const GetAnalyticsPatientValid = v.class({
    bloodWorkReports: v.Array(
        v.Object({
            createdDate: v.String(),
            fileUrl: v.String().optional(),
            labId: v.Number(),
            labWorkId: v.Number(),
            panels: v.Array(PanelResponseValid),
            patientId: v.Number(),
            previousReportDate: v.String().optional(),
            reportDate: v.String(),
        })
    ),
    prescriptions: v.Array(
        v.Object({
            id: v.Number(),
            name: v.String(),
            createdBy: v.Number(),
            pharmacyId: v.Number(),
            startDate: v.String(),
            endDate: v.String().optional(),
            prescriptionComponentList: v.Array(PrescriptionComponentModelValid),
            pharmacy: DrugModelValid,
        })
    ),
});
