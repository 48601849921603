import React from 'react';
import {
    Button, Divider, Fade, List,
    ListItem, makeStyles,
    Typography,
} from "@material-ui/core";
import {colors} from "../../theme/colors";
import dropdownExpandDown from '../../static/icons/expandDropdownGrayDown.svg';
import dropdownExpandUp from '../../static/icons/expandDropdownGrayUp.svg';
import styled from 'styled-components';
import theme from '../../theme/theme';

const createStyles = makeStyles(matTheme => ({
    button: {
        backgroundColor: 'transparent',
        fontSize: '12px',
        textTransform: 'uppercase',
        minWidth: '110px',
        padding: '8px 0 !important',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    buttonHorizontal: {
        backgroundColor: 'transparent',
        textTransform: 'uppercase',
        minWidth: '110px',
        height: '48px',
        width: '100%',
        padding: '0 28px !important',
        borderRadius: 0,
        [matTheme.breakpoints.up('lg')]: {
            '&:hover': {
                backgroundColor: theme.defaultListItemHover,
            },
        },
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
    },
    labelHorizontal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '14px',
    },
    dropdownExpandIcon: {
        marginTop: '4px'
    },
    menu: {
        borderRadius: 0,
        padding: 0,
    },
    listItem: {
        display: 'flex',
        justifyContent: 'center',
        color: colors.textSecondary,
        fontSize: '12px',
        padding: '12px 10px 8px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: colors.gray2
        },
    },
    listItemHorizontal: {
        display: 'flex',
        color: colors.textSecondary,
        fontSize: '14px',
        padding: '12px 10px 8px 100px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: colors.gray2
        },
        '&:last-child': {
            marginBottom: '8px',
        }
    },
    selected: {
        backgroundColor: `${colors.gray2} !important`,
        color: colors.redLight,
    },
    optionText: {
        fontSize: '12px',
        textTransform: 'uppercase',
    },
    optionTextHorizontal: {
        fontSize: '14px',
        textTransform: 'uppercase',
    },
    divider: {
        background: colors.gray1,
        height: '2px',
        marginLeft: '20px',
        width: '200px',
    },
    img: {
        width: '24px',
    },
}));

const DropdownWrapper = styled.div<any>`
  position: relative;
  width: ${props => props.icon ? '100%' : 'fit-content'};
`;

const ImgTextWrapper = styled.span`
    display: flex;
    align-items: center;
    > img {
      margin-right: 28px;
    }
`;

export interface MenuNavOption {
    text: string,
    link: string
}

interface InjectedProps {
    buttonText: string,
    options: MenuNavOption[],
    selectedIndex?: number,
    icon?: string;
    goToPage: (path: string) => void;
}

type PropsTypes =
    & InjectedProps;

const DropdownMenuMobile: React.FC<PropsTypes> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(props.selectedIndex ? props.selectedIndex : null);

    const toggleMenu = () => {
        setOpen(!open)
    };

    const classes = createStyles(props);
    return (

        <DropdownWrapper icon={props.icon}>
            <Button classes={{
                root: props.icon ? classes.buttonHorizontal : classes.button,
                label: props.icon ? classes.labelHorizontal : classes.label
            }}
                    style={{color: open ? colors.redMain : colors.textPrimary}} onClick={toggleMenu}
                    disableTouchRipple>

                {props.icon ?
                    <ImgTextWrapper>
                        <img src={props.icon} alt="" className={classes.img}/>
                        {props.buttonText}
                    </ImgTextWrapper>
                    :
                    <>
                        {props.buttonText}
                    </>
                }

                {open ?
                    <img src={dropdownExpandUp} className={props.icon ? '' : classes.dropdownExpandIcon} alt=''/> :
                    <img src={dropdownExpandDown} className={props.icon ? '' : classes.dropdownExpandIcon} alt=''/>
                }
            </Button>
            {open ? (
                <Fade in={open} timeout={{enter: 250, exit: 250}}>
                    <>
                        <List classes={{root: classes.menu}}>
                            {props.options.map((option, index) => (
                                <ListItem button
                                          key={index}
                                          classes={{
                                              root: props.icon ? classes.listItemHorizontal : classes.listItem,
                                              selected: classes.selected
                                          }}
                                          selected={index === selectedIndex}
                                          onClick={() => {
                                              props.goToPage(option.link);
                                              setSelectedIndex(index)
                                          }}
                                >
                                    <Typography variant='button'
                                                classes={{root: props.icon ? classes.optionTextHorizontal : classes.optionText}}>{option.text}</Typography>
                                </ListItem>
                            ))}
                        </List>
                        {props.icon &&
                        <Divider classes={{root: classes.divider}}/>}
                    </>
                </Fade>
            ) : null}
        </DropdownWrapper>
    )
};

export default DropdownMenuMobile;