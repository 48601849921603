import React, {useEffect} from 'react';
import SizeControllerWrapper from "../../../shared/SizeControllerWrapper";
import styled from "styled-components";
import {createStyles, Typography, withStyles, WithStyles} from "@material-ui/core";
import {colors} from "../../../theme/colors";
import PrimaryButton from "../../../shared/button/PrimaryButton";
import emailVerifiedBackground from '../../../static/images/landing/main/emailVerifiedBackground.svg';
import CustomLink from "../../../shared/link/CustomLink";
import {Actions as AuthActions} from "../../../store/actions/auth.actions";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";

const styles = createStyles({
    heading: {
        fontSize: '24px',
    },
    text: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    linkSendAgain: {
        textDecorationColor: colors.textPrimary,
        fontSize: '20px'
    },
    backLink: {
        color: colors.redMain,
    }
});

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 7% 10% 26%;
  align-items: end;
  margin: 0 auto;
  text-align: center;
`;

const BackgroundWrapper = styled.div`
  height: calc(100vh - 227px);
  padding: 80px 0 57px;
  background-image: url(${emailVerifiedBackground});
  background-repeat: no-repeat;
  background-position: right 0 bottom 0;
`;

const ButtonWrapper = styled.div`
    width: fit-content;
    margin: 0 auto;
`;

type DispatchModel = {
    resetStatusVariables: typeof AuthActions.resetStatusVariables
}

type PropsTypes =
    &DispatchModel
    & WithStyles<typeof styles>;

const EmailVerified: React.FC<PropsTypes> = (props) => {

    const {resetStatusVariables} = props;

    useEffect(() => {
        resetStatusVariables();
    }, [resetStatusVariables]);

    const {classes} = props;
    return (
        <>
            <BackgroundWrapper>
                <SizeControllerWrapper>
                    <Wrapper>
                        <Typography variant='h2' classes={{root: classes.heading}}>Your Email is verified.</Typography>

                        <Typography variant='body1' classes={{root: classes.text}}>To continue please Sign
                            in</Typography>

                        <ButtonWrapper>
                            <CustomLink to={'/sign-in'}>
                                <PrimaryButton>
                                    Sign In
                                </PrimaryButton>
                            </CustomLink>
                        </ButtonWrapper>

                    </Wrapper>
                </SizeControllerWrapper>
            </BackgroundWrapper>

        </>
    )
};

export default connect(
    null,
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(AuthActions.resetStatusVariables, dispatch)
    })
)(withStyles(styles)(EmailVerified));