import React, { useEffect, useState } from 'react';
import {
    Button,
    ClickAwayListener,
    createStyles,
    Fade,
    List,
    ListItem,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { colors } from '../../theme/colors';
import dropdownExpandUp from '../../static/icons/expandDropdownGrayUp.svg';
import dropdownExpandDown from '../../static/icons/expandDropdownGrayDown.svg';
import styled from 'styled-components';

const styles = createStyles({
    inputButton: {
        height: '44px',
        width: '100%',
        padding: '15px 12px !important',
        color: colors.textSecondary,
        borderBottom: `1px solid ${colors.grayUnderline}`,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    inputButtonSuccess: {
        height: '44px',
        width: '100%',
        padding: '15px 12px !important',
        color: colors.textSecondary,
        borderBottom: `2px solid ${colors.success}`,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    inputButtonError: {
        height: '44px',
        width: '100%',
        padding: '15px 12px !important',
        color: colors.textSecondary,
        borderBottom: `2px solid ${colors.redLight}`,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textTransform: 'capitalize',
    },
    button: {
        backgroundColor: colors.redMain,
        color: colors.white,
        fontSize: '12px',
        padding: '20px 47px 20px 29px',
        minWidth: '280px',
        textTransform: 'uppercase',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: colors.redMain,
        },
    },
    dropdownExpandIcon: {
        marginLeft: '9px',
    },
    menu: {
        position: 'absolute',
        width: '75%',
        right: 0,
        padding: 0,
        marginTop: '-2px',
        borderRadius: 0,
        boxShadow: '0px 10px 30px rgba(102, 107, 128, 0.5)',
        zIndex: 2,
    },
    menuWide: {
        position: 'absolute',
        width: '100%',
        backgroundColor: colors.white,
        right: 0,
        padding: 0,
        marginTop: '-2px',
        borderRadius: 0,
        boxShadow: '0px 10px 30px rgba(102, 107, 128, 0.5)',
        zIndex: 2,
    },
    listItem: {
        color: colors.textSecondary,
        fontSize: '12px',
        padding: '12px 10px 8px',
        backgroundColor: colors.white,
        '&:hover': {
            backgroundColor: colors.gray2,
        },
    },
    selected: {
        backgroundColor: `${colors.gray2} !important`,
        color: colors.redLight,
    },
    listItemText: {
        fontSize: '12px',
        textTransform: 'capitalize',
    },
});

const DropdownWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const PlaceholderWrapper = styled.span`
    color: ${colors.textSecondary};
`;

const ValueWrapper = styled.span<any>`
    color: ${props => (props.disabled ? colors.textSecondary : colors.textPrimary)};
`;

interface InjectedProps {
    placeholder: string;
    onChange: (value: string) => void;
    onKeyDown?: (evt: any) => void;
    value: string;
    options: string[];
    selectedIndex?: number | null;
    error?: boolean | null;
    success?: boolean | null;
    disabled?: boolean;
    reset?: boolean;
}

type PropsTypes = InjectedProps & WithStyles<typeof styles>;

const DropdownInput: React.FC<PropsTypes> = props => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex ? props.selectedIndex : 0);

    const closeMenu = () => {
        setOpen(false);
    };

    const toggleMenu = () => {
        if (!props.disabled) {
            setOpen(!open);
        }
    };
    useEffect(() => {
        props.reset && setSelectedIndex(0);
    }, [props.reset]);

    const handleArrowKeys = (evt: any) => {
        if (evt.keyCode === 13) {
            props.onChange(props.options[selectedIndex]);
            closeMenu();
        }
        //key up
        if (evt.keyCode === 38) {
            if (selectedIndex - 1 >= 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        }

        //key down
        if (evt.keyCode === 40) {
            if (selectedIndex + 1 < props.options.length) {
                setSelectedIndex(selectedIndex + 1);
            }
        }
    };

    const { classes } = props;

    return (
        <DropdownWrapper>
            <ClickAwayListener onClickAway={closeMenu}>
                <div>
                    {/*TODO: add onFocus action*/}
                    <Button
                        classes={{
                            root: classes.inputButton,
                            // root: props.error
                            //     ? classes.inputButtonError
                            //     : props.success
                            //     ? classes.inputButtonSuccess
                            //     : classes.inputButton,
                            label: classes.label,
                        }}
                        disableRipple
                        onClick={toggleMenu}
                        onKeyDown={(evt: any) => {
                            handleArrowKeys(evt);
                            !!props.onKeyDown && props.onKeyDown(evt);
                        }}
                        // onFocus={openMenu}
                    >
                        {props.value ? (
                            <ValueWrapper disabled={props.disabled}>{props.value}</ValueWrapper>
                        ) : (
                            <PlaceholderWrapper>{props.placeholder}</PlaceholderWrapper>
                        )}
                        <img src={open ? dropdownExpandDown : dropdownExpandUp} alt="" />
                    </Button>

                    <Fade in={open} timeout={{ enter: 250, exit: 250 }}>
                        <List classes={{ root: classes.menu }}>
                            {props.options.map((option, index) => (
                                <ListItem
                                    button
                                    key={index}
                                    classes={{
                                        root: classes.listItem,
                                        selected: props.classes.selected,
                                    }}
                                    selected={index === selectedIndex}
                                    onClick={() => {
                                        setSelectedIndex(index);
                                        props.onChange(props.options[index]);
                                        closeMenu();
                                    }}
                                >
                                    <Typography variant="button" classes={{ root: classes.listItemText }}>
                                        {option}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Fade>
                </div>
            </ClickAwayListener>
        </DropdownWrapper>
    );
};

export default withStyles(styles)(DropdownInput);
