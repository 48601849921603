import { colors } from '../../theme/colors';

export const CardElementStyle = {
    style: {
        base: {
            fontWeight: '500',
            fontFamily: 'Poppins-Regular, Open Sans, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: colors.grayUnderline,
            },
        },
        invalid: {
            iconColor: colors.redMain,
        },
    },
};
