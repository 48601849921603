import {ActionsOfType, ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {AllEntitiesByPagesDto} from "../../models/dto/allEntitiesByPages.dto";
import {BloodworkResponse} from "../../models/responses/bloodwork.response";
import {UpdateLabWorkPatientDto, UploadLabWorkPatientDto} from "../../models/dto/uploadLabWorkPatient.dto";
import {UploadLabWorkProviderDto} from "../../models/dto/uploadLabWorkProvider.dto";

export enum ActionNames {
    GET_ALL_BLOOD_LABWORKS_PATIENT = '[LabWork] Get All Blood Lab Works Patient',
    GET_ALL_BLOOD_LABWORKS_PROVIDER = '[LabWork] Get All Blood Lab Works Provider',
    GET_ALL_BLOOD_LABWORKS_SUCCESS = '[LabWork] Get All Blood Lab Works Success',
    GET_ALL_BLOOD_LABWORKS_FAILED = '[LabWork] Get All Blood Lab Works Failed',

    RESET_ALL_BLOOD_LABWORKS = '[LabWork] Reset Blood Lab Works',

    GET_BLOOD_LABWORK_PATIENT = '[LabWork] Get Blood Lab Work Patient',
    GET_BLOOD_LABWORK_PROVIDER = '[LabWork] Get Blood Lab Work Provider',
    GET_BLOOD_LABWORK_SUCCESS = '[LabWork] Get Blood Lab Work Success',
    GET_BLOOD_LABWORK_FAILED = '[LabWork] Get Blood Lab Work Failed',

    UPLOAD_BLOODWORK_PATIENT = '[LabWork] Upload Bloodwork Patient',
    UPLOAD_BLOODWORK_PATIENT_SUCCESS = '[LabWork] Upload Bloodwork Patient Success',
    UPLOAD_BLOODWORK_PATIENT_FAILED = '[LabWork] Upload Bloodwork Patient Failed',

    UPLOAD_BLOODWORK_PROVIDER = '[LabWork] Upload Bloodwork Provider',
    UPLOAD_BLOODWORK_PROVIDER_SUCCESS = '[LabWork] Upload Bloodwork Provider Success',
    UPLOAD_BLOODWORK_PROVIDER_FAILED = '[LabWork] Upload Bloodwork Provider Failed',

    UPDATE_BLOODWORK_PATIENT = '[LabWork] Update Bloodwork Patient',
    UPDATE_BLOODWORK_PATIENT_SUCCESS = '[LabWork] Update Bloodwork Patient Success',
    UPDATE_BLOODWORK_PATIENT_FAILED = '[LabWork] Update Bloodwork Patient Failed',

    INCREASE_PAGE_NUMBER_BLOODWORK = '[LabWork] Increase Pge Number Bloodwork',
    SET_FILTER_QUERY_BLOODWORK = '[LabWork] Set Filter Query Bloodwork',

    RESET_STATUS_VARIABLES = '[LabWork] Reset Status Variables',
}

export const Actions = {
    getAllBloodLabWorkPatient: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_BLOOD_LABWORKS_PATIENT, data),
    getAllBloodLabWorkProvider: (data: AllEntitiesByPagesDto, patientId: number) => createAction(ActionNames.GET_ALL_BLOOD_LABWORKS_PROVIDER, {data, patientId}),
    getAllBloodLabWorkSuccess: (data: { result: BloodworkResponse[], total: number }) => createAction(ActionNames.GET_ALL_BLOOD_LABWORKS_SUCCESS, data),
    getAllBloodLabWorkFailed: () => createAction(ActionNames.GET_ALL_BLOOD_LABWORKS_FAILED),

    getBloodLabworkPatient: (id: number) => createAction(ActionNames.GET_BLOOD_LABWORK_PATIENT, id),
    getBloodLabworkProvider: (bloodworkId: number, patientId: number) => createAction(ActionNames.GET_BLOOD_LABWORK_PROVIDER, {bloodworkId, patientId}),
    getBloodLabworkSuccess: (data: BloodworkResponse) => createAction(ActionNames.GET_BLOOD_LABWORK_SUCCESS, data),
    getBloodLabworkFailed: () => createAction(ActionNames.GET_BLOOD_LABWORK_FAILED),

    uploadBloodworkPatient: (data: UploadLabWorkPatientDto) => createAction(ActionNames.UPLOAD_BLOODWORK_PATIENT, data),
    uploadBloodworkPatientSuccess: (data: BloodworkResponse) => createAction(ActionNames.UPLOAD_BLOODWORK_PATIENT_SUCCESS, data),
    uploadBloodworkPatientError: () => createAction(ActionNames.UPLOAD_BLOODWORK_PATIENT_FAILED),

    uploadBloodworkProvider: (data: UploadLabWorkProviderDto) => createAction(ActionNames.UPLOAD_BLOODWORK_PROVIDER, data),
    uploadBloodworkProviderSuccess: (data: BloodworkResponse) => createAction(ActionNames.UPLOAD_BLOODWORK_PROVIDER_SUCCESS, data),
    uploadBloodworkProviderError: () => createAction(ActionNames.UPLOAD_BLOODWORK_PROVIDER_FAILED),

    updateBloodworkPatient: (data: UpdateLabWorkPatientDto) => createAction(ActionNames.UPDATE_BLOODWORK_PATIENT, data),
    updateBloodworkPatientSuccess: () => createAction(ActionNames.UPDATE_BLOODWORK_PATIENT_SUCCESS),
    updateBloodworkPatientFailed: () => createAction(ActionNames.UPDATE_BLOODWORK_PATIENT_FAILED),

    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER_BLOODWORK),
    setFilterQuery: (searchQuery : string) => createAction(ActionNames.SET_FILTER_QUERY_BLOODWORK, searchQuery),

    resetAllBloodLabWorks: () => createAction(ActionNames.RESET_ALL_BLOOD_LABWORKS),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllBloodLabWorkPatientType = ActionsOfType<Actions, ActionNames.GET_ALL_BLOOD_LABWORKS_PATIENT>;
export type getAllBloodLabWorkProviderType = ActionsOfType<Actions, ActionNames.GET_ALL_BLOOD_LABWORKS_PROVIDER>;
export type uploadBloodworkPatientType = ActionsOfType<Actions, ActionNames.UPLOAD_BLOODWORK_PATIENT>;
export type uploadBloodworkProviderType = ActionsOfType<Actions, ActionNames.UPLOAD_BLOODWORK_PROVIDER>;
export type getBloodLabworkPatientType = ActionsOfType<Actions, ActionNames.GET_BLOOD_LABWORK_PATIENT>;
export type getBloodLabworkProviderType = ActionsOfType<Actions, ActionNames.GET_BLOOD_LABWORK_PROVIDER>;
export type updateBloodLabworkPatientType = ActionsOfType<Actions, ActionNames.UPDATE_BLOODWORK_PATIENT>