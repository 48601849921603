import React, { useCallback, useEffect, useState } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import ResultTabs from './components/ResultTabs';
import AllPanels from './components/AllPanels';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { BloodworkResponse } from '../../models/responses/bloodwork.response';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as LabworkActions } from '../../store/actions/labwork.actions';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import Loading from '../shared/Loading';
import { PatientModel } from '../../models/responses/patient.response';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import { formatDate } from '../../helpers/dateFormatHelper';
import styled from 'styled-components';
import { mediaQuery } from '../../constants/device';
import PrimaryButton from '../../shared/button/PrimaryButton';
import ConfirmationDialog from '../payment/components/ConfirmationDialog';
import SecondaryButton from '../../shared/button/SecondaryButton';
import {BloodworkTabs} from "../../constants/tabRouting/bloodworkTabs";

const ButtonGroup = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 24px auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

    > button:first-child {
        margin-bottom: 16px;
    }

    ${mediaQuery.laptop} {
        flex-direction: row;

        > button:first-child {
            margin-bottom: 0;
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 140px);
    height: auto;

    ${mediaQuery.tablet} {
        min-height: calc(100vh - 200px);
    }
`;

interface StoreModel {
    currentBloodwork: BloodworkResponse | null;
    patient: PatientModel | null;
    loadingLabwork: boolean;
    loadingPatient: boolean;
    user: UserModel | null;
}

interface DispatchModel {
    getBloodLabworkPatient: typeof LabworkActions.getBloodLabworkPatient;
    getBloodLabworkProvider: typeof LabworkActions.getBloodLabworkProvider;
    getPatient: typeof PatientActions.getPatient;
    getPatientById: typeof PatientActions.getPatientById;
    resetStatusVariables: typeof LabworkActions.resetStatusVariables;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const BloodWork: React.FC<PropsTypes> = props => {
    const [tabName, setTabName] = useState<BloodworkTabs>(BloodworkTabs.REF_RANGE);

    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

    const {
        resetStatusVariables,
        history,
        user,
        currentBloodwork,
        patient,
        getBloodLabworkPatient,
        getBloodLabworkProvider,
        getPatient,
        getPatientById,
        loadingLabwork,
        loadingPatient,
        match,
    } = props;

    useEffect(() => {
        if (user && user.role === RoleOptions.PATIENT) {
            getBloodLabworkPatient(match.params.id);
            getPatient();
        }
        if (user && user.role === RoleOptions.PROVIDER) {
            getBloodLabworkProvider(match.params.id, match.params.patientId);
            getPatientById(match.params.patientId);
        }
    }, [user, getPatient, getBloodLabworkPatient, getPatientById, getBloodLabworkProvider, match]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    const goToEdit = useCallback(() => {
        currentBloodwork && history.push(`/bloodwork-edit/${currentBloodwork.labWorkId}`);
    }, [currentBloodwork, history]);

    const openConfirmationDialog = useCallback(() => {
        setOpenRemoveDialog(true);
    }, []);

    return (
        <>
            {(loadingPatient || loadingLabwork) && <Loading />}
            {!loadingPatient && !loadingLabwork && patient && currentBloodwork && (
                <>
                    <HeaderHeadingWithLink fileUrl={currentBloodwork.fileUrl}>
                        {formatDate(currentBloodwork.reportDate)} Report
                    </HeaderHeadingWithLink>
                    <Wrapper>
                        <div>
                            <ResultTabs
                                tabName={tabName}
                                setTabName={(tab: BloodworkTabs) => setTabName(tab)}
                                isPreviousReport={!!currentBloodwork.previousReportDate}
                            />
                            <AllPanels
                                currentBloodwork={currentBloodwork}
                                patientGender={patient.gender}
                                tabName={tabName}
                                reportDate={formatDate(currentBloodwork.reportDate)}
                                previousReportDate={formatDate(currentBloodwork.previousReportDate)}
                            />
                        </div>
                        <ButtonGroup>
                            <SecondaryButton onClick={openConfirmationDialog}>Remove Report</SecondaryButton>
                            <PrimaryButton onClick={goToEdit}>Edit Info</PrimaryButton>
                        </ButtonGroup>
                    </Wrapper>
                    {openRemoveDialog && (
                        <ConfirmationDialog
                            open={openRemoveDialog}
                            close={() => setOpenRemoveDialog(false)}
                            text={'Are you sure you want to remove this Bloodwork Report?'}
                            yesAction={() => {}}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        currentBloodwork: state.labwork.currentBloodwork,
        patient: state.patient.patient,
        loadingLabwork: state.labwork.loading,
        loadingPatient: state.patient.loading,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getBloodLabworkPatient: bindActionCreators(LabworkActions.getBloodLabworkPatient, dispatch),
        getBloodLabworkProvider: bindActionCreators(LabworkActions.getBloodLabworkProvider, dispatch),
        getPatient: bindActionCreators(PatientActions.getPatient, dispatch),
        getPatientById: bindActionCreators(PatientActions.getPatientById, dispatch),
        resetStatusVariables: bindActionCreators(LabworkActions.resetStatusVariables, dispatch),
    })
)(withRouter(BloodWork));
