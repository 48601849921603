import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';
import { ClickAwayListener, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import dropdownExpandDown from '../../static/icons/expandDropdownGrayDown.svg';
import dropdownExpandUp from '../../static/icons/expandDropdownGrayUp.svg';
import styled from 'styled-components';
import TextInput from './TextInput';

const DropdownWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const InputWrapper = styled.div`
    position: relative;
`;

interface DOMRect {
    x: number;
    y: number;
    width: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
}

const useStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '14px',
        color: colors.textSecondary,
        marginTop: '20px',
        marginLeft: '12px',
        textAlign: 'left',
    },
    listItem: {},
    labName: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    selected: {
        backgroundColor: `${colors.gray2} !important`,
        color: colors.redLight,
    },
    menu: {
        position: 'absolute',
        width: '100%',
        right: 0,
        padding: 0,
        marginTop: '-2px',
        borderRadius: 0,
        boxShadow: '0px 10px 30px rgba(102, 107, 128, 0.5)',
        zIndex: 2,
        overflow: 'scroll',
        background: colors.white,
        marginBottom: '32px',
    },
    menuNoData: {
        height: '65px',
        position: 'absolute',
        width: '100%',
        marginTop: '-2px',
        borderRadius: 0,
        boxShadow: '0px 10px 30px rgba(102, 107, 128, 0.5)',
        zIndex: 2,
        background: colors.white,
    },
    inputIcon: {
        position: 'absolute',
        right: '16px',
        top: '20px',
    },
});

interface InjectedProps {
    onChange: (entity: BaseDropdownItem | null) => void;
    label: string;
    openIcon?: boolean;
    allEntities: BaseDropdownItem[];
    selectedId?: number | null;
    value?: BaseDropdownItem | null;
    customValue?: boolean;
    error?: boolean;
    disabled?: boolean;
}

type PropsTypes = InjectedProps;

enum Position {
    'TOP',
    'BOTTOM',
}

const DefaultSelect: React.FC<PropsTypes> = props => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const { allEntities, label, onChange, value, error, disabled, openIcon } = props;

    const [labName, setLabName] = useState(value ? value.name : '');
    const [position, setPosition] = useState({ position: Position.BOTTOM, margin: 52 });

    useEffect(() => {
        value && setLabName(value.name);
    }, [value]);

    const refContainer = useRef(null);
    const classes = useStyles(props);

    const closeMenu = () => {
        setOpen(false);
    };

    const getSmallListHeight = useCallback(
        (width: number, symbolWidth: number = 7) => {
            let defaultHeight = 40;
            let totalHeight = 0;
            allEntities.forEach(e => {
                const actualWidth = e.name.length * symbolWidth;
                const newK = Math.ceil(actualWidth / width);
                totalHeight += defaultHeight + 25 * (newK - 1);
            });
            return totalHeight;
        },
        [allEntities]
    );

    const setDropdownPosition = (height: number) => {
        // @ts-ignore
        const elementParams: DOMRect = refContainer.current && refContainer.current.getBoundingClientRect();
        const isBottom = window.innerHeight - elementParams.top - 32 >= height;
        setPosition({ position: isBottom ? Position.BOTTOM : Position.TOP, margin: isBottom ? 0 : height });
    };

    const getScrollableListHeight = useCallback(() => {
        // @ts-ignore
        const elementParams: DOMRect = refContainer.current && refContainer.current.getBoundingClientRect();
        if (elementParams) {
            const width = elementParams.width - 32;
            if (allEntities.length <= 6) {
                return getSmallListHeight(width);
            }
        }
        let listItemHeight = 40;
        return allEntities.length <= 6 ? allEntities.length * listItemHeight : 240;
    }, [allEntities, getSmallListHeight]);

    const getSelectedIndex = useCallback(
        (name: string) => {
            return allEntities.findIndex(entity => entity.name.toLowerCase() === name.trim().toLowerCase());
        },
        [allEntities]
    );

    useEffect(() => {
        const height = getScrollableListHeight();
        setDropdownPosition(height);
    }, [allEntities.length, getScrollableListHeight]);

    useEffect(() => {
        if (allEntities.length > 0) {
            if (value) {
                const index = getSelectedIndex(value.name);
                setSelectedIndex(index !== -1 ? allEntities[index].id! : 0);
            } else {
                setSelectedIndex(0);
            }
        }
    }, [allEntities, value, getSelectedIndex]);

    const handleEnter = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        //enter
        if (evt.keyCode === 13) {
            if (allEntities.length > 0) {
                const entity = allEntities[selectedIndex];
                setLabName(entity.name);
                onChange(entity);
                closeMenu();
            }
        }
        //key up
        if (evt.keyCode === 38) {
            if (selectedIndex - 1 >= 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        }

        //key down
        if (evt.keyCode === 40) {
            if (selectedIndex + 1 < allEntities.length) {
                setSelectedIndex(selectedIndex + 1);
            }
        }
    };

    return (
        <DropdownWrapper>
            <ClickAwayListener onClickAway={closeMenu}>
                <div ref={refContainer}>
                    <InputWrapper>
                        <TextInput
                            placeholder={label}
                            value={labName}
                            onFocus={() => setOpen(true)}
                            onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => handleEnter(evt)}
                            error={error}
                            isInDropdown
                            disabled={disabled}
                            style={{ textTransform: 'capitalize', color: 'red' }}
                        />
                        {openIcon && (
                            <img
                                src={open ? dropdownExpandDown : dropdownExpandUp}
                                alt=""
                                className={classes.inputIcon}
                            />
                        )}
                    </InputWrapper>

                    {open && (
                        <div>
                            <List
                                classes={{ root: classes.menu }}
                                style={{
                                    top:
                                        position.position === Position.BOTTOM
                                            ? `${position.margin}px !important`
                                            : `-${position.margin}px`,
                                }}
                            >
                                {allEntities.map((entity: BaseDropdownItem, index: number) => (
                                    <ListItem
                                        key={index}
                                        classes={{
                                            root: classes.listItem,
                                            selected: classes.selected,
                                        }}
                                        onClick={() => {
                                            setSelectedIndex(index);
                                            onChange(entity);
                                            setLabName(entity.name);
                                            closeMenu();
                                        }}
                                        selected={index === selectedIndex}
                                    >
                                        <Typography variant="body1" classes={{ root: classes.labName }}>
                                            {entity.name}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
        </DropdownWrapper>
    );
};

export default DefaultSelect;
