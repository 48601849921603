import React, { useCallback } from 'react';
import SecondaryButton from '../../shared/button/SecondaryButton';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as AuthActions } from '../../store/actions/auth.actions';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;

interface DispatchModel {
    resetStatusVariables: typeof AuthActions.resetStatusVariables;
}

const ChangePasswordButton: React.FC<RouteComponentProps<any> & DispatchModel> = ({
    history,
    resetStatusVariables,
}) => {
    const goToChangePassword = useCallback(() => {
        resetStatusVariables();
        history.push('/change-password');
    }, [history, resetStatusVariables]);

    return (
        <Wrapper>
            <SecondaryButton onClick={goToChangePassword}>Change Password</SecondaryButton>
        </Wrapper>
    );
};

export default connect(
    () => ({}),
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(AuthActions.resetStatusVariables, dispatch),
    })
)(withRouter(ChangePasswordButton));
