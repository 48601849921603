import React, { useCallback } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../shared/button/PrimaryButton';
import styled from 'styled-components';
import welcomeMobile from '../../static/images/patient/welcomeMobile.png';
import welcomeDesktop from '../../static/images/patient/welcomeDesktop.png';
import { colors } from '../../theme/colors';
import { RouteComponentProps, withRouter } from 'react-router';
import { mediaQuery, size } from '../../constants/device';
import { connect } from 'react-redux';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import { bindActionCreators, Dispatch } from 'redux';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { PatientStepClassName } from '../tour/constants/patientStepsClassNames';
import { Actions as TourActions } from '../../store/actions/tour.actions';

const BackgroundWrapper = styled.div<{ background: string }>`
    height: 500px;
    background-image: url(${props => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center -12vh;

    ${mediaQuery.mobileS} {
        background-position: center 103%;
        height: 340px;
    }

    ${mediaQuery.mobileM} {
        background-position: center 103%;
        height: 415px;
    }

    ${mediaQuery.mobileL} {
        background-position: center -22vh;
        height: 700px;
    }

    ${mediaQuery.tablet} {
        background-position: center 144%;
        height: 100%;
    }

    ${mediaQuery.mobileLandscape} {
        background-position: center -128vh;
        height: 337px;
    }

    ${mediaQuery.laptop} {
        border-radius: 30px;
        height: 450px;
    }

    ${mediaQuery.desktop} {
        border-radius: 30px;
        height: 70%;
    }
`;

const Wrapper = styled.div`
    height: calc(100vh - 210px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0 40px;

    ${mediaQuery.mobileLandscape} {
        height: auto;
        padding: 20px 0 0;
    }

    ${mediaQuery.laptop} {
        height: 463px;
        padding: 130px 0;
    }
`;

const SkipLinkWrapper = styled.div`
    margin-top: 36px;

    @media (max-width: ${size.mobileS}) {
        margin-top: 12px;
    }

    ${mediaQuery.mobileLandscape} {
        margin: 10px 0 20px;
    }
`;

const ListTextWrapper = styled.div`
    margin-bottom: 20px;
    text-align: center;

    @media screen and (orientation: landscape) {
        margin: 10px 0 20px;
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const createStyles = makeStyles({
    text: {
        color: colors.textSecondary,
        fontSize: '14px',
        textAlign: 'center',
        marginTop: '24px',
    },
    heading: {
        fontSize: '24px',
    },
    listText: {
        textAlign: 'center',
        color: colors.textPrimary,
    },
    skipLink: {
        color: colors.textSecondary,
        textDecoration: 'underline',
        textDecorationColor: colors.textSecondary,
        cursor: 'pointer',
    },
});

interface DispatchModel {
    setIsSetupToTrue: typeof PatientActions.setIsSetupToTrue;
    setTourVisible: typeof TourActions.setTourVisible;
}

type PropsTypes = DispatchModel & RouteComponentProps<any>;

const Welcome: React.FC<PropsTypes> = props => {
    const { setTourVisible, setIsSetupToTrue, history } = props;

    const skipSetup = useCallback(() => {
        setIsSetupToTrue();
        history.push('/patient-start');
    }, [history, setIsSetupToTrue]);

    const classes = createStyles(props);

    const isLaptop = useMediaQuery(mediaQuery.laptop);
    const welcomeBackground = isLaptop ? welcomeDesktop : welcomeMobile;

    const setTour = useCallback(() => {
        setTourVisible(true);
    }, [setTourVisible]);

    return (
        <>
            <BackgroundWrapper background={welcomeBackground}>
                <SizeControllerWrapper>
                    <Wrapper>
                        <div>
                            <div className={PatientStepClassName.WELCOME}>
                                <Typography variant="h2" classes={{ root: classes.heading }}>
                                    Welcome to UpMed
                                </Typography>
                            </div>
                            <Typography variant="body1" classes={{ root: classes.text }}>
                                Take just a few minutes to setup <br /> Your profile to maximize your UpMed.
                            </Typography>
                        </div>

                        <ListTextWrapper>
                            <Typography variant="button" classes={{ root: classes.listText }}>
                                1. Manage Profile <br />
                                2. Manage Provider <br />
                                3. Manage Payments
                            </Typography>
                        </ListTextWrapper>

                        <ActionsWrapper>
                            <PrimaryButton onClick={setTour}>Start</PrimaryButton>

                            <SkipLinkWrapper>
                                <Typography variant="button" classes={{ root: classes.skipLink }} onClick={skipSetup}>
                                    Skip Tutorial
                                </Typography>
                            </SkipLinkWrapper>
                        </ActionsWrapper>
                    </Wrapper>
                </SizeControllerWrapper>
            </BackgroundWrapper>
        </>
    );
};

export default connect(
    () => ({}),
    (dispatch: Dispatch): DispatchModel => ({
        setIsSetupToTrue: bindActionCreators(PatientActions.setIsSetupToTrue, dispatch),
        setTourVisible: bindActionCreators(TourActions.setTourVisible, dispatch),
    })
)(withRouter(Welcome));
