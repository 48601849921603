import * as authorizationActions from '../../actions/auth.actions';
import VerificationState from '../../../constants/emailVerificationState';
import RequestState from '../../../constants/requestState';
import { UserModel } from '../../../models/responses/user.response';
import AuthState from '../../../constants/authState';

export class AuthStateModel {
    user: UserModel | null = null;
    authStatus: AuthState = AuthState.UNCHECKED;
    loading: boolean = false;
    verificationEmailSent: RequestState = RequestState.UNSENT;
    resetPasswordEmailSent: RequestState = RequestState.UNSENT;
    signedIn: RequestState = RequestState.UNSENT;
    passwordChanged: RequestState = RequestState.UNSENT;
    emailVerificationStatus: VerificationState = VerificationState.UNCHECKED;
    acceptInvitationSent: RequestState = RequestState.UNSENT;
    changeCurrentPasswordLoading: RequestState = RequestState.UNSENT;
}

export const initialState = new AuthStateModel();

export function authReducer(state = initialState, action: authorizationActions.Actions): AuthStateModel {
    switch (action.type) {
        case authorizationActions.ActionNames.SET_AUTH_STATUS: {
            return {
                ...state,
                authStatus: action.payload,
            };
        }

        case authorizationActions.ActionNames.SIGN_UP: {
            return {
                ...state,
                loading: true,
                verificationEmailSent: RequestState.SENDING,
            };
        }

        case authorizationActions.ActionNames.SIGN_UP_SUCCESS: {
            return {
                ...state,
                verificationEmailSent: RequestState.SENT_SUCCESS,
                loading: false,
            };
        }
        case authorizationActions.ActionNames.SIGN_UP_FAILED: {
            return {
                ...state,
                verificationEmailSent: RequestState.SENT_FAILED,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.RESEND_VERIFICATION_LINK: {
            return {
                ...state,
                loading: true,
            };
        }

        case authorizationActions.ActionNames.RESEND_VERIFICATION_LINK_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.RESEND_VERIFICATION_LINK_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.VERIFY_EMAIL: {
            return {
                ...state,
                loading: true,
                emailVerificationStatus: VerificationState.CHECKING,
            };
        }

        case authorizationActions.ActionNames.VERIFY_EMAIL_SUCCESS: {
            return {
                ...state,
                emailVerificationStatus: VerificationState.VERIFIED,
                user: action.payload,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.VERIFY_EMAIL_FAILED: {
            return {
                ...state,
                emailVerificationStatus: VerificationState.UNVERIFIED,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.SIGN_IN: {
            return {
                ...state,
                loading: true,
                signedIn: RequestState.SENDING,
            };
        }

        case authorizationActions.ActionNames.SIGN_IN_SUCCESS: {
            return {
                ...state,
                signedIn: RequestState.SENT_SUCCESS,
                user: action.payload.user,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.SIGN_IN_FAILED: {
            return {
                ...state,
                signedIn: RequestState.SENT_FAILED,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.RESET_PASSWORD: {
            return {
                ...state,
                loading: true,
                user: {
                    ...state.user!,
                    email: action.payload.email,
                },
                resetPasswordEmailSent: RequestState.SENDING,
            };
        }

        case authorizationActions.ActionNames.RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                resetPasswordEmailSent: RequestState.SENT_SUCCESS,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.RESET_PASSWORD_FAILED: {
            return {
                ...state,
                resetPasswordEmailSent: RequestState.SENT_FAILED,
                loading: false,
            };
        }

        case authorizationActions.ActionNames.CHANGE_PASSWORD: {
            return {
                ...state,
                loading: true,
                passwordChanged: RequestState.SENDING,
            };
        }

        case authorizationActions.ActionNames.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                loading: false,
                passwordChanged: RequestState.SENT_SUCCESS,
            };
        }

        case authorizationActions.ActionNames.CHANGE_PASSWORD_FAILED: {
            return {
                ...state,
                loading: false,
                passwordChanged: RequestState.SENT_FAILED,
            };
        }

        case authorizationActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                verificationEmailSent: RequestState.UNSENT,
                resetPasswordEmailSent: RequestState.UNSENT,
                signedIn: RequestState.UNSENT,
                passwordChanged: RequestState.UNSENT,
                acceptInvitationSent: RequestState.UNSENT,
                changeCurrentPasswordLoading: RequestState.UNSENT,
            };
        }

        case authorizationActions.ActionNames.SET_CURRENT_USER: {
            return {
                ...state,
                user: action.payload,
            };
        }

        case authorizationActions.ActionNames.REMOVE_CURRENT_USER: {
            return {
                ...state,
                user: null,
            };
        }

        case authorizationActions.ActionNames.ACCEPT_INVITATION: {
            return {
                ...state,
                loading: true,
                acceptInvitationSent: RequestState.SENDING,
            };
        }

        case authorizationActions.ActionNames.ACCEPT_INVITATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                acceptInvitationSent: RequestState.SENT_SUCCESS,
            };
        }

        case authorizationActions.ActionNames.ACCEPT_INVITATION_FAILED: {
            return {
                ...state,
                loading: false,
                acceptInvitationSent: RequestState.SENT_FAILED,
            };
        }

        case authorizationActions.ActionNames.CHANGE_CURRENT_PASSWORD: {
            return {
                ...state,
                changeCurrentPasswordLoading: RequestState.SENDING,
            };
        }

        case authorizationActions.ActionNames.CHANGE_CURRENT_PASSWORD_SUCCESS: {
            return {
                ...state,
                changeCurrentPasswordLoading: RequestState.SENT_SUCCESS,
            };
        }

        case authorizationActions.ActionNames.CHANGE_CURRENT_PASSWORD_FAILED: {
            return {
                ...state,
                changeCurrentPasswordLoading: RequestState.SENT_FAILED,
            };
        }

        default:
            return state;
    }
}
