import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { Actions as DrugActions } from '../../../store/actions/drug.actions';
import { bindActionCreators, Dispatch } from 'redux';
import DropdownInputList from '../DropdownInputList';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { DefaultPageSize, shouldFetchData } from '../../../constants/dataFetching/allEntitiesByPages';
import {useCompare} from "../../../helpers/usePreviousHelper";

interface InjectedProps {
    onChange: (entity: BaseDropdownItem | null) => void;
    label: string;
    value?: BaseDropdownItem | null;
    customValue?: boolean;
}

interface StateModel {
    loading: boolean;
    allDrugs: BaseDropdownItem[];
    totalDrugs: number | null;
    page: number;
    searchQuery: string;
}

interface DispatchModel {
    increasePageNumber: typeof DrugActions.increasePageNumber;
    getAllDrugs: typeof DrugActions.getAllDrugs;
    setFilterQuery: typeof DrugActions.setSearchQuery;
}

type PropsTypes = InjectedProps & DispatchModel & StateModel;

const DropdownInputDrugListWrapper: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);

    const {
        page,
        loading,
        value,
        onChange,
        searchQuery,
        increasePageNumber,
        label,
        setFilterQuery,
        allDrugs,
        customValue,
        getAllDrugs,
        totalDrugs,
    } = props;
    const isSearchQueryChanged = useCompare(searchQuery);

    const loadData = useCallback(
        (page: number, size: number, sort: string, searchQuery: string, overrideRecords: boolean) => {
            getAllDrugs({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllDrugs]
    );

    useEffect(() => {
        setFilterQuery('');
        loadData(0, DefaultPageSize, '', '', true);
        setInitialLoaded(true);
    }, [loadData, setFilterQuery]);

    useEffect(() => {
        if (shouldFetchData(loading, page, allDrugs.length, initialLoaded, DefaultPageSize) || isSearchQueryChanged) {
            loadData(page, DefaultPageSize, '', searchQuery, false);
        }
    }, [page, searchQuery, loading, allDrugs, initialLoaded, loadData, isSearchQueryChanged]);


    return (
        <DropdownInputList
            value={value}
            onChange={onChange}
            label={label}
            loading={loading}
            allEntities={allDrugs}
            totalEntities={totalDrugs}
            searchQuery={searchQuery}
            increasePageNumber={increasePageNumber}
            setFilterQuery={setFilterQuery}
            customValue={customValue}
        />
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.drug.loading,
        allDrugs: state.drug.allDrugs,
        totalDrugs: state.drug.totalDrugs,
        page: state.drug.page,
        searchQuery: state.drug.searchQuery,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllDrugs: bindActionCreators(DrugActions.getAllDrugs, dispatch),
        increasePageNumber: bindActionCreators(DrugActions.increasePageNumber, dispatch),
        setFilterQuery: bindActionCreators(DrugActions.setSearchQuery, dispatch),
    })
)(DropdownInputDrugListWrapper);
