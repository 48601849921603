import React, { useEffect } from 'react';
import HeaderHeadingWithLink from '../../../shared/HeaderHeadingWithLink';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';
import ProviderAccount from '../components/ProviderAccount';
import { makeStyles, Typography } from '@material-ui/core';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import RequestState from '../../../constants/requestState';
import * as invoiceAction from '../../../store/actions/invoice.actions';
import Loading from '../../shared/Loading';
import { PaymentRoutingProvider } from '../../../constants/tabRouting/routing';

const RelativeWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const Wrapper = styled.div`
    text-align: center;
`;

const createStyles = makeStyles({
    text: {
        marginTop: '20vh',
        marginBottom: '40px',
        fontSize: '16px',
    },
});

interface StoreModel {
    isMerchantVerified: boolean;
    isMerchantVerifiedRequest: RequestState;
}

interface DispatchModel {
    verifyMerchant: typeof invoiceAction.Actions.verifyMerchant;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const SetupStripeMerchant: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const { verifyMerchant, isMerchantVerified, isMerchantVerifiedRequest } = props;

    useEffect(() => {
        verifyMerchant();
    }, [verifyMerchant]);

    //TODO check if provider is not merchant and not verified
    useEffect(() => {
        if (isMerchantVerifiedRequest === RequestState.SENT_SUCCESS && isMerchantVerified) {
            props.history.push(PaymentRoutingProvider[0].path);
        }
    }, [isMerchantVerified, isMerchantVerifiedRequest, props.history]);

    return (
        <RelativeWrapper>
            <HeaderHeadingWithLink>Payments</HeaderHeadingWithLink>
            {isMerchantVerifiedRequest === RequestState.SENDING && <Loading smallSize />}
            <SizeControllerWrapper>
                <Wrapper>
                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                        Your account requires additional info to be verified by Stripe. <br /> Please, click the button
                        below to provide additional info.
                    </Typography>
                    <ProviderAccount>Update Stripe Profile</ProviderAccount>
                </Wrapper>
            </SizeControllerWrapper>
        </RelativeWrapper>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        isMerchantVerified: state.invoice.isMerchantVerified,
        isMerchantVerifiedRequest: state.invoice.isMerchantVerifiedRequest,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        verifyMerchant: bindActionCreators(invoiceAction.Actions.verifyMerchant, dispatch),
    })
)(withRouter(SetupStripeMerchant));
