const requiredEnvs = {
    REACT_APP_API_URL: '',
    REACT_APP_WEBSOCKET_URL: '',
    REACT_APP_PUBLISHABLE_KEY: '',
    REACT_APP_CLIENT_ID: '',
    REACT_APP_BASE_URL: '',
    REACT_APP_SENTRY_DSN: '',
    REACT_APP_ENVIRONMENT: '',
} as const;

type TypedEnv = typeof requiredEnvs;

const validateAndReturnTypedEnv = () => {
    const env: { [key: string]: string } = {};
    Object.keys(requiredEnvs).forEach(key => {
        if (!(key in process.env)) {
            throw new Error(`Required env "${key}" not defined`);
        }
        env[key] = process.env[key]!;
    });
    return env as TypedEnv;
};

export const TYPED_ENV = validateAndReturnTypedEnv();
