import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PanelTypeModel } from '../../models/responses/panelType.model';
import AutocompleteSingle from '../../shared/input/AutocompleteSingle';
import styled from 'styled-components';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import AutocompleteMultiple from '../../shared/input/AutocompleteMultiple';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { mediaQuery } from '../../constants/device';
import SecondaryButton from '../../shared/button/SecondaryButton';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as PanelTypeActions } from '../../store/actions/panelType.actions';
import ConfirmationDialog from '../payment/components/ConfirmationDialog';
import TooltipShared from '../shared/TooltipShared';

interface InjectedProps {
    activePanel: PanelTypeModel;
    panelTypes: PanelTypeModel[];
    bloodTargetValues: BaseDropdownItem[];
    allBloodTargets: BaseDropdownItem[];
}

const Form = styled.div`
    width: 100%;
    padding: 32px 0 170px;
    > div {
        margin-bottom: 16px;
    }

    ${mediaQuery.laptop} {
        padding: 32px 0 96px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ButtonGroup = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 500px;
    margin: 24px auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

    > button:first-child {
        margin-bottom: 16px;
    }

    ${mediaQuery.laptop} {
        flex-direction: row;

        > button:first-child {
            margin-bottom: 0;
        }
    }
`;

interface DispatchModel {
    editPanelType: typeof PanelTypeActions.editPanelType;
    removePanelType: typeof PanelTypeActions.removePanelType;
}

type PropsTypes = InjectedProps & DispatchModel;

const PanelTypeItem: React.FC<PropsTypes> = props => {
    const { activePanel, panelTypes, bloodTargetValues, allBloodTargets, removePanelType, editPanelType } = props;

    const [panel, setPanel] = useState<PanelTypeModel>();
    const [bloodTargets, setBloodTargets] = useState();
    const [defaultBloodTargets, setDefaultBloodTargets] = useState();
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

    useEffect(() => {
        setPanel(activePanel);
        const bloodTargets = allBloodTargets.filter(x => bloodTargetValues.filter(t => t.id === x.id).length);
        setBloodTargets(bloodTargets);
        setDefaultBloodTargets(bloodTargets);
    }, [activePanel, bloodTargetValues, allBloodTargets]);

    const canRemove = useMemo(() => {
        return defaultBloodTargets && !defaultBloodTargets.length;
    }, [defaultBloodTargets]);

    const openConfirmationDialog = useCallback(() => {
        canRemove && setOpenRemoveDialog(true);
    }, [canRemove]);

    const remove = useCallback(() => {
        if (canRemove && panel) {
            removePanelType(panel.id);
            setOpenRemoveDialog(false);
        }
    }, [canRemove, panel, removePanelType]);

    const update = useCallback(() => {
        if (panel && bloodTargets) {
            editPanelType({
                id: panel.id,
                bloodTargetIds: bloodTargets.map((target: BaseDropdownItem) => {
                    return target.id;
                }),
            });
        }
    }, [panel, bloodTargets, editPanelType]);

    const tooltipMessage = useMemo(() => {
        return canRemove ? '' : 'To remove this group, please reassign blood targets to another group';
    }, [canRemove]);

    const scrollToTop = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        scrollToTop();
    }, [scrollToTop]);

    return (
        <>
            {panel && (
                <>
                    <SizeControllerWrapper>
                        <Wrapper>
                            <Form>
                                <AutocompleteSingle
                                    value={panel.id}
                                    options={panelTypes}
                                    label={'Panel Types'}
                                    onChange={() => {}}
                                    disabled
                                />

                                <AutocompleteMultiple
                                    value={bloodTargets}
                                    options={allBloodTargets}
                                    onChange={(value: any) => {
                                        setBloodTargets([...new Set([...defaultBloodTargets, ...value])]);
                                    }}
                                    label={'Blood Targets'}
                                    noDelete
                                    onFocus={scrollToTop}
                                />
                            </Form>
                            <ButtonGroup>
                                <TooltipShared title={tooltipMessage}>
                                    <span>
                                        <SecondaryButton onClick={openConfirmationDialog} disabled={!canRemove}>
                                            Remove
                                        </SecondaryButton>
                                    </span>
                                </TooltipShared>

                                <PrimaryButton onClick={update}>Update Info</PrimaryButton>
                            </ButtonGroup>
                        </Wrapper>
                    </SizeControllerWrapper>
                </>
            )}

            <ConfirmationDialog
                open={openRemoveDialog}
                close={() => setOpenRemoveDialog(false)}
                text={'Are you sure you want to remove this Panel Type?'}
                yesAction={remove}
            />
        </>
    );
};

export default connect(
    null,
    (dispatch: Dispatch): DispatchModel => ({
        editPanelType: bindActionCreators(PanelTypeActions.editPanelType, dispatch),
        removePanelType: bindActionCreators(PanelTypeActions.removePanelType, dispatch),
    })
)(PanelTypeItem);
