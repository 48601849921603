import { RoleOptions } from '../constants/roleOptions';

export default class StorageHelper {
    public static token(): string | null {
        return localStorage.getItem('token');
    }

    public static setToken(token: string) {
        localStorage.setItem('token', token);
    }

    public static role(): string | null {
        return localStorage.getItem('role');
    }

    public static setRole(role: RoleOptions) {
        localStorage.setItem('role', role);
    }

    public static email(): string | null {
        return localStorage.getItem('email');
    }

    public static setEmail(email: string) {
        localStorage.setItem('email', email);
    }

    public static clear() {
        window.localStorage.clear();
    }
}
