import React, { useEffect, useState } from 'react';
import AuthState from './constants/authState';
import AuthorizedRoutes from './routing/authorizedRoutes';
import UnauthorizedRoutes from './routing/unauthorizedRoutes';
import Loading from './pages/shared/Loading';
import StorageHelper from './helpers/storageHelper';
import AuthApi from './api/auth/AuthApi';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as fromRoot from './store/reducers';
import { UserModel } from './models/responses/user.response';
import { Actions as AuthActions } from './store/actions/auth.actions';
import { Actions as SidebarActions } from './store/actions/sidebar.actions';
import SnackbarsContainer from './shared/snackbars/snackbarsContainer';
import WSProvider from './pages/common/WSProvider';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { TYPED_ENV } from './helpers/typedEnv';

interface StateModel {
    user: UserModel | null;
    open: boolean;
}

interface DispatchModel {
    setCurrentUser: typeof AuthActions.setCurrentUser;
    toggleSidebar: typeof SidebarActions.toggleSidebar;
}

interface Props extends DispatchModel, StateModel {}

const AuthorizedRouter: React.FC<{
    user: UserModel;
    token: string;
    open: boolean;
    toggleSidebar: () => void;
}> = ({ user, token, open, toggleSidebar }) => {
    const stripePromise = loadStripe(TYPED_ENV.REACT_APP_PUBLISHABLE_KEY);

    return (
        <>
            <WSProvider userId={user.id} token={token} />
            <Elements stripe={stripePromise}>
                <AuthorizedRoutes user={user} sidebarOpen={open} toggleSidebar={toggleSidebar} />
            </Elements>
            <SnackbarsContainer />
        </>
    );
};

const UnAuthorizedRouter: React.FC<{
    open: boolean;
    toggleSidebar: () => void;
}> = ({ open, toggleSidebar }) => {
    return (
        <>
            <UnauthorizedRoutes sidebarOpen={open} toggleSidebar={toggleSidebar} />
            <SnackbarsContainer />
        </>
    );
};

const AppRouting = (props: Props) => {
    const [authStatus, setAuthStatus] = useState<AuthState>(AuthState.UNCHECKED);
    const [token, setToken] = useState(StorageHelper.token());

    const { setCurrentUser, user, open, toggleSidebar } = props;

    useEffect(() => {
        setToken(StorageHelper.token());
    }, [props]);

    useEffect(() => {
        // don't check status if token isn't found
        if (!token) {
            setAuthStatus(AuthState.UNAUTHORIZED);
            return;
        }

        if (user) {
            setAuthStatus(AuthState.AUTHORIZED);
            return;
        }

        AuthApi.getCurrentUser()
            .then(({ data }) => {
                setCurrentUser(data);
                setAuthStatus(AuthState.AUTHORIZED);
            })
            .catch(() => {
                setAuthStatus(AuthState.UNAUTHORIZED);
                StorageHelper.clear();
            });
    }, [token, user, setCurrentUser]);

    switch (authStatus) {
        case AuthState.CHECKING:
        case AuthState.UNCHECKED:
            return <Loading />;
        case AuthState.AUTHORIZED:
            return <AuthorizedRouter user={user!} token={token!} open={open} toggleSidebar={toggleSidebar} />;
        case AuthState.UNAUTHORIZED:
            return <UnAuthorizedRouter open={open} toggleSidebar={toggleSidebar} />;
        default:
            return <Loading />;
    }
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        user: state.authorization.user,
        open: state.sidebar.open,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setCurrentUser: bindActionCreators(AuthActions.setCurrentUser, dispatch),
        toggleSidebar: bindActionCreators(SidebarActions.toggleSidebar, dispatch),
    })
)(AppRouting);
