import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../../theme/colors';
import styled from 'styled-components';
import { mediaQuery } from '../../../../constants/device';

const createStyles = makeStyles({
    text: (props: { textFontSize: number }) => ({
        fontSize: `${props.textFontSize}px`,
        lineHeight: '30px',
        color: colors.textSecondary,
    }),
    authorName: {
        fontSize: '12px',
        lineHeight: '18px',
        color: colors.textPrimary,
        margin: '40px 0 30px',
        display: 'block',
    },
    photo: {
        margin: '50px auto',
        width: '255px',
        display: 'block',
    },
    avatar: {
        width: '100%',
        borderRadius: '50px',
    },
});

const Wrapper = styled.div<{ partialVisible: boolean; centeredDesktop: boolean }>`
    padding: ${props => (props.partialVisible ? '38px 0px' : '38px 21px')};
    text-align: center;
    ${mediaQuery.tablet} {
        padding: ${props => (props.centeredDesktop ? '60px 235px' : '')};
    }
`;

const AvatarWrapper = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 30px;
    margin: 18px auto 62px;
`;

const CarouselItem: React.FC<{
    text: string;
    authorName?: string;
    textFontSize: number;
    image?: string;
    avatar?: string;
    partialVisible: boolean;
    centeredDesktop: boolean;
}> = props => {
    const { text, authorName, image, avatar, partialVisible, centeredDesktop } = props;
    const classes = createStyles(props);

    return (
        <Wrapper partialVisible={partialVisible} centeredDesktop={centeredDesktop}>
            {avatar && (
                <AvatarWrapper>
                    <img src={avatar} draggable={false} className={classes.avatar} alt="" />
                </AvatarWrapper>
            )}
            <Typography variant={'body1'} classes={{ root: classes.text }}>
                {text}
            </Typography>
            {props.authorName && (
                <Typography variant={'button'} classes={{ root: classes.authorName }}>
                    {authorName}
                </Typography>
            )}
            {image && <img src={image} alt="" draggable={false} className={classes.photo} />}
        </Wrapper>
    );
};

export default CarouselItem;
