import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/colors';

const AvatarWrapper = styled.div<{ img?: string }>`
    border: 1px solid ${colors.gray3};
    background-color: ${colors.gray3};
    width: 38px;
    height: 38px;
    border-radius: 20px;
    background-image: url(${props => (props.img ? props.img : '')});
    background-size: cover;
`;

const AvatarShared: React.FC<{ img: string }> = props => {
    return <AvatarWrapper img={props.img} />;
};

export default AvatarShared;
