import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import editIcon from '../../../static/icons/editIcon.svg';
import deleteIcon from '../../../static/icons/deleteIcon.svg';
import { makeStyles, Typography } from '@material-ui/core';
import { CreatePrescriptionComponentDisplayModel } from '../../../models/responses/CrestePrescriptionComponentDisplayModel';
import { RoleOptions } from '../../../constants/roleOptions';
import RemovePrescriptionDialog from './RemovePrescriptionDialog';

const Wrapper = styled.div`
    width: 90%;
    margin-top: 36px;
    padding: 20px 16px 14px 16px;
    position: relative;
    text-align: left;
    border: 1px solid ${colors.grayUnderline};
    border-radius: 10px;
    margin-bottom: 10px;
`;

const Heading = styled.div``;

const createStyles = makeStyles({
    heading: {
        fontSize: '14px',
        color: colors.redMain,
    },
    editIcon: {
        marginRight: '8px',
        marginBottom: '-1px',
        cursor: 'pointer',
    },
    deleteIcon: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        cursor: 'pointer',
    },
    date: {
        fontSize: '12px',
        color: colors.textSecondary,
        margin: '6px 0 4px 0',
    },
    info: {
        fontSize: '12px',
        color: colors.textPrimary,
    },
    private: {
        fontSize: '12px',
        color: colors.redMain,
    },
    dialogText: {
        fontSize: '16px',
        lineHeight: '24px',
    },
});

interface InjectedProps {
    prescription: CreatePrescriptionComponentDisplayModel;
    removeComponent: (id: number) => void;
    editComponent: (id: number) => void;
    role: RoleOptions;
    ableToEdit: boolean;
}

type PropsTypes = InjectedProps;

const PrescriptionInfoBlock: React.FC<PropsTypes> = props => {
    const { prescription, ableToEdit } = props;
    const [openDialog, setOpenDialog] = useState(false);

    const classes = createStyles(props);
    return (
        <>
            <Wrapper>
                {ableToEdit && (
                    <img src={deleteIcon} alt="" className={classes.deleteIcon} onClick={() => setOpenDialog(true)} />
                )}
                <Heading>
                    <img
                        src={editIcon}
                        alt="edit icon"
                        className={classes.editIcon}
                        onClick={() => props.editComponent(prescription.id)}
                    />
                    <Typography
                        variant="button"
                        classes={{ root: classes.heading }}
                        onClick={() => props.editComponent(prescription.id)}
                    >
                        {prescription.drug}
                    </Typography>
                </Heading>
                <Typography variant={'body1'} classes={{ root: classes.date }}>
                    {prescription.date}
                </Typography>
                <Typography variant={'button'} classes={{ root: classes.info }}>
                    Dosage - {prescription.dosage}. Frequency - {prescription.frequency}. <br />
                    Quantity - {prescription.quantity}. Potency - {prescription.potency}. <br />
                    Application Type - {prescription.drugApplicationType}. <br />
                    Therapy - {prescription.therapies}.
                </Typography>

                {props.role === RoleOptions.PATIENT && prescription.private && (
                    <div>
                        <Typography variant="button" classes={{ root: classes.private }}>
                            Private
                        </Typography>
                    </div>
                )}
            </Wrapper>
            {openDialog && (
                <RemovePrescriptionDialog
                    open={openDialog}
                    close={() => setOpenDialog(false)}
                    removePrescriptionPatient={() => props.removeComponent(prescription.id)}
                >
                    <Typography variant="button" classes={{ root: classes.dialogText }}>
                        Are you sure you want <br />
                        to remove this prescription component?
                    </Typography>
                </RemovePrescriptionDialog>
            )}
        </>
    );
};

export default PrescriptionInfoBlock;
