import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import styled from 'styled-components';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../constants/layout';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import addRowIcon from '../../static/icons/addRow.svg';
import AddLabRow from './AddLabRow';
import { connect } from 'react-redux';
import * as RootStateModel from '../../store/reducers/index';
import RequestState from '../../constants/requestState';
import { Actions as LabsActions } from '../../store/actions/lab.actions';
import { bindActionCreators, Dispatch } from 'redux';
import LoadingTransparent from '../shared/LoadingTransparent';
import Snackbar, { SnackbarTypes } from '../../shared/snackbars/snackbar';
import { withRouter, RouteComponentProps } from 'react-router';
import CancelLink from "../../shared/link/CancelLink";

const Wrapper = styled.div<{ height: number }>`
    overflow: scroll;
    min-height: ${props => props.height}px;
    margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const tableCols: string[] = [
    'Name',
    'Website',
    'Phone',
    'Email',
    'Street Address 1',
    'Street Address 2',
    'City',
    'State',
    'Zip',
];

export interface LabRow {
    id: number;
    name: string;
    website: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    email: string;
}

const initialStateRow = {
    id: 0,
    name: '',
    website: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
};

const initialState = [initialStateRow];

const createStyles = makeStyles({
    iconRow: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
    addRow: {
        border: 'none',
    },
});

const localBottomPadding = 156;

interface StateModel {
    saveLabsRequest: RequestState;
}

interface DispatchModel {
    saveLabs: typeof LabsActions.saveLabs;
    resetStatusVariables: typeof LabsActions.resetStatusVariables;
}

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const AddLabs: React.FC<PropsTypes> = props => {
    const [height, setHeight] = useState(0);
    const isLaptop = useMediaQuery(mediaQuery.laptop);
    const listRef = useRef(null);

    const [error, setError] = useState(false);

    const [table, setTable] = useState<LabRow[]>(initialState);
    const classes = createStyles(props);

    const { saveLabs, saveLabsRequest, resetStatusVariables } = props;

    const changeRow = useCallback(
        (filedName: string, value: string, id: number) => {
            setError(false);
            const currentRow = table.filter(r => r.id === id)[0];
            const index = table.indexOf(currentRow);
            const newTable = [...table];
            newTable[index] = { ...currentRow, [filedName]: value };
            setTable(newTable);
        },
        [table]
    );

    const removeRow = useCallback(
        (id: number) => {
            const currentRow = table.filter(r => r.id === id)[0];
            const index = table.indexOf(currentRow);
            setTable([...table.slice(0, index), ...table.slice(index + 1)]);
        },
        [table]
    );

    const addRow = useCallback(() => {
        const lastIndex = table[table.length - 1].id;
        const newRow = { ...initialStateRow, id: lastIndex + 1 };
        setTable([...table, newRow]);
    }, [table]);

    const canRemoveRow = useMemo(() => {
        return table.length > 1;
    }, [table]);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height =
                window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top - localBottomPadding;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    const isFormValid = useMemo(() => {
        let valid = false;
        table.forEach(row => {
            if (row.name.length > 0) {
                valid = true;
            }
        });
        return valid;
    }, [table]);

    const getValidLabs = useCallback(() => {
        return table.filter(row => row.name.length > 0);
    }, [table]);

    const save = useCallback(() => {
        if (isFormValid) {
            saveLabs(getValidLabs());
        } else {
            setError(true);
        }
    }, [isFormValid, getValidLabs, saveLabs]);

    useEffect(() => {
        if (saveLabsRequest === RequestState.SENT_SUCCESS) {
            props.history.push('/admin/labs');
        }
    }, [saveLabsRequest, props.history]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    return (
        <>
            {saveLabsRequest === RequestState.SENDING && <LoadingTransparent />}
            <HeaderHeadingWithLink>Add Labs</HeaderHeadingWithLink>
            <Wrapper ref={listRef} height={height}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {tableCols.map((item, index) => (
                                <TableCell key={index}>
                                    <Typography variant={'button'} key={index}>
                                        {item}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table.map((row, index) => (
                            <AddLabRow
                                key={index}
                                row={row}
                                changeRow={changeRow}
                                removeRow={removeRow}
                                canRemoveRow={canRemoveRow}
                            />
                        ))}
                        <TableRow>
                            <TableCell className={classes.addRow}>
                                <img src={addRowIcon} alt="" className={classes.iconRow} onClick={addRow} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Wrapper>
            <ButtonWrapper>
                <PrimaryButton onClick={save}>Save Labs</PrimaryButton>
            </ButtonWrapper>

            <CancelLink/>

            <Snackbar
                message={'Fill at least 1 row in the table'}
                open={error}
                variant={'error'}
                type={SnackbarTypes.ERROR}
            />
        </>
    );
};

export default connect(
    (state: RootStateModel.RootStateModel): StateModel => ({
        saveLabsRequest: state.lab.saveLabsRequest,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        saveLabs: bindActionCreators(LabsActions.saveLabs, dispatch),
        resetStatusVariables: bindActionCreators(LabsActions.resetStatusVariables, dispatch),
    })
)(withRouter(AddLabs));
