import React  from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import WeightManagementSettings from '../weightManagement/components/weightManagementSettings';

const Settings = () => {
    return (
        <>
            <HeaderHeadingWithLink>Settings</HeaderHeadingWithLink>
            <WeightManagementSettings/>
        </>
    );
};

export default Settings;
