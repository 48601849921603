import React, {useCallback} from 'react';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { colors } from '../../../theme/colors';
import { DrugInAnalytics } from '../../../models/responses/drugInAnalytics.model';
import { DataRange } from '../../../store/reducers/analytics';
import {formatDateTick, formatDateToolTip, isInvalid} from '../../../helpers/dateFormatHelper';

const Wrapper = styled.div`
    svg {
        overflow: scroll !important;
    }
`;

interface InjectedProps {
    drugsList: DrugInAnalytics[];
    date: DataRange;
}

type PropsTypes = InjectedProps;

const DrugsDiagram: React.FC<PropsTypes> = props => {

    const {drugsList} = props;

    const styles = {
        fontFamily: 'Poppins-Semibold',
        height: 'auto',
    };

    const getFormatDate = useCallback((date) => {
        return isInvalid(date) ? '-' : formatDateToolTip(Number(date));
    }, []);

    return (
        <Wrapper>
            <BarChart width={730} height={135} data={drugsList} layout="vertical" style={styles}>
                <CartesianGrid stroke={colors.white} fill={colors.gray2} />
                <XAxis
                    tickFormatter={formatDateTick}
                    tick={{ fontSize: 12, fill: colors.textSecondary }}
                    stroke={colors.gray2}
                    type="number"
                    domain={[Number(props.date.startDate), Number(props.date.endDate)]}
                />
                <YAxis
                    width={85}
                    type="category"
                    dataKey={'name'}
                    tick={{ fontSize: 10, fill: colors.textSecondary }}
                    stroke={colors.gray2}
                />
                <Bar dataKey="date" fill="#9E0000" barSize={4} radius={5} />
                <Tooltip
                    formatter={(value, name, props) => {
                        // @ts-ignore
                        return [getFormatDate(value[1]), getFormatDate(value[0])];
                    }}
                />
            </BarChart>
        </Wrapper>
    );
};

export default DrugsDiagram;
