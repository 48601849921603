import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import CustomReportTable from '../../uploadResults/CustomReportTable';
import { BloodtargetReponce } from '../../../models/responses/bloodtarget.reponce';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as BloodTargetActions } from '../../../store/actions/bloodTarget.actions';
import Loading from '../../shared/Loading';
import AutocompleteSingle from '../../../shared/input/AutocompleteSingle';
import { PanelTypeModel } from '../../../models/responses/panelType.model';
import { Actions as PanelTypeActions } from '../../../store/actions/panelType.actions';
import TextInput from '../../../shared/input/TextInput';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { Row } from '../AddBloodwork';
import { checkIfStringContains } from '../../../helpers/stringFilterHelper';
import {TargetLayout} from "../../../models/dto/uploadLabWorkPatient.dto";

const FormWrapper = styled.div`
    width: 100%;
    padding: 16px 0 32px;
    > div:first-child {
        margin-bottom: 8px;
    }
`;

const TableWrapper = styled.div`
    width: 100%;
    padding: 8px 0 0;
`;

interface StoreModel {
    allBloodTargets: BloodtargetReponce[];
    loading: boolean;
    panelTypes: PanelTypeModel[];
}

interface DispatchModel {
    getAllBloodTargets: typeof BloodTargetActions.getAllBloodTargets;
    getPanelType: typeof PanelTypeActions.getPanelType;
}

interface InjectedProps {
    table: Row[];
    targetValues: TargetLayout[];
    setTargetValues: (value: TargetLayout[]) => void;
}

type PropsTypes = StoreModel & DispatchModel & InjectedProps;

const BloodworkCustomReportTable: React.FC<PropsTypes> = props => {
    const {
        table,
        allBloodTargets,
        getAllBloodTargets,
        loading,
        getPanelType,
        panelTypes,
        targetValues,
        setTargetValues,
    } = props;

    const [panel, setPanel] = useState<null | BaseDropdownItem>(null);
    const [name, setName] = useState('');
    const [targetsToShow, setTargetsToShow] = useState();

    const loadTargets = useCallback(
        (page: number, size: number, sort: string, searchQuery: string, overrideRecords: boolean) => {
            getAllBloodTargets({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllBloodTargets]
    );

    useEffect(() => {
        const result = table.filter(row => {
            return panel
                ? row.panelTypeId === panel.id && checkIfStringContains(row.target, name)
                : checkIfStringContains(row.target, name);
        });
        setTargetsToShow(result);
    }, [name, panel, table]);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadTargets(0, 100, '', '', true);
    }, [loadTargets]);

    useEffect(() => {
        getPanelType();
    }, [getPanelType]);

    const isFilteringNow = useMemo(()=>{
        return !!panel || !!name.length;
    },[panel, name]);

    return (
        <>
            {loading && <Loading smallSize />}
            {!loading && !!allBloodTargets.length && (
                <>
                    <FormWrapper>
                        <AutocompleteSingle
                            value={panel && panel.id}
                            options={panelTypes}
                            label={'Panel Types'}
                            onChange={(value: BaseDropdownItem | null) => setPanel(value)}
                        />
                        <TextInput
                            placeholder={'Name'}
                            value={name}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                setName(evt.target.value);
                            }}
                        />
                    </FormWrapper>
                    <TableWrapper>
                        <CustomReportTable
                            targetsToShow={targetsToShow}
                            table={table}
                            allBloodTargets={allBloodTargets}
                            isFilteringNow={isFilteringNow}
                            values={targetValues}
                            setValues={setTargetValues}
                        />
                    </TableWrapper>
                </>
            )}
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        allBloodTargets: store.bloodTarget.allBloodTargets,
        loading: store.bloodTarget.loading,
        panelTypes: store.panelType.panelTypes,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllBloodTargets: bindActionCreators(BloodTargetActions.getAllBloodTargets, dispatch),
        getPanelType: bindActionCreators(PanelTypeActions.getPanelType, dispatch),
    })
)(BloodworkCustomReportTable);
