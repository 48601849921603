import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import removeRowIcon from '../../static/icons/removeRow.svg';
import TextInput from '../../shared/input/TextInput';
import { BloodTargetRow } from './AddBloodTargets';
import { ValidationErrorsMap } from '../../models/validation/validationErrorsMap';
import { useDebounce } from 'use-debounce';
import fillClassInstanceWithValues from '../../shared/utils/fillClassInstanceWithValues';
import { validateSync } from 'class-validator';
import { AddBloodTargetDto } from '../../models/dto/AddBloodTarget.dto';
import { colors } from '../../theme/colors';
import styled from 'styled-components';
import { PanelTypeModel } from '../../models/responses/panelType.model';
import { DosageUnitOptions } from '../../constants/dosage/dosageUnitOptions';
import AutocompleteSingle from '../../shared/input/AutocompleteSingle';
import rangeValid from '../../shared/utils/validation/range-validation';
import {BaseDropdownItem} from "../../models/responses/baseModel.responce";

const CellWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    > div {
        width: 49%;
    }
`;

const AutocompleteWrapper = styled.div`
    height: 24px;
`;

export enum FormFields {
    target = 'target',
    label = 'label',
    bloodTargetPanelType = 'bloodTargetPanelType',
    bloodTargetPanelTypeId = 'bloodTargetPanelTypeId',
    femaleMinRange = 'femaleMinRange',
    femaleMaxRange = 'femaleMaxRange',
    maleMinRange = 'maleMinRange',
    maleMaxRange = 'maleMaxRange',
    unit = 'unit',
}

const createStyles = makeStyles({
    cell: {
        minWidth: '125px',
    },
    rangeCell: {
        minWidth: '218px',
    },
    iconRow: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
    rangeLabel: {
        color: colors.textSecondary,
        fontSize: '12px',
        marginBottom: '8px',
        display: 'block',
    },
    slider: {
        padding: 0,
        marginBottom: '-10px',
    },
});

interface InjectedProps {
    changeRow: (filedName: string, value: string | number | number[] | PanelTypeModel | null, id: number) => void;
    removeRow: (id: number) => void;
    canRemoveRow: boolean;
    row: BloodTargetRow;
    panelTypes: PanelTypeModel[];
}

const AddBloodTargetRow: React.FC<InjectedProps> = props => {
    const { canRemoveRow, removeRow, row, changeRow, panelTypes } = props;
    const classes = createStyles(props);

    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    const [femaleRangeValid, setFemaleRangeValid] = useState(true);
    const [maleRangeValid, setMaleRangeValid] = useState(true);

    const validateMinMaxValues = useCallback(
        (row: BloodTargetRow) => {
            if (row.isBeingEdited.has('femaleMinRange') && row.isBeingEdited.has('femaleMaxRange')) {
                rangeValid(row.femaleMinRange, row.femaleMaxRange)
                    ? setFemaleRangeValid(true)
                    : setFemaleRangeValid(false);
            }
            if (row.isBeingEdited.has('maleMinRange') && row.isBeingEdited.has('maleMaxRange')) {
                rangeValid(row.maleMinRange, row.maleMaxRange) ? setMaleRangeValid(true) : setMaleRangeValid(false);
            }
        },
        []
    );

    useEffect(() => {
        validateForm(row);
        validateMinMaxValues(row);
    }, [row, validateMinMaxValues]);

    const validateForm = (user: any, isOnSubmit = false) => {
        const item = fillClassInstanceWithValues<AddBloodTargetDto>(AddBloodTargetDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                    map[err.property] = Object.entries(err.constraints)
                        .map(([, value]) => value)
                        .join(', ');
                }
            });
            setValidationErrorsMap(map);
        }
        setValidationErrorsMap(map);
        return map;
    };

    const changeForm = (fieldName: string, value: string | number | number[] | PanelTypeModel | null) => {
        changeRow(fieldName, value, row.id);
    };

    const remove = useCallback(() => {
        removeRow(row.id);
    }, [row, removeRow]);

    return (
        <TableRow>
            <TableCell>
                {canRemoveRow && <img src={removeRowIcon} alt="" className={classes.iconRow} onClick={remove} />}
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Target'}
                    value={row.target}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.target, evt.target.value)
                    }
                    error={!!validation.target}
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Label'}
                    value={row.label}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.label, evt.target.value)
                    }
                    error={!!validation.label}
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <AutocompleteWrapper>
                    <AutocompleteSingle
                        value={row.bloodTargetPanelTypeId}
                        options={panelTypes}
                        label={'Panel Type'}
                        placeholder={'Panel Type'}
                        onChange={(value: BaseDropdownItem | null) => {
                            changeForm(FormFields.bloodTargetPanelTypeId, value ? value.id : null);
                        }}
                        isOptionIdString
                        error={!!validation.bloodTargetPanelTypeId}
                    />
                </AutocompleteWrapper>
            </TableCell>
            <TableCell classes={{ root: classes.rangeCell }}>
                <CellWrapper>
                    <TextInput
                        placeholder={'Female Min'}
                        value={row.femaleMinRange}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeForm(FormFields.femaleMinRange, evt.target.value)
                        }
                        error={!!validation.femaleMinRange || !femaleRangeValid}
                        number
                    />
                    <TextInput
                        placeholder={'Female max'}
                        value={row.femaleMaxRange}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeForm(FormFields.femaleMaxRange, evt.target.value)
                        }
                        error={!!validation.femaleMaxRange || !femaleRangeValid}
                        number
                    />
                </CellWrapper>
            </TableCell>
            <TableCell classes={{ root: classes.rangeCell }}>
                <CellWrapper>
                    <TextInput
                        placeholder={'Male Min'}
                        value={row.maleMinRange}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeForm(FormFields.maleMinRange, evt.target.value)
                        }
                        error={!!validation.maleMinRange || !maleRangeValid}
                        number
                    />
                    <TextInput
                        placeholder={'Male max'}
                        value={row.maleMaxRange}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeForm(FormFields.maleMaxRange, evt.target.value)
                        }
                        error={!!validation.maleMaxRange || !maleRangeValid}
                        number
                    />
                </CellWrapper>
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <AutocompleteWrapper>
                    <AutocompleteSingle
                        value={row.unit}
                        options={DosageUnitOptions}
                        label={'Unit'}
                        placeholder={'Unit'}
                        onChange={(value: BaseDropdownItem | null) => changeForm(FormFields.unit, value ? value.id : '')}
                        isOptionIdString
                        error={!!validation.unit}
                    />
                </AutocompleteWrapper>
            </TableCell>
        </TableRow>
    );
};

export default AddBloodTargetRow;
