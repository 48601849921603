import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { PatientModel } from '../../models/responses/patient.response';
import { PatientDto } from '../../models/dto/patient.dto';
import { UploadAvatarDto } from '../../models/dto/uploadAvatar.dto';
import { LinkingStatusModel } from '../../models/responses/linkingStatus.response';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { MeasurementSystemDto } from '../../models/dto/measurementSystem.dto';

export enum ActionNames {
    GET_ALL_PATIENTS = '[Patients] Get All Patients',
    GET_ALL_PATIENTS_UPDATE_SUCCESS = '[Patients] Get All Patients Update Success',
    GET_ALL_PATIENTS_OVERRIDE_SUCCESS = '[Patients] Get All Patients Override Success',
    GET_ALL_PATIENTS_FAILED = '[Patients] Get All Patients Failed',
    INCREASE_PAGE_NUMBER_ALL_PATIENTS = '[Patients] Increase Page Number All Patients',
    SET_FILTER_QUERY_ALL_PATIENTS = '[Patients] Set Filter Query All Patients',

    GET_PATIENT = '[Patient] Get Patient',
    GET_PATIENT_SUCCESS = '[Patient] Get Patient Success',
    GET_PATIENT_FAILED = '[Patient] Get Patient Failed',

    GET_PATIENT_BY_ID = '[Patient] Get Patient By Id',
    GET_PATIENT_BY_ID_SUCCESS = '[Patient] Get Patient By Id Success',
    GET_PATIENT_BY_ID_FAILED = '[Patient] Get Patient By Id Failed',

    GET_LINKING_STATUS = '[Patient] Get Linking Status',
    GET_LINKING_STATUS_SUCCESS = '[Patient] Get Linking Status Success',
    GET_LINKING_STATUS_FAILED = '[Patient] Get Linking Status Failed',

    UPDATE_PATIENT = '[Patient] Update Patient',
    UPDATE_PATIENT_SUCCESS = '[Patient] Update Patient Success',
    UPDATE_PATIENT_FAILED = '[Patient] Update Patient Failed',

    REJECT_PATIENT_REQUEST = '[Patient] Reject Patient Request',
    REJECT_PATIENT_REQUEST_SUCCESS = '[Patient] Reject Patient Request Success',
    REJECT_PATIENT_REQUEST_FAILED = '[Patient] Reject Patient Request Failed',

    APPROVE_PATIENT_REQUEST = '[Patient] Approve Patient Request',
    APPROVE_PATIENT_REQUEST_SUCCESS = '[Patient] Approve Patient Request Success',
    APPROVE_PATIENT_REQUEST_FAILED = '[Patient] Approve Patient Request Failed',

    UPLOAD_AVATAR = '[Patient] Upload Avatar',
    UPLOAD_AVATAR_SUCCESS = '[Patient] Upload Avatar Success',
    UPLOAD_AVATAR_FAILED = '[Patient] Upload Avatar Failed',

    RESET_STATUS_VARIABLES = '[Patient] Reset Status Variables',

    PATIENT_SET_TAB_INDEX = '[Patient] Set Tab Index',

    SET_IS_SETUP_TO_TRUE = '[Patient] Set Is Setup To True',

    SETUP_MEASUREMENT_SYSTEM = '[Patient] Setup Measurement System',
    SETUP_MEASUREMENT_SYSTEM_SUCCESS = '[Patient] Setup Measurement System Success',
    SETUP_MEASUREMENT_SYSTEM_FAILED = '[Patient] Setup Measurement System Failed',
}

export const Actions = {
    getAllPatients: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_PATIENTS, data),
    getAllPatientsUpdateSuccess: (data: PatientModel[], total: number) =>
        createAction(ActionNames.GET_ALL_PATIENTS_UPDATE_SUCCESS, { data, total }),
    getAllPatientsOverrideSuccess: (data: PatientModel[], total: number) =>
        createAction(ActionNames.GET_ALL_PATIENTS_OVERRIDE_SUCCESS, { data, total }),
    getAllPatientsFailed: () => createAction(ActionNames.GET_ALL_PATIENTS_FAILED),

    increasePageNumberAllPatients: () => createAction(ActionNames.INCREASE_PAGE_NUMBER_ALL_PATIENTS),
    setFilterQueryAllPatients: (searchQuery: string) =>
        createAction(ActionNames.SET_FILTER_QUERY_ALL_PATIENTS, searchQuery),

    getPatient: () => createAction(ActionNames.GET_PATIENT),
    getPatientSuccess: (patient: PatientModel) => createAction(ActionNames.GET_PATIENT_SUCCESS, patient),
    getPatientFailed: () => createAction(ActionNames.GET_PATIENT_FAILED),

    getPatientById: (id: number) => createAction(ActionNames.GET_PATIENT_BY_ID, id),
    getPatientByIdSuccess: (patient: PatientModel) => createAction(ActionNames.GET_PATIENT_BY_ID_SUCCESS, patient),
    getPatientByIdFailed: () => createAction(ActionNames.GET_PATIENT_BY_ID_FAILED),

    getLinkingStatus: () => createAction(ActionNames.GET_LINKING_STATUS),
    getLinkingStatusSuccess: (data: LinkingStatusModel) => createAction(ActionNames.GET_LINKING_STATUS_SUCCESS, data),
    getLinkingStatusFailed: () => createAction(ActionNames.GET_LINKING_STATUS_FAILED),

    updatePatient: (patient: PatientDto) => createAction(ActionNames.UPDATE_PATIENT, patient),
    updatePatientSuccess: (patient: PatientModel) => createAction(ActionNames.UPDATE_PATIENT_SUCCESS, patient),
    updatePatientFailed: () => createAction(ActionNames.UPDATE_PATIENT_FAILED),

    uploadAvatar: (avatar: UploadAvatarDto) => createAction(ActionNames.UPLOAD_AVATAR, avatar),
    uploadAvatarSuccess: () => createAction(ActionNames.UPLOAD_AVATAR_SUCCESS),
    uploadAvatarFailed: () => createAction(ActionNames.UPLOAD_AVATAR_FAILED),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),

    setTabIndex: (index: number) => createAction(ActionNames.PATIENT_SET_TAB_INDEX, index),

    setIsSetupToTrue: () => createAction(ActionNames.SET_IS_SETUP_TO_TRUE),

    rejectPatientRequest: (id: number) => createAction(ActionNames.REJECT_PATIENT_REQUEST, id),
    rejectPatientRequestSuccess: () => createAction(ActionNames.REJECT_PATIENT_REQUEST_SUCCESS),
    rejectPatientRequestFailed: () => createAction(ActionNames.REJECT_PATIENT_REQUEST_FAILED),

    approvePatientRequest: (id: number) => createAction(ActionNames.APPROVE_PATIENT_REQUEST, id),
    approvePatientRequestSuccess: () => createAction(ActionNames.APPROVE_PATIENT_REQUEST_SUCCESS),
    approvePatientRequestFailed: () => createAction(ActionNames.APPROVE_PATIENT_REQUEST_FAILED),

    setupMeasurementSystem: (dto: MeasurementSystemDto) => createAction(ActionNames.SETUP_MEASUREMENT_SYSTEM, dto),
    setupMeasurementSystemSuccess: () => createAction(ActionNames.SETUP_MEASUREMENT_SYSTEM_SUCCESS),
    setupMeasurementSystemFailed: () => createAction(ActionNames.SETUP_MEASUREMENT_SYSTEM_FAILED),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getALLPatientsType = ActionsOfType<Actions, ActionNames.GET_ALL_PATIENTS>;
export type getPatientType = ActionsOfType<Actions, ActionNames.GET_PATIENT>;
export type getPatientByIdType = ActionsOfType<Actions, ActionNames.GET_PATIENT_BY_ID>;
export type getLinkingStatusType = ActionsOfType<Actions, ActionNames.GET_LINKING_STATUS>;
export type updatePatientType = ActionsOfType<Actions, ActionNames.UPDATE_PATIENT>;
export type uploadAvatarType = ActionsOfType<Actions, ActionNames.UPLOAD_AVATAR>;
export type setIsSetupToTrueType = ActionsOfType<Actions, ActionNames.SET_IS_SETUP_TO_TRUE>;
export type rejectPatientRequestType = ActionsOfType<Actions, ActionNames.REJECT_PATIENT_REQUEST>;
export type approvePatientRequestType = ActionsOfType<Actions, ActionNames.APPROVE_PATIENT_REQUEST>;
export type setupMeasurementSystem = ActionsOfType<Actions, ActionNames.SETUP_MEASUREMENT_SYSTEM>;
