import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';

const createStyles = makeStyles({
    text: {
        margin: '20px 0',
    },
});

const NoData: React.FC = props => {
    const classes = createStyles(props);
    return (
        <SizeControllerWrapper minHeight>
            <Typography variant={'body1'} classes={{ root: classes.text }}>
                No invoices.
            </Typography>
        </SizeControllerWrapper>
    );
};

export default NoData;
