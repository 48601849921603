import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import PrimaryButton from '../../shared/button/PrimaryButton';
import SecondaryButton from '../../shared/button/SecondaryButton';
import styled from 'styled-components';
import DatePicker from '../../shared/input/DatePicker';
import { RouteComponentProps, withRouter } from 'react-router';
import removeFileIcon from '../../static/icons/removeFile.svg';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as LabworkActions } from '../../store/actions/labwork.actions';
import LoadingTransparent from '../shared/LoadingTransparent';
import RequestState from '../../constants/requestState';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import Snackbar, { SnackbarTypes } from '../../shared/snackbars/snackbar';
import { Target } from '../../models/dto/uploadLabWorkProvider.dto';
import { DropdownInputDataTypes } from '../../constants/dropdownInputDataTypes';
import DropdownInputEntitiesWrapper from '../../shared/input/DropdownInputEntitiesWrapper';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';
import { Actions as BloodTargetActions } from '../../store/actions/bloodTarget.actions';
import { BloodtargetReponce } from '../../models/responses/bloodtarget.reponce';
import { BloodworkResponse } from '../../models/responses/bloodwork.response';
import BloodworkCustomReportTable from './components/BloodworkCustomReportTable';
import { TargetLayout } from '../../models/dto/uploadLabWorkPatient.dto';

const createStyles = makeStyles({
    header: {
        fontSize: '24px',
        color: colors.textPrimary,
    },
    backLink: {
        textDecoration: 'underline',
        textDecorationColor: colors.gray1,
        color: colors.gray1,
        marginBottom: '36px',
        cursor: 'pointer',
    },
    labelText: {
        color: colors.gray1,
        fontSize: '10px',
        textAlign: 'left',
        marginLeft: '12px',
        marginTop: '2px',
    },
    removeFileIcon: {
        marginBottom: '-1px',
        marginLeft: '8px',
        cursor: 'pointer',
    },
    textFieldRoot: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            padding: '15px 12px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
    },
    hintText: {
        color: colors.textSecondary,
        fontSize: '12px',
        lineHeight: '16px',
        margin: '12px 0 14px',
    },
    fileName: {
        cursor: 'pointer',
    },
});

const Wrapper = styled.div`
    height: calc(100vh - 214px);
    padding: 13vh 0 37px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (orientation: landscape) {
        height: auto;
    }
`;

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
`;

const InputFields = styled.div`
    width: 100%;
`;

const SelectButtonWrapper = styled.div`
    margin: 16px 0 30px;
`;

const UploadButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    @media screen and (orientation: landscape) {
        margin: 16px 0;
    }
`;

const FileWrapper = styled.div`
    height: 14px;
    margin-top: 14px;
`;

export interface Row {
    id: number;
    target: string;
    value: string;
    unit: string;
    panelTypeId: number | null;
}

const emptyRow = { target: '', value: '', unit: '', id: 0, panelTypeId: null };

const initialState = [emptyRow];

interface StoreModel {
    loadingLabwork: boolean;
    loadingBloodTargets: boolean;
    uploadReportStatus: RequestState;
    updateReportStatus: RequestState;
    user: UserModel | null;
    allBloodTargets: BloodtargetReponce[];
    currentBloodwork: BloodworkResponse | null;
}

interface DispatchModel {
    uploadBloodworkPatient: typeof LabworkActions.uploadBloodworkPatient;
    uploadBloodworkProvider: typeof LabworkActions.uploadBloodworkProvider;
    resetStatusVariables: typeof LabworkActions.resetStatusVariables;
    getAllBloodTargets: typeof BloodTargetActions.getAllBloodTargets;
    getBloodLabworkPatient: typeof LabworkActions.getBloodLabworkPatient;
    updateBloodworkPatient: typeof LabworkActions.updateBloodworkPatient;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const ErrorMessage = {
    LAB_INVALID: 'Select Lab please',
    DATE_INVALID: 'Fill Report Date please',
    LAB_AND_DATE_INVALID: 'Fill Report Date and Lab fields',
    TARGET_EMPTY: 'Fill at least 1 Target in the form',
    ALL_EMPTY: 'Fill the fields in the form',
};

const AddBloodwork: React.FC<PropsTypes> = props => {
    const [reportDate, setReportDate] = useState<Date | null>(null);
    const [reportDateValid, setReportDateValid] = React.useState<boolean | null>(null);

    const [labId, setLabId] = useState<number | null>(null);
    const [labValid, setLabValid] = React.useState<boolean | null>(null);

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');

    const [tableReport, setTableReport] = useState<Row[]>(initialState);
    const [targetValues, setTargetValues] = useState<TargetLayout[]>([]);

    const [tableReportError, setTableReportError] = useState('');

    const {
        currentBloodwork,
        getBloodLabworkPatient,
        updateBloodworkPatient,
        uploadReportStatus,
        updateReportStatus,
        resetStatusVariables,
        loadingLabwork,
        match,
        allBloodTargets,
        uploadBloodworkPatient,
        uploadBloodworkProvider,
        user,
        history,
    } = props;

    const isEditMode = useMemo(() => {
        return !!match.params.id;
    }, [match]);

    useEffect(() => {
        if (isEditMode) {
            getBloodLabworkPatient(match.params.id);
        }
    }, [isEditMode, getBloodLabworkPatient, match]);

    useEffect(() => {
        if (currentBloodwork) {
            setReportDate(new Date(currentBloodwork.reportDate));
            setLabId(currentBloodwork.labId);
            currentBloodwork.fileUrl && setFileName('Report File');
        }
    }, [currentBloodwork]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    const filledTargets = useMemo(() => {
        const filledTargets: { [key: string]: number } = {};
        if (currentBloodwork) {
            currentBloodwork.panels.forEach(panel => {
                Object.entries(panel.currentResult).forEach(([key, value]) => (filledTargets[key] = value));
            });
        }
        return filledTargets;
    }, [currentBloodwork]);

    useEffect(() => {
        const table: Row[] = [];
        const targetValues: TargetLayout[] = [];
        allBloodTargets.forEach((target) => {
            const value = filledTargets.hasOwnProperty(target.target) ? filledTargets[target.target].toString() : '';
            table.push({
                target: target.target,
                value,
                unit: target.unit,
                id: target.id,
                panelTypeId: target.bloodTargetPanelTypeId,
            });
            if (value) {
                targetValues.push({ id: target.id, target: target.target, value });
            }
        });
        setTableReport(table);
        setTargetValues(targetValues);
    }, [allBloodTargets, filledTargets]);

    const downloadFile = useCallback(() => {
        currentBloodwork && currentBloodwork.fileUrl && window.open(currentBloodwork.fileUrl);
    }, [currentBloodwork]);

    useEffect(() => {
        if (uploadReportStatus === RequestState.SENT_SUCCESS || updateReportStatus === RequestState.SENT_SUCCESS) {
            if (user && user.role === RoleOptions.PROVIDER) {
                history.push(`/profile-patient/${match.params.patientId}`);
            } else {
                history.push('/bloodwork-reports');
            }
        }
    }, [uploadReportStatus, updateReportStatus, history, match, user]);

    const changeFile = (evt: React.ChangeEvent<any>) => {
        if (evt.target.files.length > 0) {
            setFileName(evt.target.files[0].name);
            setFile(evt.target.files[0]);
        }
    };

    const validateForm = () => {
        const tableErr: { status: boolean; errMessage: string } = isTableReportValid();
        const formErr: string = isFormValid();

        if (tableErr.errMessage === ErrorMessage.TARGET_EMPTY && formErr === ErrorMessage.LAB_AND_DATE_INVALID) {
            setTableReportError(ErrorMessage.ALL_EMPTY);
        }
    };

    const isDateValid = (value: string | Date | null) => {
        return !!value && value !== 'Invalid Date' && value <= new Date();
    };

    const isFormValid = () => {
        let errMessage = '';
        const dateValid = isDateValid(reportDate);
        setReportDateValid(dateValid);
        setLabValid(!!labId);
        if (!dateValid && labId) {
            errMessage = ErrorMessage.DATE_INVALID;
        }
        if (dateValid && !labId) {
            errMessage = ErrorMessage.LAB_INVALID;
        }
        if (!dateValid && !labId) {
            errMessage = ErrorMessage.LAB_AND_DATE_INVALID;
        }
        setTableReportError(errMessage);

        return errMessage;
    };

    const isTableReportValid = () => {
        let errMessage = '';
        let valid = false;
        targetValues.forEach(row => {
            if (row.target && row.target.trim().length > 0 && row.value.trim().length > 0) {
                valid = true;
            }
        });

        if (!valid) {
            errMessage = ErrorMessage.TARGET_EMPTY;
        }
        setTableReportError(errMessage);

        return { status: valid, errMessage };
    };

    const shouldUpdateFile = useMemo(() => {
        return !(fileName.length && !file);
    }, [fileName, file]);

    const uploadReport = () => {
        validateForm();
        if (reportDate && labId && isTableReportValid().status) {
            const tableTargets: Target[] = targetValues.map(t => {
                return { ...t, value: Number(t.value) };
            });

            if (user && user.role === RoleOptions.PROVIDER) {
                uploadBloodworkProvider({
                    reportDate: reportDate.toISOString(),
                    file,
                    labId,
                    patientId: match.params.patientId,
                    targets: tableTargets,
                });
            } else {
                currentBloodwork
                    ? updateBloodworkPatient({
                          id: currentBloodwork.labWorkId,
                          reportDate: reportDate.toISOString(),
                          file,
                          shouldUpdateFile: shouldUpdateFile,
                          labId,
                          targets: tableTargets,
                      })
                    : uploadBloodworkPatient({
                          reportDate: reportDate.toISOString(),
                          file,
                          labId,
                          targets: tableTargets,
                      });
            }
        }
    };

    const removeFile = () => {
        setFileName('');
        setFile(null);
    };

    const isLoading = useMemo(() => {
        return (
            loadingLabwork || uploadReportStatus === RequestState.SENDING || updateReportStatus === RequestState.SENDING
        );
    }, [uploadReportStatus, loadingLabwork, updateReportStatus]);

    const changeTargetValues = useCallback((value: TargetLayout[]) => {
        setTargetValues(value);
        setTableReportError('');
    }, []);

    const classes = createStyles(props);
    return (
        <>
            <SizeControllerWrapper>
                <Wrapper>
                    <Typography variant="h2" classes={{ root: classes.header }}>
                        {isEditMode ? 'Edit Report' : 'Upload Results'}
                    </Typography>

                    <InputFields>
                        <InputWrapper>
                            <DatePicker
                                value={reportDate}
                                onChange={(date: Date | null) => {
                                    setReportDate(date);
                                    setTableReportError('');
                                    setReportDateValid(isDateValid(date));
                                }}
                                error={reportDateValid !== null && !reportDateValid}
                            />
                            <Typography variant="button" classes={{ root: classes.labelText }}>
                                Select Report Date
                            </Typography>
                        </InputWrapper>

                        <InputWrapper>
                            <DropdownInputEntitiesWrapper
                                onChange={(entity: BaseDropdownItem | null) => {
                                    setLabId(entity && entity.id);
                                    setTableReportError('');
                                    setLabValid(true);
                                }}
                                label={'Labs'}
                                type={DropdownInputDataTypes.LAB}
                                error={labValid !== null && !labValid}
                                value={{ id: labId, name: '' }}
                            />
                            <Typography variant="button" classes={{ root: classes.labelText }}>
                                Select Lab
                            </Typography>
                        </InputWrapper>
                    </InputFields>

                    <BloodworkCustomReportTable
                        table={tableReport}
                        targetValues={targetValues}
                        setTargetValues={changeTargetValues}
                    />
                    <Typography variant={'body1'} classes={{ root: classes.hintText }}>
                        Targets with empty values <br /> will not be attached to the report.
                    </Typography>

                    <FileWrapper>
                        <Typography variant="button" onClick={downloadFile} classes={{ root: classes.fileName }}>
                            {fileName}
                        </Typography>
                        {!!fileName.length && (
                            <img src={removeFileIcon} alt="" className={classes.removeFileIcon} onClick={removeFile} />
                        )}
                    </FileWrapper>

                    <SelectButtonWrapper>
                        <SecondaryButton
                            disabled={!!file}
                            onClick={() =>
                                !file &&
                                document.getElementById('fileInput') &&
                                document.getElementById('fileInput')!.click()
                            }
                        >
                            Attach Original Report
                        </SecondaryButton>
                    </SelectButtonWrapper>

                    <UploadButtonWrapper>
                        <PrimaryButton onClick={uploadReport}>Upload Report</PrimaryButton>
                    </UploadButtonWrapper>

                    <Typography variant="button" classes={{ root: classes.backLink }} onClick={() => history.goBack()}>
                        Cancel
                    </Typography>
                </Wrapper>
            </SizeControllerWrapper>

            <input
                type="file"
                multiple={false}
                id="fileInput"
                style={{ display: 'none' }}
                onChange={changeFile}
                onClick={(evt: any) => {
                    evt.target.value = null;
                }}
            />

            <Snackbar
                message={tableReportError}
                open={tableReportError.length > 0}
                variant={'error'}
                type={SnackbarTypes.ERROR}
            />

            {isLoading && <LoadingTransparent />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        loadingLabwork: state.labwork.loading,
        uploadReportStatus: state.labwork.uploadReportStatus,
        user: state.authorization.user,
        allBloodTargets: state.bloodTarget.allBloodTargets,
        loadingBloodTargets: state.bloodTarget.loading,
        currentBloodwork: state.labwork.currentBloodwork,
        updateReportStatus: state.labwork.updateReportStatus,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        uploadBloodworkPatient: bindActionCreators(LabworkActions.uploadBloodworkPatient, dispatch),
        uploadBloodworkProvider: bindActionCreators(LabworkActions.uploadBloodworkProvider, dispatch),
        resetStatusVariables: bindActionCreators(LabworkActions.resetStatusVariables, dispatch),
        getAllBloodTargets: bindActionCreators(BloodTargetActions.getAllBloodTargets, dispatch),
        getBloodLabworkPatient: bindActionCreators(LabworkActions.getBloodLabworkPatient, dispatch),
        updateBloodworkPatient: bindActionCreators(LabworkActions.updateBloodworkPatient, dispatch),
    })
)(withRouter(AddBloodwork));
