import { restAxios } from '../restAxios';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { GetAnalyticsPatientDto } from '../../models/dto/getAnalyticsPatient.dto';
import { GetAnalyticsProviderDto } from '../../models/dto/getAnalyticsProvider.dto';
import { GetAnalyticsPatientValid } from '../../models/dtoValidation/analytics.responce';

class AnalyticsApi {
    @HandleAsyncErrors()
    static async getAnalyticsPatient(data: GetAnalyticsPatientDto) {
        const { startDate, endDate } = data;
        const res = await restAxios.get('/analytics', { params: { startDate, endDate } });
        return new GetAnalyticsPatientValid(res.data);
    }

    @HandleAsyncErrors()
    static async getAnalyticsPatientProvider(data: GetAnalyticsProviderDto) {
        const { startDate, endDate } = data;
        return await restAxios.get(`/analytics/patient/${data.patientId}`, { params: { startDate, endDate } });
    }
}

export default AnalyticsApi;
