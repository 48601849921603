import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface InjectedProps {
    to: string;
    underlineColor?: string;
    onClick?: () => void;
}

type PropsType = InjectedProps;

const CustomLink: React.FC<PropsType> = props => {
    const style = useMemo(
        () => ({
            textDecoration: props.underlineColor ? 'underline' : 'none',
            textDecorationColor: props.underlineColor ? props.underlineColor : '',
        }),
        [props.underlineColor]
    );

    return (
        <Link to={props.to} style={style} onClick={props.onClick}>
            {props.children}
        </Link>
    );
};

export default CustomLink;
