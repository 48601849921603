import React from 'react';
import { ValidationErrorsMap } from '../../models/validation/validationErrorsMap';
import { FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../theme/colors';

const ErrorWrapper = styled.div`
    position: absolute;
    > p {
        color: ${colors.redMain};
    }
`;

const RenderErrorShared: React.FC<{ field: keyof ValidationErrorsMap; validationErrorsMap: any }> = ({
    field,
    validationErrorsMap,
}) => {
    return !!validationErrorsMap[field] ? (
        <ErrorWrapper>
            <FormHelperText>{validationErrorsMap[field].split(',')[0]}</FormHelperText>
        </ErrorWrapper>
    ) : null;
};

export default RenderErrorShared;
