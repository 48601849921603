import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';

export enum ActionNames {
    GET_ALL_PHARMACIES = '[Pharmacies] Get all Pharmacies',
    GET_ALL_PHARMACIES_UPDATE_SUCCESS = '[Pharmacies] Get all Pharmacies Update Success',
    GET_ALL_PHARMACIES_OVERRIDE_SUCCESS = '[Pharmacies] Get all Pharmacies Override Success',
    GET_ALL_PHARMACIES_FAILED = '[Pharmacies] Get all Pharmacies Failed',
    SET_SEARCH_QUERY = '[Pharmacies] Set Search Query Pharmacies',

    INCREASE_PAGE_NUMBER = '[Pharmacies] Increase Page Number',
}

export const Actions = {
    getAllPharmacies: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_PHARMACIES, data),
    getAllPharmaciesUpdateSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_PHARMACIES_UPDATE_SUCCESS, data),
    getAllPharmaciesOverrideSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_PHARMACIES_OVERRIDE_SUCCESS, data),
    getAllPharmaciesFailed: () => createAction(ActionNames.GET_ALL_PHARMACIES_FAILED),
    setSearchQuery: (query: string) => createAction(ActionNames.SET_SEARCH_QUERY, query),

    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllPharmaciesType = ActionsOfType<Actions, ActionNames.GET_ALL_PHARMACIES>;
