import moment from 'moment';

export const formatDate = (date: string | Date | null) => {
    return date ? moment(date).format('MMM DD, YYYY') : '-';
};

export const isInvalid = (date: string) => {
    return date.toLowerCase() === 'invalid date';
};

export const formatDateTick = (value: number) => {
    return moment(value).format('MM.DD');
};

export const formatDateToolTip = (value: number) => {
    return moment(value).format('MM.DD.YYYY');
};