import React, { useEffect, useRef } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';
import { colors } from '../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../shared/button/PrimaryButton';
import CustomLink from '../../shared/link/CustomLink';
import { connect } from 'react-redux';
import { ProviderModel } from '../../models/responses/provider.response';
import { Actions as ProviderActions } from '../../store/actions/provider.actions';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../shared/Loading';
import RequestState from '../../constants/requestState';
import { AcceptProviderStatus } from '../../constants/acceptProviderStatus';
import providerAvatar from '../../static/images/provider/providerAvatar.svg';
import { SearchRoutingForPatient } from '../../constants/tabRouting/routing';
import { mediaQuery } from '../../constants/device';
import CancelLink from "../../shared/link/CancelLink";

const createStyles = makeStyles({
    heading: {
        fontSize: '24px',
        color: colors.textPrimary,
        marginBottom: '-6px',
    },
    subHeading: {
        fontSize: '14px',
        color: colors.textSecondary,
    },
    redSubHeading: {
        fontSize: '14px',
        color: colors.redMain,
    },
});

export const PADDING_TOP = 25;
export const PADDING_BOTTOM = 30;

const Wrapper = styled.div`
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${PADDING_TOP}px 0 ${PADDING_BOTTOM}px;

    ${mediaQuery.laptop} {
        height: calc(100vh - 256px);
    }
`;

const Avatar = styled.div<any>`
    width: 198px;
    height: 198px;
    display: flex;
    justify-content: center;
    background-color: ${props =>
        props.requestStatus === AcceptProviderStatus.REJECTED ? 'rgba(102, 107, 128, 0.5)' : colors.textSecondary};
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100px;
    border: 1px solid ${colors.textSecondary};
    margin-top: 30px;
`;

const InfoWrapper = styled.div`
    text-align: center;
`;

const FooterWrapper = styled.div`
    width: 65%;
    height: 21px;
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
`;

type StateModel = {
    loading: boolean;
    currentProvider: ProviderModel | null;
    requestProviderSent: RequestState;
};

type DispatchModel = {
    getProviderById: typeof ProviderActions.getProviderById;
    requestProvider: typeof ProviderActions.requestProvider;
};

type PropsTypes = RouteComponentProps<any> & StateModel & DispatchModel;

const ProviderRequestLink: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const wrapperRef = useRef(null);
    const { loading, currentProvider, getProviderById, requestProvider, requestProviderSent, history, match } = props;

    useEffect(() => {
        if (requestProviderSent !== RequestState.UNSENT && requestProviderSent !== RequestState.SENDING) {
            requestProviderSent === RequestState.SENT_SUCCESS && history.push(`/provider-link-sent/${match.params.id}`);
        }
    }, [requestProviderSent, history, match]);

    useEffect(() => {
        getProviderById(match.params.id);
    }, [match, getProviderById]);

    useEffect(() => {
        if (
            currentProvider &&
            currentProvider.id.toString() === match.params.id &&
            currentProvider.status === AcceptProviderStatus.PENDING
        ) {
            history.push(`/provider-link-sent/${match.params.id}`);
        }
    }, [currentProvider, history, match]);

    const requestLink = () => {
        requestProvider(match.params.id);
    };

    return (
        <>
            <HeaderHeadingWithLink>Send Request</HeaderHeadingWithLink>
            {loading && <Loading />}
            {!loading && currentProvider && (
                <SizeControllerWrapper>
                    <Wrapper ref={wrapperRef}>
                        <Avatar requestStatus={currentProvider.status}>
                            <img
                                src={
                                    currentProvider.avatarThumbnailUrl
                                        ? currentProvider.avatarThumbnailUrl
                                        : providerAvatar
                                }
                                alt=""
                            />
                        </Avatar>
                        <InfoWrapper>
                            <Typography variant="h2" classes={{ root: classes.heading }}>
                                {currentProvider.firstName} {currentProvider.lastName}
                            </Typography>
                            {currentProvider.status === AcceptProviderStatus.REJECTED ? (
                                <Typography variant="body1" classes={{ root: classes.redSubHeading }}>
                                    Declined your request
                                </Typography>
                            ) : (
                                <Typography variant="body1" classes={{ root: classes.subHeading }}>
                                    {currentProvider.status === AcceptProviderStatus.APPROVED
                                        ? 'Accepted your request'
                                        : 'Doctor first Class'}
                                </Typography>
                            )}
                        </InfoWrapper>
                        {currentProvider.status !== AcceptProviderStatus.REJECTED &&
                            currentProvider.status !== AcceptProviderStatus.APPROVED && (
                                <PrimaryButton onClick={requestLink}>Send Request</PrimaryButton>
                            )}

                        {(currentProvider.status === AcceptProviderStatus.REJECTED ||
                            currentProvider.status === AcceptProviderStatus.APPROVED) && (
                            <CustomLink to={SearchRoutingForPatient[0].path}>
                                <PrimaryButton>Close</PrimaryButton>
                            </CustomLink>
                        )}
                        <FooterWrapper>
                            {currentProvider.status !== AcceptProviderStatus.REJECTED &&
                                currentProvider.status !== AcceptProviderStatus.APPROVED && (
                                    <CancelLink/>
                                )}
                        </FooterWrapper>
                    </Wrapper>
                </SizeControllerWrapper>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.provider.loading,
        currentProvider: state.provider.currentProvider,
        requestProviderSent: state.provider.requestProviderSent,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getProviderById: bindActionCreators(ProviderActions.getProviderById, dispatch),
        requestProvider: bindActionCreators(ProviderActions.requestProvider, dispatch),
    })
)(withRouter(ProviderRequestLink));
