import { restAxios } from '../restAxios';
import { PatientDto } from '../../models/dto/patient.dto';
import { UploadAvatarDto } from '../../models/dto/uploadAvatar.dto';
import { InvitePatientDto } from '../../models/dto/invitePatientDto';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import {
    GetPatientValid,
    GetLinkedPatientsValid,
    GetLinkingStatusValid,
    StringValidator,
} from '../../models/dtoValidation/patientApi.response';
import { MeasurementSystemDto } from '../../models/dto/measurementSystem.dto';

export interface PagingQueryParams {
    page?: number;
    size?: number;
    sort?: string;
    searchQuery?: string;
    overrideRecords?: boolean;
}

class PatientApi {
    @HandleAsyncErrors()
    static async getPatient() {
        const res = await restAxios.get('private/patients');
        return new GetPatientValid(res.data);
    }

    @HandleAsyncErrors()
    static async getPatientById(id: number) {
        const res = await restAxios.get(`private/patients/${id}`);
        return new GetPatientValid(res.data);
    }

    @HandleAsyncErrors()
    static async invitePatient(data: InvitePatientDto) {
        const res = await restAxios.post('private/patients/invite', data);
        return StringValidator(res.data);
    }

    @HandleAsyncErrors()
    static async getAllPatients({ page = 0, size = 20, sort, searchQuery }: PagingQueryParams) {
        const res = await restAxios.get('private/patients/all', { params: { page, size, sort, searchQuery } });
        return new GetLinkedPatientsValid(res.data);
    }

    @HandleAsyncErrors()
    static async getLinkedPatients({ page = 0, size = 20, sort, searchQuery }: PagingQueryParams) {
        const res = await restAxios.get('private/patients/linked', { params: { page, size, sort, searchQuery } });
        return new GetLinkedPatientsValid(res.data);
    }

    @HandleAsyncErrors()
    static async getPendingPatients({ page = 0, size = 20, sort, searchQuery }: PagingQueryParams) {
        const res = await restAxios.get('private/patients/pending', { params: { page, size, sort, searchQuery } });
        return new GetLinkedPatientsValid(res.data);
    }

    @HandleAsyncErrors()
    static async getInvitedPatients({ page = 0, size = 20, sort, searchQuery }: PagingQueryParams) {
        const res = await restAxios.get('private/patients/invited', { params: { page, size, sort, searchQuery } });
        return new GetLinkedPatientsValid(res.data);
    }

    @HandleAsyncErrors()
    static async updatePatient(data: PatientDto) {
        const res = await restAxios.patch('private/patients', data);
        return new GetPatientValid(res.data);
    }

    @HandleAsyncErrors()
    static async setupMeasurementSystem(data: MeasurementSystemDto) {
        return (await restAxios.patch('private/patients/units', data)).data;
    }

    @HandleAsyncErrors()
    static async uploadAvatar(data: UploadAvatarDto) {
        const formData = new FormData();
        formData.append('file', data.file);
        const res = await restAxios.patch(
            `private/patients/upload-avatar?height=${data.height}&width=${data.width}&x=${data.x}&y=${data.y}`,
            formData
        );
        return res.data;
    }

    static async setIsSetup() {
        const res = await restAxios.get('private/users/setup');
        return res.data;
    }

    @HandleAsyncErrors()
    static async getLinkingStatus() {
        const res = await restAxios.get(`provider-patient/linking-status`);
        return new GetLinkingStatusValid(res.data);
    }

    @HandleAsyncErrors()
    static async rejectPatientRequest(id: number) {
        const res = await restAxios.put(`private/patients/${id}/reject`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async approvePatientRequest(id: number) {
        const res = await restAxios.put(`private/patients/${id}/approve`);
        return StringValidator(res.data);
    }
}

export default PatientApi;
