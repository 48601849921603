import {ActionsOfType, ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {AllEntitiesByPagesDto} from "../../models/dto/allEntitiesByPages.dto";
import {ProviderModel} from "../../models/responses/provider.response";
import {ProviderDto} from "../../models/dto/provider.dto";
import {UploadAvatarDto} from "../../models/dto/uploadAvatar.dto";
import {SaveProviderDto} from "../../models/dto/saveProvider.dto";

export enum ActionNames {
    GET_ALL_PROVIDERS = '[Provider] Get All Providers',
    GET_ALL_PROVIDERS_UPDATE_SUCCESS = '[Provider] Get All Providers Update Success',
    GET_ALL_PROVIDERS_OVERRIDE_SUCCESS = '[Provider] Get All Providers Override Success',
    GET_ALL_PROVIDERS_FAILED = '[Provider] Get All Providers Failed',

    INCREASE_PAGE_NUMBER = '[Provider] Increase Page Number',

    SET_FILTER_QUERY = '[Provider] Set Filter Query',

    GET_PROVIDER = '[Provider] Get Provider',
    GET_PROVIDER_SUCCESS = '[Provider] Get Provider Success',
    GET_PROVIDER_FAILED = '[Provider] Get Provider Failed',

    GET_PROVIDER_BY_ID = '[Provider] Get Provider By Id',
    GET_PROVIDER_BY_ID_SUCCESS = '[Provider] Get Provider By Id Success',
    GET_PROVIDER_BY_ID_FAILED = '[Provider] Get Provider By Id Failed',

    UPDATE_PROVIDER = '[Provider] Update Provider',
    UPDATE_PROVIDER_SUCCESS = '[Provider] Update Provider Success',
    UPDATE_PROVIDER_FAILED = '[Provider] Update Provider Failed',

    UPDATE_PROVIDERS = '[Provider] Update Providers',
    UPDATE_PROVIDERS_SUCCESS = '[Provider] Update Providers Success',
    UPDATE_PROVIDERS_FAILED = '[Provider] Update Providers Failed',

    UPLOAD_AVATAR = '[Provider] Upload Avatar',
    UPLOAD_AVATAR_SUCCESS = '[Provider] Upload Avatar Success',
    UPLOAD_AVATAR_FAILED = '[Provider] Upload Avatar Failed',

    REQUEST_PROVIDER = '[Provider] Request Provider',
    REQUEST_PROVIDER_SUCCESS = '[Provider] Request Provider Success',
    REQUEST_PROVIDER_FAILED = '[Provider] Request Provider Failed',

    CANCEL_REQUEST_PROVIDER = '[Provider] Cancel Request Provider',
    CANCEL_REQUEST_PROVIDER_SUCCESS = '[Provider] Cancel Request Provider Success',
    CANCEL_REQUEST_PROVIDER_FAILED = '[Provider] Cancel Request Provider Failed',

    RESET_STATUS_VARIABLES = '[Provider] Reset Status Variables',
    RESET_FILTERS = '[Provider] Reset Filters',

    SAVE_PROVIDERS = '[Provider] Save Providers',
    SAVE_PROVIDERS_SUCCESS = '[Provider] Save Providers Success',
    SAVE_PROVIDERS_FAILED = '[Provider] Save Providers Failed',

    GET_NEW_PASSWORD_PROVIDER = '[Provider] Get New Password Provider',
    GET_NEW_PASSWORD_PROVIDER_SUCCESS = '[Provider] Get New Password Provider Success',
    GET_NEW_PASSWORD_PROVIDER_FAILED = '[Provider] Get New Password Provider Failed',
}

export const Actions = {
    getAllProviders: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_PROVIDERS, data),
    resetFilters: () => createAction(ActionNames.RESET_FILTERS),
    getAllProvidersUpdateSuccess: (data: { result: ProviderModel[], total: number }) => createAction(ActionNames.GET_ALL_PROVIDERS_UPDATE_SUCCESS, data),
    getAllProvidersOverrideSuccess: (data: { result: ProviderModel[], total: number }) => createAction(ActionNames.GET_ALL_PROVIDERS_OVERRIDE_SUCCESS, data),
    getAllProvidersFailed: () => createAction(ActionNames.GET_ALL_PROVIDERS_FAILED),

    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER),

    setFilterQuery: (searchQuery: string) => createAction(ActionNames.SET_FILTER_QUERY, searchQuery),

    getProviderById: (id: number) => createAction(ActionNames.GET_PROVIDER_BY_ID, id),
    getProviderSuccessById: (provider: ProviderModel) => createAction(ActionNames.GET_PROVIDER_BY_ID_SUCCESS, provider),
    getProviderFailedById: () => createAction(ActionNames.GET_PROVIDER_BY_ID_FAILED),

    getProvider: () => createAction(ActionNames.GET_PROVIDER),
    getProviderSuccess: (provider: ProviderModel) => createAction(ActionNames.GET_PROVIDER_SUCCESS, provider),
    getProviderFailed: () => createAction(ActionNames.GET_PROVIDER_FAILED),

    updateProvider: (provider: ProviderDto) => createAction(ActionNames.UPDATE_PROVIDER, provider),
    updateProviderSuccess: (provider: ProviderModel) => createAction(ActionNames.UPDATE_PROVIDER_SUCCESS, provider),
    updateProviderFailed: () => createAction(ActionNames.UPDATE_PROVIDER_FAILED),

    updateProviders: (provider: ProviderDto[]) => createAction(ActionNames.UPDATE_PROVIDERS, provider),
    updateProvidersSuccess: () => createAction(ActionNames.UPDATE_PROVIDERS_SUCCESS),
    updateProvidersFailed: () => createAction(ActionNames.UPDATE_PROVIDERS_FAILED),

    uploadAvatar: (file: UploadAvatarDto) => createAction(ActionNames.UPLOAD_AVATAR, file),
    uploadAvatarSuccess: () => createAction(ActionNames.UPLOAD_AVATAR_SUCCESS),
    uploadAvatarFailed: () => createAction(ActionNames.UPLOAD_AVATAR_FAILED),

    requestProvider: (id: number) => createAction(ActionNames.REQUEST_PROVIDER, id),
    requestProviderSuccess: () => createAction(ActionNames.REQUEST_PROVIDER_SUCCESS),
    requestProviderFailed: () => createAction(ActionNames.REQUEST_PROVIDER_FAILED),

    cancelRequestProvider: () => createAction(ActionNames.CANCEL_REQUEST_PROVIDER),
    cancelRequestProviderSuccess: () => createAction(ActionNames.CANCEL_REQUEST_PROVIDER_SUCCESS),
    cancelRequestProviderFailed: () => createAction(ActionNames.CANCEL_REQUEST_PROVIDER_FAILED),


    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),

    saveProviders: (data: SaveProviderDto[]) => createAction(ActionNames.SAVE_PROVIDERS, data),
    saveProvidersSuccess: () => createAction(ActionNames.SAVE_PROVIDERS_SUCCESS),
    saveProvidersFailed: () => createAction(ActionNames.SAVE_PROVIDERS_FAILED),

    getNewPasswordProvider: (id: number) => createAction(ActionNames.GET_NEW_PASSWORD_PROVIDER, id),
    getNewPasswordProviderSuccess: () => createAction(ActionNames.GET_NEW_PASSWORD_PROVIDER_SUCCESS),
    getNewPasswordProviderFailed: () => createAction(ActionNames.GET_NEW_PASSWORD_PROVIDER_FAILED),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllProvidersType = ActionsOfType<Actions, ActionNames.GET_ALL_PROVIDERS>;
export type getProviderByIdType = ActionsOfType<Actions, ActionNames.GET_PROVIDER_BY_ID>;
export type getProviderType = ActionsOfType<Actions, ActionNames.GET_PROVIDER>;
export type updateProviderType = ActionsOfType<Actions, ActionNames.UPDATE_PROVIDER>;
export type updateProvidersType = ActionsOfType<Actions, ActionNames.UPDATE_PROVIDERS>;
export type uploadAvatarType = ActionsOfType<Actions, ActionNames.UPLOAD_AVATAR>;
export type requestProviderType = ActionsOfType<Actions, ActionNames.REQUEST_PROVIDER>;
export type cancelRequestProviderType = ActionsOfType<Actions, ActionNames.CANCEL_REQUEST_PROVIDER>;
export type saveProvidersType = ActionsOfType<Actions, ActionNames.SAVE_PROVIDERS>;
export type getNewPasswordProviderType = ActionsOfType<Actions, ActionNames.GET_NEW_PASSWORD_PROVIDER>;