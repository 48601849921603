import React, { useEffect } from 'react';
import HeaderHeadingWithLink from '../../../shared/HeaderHeadingWithLink';
import providerAvatar from '../../../static/images/provider/providerAvatar.svg';
import { makeStyles, Typography } from '@material-ui/core';
import CancelLink from '../../../shared/link/CancelLink';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import styled from 'styled-components';
import { mediaQuery } from '../../../constants/device';
import { PADDING_BOTTOM, PADDING_TOP } from '../../patient/ProviderRequestLink';
import { colors } from '../../../theme/colors';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { withRouter, RouteComponentProps } from 'react-router';
import { PatientStepClassName } from '../constants/patientStepsClassNames';
import { UserModel } from '../../../models/responses/user.response';
import { RoleOptions } from '../../../constants/roleOptions';

const createStyles = makeStyles({
    heading: {
        fontSize: '24px',
        color: colors.textPrimary,
        marginBottom: '-6px',
    },
    subHeading: {
        fontSize: '14px',
        color: colors.textSecondary,
    },
    redSubHeading: {
        fontSize: '14px',
        color: colors.redMain,
    },
});

const Wrapper = styled.div`
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${PADDING_TOP}px 0 ${PADDING_BOTTOM}px;

    ${mediaQuery.laptop} {
        height: calc(100vh - 256px);
    }
`;

const Avatar = styled.div`
    width: 198px;
    height: 198px;
    display: flex;
    justify-content: center;
    background-color: ${colors.textSecondary};

    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100px;
    border: 1px solid ${colors.textSecondary};
    margin-top: 30px;
`;
const InfoWrapper = styled.div`
    text-align: center;
`;

const FooterWrapper = styled.div`
    width: 65%;
    height: 21px;
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
`;

interface StoreModel {
    isTourVisible: boolean;
    user: UserModel | null;
}

const MockProviderForTutorial: React.FC<StoreModel & RouteComponentProps> = props => {
    const classes = createStyles(props);
    const { isTourVisible, user, history } = props;

    useEffect(() => {
        if (user && user.role === RoleOptions.PATIENT && !isTourVisible) {
            history.push('/');
        }
    }, [user, isTourVisible, history]);

    return (
        <>
            <HeaderHeadingWithLink>Send Request</HeaderHeadingWithLink>
            <SizeControllerWrapper>
                <Wrapper>
                    <Avatar>
                        <img src={providerAvatar} alt="" />
                    </Avatar>
                    <InfoWrapper>
                        <Typography variant="h2" classes={{ root: classes.heading }}>
                            John Smith
                        </Typography>
                        <Typography variant="body1" classes={{ root: classes.subHeading }}>
                            Doctor first Class
                        </Typography>
                    </InfoWrapper>
                    <div className={PatientStepClassName.SEND_REQUEST}>
                        <PrimaryButton>Send Request</PrimaryButton>
                    </div>
                    <FooterWrapper>
                        <CancelLink />
                    </FooterWrapper>
                </Wrapper>
            </SizeControllerWrapper>
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        isTourVisible: store.tour.isTourVisible,
        user: store.authorization.user,
    }),
    () => ({})
)(withRouter(MockProviderForTutorial));
