import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { makeStyles, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { colors } from '../../../theme/colors';
import { mediaQuery } from '../../../constants/device';

export enum ImgType {
    icon,
    image,
}

type StyleType = {
    imgType: ImgType;
};

const useStyles = makeStyles(theme => ({
    text: (props: StyleType) => ({
        width: props.imgType === ImgType.image ? '80%' : '100%',
        alignSelf: 'flex-start',
        margin: '4px 0 18px 0',
        color: colors.textSecondary,
    }),
    heading: {
        alignSelf: 'flex-start',
        [theme.breakpoints.up('md')]: {
            marginBottom: '30px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '48px',
            lineHeight: '60px',
        },
    },
}));

const BackgroundWrapper = styled.div<any>`
    width: 90%;
    margin: 20px auto;
    height: 322px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(${props => props.background});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${mediaQuery.mobileL} {
        width: 70%;
        height: 400px;
        background-position: center;
        padding: 70px 53px 27px 40px;
    }

    ${mediaQuery.tablet} {
        width: 50%;
        height: 400px;
        background-position: center;
        padding: 70px 53px 27px 40px;
    }

    ${mediaQuery.laptop} {
        width: 100%;
        margin: 0;
        height: 36vw;
        align-items: flex-start;
        padding: 0;
        background-position: initial;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 4px 27px 4vw;

    ${mediaQuery.mobileL} {
        height: 400px;
        padding: 45px 5vw 27px;
        align-items: start;
    }
    ${mediaQuery.tablet} {
        height: 400px;
        padding: 45px 3vw 27px;
        align-items: start;
    }
    ${mediaQuery.laptop} {
        width: 310px;
        padding: 7vw 6px 2vw 5.5vw;
        align-items: start;
    }
`;

interface InjectedProps {
    path: string;
    heading: string;
    text: string;
    buttonText: string;
    background: string;
    imgType: ImgType;
}

type PropsTypes = RouteComponentProps<any> & InjectedProps;

const TextWithBackgroundBlock: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);

    const { path, heading, text, buttonText, background } = props;

    return (
        <BackgroundWrapper background={background}>
            <Content>
                <Typography variant="h2" classes={{ root: classes.heading }}>
                    {heading}
                </Typography>
                <Typography variant="body1" classes={{ root: classes.text }}>
                    {text}
                </Typography>
                <PrimaryButton size={'large'} onClick={() => props.history.push(path)}>
                    {buttonText}
                </PrimaryButton>
            </Content>
        </BackgroundWrapper>
    );
};

export default withRouter(TextWithBackgroundBlock);
