export default function fillClassInstanceWithValues<T>(klass: new () => T, obj: any): T {
    const instance: T = new klass();
    const instanceKeys = Object.keys(instance);
    const objKeys = `::${Object.keys(obj).join('::')}::`;
    instanceKeys.forEach((key: any) => {
        // surround key with commas to prevent partial key matching (e.g. [userName] => [user];)
        const hashedKey = `::${key}::`;
        if (!objKeys.includes(hashedKey)) {
            throw new TypeError(
                `Expected second argument to contain all the fields of classInstance, first item keys: ${instanceKeys.join(
                    ','
                )}, second: ${objKeys} `
            );
        }
        // @ts-ignore
        instance[key] = obj[key];
    });
    return instance;
}
