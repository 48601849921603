import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import theme from '../../../theme/theme';
import { colors } from '../../../theme/colors';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import SecondaryButton from '../../../shared/button/SecondaryButton';
import styled from 'styled-components';

const createStyles = makeStyles(themeMaterial => ({
    dialogPaper: {
        height: '36%',
        boxShadow: theme.boxShadowCard,
        padding: '25px',
        borderRadius: '30px',
        backgroundColor: colors.white,
        margin: '0',
        width: '76%',
        top: '3vh',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        [themeMaterial.breakpoints.down('sm')]: {
            height: '48%',
        },
    },
}));

interface InjectedProps {
    open: boolean;
    close: any;
    removePrescriptionPatient: () => void;
}

const ButtonBlock = styled.div`
    height: 135px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const RemovePrescriptionDialog: React.FC<InjectedProps> = props => {
    const classes = createStyles(props);
    return (
        <Dialog open={props.open} classes={{ paper: classes.dialogPaper }} onClose={props.close}>
            {props.children}
            <ButtonBlock>
                <PrimaryButton onClick={props.removePrescriptionPatient}>Yes</PrimaryButton>
                <SecondaryButton onClick={props.close}>No</SecondaryButton>
            </ButtonBlock>
        </Dialog>
    );
};

export default RemovePrescriptionDialog;
