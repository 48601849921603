import { Snackbar } from '../../store/reducers/common/snackbars';
import SnackbarComponent from './snackbar';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { SnackbarActions } from '../../store/actions/snackbar.actions';
import { bindActionCreators, Dispatch } from 'redux';

interface MapStateToProps {
    snackbars: Snackbar[];
}

interface MapDispatchToProps {
    removeSnackbar: typeof SnackbarActions.removeSnackbar;
}

interface Props extends MapStateToProps, MapDispatchToProps {}

function SnackbarsContainer(props: Props) {
    const { removeSnackbar, snackbars } = props;

    const onClose = useCallback(
        (id: number) => {
            removeSnackbar(id);
        },
        [removeSnackbar]
    );

    return (
        <>
            {snackbars.map((snackbar, index) => (
                <SnackbarComponent
                    key={snackbar.id!}
                    variant={snackbar.type}
                    message={`${snackbar.title}${snackbar.text}`}
                    type={snackbar.type}
                    open={snackbars.length >= index}
                    onClose={() => {
                        onClose(snackbar.id!);
                    }}
                />
            ))}
        </>
    );
}

const mapStateToProps = (store: fromRoot.RootStateModel): MapStateToProps => ({
    snackbars: store.snackbars.snackbars,
});
const mapDispatchToProps = (dispatch: Dispatch): MapDispatchToProps => ({
    removeSnackbar: bindActionCreators(SnackbarActions.removeSnackbar, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(SnackbarsContainer);
