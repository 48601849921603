import React, { useEffect } from 'react';
import HeaderHeadingWithLink from '../../../shared/HeaderHeadingWithLink';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import Loading from '../../shared/Loading';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as InvoiceActions } from '../../../store/actions/invoice.actions';
import RequestState from '../../../constants/requestState';
import * as formRoot from '../../../store/reducers';
import { PaymentRoutingProvider } from '../../../constants/tabRouting/routing';

const Wrapper = styled.div`
    text-align: center;
`;

const RelativeWrapper = styled.div`
    position: relative;
    height: 100%;
`;

interface StateModel {
    connectOAuthRequest: RequestState;
}

interface DispatchModel {
    connectOAuth: typeof InvoiceActions.connectOAuth;
    resetStatusVariables: typeof InvoiceActions.resetStatusVariables;
}

type PropsTypes = RouteComponentProps<any> & DispatchModel & StateModel;

const CreatedMerchant: React.FC<PropsTypes> = props => {
    const { connectOAuth, connectOAuthRequest, resetStatusVariables } = props;

    useEffect(() => {
        const values = queryString.parse(props.location.search);
        if (values.code && values.state) {
            connectOAuth({ authorizationCode: values.code.toString(), state: values.state.toString() });
        }
    }, [props.location, connectOAuth]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    useEffect(() => {
        if (connectOAuthRequest === RequestState.SENT_SUCCESS || connectOAuthRequest === RequestState.SENT_FAILED) {
            props.history.push(PaymentRoutingProvider[0].path);
        }
    }, [connectOAuthRequest, props.history]);

    return (
        <RelativeWrapper>
            <HeaderHeadingWithLink>Payments</HeaderHeadingWithLink>
            <SizeControllerWrapper>
                <Wrapper>
                    <Loading smallSize />
                </Wrapper>
            </SizeControllerWrapper>
        </RelativeWrapper>
    );
};

export default connect(
    (state: formRoot.RootStateModel): StateModel => ({
        connectOAuthRequest: state.invoice.connectOAuthRequest,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        connectOAuth: bindActionCreators(InvoiceActions.connectOAuth, dispatch),
        resetStatusVariables: bindActionCreators(InvoiceActions.resetStatusVariables, dispatch),
    })
)(withRouter(CreatedMerchant));
