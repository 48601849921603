import {ActionsOfType, ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {GetAllPrescriptionsDto} from "../../models/dto/getAllPrescriptions.dto";
import {CreatePrescriptionDto} from "../../models/dto/createPrescription.dto";
import {GetPrescriptionByIdDto} from "../../models/dto/getPrescriptionById.dto";
import {UpdatePrescriptionDto} from "../../models/dto/updatePrescription.dto";
import {PrescriptionComponentModel} from "../../models/responses/prescriptionComponentModel.responce";

export enum ActionNames {
    GET_ALL_PRESCRIPTIONS_PATIENT = '[Prescription] Get All Prescriptions Patient',
    GET_ALL_PRESCRIPTIONS_PROVIDER = '[Prescription] Get All Prescriptions Provider',
    GET_ALL_PRESCRIPTIONS_UPDATE_SUCCESS = '[Prescription] Get All Prescriptions Update Success',
    GET_ALL_PRESCRIPTIONS_OVERRIDE_SUCCESS = '[Prescription] Get All Prescriptions Override Success',
    GET_ALL_PRESCRIPTIONS_FAILED = '[Prescription] Get All Prescriptions Failed',
    INCREASE_PAGE_NUMBER = '[Prescription] Increase Page Number',

    GET_PRESCRIPTION_BY_ID_PATIENT = '[Prescription] Get Prescription By Id Patient',
    GET_PRESCRIPTION_BY_ID_PROVIDER = '[Prescription] Get Prescription By Id Provider',
    GET_PRESCRIPTION_BY_ID_SUCCESS = '[Prescription] Get Prescription By Id Success',
    GET_PRESCRIPTION_BY_ID_FAILED = '[Prescription] Get Prescription By Id Failed',

    ADD_PRESCRIPTION_COMPONENT_NEW = '[Prescription] Add Prescription Component New',
    EDIT_PRESCRIPTION_COMPONENT_NEW = '[Prescription] Edit Prescription Component New',
    CANCEL_EDIT_PRESCRIPTION_COMPONENT_NEW = '[Prescription] Cancel Edit Prescription Component New',
    SAVE_EDITED_PRESCRIPTION_COMPONENT_NEW = '[Prescription] Saved Edited Prescription Component New',
    REMOVE_PRESCRIPTION_COMPONENT_NEW = '[Prescription] Remove Prescription Component New',

    ADD_PRESCRIPTION_COMPONENT_UPDATE = '[Prescription] Add Prescription Component Update',
    EDIT_PRESCRIPTION_COMPONENT_UPDATE = '[Prescription] Edit Prescription Component Update',
    CANCEL_EDIT_PRESCRIPTION_COMPONENT_UPDATE = '[Prescription] Cancel Edit Prescription Component Update',
    SAVE_EDITED_PRESCRIPTION_COMPONENT_UPDATE = '[Prescription] Saved Edited Prescription Component Update',
    REMOVE_PRESCRIPTION_COMPONENT_UPDATE= '[Prescription] Remove Prescription Component Update',

    ADD_PRESCRIPTION_PATIENT = '[Prescription] Add Prescription Patient',
    ADD_PRESCRIPTION_PATIENT_SUCCESS = '[Prescription] Add Prescription Patient Success',
    ADD_PRESCRIPTION_PATIENT_FAILED = '[Prescription] Add Prescription Patient Failed',

    UPDATE_PRESCRIPTION_PATIENT = '[Prescription] Update Prescription Patient',
    UPDATE_PRESCRIPTION_PATIENT_SUCCESS = '[Prescription] Update Prescription Patient Success',
    UPDATE_PRESCRIPTION_PATIENT_FAILED = '[Prescription] Update Prescription Patient Failed',

    ADD_PRESCRIPTION_PROVIDER = '[Prescription] Add Prescription Provider',
    ADD_PRESCRIPTION_PROVIDER_SUCCESS = '[Prescription] Add Prescription Provider Success',
    ADD_PRESCRIPTION_PROVIDER_FAILED = '[Prescription] Add Prescription Provider Failed',

    UPDATE_PRESCRIPTION_PROVIDER = '[Prescription] Update Prescription Provider',
    UPDATE_PRESCRIPTION_PROVIDER_SUCCESS = '[Prescription] Update Prescription Provider Success',
    UPDATE_PRESCRIPTION_PROVIDER_FAILED = '[Prescription] Update Prescription Provider Failed',

    REMOVE_PRESCRIPTION_PATIENT = '[Prescription] Remove Prescription Patient',
    REMOVE_PRESCRIPTION_PATIENT_SUCCESS = '[Prescription] Remove Prescription Patient Success',
    REMOVE_PRESCRIPTION_PATIENT_FAILED = '[Prescription] Remove Prescription Patient Failed',

    REMOVE_PRESCRIPTION_PROVIDER = '[Prescription] Remove Prescription Provider',
    REMOVE_PRESCRIPTION_PROVIDER_SUCCESS = '[Prescription] Remove Prescription Provider Success',
    REMOVE_PRESCRIPTION_PROVIDER_FAILED = '[Prescription] Remove Prescription Provider Failed',

    RESET_STATUS_VARIABLES = '[Prescription] Reset Status Variables',
}

export const Actions = {
    getAllPrescriptionsPatient: (data: GetAllPrescriptionsDto) => createAction(ActionNames.GET_ALL_PRESCRIPTIONS_PATIENT, data),
    getAllPrescriptionsProvider: (data: GetAllPrescriptionsDto) => createAction(ActionNames.GET_ALL_PRESCRIPTIONS_PROVIDER, data),
    getAllPrescriptionsOverrideSuccess: (data: { result: GetPrescriptionByIdDto[], total: number }) => createAction(ActionNames.GET_ALL_PRESCRIPTIONS_OVERRIDE_SUCCESS, data),
    getAllPrescriptionsUpdateSuccess: (data: { result: GetPrescriptionByIdDto[], total: number }) => createAction(ActionNames.GET_ALL_PRESCRIPTIONS_UPDATE_SUCCESS, data),
    getAllPrescriptionsFailed: () => createAction(ActionNames.GET_ALL_PRESCRIPTIONS_FAILED),
    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER),

    getPrescriptionByIdPatient: (id: number) => createAction(ActionNames.GET_PRESCRIPTION_BY_ID_PATIENT, id),
    getPrescriptionByIdProvider: (data: {patientId: number, prescriptionId: number}) => createAction(ActionNames.GET_PRESCRIPTION_BY_ID_PROVIDER, data),
    getPrescriptionByIdSuccess: (data: GetPrescriptionByIdDto) => createAction(ActionNames.GET_PRESCRIPTION_BY_ID_SUCCESS, data),
    getPrescriptionByIdFailed: () => createAction(ActionNames.GET_PRESCRIPTION_BY_ID_FAILED),

    addPrescriptionComponentNew: (data: PrescriptionComponentModel) => createAction(ActionNames.ADD_PRESCRIPTION_COMPONENT_NEW, data),
    editPrescriptionComponentNew: (id: number) => createAction(ActionNames.EDIT_PRESCRIPTION_COMPONENT_NEW, id),
    cancelEditPrescriptionComponentNew: (id: number) => createAction(ActionNames.CANCEL_EDIT_PRESCRIPTION_COMPONENT_NEW, id),
    saveEditedPrescriptionComponentNew: (data: PrescriptionComponentModel) => createAction(ActionNames.SAVE_EDITED_PRESCRIPTION_COMPONENT_NEW, data),
    removePrescriptionComponentNew: (id: number) => createAction(ActionNames.REMOVE_PRESCRIPTION_COMPONENT_NEW, id),

    addPrescriptionComponentUpdate: (data: PrescriptionComponentModel) => createAction(ActionNames.ADD_PRESCRIPTION_COMPONENT_UPDATE, data),
    editPrescriptionComponentUpdate: (id: number) => createAction(ActionNames.EDIT_PRESCRIPTION_COMPONENT_UPDATE, id),
    cancelEditPrescriptionComponentUpdate: (id: number) => createAction(ActionNames.CANCEL_EDIT_PRESCRIPTION_COMPONENT_UPDATE, id),
    saveEditedPrescriptionComponentUpdate: (data: PrescriptionComponentModel) => createAction(ActionNames.SAVE_EDITED_PRESCRIPTION_COMPONENT_UPDATE, data),
    removePrescriptionComponentUpdate: (id: number) => createAction(ActionNames.REMOVE_PRESCRIPTION_COMPONENT_UPDATE, id),

    addPrescriptionPatient: (data: CreatePrescriptionDto) => createAction(ActionNames.ADD_PRESCRIPTION_PATIENT, data),
    addPrescriptionSuccess: () => createAction(ActionNames.ADD_PRESCRIPTION_PATIENT_SUCCESS),
    addPrescriptionFailed: () => createAction(ActionNames.ADD_PRESCRIPTION_PATIENT_FAILED),

    updatePrescriptionPatient: (data: UpdatePrescriptionDto) => createAction(ActionNames.UPDATE_PRESCRIPTION_PATIENT, data),
    updatePrescriptionPatientSuccess: () => createAction(ActionNames.UPDATE_PRESCRIPTION_PATIENT_SUCCESS),
    updatePrescriptionPatientFailed: () => createAction(ActionNames.UPDATE_PRESCRIPTION_PATIENT_FAILED),

    updatePrescriptionProvider: (data: {data: UpdatePrescriptionDto, patientId: number}) => createAction(ActionNames.UPDATE_PRESCRIPTION_PROVIDER, data),
    updatePrescriptionProviderSuccess: () => createAction(ActionNames.UPDATE_PRESCRIPTION_PROVIDER_SUCCESS),
    updatePrescriptionProviderFailed: () => createAction(ActionNames.UPDATE_PRESCRIPTION_PROVIDER_FAILED),

    removePrescriptionPatient: (id: number) => createAction(ActionNames.REMOVE_PRESCRIPTION_PATIENT, id),
    removePrescriptionPatientSuccess: () => createAction(ActionNames.REMOVE_PRESCRIPTION_PATIENT_SUCCESS),
    removePrescriptionPatientFailed: () => createAction(ActionNames.REMOVE_PRESCRIPTION_PATIENT_FAILED),

    removePrescriptionProvider: (data: {prescriptionId: number, patientId: number}) => createAction(ActionNames.REMOVE_PRESCRIPTION_PROVIDER, data),
    removePrescriptionProviderSuccess: () => createAction(ActionNames.REMOVE_PRESCRIPTION_PROVIDER_SUCCESS),
    removePrescriptionProviderFailed: () => createAction(ActionNames.REMOVE_PRESCRIPTION_PROVIDER_FAILED),

    addPrescriptionProvider: (data: {data: CreatePrescriptionDto, id: number}) => createAction(ActionNames.ADD_PRESCRIPTION_PROVIDER, data),
    addPrescriptionProviderSuccess: () => createAction(ActionNames.ADD_PRESCRIPTION_PROVIDER_SUCCESS),
    addPrescriptionProviderFailed: () => createAction(ActionNames.ADD_PRESCRIPTION_PROVIDER_FAILED),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllPrescriptionsPatientType = ActionsOfType<Actions, ActionNames.GET_ALL_PRESCRIPTIONS_PATIENT>;
export type getAllPrescriptionsProviderType = ActionsOfType<Actions, ActionNames.GET_ALL_PRESCRIPTIONS_PROVIDER>;

export type getPrescriptionByIdPatientType = ActionsOfType<Actions, ActionNames.GET_PRESCRIPTION_BY_ID_PATIENT>;
export type getPrescriptionByIdProviderType = ActionsOfType<Actions, ActionNames.GET_PRESCRIPTION_BY_ID_PROVIDER>;

export type addPrescriptionPatientType = ActionsOfType<Actions, ActionNames.ADD_PRESCRIPTION_PATIENT>;
export type addPrescriptionProviderType = ActionsOfType<Actions, ActionNames.ADD_PRESCRIPTION_PROVIDER>;

export type updatePrescriptionPatientType = ActionsOfType<Actions, ActionNames.UPDATE_PRESCRIPTION_PATIENT>;
export type updatePrescriptionProviderType = ActionsOfType<Actions, ActionNames.UPDATE_PRESCRIPTION_PROVIDER>;

export type removePrescriptionPatientType = ActionsOfType<Actions, ActionNames.REMOVE_PRESCRIPTION_PATIENT>;
export type removePrescriptionProviderType = ActionsOfType<Actions, ActionNames.REMOVE_PRESCRIPTION_PROVIDER>;