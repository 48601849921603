import React from 'react';
import Footer from "../../../shared/Footer";
import FirstScreen from "./components/FirstScreen";
import RequestConsultation from "./components/RequestConsultation";
import TextWithBackgroundBlock, {ImgType} from "../shared/TextWithBackgroundBlock";
import RedInfoBlock from "./components/RedInfoBlock";
import resultCharting from '../../../static/images/landing/main/resultCharting.svg';
import labIncorporatedBloodworkIcon from '../../../static/images/landing/main/labIncorporatedBloodworkIcon.svg';
import styled from "styled-components";
import {colors} from "../../../theme/colors";
import InfoWithVideoLink from "../shared/InfoWithVideoLink";
import infoWithVideoLinkMainMobile from '../../../static/images/landing/main/infoWithVideoLinkMainMobile.png';
import infoWithVideoLinkMainDesktop from '../../../static/images/landing/main/infoWithVideoLinkMainDesktop.png';
import SharedCarousel from "../shared/SharedCarousel";
import LandingBigHeading from "../../shared/LandingBigHeading";
import LandingRedUppercaseHeading from "../../shared/LandingRedUppercaseHeading";
import mainRedImg from '../../../static/images/landing/main/mainRedImg.png';
import mainGrayImg from '../../../static/images/landing/main/mainGrayImg.png';
import ComingSoonPage from "./components/ComingSoonPage";
import {makeStyles, Typography} from "@material-ui/core";
import carouserBackgroundMobile from '../../../static/images/landing/main/carouserBackgroundMobile.svg';
import carouserBackgroundDesktop from '../../../static/images/landing/main/carouselBackgroundDesktop.svg';
import {RoleOptions} from "../../../constants/roleOptions";
import patientImg from "../../../static/images/landing/main/patientImg.svg";
import providerImg from "../../../static/images/landing/main/providerImg.svg";
import {mediaQuery} from "../../../constants/device";
import redBackground from '../../../static/images/landing/main/redBackground.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SizeControllerWrapper from "../../../shared/SizeControllerWrapper";
import CardWithTopImageGray, {ImgSide} from "../../../shared/card/CardWithTopImageGray";
import CardWithTopImageRed from "../../../shared/card/CardWithTopImageRed";

const DividerBackground = styled.div`
  background-color: ${colors.redMain};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${colors.white};
  
  ${mediaQuery.laptop}{
    height: 325px;
    width: 1px;
  }
`;

const RedHeaderWrapper = styled.div`
  margin: 96px 0 21px;
`;

const CarouselWrapper = styled.div`
  margin: 30px 0;
  
  ${mediaQuery.laptop}{
    width: 90%;
    margin: 30px auto;
  }
`;

const GrayCardWrapper = styled.div`
  margin-top: 95px;
  position: relative;
`;

const GrayBackgroundMobile = styled.div`
  height: 65px;
  background-color: ${colors.gray2};
  
  ${mediaQuery.tablet}{
    margin-left: 12%;
  }
`;
const GrayBackgroundDesktop = styled.div`
  display: none;
  ${mediaQuery.tablet}{
      background-color: ${colors.gray2};
      display: block;
      margin-left: 12%;
      position: absolute;
      bottom: -300px;
      height: 300px;
      width: 88%;
      z-index: -1;
  }
    ${mediaQuery.laptop}{
      background-color: ${colors.gray2};
      display: block;
      margin-left: 12%;
      position: absolute;
      bottom: -400px;
      height: 400px;
      width: 88%;
      z-index: -1;
  }
`;

const ProviderPatientBlocks = styled.div` 
  display: flex;
  flex-direction: column;
  
  ${mediaQuery.laptop}{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    width: 90%;
    margin: 0 auto;
    padding: 75px 0 136px;
  }
`;

const RedInfoBlocks = styled.div`
  display: flex;
  flex-direction: column;
  
  ${mediaQuery.laptop}{
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
  }
`;

const DividerWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  
  ${mediaQuery.laptop}{
    height: 100%;  
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const RedInfoBlocksBackgroundWrapper = styled.div`
  ${mediaQuery.laptop}{
    background-color: ${colors.redMain};
    background-image: url(${redBackground});
    background-size: cover;
    height: 560px;
    display: flex;
    align-items: center;
  }
`;

const createStyles = makeStyles(theme => ({
    infoWithVideoLinkText: {
        fontSize: '20px',
        lineHeight: '35px',
        color: colors.white,
        display: 'block',
        [theme.breakpoints.up('lg')]: {
            fontSize: '30px',
            lineHeight: '45px',
        }
    },
    headingGrayBlock: {
        fontSize: '24px',
        lineHeight: '36px',
        color: colors.textPrimary,
        display: 'block',
        marginBottom: '36px',
        [theme.breakpoints.up('xl')]: {
            fontSize: '36px',
            lineHeight: '48px',
        }
    },
    headingRedBlock: {
        fontSize: '24px',
        lineHeight: '36px',
        color: colors.white,
        display: 'block',
        marginBottom: '36px',
        [theme.breakpoints.up('xl')]: {
            fontSize: '36px',
            lineHeight: '48px',
        }
    }
}));

const MainPage: React.FC = (props) => {

    const classes = createStyles(props);

    const isTablet = useMediaQuery(mediaQuery.tablet);
    const isMobile = useMediaQuery(mediaQuery.mobileM);

    return (
        <>
            <ComingSoonPage/>
            <FirstScreen/>
            <RequestConsultation/>
            <ProviderPatientBlocks>
                <TextWithBackgroundBlock
                    heading={'Track your results'}
                    text={'Tracking the impact of supplementation over a long period of time has always been extremely difficult. UPmed has made it easy.'}
                    buttonText={'Patient'}
                    background={patientImg}
                    path={'/patient'}
                    imgType={ImgType.image}
                />
                <TextWithBackgroundBlock
                    heading={'Serve your patients'}
                    text={'Tracking long term adjustments to patient bloodwork to ascertain viability of RX changes has always proven difficult in the alternative medicine marketplace. UPmed has made it easy for you.'}
                    buttonText={'Provider'}
                    background={providerImg}
                    path={'/provider'}
                    imgType={ImgType.icon}
                />
            </ProviderPatientBlocks>

            <RedInfoBlocksBackgroundWrapper>
                <RedInfoBlocks>
                    <RedInfoBlock
                        link={'/sign-up'}
                        icon={resultCharting}
                        heading={'Results Charting'}
                        text={'Custom inputs allow you to incorporate external supplementation that you may be ' +
                        'incorporating to give you, the patient, a better view of your total health picture.'}
                    />
                    <DividerBackground>
                        <DividerWrapper>
                            <Divider/>
                        </DividerWrapper>
                    </DividerBackground>

                    <RedInfoBlock
                        link={'/sign-up'}
                        icon={labIncorporatedBloodworkIcon}
                        heading={'Lab Incorporated Bloodwork'}
                        text={'Include your prescribed lab results as well as any external labwork your patient' +
                        ' has had performed along the way. Our goal is to show you your patients full history of treatment and results.'}
                    />
                </RedInfoBlocks>
            </RedInfoBlocksBackgroundWrapper>

            <InfoWithVideoLink
                role={RoleOptions.PATIENT}
                img={isTablet ? infoWithVideoLinkMainDesktop : infoWithVideoLinkMainMobile}
                imgSide={ImgSide.right}
            >
                <Typography variant='button' classes={{root: classes.infoWithVideoLinkText}}>
                    UPmed technology has had an incredible impact on the lives of the doctors and patients we serve.
                    But, we don’t need to be the ones to tell you that….
                </Typography>
            </InfoWithVideoLink>

            <RedHeaderWrapper>
                <LandingRedUppercaseHeading>OUR PATIENTS SAYS</LandingRedUppercaseHeading>
            </RedHeaderWrapper>

            <LandingBigHeading>Reviews</LandingBigHeading>

            <CarouselWrapper>
                <SizeControllerWrapper>
                    <SharedCarousel
                        textFontSize={18}
                        isTablet={isTablet}
                        backgroundImg={isMobile ? carouserBackgroundDesktop : carouserBackgroundMobile}
                        items={[
                            {
                                text: 'UPmed has provided me with a tool that better organizes my ' +
                                    'staff and better serves my patients. It is easy to utilize for both my ' +
                                    'team and patients, and has enough flexibility to customize to our needs as ' +
                                    'an alternative health provider. It has dramatically streamlined all operational and treatment related aspects of my practice.',
                                authorName: '“Dr. Roy Korth”'
                            },
                        ]}/>
                </SizeControllerWrapper>
            </CarouselWrapper>

            <GrayCardWrapper>
                <CardWithTopImageGray
                    imageMargin
                    redHeading={'Better Together'}
                    text={'“The alternative healthy model is a largely underappreciated industry. Both patient and provider      ' +
                    'are given few tools to effectively manage treatment, adjust dosages or fully understand the patient’s ' +
                    'entire health narrative. UPdent strives to fill in the gap, to bridge the information divide and provide ' +
                    'a tool that both clinician  and patient can utilize to better understand the far-reaching benefits of an ' +
                    'alternative and holistic approach to health optimization.”'}
                    img={mainGrayImg}
                    horizontal
                    imgSide={ImgSide.left}
                >
                    <Typography variant={'button'} classes={{root: classes.headingGrayBlock}}>Our goal is to improve
                        communication between provider and patient.</Typography>
                </CardWithTopImageGray>
                <GrayBackgroundMobile/>
                <GrayBackgroundDesktop/>
            </GrayCardWrapper>

            <CardWithTopImageRed
                imageMargin
                text={'Join us… We’re excited to be a part of your business, your treatment, your family!'}
                img={mainRedImg}
                backgroundColor={colors.gray2}
                imgSide={ImgSide.right}
            >
                <Typography variant={'button'} classes={{root: classes.headingRedBlock}}>Your partner in the management
                    of alternative therapeutic supplementation.</Typography>
            </CardWithTopImageRed>

            <Footer/>
        </>
    );
};

export default MainPage;