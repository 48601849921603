import { put, call, takeEvery } from 'redux-saga/effects';
import * as authorizationActions from '../../actions/auth.actions';
import AuthApi from '../../../api/auth/AuthApi';
import StorageHelper from '../../../helpers/storageHelper';
import { typedCall } from '../../../shared/utils/typedYieldWrapper';

function* signUp(action: authorizationActions.signUpType) {
    try {
        yield call(AuthApi.signUp, action.payload);
        yield put({ type: authorizationActions.ActionNames.SIGN_UP_SUCCESS });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.SIGN_UP_FAILED });
    }
}

function* verifyEmail(action: authorizationActions.verifyEmailType) {
    try {
        const value = yield call(AuthApi.verifyEmail, action.payload);
        const res = typedCall(AuthApi.verifyEmail, value);
        StorageHelper.setToken(res.data.accessToken);
        yield put({ type: authorizationActions.ActionNames.VERIFY_EMAIL_SUCCESS, payload: res.data.user });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.VERIFY_EMAIL_FAILED });
    }
}

function* resendVerificationLink(action: authorizationActions.resendVerificationLink) {
    try {
        yield call(AuthApi.resendVerificationLink, action.payload);
        yield put({ type: authorizationActions.ActionNames.RESEND_VERIFICATION_LINK_SUCCESS });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.RESEND_VERIFICATION_LINK_FAILED });
    }
}

function* signIn(action: authorizationActions.signInType) {
    try {
        const value = yield call(AuthApi.signIn, action.payload);
        const response = typedCall(AuthApi.signIn, value);
        StorageHelper.setToken(response.data.accessToken);
        yield put({ type: authorizationActions.ActionNames.SIGN_IN_SUCCESS, payload: response.data.user });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.SIGN_IN_FAILED });
    }
}

function* resetPassword(action: authorizationActions.resetPassword) {
    try {
        yield call(AuthApi.resetPassword, action.payload);
        yield put({ type: authorizationActions.ActionNames.RESET_PASSWORD_SUCCESS });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.RESET_PASSWORD_FAILED });
    }
}

function* changePassword(action: authorizationActions.changePassword) {
    try {
        yield call(AuthApi.changePassword, action.payload);
        yield put({ type: authorizationActions.ActionNames.CHANGE_PASSWORD_SUCCESS });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.CHANGE_PASSWORD_FAILED });
    }
}

function* acceptInvitation(action: authorizationActions.acceptInvitationType) {
    try {
        yield call(AuthApi.acceptInvitation, action.payload);
        yield put({ type: authorizationActions.ActionNames.ACCEPT_INVITATION_SUCCESS });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.ACCEPT_INVITATION_FAILED });
    }
}

function* getCurrentUser() {
    try {
        const value = yield call(AuthApi.getCurrentUser);
        const response = typedCall(AuthApi.getCurrentUser, value);
        yield put({ type: authorizationActions.ActionNames.SET_CURRENT_USER, payload: response.data });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.GET_CURRENT_USER_FAILED });
    }
}

function* changeCurrentPassword(action: authorizationActions.chnageCurrentPasswordType) {
    try {
        yield call(AuthApi.changeCurrentPassword, action.payload);
        yield put({ type: authorizationActions.ActionNames.CHANGE_CURRENT_PASSWORD_SUCCESS });
    } catch (e) {
        yield put({ type: authorizationActions.ActionNames.CHANGE_CURRENT_PASSWORD_FAILED });
    }
}

export default function* AuthorizationSagas() {
    yield takeEvery(authorizationActions.ActionNames.SIGN_UP, signUp);
    yield takeEvery(authorizationActions.ActionNames.VERIFY_EMAIL, verifyEmail);
    yield takeEvery(authorizationActions.ActionNames.RESEND_VERIFICATION_LINK, resendVerificationLink);
    yield takeEvery(authorizationActions.ActionNames.SIGN_IN, signIn);
    yield takeEvery(authorizationActions.ActionNames.RESET_PASSWORD, resetPassword);
    yield takeEvery(authorizationActions.ActionNames.CHANGE_PASSWORD, changePassword);
    yield takeEvery(authorizationActions.ActionNames.ACCEPT_INVITATION, acceptInvitation);
    yield takeEvery(authorizationActions.ActionNames.GET_CURRENT_USER, getCurrentUser);
    yield takeEvery(authorizationActions.ActionNames.CHANGE_CURRENT_PASSWORD, changeCurrentPassword);
}
