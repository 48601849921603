import * as panelTypeActions from '../../actions/panelType.actions';
import {PanelTypeModel} from "../../../models/responses/panelType.model";
import RequestState from "../../../constants/requestState";

export class StateModel {
    panelTypes: PanelTypeModel[];
    panelTypesLoading: boolean;
    newPanel: PanelTypeModel | null;

    editPanelTypeRequest: RequestState;
    removePanelTypeRequest: RequestState;
    addPanelTypeRequest: RequestState;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: panelTypeActions.Actions) {
    switch (action.type) {

        case panelTypeActions.ActionNames.GET_PANEL_TYPES: {
            return {
                ...state,
                panelTypesLoading: true,
            }
        }
        case panelTypeActions.ActionNames.GET_PANEL_TYPES_SUCCESS: {
            return {
                ...state,
                panelTypesLoading: false,
                panelTypes: action.payload,
            }
        }
        case panelTypeActions.ActionNames.GET_PANEL_TYPES_FAILED: {
            return {
                ...state,
                panelTypesLoading: false,
            }
        }

        case panelTypeActions.ActionNames.GET_PANEL_TYPES_WITH_TARGETS: {
            return {
                ...state,
                panelTypesLoading: true,
            }
        }
        case panelTypeActions.ActionNames.GET_PANEL_TYPES_WITH_TARGETS_SUCCESS: {
            return {
                ...state,
                panelTypesLoading: false,
                panelTypes: action.payload,
            }
        }
        case panelTypeActions.ActionNames.GET_PANEL_TYPES_WITH_TARGETS_FAILED: {
            return {
                ...state,
                panelTypesLoading: false,
            }
        }

        case panelTypeActions.ActionNames.EDIT_PANEL_TYPE: {
            return {
                ...state,
                editPanelTypeRequest: RequestState.SENDING,
            }
        }

        case panelTypeActions.ActionNames.EDIT_PANEL_TYPE_SUCCESS: {
            return {
                ...state,
                editPanelTypeRequest: RequestState.SENT_SUCCESS,
            }
        }

        case panelTypeActions.ActionNames.EDIT_PANEL_TYPE_FAILED: {
            return {
                ...state,
                editPanelTypeRequest: RequestState.SENT_FAILED,
            }
        }

        case panelTypeActions.ActionNames.REMOVE_PANEL_TYPE: {
            return {
                ...state,
                removePanelTypeRequest: RequestState.SENDING,
            }
        }

        case panelTypeActions.ActionNames.REMOVE_PANEL_TYPE_SUCCESS: {
            return {
                ...state,
                removePanelTypeRequest: RequestState.SENT_SUCCESS,
            }
        }

        case panelTypeActions.ActionNames.REMOVE_PANEL_TYPE_FAILED: {
            return {
                ...state,
                removePanelTypeRequest: RequestState.SENT_FAILED,
            }
        }

        case panelTypeActions.ActionNames.ADD_PANEL_TYPE: {
            return {
                ...state,
                addPanelTypeRequest: RequestState.SENDING,
            }
        }

        case panelTypeActions.ActionNames.ADD_PANEL_TYPE_SUCCESS: {
            return {
                ...state,
                addPanelTypeRequest: RequestState.SENT_SUCCESS,
                newPanel: action.payload,
            }
        }

        case panelTypeActions.ActionNames.ADD_PANEL_TYPE_FAILED: {
            return {
                ...state,
                addPanelTypeRequest: RequestState.SENT_FAILED,
            }
        }

        case panelTypeActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                addPanelTypeRequest: RequestState.UNSENT,
                editPanelTypeRequest: RequestState.UNSENT,
                removePanelTypeRequest: RequestState.UNSENT,
                newPanel: null,
            }
        }

        default:
            return state;
    }
}