import React, { useEffect, useRef, useState } from 'react';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { List, makeStyles, Typography } from '@material-ui/core';
import LabListItem from './LabListItem';
import AlphabetLetter from './AlphabetLetter';
import Loading from '../../shared/Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { colors } from '../../../theme/colors';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../../constants/layout';
import {ifStringEquals} from "../../../helpers/stringFilterHelper";

const useStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '16px',
        color: colors.textSecondary,
        marginTop: '20px',
    },
});

interface InjectedProps {
    loading: boolean;
    allLabs: BaseDropdownItem[];
    totalLabs: number | null;
    page: number;
    searchQuery: string;
    increasePageNumber: () => void;
    redirectToLab?: (id: number) => void;
}

type PropsTypes = InjectedProps;

const LabsList: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);
    let latestLetter: string | null = null;

    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const { redirectToLab } = props;

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    const getMoreLabs = () => {
        props.increasePageNumber();
    };

    return (
        <>
            {!!props.allLabs.length && (
                <List classes={{ root: classes.list }} ref={listRef}>
                    <InfiniteScroll
                        dataLength={props.allLabs.length}
                        next={getMoreLabs}
                        height={height}
                        hasMore={props.totalLabs ? props.allLabs.length < props.totalLabs : false}
                        loader={<Loading smallSize />}
                        className={classes.scrollContainer}
                    >
                        {props.allLabs.map((lab, index) => {
                            let should = false;
                            if (!latestLetter || !ifStringEquals(latestLetter, lab.name[0])) {
                                should = true;
                                latestLetter = lab.name[0];
                            }
                            return (
                                <div key={index}>
                                    {should && <AlphabetLetter>{lab.name[0]}</AlphabetLetter>}
                                    <LabListItem key={index} lab={lab} redirectToLab={redirectToLab} />
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                </List>
            )}

            {!props.loading && props.allLabs.length === 0 && (
                <SizeControllerWrapper>
                    <Typography variant="body1" classes={{ root: classes.noData }}>
                        No data found.
                    </Typography>
                </SizeControllerWrapper>
            )}

            {props.loading && props.page === 0 && <Loading smallSize />}
        </>
    );
};

export default LabsList;
