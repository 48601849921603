import { IsString, MaxLength, MinLength } from 'class-validator';

export class SetNewPasswordDto {
    @IsString()
    @MinLength(8, { message: 'Password must be at least 8 characters' })
    @MaxLength(30, { message: 'Password is too long' })
    password: string;

    @IsString()
    passwordConfirm: string;
}
