import { put, call, takeEvery, select } from 'redux-saga/effects';
import * as bloodTargetActions from '../../actions/bloodTarget.actions';
import BloodTargetApi from '../../../api/bloodTarget/BloodTargetApi';
import * as fromRoot from '../../reducers';
import { RoleOptions } from '../../../constants/roleOptions';

function* getBloodTargetById(action: bloodTargetActions.getBloodTargetByIdType) {
    try {
        const response = yield call(BloodTargetApi.getBloodTargetById, action.payload);
        yield put({ type: bloodTargetActions.ActionNames.GET_BLOOD_TARGET_BY_ID_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: bloodTargetActions.ActionNames.GET_BLOOD_TARGET_BY_ID_FAILED });
    }
}

function* addBloodTargets(action: bloodTargetActions.addBloodTargetsType) {
    const user = yield select((state: fromRoot.RootStateModel) => state.authorization.user);
    try {
        const response = yield call(
            user.role === RoleOptions.ADMIN ? BloodTargetApi.addBloodTargetsAdmin : BloodTargetApi.addBloodTargetsUsers,
            action.payload
        );
        yield put({ type: bloodTargetActions.ActionNames.ADD_BLOOD_TARGETS_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: bloodTargetActions.ActionNames.ADD_BLOOD_TARGETS_FAILED });
    }
}

function* editBloodTarget(action: bloodTargetActions.editBloodTargetType) {
    const user = yield select((state: fromRoot.RootStateModel) => state.authorization.user);
    try {
        const response = yield call(
            user.role === RoleOptions.ADMIN ? BloodTargetApi.editBloodTargetAdmin : BloodTargetApi.editBloodTargetUsers,
            action.payload
        );
        yield put({ type: bloodTargetActions.ActionNames.EDIT_BLOOD_TARGET_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: bloodTargetActions.ActionNames.EDIT_BLOOD_TARGET_FAILED });
    }
}

function* removeBloodTarget(action: bloodTargetActions.removeBloodTargetType) {
    const user = yield select((state: fromRoot.RootStateModel) => state.authorization.user);
    try {
        const response = yield call(
            user.role === RoleOptions.ADMIN
                ? BloodTargetApi.removeBloodTargetAdmin
                : BloodTargetApi.removeBloodTargetUsers,
            action.payload
        );
        yield put({ type: bloodTargetActions.ActionNames.REMOVE_BLOOD_TARGET_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: bloodTargetActions.ActionNames.REMOVE_BLOOD_TARGET_FAILED });
    }
}

export default function* BloodTargetSagas() {
    yield takeEvery(bloodTargetActions.ActionNames.ADD_BLOOD_TARGETS, addBloodTargets);
    yield takeEvery(bloodTargetActions.ActionNames.GET_BLOOD_TARGET_BY_ID, getBloodTargetById);
    yield takeEvery(bloodTargetActions.ActionNames.EDIT_BLOOD_TARGET, editBloodTarget);
    yield takeEvery(bloodTargetActions.ActionNames.REMOVE_BLOOD_TARGET, removeBloodTarget);
}
