import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import DatePickerBase from '../../../shared/input/DatePickerBase';
import moment from 'moment';
import { GetAnalyticsPatientDto } from '../../../models/dto/getAnalyticsPatient.dto';
import AutocompleteMultiple from '../../../shared/input/AutocompleteMultiple';
import { DrugInAnalytics } from '../../../models/responses/drugInAnalytics.model';
import { BloodTargetInAnalytics } from '../../../models/responses/bloodworkInAnalytics.model';
import { DropdownMultipleInputWrapper } from '../../../constants/dropdownMultipleInputData';
import { AnalyticsResultModel } from '../../../models/responses/analyticsResult.model';
import { DataRange, SELECT_ALL } from '../../../store/reducers/analytics';
import RequestState from '../../../constants/requestState';

const InputWrapperMultiple = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
`;

const emptyAnalytics = {
    endDate: null,
    startDate: null,
    bloodTargets: [],
    drugs: [],
};

enum FieldName {
    endDate = 'endDate',
    startDate = 'startDate',
    bloodTargets = 'bloodTargets',
    drugs = 'drugs',
}

const Divider = styled.div`
    width: 1px;
    height: 20px;
    background-color: ${colors.grayUnderline};
    margin: 0 10px 8px;
`;

const InputWrapperFullWidth = styled.div`
    margin-bottom: 8px;
`;

const FormWrapper = styled.div`
    margin-top: 20px;
`;

const DropdownWrapper = styled.div<any>`
    opacity: ${props => (props.disabled ? 0.7 : 1)};
`;

const SizeWrapper = styled.div`
    padding: 0 5%;
`;

interface InjectedProps {
    drugsList: DrugInAnalytics[];
    bloodTargetsList: BloodTargetInAnalytics[];
    getAnalytics: ({ startDate, endDate }: GetAnalyticsPatientDto) => void;
    getAnalyticsResult: (data: AnalyticsResultModel) => void;
    date: DataRange | null;
    clearAnalytics: () => void;
    loadingRequestState: RequestState;
    resetStatusVariables: () => void;
}

const AnalyticsForm: React.FC<InjectedProps> = props => {
    const [analytics, setAnalytics] = useState(emptyAnalytics);
    const [datesValid, setDatesValid] = useState(false);

    const {
        drugsList,
        bloodTargetsList,
        getAnalytics,
        getAnalyticsResult,
        date,
        clearAnalytics,
        loadingRequestState,
        resetStatusVariables,
    } = props;

    const changeAnalytics = (
        fieldName: FieldName,
        value: Date | null | BloodTargetInAnalytics[] | DrugInAnalytics[]
    ) => {
        setAnalytics({ ...analytics, [fieldName]: value });
    };

    const getAllEntities = useCallback(
        (fieldName: FieldName) => {
            switch (fieldName) {
                case FieldName.drugs:
                    return drugsList.filter(d => d.name !== SELECT_ALL);
                case FieldName.bloodTargets:
                    return bloodTargetsList.filter(t => t.name !== SELECT_ALL);
                default:
                    return null;
            }
        },
        [drugsList, bloodTargetsList]
    );

    const selectAll = useCallback(
        (fieldName: FieldName) => {
            setAnalytics({ ...analytics, [fieldName]: getAllEntities(fieldName) });
        },
        [getAllEntities, analytics]
    );

    useEffect(() => {
        if (loadingRequestState === RequestState.SENT_SUCCESS) {
            selectAll(FieldName.drugs);
            resetStatusVariables();
        }
    }, [loadingRequestState, resetStatusVariables, selectAll]);


    const isDateValid = useMemo(() => {
        return (
            !!analytics.startDate &&
            !!analytics.endDate &&
            analytics.startDate !== 'Invalid Date' &&
            analytics.endDate !== 'Invalid Date' &&
            moment(analytics.startDate!).isBefore(analytics.endDate!)
        );
    }, [analytics]);


    useEffect(() => {
        setDatesValid(isDateValid);
        if (isDateValid) {
            getAnalytics({ startDate: analytics.startDate!, endDate: analytics.endDate! });
        } else {
            setAnalytics({ endDate: analytics.endDate, startDate: analytics.startDate, drugs: [], bloodTargets: [] });
        }
        clearAnalytics();
    }, [clearAnalytics, analytics.endDate, analytics.startDate, getAnalytics, isDateValid]);


    useEffect(() => {
        if (isDateValid) {
            getAnalyticsResult({
                bloodTargetsList: analytics.bloodTargets,
                drugsList: analytics.drugs,
            });
        }
    }, [analytics, isDateValid, getAnalyticsResult]);

    useEffect(() => {
        if (!date) {
            setAnalytics(emptyAnalytics);
        }
    }, [date]);

    return (
        <>
            <SizeWrapper>
                <FormWrapper>
                    <InputWrapperMultiple>
                        <DatePickerBase
                            label={'From'}
                            value={analytics.startDate}
                            onChange={(date: Date | null) => {
                                if (date) {
                                    date.setHours(0, 0, 0, 0);
                                    changeAnalytics(FieldName.startDate, date);
                                } else {
                                    changeAnalytics(FieldName.startDate, null);
                                }
                            }}
                        />
                        <Divider />
                        <DatePickerBase
                            label={'To'}
                            value={analytics.endDate}
                            onChange={(date: Date | null) => {
                                if (date) {
                                    date.setHours(23, 59, 59, 59);
                                    changeAnalytics(FieldName.endDate, date);
                                } else {
                                    changeAnalytics(FieldName.endDate, null);
                                }
                            }}
                        />
                    </InputWrapperMultiple>
                    <DropdownWrapper disabled={!datesValid}>
                        <InputWrapperFullWidth>
                            <AutocompleteMultiple
                                value={analytics.bloodTargets}
                                options={Array.from(new Set(bloodTargetsList))}
                                label={'Blood Targets'}
                                onSelectAll={() => selectAll(FieldName.bloodTargets)}
                                onChange={(target: any | null) => changeAnalytics(FieldName.bloodTargets, target)}
                                type={DropdownMultipleInputWrapper.BLOOD_TARGET}
                                disabled={!datesValid}
                            />
                        </InputWrapperFullWidth>
                        <InputWrapperFullWidth>
                            <AutocompleteMultiple
                                value={analytics.drugs}
                                options={drugsList}
                                label={'Drugs'}
                                onSelectAll={() => selectAll(FieldName.drugs)}
                                onChange={(target: any | null) => changeAnalytics(FieldName.drugs, target)}
                                type={DropdownMultipleInputWrapper.DRUG}
                                disabled={!datesValid}
                            />
                        </InputWrapperFullWidth>
                    </DropdownWrapper>
                </FormWrapper>
            </SizeWrapper>
        </>
    );
};

export default AnalyticsForm;
