import React from 'react';
import HeaderAuth from './HeaderAuth';
import HeaderUnAuth from './HeaderUnAuth';
import { connect } from 'react-redux';
import { UserModel } from '../../models/responses/user.response';
import * as fromRoot from '../../store/reducers/index';

interface StoreModel {
    user?: UserModel | null;
}

const Header: React.FC<StoreModel> = props => {
    const { user } = props;
    return <>{user ? <HeaderAuth /> : <HeaderUnAuth />}</>;
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        user: store.authorization.user,
    }),
    null
)(Header);
