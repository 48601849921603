import React, { useMemo } from 'react';
import Tour, { ReactourStep } from 'reactour';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { Actions } from '../../store/actions/tour.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { useCallback } from 'react';
import TourStep from './components/TourStep';
import { colors } from '../../theme/colors';
import { RouteComponentProps, withRouter } from 'react-router';
import { PatientStepClassName } from './constants/patientStepsClassNames';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import { UserModel } from '../../models/responses/user.response';

interface StoreModel {
    isTourVisible: boolean;
    user: UserModel | null;
}

interface DispatchModel {
    setTourVisible: typeof Actions.setTourVisible;
    setIsSetupToTrue: typeof PatientActions.setIsSetupToTrue;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const PatientTour: React.FC<PropsTypes> = ({
    isTourVisible,
    setTourVisible,
    setIsSetupToTrue,
    history,
    user,
    location: { pathname },
}) => {
    const closeTour = useCallback(() => {
        setTourVisible(false);
    }, [setTourVisible]);

    const redirect = useCallback(
        (link: string) => {
            if (link !== pathname) {
                history.push(link);
            }
        },
        [history, pathname]
    );

    const tourSteps = useMemo(() => {
        return [
            {
                selector: `.${PatientStepClassName.WELCOME}`,
                action: () => {
                    redirect('/welcome');
                },
                content: () => (
                    <TourStep
                        heading={'Hello!'}
                        text={'This tutorial will guide you through basic steps of UpMed usage.'}
                    />
                ),
                position: 'left',
            },
            {
                selector: `.${PatientStepClassName.PROFILE}`,
                action: () => {
                    redirect('/edit-your-profile');
                },
                content: () => (
                    <TourStep
                        heading={'Manage Profile.'}
                        text={'Add information about your age and contacts for us to know you better.'}
                    />
                ),
                position: 'top',
            },
            {
                selector: `.${PatientStepClassName.VIEW_PROVIDER}`,
                action: () => {
                    redirect('/see-provider-status');
                },
                content: () => (
                    <TourStep
                        heading={'Choose a Provider.'}
                        text={
                            'Your personal doctor will take care of your weight issues, blood analysis and prescribe you treatment if you need any.'
                        }
                    />
                ),
                position: 'left',
            },
            {
                selector: `.${PatientStepClassName.CHOOSE_PROVIDER}`,
                action: () => {
                    redirect('/search/providers');
                },
                content: () => (
                    <TourStep
                        heading={'You can pick any provider from the list.'}
                        text={'Once you have decided who you want to work with click on that provider.'}
                    />
                ),
                position: 'right',
            },
            {
                selector: `.${PatientStepClassName.SEND_REQUEST}`,
                action: () => {
                    redirect('/send-request-to-provider');
                },
                content: () => (
                    <TourStep
                        heading={'Send a request.'}
                        text={
                            'The provider will get your request after you click this button. You need to be approved by a provider to become connected with them.'
                        }
                    />
                ),
                position: 'left',
            },
            {
                selector: `.${PatientStepClassName.NOTIFICATION}`,
                content: () => (
                    <TourStep
                        heading={'Notification.'}
                        text={'Once you get approval by a provider you will see a notification.'}
                    />
                ),
                position: 'left',
            },

            {
                selector: `.${PatientStepClassName.NOTIFICATIONS}`,
                action: () => {
                    redirect('/notifications');
                },
                content: () => (
                    <TourStep
                        heading={'Notifications.'}
                        text={
                            'This is your notifications page. We will keep you posted about your provider, payments, dosages, bloodwork and other things.'
                        }
                    />
                ),
                position: 'bottom',
            },

            {
                selector: `.${PatientStepClassName.PAYMENT}`,
                action: () => {
                    redirect('/payments/settings');
                },
                content: () => (
                    <TourStep
                        heading={'Manage Payment Into.'}
                        text={'Save your card here and use it for further payments.'}
                    />
                ),
                position: 'bottom',
            },
            {
                selector: `.${PatientStepClassName.FINISH}`,
                action: () => {
                    if (user && !user.setup) {
                        setIsSetupToTrue();
                    }
                    redirect('/patient-start');
                },
                content: () => (
                    <TourStep
                        heading={'This is it!'}
                        text={'You are ready to use UpMed. If you need any help just click here.'}
                    />
                ),
                position: 'right',
            },
        ] as ReactourStep[];
    }, [redirect, setIsSetupToTrue, user]);

    if (!isTourVisible) return null;
    return (
        <Tour
            isOpen={isTourVisible}
            onRequestClose={closeTour}
            steps={tourSteps}
            accentColor={colors.redMain}
            rounded={10}
        />
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        isTourVisible: store.tour.isTourVisible,
        user: store.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setTourVisible: bindActionCreators(Actions.setTourVisible, dispatch),
        setIsSetupToTrue: bindActionCreators(PatientActions.setIsSetupToTrue, dispatch),
    })
)(withRouter(PatientTour));
