export const colors = {
    white: '#FFF',
    black: '#000',

    textPrimary: '#141A33',
    textSecondary: '#666B80',
    gray1: '#E7E7E7',
    gray2: '#F4F4F4',
    gray3: '#C4C4C4',
    gray4: '#838282',
    grayBorder: '#DFE6EE',
    grayUnderline: '#C0C0C0',
    accordionGray: '#d6d6d6',
    whiteSmoke: '#f3f3f3',

    redLight: '#CF0000',
    redMain: '#9E0000',
    redDark: '#7B0101',
    redDisabled: '#FFD6D6',
    redTransparent: '#eadcdd',
    redText: '#8D0000',

    blueMain: '#00599E',
    blueLight: '#036DBF',

    success: '#56BD5B',

    drawerBackground: 'rgba(20, 26, 51, 0.7)',
};
