import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { Actions as TherapiesActions } from '../../../store/actions/therapies.actions';
import { bindActionCreators, Dispatch } from 'redux';
import DropdownInputList from '../DropdownInputList';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { DefaultPageSize, shouldFetchData } from '../../../constants/dataFetching/allEntitiesByPages';
import { useCompare } from '../../../helpers/usePreviousHelper';

interface InjectedProps {
    onChange: (entity: BaseDropdownItem | null) => void;
    label: string;
    value?: BaseDropdownItem | null;
}

interface StateModel {
    loading: boolean;
    allTherapies: BaseDropdownItem[];
    totalTherapies: number | null;
    page: number;
    searchQuery: string;
}

interface DispatchModel {
    increasePageNumber: typeof TherapiesActions.increasePageNumber;
    getAllTherapies: typeof TherapiesActions.getAllTherapies;
    setFilterQuery: typeof TherapiesActions.setSearchQuery;
}

type PropsTypes = InjectedProps & DispatchModel & StateModel;

const DropdownInputTherapiesListWrapper: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);

    const {
        loading,
        page,
        searchQuery,
        setFilterQuery,
        label,
        increasePageNumber,
        onChange,
        value,
        allTherapies,
        getAllTherapies,
        totalTherapies,
    } = props;

    const isSearchQueryChanged = useCompare(searchQuery);

    const loadData = useCallback(
        (page: number, size: number, sort: string, searchQuery: string, overrideRecords: boolean) => {
            getAllTherapies({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllTherapies]
    );

    useEffect(() => {
        setFilterQuery('');
        loadData(0, DefaultPageSize, '', '', true);
        setInitialLoaded(true);
    }, [setFilterQuery, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(loading, page, allTherapies.length, initialLoaded, DefaultPageSize) ||
            isSearchQueryChanged
        ) {
            loadData(page, DefaultPageSize, '', searchQuery, false);
        }
    }, [loading, page, searchQuery, allTherapies, initialLoaded, loadData, isSearchQueryChanged]);

    return (
        <DropdownInputList
            value={value}
            onChange={onChange}
            label={label}
            loading={loading}
            allEntities={allTherapies}
            totalEntities={totalTherapies}
            searchQuery={searchQuery}
            increasePageNumber={increasePageNumber}
            setFilterQuery={setFilterQuery}
        />
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.therapy.loading,
        allTherapies: state.therapy.allTherapies,
        totalTherapies: state.therapy.totalTherapies,
        page: state.therapy.page,
        searchQuery: state.therapy.searchQuery,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllTherapies: bindActionCreators(TherapiesActions.getAllTherapies, dispatch),
        increasePageNumber: bindActionCreators(TherapiesActions.increasePageNumber, dispatch),
        setFilterQuery: bindActionCreators(TherapiesActions.setSearchQuery, dispatch),
    })
)(DropdownInputTherapiesListWrapper);
