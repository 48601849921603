import React, { useCallback, useState } from 'react';
import {
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography,
} from '@material-ui/core';
import cardIcon from '../../../../static/icons/payment/card-icon.svg';
import removeCardIcon from '../../../../static/icons/payment/remove-card-icon.svg';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import SetAsDefault from './SetAsDefault';
import DefaultMarker from './DefaultMarker';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import cardChecked from '../../../../static/icons/payment/card-checked.svg';
import cardUnchecked from '../../../../static/icons/payment/card-uncheck.svg';
import { CardModel } from '../../../../models/responses/card.model';
import { connect } from 'react-redux';
import * as CardManagementActions from '../../../../store/actions/cardManagement.actions';
import { bindActionCreators, Dispatch } from 'redux';

interface InjectedProps {
    card: CardModel;
    isRadio?: boolean;
    handleChange?: (id: string) => void;
    selected?: boolean;
}

interface DispatchModel {
    deleteCard: typeof CardManagementActions.Actions.deleteCard;
    makeCardDefault: typeof CardManagementActions.Actions.makeCardDefault;
}

type PropsTypes = DispatchModel & InjectedProps;

const CardNumber = styled.div`
    display: flex;
    align-items: center;
    height: 10px;
`;

const createStyles = makeStyles({
    listItem: {
        padding: '9px 30px 5px',
    },
    listItemContainer: {
        listStyleType: 'none !important',
    },
    listItemText: {
        margin: '0 !important',
    },
    secondaryActions: {
        display: 'flex',
        alignItems: 'center',
        margin: '2px 0',
        right: '32px',
    },
    cardNumber: {
        fontSize: '16px',
        marginRight: '4px',
    },
    date: {
        fontSize: '12px',
        lineHeight: '15px',
        color: colors.textSecondary,
    },
    icon: {
        border: `1px solid ${colors.gray3}`,
        borderRadius: '20px',
    },
    iconButton: {
        padding: '0 !important',
        marginLeft: '6px ',
    },
});

const CardListItem: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const { card, handleChange, selected, isRadio, deleteCard, makeCardDefault } = props;

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openSetDefaultDialog, setSetDefaultDialog] = useState(false);

    const toggleSetDefaultDialog = useCallback(() => {
        setSetDefaultDialog(!openSetDefaultDialog);
    }, [openSetDefaultDialog]);

    const toggleDeleteDialog = useCallback(() => {
        setOpenDeleteDialog(!openDeleteDialog);
    }, [openDeleteDialog]);

    const onClick = useCallback(() => {
        !!handleChange && handleChange(card.paymentMethodId);
    }, [handleChange, card]);

    const getMonth = useCallback((month: string) => {
        return ('0' + month).slice(-2);
    }, []);

    const confirmDeleteCard = useCallback(() => {
        deleteCard(card.paymentMethodId);
        toggleDeleteDialog();
    }, [deleteCard, card, toggleDeleteDialog]);

    const confirmMakeCardDefault = useCallback(() => {
        makeCardDefault(card.paymentMethodId);
        toggleSetDefaultDialog();
    }, [makeCardDefault, card, toggleSetDefaultDialog]);

    return (
        <>
            <ListItem
                // @ts-ignore
                button={!!isRadio}
                classes={{ root: classes.listItem, container: classes.listItemContainer }}
                onClick={onClick}
            >
                <ListItemAvatar>
                    <img src={cardIcon} alt="" className={classes.icon} />
                </ListItemAvatar>
                <ListItemText
                    classes={{ root: classes.listItemText }}
                    primary={
                        <CardNumber>
                            <Typography variant={'body2'} classes={{ root: classes.cardNumber }}>
                                **** **** **** {card.last4}
                            </Typography>
                            {card.isDefault && <DefaultMarker />}
                        </CardNumber>
                    }
                    secondary={
                        <Typography variant={'body1'} classes={{ root: classes.date }}>
                            {getMonth(card.expMonth)} / {card.expYear}
                        </Typography>
                    }
                />
                <ListItemSecondaryAction classes={{ root: classes.secondaryActions }}>
                    {isRadio ? (
                        <img src={selected ? cardChecked : cardUnchecked} alt="" />
                    ) : (
                        <>
                            {!card.isDefault && <SetAsDefault onClick={toggleSetDefaultDialog} />}
                            <IconButton classes={{ root: classes.iconButton }} onClick={toggleDeleteDialog}>
                                <img src={removeCardIcon} alt="" />
                            </IconButton>
                        </>
                    )}
                </ListItemSecondaryAction>
            </ListItem>

            <ConfirmationDialog
                text={'Are you sure you want to set\nthis card as default?'}
                open={openSetDefaultDialog}
                close={toggleSetDefaultDialog}
                yesAction={confirmMakeCardDefault}
            />

            <ConfirmationDialog
                text={'Are you sure you want\nto delete this card?'}
                open={openDeleteDialog}
                close={toggleDeleteDialog}
                yesAction={confirmDeleteCard}
            />
        </>
    );
};

export default connect(
    () => ({}),
    (dispatch: Dispatch): DispatchModel => ({
        deleteCard: bindActionCreators(CardManagementActions.Actions.deleteCard, dispatch),
        makeCardDefault: bindActionCreators(CardManagementActions.Actions.makeCardDefault, dispatch),
    })
)(CardListItem);
