import React from 'react';
import styled from "styled-components";
import {colors} from "../../../theme/colors";
import {makeStyles, Typography} from "@material-ui/core";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    background: ${colors.redMain};
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 15px;
    min-width: 23px;
    padding-top: 3px;
`;

const createStyles = makeStyles({
    badgeText: {
        color: `${colors.white} !important`,
    },
});

const Badge: React.FC = (props) => {
    const classes = createStyles(props);
    return (
        <Wrapper>
            <Typography variant={'button'} classes={{ root: classes.badgeText }}>
                {props.children}
            </Typography>
        </Wrapper>
    )
};

export default Badge;