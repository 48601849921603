import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PaymentTabs from './components/PaymentTabs';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import { Fade } from '@material-ui/core';
import HistoryBlock from './components/history/HistoryBlock';
import { connect } from 'react-redux';
import { InvoiceModel } from '../../models/responses/invoice.model';
import * as fromRoot from '../../store/reducers';
import * as invoiceAction from '../../store/actions/invoice.actions';
import * as authAction from '../../store/actions/auth.actions';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../shared/Loading';
import RecurringSubscriptionOnePatient from './components/RecurringSubscriptionOnePatient';
import InactiveBlock from './components/history/InactiveBlock';
import DropdownInputPatientListWrapper from '../../shared/input/dropdownAutocompleteWrappers/DropdownInputLinkedPatientListWrapper';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { UserModel } from '../../models/responses/user.response';
import RequestState from '../../constants/requestState';
import LoadingTransparent from '../shared/LoadingTransparent';
import FabButton from '../../shared/button/FabButton';
import { RouteComponentProps, withRouter } from 'react-router';
import ProviderAccount from './components/ProviderAccount';
import RecurringSubscriptionAllPatientsActive from './components/RecurringSubscriptionAllPatientsActive';
import OneTimeRequestedAllPatients from './components/OneTimeRequestedAllPateients';
import RecurringSubscriptionAllPatientsRequested from './components/RecurringSubscriptionAllPatientsRequested';
import OneTimeRequestedOnePateient from './components/OneTimeRequestedOnePateient';
import { PaymentRoutingProvider } from '../../constants/tabRouting/routing';

interface StateModel {
    loadingOneTimeRequested: boolean;
    invoiceOneTimeRequested: InvoiceModel[];
    totalInvoiceOneTimeRequested: number | null;
    totalAmountRecurringSubscriptionPatient: number;

    recurringSubscription: InvoiceModel[];
    loadingRecurringSubscription: boolean;

    user: UserModel | null;
    deleteRequest: RequestState;

    isMerchantVerified: boolean;
    isMerchantVerifiedRequest: RequestState;

    countOneTimeRequested: number;
    countSubscriptionRequested: number;
}

interface DispatchModel {
    resetArchived: typeof invoiceAction.Actions.resetArchived;
    resetStatusVariables: typeof invoiceAction.Actions.resetStatusVariables;
    getCurrentUser: typeof authAction.Actions.getCurrentUser;
    verifyMerchant: typeof invoiceAction.Actions.verifyMerchant;
    getInvoicesCountProviderAllPatients: typeof invoiceAction.Actions.getInvoicesCountProviderAllPatients;
    getAllRecurringSubscriptionProvider: typeof invoiceAction.Actions.getAllRecurringSubscriptionProvider;
    getInvoicesCountProviderPatient: typeof invoiceAction.Actions.getInvoicesCountProviderPatient;
}

const PatientWrapper = styled.div`
    padding: 16px 0 30px;
    background-color: ${colors.gray2};
`;

const Wrapper = styled.div`
    margin-bottom: 85px;
`;

const TabsWrapper = styled.div``;

type PropsTypes = DispatchModel & StateModel & RouteComponentProps<any>;

const PaymentProvider: React.FC<PropsTypes> = props => {
    const [activeTab, setActiveTab] = useState(0);

    const [patient, setPatient] = useState<BaseDropdownItem | null>(null);

    const {
        loadingOneTimeRequested,
        recurringSubscription,
        loadingRecurringSubscription,
        resetArchived,
        resetStatusVariables,
        deleteRequest,
        user,
        getCurrentUser,
        verifyMerchant,
        isMerchantVerified,
        isMerchantVerifiedRequest,
        getInvoicesCountProviderAllPatients,
        countOneTimeRequested,
        countSubscriptionRequested,
        getAllRecurringSubscriptionProvider,
        getInvoicesCountProviderPatient,
    } = props;

    useEffect(() => {
        switch (props.history.location.pathname) {
            case PaymentRoutingProvider[0].path: {
                setActiveTab(PaymentRoutingProvider[0].tab);
                break;
            }
            case PaymentRoutingProvider[1].path: {
                setActiveTab(PaymentRoutingProvider[1].tab);
                break;
            }
            case PaymentRoutingProvider[2].path: {
                setActiveTab(PaymentRoutingProvider[2].tab);
                break;
            }
            default: {
                setActiveTab(0);
            }
        }
    }, [props.history.location.pathname]);

    const setTab = useCallback(
        (newActiveTab: number) => {
            if(activeTab !== newActiveTab) {
                setActiveTab(newActiveTab);
                resetArchived();
            }
        },
        [resetArchived, activeTab]
    );

    useEffect(() => {
        getCurrentUser();
        verifyMerchant();
    }, [getCurrentUser, verifyMerchant]);

    const canFetchPaymentData = useMemo(() => {
        return (
            deleteRequest !== RequestState.SENDING &&
            isMerchantVerifiedRequest === RequestState.SENT_SUCCESS &&
            isMerchantVerified
        );
    }, [deleteRequest, isMerchantVerifiedRequest, isMerchantVerified]);

    useEffect(() => {
        if (canFetchPaymentData) {
            if (patient && patient.id) {
                getAllRecurringSubscriptionProvider(patient.id);
                getInvoicesCountProviderPatient(patient.id);
            } else {
                getInvoicesCountProviderAllPatients();
            }
        }
    }, [
        canFetchPaymentData,
        patient,
        deleteRequest,
        getInvoicesCountProviderAllPatients,
        getInvoicesCountProviderPatient,
        getAllRecurringSubscriptionProvider,
    ]);

    useEffect(() => {
        if (deleteRequest === RequestState.SENT_FAILED || deleteRequest === RequestState.SENT_SUCCESS) {
            resetStatusVariables();
        }
    }, [deleteRequest, resetStatusVariables]);

    const loading = useMemo(() => {
        return loadingOneTimeRequested && loadingRecurringSubscription;
    }, [loadingOneTimeRequested, loadingRecurringSubscription]);

    useEffect(() => {
        resetStatusVariables();
        return () => {
            resetArchived();
        };
    }, [resetStatusVariables, resetArchived]);

    useEffect(() => {
        user && !user.merchant && props.history.push('/create-merchant');
        !isMerchantVerified &&
            isMerchantVerifiedRequest === RequestState.SENT_SUCCESS &&
            props.history.push('/setup-merchant');
    }, [user, props.history, isMerchantVerified, isMerchantVerifiedRequest]);

    const canGetPayment = useMemo(() => {
        return user && user.merchant && !!isMerchantVerified;
    }, [user, isMerchantVerified]);

    const loadingAllScreen = useMemo(() => {
        return deleteRequest === RequestState.SENDING || isMerchantVerifiedRequest === RequestState.SENDING;
    }, [deleteRequest, isMerchantVerifiedRequest]);

    return (
        <>
            {loadingAllScreen && <LoadingTransparent />}

            <HeaderHeadingWithLink goBackLink={'/provider/my-links'}>Payments</HeaderHeadingWithLink>

            {canGetPayment ? (
                <>
                    <PatientWrapper>
                        <SizeControllerWrapper>
                            <DropdownInputPatientListWrapper
                                label={'Patient'}
                                onChange={(entity: BaseDropdownItem | null) => {
                                    resetArchived();
                                    setPatient(entity);
                                }}
                            />
                        </SizeControllerWrapper>
                    </PatientWrapper>

                    <Wrapper>
                        <TabsWrapper>
                            <PaymentTabs
                                activeTab={activeTab}
                                setActiveTab={setTab}
                                oneTime={countOneTimeRequested}
                                subscription={
                                    patient && patient.id ? recurringSubscription.length : countSubscriptionRequested
                                }
                                user={user}
                            />
                        </TabsWrapper>

                        {loading && <Loading />}

                        {activeTab === 0 && (
                            <Fade in={activeTab === 0} timeout={{ enter: 500, exit: 500 }}>
                                <>
                                    {patient && patient.id ? (
                                        <OneTimeRequestedOnePateient
                                            canFetchPaymentData
                                            patientId={patient && patient.id}
                                        />
                                    ) : (
                                        <OneTimeRequestedAllPatients canFetchPaymentData />
                                    )}
                                    <HistoryBlock patientId={patient && patient.id} />
                                </>
                            </Fade>
                        )}

                        {activeTab === 1 && (
                            <Fade in={activeTab === 1} timeout={{ enter: 500, exit: 500 }}>
                                <>
                                    {patient && patient.id ? (
                                        <RecurringSubscriptionOnePatient
                                            patientId={patient && patient.id}
                                            invoices={recurringSubscription}
                                            user={user}
                                        />
                                    ) : (
                                        <>
                                            <RecurringSubscriptionAllPatientsRequested
                                                canFetchPaymentData
                                                user={user}
                                            />
                                            <RecurringSubscriptionAllPatientsActive />
                                        </>
                                    )}
                                    <InactiveBlock patientId={patient && patient.id} />
                                </>
                            </Fade>
                        )}

                        {activeTab === 2 && (
                            <Fade in={activeTab === 2} timeout={{ enter: 500, exit: 500 }}>
                                <>
                                    <ProviderAccount>Go To Stripe</ProviderAccount>
                                </>
                            </Fade>
                        )}
                    </Wrapper>

                    <FabButton activeTab={activeTab} redirectUrl={'/add-payment'} />
                </>
            ) : (
                <Loading smallSize />
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loadingOneTimeRequested: state.invoice.loadingOneTimeRequested,
        invoiceOneTimeRequested: state.invoice.invoiceOneTimeRequested,
        totalInvoiceOneTimeRequested: state.invoice.totalInvoiceOneTimeRequested,
        totalAmountRecurringSubscriptionPatient: state.invoice.totalAmountRecurringSubscriptionPatient,

        recurringSubscription: state.invoice.recurringSubscriptionPatient,

        loadingRecurringSubscription: state.invoice.loadingRecurringSubscription,

        user: state.authorization.user,
        deleteRequest: state.invoice.deleteRequest,
        isMerchantVerified: state.invoice.isMerchantVerified,
        isMerchantVerifiedRequest: state.invoice.isMerchantVerifiedRequest,

        countOneTimeRequested: state.invoice.countOneTimeRequested,
        countSubscriptionRequested: state.invoice.countSubscriptionRequested,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        resetArchived: bindActionCreators(invoiceAction.Actions.resetArchived, dispatch),
        resetStatusVariables: bindActionCreators(invoiceAction.Actions.resetStatusVariables, dispatch),
        getCurrentUser: bindActionCreators(authAction.Actions.getCurrentUser, dispatch),
        verifyMerchant: bindActionCreators(invoiceAction.Actions.verifyMerchant, dispatch),
        getAllRecurringSubscriptionProvider: bindActionCreators(
            invoiceAction.Actions.getAllRecurringSubscriptionProvider,
            dispatch
        ),
        getInvoicesCountProviderAllPatients: bindActionCreators(
            invoiceAction.Actions.getInvoicesCountProviderAllPatients,
            dispatch
        ),
        getInvoicesCountProviderPatient: bindActionCreators(
            invoiceAction.Actions.getInvoicesCountProviderPatient,
            dispatch
        ),
    })
)(withRouter(PaymentProvider));
