import React, { useEffect } from 'react';
import * as NotificationsActions from '../../store/actions/notifications.actions';
import { TYPED_ENV } from '../../helpers/typedEnv';
// @ts-ignore
import { Stomp, IFrame } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

interface InjectedProps {
    token: string | null;
    userId: number | null;
}

interface DispatchModel {
    openedWsConnection: typeof NotificationsActions.NotificationActions.openedWsConnection;
    wsReceived: typeof NotificationsActions.NotificationActions.wsReceived;
}

type PropsTypes = InjectedProps & DispatchModel;

const WSProvider: React.FC<PropsTypes> = props => {
    const { userId, token, wsReceived, openedWsConnection } = props;

    useEffect(() => {
        if (userId && token) {
            const socket = new SockJS(`${TYPED_ENV.REACT_APP_WEBSOCKET_URL}?token=${token}`);
            const StompClient = Stomp.over(socket);

            const onConnect = () => {
                openedWsConnection(StompClient);

                StompClient.subscribe(`/user/${userId}/notification`, (e: IFrame) => {
                    const body = JSON.parse(e.body);
                    console.log(body);
                    if (body.hasOwnProperty('notifications')) {
                        wsReceived(body.notifications);
                    } else {
                        wsReceived([body]);
                    }
                });
            };

            StompClient.connect({}, onConnect);
        }
    }, [userId, openedWsConnection, token, wsReceived]);

    return <></>;
};

export default connect(
    () => ({}),
    (dispatch: Dispatch): DispatchModel => ({
        openedWsConnection: bindActionCreators(NotificationsActions.NotificationActions.openedWsConnection, dispatch),
        wsReceived: bindActionCreators(NotificationsActions.NotificationActions.wsReceived, dispatch),
    })
)(WSProvider);
