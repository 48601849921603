import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProviderMyPatientActions } from '../../store/actions/providerMyPatients.actions';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import ScrollableList from '../../shared/scrollable-list/ScrollableList';
import { PatientModel } from '../../models/responses/patient.response';
import { SortOptions } from '../../constants/sortOptions';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers/index';
import NoPatients from './NoPatients';
import PatientTypes from '../../constants/patientTypes';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../constants/layout';
import { DefaultPageSize, shouldFetchData } from '../../constants/dataFetching/allEntitiesByPages';
import {useCompare} from "../../helpers/usePreviousHelper";

interface DispatchModel {
    getAllLinkedPatients: typeof ProviderMyPatientActions.getAllLinkedPatients;
    increasePageNumberLinkedPatients: typeof ProviderMyPatientActions.increasePageNumberLinkedPatients;
    setFilterQueryLinkedPatients: typeof ProviderMyPatientActions.setFilterQueryLinkedPatients;
    resetStatusVariables: typeof PatientActions.resetStatusVariables;
}

interface StoreModel {
    myLinkedPatients: PatientModel[];
    totalLinkedPatients: number;
    pageLinkedPatients: number;
    searchQueryLinkedPatients: string;
    loading: boolean;
}

type PropsTypes = StoreModel & DispatchModel;

function LinkedPatientsList(props: PropsTypes) {
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const {
        loading,
        resetStatusVariables,
        getAllLinkedPatients,
        increasePageNumberLinkedPatients,
        myLinkedPatients,
        pageLinkedPatients,
        searchQueryLinkedPatients,
        setFilterQueryLinkedPatients,
        totalLinkedPatients,
    } = props;
    const isSearchQueryChanged = useCompare(searchQueryLinkedPatients);

    const loadLinkedPatients = useCallback(
        (page: number, size: number, sort: SortOptions, q: string, override: boolean) => {
            getAllLinkedPatients({
                page,
                size,
                sort,
                searchQuery: q,
                overrideRecords: override,
            });
        },
        [getAllLinkedPatients]
    );

    useEffect(() => {
        resetStatusVariables();
        setFilterQueryLinkedPatients('');
        loadLinkedPatients(0, 10, SortOptions.LAST_NAME_ASCENDING, '', true);
        setInitialLoaded(true);
    }, [resetStatusVariables, setFilterQueryLinkedPatients, loadLinkedPatients]);

    useEffect(() => {
        if (shouldFetchData(loading, pageLinkedPatients, myLinkedPatients.length, initialLoaded, DefaultPageSize) || isSearchQueryChanged) {
            loadLinkedPatients(
                pageLinkedPatients,
                DefaultPageSize,
                SortOptions.LAST_NAME_ASCENDING,
                searchQueryLinkedPatients,
                false
            );
        }
    }, [searchQueryLinkedPatients, loading, initialLoaded, loadLinkedPatients, myLinkedPatients, pageLinkedPatients, isSearchQueryChanged]);

    const getMoreLinkedPatients = useCallback(() => {
        increasePageNumberLinkedPatients();
    }, [increasePageNumberLinkedPatients]);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    return (
        <>
            {!loading && totalLinkedPatients === 0 && !searchQueryLinkedPatients.length ? (
                <NoPatients type={PatientTypes.LINKED} height={height} />
            ) : (
                <ScrollableList
                    isLoading={loading}
                    totalRecordsNumber={totalLinkedPatients}
                    data={myLinkedPatients}
                    nextAction={loading ? () => {} : getMoreLinkedPatients}
                    height={height}
                    type={'patient'}
                />
            )}
        </>
    );
}

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        myLinkedPatients: state.providerMyPatients.myLinkedPatients,
        totalLinkedPatients: state.providerMyPatients.totalLinkedPatients,
        pageLinkedPatients: state.providerMyPatients.pageLinkedPatients,
        searchQueryLinkedPatients: state.providerMyPatients.searchQueryLinkedPatients,
        loading: state.providerMyPatients.loading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllLinkedPatients: bindActionCreators(ProviderMyPatientActions.getAllLinkedPatients, dispatch),
        setFilterQueryLinkedPatients: bindActionCreators(
            ProviderMyPatientActions.setFilterQueryLinkedPatients,
            dispatch
        ),
        increasePageNumberLinkedPatients: bindActionCreators(
            ProviderMyPatientActions.increasePageNumberLinkedPatients,
            dispatch
        ),
        resetStatusVariables: bindActionCreators(PatientActions.resetStatusVariables, dispatch),
    })
)(LinkedPatientsList);
