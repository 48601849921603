import React from 'react';
import styled from 'styled-components';
import {mediaQuery, size} from '../constants/device';

const ContentWrapper = styled.div<{isLanding: boolean}>`
    margin: 0 auto;
    ${mediaQuery.laptop} {
        width: ${props => props.isLanding ? '100%' : '90%'}
    }
    ${mediaQuery.laptopL} {
        width: ${props => props.isLanding ? '100%' : '77%'}
    }
    ${mediaQuery.desktop} {
        width: ${props => props.isLanding ? '100%' : '90%'}
        max-width: ${size.laptopL}
    }
`;

const SizeControllerWrapperDesktopOnly: React.FC<{isLanding: boolean}> = props => {
    return <ContentWrapper isLanding={props.isLanding}>{props.children}</ContentWrapper>;
};

export default SizeControllerWrapperDesktopOnly;