type ThenArg<T> = T extends Promise<infer U>
    ? U
    : T extends (..._: any) => Promise<infer V>
    ? V
    : T extends (..._: any) => infer R
    ? R
    : unknown;

/**
 *
 * @param _ describes type resolving from Promise
 * @param yieldValue include value
 */

export const typedCall = <F extends (..._: any) => any>(_: F, yieldValue: any): ThenArg<F> => {
    return yieldValue;
};
