import { restAxios } from '../restAxios';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { DeleteInvoiceDto } from '../../models/dto/deleteInvoice.dto';
import { AddInvoiceDto } from '../../models/dto/addInvoice.dto';
import { EditInvoiceDto } from '../../models/dto/editInvoiceDto';
import { ConnectOauthDto } from '../../models/dto/connectOauth.dto';
import GetInvoiceProviderDto from '../../models/dto/getInvoiceProvider.dto';
import { PayInvoiceDto } from '../../models/dto/payInvoice.dto';

class InvoiceApi {
    @HandleAsyncErrors()
    static async getOneTimeRequestedPatient(data: AllEntitiesByPagesDto) {
        const { size, page } = data;
        const res = await restAxios.get('private/invoices/once/requested', {
            params: {
                page,
                size,
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getOneTimeRequestedCountPatient() {
        const res = await restAxios.get('private/invoices/once/requested/count');
        return res.data;
    }

    @HandleAsyncErrors()
    static async getOneTimeArchivedPatient(data: AllEntitiesByPagesDto) {
        const { size, page, sort } = data;
        const res = await restAxios.get('private/invoices/once/archived', {
            params: {
                page,
                size,
                sort: sort && encodeURIComponent(sort),
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getRecurringSubscriptionPatient() {
        const res = await restAxios.get('private/invoices/recurring/subscription');
        return res.data;
    }

    @HandleAsyncErrors()
    static async getRecurringArchivedPatient(data: AllEntitiesByPagesDto) {
        const { size, page, sort } = data;
        const res = await restAxios.get('private/invoices/recurring/archived', {
            params: {
                page,
                size,
                sort: sort && encodeURIComponent(sort),
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getInvoicesCountProviderAllPatients() {
        const res = await restAxios.get('private/invoices/requested/count/patient');
        return res.data;
    }

    @HandleAsyncErrors()
    static async getOneTimeRequestedProvider(data: { data: AllEntitiesByPagesDto; patientId: number }) {
        const { size, page } = data.data;
        const { patientId } = data;
        const res = await restAxios.get(`private/invoices/once/requested/patient/${patientId}`, {
            params: {
                page,
                size,
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getOneTimeArchivedProvider(data: { data: AllEntitiesByPagesDto; patientId: number }) {
        const { size, page, sort } = data.data;
        const res = await restAxios.get(`private/invoices/once/archived/patient/${data.patientId}`, {
            params: {
                page,
                size,
                sort: sort && encodeURIComponent(sort),
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getRecurringSubscriptionProvider(patientId: number) {
        const res = await restAxios.get(`private/invoices/recurring/subscription/patient/${patientId}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async getInvoicesCountProvider(patientId: number) {
        const res = await restAxios.get(`private/invoices/once/requested/count/patient/${patientId}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async getRecurringArchivedProvider(data: { data: AllEntitiesByPagesDto; patientId: number }) {
        const { size, page, sort } = data.data;
        const res = await restAxios.get(`private/invoices/recurring/subscription/patient/${data.patientId}`, {
            params: {
                page,
                size,
                sort: sort && encodeURIComponent(sort),
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getOneTimeRequestedProviderAllPatients(data: AllEntitiesByPagesDto) {
        const { size, page } = data;
        const res = await restAxios.get('private/invoices/once/requested/patient', {
            params: {
                page,
                size,
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getOneTimeArchivedProviderAllPatients(data: AllEntitiesByPagesDto) {
        const { size, page, sort } = data;
        const res = await restAxios.get('private/invoices/once/archived/patient', {
            params: {
                page,
                size,
                sort: sort && encodeURIComponent(sort),
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getRecurringSubscriptionProviderAllPatientsRequested(data: AllEntitiesByPagesDto) {
        const { size, page } = data;
        const res = await restAxios.get('private/invoices/recurring/requested/patient', {
            params: {
                page,
                size,
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getRecurringSubscriptionProviderAllPatientsActive(data: AllEntitiesByPagesDto) {
        const { size, page } = data;
        const res = await restAxios.get('private/invoices/recurring/active/patient', {
            params: {
                page,
                size,
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async getRecurringArchivedProviderAllPatients(data: AllEntitiesByPagesDto) {
        const { size, page, sort } = data;
        const res = await restAxios.get('private/invoices/recurring/archived/patient', {
            params: {
                page,
                size,
                sort: sort && encodeURIComponent(sort),
            },
        });
        return res.data;
    }

    @HandleAsyncErrors()
    static async deleteInvoice(data: DeleteInvoiceDto) {
        const { id, patientId } = data;
        const res = await restAxios.delete(`private/invoices/${id}/patient/${patientId}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async addInvoice(data: AddInvoiceDto) {
        const { patientId } = data;
        const res = await restAxios.post(`private/invoices/patient/${patientId}`, data);
        return res.data;
    }

    @HandleAsyncErrors()
    static async editInvoice(data: EditInvoiceDto) {
        const { patientId, id } = data;
        const res = await restAxios.put(`private/invoices/${id}/patient/${patientId}`, data);
        return res.data;
    }

    @HandleAsyncErrors()
    static async rejectInvoice(id: number) {
        const res = await restAxios.put(`private/invoices/${id}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async getRequestedInvoiceById(data: { id: number; patientId: number }) {
        const { id, patientId } = data;
        const res = await restAxios.get(`private/invoices/${id}/requested/patient/${patientId}`);
        return res.data;
    }

    // For merchant
    @HandleAsyncErrors()
    static async generateState() {
        const res = await restAxios.post(`private/payments/generate-state`);
        return res.data;
    }

    // For merchant
    @HandleAsyncErrors()
    static async connectOAuth(data: ConnectOauthDto) {
        const res = await restAxios.post(`private/payments/connect/oauth`, data);
        return res.data;
    }

    // For merchant
    @HandleAsyncErrors()
    static async getConnectLoginLink() {
        const res = await restAxios.post(`private/payments/connect/login-link`);
        return res.data;
    }

    // For merchant
    static async verifyMerchant() {
        const res = await restAxios.get(`private/payments/verify-account`);
        return res.data;
    }

    // For patient
    @HandleAsyncErrors()
    static async payInvoice(data: PayInvoiceDto) {
        const res = await restAxios.post(`private/payments/pay/invoice/${data.invoiceId}`, data.paymentMethod);
        return res.data;
    }

    // For patient
    @HandleAsyncErrors()
    static async subscribeInvoice(data: PayInvoiceDto) {
        const res = await restAxios.post(`private/payments/subscribe/invoice/${data.invoiceId}`, data.paymentMethod);
        return res.data;
    }

    // For patient
    @HandleAsyncErrors()
    static async unsubscribeInvoice(invoiceId: number) {
        const res = await restAxios.post(`private/payments/unsubscribe/invoice/${invoiceId}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async getInvoiceByIdPatient(invoiceId: number) {
        const res = await restAxios.get(`private/invoices/${invoiceId}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async getInvoiceByIdProvider(data: GetInvoiceProviderDto) {
        const { id, patientId } = data;
        const res = await restAxios.get(`private/invoices/${id}/patient/${patientId}`);
        return res.data;
    }

    static async getClientSecret() {
        const res = await restAxios.post('private/payments/cards');
        return res.data;
    }

    static async getAllCards() {
        const res = await restAxios.get('private/payments/cards');
        return res.data;
    }

    static async deleteCard(paymentMethodId: string) {
        const res = await restAxios.delete(`private/payments/cards/${paymentMethodId}`);
        return res.data;
    }

    static async makeCardDefault(paymentMethodId: string) {
        const res = await restAxios.put(`private/payments/cards/${paymentMethodId}`);
        return res.data;
    }
}

export default InvoiceApi;
