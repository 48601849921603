import React, {useEffect} from 'react';
import styled from "styled-components";
import {createStyles, Typography, withStyles, WithStyles} from "@material-ui/core";
import PrimaryButton from "../../../shared/button/PrimaryButton";
import CustomLink from "../../../shared/link/CustomLink";
import {colors} from "../../../theme/colors";
import SizeControllerWrapper from "../../../shared/SizeControllerWrapper";
import {Actions as AuthActions} from "../../../store/actions/auth.actions";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import Card from "../../../shared/card/Card";

const styles = createStyles({
    heading: {
        fontSize: '24px',
    },
    text: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
});

const BackgroundWrapper = styled.div`
  height: calc(100vh - 227px);
  padding: 52px 0 85px;
  @media screen and (orientation: landscape){
    height: calc(100vh - 140px);
    padding: 4px 0;
  }
  
`;

const ButtonWrapper = styled.div`
    width: fit-content;
    margin: 0 auto;
`;

type DispatchModel = {
    resetStatusVariables: typeof AuthActions.resetStatusVariables
}

type PropsTypes =
    & DispatchModel
    & WithStyles<typeof styles>;

const PasswordChanged: React.FC<PropsTypes> = (props) => {

    const {resetStatusVariables} = props;

    useEffect(() => {
        resetStatusVariables();
    }, [resetStatusVariables]);

    const {classes} = props;
    return (
        <>
            <BackgroundWrapper>
                <SizeControllerWrapper>
                    <Card>
                        <Typography variant='h2' classes={{root: classes.heading}}>
                            Password <br/>
                            Successfully <br/>
                            Changed</Typography>

                        <Typography variant='body1' classes={{root: classes.text}}>
                            Now you can login <br/> and continue using UpMed</Typography>

                        <ButtonWrapper>
                            <CustomLink to={'/sign-in'}>
                                <PrimaryButton>
                                    Login
                                </PrimaryButton>
                            </CustomLink>
                        </ButtonWrapper>
                    </Card>
                </SizeControllerWrapper>
            </BackgroundWrapper>
        </>
    )
};

export default connect(
    null,
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(AuthActions.resetStatusVariables, dispatch)
    })
)(withStyles(styles)(PasswordChanged));