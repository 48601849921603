import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';

const Wrapper = styled.div`
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //margin-bottom: 80px;
    padding: 35px 20px;
    background-color: ${colors.gray2};
`;

const createStyles = makeStyles({
    text: {
        color: colors.textSecondary,
    },
    linkText: {
        fontSize: '16px',
        marginTop: '16px',
        display: 'block',
    },
    downloadLink: {
        color: colors.textPrimary,
    },
});

const TermsMobile: React.FC = props => {
    const classes = createStyles(props);

    return (
        <Wrapper>
            <Typography variant={'body2'} classes={{ root: classes.text }}>
                You can download our Terms and Conditions by clicking the link below.
            </Typography>
            <Typography variant={'button'} classes={{ root: classes.linkText }}>
                <a
                    href="../../../../static/docs/UPMED%20LLC%20Terms%20and%20Conditions.pdf"
                    download
                    className={classes.downloadLink}
                >
                    {' '}
                    UPMED LLC Terms and Conditions
                </a>
            </Typography>
        </Wrapper>
    );
};

export default TermsMobile;
