import React, { useEffect, useState } from 'react';
import ProfileInfoPatientView from '../profile/components/ProfileInfoPatientView';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import { ProviderMyPatientActions } from '../../store/actions/providerMyPatients.actions';
import * as fromRoot from '../../store/reducers';
import { PatientModel } from '../../models/responses/patient.response';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../shared/Loading';
import defaultAvatar from '../../static/icons/defaultAvatar.svg';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { Fade, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import aboutTab from '../../static/icons/provider/aboutTab.svg';
import bloodworkTab from '../../static/icons/provider/bloodworkTab.svg';
import dosageTab from '../../static/icons/provider/dosageTab.svg';
import analyticsTab from '../../static/icons/provider/analyticsTab.svg';
import weightTab from '../../static/icons/provider/weightTab.svg';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import BloodworkReportsList from '../bloodwork/components/BloodworkReportsList';
import theme from '../../theme/theme';
import FabButton from '../../shared/button/FabButton';
import DosageTabs from '../prescription/components/DosageTabs';
import { mediaQuery } from '../../constants/device';

const createStyles = makeStyles({
    name: {
        fontSize: '24px',
    },
    tabs: {
        padding: '27px 0 18px',
        backgroundColor: colors.gray1,
        '& div': {
            justifyContent: 'space-between',
        },
    },
    scroller: {
        padding: '0 22px',
    },
    indicator: {
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: '100%',
            left: 'calc(50% - 4px)',
            borderBottom: `3px solid ${colors.redMain}`,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            width: 0,
            height: 0,
        },
    },
    tab: {
        padding: '0 0 10px 0',
        minWidth: '54px',
    },
    selectedTab: {
        '& span': {
            color: colors.textPrimary,
        },
    },
    tabText: {
        fontSize: '10px',
        textTransform: 'uppercase',
    },
    fab: {
        position: 'fixed',
        right: '15px',
        bottom: '3.5%',
        backgroundColor: colors.redMain,
        boxShadow: theme.boxShadowRed,
        '&:hover': {
            backgroundColor: colors.redMain,
        },
        '&:active': {
            backgroundColor: colors.redMain,
            boxShadow: theme.boxShadowRed,
        },
    },
});

const ProfileHeader = styled.div`
    height: 180px;
    background-color: ${colors.gray2};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    justify-content: space-around;

    ${mediaQuery.laptop} {
        width: calc(100% + 5vw);
    }
    ${mediaQuery.laptopL} {
        width: calc(100% + 11.5vw);
    }
    ${mediaQuery.desktop} {
        width: calc(100% + 5vw);
    }
`;

const Avatar = styled.div<{ isDefaultAvatar: boolean; image: string }>`
    width: 120px;
    height: 120px;
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-size: ${props => (props.isDefaultAvatar ? 'auto' : 'cover')};
    border-radius: 80px;
`;

const ContentWrapper = styled.div`
    ${mediaQuery.laptop} {
        width: calc(100% + 5vw);
    }
    ${mediaQuery.laptopL} {
        width: calc(100% + 11.5vw);
    }
    ${mediaQuery.desktop} {
        width: calc(100% + 5vw);
    }
`;

interface StoreModel {
    patient: PatientModel | null;
    loading: boolean;
    patientTabIndex: number;
}

interface DispatchModel {
    getPatientById: typeof PatientActions.getPatientById;
    setPatientPageTabIndex: typeof ProviderMyPatientActions.setPatientPageTabIndex;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const PatientPage: React.FC<PropsTypes> = props => {
    const [dosageTabIndex, setDosageTabIndex] = useState(0);

    const { patientTabIndex, getPatientById, match } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
        getPatientById(match.params.id);
    }, [getPatientById, match]);

    const classes = createStyles(props);

    return (
        <>
            <ContentWrapper>
                <HeaderHeadingWithLink goBackLink={'/provider/my-links'}>My Patients</HeaderHeadingWithLink>
            </ContentWrapper>

            {props.loading && <Loading />}
            {!props.loading && props.patient && (
                <>
                    <ProfileHeader>
                        <Avatar
                            isDefaultAvatar={!props.patient.avatarThumbnailUrl}
                            image={props.patient.avatarThumbnailUrl ? props.patient.avatarThumbnailUrl : defaultAvatar}
                        />
                        <Typography variant={'h2'} classes={{ root: classes.name }}>
                            {props.patient.firstName} {props.patient.lastName}
                        </Typography>
                    </ProfileHeader>

                    <ContentWrapper>
                    <Tabs
                        value={patientTabIndex}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) =>
                            props.setPatientPageTabIndex(newValue)
                        }
                        indicatorColor="primary"
                        classes={{ root: classes.tabs, indicator: classes.indicator, scroller: classes.scroller }}
                    >
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <>
                                    <Typography variant="button" classes={{ root: classes.tabText }}>
                                        about
                                    </Typography>
                                    <img src={aboutTab} alt="" />
                                </>
                            }
                        />
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <>
                                    <Typography variant="button" classes={{ root: classes.tabText }}>
                                        bloodwork
                                    </Typography>
                                    <img src={bloodworkTab} alt="" />
                                </>
                            }
                        />
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <>
                                    <Typography variant="button" classes={{ root: classes.tabText }}>
                                        dosage
                                    </Typography>
                                    <img src={dosageTab} alt="" />
                                </>
                            }
                        />
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <>
                                    <Typography variant="button" classes={{ root: classes.tabText }}>
                                        weight
                                    </Typography>
                                    <img src={weightTab} alt="" />
                                </>
                            }
                        />
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <>
                                    <Typography variant="button" classes={{ root: classes.tabText }}>
                                        analytics
                                    </Typography>
                                    <img src={analyticsTab} alt="" />
                                </>
                            }
                        />
                    </Tabs>
                    </ContentWrapper>

                    {/*About*/}
                    {patientTabIndex === 0 && (
                        <Fade in={patientTabIndex === 0} timeout={{ enter: 500, exit: 500 }}>
                            <ContentWrapper>
                                <ProfileInfoPatientView patient={props.patient} />
                            </ContentWrapper>
                        </Fade>
                    )}

                    {/*Bloodwork*/}
                    {patientTabIndex === 1 && (
                        <Fade in={patientTabIndex === 1} timeout={{ enter: 500, exit: 500 }}>
                            <ContentWrapper>
                                <SizeControllerWrapper>
                                    <BloodworkReportsList patientId={props.match.params.id} />
                                </SizeControllerWrapper>
                                <FabButton redirectUrl={`/upload-results/${props.match.params.id}`} />
                            </ContentWrapper>
                        </Fade>
                    )}

                    {/*Dosage*/}
                    {patientTabIndex === 2 && (
                        <Fade in={patientTabIndex === 2} timeout={{ enter: 500, exit: 500 }}>
                            <ContentWrapper>
                                <DosageTabs
                                    activeTab={dosageTabIndex}
                                    setActiveTab={index => setDosageTabIndex(index)}
                                />
                                <FabButton redirectUrl={`/add-prescription/${props.match.params.id}`} />
                            </ContentWrapper>
                        </Fade>
                    )}

                    {/*Weight*/}
                    {patientTabIndex === 3 && (
                        <Fade in={patientTabIndex === 3} timeout={{ enter: 500, exit: 500 }}>
                            <ContentWrapper>
                                <SizeControllerWrapper>
                                    <Typography variant="body1">Weight coming soon.</Typography>
                                </SizeControllerWrapper>
                            </ContentWrapper>
                        </Fade>
                    )}

                    {/*Reporting*/}
                    {patientTabIndex === 4 && (
                        <Fade in={patientTabIndex === 4} timeout={{ enter: 500, exit: 500 }}>
                            <ContentWrapper>
                                <SizeControllerWrapper>
                                    {/*<AnalyticsPage/>*/}
                                    <Typography variant="body1">Reporting coming soon.</Typography>
                                </SizeControllerWrapper>
                            </ContentWrapper>
                        </Fade>
                    )}
                </>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        patient: state.patient.patient,
        loading: state.patient.loading,
        patientTabIndex: state.providerMyPatients.patientTabIndex,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getPatientById: bindActionCreators(PatientActions.getPatientById, dispatch),
        setPatientPageTabIndex: bindActionCreators(ProviderMyPatientActions.setPatientPageTabIndex, dispatch),
    })
)(withRouter(PatientPage));
