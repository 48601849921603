import React, {useMemo, useRef} from 'react';
import { Autocomplete } from '@material-ui/lab';
import {makeStyles, TextField, Typography} from '@material-ui/core';
import { colors } from '../../theme/colors';
import theme from '../../theme/theme';
import {BaseDropdownItem} from "../../models/responses/baseModel.responce";

const createStyles = makeStyles({
    autocompleteRoot: {
        width: '100%',
        '& .MuiAutocomplete-clearIndicator': {
            display: 'none',
        },
        '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
        },
    },
    root: {
        '& >div': {
            padding: 0,
        },
        '& input': {
            fontSize: '12px',
            padding: '11px 0 11px 12px !important',
            color: colors.textPrimary,
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: `1px solid ${colors.grayUnderline}`,
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${colors.grayUnderline}`,
        },
    },
    error: {
        '& >div': {
            padding: 0,
        },
        '& input': {
            fontSize: '12px',
            padding: '11px 0 11px 12px !important',
            color: colors.textPrimary,
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: `1px solid ${colors.redMain}`,
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${colors.redMain}`,
        },
    },
    autocompletePaper: {
        borderRadius: 0,
        boxShadow: theme.boxShadowGray,
        '& ul': {
            '& li': {
                minHeight: '24px',
                padding: '6px 8px',
            },
        },
    },
    option: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary,
        fontSize: '12px',
        marginLeft: '12px',
    },
});

interface InjectedProps {
    options: { id: string | number; name: string }[];
    label: string;
    placeholder?: string;
    onChange: (value: BaseDropdownItem | null) => void;
    value?: any
    disabled?: boolean;
    isOptionIdString?: boolean;
    error?: boolean;
}

type PropsTypes = InjectedProps;

const AutocompleteSingle: React.FC<PropsTypes> = props => {
    const inputRef = useRef(null);
    const { disabled, placeholder, options, value, isOptionIdString, error } = props;
    const classes = createStyles(props);

    const getNameById = useMemo(() => {
        if (typeof value === 'number' && !!options) {
            return options.filter(option => option.id === value)[0];
        } else if (typeof value === 'string' && !!options) {
            return isOptionIdString
                ? options.filter(option => option.id === value)[0]
                : options.filter(option => option.name === value)[0];
        } else {
            return {};
        }
    }, [value, options, isOptionIdString]);

    return (
        <>
            <Autocomplete
                disabled={disabled}
                autoHighlight={!value}
                options={options}
                getOptionLabel={(option: any) => {
                    return option ? option.name : '';
                }}
                onChange={(event: React.ChangeEvent<{}>, newValue) => {
                    // props.onChange(newValue ? newValue : '');
                    props.onChange(newValue);
                }}
                renderOption={(option: any) => (
                    <Typography variant="button" classes={{ root: classes.option }}>
                        {option ? option.name : ''}
                    </Typography>
                )}
                classes={{ root: classes.autocompleteRoot, paper: classes.autocompletePaper }}
                defaultValue={value && getNameById}
                value={value && getNameById}
                renderInput={params => (
                    <TextField
                        label={
                            placeholder ? null : (
                                <Typography variant={'button'} classes={{ root: classes.label }}>
                                    {props.label}
                                </Typography>
                            )
                        }
                        placeholder={placeholder && placeholder}
                        {...params}
                        classes={{ root: error ? classes.error : classes.root }}
                        fullWidth
                        ref={inputRef}
                    />
                )}
            />
        </>
    );
};

export default AutocompleteSingle;
