import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { AdminStatisticsModel } from '../../models/responses/adminStatistics';

export enum ActionNames {
    GET_ADMIN_STATISTICS = '[Admin Statistics] Get Admin Statistics',
    GET_ADMIN_STATISTICS_SUCCESS = '[Admin Statistics] Get Admin Statistics Success',
    GET_ADMIN_STATISTICS_FAILED = '[Admin Statistics] Get Admin Statistics Failed',
}

export const Actions = {
    getAdminStatistics: () => createAction(ActionNames.GET_ADMIN_STATISTICS),
    getAdminStatisticsSuccess: (data: AdminStatisticsModel) =>
        createAction(ActionNames.GET_ADMIN_STATISTICS_SUCCESS, data),
    getAdminStatisticsFailed: () => createAction(ActionNames.GET_ADMIN_STATISTICS_FAILED),
};

export type Actions = ActionsUnion<typeof Actions>;
