import { restAxios } from '../restAxios';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { GetPatientNotificationsDto } from '../../models/dto/getPatientNotifications.dto';

class NotificationApi {
    @HandleAsyncErrors()
    static async markAsRead() {
        return await restAxios.delete(`private/notifications`);
    }

    @HandleAsyncErrors()
    static async markOneAsRead(id: number) {
        return await restAxios.delete(`private/notifications/${id}`);
    }

    @HandleAsyncErrors()
    static async getPatientNotifications(dataDto: GetPatientNotificationsDto) {
        const { patientId, data } = dataDto;
        const { page, size, sort } = data;
        const res =  await restAxios.get(`private/notifications/patient/${patientId}`, { params: { page, size, sort } });
        return res.data;
    }
}

export default NotificationApi;
