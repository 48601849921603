import React, { useEffect, useRef } from 'react';
import Snackbar, { SnackbarTypes } from '../../../shared/snackbars/snackbar';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import RequestState from '../../../constants/requestState';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions } from '../../../store/actions/patient.actions';

interface DispatchModel {
    resetStatusVariables: typeof Actions.resetStatusVariables;
}

interface StoreModel {
    loadingSetupMeasurementSystem: RequestState;
}

const WeightManagementSettingsSnackbar: React.FC<StoreModel & DispatchModel> = ({
    resetStatusVariables,
    loadingSetupMeasurementSystem,
}) => {
    const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null);

    useEffect(() => {
        if (loadingSetupMeasurementSystem === RequestState.SENT_SUCCESS) {
            timerRef.current = setTimeout(() => {
                resetStatusVariables();
            }, 5000);
        }
    }, [loadingSetupMeasurementSystem, resetStatusVariables]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [resetStatusVariables]);

    return (
        <Snackbar
            message={'The settings are saved'}
            open={loadingSetupMeasurementSystem === RequestState.SENT_SUCCESS}
            variant={'success'}
            type={SnackbarTypes.SUCCESS}
            onClose={resetStatusVariables}
        />
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        loadingSetupMeasurementSystem: store.patient.loadingSetupMeasurementSystem,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(Actions.resetStatusVariables, dispatch),
    })
)(WeightManagementSettingsSnackbar);
