import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';
import calendarPicker from '../../static/icons/calenderPicker.svg';
import { DatePickerView } from '@material-ui/pickers/DatePicker/DatePicker';

interface InjectedProps {
    label: string;
    value: Date | null;
    onChange: (date: Date | null) => void;
    disableFuture?: boolean;
    disablePast?: boolean;
    helperText?: string;
}

const styles = createStyles({
    root: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            padding: '11px 12px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
        },
        '& >div': {
            marginLeft: '0 !important',
            '& button': {
                padding: 0,
            },
        },
    },
    underline: {
        '&:after': {
            borderBottom: 'transparent !important',
        },
        '&:before': {
            borderBottom: `1px solid ${colors.grayUnderline} !important`,
        },
        '&:hover:not($error):before': {
            borderBottom: `1px solid ${colors.grayUnderline} !important`,
        },
    },
    calendar: {},
    label: {
        color: colors.textSecondary,
        fontSize: '12px',
        marginLeft: '12px',
    },
    helperText: {
        position: 'absolute',
        fontSize: '10px',
        color: colors.grayUnderline,
        fontFamily: 'Poppins-Regular',
    },
});

type PropsTypes = WithStyles<typeof styles> & InjectedProps;

const dateKeyboardPicker: DatePickerView[] = ['date'];

const DatePickerBase: React.FC<PropsTypes> = props => {
    const { classes } = props;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                label={
                    <Typography variant={'button'} classes={{ root: classes.label }}>
                        {props.label}
                    </Typography>
                }
                format="MM/dd/yyyy"
                variant="inline"
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
                openTo="year"
                views={dateKeyboardPicker}
                autoOk
                margin="normal"
                value={props.value}
                onChange={props.onChange}
                keyboardIcon={<img src={calendarPicker} alt="calendar picker" className={classes.calendar} />}
                helperText={props.helperText}
                InputProps={{
                    classes: {
                        root: classes.root,
                        underline: classes.underline,
                    },
                }}
                FormHelperTextProps={{
                    classes: {
                        root: classes.helperText,
                    },
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default withStyles(styles)(DatePickerBase);
