import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScrollableList from '../../shared/scrollable-list/ScrollableList';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import { PatientModel } from '../../models/responses/patient.response';
import { SortOptions } from '../../constants/sortOptions';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../constants/layout';
import { DefaultPageSize, shouldFetchData } from '../../constants/dataFetching/allEntitiesByPages';
import { useCompare } from '../../helpers/usePreviousHelper';

interface DispatchModel {
    getAllLinkedPatients: typeof PatientActions.getAllPatients;
    setFilterQuery: typeof PatientActions.setFilterQueryAllPatients;
    increasePageNumberLinkedPatients: typeof PatientActions.increasePageNumberAllPatients;
}

interface StoreModel {
    allPatients: PatientModel[];
    totalPatients: number;
    loading: boolean;
    pageAllPatients: number;
    searchQueryAllPatients: string;
}

type PropsTypes = DispatchModel & StoreModel;

const AllPatientsList: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const {
        loading,
        totalPatients,
        setFilterQuery,
        searchQueryAllPatients,
        pageAllPatients,
        allPatients,
        getAllLinkedPatients,
        increasePageNumberLinkedPatients,
    } = props;
    const isSearchQueryChanged = useCompare(searchQueryAllPatients);

    const loadData = useCallback(
        (page: number, size: number, sort: SortOptions, searchQuery: string, overrideRecords: boolean) => {
            getAllLinkedPatients({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllLinkedPatients]
    );

    useEffect(() => {
        setFilterQuery('');
        loadData(0, DefaultPageSize, SortOptions.LAST_NAME_ASCENDING, '', true);
        setInitialLoaded(true);
    }, [setFilterQuery, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(loading, pageAllPatients, allPatients.length, initialLoaded, DefaultPageSize) ||
            isSearchQueryChanged
        ) {
            loadData(pageAllPatients, DefaultPageSize, SortOptions.LAST_NAME_ASCENDING, searchQueryAllPatients, false);
        }
    }, [pageAllPatients, searchQueryAllPatients, loading, initialLoaded, loadData, allPatients, isSearchQueryChanged]);

    const getMorePatients = useCallback(() => {
        increasePageNumberLinkedPatients();
    }, [increasePageNumberLinkedPatients]);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    return (
        <div ref={listRef}>
            <ScrollableList
                isLoading={loading}
                totalRecordsNumber={totalPatients}
                data={allPatients}
                nextAction={loading ? () => {} : getMorePatients}
                height={height}
                type={'patient'}
                showStatusIcons
            />
        </div>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        allPatients: state.patient.allPatients,
        totalPatients: state.patient.totalPatients,
        loading: state.patient.loading,
        pageAllPatients: state.patient.pageAllPatients,
        searchQueryAllPatients: state.patient.searchQueryAllPatients,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllLinkedPatients: bindActionCreators(PatientActions.getAllPatients, dispatch),
        setFilterQuery: bindActionCreators(PatientActions.setFilterQueryAllPatients, dispatch),
        increasePageNumberLinkedPatients: bindActionCreators(PatientActions.increasePageNumberAllPatients, dispatch),
    })
)(AllPatientsList);
