import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import DropdownInputPatientListWrapper from '../../../shared/input/dropdownAutocompleteWrappers/DropdownInputLinkedPatientListWrapper';
import HeaderHeadingWithLink from '../../../shared/HeaderHeadingWithLink';
import TextInput from '../../../shared/input/TextInput';
import DefaultSelect from '../../../shared/input/DefaultSelect';
import { InvoiceTypes } from '../../../constants/invoice/invoiceType';
import styled from 'styled-components';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import * as invoiceActions from '../../../store/actions/invoice.actions';
import { bindActionCreators, Dispatch } from 'redux';
import RequestState from '../../../constants/requestState';
import { RouteComponentProps, withRouter } from 'react-router';
import LoadingTransparent from '../../shared/LoadingTransparent';
import { InvoiceModel } from '../../../models/responses/invoice.model';
import Loading from '../../shared/Loading';
import { convertCentsToDollars, convertDollarsToCents } from '../../../helpers/convertMoney';
import { displayingInvoiceType, nameForSending } from '../../../shared/utils/nameForTypeInvoice';
import CancelLink from '../../../shared/link/CancelLink';

const FormWrapper = styled.div`
    margin-top: 32px;
    > div {
        margin-bottom: 16px !important;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
`;

const Divider = styled.div`
    width: 1px;
    height: 20px;
    background-color: #c0c0c0;
    margin: 0 10px 8px;
`;

const MultipleInputWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

const InputWrapper = styled.div`
    flex: 1;
`;

enum FormFields {
    patient = 'patient',
    type = 'type',
    amount = 'amount',
    description = 'description',
}

const emptyForm = {
    patient: null,
    type: null,
    amount: '',
    description: '',
};

interface Form {
    patient: null | BaseDropdownItem;
    type: null | BaseDropdownItem;
    amount: string;
    description: string;
}

const createStyles = makeStyles({
    adornmentText: {
        color: colors.textSecondary,
    },
});

interface StateModel {
    addRequest: RequestState;
    editRequest: RequestState;
    currentInvoice: InvoiceModel | null;
    loadingCurrentInvoice: boolean;
}

interface DispatchModel {
    addInvoice: typeof invoiceActions.Actions.addInvoice;
    editInvoice: typeof invoiceActions.Actions.editInvoice;
    getInvoiceById: typeof invoiceActions.Actions.getRequestedInvoiceById;
}

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const AddPayment: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    const {
        addInvoice,
        addRequest,
        getInvoiceById,
        loadingCurrentInvoice,
        currentInvoice,
        editInvoice,
        editRequest,
        location,
        history,
        match,
    } = props;

    const [form, setForm] = useState<Form>(emptyForm);

    useEffect(() => {
        return () => {
            setForm({ ...emptyForm });
        };
    }, []);

    const changeForm = useCallback(
        (formField: string, value: string | null | BaseDropdownItem) => {
            setForm({ ...form, [formField]: value });
        },
        [form]
    );

    const shouldGetInvoice = useMemo(() => {
        const id = match.params.id;
        const patientId = match.params.patientId;
        return !!id && !!patientId;
    }, [match.params]);

    useEffect(() => {
        if (currentInvoice && shouldGetInvoice) {
            setForm({
                patient: {
                    name: `${currentInvoice.patient.firstName} ${currentInvoice.patient.lastName}`,
                    id: currentInvoice.patient.id,
                },
                type: {
                    name: displayingInvoiceType(currentInvoice.type),
                    id: 0,
                },
                amount: convertCentsToDollars(currentInvoice.amount).toString(),
                description: currentInvoice.description,
            });
        } else {
            setForm(emptyForm);
        }
    }, [currentInvoice, location, shouldGetInvoice]);

    const validateForm = useCallback(() => {
        return !!form.amount && !!form.patient && !!form.description && !!form.type;
    }, [form]);

    const saveInvoice = useCallback(() => {
        if (validateForm()) {
            if (shouldGetInvoice && currentInvoice) {
                editInvoice({
                    id: currentInvoice.id,
                    type: nameForSending(form.type!.name.toUpperCase()),
                    amount: convertDollarsToCents(Number(form.amount)),
                    patientId: form.patient!.id!,
                    description: form.description,
                });
            } else {
                addInvoice({
                    type: nameForSending(form.type!.name.toUpperCase()),
                    amount: convertDollarsToCents(Number(form.amount)),
                    patientId: form.patient!.id!,
                    description: form.description,
                });
            }
        }
    }, [form, editInvoice, addInvoice, currentInvoice, shouldGetInvoice, validateForm]);

    const isLoading = useMemo(() => {
        return addRequest === RequestState.SENDING || editRequest === RequestState.SENDING;
    }, [addRequest, editRequest]);

    const isSuccess = useMemo(() => {
        return addRequest === RequestState.SENT_SUCCESS || editRequest === RequestState.SENT_SUCCESS;
    }, [addRequest, editRequest]);

    useEffect(() => {
        if (isSuccess && form.type) {
            history.push(`/payments/${form.type.name.toLowerCase().replace(/ /gi, '')}`);
        }
    }, [form.type, addRequest, editRequest, history, isSuccess]);

    useEffect(() => {
        const id = match.params.id;
        const patientId = match.params.patientId;
        if (id && patientId) {
            getInvoiceById({ id, patientId });
        }
    }, [match, getInvoiceById]);

    useEffect(() => {
        if (!form.type && location.state && !form.type) {
            const activeTab = InvoiceTypes[location.state.activeTab];
            changeForm(FormFields.type, { ...activeTab, name: activeTab.name });
        }
    }, [form.type, changeForm, location]);

    const changeAmount = (evt: React.ChangeEvent<HTMLInputElement>) => {
        changeForm(FormFields.amount, evt.target.value.replace(/\D*0*(\d*\.?\d{0,2}).*/g, '$1'));
    };
    return (
        <>
            {isLoading && <LoadingTransparent />}
            <HeaderHeadingWithLink>{shouldGetInvoice ? 'Edit Invoice' : 'Add Invoice'}</HeaderHeadingWithLink>
            {shouldGetInvoice && loadingCurrentInvoice && <Loading smallSize />}
            {!loadingCurrentInvoice && (
                <SizeControllerWrapper>
                    <FormWrapper>
                        <DropdownInputPatientListWrapper
                            label={'Patient'}
                            onChange={value => changeForm(FormFields.patient, value)}
                            value={form.patient}
                            disabled={shouldGetInvoice}
                        />

                        <MultipleInputWrapper>
                            <InputWrapper>
                                <TextInput
                                    placeholder={'Amount'}
                                    value={form.amount}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => changeAmount(evt)}
                                    adornment={
                                        <Typography variant={'button'} classes={{ root: classes.adornmentText }}>
                                            $
                                        </Typography>
                                    }
                                />
                            </InputWrapper>
                            <Divider />
                            <InputWrapper>
                                <DefaultSelect
                                    value={form.type}
                                    onChange={value =>
                                        changeForm(FormFields.type, value ? { ...value, name: value.name } : null)
                                    }
                                    label={'Type'}
                                    allEntities={InvoiceTypes}
                                    openIcon
                                    disabled={shouldGetInvoice}
                                />
                            </InputWrapper>
                        </MultipleInputWrapper>

                        <TextInput
                            placeholder={'Description'}
                            value={form.description}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                changeForm(FormFields.description, evt.target.value)
                            }
                            rows={3}
                        />
                    </FormWrapper>
                    <ButtonWrapper>
                        <PrimaryButton onClick={saveInvoice}>{shouldGetInvoice ? 'Save' : 'Create'}</PrimaryButton>
                        <CancelLink />
                    </ButtonWrapper>
                </SizeControllerWrapper>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        addRequest: state.invoice.addRequest,
        editRequest: state.invoice.editRequest,
        currentInvoice: state.invoice.currentInvoice,
        loadingCurrentInvoice: state.invoice.loadingCurrentInvoice,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        addInvoice: bindActionCreators(invoiceActions.Actions.addInvoice, dispatch),
        editInvoice: bindActionCreators(invoiceActions.Actions.editInvoice, dispatch),
        getInvoiceById: bindActionCreators(invoiceActions.Actions.getRequestedInvoiceById, dispatch),
    })
)(withRouter(AddPayment));
