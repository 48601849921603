import React from 'react';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import PatientTour from './PatientTour';

interface InjectedProps {
    user: UserModel | null;
}

const TourRoot: React.FC<InjectedProps> = ({ user }) => {
    if (user) {
        switch (user.role) {
            case RoleOptions.PATIENT: {
                return <PatientTour />;
            }
            default:
                return null;
        }
    } else {
        return null;
    }
};

export default TourRoot;
