import React, { useRef } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Chip, makeStyles, TextField, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import theme from '../../theme/theme';
import removeChip from '../../static/icons/removeChip.svg';
import { DropdownMultipleInputWrapper } from '../../constants/dropdownMultipleInputData';
import { BloodTargetInAnalytics } from '../../models/responses/bloodworkInAnalytics.model';
import { DrugInAnalytics } from '../../models/responses/drugInAnalytics.model';
import { SELECT_ALL } from '../../store/reducers/analytics';
import {BaseDropdownItem} from "../../models/responses/baseModel.responce";

const createStyles = makeStyles({
    autocompleteRoot: {
        width: '100%',
        '& .MuiAutocomplete-clearIndicator': {
            display: 'none',
        },
        '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
        },
    },
    root: () => ({
        '& >div': {
            padding: 0,
        },
        '& input': {
            fontSize: '12px',
            padding: '11px 12px !important',
            color: colors.textPrimary,
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: `1px solid ${colors.grayUnderline}`,
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${colors.grayUnderline}`,
        },
    }),
    label: {
        color: colors.textSecondary,
        fontSize: '12px',
        marginLeft: '12px',
    },
    autocompletePaper: {
        borderRadius: 0,
        boxShadow: theme.boxShadowGray,
        '& ul': {
            '& li': {
                minHeight: '24px',
                padding: '6px 8px',
            },
        },
    },
    option: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
    chip: {
        backgroundColor: colors.gray2,
        marginBottom: '10px',
        height: '30px',
    },
    chipLabel: {
        marginBottom: '-4px',
    },
    chipDisabled: {
        opacity: '1 !important',
    },
});


interface InjectedProps {
    label: string;
    options: BloodTargetInAnalytics[] | DrugInAnalytics[] | BaseDropdownItem[];
    onChange: (value: BloodTargetInAnalytics[] | DrugInAnalytics[] | BaseDropdownItem[] | null) => void;
    onFocus?: () => void;
    value: BloodTargetInAnalytics[] | DrugInAnalytics[] | BaseDropdownItem[] | null;
    type?: DropdownMultipleInputWrapper;
    onSelectAll?: () => void;
    disabled?: boolean;
    noDelete?: boolean;
    bottomPosition?: boolean;
}

type PropsTypes = InjectedProps;

const AutocompleteMultiple: React.FC<PropsTypes> = props => {
    const inputRef = useRef(null);

    //TODO Push item to the values if user printed it
    const changeText = (evt: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        let item = props.options.filter(item => evt.target.value === item.name)[0];
        const index = props.options.indexOf(item);
        if (index !== -1) {
            // props.onChange(item);
        }
    };

    const classes = createStyles(props);

    const checkIfSelectAll = (newValue: any[]) => {
        newValue.forEach(v => {
            if (v.name === SELECT_ALL) {
                props.onSelectAll && props.onSelectAll();
                return true;
            }
        });
        return false;
    };

    return (
        <>
            <Autocomplete
                disabled={props.disabled}
                multiple
                autoHighlight
                options={props.options}
                getOptionLabel={(option: any) => {
                    return option.name;
                }}
                onChange={(event: React.ChangeEvent<{}>, newValue) => {
                    props.onChange(newValue ? newValue : null);
                    checkIfSelectAll(newValue);
                }}
                renderOption={(option: any) => (
                    <Typography variant="button" classes={{ root: classes.option }}>
                        {option ? option.name : ''}
                    </Typography>
                )}
                // anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                // targetOrigin={{ vertical: 'bottom', horizontal: 'left' }}

                value={props.value}
                classes={{ root: classes.autocompleteRoot, paper: classes.autocompletePaper}}
                renderTags={(value: any[], getTagProps) =>
                    props.value && props.value.length
                        ?
                        //@ts-ignore
                          props.value.map((option: any, index: number) => (
                              <Chip
                                  classes={{ root: classes.chip, label: classes.chipLabel, disabled: classes.chipDisabled }}
                                  deleteIcon={props.noDelete ? <></> :  <img src={removeChip} alt="" />}
                                  label={option.name}
                                  {...getTagProps({ index })}
                                  disabled={props.disabled}
                              />
                          ))
                        : null
                }
                renderInput={params => (
                    <TextField
                        label={
                            <Typography variant={'button'} classes={{ root: classes.label }}>
                                {props.label}
                            </Typography>
                        }
                        {...params}
                        classes={{ root: classes.root }}
                        fullWidth
                        ref={inputRef}
                        disabled={props.disabled}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => changeText(evt)}
                        onFocus={() => props.onFocus && props.onFocus()}
                    />
                )}
            />
        </>
    );
};

export default AutocompleteMultiple;
