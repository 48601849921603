import React from 'react';
import NewPaymentListItem from './NewPaymentListItem';
import BlockHeader from '../BlockHeader';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import NoData from '../NoData';
import { RoleOptions } from '../../../../constants/roleOptions';
import { UserModel } from '../../../../models/responses/user.response';
import { convertCentsToDollars } from '../../../../helpers/convertMoney';
import { templateDisplayingDollar } from '../../../../shared/utils/templateDisplayingDollar';

export enum PaymentStatus {
    PAID,
    UNPAID,
    PENDING,
}

export interface NewPaymentModel {
    amount: number;
    text: string;
    date: string;
    provider: string;
    payDate?: string;
    status: PaymentStatus;
}

const NewBlock: React.FC<{
    invoices: InvoiceModel[];
    patientId?: number | null;
    totalAmountInvoiceRecurringSubscriptionAllPatientsRequested?: number;
    user?: UserModel | null;
}> = ({
    invoices,
    patientId,
    totalAmountInvoiceRecurringSubscriptionAllPatientsRequested,
    user,
}) => {
    return (
        <>
            {user && user.role === RoleOptions.PATIENT ? (
                <BlockHeader>
                    New
                </BlockHeader>
            ) : (
                <BlockHeader>
                    <div>New</div>
                    <div>
                        Total :{' '}
                        {totalAmountInvoiceRecurringSubscriptionAllPatientsRequested !== undefined &&
                            templateDisplayingDollar(
                                convertCentsToDollars(totalAmountInvoiceRecurringSubscriptionAllPatientsRequested)
                            )}
                    </div>
                </BlockHeader>
            )}
            {invoices.length > 0 ? (
                invoices.map((invoice, index) => (
                    <NewPaymentListItem key={index} index={index} patientId={patientId} invoice={invoice} />
                ))
            ) : (
                <NoData />
            )}
        </>
    );
};

export default NewBlock;
