import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';

const createStyles = makeStyles({
    text: {
        fontSize: '16px',
        lineHeight: '24px',
        color: colors.textSecondary,
    },
});

const RegularText: React.FC = props => {
    const classes = createStyles(props);

    return (
        <Typography variant={'body1'} classes={{ root: classes.text }}>
            {props.children}
        </Typography>
    );
};

export default RegularText;
