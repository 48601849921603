import {SnackbarTypes} from "../../../shared/snackbars/snackbar";
import {SnackbarActionNames, SnackbarActions} from "../../actions/snackbar.actions";

export interface Snackbar {
    title: string;
    text: string;
    type: SnackbarTypes;
    id?: number;
}

export class SnackbarReducerModel {
    snackbars: Snackbar[] = [];
    counter: number = 0;
}

const initialState = new SnackbarReducerModel();

export default function snackbarReducer(state = initialState, action: SnackbarActions) {
    switch (action.type) {
        case SnackbarActionNames.SNACKBAR_ADD:
            return {
                ...state,
                counter: state.counter + 1,
                snackbars: [
                    ...state.snackbars,
                    {
                        ...action.payload,
                        id: state.counter
                    }
                ]
            };
        case SnackbarActionNames.SNACKBAR_REMOVE:
            return {
                ...state,
                snackbars: state.snackbars.filter(s => s.id !== action.payload)
            };
        default:
            return state;
    }
}
