import React, { useCallback, useMemo } from 'react';
import TextInput from './TextInput';
import { IRenderableFormField } from '../../pages/weightManagement/ui/formBuilder/form';
import { WeightRecordFormFields } from '../../pages/weightManagement/dtos/weight-record.interface';
import { CmToInch, InchToCm } from '../utils/converter/length-converter';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { MeasurementSystem, UserModel } from '../../models/responses/user.response';
import { KgToLbs, LbsToKg } from '../utils/converter/weight-converter';
import { roundToTwoPoints } from '../utils/converter/round';

interface InjectedProps {
    isValid: boolean;
    readonly: boolean;
    fieldData: IRenderableFormField;
    onChange: any;
}

interface StoreModel {
    user: UserModel | null;
}

const SmartValueInput: React.FC<InjectedProps> = ({ isValid, fieldData, onChange, readonly }) => {
    return (
        <TextInput
            error={!isValid}
            disabled={readonly}
            style={{ width: '90%' }}
            onChange={onChange}
            placeholder={fieldData.placeholder || ''}
            value={fieldData.value}
            inputProps={{ type: 'number' }}
        />
    );
};

const withSmartValue = (TextInput: any) => (props: InjectedProps & StoreModel) => {
    const { fieldData, onChange, user } = props;

    const currentFieldData = useMemo(() => {
        if (user && user.measurementSystem === MeasurementSystem.METRIC) {
            switch (fieldData.dataField) {
                case WeightRecordFormFields.weight:
                    return { ...fieldData, value: roundToTwoPoints(Number(fieldData.value)).toString() };
                default:
                    return fieldData;
            }
        } else {
            switch (fieldData.dataField) {
                case WeightRecordFormFields.waist:
                case WeightRecordFormFields.arm:
                case WeightRecordFormFields.thigh:
                    return { ...fieldData, value: CmToInch(Number(fieldData.value)).toString() };
                case WeightRecordFormFields.weight:
                    return { ...fieldData, value: roundToTwoPoints(KgToLbs(Number(fieldData.value))).toString() };
                default:
                    return fieldData;
            }
        }
    }, [fieldData, user]);

    const getValueToSave = useCallback(
        (value: number) => {
            if (user && user.measurementSystem === MeasurementSystem.METRIC) {
                return value;
            } else {
                switch (fieldData.dataField) {
                    case WeightRecordFormFields.waist:
                    case WeightRecordFormFields.arm:
                    case WeightRecordFormFields.thigh:
                        return InchToCm(Number(value)).toString();
                    case WeightRecordFormFields.weight:
                        return LbsToKg(Number(value)).toString();
                    default:
                        return value;
                }
            }
        },
        [fieldData, user]
    );

    const onChangeValue = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>) => {
            onChange(getValueToSave(Number(evt.target.value)));
        },
        [onChange, getValueToSave]
    );

    return <TextInput {...props} fieldData={currentFieldData} onChange={onChangeValue} />;
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        user: store.authorization.user,
    }),
    () => ({})
)(withSmartValue(SmartValueInput));
