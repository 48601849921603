import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from './colors';
import * as CustomTheme from './theme';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.redMain,
        },
        secondary: {
            main: colors.white,
        },
    },
    typography: {
        fontFamily: 'Poppins-Semibold',
        h1: {
            fontFamily: 'Poppins-Semibold',
            fontSize: '48px',
            color: colors.textPrimary,
            letterSpacing: 0,
            lineHeight: '58px',
        },
        h2: {
            fontFamily: 'Poppins-Semibold',
            fontSize: '30px',
            color: colors.textPrimary,
            letterSpacing: 0,
            lineHeight: '36px',
        },
        body1: {
            fontFamily: 'Muli-Regular',
            fontSize: '16px',
            color: colors.textPrimary,
            letterSpacing: 0,
            lineHeight: '24px',
        },
        body2: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: colors.textPrimary,
            letterSpacing: 0,
            lineHeight: '24px',
        },
        button: {
            fontFamily: 'Poppins-Semibold',
            fontSize: '14px',
            letterSpacing: 0,
            lineHeight: '20px',
            textTransform: 'initial',
        },
    },

    overrides: {
        MuiFormControl: {
            root: {
                display: 'block',
                margin: '0 !important',
            },
        },
        MuiInputBase: {
            root: {
                width: '100%',
            },
        },
        MuiButton: {
            root: {
                minWidth: '200px',
                height: '60px',
                padding: '13px 24px 9px !important',
                borderRadius: '30px',
                fontSize: '12px',
            },
            sizeLarge: {
                minWidth: '200px',
                height: '60px',
                padding: '19px 38px !important',
                borderRadius: '30px',
                fontSize: '14px',
            },
            sizeSmall: {
                minWidth: '68px',
                height: '36px',
                fontSize: '12px',
                padding: '9px 24px 6px !important',
            },
        },
        MuiList: {
            root: {
                padding: '0 !important',
            },
        },
        MuiToolbar: {
            root: {
                backgroundColor: `${colors.redMain} !important`,
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'transparent',
            },
        },
        MuiExpansionPanel: {
            root: {
                margin: '0 !important',
                borderBottom: `1px solid ${colors.accordionGray}`,
            },
        },
        MuiBadge: {
            badge: {
                color: `${colors.white} !important`,
            },
        },
        MuiTabs: {
            indicator: {
                height: '2px !important',
                left: 'auto',
            },
            root: {
                width: 'auto !important',
            }

        },
        MuiTab:{
            root: {
                '& span': {
                    height: 'auto !important',
                    backgroundColor: 'transparent !important'
                },
                "&$selected": {
                    "& span": {
                        "height": "auto !important",
                        "backgroundColor": "transparent !important"
                    }
                }
            },

        },
        MuiDialog: {
            paper: {
                boxShadow: CustomTheme.default.boxShadowCard,
                padding: '25px',
                borderRadius: '30px',
                backgroundColor: colors.white,
                margin: '0',
                top: '3vh',
                justifyContent: 'center',
                textAlign: 'center',
            }
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 768,
            lg: 1024,
            xl: 1440,
        },
    },
});

export default theme;
