import * as labworkActions from '../../actions/labwork.actions';
import RequestState from "../../../constants/requestState";
import {BloodworkResponse} from "../../../models/responses/bloodwork.response";

export class StateModel {
    allBloodworks: BloodworkResponse[] = [];
    page: number = 0;
    currentBloodwork: null | BloodworkResponse = null;
    totalBloodworks: number | null = null;
    loading: boolean = false;
    uploadReportStatus: RequestState = RequestState.UNSENT;
    updateReportStatus: RequestState = RequestState.UNSENT;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: labworkActions.Actions) {
    switch (action.type) {

        case labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_PATIENT: {
            return {
                ...state,
                loading: true,
            }
        }

        case labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_PROVIDER: {
            return {
                ...state,
                loading: true,
            }
        }

        case labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_SUCCESS: {
            return {
                ...state,
                loading: false,
                totalBloodworks: action.payload.total,
                allBloodworks: state.allBloodworks.concat(action.payload.result),
            }
        }

        case labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }

        case labworkActions.ActionNames.GET_BLOOD_LABWORK_PATIENT: {
            return {
                ...state,
                loading: true,
            }
        }

        case labworkActions.ActionNames.GET_BLOOD_LABWORK_PROVIDER: {
            return {
                ...state,
                loading: true,
            }
        }

        case labworkActions.ActionNames.GET_BLOOD_LABWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                currentBloodwork: action.payload,
            }
        }

        case labworkActions.ActionNames.GET_BLOOD_LABWORK_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }

        case labworkActions.ActionNames.INCREASE_PAGE_NUMBER_BLOODWORK: {
            return {
                ...state,
                page: state.page + 1,
            }
        }

        case labworkActions.ActionNames.UPLOAD_BLOODWORK_PATIENT: {
            return {
                ...state,
                loading: true,
                uploadReportStatus: RequestState.SENDING,
            }
        }

        case labworkActions.ActionNames.UPLOAD_BLOODWORK_PATIENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                uploadReportStatus: RequestState.SENT_SUCCESS,
            }
        }

        case labworkActions.ActionNames.UPLOAD_BLOODWORK_PATIENT_FAILED: {
            return {
                ...state,
                loading: false,
                uploadReportStatus: RequestState.SENT_FAILED,
            }
        }

        case labworkActions.ActionNames.UPLOAD_BLOODWORK_PROVIDER: {
            return {
                ...state,
                loading: true,
                uploadReportStatus: RequestState.SENDING,
            }
        }

        case labworkActions.ActionNames.UPLOAD_BLOODWORK_PROVIDER_SUCCESS: {
            return {
                ...state,
                loading: false,
                uploadReportStatus: RequestState.SENT_SUCCESS,
            }
        }

        case labworkActions.ActionNames.UPLOAD_BLOODWORK_PROVIDER_FAILED: {
            return {
                ...state,
                loading: false,
                uploadReportStatus: RequestState.SENT_FAILED,
            }
        }

        case labworkActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                uploadReportStatus: RequestState.UNSENT,
                updateReportStatus: RequestState.UNSENT,
                currentBloodwork: null,
            }
        }

        case labworkActions.ActionNames.RESET_ALL_BLOOD_LABWORKS: {
            return {
                ...state,
                allBloodworks: initialState.allBloodworks,
                totalBloodworks: initialState.totalBloodworks,
                page: initialState.page,
            }
        }

        case labworkActions.ActionNames.UPDATE_BLOODWORK_PATIENT: {
            return {
               ...state,
                updateReportStatus: RequestState.SENDING,
            }
        }

        case labworkActions.ActionNames.UPDATE_BLOODWORK_PATIENT_SUCCESS: {
            return {
                ...state,
                updateReportStatus: RequestState.SENT_SUCCESS,
            }
        }

        case labworkActions.ActionNames.UPDATE_BLOODWORK_PATIENT_FAILED: {
            return {
                ...state,
                updateReportStatus: RequestState.SENT_FAILED,
            }
        }

        default:
            return state;
    }
}