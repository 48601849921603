import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

export enum ActionNames {
    SET_TOUR_VISIBLE = '[Tour] Set Tour Visible',
}

export const Actions = {
    setTourVisible: (isVisible: boolean) => createAction(ActionNames.SET_TOUR_VISIBLE, isVisible),
};

export type Actions = ActionsUnion<typeof Actions>;
