import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InvoiceModel } from '../../../models/responses/invoice.model';
import { connect } from 'react-redux';
import { Actions as InvoiceActions } from '../../../store/actions/invoice.actions';
import { bindActionCreators, Dispatch } from 'redux';
import * as fromRoot from '../../../store/reducers/index';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import styled from 'styled-components';
import Loading from '../../shared/Loading';
import ActiveSubscriptionBlock from './active/ActiveSubscriptionBlock';
import {DefaultPageSize, shouldFetchData} from '../../../constants/dataFetching/allEntitiesByPages';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

interface StoreModel {
    recurringSubscriptionAllPatientsActive: InvoiceModel[];
    pageNumber: number;
    totalInvoice: number | null;
    loadingRecurringSubscription: boolean;
    totalAmountInvoiceRecurringSubscriptionAllPatientsActive: number;
}

interface DispatchModel {
    getAllRecurringSubscription: typeof InvoiceActions.getAllRecurringSubscriptionProviderAllPatientsActive;
    increasePageNumber: typeof InvoiceActions.increasePageNumberAllRecurringSubscriptionProviderAllPatientsActive;
}

type PropsTypes = StoreModel & DispatchModel;

const RecurringSubscriptionAllPatientsActive: React.FC<PropsTypes> = props => {
    const {
        recurringSubscriptionAllPatientsActive,
        pageNumber,
        totalInvoice,
        loadingRecurringSubscription,
        getAllRecurringSubscription,
        increasePageNumber,
        totalAmountInvoiceRecurringSubscriptionAllPatientsActive,
    } = props;

    const [initialLoaded, setInitialLoaded] = useState(false);

    const loadData = useCallback((page, overrideRecords: boolean) => {
        getAllRecurringSubscription({
            size: DefaultPageSize,
            overrideRecords,
            page,
        });
    }, [getAllRecurringSubscription]);

    useEffect(() => {
        loadData(0, true);
        setInitialLoaded(true);
    }, [loadData]);

    useEffect(() => {
        if (
            shouldFetchData(
                loadingRecurringSubscription,
                pageNumber,
                recurringSubscriptionAllPatientsActive.length,
                initialLoaded
            )
        ) {
            loadData(pageNumber, false);
        }
    }, [loadingRecurringSubscription, pageNumber, recurringSubscriptionAllPatientsActive, initialLoaded, loadData]);

    const loadMore = useCallback(() => {
        increasePageNumber();
    }, [increasePageNumber]);

    const hasMore = useMemo(() => {
        return totalInvoice ? recurringSubscriptionAllPatientsActive.length < totalInvoice : false;
    }, [totalInvoice, recurringSubscriptionAllPatientsActive]);

    return (
        <>
            <ActiveSubscriptionBlock invoices={recurringSubscriptionAllPatientsActive} totalAmountInvoiceRecurringSubscriptionAllPatientsActive={totalAmountInvoiceRecurringSubscriptionAllPatientsActive}/>

            {hasMore && !loadingRecurringSubscription && (
                <ButtonWrapper>
                    <PrimaryButton onClick={loadMore}>Load More</PrimaryButton>
                </ButtonWrapper>
            )}

            {loadingRecurringSubscription && <Loading smallSize />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        recurringSubscriptionAllPatientsActive: state.invoice.recurringSubscriptionAllPatientsActive,
        pageNumber: state.invoice.pageNumberRecurringSubscriptionAllPatientsActive,
        totalInvoice: state.invoice.totalInvoiceRecurringSubscriptionAllPatientsActive,
        loadingRecurringSubscription: state.invoice.loadingRecurringSubscriptionActive,
        totalAmountInvoiceRecurringSubscriptionAllPatientsActive: state.invoice.totalAmountInvoiceRecurringSubscriptionAllPatientsActive,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllRecurringSubscription: bindActionCreators(
            InvoiceActions.getAllRecurringSubscriptionProviderAllPatientsActive,
            dispatch
        ),
        increasePageNumber: bindActionCreators(
            InvoiceActions.increasePageNumberAllRecurringSubscriptionProviderAllPatientsActive,
            dispatch
        ),
    })
)(RecurringSubscriptionAllPatientsActive);
