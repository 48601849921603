import React, { useEffect } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import * as fromRoot from '../../store/reducers';
import { PatientModel } from '../../models/responses/patient.response';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../shared/Loading';
import defaultAvatar from '../../static/icons/defaultAvatar.svg';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { AcceptProviderStatus } from '../../constants/acceptProviderStatus';

const createStyles = makeStyles({
    name: {
        fontSize: '24px',
    },
    subHeading: {
        fontSize: '18px',
        color: colors.textSecondary,
    },
    rejectLink: {
        fontSize: '14px',
        color: colors.redMain,
        textDecoration: 'underline',
        textDecorationColor: colors.redMain,
    },
});
const Wrapper = styled.div`
    height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0 40px 0;
`;

const Avatar = styled.div<any>`
    height: 200px;
    width: 200px;
    border-radius: 100px;
    background-color: ${colors.gray2};
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-position: center;
`;
const TextWrapper = styled.div`
    text-align: center;
    margin-bottom: 30px;
`;

interface StoreModel {
    patient: PatientModel | null;
    loading: boolean;
    patientRequestSet: boolean;
}

interface DispatchModel {
    getPatientById: typeof PatientActions.getPatientById;
    rejectPatientRequest: typeof PatientActions.rejectPatientRequest;
    approvePatientRequest: typeof PatientActions.approvePatientRequest;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const PatientSentRequestPage: React.FC<PropsTypes> = props => {
    const {
        getPatientById,
        loading,
        patient,
        history,
        approvePatientRequest,
        patientRequestSet,
        rejectPatientRequest,
        match,
    } = props;

    useEffect(() => {
        getPatientById(match.params.id);
    }, [getPatientById, match]);

    useEffect(() => {
        if (patientRequestSet) {
            history.push('/provider/my-links');
        }
    }, [patientRequestSet, history]);

    useEffect(() => {
        if (
            patient &&
            patient.id.toString() === match.params.id &&
            patient.linkingStatus === AcceptProviderStatus.REJECTED
        ) {
            history.push('/provider/my-links');
        }
        if (
            patient &&
            patient.id.toString() === match.params.id &&
            patient.linkingStatus === AcceptProviderStatus.APPROVED
        ) {
            history.push(`/profile-patient/${patient.id}`);
        }
    }, [patient, history, match]);

    const classes = createStyles(props);

    return (
        <>
            {loading && <Loading />}
            {!loading && patient && patient.linkingStatus === AcceptProviderStatus.PENDING && (
                <SizeControllerWrapper>
                    <Wrapper>
                        <Avatar image={patient.avatarThumbnailUrl ? patient.avatarThumbnailUrl : defaultAvatar} />
                        <TextWrapper>
                            <Typography variant="h2" classes={{ root: classes.name }}>
                                {patient.firstName} {patient.lastName}
                            </Typography>
                            <Typography variant="body1" classes={{ root: classes.subHeading }}>
                                Sent Request
                            </Typography>
                        </TextWrapper>
                        <PrimaryButton onClick={() => patient && patient.id && approvePatientRequest(patient.id)}>
                            Accept Request
                        </PrimaryButton>
                        <Typography
                            variant="button"
                            classes={{ root: classes.rejectLink }}
                            onClick={() => patient && patient.id && rejectPatientRequest(patient.id)}
                        >
                            Reject
                        </Typography>
                    </Wrapper>
                </SizeControllerWrapper>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        patient: state.patient.patient,
        loading: state.patient.loading,
        patientRequestSet: state.patient.patientRequestSet,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getPatientById: bindActionCreators(PatientActions.getPatientById, dispatch),
        approvePatientRequest: bindActionCreators(PatientActions.approvePatientRequest, dispatch),
        rejectPatientRequest: bindActionCreators(PatientActions.rejectPatientRequest, dispatch),
    })
)(withRouter(PatientSentRequestPage));
