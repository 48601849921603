import React, { useCallback, useEffect, useState } from 'react';
import ProvidersList from './ProvidersList';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as ProviderActions } from '../../../store/actions/provider.actions';
import { ProviderModel } from '../../../models/responses/provider.response';
import { SortOptions } from '../../../constants/sortOptions';
import { DefaultPageSize, shouldFetchData } from '../../../constants/dataFetching/allEntitiesByPages';
import { useCompare } from '../../../helpers/usePreviousHelper';

type StateModel = {
    loading: boolean;
    allProviders: ProviderModel[];
    totalProviders: number | null;
    searchQuery: string;
    page: number;
};

type DispatchModel = {
    getAllProviders: typeof ProviderActions.getAllProviders;
    increasePageNumber: typeof ProviderActions.increasePageNumber;
    setFilterQuery: typeof ProviderActions.setFilterQuery;
};

type PropsTypes = StateModel & DispatchModel;

const ProviderListSearchPage: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);

    const {
        page,
        setFilterQuery,
        searchQuery,
        increasePageNumber,
        loading,
        allProviders,
        totalProviders,
        getAllProviders,
    } = props;
    const isSearchQueryChanged = useCompare(searchQuery);

    const loadData = useCallback(
        (page: number, size: number, sort: SortOptions, searchQuery: string, overrideRecords: boolean) => {
            getAllProviders({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllProviders]
    );

    useEffect(() => {
        setFilterQuery('');
        loadData(0, DefaultPageSize, SortOptions.LAST_NAME_ASCENDING, '', true);
        setInitialLoaded(true);
    }, [setFilterQuery, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(loading, page, allProviders.length, initialLoaded, DefaultPageSize) ||
            isSearchQueryChanged
        ) {
            loadData(page, DefaultPageSize, SortOptions.LAST_NAME_ASCENDING, searchQuery, false);
        }
    }, [page, searchQuery, loading, allProviders, initialLoaded, loadData, isSearchQueryChanged]);

    return (
        <ProvidersList
            allProviders={allProviders}
            totalProviders={totalProviders}
            loading={loading}
            page={page}
            increasePageNumber={increasePageNumber}
        />
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        allProviders: state.provider.allProviders,
        totalProviders: state.provider.totalProviders,
        loading: state.provider.loading,
        searchQuery: state.provider.searchQuery,
        page: state.provider.page,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setFilterQuery: bindActionCreators(ProviderActions.setFilterQuery, dispatch),
        getAllProviders: bindActionCreators(ProviderActions.getAllProviders, dispatch),
        increasePageNumber: bindActionCreators(ProviderActions.increasePageNumber, dispatch),
    })
)(ProviderListSearchPage);
