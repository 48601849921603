import React, {useEffect} from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import bloodworkAnalyticsIcon from '../../static/icons/bloodworkAnalyticsIcon.svg';
import {makeStyles, Typography} from '@material-ui/core';
import AnalyticsForm from './components/AnalyticsForm';
import BloodTargetsDiagram from './components/BloodTargetsDiagram';
import DrugsDiagram from './components/DrugsDiagram';
import styled from 'styled-components';
import {colors} from '../../theme/colors';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import {connect} from 'react-redux';
import * as fromRoot from '../../store/reducers/index';
import {bindActionCreators, Dispatch} from 'redux';
import {AnalyticsModel} from '../../models/responses/analytics.responce';
import {Actions as AnalyticsActions} from '../../store/actions/analytics.actions';
import {DrugInAnalytics} from '../../models/responses/drugInAnalytics.model';
import {BloodTargetInAnalytics} from '../../models/responses/bloodworkInAnalytics.model';
import {BloodTargetInGraphModel} from '../../models/responses/bloodTargetInGraph.model';
import {DataRange} from '../../store/reducers/analytics';
import RequestState from "../../constants/requestState";

const DiagramWrapper = styled.div`
    height: auto;
    width: inherit;
    overflow: scroll;
    margin: 32px 0 24px;
`;

const createStyles = makeStyles({
    headerIcon: {
        margin: '0 8px -3px 0',
    },
    clearText: {
        fontSize: '14px',
        color: colors.grayUnderline,
        textDecoration: 'underline',
        textDecorationColor: colors.grayUnderline,
        display: 'block',
        textAlign: 'center',
        marginBottom: '36px',
        cursor: 'pointer',
    },
});

interface StoreModel {
    loadingRequestState: RequestState;
    showAnalytics: boolean;
    analyticsInfo: AnalyticsModel | null;
    analyticsResult: AnalyticsModel | null;
    drugsList: DrugInAnalytics[];
    bloodTargetsList: BloodTargetInAnalytics[];
    drugsListResult: DrugInAnalytics[];
    bloodTargetsListResult: BloodTargetInGraphModel | null;
    date: DataRange | null;
}

interface DispatchModel {
    getAnalyticsPatient: typeof AnalyticsActions.getAnalyticsPatient;
    clearAnalytics: typeof AnalyticsActions.clearAnalytics;
    clearAnalyticsDate: typeof AnalyticsActions.clearAnalyticsDate;
    getAnalyticsResult: typeof AnalyticsActions.getAnalyticsResult;
    resetStatusVariables: typeof AnalyticsActions.resetStatusVariables;
}

type PropsTypes = StoreModel & DispatchModel;

const AnalyticsPage: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    //TODO figure out why do we need analyticsInfo
    const {
        clearAnalytics,
        clearAnalyticsDate,
        getAnalyticsPatient,
        // analyticsInfo,
        drugsList,
        bloodTargetsList,
        getAnalyticsResult,
        date,
        showAnalytics,
        bloodTargetsListResult,
        drugsListResult,
        loadingRequestState,
        resetStatusVariables,
    } = props;

    useEffect(() => {
        return () => {
            clearAnalytics();
            clearAnalyticsDate();
        };
    }, [clearAnalytics, clearAnalyticsDate]);

    return (
        <>
            <HeaderHeadingWithLink>
                <img src={bloodworkAnalyticsIcon} className={classes.headerIcon} alt="" />
                Bloodwork Analytics
            </HeaderHeadingWithLink>
            <AnalyticsForm
                getAnalytics={getAnalyticsPatient}
                drugsList={drugsList}
                bloodTargetsList={bloodTargetsList}
                getAnalyticsResult={getAnalyticsResult}
                date={date}
                clearAnalytics={clearAnalytics}
                loadingRequestState={loadingRequestState}
                resetStatusVariables={resetStatusVariables}
            />
            {showAnalytics &&
                date &&
                bloodTargetsListResult &&
                !!bloodTargetsListResult.graphData.length &&
                !!drugsListResult.length && (
                    <>
                        <DiagramWrapper>
                            <BloodTargetsDiagram date={date} bloodTargetsList={bloodTargetsListResult} />
                            <DrugsDiagram date={date} drugsList={drugsListResult} />
                        </DiagramWrapper>
                        <SizeControllerWrapper>
                            <Typography
                                variant="button"
                                classes={{ root: classes.clearText }}
                                onClick={() => {
                                    clearAnalytics();
                                    clearAnalyticsDate();
                                }}
                            >
                                Clear Diagram
                            </Typography>
                        </SizeControllerWrapper>
                    </>
                )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        analyticsInfo: state.analytics.analyticsInfo,
        bloodTargetsList: state.analytics.bloodTargetsList,
        bloodTargetsListResult: state.analytics.bloodTargetsListResult,
        drugsList: state.analytics.drugsList,
        drugsListResult: state.analytics.drugsListResult,
        analyticsResult: state.analytics.analyticsResult,
        loadingRequestState: state.analytics.loadingRequestState,
        showAnalytics: state.analytics.showAnalytics,
        date: state.analytics.date,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAnalyticsPatient: bindActionCreators(AnalyticsActions.getAnalyticsPatient, dispatch),
        clearAnalytics: bindActionCreators(AnalyticsActions.clearAnalytics, dispatch),
        clearAnalyticsDate: bindActionCreators(AnalyticsActions.clearAnalyticsDate, dispatch),
        getAnalyticsResult: bindActionCreators(AnalyticsActions.getAnalyticsResult, dispatch),
        resetStatusVariables: bindActionCreators(AnalyticsActions.resetStatusVariables, dispatch),
    })
)(AnalyticsPage);
