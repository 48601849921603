import React from 'react';
import {makeStyles, Tab, Tabs, Typography} from "@material-ui/core";
import {colors} from "../../theme/colors";
import styled from "styled-components";

const createStyles = makeStyles({
    tab: {
        minHeight: '20px',
        height: '30px',
        minWidth: '10px',
        padding: '6px 16px',
    },
    selectedTab: {
        '& span': {
            color: colors.textPrimary,
        },
    },
    tabText: {
        fontSize: '12px !important',
        color: colors.textSecondary,
    },
    tabs: {
        // minHeight: '35px !important',
        // height: '35px !important',
        marginTop: '4px',

        '& div': {
            whiteSpace: 'initial',
            display: 'block',
            textAlign: 'left',
        },
    },
    indicator: {
        display: 'none',
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: '100%',
            left: 'calc(50% - 4px)',

            borderBottom: `3px solid ${colors.redMain}`,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            width: 0,
            height: 0,
        },
    },
});


const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${colors.gray2};
    padding: 16px 32px;
`;

const PanelTypesTabs: React.FC<any> = (props) => {
    const classes = createStyles(props);
    const { tab, setTab, tabs } = props;
    return(
        <HeaderWrapper>
        <Tabs
            indicatorColor="primary"
            value={tab}
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => setTab && setTab(newValue)}
            classes={{ root: classes.tabs, indicator: classes.indicator }}
        >
            {tabs.map((tab: any, index: number) => (
                <Tab
                    key={index}
                    disableRipple
                    classes={{ root: classes.tab, selected: classes.selectedTab }}
                    label={
                        <Typography variant="button" classes={{ root: classes.tabText }}>
                            {tab.name}
                        </Typography>
                    }
                />
            ))}
        </Tabs>
        </HeaderWrapper>
    )
};

export default PanelTypesTabs;