import React from 'react';
import { UserModel } from '../../models/responses/user.response';
import SidebarMenuUnAuth from './SidebarMenuUnAuth';
import SidebarMenuAuth from './SidebarMenuAuth';
import styled from 'styled-components';
import {colors} from "../../theme/colors";

interface InjectedProps {
    removeCurrentUser: () => void;
    resetStatusVariables: () => void;
    user?: UserModel | null;
    goToPage: (path: string) => void;
}

type PropsTypes = InjectedProps;

const Wrapper = styled.div`
    min-height: calc(100vh - 90px);
    border-right: 1px solid ${colors.grayUnderline};
`;

const SidebarMenuDesktop: React.FC<PropsTypes> = props => {
    return (
        <Wrapper>
            {props.user && props.user.id ? (
                <SidebarMenuAuth
                    goToPage={props.goToPage}
                    closeSidebar={() => {}}
                    removeCurrentUser={props.removeCurrentUser}
                    resetStatusVariables={props.resetStatusVariables}
                    user={props.user}
                />
            ) : (
                <SidebarMenuUnAuth goToPage={props.goToPage} />
            )}
        </Wrapper>
    );
};

export default SidebarMenuDesktop;
