import React from 'react';
import styled from 'styled-components';
import Aboutushead from '../../../../static/images/landing/about/Aboutushead.jpg';
import { colors } from '../../../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import { mediaQuery } from '../../../../constants/device';

const Wrapper = styled.div`
    overflow: hidden;
    min-height: 450px;
    margin-bottom: -100px;
`;

const BackWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(${Aboutushead}) no-repeat;
    background-size: 1008px 322px;
    width: 1008px;
    height: 322px;
    left: -200px;

    ${mediaQuery.tablet} {
        left: 0;
        width: auto;
        min-height: 450px;
        background-size: cover;
    }
`;

const createStyles = makeStyles(theme => ({
    textOur: {
        position: 'relative',
        left: `calc(50% - 80px)`,
        top: '-130px',
        textAlign: 'justify',
        color: `${colors.textPrimary}`,
    },
    textAbout: {
        position: 'relative',
        width: '90%',
        margin: '0 5%',
        top: '-130px',
        textAlign: 'justify',
        color: `${colors.textSecondary}`,
        [theme.breakpoints.up('md')]: {
            width: 'auto',
            margin: '0 20%',
            textAlign: 'center',
        },
    },
}));

const OurWhyMobile = () => {
    const classes = createStyles();

    return (
        <>
            <Wrapper>
                <BackWrapper />
                <Typography variant="h2" classes={{ root: classes.textOur }}>
                    Our Why
                </Typography>
                <Typography variant="body1" classes={{ root: classes.textAbout }}>
                    UPmed began as a passion project, tossed around casually while grilling chicken on the BBQ. After
                    dozens of conversations, numerous clinics trialed and multiple conversations with family and friends
                    affected by the lack of support, it became obvious that there was a definitive void both
                    educationally and logistically in the marketplace. UPmed founders Josh and JC felt they could figure
                    out a way to fill that void and successfully assist thousands of clinicians and their patients
                    towards better overall health. UPmed, is the result of that passion project. We’re excited… to say
                    the least…
                </Typography>
            </Wrapper>
        </>
    );
};

export default OurWhyMobile;
