import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControlLabel, makeStyles, Switch, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import DatePickerBase from '../../../shared/input/DatePickerBase';
import styled from 'styled-components';
import TextInput from '../../../shared/input/TextInput';
import AutocompleteSingle from '../../../shared/input/AutocompleteSingle';
import { DosageUnitOptions } from '../../../constants/dosage/dosageUnitOptions';
import { FrequencyUnitOptions } from '../../../constants/dosage/frequencyUnitOption';
import { QuantityUnitOptions } from '../../../constants/dosage/quantityUnitOptions';
import { PotencyUnitOptions } from '../../../constants/dosage/potencyUnitOptions';
import DropdownInputEntitiesWrapper from '../../../shared/input/DropdownInputEntitiesWrapper';
import { DropdownInputDataTypes } from '../../../constants/dropdownInputDataTypes';
import Snackbar, { SnackbarTypes } from '../../../shared/snackbars/snackbar';
import moment from 'moment';
import SecondaryButton from '../../../shared/button/SecondaryButton';
import { PrescriptionComponentModel } from '../../../models/responses/prescriptionComponentModel.responce';
import { RoleOptions } from '../../../constants/roleOptions';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { mediaQuery } from '../../../constants/device';

interface Prescription {
    dosage: string;
    dosageUnit: string;
    drugApplicationType: BaseDropdownItem | null;
    drug: BaseDropdownItem | null;
    endDate: Date | null;
    frequencyType: string;
    frequencyValue: string;
    potency: string;
    potencyUnit: string;
    private: boolean;
    quantity: string;
    quantityUnit: string;
    startDate: Date | null;
    therapyList: BaseDropdownItem[];
}

const EmptyPrescription = {
    dosage: '',
    dosageUnit: '',
    drugApplicationType: null,
    drug: null,
    endDate: null,
    frequencyType: '',
    frequencyValue: '',
    potency: '',
    potencyUnit: '',
    private: false,
    quantity: '',
    quantityUnit: '',
    startDate: null,
    therapyList: [],
};

enum FieldName {
    dosage = 'dosage',
    dosageUnit = 'dosageUnit',
    drugApplicationType = 'drugApplicationType',
    drug = 'drug',
    endDate = 'endDate',
    frequencyType = 'frequencyType',
    frequencyValue = 'frequencyValue',
    potency = 'potency',
    potencyUnit = 'potencyUnit',
    private = 'private',
    quantity = 'quantity',
    quantityUnit = 'quantityUnit',
    startDate = 'startDate',
    therapyList = 'therapyList',
}

const createStyles = makeStyles({
    noStylesCell: {
        borderBottom: 'none',
    },
    styledCell: {
        padding: '4px',
        borderBottom: `1px solid ${colors.gray1}`,
    },
    unitCell: {
        padding: '0',
        width: '50px',
        borderBottom: `1px solid ${colors.gray1}`,
    },
    deleteCell: {
        border: 'none',
        padding: '0 0 0 4px',
    },
    headerCell: {
        border: `1px solid ${colors.gray1}`,
    },
    row: {},
    placeholderUnit: {
        fontSize: '12px',
        color: colors.gray1,
    },
    valueUnit: {
        color: colors.textSecondary,
        fontSize: '12px',
    },
    inputBase: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            padding: '3px 10px',
            margin: '0 auto',
            width: '60px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
    },
    removeIcon: {
        marginBottom: '-8px',
    },
    noStylesRow: {
        border: 'none',
    },
    addIconDisabled: {
        opacity: 0.5,
        marginBottom: '-8px',
    },
    iconCell: {
        borderBottom: `1px solid ${colors.gray1}`,
        padding: '12px 16px',
    },
    dateRow: {
        borderBottom: `1px solid ${colors.gray1}`,
    },
    cancel: {
        fontSize: '14px',
        color: colors.grayUnderline,
        textDecoration: 'underline',
        textDecorationColor: colors.grayUnderline,
        marginTop: '36px',
        cursor: 'pointer',
    },
    switchText: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
});

interface InjectedProps {
    addPrescriptionComponent: (data: PrescriptionComponentModel) => void;
    saveEditedComponent: (data: PrescriptionComponentModel) => void;
    onCancelClick: (id?: number) => void;
    getId: () => number;
    prescription?: PrescriptionComponentModel | null;
    isBeingEdited?: boolean;
    role: RoleOptions;
}

type PropsTypes = InjectedProps;

const Wrapper = styled.div`
    width: 100%;
    margin-top: 28px;
    margin-bottom: 20px;
`;

const InputWrapperMultiple = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 22px;
`;

const InputWrapperSmall = styled.div`
    width: 70px;
    min-width: 70px;
    max-width: 70px;

    ${mediaQuery.betweenMobileAndTablet} {
        width: 155px;
        min-width: 155px;
        max-width: 155px;
    }

    ${mediaQuery.tablet} {
        width: 235px;
        min-width: 235px;
        max-width: 235px;
    }

    ${mediaQuery.laptop} {
        width: 27%;
        min-width: 27%;
        max-width: 27%;
    }

    ${mediaQuery.laptopL} {
        width: 34%;
        min-width: 34%;
        max-width: 34%;
    }
`;

const InputWrapperFullWidth = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
`;

const FieldWrapper = styled.div<any>`
    width: ${props => (props.isFullLength ? '100%' : '80%')};
`;

const Divider = styled.div`
    width: 1px;
    height: 20px;
    background-color: ${colors.grayUnderline};
    margin: 0 10px 8px;

    ${mediaQuery.tablet} {
        margin: 0 20px 8px;
    }
    ${mediaQuery.laptop} {
        margin: 0 10px 8px;
    }
`;

const DividerTransparent = styled.div`
    margin: 0 5px;

    ${mediaQuery.tablet} {
        margin: 0 15px;
    }
    ${mediaQuery.laptop} {
        margin: 0 5px;
    }
`;

const ControlsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ImagePrivateWrapper = styled.div`
    width: 64px;
`;

const DateWrapper = styled.div`
    width: 100%;
`;

const PrescriptionForm: React.FC<PropsTypes> = props => {
    const [prescription, setPrescription] = useState<PrescriptionComponentModel | Prescription>(
        props.prescription
            ? {
                  ...props.prescription,
                  endDate: props.prescription.endDate ? new Date(props.prescription.endDate) : null,
                  startDate: new Date(props.prescription.startDate),
              }
            : EmptyPrescription
    );

    const [prescriptionError, setPrescriptionError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const classes = createStyles(props);

    const isDateValid = useCallback((value: string | Date | null) => {
        return !!value && value !== 'Invalid Date';
    }, []);

    const checkDatesConsistency = useCallback(() => {
        if (prescription.startDate && prescription.endDate) {
            if (moment(prescription.startDate).isAfter(prescription.endDate)) {
                setPrescriptionError('Dates are not consistent');
            }
        }
        if (prescription.startDate && !prescription.endDate) {
            setPrescriptionError('');
        }
    }, [prescription]);

    const isValid = useMemo(() => {
        checkDatesConsistency();
        console.log(prescription);
        return (
            prescription.quantity.toString().length > 0 &&
            prescription.quantityUnit.length > 0 &&
            prescription.potency.toString().length > 0 &&
            prescription.potencyUnit.length > 0 &&
            prescription.dosage.toString().length > 0 &&
            prescription.dosageUnit.length > 0 &&
            prescription.drugApplicationType &&
            prescription.drug &&
            prescription.frequencyType.length > 0 &&
            prescription.frequencyValue.toString().length > 0 &&
            prescription.therapyList.length > 0 &&
            prescription.startDate &&
            isDateValid(prescription.startDate)
        );
    }, [prescription, isDateValid, checkDatesConsistency]);

    const saveDrug = () => {
        if (isValid) {
            if (prescriptionError.length === 0) {
                const newPrescriptionComponent: PrescriptionComponentModel = {
                    dosage: Number(prescription.dosage),
                    dosageUnit: prescription.dosageUnit,
                    drugApplicationType: prescription.drugApplicationType,
                    drug: prescription.drug,
                    endDate: isDateValid(prescription.endDate!) ? new Date(prescription.endDate!).toISOString() : null,
                    frequencyType: prescription.frequencyType,
                    frequencyValue: Number(prescription.frequencyValue),
                    potency: Number(prescription.potency),
                    potencyUnit: prescription.potencyUnit,
                    private: prescription.private,
                    quantity: Number(prescription.quantity),
                    quantityUnit: prescription.quantityUnit,
                    startDate: new Date(prescription.startDate!).toISOString(),
                    therapyList: prescription.therapyList,
                    id: props.isBeingEdited ? props.prescription!.id : props.getId(),
                    isBeingEdited: false,
                };
                if (props.isBeingEdited) {
                    props.saveEditedComponent(newPrescriptionComponent);
                } else {
                    props.addPrescriptionComponent(newPrescriptionComponent);
                }
            }
        } else {
            setPrescriptionError('Fill all the fields in the prescription');
        }
    };

    useEffect(() => {
        setIsFormValid(!!isValid);
    }, [prescription, isValid]);

    const changeDosage = useCallback(
        (fieldName: FieldName, value: string | number | Date | null | any[] | BaseDropdownItem | boolean) => {
            setPrescriptionError('');
            setPrescription({ ...prescription, [fieldName]: value });
        },
        [prescription]
    );

    const changeDosageDrug = (fieldName: FieldName, value: null | BaseDropdownItem) => {
        setPrescriptionError('');
        const newValue = value && value.id === 0 ? { id: null, name: value.name } : value;
        setPrescription({ ...prescription, [fieldName]: newValue });
    };

    const onCancelClick = () => {
        props.onCancelClick(props.isBeingEdited && props.prescription ? props.prescription.id : undefined);
    };

    const SwitchComponent = useCallback(
        () => (
            <Switch
                color="primary"
                checked={prescription.private}
                onClick={() => changeDosage(FieldName.private, !prescription.private)}
            />
        ),
        [prescription, changeDosage]
    );

    const TypographyPatientComponent = useCallback(
        () => (
            <Typography variant={'button'} classes={{ root: classes.switchText }}>
                Private
            </Typography>
        ),
        [classes.switchText]
    );

    return (
        <>
            <Wrapper>
                <InputWrapperFullWidth>
                    <FieldWrapper isFullLength={props.role === RoleOptions.PROVIDER}>
                        <DropdownInputEntitiesWrapper
                            type={DropdownInputDataTypes.DRUG}
                            onChange={(drug: BaseDropdownItem | null) => changeDosageDrug(FieldName.drug, drug)}
                            value={props.prescription && props.prescription.drug}
                            label={'Drug Name'}
                            customValue
                        />
                    </FieldWrapper>

                    {props.role === RoleOptions.PATIENT && (
                        <ImagePrivateWrapper>
                            <FormControlLabel
                                value="bottom"
                                control={SwitchComponent()}
                                label={TypographyPatientComponent()}
                                labelPlacement="bottom"
                            />
                        </ImagePrivateWrapper>
                    )}
                </InputWrapperFullWidth>
                <InputWrapperMultiple>
                    <TextInput
                        value={prescription.dosage.toString()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeDosage(FieldName.dosage, evt.target.value)
                        }
                        placeholder="Dosage"
                        number
                    />
                    <DividerTransparent />
                    <InputWrapperSmall>
                        <AutocompleteSingle
                            value={prescription.dosageUnit}
                            options={DosageUnitOptions}
                            label={'Unit'}
                            placeholder={'Unit'}
                            isOptionIdString
                            onChange={(value: BaseDropdownItem | null) =>
                                changeDosage(FieldName.dosageUnit, value ? value.id : '')
                            }
                        />
                    </InputWrapperSmall>
                    <Divider />
                    <TextInput
                        value={prescription.frequencyValue.toString()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeDosage(FieldName.frequencyValue, evt.target.value)
                        }
                        placeholder="Frequency"
                        number
                    />
                    <DividerTransparent />
                    <InputWrapperSmall>
                        <AutocompleteSingle
                            value={prescription.frequencyType}
                            options={FrequencyUnitOptions}
                            label={'Type'}
                            placeholder={'Type'}
                            isOptionIdString
                            onChange={(value: BaseDropdownItem | null) => {
                                changeDosage(FieldName.frequencyType, value ? value.id : '');
                            }}
                        />
                    </InputWrapperSmall>
                </InputWrapperMultiple>

                <InputWrapperMultiple>
                    <DateWrapper>
                        <DatePickerBase
                            label={'Start Date'}
                            value={prescription.startDate ? new Date(prescription.startDate) : null}
                            onChange={(date: Date | null) => {
                                changeDosage(FieldName.startDate, date);
                            }}
                        />
                    </DateWrapper>
                    <Divider />
                    <DateWrapper>
                        <DatePickerBase
                            label={'End Date'}
                            value={prescription.endDate ? new Date(prescription.endDate) : null}
                            onChange={(date: Date | null) => {
                                changeDosage(FieldName.endDate, date);
                            }}
                            helperText={'(optional)'}
                        />
                    </DateWrapper>
                </InputWrapperMultiple>

                <InputWrapperMultiple>
                    <TextInput
                        value={prescription.quantity.toString()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeDosage(FieldName.quantity, evt.target.value)
                        }
                        placeholder="Quantity"
                        number
                    />
                    <DividerTransparent />
                    <InputWrapperSmall>
                        <AutocompleteSingle
                            value={prescription.quantityUnit}
                            options={QuantityUnitOptions}
                            label={'Unit'}
                            placeholder={'Unit'}
                            isOptionIdString
                            onChange={(value: BaseDropdownItem | null) => changeDosage(FieldName.quantityUnit, value ? value.id : '')}
                        />
                    </InputWrapperSmall>
                    <Divider />
                    <TextInput
                        value={prescription.potency.toString()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeDosage(FieldName.potency, evt.target.value)
                        }
                        placeholder="Potency"
                        number
                    />
                    <DividerTransparent />
                    <InputWrapperSmall>
                        <AutocompleteSingle
                            value={prescription.potencyUnit}
                            options={PotencyUnitOptions}
                            label={'Unit'}
                            placeholder={'Unit'}
                            isOptionIdString
                            onChange={(value: BaseDropdownItem | null) => changeDosage(FieldName.potencyUnit, value ? value.id : '')}
                        />
                    </InputWrapperSmall>
                </InputWrapperMultiple>

                <InputWrapperMultiple>
                    <DropdownInputEntitiesWrapper
                        type={DropdownInputDataTypes.APPLICATION_TYPE}
                        onChange={(type: BaseDropdownItem | null) => changeDosage(FieldName.drugApplicationType, type)}
                        value={props.prescription && props.prescription.drugApplicationType}
                        label={'Application Type'}
                    />
                    <Divider />
                    <DropdownInputEntitiesWrapper
                        type={DropdownInputDataTypes.THERAPY}
                        onChange={(therapy: BaseDropdownItem | null) =>
                            changeDosage(FieldName.therapyList, therapy ? [therapy] : [])
                        }
                        value={props.prescription && props.prescription.therapyList[0]}
                        label={'Therapy'}
                    />
                </InputWrapperMultiple>
            </Wrapper>

            <ControlsWrapper>
                <SecondaryButton onClick={isFormValid ? () => saveDrug() : () => {}} disabled={!isFormValid}>
                    {props.isBeingEdited ? 'Save' : 'Add Drug'}
                </SecondaryButton>
                <Typography variant="button" classes={{ root: classes.cancel }} onClick={onCancelClick}>
                    Cancel
                </Typography>
            </ControlsWrapper>

            <Snackbar
                message={prescriptionError}
                open={prescriptionError.length > 0}
                variant={'error'}
                type={SnackbarTypes.ERROR}
            />
        </>
    );
};

export default PrescriptionForm;
