import React, { useCallback, useEffect } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import { connect } from 'react-redux';
import { UserModel } from '../../models/responses/user.response';
import { InvoiceModel } from '../../models/responses/invoice.model';
import { Actions as InvoiceActions } from '../../store/actions/invoice.actions';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RoleOptions } from '../../constants/roleOptions';
import Loading from '../shared/Loading';
import HistoryListItem from './components/history/HistoryListItem';
import { InvoiceStatus } from '../../constants/invoice/invoiceStatus';
import NewPaymentListItem from './components/new/NewPaymentListItem';
import ActiveSubscriptionListItem from './components/active/ActiveSubscriptionListItem';

interface StoreModel {
    user: UserModel | null;
    currentInvoice: InvoiceModel | null;
    loadingCurrentInvoice: boolean;
}

interface DispatchModel {
    getInvoiceById: typeof InvoiceActions.getInvoiceById;
    getInvoiceByIdProvider: typeof InvoiceActions.getInvoiceByIdProvider;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const InvoiceView: React.FC<PropsTypes> = props => {
    const { user, getInvoiceById, getInvoiceByIdProvider, currentInvoice, loadingCurrentInvoice, match } = props;

    useEffect(() => {
        if (user && user.role === RoleOptions.PATIENT) {
            getInvoiceById(match.params.id);
        }
        if (user && user.role === RoleOptions.PROVIDER) {
            getInvoiceByIdProvider({ id: match.params.id, patientId: match.params.patientId });
        }
    }, [match, getInvoiceById, getInvoiceByIdProvider, user]);

    const renderInvoice = useCallback(() => {
        if (currentInvoice) {
            switch (currentInvoice.status) {
                case InvoiceStatus.FAILED:
                case InvoiceStatus.INACTIVE:
                case InvoiceStatus.PAID:
                case InvoiceStatus.REJECTED:
                    return <HistoryListItem index={0} invoice={currentInvoice} isViewPage />;
                case InvoiceStatus.REQUESTED:
                case InvoiceStatus.REQUIRES_ACTION:
                    return <NewPaymentListItem invoice={currentInvoice} isViewPage index={0} />;
                case InvoiceStatus.ACTIVE:
                    return <ActiveSubscriptionListItem invoice={currentInvoice} index={0} isViewPage />;
            }
        }
    }, [currentInvoice]);

    return (
        <>
            <HeaderHeadingWithLink>Payment</HeaderHeadingWithLink>
            {loadingCurrentInvoice && <Loading smallSize />}

            {renderInvoice()}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
        currentInvoice: state.invoice.currentInvoice,
        loadingCurrentInvoice: state.invoice.loadingCurrentInvoice,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getInvoiceById: bindActionCreators(InvoiceActions.getInvoiceById, dispatch),
        getInvoiceByIdProvider: bindActionCreators(InvoiceActions.getInvoiceByIdProvider, dispatch),
    })
)(withRouter(InvoiceView));
