import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { mediaQuery } from '../../../../constants/device';
import { colors } from '../../../../theme/colors';
import RegularText from '../../shared/RegularText';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr 0.12fr;
    grid-template-rows: 0.1fr 0.48fr 1.5fr;

    ${mediaQuery.tablet} {
        padding: 0;
        grid-template-rows: 1fr;
        grid-template-columns: 1.5fr 1fr;
    }
    ${mediaQuery.laptopL} {
        padding: 0 12%;
    }
`;

const TextWrapper = styled.div`
    padding: 40px 42px 20px 23px;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 3;
    height: auto;

    ${mediaQuery.tablet} {
        padding: 50px;
        grid-column: 1;
        grid-row: 1;
    }
`;

const Background = styled.div`
    background-color: ${colors.gray2};
    grid-row: 1/4;
    grid-column: 1/4;
    width: 95%;
    ${mediaQuery.tablet} {
        background-color: transparent;
    }
`;

const DetailsWrapper = styled.div`
    margin-top: 12px;
    ${mediaQuery.tablet} {
        width: 110%;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }
    ${mediaQuery.laptop} {
        width: 100%;
    }
`;

interface InjectedProps {
    redHeading: string;
    heading: string;
    text: string;
    author: string;
    date: string;
    img: string;
}

const createStyles = makeStyles(theme => ({
    redHeading: {
        fontSize: '12px',
        lineHeight: '18px',
        color: colors.redMain,
        textTransform: 'uppercase',
        display: 'block',
        marginBottom: '4px',
    },
    heading: {
        fontSize: '24px',
        lineHeight: '36px',
        color: colors.textPrimary,
        marginBottom: '22px',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            width: '93%',
            fontSize: '36px',
            lineHeight: '48px',
        },
    },
    img: {
        width: '100%',
        gridColumn: '2/4',
        gridRow: '2/3',
        zIndex: 2,
        marginBottom: '-30px',
        [theme.breakpoints.up('md')]: {
            gridRow: '1',
            gridColumn: '2',
            alignSelf: 'center',
            justifySelf: 'center',
            width: '350px',
        },
    },
    author: {
        fontSize: '12px',
        lineHeight: '18px',
        color: colors.textPrimary,
        textTransform: 'uppercase',
        marginBottom: '8px',
    },
    date: {
        fontSize: '12px',
        lineHeight: '18px',
        color: colors.textPrimary,
    },
}));

const CaseStudyComponent: React.FC<InjectedProps> = props => {
    const { redHeading, text, author, date, img, heading } = props;

    const classes = createStyles(props);

    return (
        <Wrapper>
            <img src={img} className={classes.img} alt="" />
            <Background />
            <TextWrapper>
                <Typography variant={'button'} classes={{ root: classes.redHeading }}>
                    {redHeading}
                </Typography>
                <Typography variant={'button'} classes={{ root: classes.heading }}>
                    {heading}
                </Typography>
                <RegularText>{text}</RegularText>
                <DetailsWrapper>
                    <Typography variant={'button'} classes={{ root: classes.author }}>
                        By {author}
                    </Typography>{' '}
                    <br />
                    <Typography variant={'button'} classes={{ root: classes.date }}>
                        Publish Date {date}
                    </Typography>
                </DetailsWrapper>
            </TextWrapper>
        </Wrapper>
    );
};

export default CaseStudyComponent;
