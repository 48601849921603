import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import { Link } from 'react-router-dom';
import { ChevronLeft } from '@material-ui/icons';

interface IProps {
    backLink?: string;
    children?: ReactNode;
}

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 50px;
    width: 100%;
    padding: 5px 0;
    text-align: center;
    margin: 25px 0;
    position: relative;
`;

const BackLink = styled(Link)`
    position: absolute;
    background-color: ${colors.gray2};
    color: ${colors.textSecondary};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    left: 30px;
    border-radius: 100%;
    text-align: center;
`;

export function EmptyHeaderWithLink(props: IProps) {
    return (
        <HeaderWrapper>
            {props.backLink && <BackLink to={props.backLink}>
                <ChevronLeft />
            </BackLink>}
            {props.children}
        </HeaderWrapper>
    )
}
