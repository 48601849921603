import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';
import calendarPicker from '../../static/icons/calenderPicker.svg';
import { DatePickerView } from '@material-ui/pickers/DatePicker/DatePicker';

interface InjectedProps {
    value: Date | null;
    onChange: (date: Date | null) => void;
    success?: boolean | null;
    error?: boolean | null;
    disabled?: boolean;
}

const styles = createStyles({
    root: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            padding: '15px 12px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
        },
    },
    underline: {
        '&:after': {
            borderBottom: `1px solid ${colors.grayUnderline} !important`,
        },
        '&:before': {
            borderBottom: `1px solid ${colors.grayUnderline} !important`,
        },
        '&:hover:not($error):before': {
            borderBottom: `1px solid ${colors.grayUnderline} !important`,
        },
    },
    underlineSuccess: {
        '&:after': {
            borderBottom: `1px solid ${colors.success}`,
        },
        '&:before': {
            borderBottom: `1px solid ${colors.success}`,
        },
        '&:hover:not($error):before': {
            borderBottom: `1px solid ${colors.success} !important`,
        },
    },
    error: {
        '&:after': {
            borderBottom: `1px solid ${colors.redLight}!important`,
        },
    },
    disabled: {
        color: `${colors.textSecondary} !important`,
    },
});

type PropsTypes = WithStyles<typeof styles> & InjectedProps;

const monthViewKeyboard: DatePickerView[] = ['year', 'month', 'date'];

const DatePicker: React.FC<PropsTypes> = props => {
    const { classes } = props;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                placeholder={'__/__/____'}
                format="MM/dd/yyyy"
                variant="inline"
                disableFuture
                openTo="year"
                views={monthViewKeyboard}
                autoOk
                margin="normal"
                value={props.value}
                onChange={props.onChange}
                keyboardIcon={<img src={calendarPicker} alt="calendar picker" />}
                helperText={null}
                InputProps={{
                    classes: {
                        root: classes.root,
                        underline: props.success ? classes.underlineSuccess : classes.underline,
                        error: classes.error,
                        disabled: classes.disabled,
                    },
                }}
                error={!!props.error}
                disabled={props.disabled}
            />
        </MuiPickersUtilsProvider>
    );
};

export default withStyles(styles)(DatePicker);
