import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';

const useStyles = makeStyles({
    letter: {
        fontSize: '24px',
        color: colors.gray1,
        textTransform: 'uppercase',
        margin: '12px 12px -11px',
    },
});

const AlphabetLetter = (props: any) => {
    const classes = useStyles(props);

    return (
        <SizeControllerWrapper>
            <Typography variant="h2" classes={{ root: classes.letter }}>
                {props.children}
            </Typography>
        </SizeControllerWrapper>
    );
};

export default AlphabetLetter;
