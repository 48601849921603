import { restAxios } from '../restAxios';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { UploadBloodworkFilePatientDto } from '../../models/dto/uploadBloodworkFilePatientDto';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { UploadBloodworkFileProviderDto } from '../../models/dto/uploadBloodworkFileProviderDto';
import {UpdateLabWorkPatientDto, UploadLabWorkPatientDto} from '../../models/dto/uploadLabWorkPatient.dto';
import { UploadLabWorkProviderDto } from '../../models/dto/uploadLabWorkProvider.dto';

class LabWorkApi {
    @HandleAsyncErrors()
    static async getAllBloodWorksPatient(data: AllEntitiesByPagesDto) {
        return await restAxios.get(
            `private/lab-works/blood?page=${data.page}&size=${data.size}&sort=${data.sort &&
                encodeURIComponent(data.sort)}`
        );
    }

    @HandleAsyncErrors()
    static async getBloodWorkByIdPatient(id: number) {
        return await restAxios.get(`private/lab-works/blood/${id}`);
    }

    @HandleAsyncErrors()
    static async getBloodWorkByIdProvider({ bloodworkId, patientId }: { bloodworkId: number; patientId: number }) {
        return await restAxios.get(`private/lab-works/blood/${bloodworkId}/patient/${patientId}`);
    }

    @HandleAsyncErrors()
    static async getAllBloodWorksByPatientIdProvider({
        data,
        patientId,
    }: {
        data: AllEntitiesByPagesDto;
        patientId: number;
    }) {
        const { page, size, sort, searchQuery } = data;
        return await restAxios.get(`private/lab-works/blood/patient/${patientId}`, {
            params: {
                page,
                size,
                sort,
                searchQuery,
            },
        });
    }

    @HandleAsyncErrors()
    static async uploadBloodWorkPatient(data: UploadLabWorkPatientDto) {
        const { labId, reportDate, targets } = data;
        return await restAxios.post(`private/lab-works/blood`, {
            labId,
            reportDate,
            targets,
        });
    }

    @HandleAsyncErrors()
    static async uploadBloodWorkProvider(data: UploadLabWorkProviderDto) {
        const { labId, reportDate, targets, patientId } = data;
        return await restAxios.post(`private/lab-works/blood/patient/${patientId}`, {
            labId,
            reportDate,
            targets,
        });
    }

    @HandleAsyncErrors()
    static async uploadBloodWorkFilePatient(data: UploadBloodworkFilePatientDto) {
        const { id, file } = data;
        const formData = new FormData();
        formData.append('file', file);
        return await restAxios.post(`private/lab-works/${id}/file`, formData);
    }

    @HandleAsyncErrors()
    static async uploadBloodWorkFileProvider(data: UploadBloodworkFileProviderDto) {
        const { id, file, patientId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return await restAxios.post(`private/lab-works/${id}/patient/${patientId}/file`, formData);
    }

    @HandleAsyncErrors()
    static async getBloodTargets(data: AllEntitiesByPagesDto) {
        const { size, page, sort, searchQuery } = data;
        return await restAxios.get(`private/blood-targets`, {
            params: { page, size, searchQuery, sort: sort && encodeURIComponent(sort) },
        });
    }

    @HandleAsyncErrors()
    static async updateBloodWorkPatient(data: UpdateLabWorkPatientDto){
        return await  restAxios.put(`private/lab-works/blood/${data.id}`, data);
    }
}

export default LabWorkApi;
