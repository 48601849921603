import React from 'react';
import styled from 'styled-components';
import checkIcon from '../../../../static/icons/payment/check-icon-red.svg';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../../theme/colors';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.redTransparent};
    border-radius: 20px;
    width: 67px;
    height: 25px;
`;

const createStyles = makeStyles({
    text: {
        fontSize: '10px',
        color: colors.redText,
        margin: '3px 0 0 3px',
    },
});

const DefaultMarker: React.FC = props => {
    const classes = createStyles(props);
    return (
        <Wrapper>
            <img src={checkIcon} alt="" />
            <Typography variant={'body2'} classes={{ root: classes.text }}>
                Default
            </Typography>
        </Wrapper>
    );
};

export default DefaultMarker;
