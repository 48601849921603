import React, { useState } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import PrimaryButton from '../../shared/button/PrimaryButton';
import CropAvatar from './components/CropAvatar';
import halfLogo from '../../static/images/landing/main/halfLogoBackground.svg';
import { colors } from '../../theme/colors';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import CustomLink from '../../shared/link/CustomLink';
import {mediaQuery} from "../../constants/device";

const styles = createStyles({
    backLink: {
        color: colors.redMain,
        cursor: 'pointer',
    },
});

const BackgroundWrapper = styled.div`
    height: calc(100vh - 90px);
    background-image: url(${halfLogo});
    background-repeat: no-repeat;
    background-position: right 45%;
    background-size: 53%;
    ${mediaQuery.laptop}{
      height: calc(100vh - 160px);
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: -webkit-fill-available;
    padding: 55% 0 38px;
`;

const ButtonWrapper = styled.div`
    width: min-content;
    & button:first-child {
        margin-bottom: 20px;
    }
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

type PropsTypes = WithStyles<typeof styles>;

const SelectAvatar: React.FC<PropsTypes> = props => {
    const [avatar, setAvatar] = useState<string | null>(null);
    const [avatarFile, setAvatarFile] = useState(null);
    const [openCropModal, setOpenCropModal] = useState(false);
    const [orientation, setOrientation] = useState<'h' | 'v' | null>(null);
    const [originalSize, setOriginalSize] = useState({ h: 0, w: 0 });

    const changeAvatar = (evt: React.ChangeEvent<any>) => {
        if (evt.target.files.length > 0) {
            setOpenCropModal(true);
            const readerInstance = new FileReader();

            readerInstance.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = function() {
                    setOriginalSize({ h: image.height, w: image.width });
                    setOrientation(image.width > image.height ? 'h' : 'v');
                    setAvatar(e.target.result);
                    readerInstance.abort();
                };
            };
            readerInstance.onerror = (e: any) => {
                console.log('err', e);
            };
            if (readerInstance.readyState !== readerInstance.DONE) {
                readerInstance.readAsDataURL(evt.target.files[0]);
                setAvatarFile(evt.target.files[0]);
            }
        }
    };

    const { classes } = props;

    return (
        <RelativeWrapper>
            <BackgroundWrapper>
                <SizeControllerWrapper>
                    <Wrapper>
                        <ButtonWrapper>
                            <PrimaryButton onClick={() => {}}>Use Camera</PrimaryButton>
                            <PrimaryButton
                                onClick={() =>
                                    document.getElementById('photoInput') &&
                                    document.getElementById('photoInput')!.click()
                                }
                            >
                                Open Gallery
                            </PrimaryButton>
                        </ButtonWrapper>
                        <CustomLink to={'/profile/editOn'} underlineColor={colors.redMain}>
                            <Typography variant="button" classes={{ root: classes.backLink }}>
                                Cancel
                            </Typography>
                        </CustomLink>
                    </Wrapper>
                </SizeControllerWrapper>
            </BackgroundWrapper>

            <input
                type="file"
                multiple={false}
                id="photoInput"
                style={{ display: 'none' }}
                onChange={changeAvatar}
                onClick={(evt: any) => {
                    evt.target.value = null;
                }}
            />

            {avatar && openCropModal && (
                <CropAvatar
                    src={avatar}
                    file={avatarFile}
                    orientation={orientation}
                    closeDialog={() => {
                        setOpenCropModal(false);
                        setAvatar(null);
                    }}
                    originalSize={originalSize}
                />
            )}
        </RelativeWrapper>
    );
};

export default withStyles(styles)(SelectAvatar);
