import { IconButton, makeStyles, Snackbar as MaterialSnackbar, SnackbarContent, Theme } from '@material-ui/core';
import * as React from 'react';
import { amber, green } from '@material-ui/core/colors';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

export enum SnackbarTypes {
    SUCCESS = 'success',
    INFO = 'info',
    ERROR = 'error',
    WARNING = 'warning',
}

export interface SnackbarProps {
    type: SnackbarTypes;
    message: string;
    open: boolean;
    onClose?: () => void;
    variant: keyof typeof snackbarIconVariant;
}

export const snackbarIconVariant = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
    [SnackbarTypes.SUCCESS]: {
        backgroundColor: green[600],
    },
    [SnackbarTypes.ERROR]: {
        backgroundColor: theme.palette.error.dark,
    },
    [SnackbarTypes.INFO]: {
        backgroundColor: theme.palette.primary.main,
    },
    [SnackbarTypes.WARNING]: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    messageWrapper: {
        width: '97%',
    },
    closeIcon: {
        position: 'absolute',
        right: '0',
        top: '4px',
    },
}));

export default function Snackbar(props: SnackbarProps) {
    const classes = useStyles();
    const Icon = snackbarIconVariant[props.variant];

    const renderMsg = () => {
        return (
            <SnackbarContent
                className={clsx(classes[props.type])}
                classes={{ message: classes.messageWrapper }}
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {props.message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={props.onClose}
                        className={classes.closeIcon}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        );
    };

    return (
        <MaterialSnackbar
            autoHideDuration={5000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={props.open}
            onClose={props.onClose}
        >
            {renderMsg()}
        </MaterialSnackbar>
    );
}
