import React, { useEffect } from 'react';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import CustomLink from '../../../shared/link/CustomLink';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { Actions as AuthActions } from '../../../store/actions/auth.actions';
import { bindActionCreators, Dispatch } from 'redux';
import Card from '../../../shared/card/Card';
import { UserModel } from '../../../models/responses/user.response';
import { mediaQuery } from '../../../constants/device';

const styles = createStyles({
    heading: {
        fontSize: '24px',
    },
    text: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    linkSendAgain: {
        textDecorationColor: colors.textPrimary,
        fontSize: '20px',
    },
    backLink: {
        color: colors.redMain,
    },
});

const BackgroundWrapper = styled.div`
    padding: 52px 0 85px;
    background-color: ${colors.gray2};
    height: calc(100vh - 227px);

    ${mediaQuery.mobileLandscape} {
        height: calc(100vh - 128px);
    }
`;

const EmailWrapper = styled.span`
    font-weight: bold;
    color: ${colors.textPrimary};
    margin-left: 2px;
`;

type StoreModel = {
    user: UserModel | null;
};

type DispatchModel = {
    resetStatusVariables: typeof AuthActions.resetStatusVariables;
};

type PropsTypes = DispatchModel & StoreModel & WithStyles<typeof styles>;

const ResetPasswordLinkSent: React.FC<PropsTypes> = props => {
    const { resetStatusVariables, user } = props;

    useEffect(() => {
        resetStatusVariables();
    }, [resetStatusVariables]);

    const { classes } = props;
    return (
        <>
            <BackgroundWrapper>
                <SizeControllerWrapper>
                    <Card>
                        <Typography variant="h2" classes={{ root: classes.heading }}>
                            Password Reset <br />
                            Email Sent
                        </Typography>

                        <Typography variant="body1" classes={{ root: classes.text }}>
                            An email has been sent to your email address,
                            <EmailWrapper>{user && user.email}</EmailWrapper>.
                            <br />
                            Follow the directions in the email to reset your password.
                        </Typography>

                        <CustomLink to={'/'}>
                            <PrimaryButton>Ok</PrimaryButton>
                        </CustomLink>
                    </Card>
                </SizeControllerWrapper>
            </BackgroundWrapper>
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(AuthActions.resetStatusVariables, dispatch),
    })
)(withStyles(styles)(ResetPasswordLinkSent));
