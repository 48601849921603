import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../../theme/colors';

const createStyles = makeStyles({
    text: {
        fontSize: '12px',
        color: colors.gray3,
        lineHeight: '18px',
    },
    button: {
        minWidth: 'auto',
        minHeight: 'auto',
        height: '35px',
        width: '114px',
        border: `1px solid ${colors.gray3}`,
        padding: '7.5px 12px !important',
        textAlign: 'center',
    },
});

const SetAsDefault: React.FC<{onClick: () => void}> = props => {
    const classes = createStyles(props)
    const {onClick} = props;
    return (
        <Button classes={{ root: classes.button }} onClick={onClick}>
            <Typography variant={'body2'} classes={{ root: classes.text }}>
                Set as Default
            </Typography>
        </Button>
    );
};

export default SetAsDefault;
