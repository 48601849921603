import { restAxios } from '../restAxios';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import {EditPanelType} from "../../models/dto/editPanelType";
import {AddPanelTypeDto} from "../../models/dto/addPanelType.dto";

class PanelTypeApi {
    @HandleAsyncErrors()
    static async getPanelTypes() {
        const res = await restAxios.get(`private/blood-target-panel-types`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async getPanelTypesWithBloodTargets() {
        const res = await restAxios.get(`private/blood-target-panel-types/with-targets`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async editPanelType(data: EditPanelType) {
        const res = await restAxios.put(`private/blood-target-panel-types/${data.id}/with-targets`, data.bloodTargetIds);
        return res.data;
    }

    @HandleAsyncErrors()
    static async removePanelType(id: number) {
        const res = await restAxios.delete(`private/blood-target-panel-types/${id}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async addPanelType(data: AddPanelTypeDto) {
        const res = await restAxios.post(`private/blood-target-panel-types`, data);
        return res.data;
    }
}

export default PanelTypeApi;
