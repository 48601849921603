import React from 'react';
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import { mediaQuery } from '../../constants/device';

const createStyles = makeStyles(theme => ({
    text: {
        fontSize: '16px',
        lineHeight: '24px',
        color: colors.white,
    },

    imgLeft: (props: { imageMargin?: boolean | undefined; horizontal?: boolean | undefined }) => ({
        width: '100%',
        gridColumn: props.horizontal ? '1/3' : props.imageMargin ? '2/3' : '2/4',
        zIndex: 2,
        gridRow: '1/3',
        [theme.breakpoints.up('md')]: {
            gridRow: '2/4',
            gridColumn: '1/3',
            zIndex: -1,
        },
    }),
    imgRight: (props: { imageMargin?: boolean | undefined; horizontal?: boolean | undefined }) => ({
        width: '100%',
        gridColumn: props.horizontal ? '1/3' : props.imageMargin ? '2/3' : '2/4',
        zIndex: 2,
        gridRow: '1/3',
        [theme.breakpoints.up('md')]: {
            gridColumn: 3,
        },
    }),
}));

//
const WrapperImgLeft = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr 0.1fr;
    grid-template-rows: 0.5fr 0.48fr 1.5fr;
    background-color: ${colors.redMain};

    ${mediaQuery.tablet} {
        grid-template-rows: 0.1fr 1fr 0.1fr;
        grid-template-columns: 1fr 0.1fr 1fr;
        background-color: transparent;
    }
`;

const WrapperImgRight = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr 0.1fr;
    grid-template-rows: 0.5fr 0.48fr 1.5fr;
    background-color: ${colors.redMain};

    ${mediaQuery.tablet} {
        grid-template-rows: 0.1fr 1fr;
        grid-template-columns: 0.3fr 1.5fr 0.9fr;
        margin-right: 12%;
        background-color: transparent;
    }
`;

//
const TextWrapperLeft = styled.div`
    padding: 40px 20px;
    background-color: ${colors.redMain};
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 3;
    height: auto;

    ${mediaQuery.tablet} {
        padding: 13% 5% 13% 12%;
        grid-row: 2/3;
        grid-column: 2/4;
    }
    ${mediaQuery.laptop} {
        padding: 13% 42% 13% 12%;
    }
`;

const TextWrapperRight = styled.div`
    padding: 40px 20px;
    background-color: ${colors.redMain};
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 3;
    height: auto;

    ${mediaQuery.tablet} {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        padding: 40px 50px;
        grid-row: 2 / 3;
        grid-column: 2 / 3;
    }
    ${mediaQuery.laptop} {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 54%;
        margin: 0 auto;
        padding: 40px 50px;
        grid-row: 2 / 3;
        grid-column: 2 / 3;
    }
`;

const BackgroundLeft = styled.div`
    background-color: ${colors.redMain};
    grid-row: 2/3;
    grid-column: 1/4;
    ${mediaQuery.tablet} {
        grid-row: 1/3;
        grid-column: 2/4;
    }
`;

const BackgroundRight = styled.div`
    background-color: ${colors.redMain};
    grid-row: 2 / 3;
    grid-column: 1 / 4;
`;

export enum ImgSide {
    'right',
    'left',
}

interface InjectedProps {
    text: string;
    img: string;
    imgSide: ImgSide;
    backgroundColor?: string;
    imageMargin?: boolean | undefined;
    horizontal?: boolean;
}

type PropsTypes = InjectedProps;

const CardWithTopImageRed: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    return (
        <>
            {props.imgSide === ImgSide.left ? (
                <WrapperImgLeft>
                    <img src={props.img} className={classes.imgLeft} alt="" />
                    <BackgroundLeft />
                    <TextWrapperLeft>
                        {props.children}
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            {props.text}
                        </Typography>
                    </TextWrapperLeft>
                </WrapperImgLeft>
            ) : (
                <WrapperImgRight>
                    <img src={props.img} className={classes.imgRight} alt="" />
                    <BackgroundRight />
                    <TextWrapperRight>
                        {props.children}
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            {props.text}
                        </Typography>
                    </TextWrapperRight>
                </WrapperImgRight>
            )}
        </>
    );
};

export default CardWithTopImageRed;
