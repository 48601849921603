import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProviderMyPatientActions } from '../../store/actions/providerMyPatients.actions';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import ScrollableList from '../../shared/scrollable-list/ScrollableList';
import { PatientModel } from '../../models/responses/patient.response';
import { SortOptions } from '../../constants/sortOptions';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers/index';
import NoPatients from './NoPatients';
import PatientTypes from '../../constants/patientTypes';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../constants/layout';
import {DefaultPageSize, shouldFetchData} from '../../constants/dataFetching/allEntitiesByPages';

interface DispatchModel {
    increasePageNumberInvitedPatients: typeof ProviderMyPatientActions.increasePageNumberInvitedPatients;
    getAllInvitedPatients: typeof ProviderMyPatientActions.getAllInvitedPatients;
    setFilterQueryInvitedPatients: typeof ProviderMyPatientActions.setFilterQueryInvitedPatients;
    resetStatusVariables: typeof PatientActions.resetStatusVariables;
}

interface StoreModel {
    myInvitedPatients: PatientModel[];
    totalInvitedPatients: number;
    pageInvitedPatients: number;
    searchQueryInvitedPatients: string;

    loading: boolean;
}

type PropsTypes = StoreModel & DispatchModel;

function InvitedPatientsList(props: PropsTypes) {
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const {
        resetStatusVariables,
        loading,
        getAllInvitedPatients,
        increasePageNumberInvitedPatients,
        myInvitedPatients,
        pageInvitedPatients,
        searchQueryInvitedPatients,
        setFilterQueryInvitedPatients,
        totalInvitedPatients,
    } = props;

    const loadInvitedPatients = useCallback(
        (page: number, size: number, sort: SortOptions, q: string, override: boolean) => {
            getAllInvitedPatients({
                page,
                size,
                sort,
                searchQuery: q,
                overrideRecords: override,
            });
        },
        [getAllInvitedPatients]
    );

    useEffect(() => {
        resetStatusVariables();
        setFilterQueryInvitedPatients('');
        loadInvitedPatients(0, 10, SortOptions.LAST_NAME_ASCENDING, '', true);
        setInitialLoaded(true);
    }, [resetStatusVariables, setFilterQueryInvitedPatients, loadInvitedPatients]);

    useEffect(() => {
        if (
            shouldFetchData(loading, pageInvitedPatients, myInvitedPatients.length, initialLoaded, DefaultPageSize) ||
            (searchQueryInvitedPatients && initialLoaded)
        ) {
            loadInvitedPatients(
                pageInvitedPatients,
                DefaultPageSize,
                SortOptions.LAST_NAME_ASCENDING,
                searchQueryInvitedPatients,
                false
            );
        }
    }, [
        pageInvitedPatients,
        searchQueryInvitedPatients,
        loadInvitedPatients,
        initialLoaded,
        loading,
        myInvitedPatients,
    ]);

    const getMoreInvitedPatients = () => {
        increasePageNumberInvitedPatients();
    };

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    return (
        <>
            {!loading && totalInvitedPatients === 0 && !searchQueryInvitedPatients.length ? (
                <NoPatients type={PatientTypes.INVITED} height={height} />
            ) : (
                <div ref={listRef}>
                    <ScrollableList
                        isLoading={loading}
                        totalRecordsNumber={totalInvitedPatients}
                        data={myInvitedPatients}
                        nextAction={loading ? () => {} : getMoreInvitedPatients}
                        height={height}
                        type={'patient'}
                    />
                </div>
            )}
        </>
    );
}

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        myInvitedPatients: state.providerMyPatients.myInvitedPatients,
        totalInvitedPatients: state.providerMyPatients.totalInvitedPatients,
        pageInvitedPatients: state.providerMyPatients.pageInvitedPatients,
        searchQueryInvitedPatients: state.providerMyPatients.searchQueryInvitedPatients,
        loading: state.providerMyPatients.loading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllInvitedPatients: bindActionCreators(ProviderMyPatientActions.getAllInvitedPatients, dispatch),
        setFilterQueryInvitedPatients: bindActionCreators(
            ProviderMyPatientActions.setFilterQueryInvitedPatients,
            dispatch
        ),
        increasePageNumberInvitedPatients: bindActionCreators(
            ProviderMyPatientActions.increasePageNumberInvitedPatients,
            dispatch
        ),
        resetStatusVariables: bindActionCreators(PatientActions.resetStatusVariables, dispatch),
    })
)(InvitedPatientsList);
