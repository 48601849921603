import React from 'react';
import {Button, makeStyles} from "@material-ui/core";
import editProfileAvatar from "../../static/icons/editProfileAvatar.svg";
import styled from "styled-components";
import {colors} from "../../theme/colors";
import CustomLink from "../../shared/link/CustomLink";

const Avatar = styled.div<{ isDefaultAvatar: boolean; image: string | null }>`
    height: 250px;
    width: 250px;
    border-radius: 150px;
    background-color: ${colors.gray2};
    background-image: url(${props => props.image});
    background-size: ${props => (props.isDefaultAvatar ? 'auto' : 'cover')};
    background-repeat: no-repeat;
    background-position: center;
`;

const AvatarDefault = styled.div<{ isDefaultAvatar: boolean; image: string | null }>`
    height: 200px;
    width: 200px;
    border-radius: 100px;
    background-color: ${colors.gray2};
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${props => (props.isDefaultAvatar ? 'auto' : 'cover')};
`;

const AvatarStyling = styled.div`
    width: 270px;
    height: 270px;
    border-radius: 150px;
    border: 1px solid ${colors.gray2};
    background-color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AvatarWrapper = styled.div`
    position: relative;
`;

interface InjectedProps {
    editMode: boolean;
    isDefaultAvatar: boolean;
    avatar: string | null;
}

const createStyles = makeStyles({
    imgEditAvatar: {
        position: 'absolute',
        right: '-4px',
        bottom: '-4px',
        minWidth: '60px',
        width: '60px',
        height: '60px',
        padding: '0 !important',
    },
});

const AvatarProfileBigShared: React.FC<InjectedProps> = (props) => {
    const {editMode,isDefaultAvatar,avatar} = props;
    const classes = createStyles(props);
    return (
        <AvatarWrapper>
            {editMode ? (
                <AvatarDefault isDefaultAvatar={isDefaultAvatar} image={avatar} />
            ) : (
                <AvatarStyling>
                    <Avatar isDefaultAvatar={isDefaultAvatar} image={avatar} />
                </AvatarStyling>
            )}
            {editMode && (
                <CustomLink to={'/select-avatar'}>
                    <Button classes={{ root: classes.imgEditAvatar }} >
                        <img src={editProfileAvatar} alt="" />
                    </Button>
                </CustomLink>
            )}
        </AvatarWrapper>
    )
};

export default(AvatarProfileBigShared);