import React, { useEffect } from 'react';
import { CircularProgress, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';
import styled from 'styled-components';

const styles = createStyles({
    spinner: {
        margin: '0 auto',
        color: colors.redMain,
        height: '50px !important',
        width: '50px !important',
    },
});

const TransparentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    background: rgba(230, 228, 228, 0.72);
    z-index: 2;
`;

type PropsTypes = WithStyles<typeof styles>;

const LoadingTransparent: React.FC<PropsTypes> = props => {
    const { classes } = props;
    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, []);
    useEffect(() => {
        return () => {
            document.body.style.overflow = 'scroll';
        };
    }, []);
    return (
        <TransparentWrapper>
            <CircularProgress classes={{ root: classes.spinner }} />
        </TransparentWrapper>
    );
};

export default withStyles(styles)(LoadingTransparent);
