import React, { useMemo } from 'react';
import NewBlock from './new/NewBlock';
import ActiveSubscriptionBlock from './active/ActiveSubscriptionBlock';
import { InvoiceModel } from '../../../models/responses/invoice.model';
import { InvoiceStatus } from '../../../constants/invoice/invoiceStatus';
import { UserModel } from '../../../models/responses/user.response';

const RecurringSubscriptionOnePatient: React.FC<{
    invoices: InvoiceModel[];
    patientId?: number | null;
    user: UserModel | null;
}> = props => {
    const { invoices, patientId, user } = props;

    const requestedInvoices = useMemo(() => {
        return invoices.filter(invoice => invoice.status === InvoiceStatus.REQUESTED);
    }, [invoices]);

    const activeInvoices = useMemo(() => {
        return invoices.filter(
            invoice => invoice.status === InvoiceStatus.ACTIVE || invoice.status === InvoiceStatus.REQUIRES_ACTION
        );
    }, [invoices]);

    return (
        <>
            {invoices.length > 0 ? (
                <>
                    {requestedInvoices.length > 0 && (
                        <NewBlock user={user} patientId={patientId} invoices={requestedInvoices} />
                    )}
                    {activeInvoices.length > 0 && (
                        <ActiveSubscriptionBlock user={user} patientId={patientId} invoices={activeInvoices} />
                    )}
                </>
            ) : (
                <NewBlock invoices={invoices} user={user} />
            )}
        </>
    );
};

export default RecurringSubscriptionOnePatient;
