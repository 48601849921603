import React, { useCallback, useMemo, useState } from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    ListItem,
    makeStyles,
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import expandDropdownGrayUp from '../../../../static/icons/expandDropdownGrayUp.svg';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import UserAvatar from '../UserAvatar';
import SecondaryButton from '../../../../shared/button/SecondaryButton';
import ConfirmationDialog from '../ConfirmationDialog';
import { UserModel } from '../../../../models/responses/user.response';
import { RoleOptions } from '../../../../constants/roleOptions';
import { connect } from 'react-redux';
import * as fromRoot from '../../../../store/reducers';
import { convertCentsToDollars } from '../../../../helpers/convertMoney';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as InvoiceActions } from '../../../../store/actions/invoice.actions';
import { templateDisplayingDollar } from '../../../../shared/utils/templateDisplayingDollar';
import { InvoiceStatus } from '../../../../constants/invoice/invoiceStatus';
import { RouteComponentProps, withRouter } from 'react-router';
import { InvoiceType } from '../../../../constants/invoice/invoiceType';
import {formatDate} from "../../../../helpers/dateFormatHelper";
import ApproveButton from '../pay-buttons/ApproveButton';

const createStyles = makeStyles({
    listItem: (props: { patientId?: number | null; user: UserModel | null }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
        height: props.patientId ? '40px' : '100px',
    }),
    price: {
        fontSize: '16px',
        color: colors.textPrimary,
    },
    name: {
        fontSize: '14px',
        color: colors.textPrimary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text: {
        fontSize: '14px',
        lineHeight: '16px',
        color: colors.textSecondary,
    },
    panel: (props: { isViewPage?: boolean; user: UserModel | null }) => ({
        boxShadow: 'none',
        backgroundColor: colors.white,
        margin: 0,
        borderBottom: props.isViewPage ? 'none' : `1px solid ${colors.accordionGray}`,
    }),
    info: {
        fontSize: '14px',
        marginRight: '6px',
    },
    panelDetails: {
        paddingTop: 0,
        flexDirection: 'column',
    },
    payDate: {
        color: colors.textSecondary,
        fontSize: '14px',
        marginRight: '14px',
        textAlign: 'right',
    },
    panelSummary: (props: { user: UserModel | null }) => ({
        alignItems: props.user && props.user.role === RoleOptions.PROVIDER ? 'flex-start' : 'center',
    }),
    expandIconProvider: {
        marginTop: '16px',
        position: 'absolute',
        right: '24px',
    },
    expandIconPatient: {},
});

interface InjectedProps {
    invoice: InvoiceModel;
    patientId?: number | null;
    index: number;
    isViewPage?: boolean;
}

interface StoreModel {
    user: UserModel | null;
}

interface DispatchModel {
    unsubscribeInvoice: typeof InvoiceActions.unsubscribeInvoice;
}

type PropsTypes = InjectedProps & StoreModel & DispatchModel & RouteComponentProps<any>;

const TextBlock = styled.div`
    margin-bottom: 4px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
`;

const Patient = styled.div``;

const DetailsCroppedString = styled.div`
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DetailsString = styled.div`
    margin-bottom: 12px;
`;

const ActiveSubscriptionListItem: React.FC<PropsTypes> = props => {
    const { invoice, patientId, user, unsubscribeInvoice, isViewPage, history } = props;
    const classes = createStyles(props);

    const [openStopDialog, setOpenStopDialog] = useState(false);
    const [expanded, setExpanded] = React.useState<string | false>(isViewPage ? 'panel' : false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const isExpanded = useMemo(() => {
        return isViewPage ? isViewPage : expanded === 'panel';
    }, [expanded, isViewPage]);

    const stop = useCallback((evt: any) => {
        setOpenStopDialog(true);
        evt.stopPropagation();
    }, []);


    const closeDialog = useCallback(() => {
        setOpenStopDialog(false);
    }, []);

    const cancelPatientSubscription = useCallback(() => {
        unsubscribeInvoice(invoice.id);
        closeDialog();
        isViewPage &&
            history.push(
                invoice.type === InvoiceType.ONE_TIME ? '/payments/one-time' : '/payments/subscriptions'
            );
    }, [invoice, closeDialog, history, isViewPage, unsubscribeInvoice]);

    const isProvider = useMemo(() => {
        return user && user.role === RoleOptions.PROVIDER;
    }, [user]);

    return (
        <>
            <ExpansionPanel classes={{ root: classes.panel }} expanded={isExpanded} onChange={handleChange('panel')}>
                <ExpansionPanelSummary
                    expandIcon={!isViewPage && <img src={expandDropdownGrayUp} alt="" />}
                    classes={{
                        root: classes.panelSummary,
                        expandIcon:
                            user && user.role === RoleOptions.PROVIDER
                                ? classes.expandIconProvider
                                : classes.expandIconPatient,
                    }}
                >
                    <ListItem
                        classes={{ root: classes.listItem }}
                        style={{ height: isProvider ? (!patientId ? '120px' : '100px') : '100px' }}
                    >
                        <ContentWrapper>
                            {!patientId && isProvider && (
                                <Patient>
                                    <UserAvatar user={invoice.patient} />
                                </Patient>
                            )}
                            <Info>
                                <Typography variant={'button'} classes={{ root: classes.price }}>
                                    {templateDisplayingDollar(convertCentsToDollars(invoice.amount))}
                                </Typography>

                                {!isProvider && (
                                    <>
                                        {invoice.status === InvoiceStatus.REQUIRES_ACTION ? (
                                            <ApproveButton invoice={invoice}/>
                                        ) : (
                                            <SecondaryButton size={'small'} onClick={stop}>
                                                Cancel
                                            </SecondaryButton>
                                        )}
                                    </>
                                )}
                            </Info>
                            {!isExpanded && (
                                <DetailsCroppedString>
                                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                                        <Typography variant={'button'} classes={{ root: classes.info }}>
                                            Details:
                                        </Typography>
                                        {invoice.description}
                                    </Typography>
                                </DetailsCroppedString>
                            )}
                        </ContentWrapper>
                    </ListItem>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                    {!isProvider && <UserAvatar user={invoice.provider} />}
                    <TextBlock>
                        {isExpanded && (
                            <DetailsString>
                                <Typography variant={'body1'} classes={{ root: classes.text }}>
                                    <Typography variant={'button'} classes={{ root: classes.info }}>
                                        Details:
                                    </Typography>
                                    {invoice.description}
                                </Typography>
                            </DetailsString>
                        )}
                    </TextBlock>
                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                        <Typography variant={'button'} classes={{ root: classes.info }}>
                            Created date:
                        </Typography>
                        {formatDate(invoice.createdDate)}
                    </Typography>
                    {invoice.lastPaymentDate && (
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            <Typography variant={'button'} classes={{ root: classes.info }}>
                                Last payment date:
                            </Typography>
                            {formatDate(invoice.lastPaymentDate!)}
                        </Typography>
                    )}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {openStopDialog && (
                <ConfirmationDialog
                    open={openStopDialog}
                    close={() => closeDialog()}
                    text={'Are you sure you want to unsubscribe?'}
                    yesAction={cancelPatientSubscription}
                />
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        unsubscribeInvoice: bindActionCreators(InvoiceActions.unsubscribeInvoice, dispatch),
    })
)(withRouter(ActiveSubscriptionListItem));
