import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InvoiceModel } from '../../../models/responses/invoice.model';
import { connect } from 'react-redux';
import { Actions as InvoiceActions } from '../../../store/actions/invoice.actions';
import { bindActionCreators, Dispatch } from 'redux';
import * as fromRoot from '../../../store/reducers/index';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import styled from 'styled-components';
import Loading from '../../shared/Loading';
import ToBePaidBlock from './new/ToBePaidBlock';
import RequestState from '../../../constants/requestState';
import { DefaultPageSize, hasMoreData, shouldFetchData } from '../../../constants/dataFetching/allEntitiesByPages';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

interface InjectedProps {
    canFetchPaymentData: boolean;
}

interface StoreModel {
    recurringSubscriptionAllPatientsActive: InvoiceModel[];
    pageNumber: number;
    totalInvoice: number | null;
    loadingRecurringSubscription: boolean;
    deleteRequest: RequestState;
    totalAmountInvoiceOneTimeRequested: number;
}

interface DispatchModel {
    getAllRecurringSubscription: typeof InvoiceActions.getAllOneTimeRequestedProviderAllPatients;
    increasePageNumber: typeof InvoiceActions.increasePageNumberOneTimeRequestedProviderAllPatients;
}

type PropsTypes = StoreModel & DispatchModel & InjectedProps;

const OneTimeRequestedAllPatients: React.FC<PropsTypes> = props => {
    const {
        recurringSubscriptionAllPatientsActive,
        pageNumber,
        totalInvoice,
        loadingRecurringSubscription,
        getAllRecurringSubscription,
        increasePageNumber,
        canFetchPaymentData,
        deleteRequest,
        totalAmountInvoiceOneTimeRequested,
    } = props;

    const [initialLoaded, setInitialLoaded] = useState(false);

    const loadData = useCallback((page, overrideRecords: boolean) => {
        getAllRecurringSubscription({
            size: DefaultPageSize,
            overrideRecords,
            page,
        });
    }, [getAllRecurringSubscription]);

    useEffect(() => {
        if (canFetchPaymentData) {
            loadData(0, true);
            setInitialLoaded(true);
        }
    }, [deleteRequest, canFetchPaymentData, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(
                loadingRecurringSubscription,
                pageNumber,
                recurringSubscriptionAllPatientsActive.length,
                initialLoaded,
                DefaultPageSize
            )
        ) {
            loadData(pageNumber, false);
        }
    }, [loadingRecurringSubscription, pageNumber, recurringSubscriptionAllPatientsActive, initialLoaded, loadData]);

    const loadMore = useCallback(() => {
        increasePageNumber();
    }, [increasePageNumber]);

    const hasMore = useMemo(() => {
        return hasMoreData(totalInvoice, recurringSubscriptionAllPatientsActive.length);
    }, [totalInvoice, recurringSubscriptionAllPatientsActive]);

    return (
        <>
            <ToBePaidBlock
                invoices={recurringSubscriptionAllPatientsActive}
                totalAmountInvoiceOneTimeRequested={totalAmountInvoiceOneTimeRequested}
            />

            {hasMore && !loadingRecurringSubscription && (
                <ButtonWrapper>
                    <PrimaryButton onClick={loadMore}>Load More</PrimaryButton>
                </ButtonWrapper>
            )}

            {loadingRecurringSubscription && <Loading smallSize />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        recurringSubscriptionAllPatientsActive: state.invoice.invoiceOneTimeRequested,
        pageNumber: state.invoice.pageNumberInvoiceOneTimeRequested,
        totalInvoice: state.invoice.totalInvoiceOneTimeRequested,
        loadingRecurringSubscription: state.invoice.loadingOneTimeRequested,
        deleteRequest: state.invoice.deleteRequest,
        totalAmountInvoiceOneTimeRequested: state.invoice.totalAmountInvoiceOneTimeRequested,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllRecurringSubscription: bindActionCreators(
            InvoiceActions.getAllOneTimeRequestedProviderAllPatients,
            dispatch
        ),
        increasePageNumber: bindActionCreators(
            InvoiceActions.increasePageNumberOneTimeRequestedProviderAllPatients,
            dispatch
        ),
    })
)(OneTimeRequestedAllPatients);
