import {ActionsUnion, createAction} from "@martin_hotell/rex-tils";

export enum ActionNames {
    TOGGLE_SIDEBAR = '[Sidebar] Toggle Sidebar',
}

export const Actions = {
    toggleSidebar: () => createAction(ActionNames.TOGGLE_SIDEBAR),
};

export type Actions = ActionsUnion<typeof Actions>;