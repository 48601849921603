import React, { useEffect, useRef, useState } from 'react';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import { List, makeStyles } from '@material-ui/core';
import { colors } from '../../../../theme/colors';
import InfiniteScroll from 'react-infinite-scroll-component';
import HistoryListItem from './HistoryListItem';
import Loading from '../../../shared/Loading';

interface InjectedProps {
    loading: boolean;
    allEntities: InvoiceModel[];
    totalEntities: number | null;
    page: number;
    increasePageNumber: () => void;
    patientId?: number | null;
}

type PropsTypes = InjectedProps;

const createStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '16px',
        color: colors.textSecondary,
        marginTop: '20px',
    },
});

const HistoryInfiniteScrollList: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const { allEntities, totalEntities, patientId } = props;
    const listRef = useRef(null);
    const [height, setHeight] = useState(0);

    const getMoreLabs = () => {
        props.increasePageNumber();
    };

    //TODO: find a better way to calc the height
    useEffect(() => {
        if (listRef && listRef.current) {
            const calcHeight = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;

            let entityBasedHeight = 0;

            if (totalEntities && totalEntities > 10) {
                entityBasedHeight = 400;
            } else if (totalEntities) {
                entityBasedHeight = totalEntities * 82 + 24;
            }

            setHeight(calcHeight > entityBasedHeight ? calcHeight : entityBasedHeight);
        }
    }, [listRef, totalEntities]);

    return (
        <List classes={{ root: classes.list }} ref={listRef}>
            {!!allEntities.length && !!height && (
                <InfiniteScroll
                    dataLength={allEntities.length}
                    next={getMoreLabs}
                    // height={height}
                    hasMore={totalEntities ? allEntities.length < totalEntities : false}
                    loader={<Loading smallSize />}
                    className={classes.scrollContainer}
                >
                    {props.allEntities.map((invoice, index) => (
                        <HistoryListItem key={index} index={index} invoice={invoice} patientId={patientId} />
                    ))}
                </InfiniteScroll>
            )}
        </List>
    );
};

export default HistoryInfiniteScrollList;
