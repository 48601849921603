import React, { useCallback } from 'react';
import upmedHeaderLogo from '../../static/images/upmedHeaderLogo.svg';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { Button, createStyles, Divider, Typography, withStyles, WithStyles } from '@material-ui/core';
import phone from '../../static/icons/phoneRed.svg';
import SizeControllerWrapper from '../SizeControllerWrapper';
import MenuButton from '../button/MenuButton';
import CustomLink from '../link/CustomLink';
import { mediaQuery } from '../../constants/device';
import SecondaryButton from '../button/SecondaryButton';
import MenuNavDesktop from '../nav/MenuNavDesktop';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as SidebarActions } from '../../store/actions/sidebar.actions';
import * as fromRoot from '../../store/reducers';
import { MenuNavItems } from '../../constants/menuNavItems';

const styles = createStyles({
    phoneNumber: {
        fontSize: '12px',
        marginRight: '10px',
    },
    phoneIcon: {
        width: '19px',
        height: '19px',
        marginBottom: '5px',
    },
    dividerRed: {
        height: '10px',
        backgroundColor: colors.redMain,
    },
    phoneButton: {
        width: '60px',
        height: '60px',
        minWidth: '60px',
        borderRadius: '30px',
    },
    divider: {
        height: '33px',
        width: '2px',
        backgroundColor: colors.gray1,
        margin: '0 30px',
    },
    link: {
        fontSize: '12px',
        marginLeft: '10px',
        color: colors.redMain,
        '&:hover': {
            color: colors.redDark,
        },
    },
});

const ContentWrapper = styled.div`
    position: relative;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.white};
`;

const Wrapper = styled.div`
    width: calc(100% - 39px);
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MenuMobileButtonWrapper = styled.div`
    margin-left: 35px;
    position: absolute;
    right: 0;

    ${mediaQuery.laptop} {
        display: none;
    }
`;

const RightSide = styled.div`
    display: flex;
    align-items: center;
`;

const BackgroundWrapper = styled.div`
    background-color: ${colors.white};
`;

const DesktopSide = styled.div`
    display: none;
    ${mediaQuery.laptop} {
        display: flex;
        align-items: center;
    }
`;

const MenuNavWrapper = styled.div`
    display: none;
    align-items: center;
    position: absolute;
    right: 0;
    width: 60%;
    z-index: 2;
    ${mediaQuery.laptop} {
        display: flex;
    }
    ${mediaQuery.laptopL} {
        width: 50%;
    }
`;

const HeaderWrapper = styled.div`
    position: relative;
`;

interface StoreModel {
    openSidebar: boolean;
}

interface DispatchModel {
    toggleSidebar: typeof SidebarActions.toggleSidebar;
}

type PropsTypes = WithStyles<typeof styles> & StoreModel & DispatchModel;

const HeaderUnAuth: React.FC<PropsTypes> = props => {
    const { classes } = props;

    const { openSidebar, toggleSidebar } = props;

    const toggle = useCallback(() => {
        toggleSidebar();
    }, [toggleSidebar]);

    const closeSidebar = useCallback(() => {
        if (openSidebar) {
            toggleSidebar();
        }
    }, [openSidebar, toggleSidebar]);

    return (
        <HeaderWrapper>
            <BackgroundWrapper>
                <SizeControllerWrapper>
                    <ContentWrapper>
                        <Wrapper>
                            <CustomLink to={'/'}>
                                <img src={upmedHeaderLogo} alt="" onClick={closeSidebar} />
                            </CustomLink>
                            <RightSide>
                                <DesktopSide>
                                    <Button classes={{ root: classes.phoneButton }} disabled>
                                        <img src={phone} alt="" className={classes.phoneIcon} />
                                    </Button>
                                    <Typography variant={'button'} classes={{ root: classes.phoneNumber }}>
                                        +1-202-555-0111
                                    </Typography>
                                    <Divider classes={{ root: classes.divider }} />
                                    <CustomLink to={'/sign-in'}>
                                        <SecondaryButton size={'small'} style={{ minWidth: '120px', fontSize: '12px' }}>
                                            Sign In
                                        </SecondaryButton>
                                    </CustomLink>
                                    <CustomLink to={'/sign-up'}>
                                        <Typography variant={'button'} classes={{ root: classes.link }}>
                                            Sign Up
                                        </Typography>
                                    </CustomLink>
                                </DesktopSide>
                            </RightSide>
                        </Wrapper>
                        <MenuMobileButtonWrapper>
                            <MenuButton open={openSidebar} onClickMenu={toggle} />
                        </MenuMobileButtonWrapper>
                    </ContentWrapper>
                </SizeControllerWrapper>
            </BackgroundWrapper>
            <Divider classes={{ root: classes.dividerRed }} />

            <MenuNavWrapper>
                <MenuNavDesktop items={MenuNavItems} />
            </MenuNavWrapper>
        </HeaderWrapper>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        openSidebar: store.sidebar.open,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        toggleSidebar: bindActionCreators(SidebarActions.toggleSidebar, dispatch),
    })
)(withStyles(styles)(HeaderUnAuth));
