import React, { useCallback, useMemo } from 'react';
import { Button, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import forwardIcon from '../../../../static/icons/forwardIcon.svg';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import { WeightRecordResponse } from '../../../../models/responses/weightRecord.response';
import { formatDate } from '../../../../helpers/dateFormatHelper';
import CustomLink from '../../../../shared/link/CustomLink';
import SizeControllerWrapper from '../../../../shared/SizeControllerWrapper';
import { WeightManagementRoutes } from '../../enums/routes';
import weighManagementIcon from '../../../../static/icons/weight.svg';
import { MeasurementSystem, UserModel } from '../../../../models/responses/user.response';
import { UnitWeightManagement } from '../../../../constants/weightManagement/unit.constants';
import { KgToLbs } from '../../../../shared/utils/converter/weight-converter';
import { roundToTwoPoints } from '../../../../shared/utils/converter/round';

const useStyles = makeStyles({
    listItem: {
        padding: 0,
        margin: '8px 0',
    },
    nameText: {
        fontSize: '14px',
        color: colors.textPrimary,
    },
    typeText: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'capitalize',
    },
    avatar: {
        border: `1px solid ${colors.gray3}`,
        backgroundColor: colors.gray3,
        width: '38px',
        height: '38px',
    },
    avatarWrapper: {
        minWidth: 'auto',
        marginRight: '10px',
        border: `1px solid ${colors.gray1}`,
        borderRadius: '30px',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    forwardIcon: {
        padding: '0 !important',
        minWidth: '25px',
        height: '25px',
    },
    myProviderText: {
        fontSize: '14px',
        color: colors.gray1,
        marginLeft: '4px',
    },
});

const TextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: -6px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface InjectedProps {
    weightRecord: WeightRecordResponse;
    user: UserModel;
}

type PropsTypes = InjectedProps;

const WeightListItem: React.FC<PropsTypes> = ({ user, weightRecord }) => {
    const classes = useStyles();

    const ListItemTextPrimary = useCallback(
        () => (
            <TextWrapper>
                <Typography variant="button" classes={{ root: classes.nameText }}>
                    {formatDate(weightRecord.date) + ' Report '}
                </Typography>
            </TextWrapper>
        ),
        [weightRecord, classes.nameText]
    );

    const weightUnit = useMemo(() => {
        return user.measurementSystem === MeasurementSystem.IMPERIAL
            ? UnitWeightManagement.LBS
            : UnitWeightManagement.KG;
    }, [user]);

    const weightValue = useMemo(() => {
        return user.measurementSystem === MeasurementSystem.IMPERIAL
            ? roundToTwoPoints(KgToLbs(weightRecord.weight))
            : roundToTwoPoints(weightRecord.weight);
    }, [user, weightRecord]);

    const ListItemTextSecondary = useCallback(
        () => (
            <Typography variant="body1" classes={{ root: classes.typeText }}>
                {roundToTwoPoints(weightValue)} {weightUnit}
            </Typography>
        ),
        [classes, weightUnit, weightValue]
    );

    return (
        <CustomLink to={`${WeightManagementRoutes.BASE_ROUTE}/${weightRecord.id}`}>
            <ListItem classes={{ root: classes.listItem }} button>
                <SizeControllerWrapper>
                    <Wrapper>
                        <ListItemAvatar classes={{ root: classes.avatarWrapper }}>
                            <img width={25} src={weighManagementIcon} alt="" />
                        </ListItemAvatar>
                        <ListItemText primary={ListItemTextPrimary()} secondary={ListItemTextSecondary()} />

                        <Button classes={{ root: classes.forwardIcon }}>
                            <img src={forwardIcon} alt="" />
                        </Button>
                    </Wrapper>
                </SizeControllerWrapper>
            </ListItem>
        </CustomLink>
    );
};

export default WeightListItem;
