import React, { useCallback, useMemo, useState } from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    ListItem,
    makeStyles,
    Typography,
} from '@material-ui/core';
import SecondaryButton from '../../../../shared/button/SecondaryButton';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import expandDropdownGrayUp from '../../../../static/icons/expandDropdownGrayUp.svg';
import { mediaQuery } from '../../../../constants/device';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import UserAvatar from '../UserAvatar';
import ConfirmationDialog from '../ConfirmationDialog';
import { UserModel } from '../../../../models/responses/user.response';
import { RoleOptions } from '../../../../constants/roleOptions';
import { connect } from 'react-redux';
import * as fromRoot from '../../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import * as InvoiceActions from '../../../../store/actions/invoice.actions';
import { RouteComponentProps, withRouter } from 'react-router';
import RemoveButton from '../RemoveButton';
import { convertCentsToDollars } from '../../../../helpers/convertMoney';
import { InvoiceType } from '../../../../constants/invoice/invoiceType';
import { templateDisplayingDollar } from '../../../../shared/utils/templateDisplayingDollar';
import { formatDate } from '../../../../helpers/dateFormatHelper';
import PayDialog from '../../client-management/PayDialog';
import PrimaryButton from '../../../../shared/button/PrimaryButton';
import { InvoiceStatus } from '../../../../constants/invoice/invoiceStatus';
import ApproveButton from '../pay-buttons/ApproveButton';

const createStyles = makeStyles({
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
    },
    price: {
        fontSize: '18px',
        color: colors.redMain,
    },
    name: {
        fontSize: '13px',
        color: colors.textPrimary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text: {
        fontSize: '14px',
        lineHeight: '16px',
        color: colors.textSecondary,
    },
    panel: (props: { isViewPage?: boolean }) => ({
        boxShadow: 'none',
        backgroundColor: props.isViewPage ? colors.white : colors.gray2,
        margin: 0,
        borderBottom: props.isViewPage ? 'none' : `1px solid ${colors.accordionGray}`,
    }),
    info: {
        fontSize: '14px',
        marginRight: '6px',
    },
    panelDetails: {
        paddingTop: 0,
        flexDirection: 'column',
    },
    status: {
        color: colors.textSecondary,
        marginRight: '14px',
        width: '150px',
        textAlign: 'right',
    },
    panelSummary: (props: { user: UserModel | null; isViewPage?: boolean }) => ({
        alignItems: props.user && props.user.role === RoleOptions.PROVIDER ? 'flex-start' : 'center',
    }),
    expandIconProvider: {
        marginTop: '16px',
        position: 'absolute',
        right: '24px',
    },
    expandIconPatient: {},
});

interface InjectedProps {
    invoice: InvoiceModel;
    index: number;
    patientId?: number | null;
    isViewPage?: boolean;
}

interface StoreModel {
    user: UserModel | null;
}

interface DispatchModel {
    deleteInvoice: typeof InvoiceActions.Actions.deleteInvoice;
    rejectInvoice: typeof InvoiceActions.Actions.rejectInvoice;
}

type PropsTypes = InjectedProps & StoreModel & DispatchModel & RouteComponentProps<any>;

const DateWrapper = styled.div`
    ${mediaQuery.lessMobileS} {
        display: none;
    }
`;

const ActionWrapper = styled.div``;

const TextBlock = styled.div`
    margin-bottom: 8px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Patient = styled.div``;

const ProviderActions = styled.div`
    display: flex;
    align-items: center;
`;

const DetailsCroppedString = styled.div`
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 12px;
`;

const ApproveWrapper = styled.div`
    margin-right: 8px;
`;

const BtnGroupWrapper = styled.div`
    display: flex; 
`;

const NewPaymentListItem: React.FC<PropsTypes> = props => {
    const { invoice, patientId, user, deleteInvoice, rejectInvoice, isViewPage, history } = props;
    const classes = createStyles(props);

    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [expanded, setExpanded] = useState<string | false>(isViewPage ? 'panel' : false);

    const [payDialog, setPayDialog] = useState(false);

    const togglePayDialog = useCallback(
        (evt?: any) => {
            evt && evt.stopPropagation();
            setPayDialog(!payDialog);
        },
        [payDialog]
    );

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const isExpanded = useMemo(() => {
        return isViewPage ? isViewPage : expanded === 'panel';
    }, [expanded, isViewPage]);

    const closeDialogs = useCallback(() => {
        setOpenRejectDialog(false);
        setOpenEditDialog(false);
        setOpenRemoveDialog(false);
    }, []);

    const shouldShowDate = useMemo(() => {
        return convertCentsToDollars(invoice.amount).toString().length < 6;
    }, [invoice.amount]);

    const edit = useCallback((evt: any) => {
        setOpenEditDialog(true);
        evt.stopPropagation();
    }, []);

    const remove = useCallback((evt: any) => {
        setOpenRemoveDialog(true);
        evt.stopPropagation();
    }, []);

    const reject = useCallback((evt: any) => {
        setOpenRejectDialog(true);
        evt.stopPropagation();
    }, []);

    const deleteInvoicePatient = useCallback(() => {
        deleteInvoice({ id: invoice.id, patientId: invoice.patient.id });
        closeDialogs();
    }, [invoice.id, invoice.patient.id, closeDialogs, deleteInvoice]);

    const rejectActiveInvoice = useCallback(() => {
        rejectInvoice(invoice.id);
        closeDialogs();
        if(isViewPage) {
            history.push(invoice.type === InvoiceType.ONE_TIME ? '/payments/one-time' : '/payments/subscriptions');
        }
    }, [invoice, closeDialogs, isViewPage, rejectInvoice, history]);

    const goToEditPage = useCallback(() => {
        history.push(`/add-payment/${invoice.id}/${invoice.patient.id}`);
    }, [invoice, history]);

    const isProvider = useMemo(() => {
        return user && user.role === RoleOptions.PROVIDER;
    }, [user]);

    const redirectToFail = useCallback(() => {
        history.push('/payment-result?status=fail');
    }, [history]);

    const redirectToSuccess = useCallback(() => {
        history.push('/payment-result?status=success');
    }, [history]);

    return (
        <>
            <ExpansionPanel classes={{ root: classes.panel }} expanded={isExpanded} onChange={handleChange('panel')}>
                <ExpansionPanelSummary
                    expandIcon={!isViewPage && <img src={expandDropdownGrayUp} alt="" />}
                    classes={{
                        root: classes.panelSummary,
                        expandIcon:
                            user && user.role === RoleOptions.PROVIDER
                                ? classes.expandIconProvider
                                : classes.expandIconPatient,
                    }}
                >
                    <ListItem
                        classes={{ root: classes.listItem }}
                        style={{ height: isProvider ? (!patientId ? '140px' : '100px') : '100px' }}
                    >
                        <ContentWrapper>
                            {!patientId && isProvider && (
                                <Patient>
                                    <UserAvatar user={invoice.patient} />
                                </Patient>
                            )}
                            <Info>
                                {shouldShowDate && (
                                    <DateWrapper>
                                        <Typography variant={'body2'} classes={{ root: classes.name }}>
                                            {formatDate(invoice.createdDate)}
                                        </Typography>
                                    </DateWrapper>
                                )}
                                <Typography variant={'button'} classes={{ root: classes.price }}>
                                    {templateDisplayingDollar(convertCentsToDollars(invoice.amount))}
                                </Typography>

                                <ActionWrapper>
                                    {isProvider ? (
                                        <ProviderActions>
                                            <SecondaryButton
                                                size={'small'}
                                                style={{ marginRight: '8px' }}
                                                onClick={edit}
                                            >
                                                Edit
                                            </SecondaryButton>
                                            <div onClick={remove}>
                                                <RemoveButton />
                                            </div>
                                        </ProviderActions>
                                    ) : (
                                        <BtnGroupWrapper>
                                            {invoice.status === InvoiceStatus.REQUIRES_ACTION ? (
                                                <ApproveWrapper>
                                                    <ApproveButton invoice={invoice} />
                                                </ApproveWrapper>
                                            ) : (
                                                <PrimaryButton
                                                    size={'small'}
                                                    onClick={togglePayDialog}
                                                    style={{ marginRight: '8px' }}
                                                >
                                                    Pay
                                                </PrimaryButton>
                                            )}
                                            <SecondaryButton size={'small'} onClick={reject}>
                                                {' '}
                                                Reject
                                            </SecondaryButton>
                                        </BtnGroupWrapper>
                                    )}
                                </ActionWrapper>
                            </Info>
                            {!isExpanded && (
                                <DetailsCroppedString>
                                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                                        <Typography variant={'button'} classes={{ root: classes.info }}>
                                            Details:
                                        </Typography>
                                        {invoice.description}
                                    </Typography>
                                </DetailsCroppedString>
                            )}
                        </ContentWrapper>
                    </ListItem>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                    {!isProvider && <UserAvatar user={invoice.provider} />}
                    {isExpanded && (
                        <TextBlock>
                            <Typography variant={'body1'} classes={{ root: classes.text }}>
                                <Typography variant={'button'} classes={{ root: classes.info }}>
                                    Details:
                                </Typography>
                                {invoice.description}
                            </Typography>
                        </TextBlock>
                    )}
                    {!shouldShowDate && (
                        <TextBlock>
                            <Typography variant={'body1'} classes={{ root: classes.text }}>
                                <Typography variant={'button'} classes={{ root: classes.info }}>
                                    Created date:
                                </Typography>
                                {formatDate(invoice.createdDate)}
                            </Typography>
                        </TextBlock>
                    )}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {openRejectDialog && (
                <ConfirmationDialog
                    open={openRejectDialog}
                    close={() => closeDialogs()}
                    text={'Are you sure you want to reject the invoice?'}
                    yesAction={rejectActiveInvoice}
                />
            )}

            {openEditDialog && (
                <ConfirmationDialog
                    open={openEditDialog}
                    close={() => closeDialogs()}
                    text={'Are you sure you want to edit the invoice?'}
                    yesAction={goToEditPage}
                />
            )}

            {openRemoveDialog && (
                <ConfirmationDialog
                    open={openRemoveDialog}
                    close={() => closeDialogs()}
                    text={'Are you sure you want to remove the invoice?'}
                    yesAction={deleteInvoicePatient}
                />
            )}

            {payDialog && (
                <PayDialog
                    open={payDialog}
                    onClose={togglePayDialog}
                    invoice={invoice}
                    redirectToFail={redirectToFail}
                    redirectToSuccess={redirectToSuccess}
                />
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        deleteInvoice: bindActionCreators(InvoiceActions.Actions.deleteInvoice, dispatch),
        rejectInvoice: bindActionCreators(InvoiceActions.Actions.rejectInvoice, dispatch),
    })
)(withRouter(NewPaymentListItem));
