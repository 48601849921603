import React, { useCallback, useEffect, useMemo } from 'react';
import CardListItem from './CardListItem';
import { makeStyles, Typography } from '@material-ui/core';
import { CardModel } from '../../../../models/responses/card.model';
import RequestState from '../../../../constants/requestState';
import * as cardManagementActions from '../../../../store/actions/cardManagement.actions';
import { connect } from 'react-redux';
import * as fromRoot from '../../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../../../shared/Loading';

const createStyles = makeStyles({
    header: {
        display: 'block',
        textAlign: 'left',
        marginLeft: '32px',
        marginBottom: '8px',
    },
});

interface StoreModel {
    cardsList: CardModel[];
    cardsListLoading: RequestState;
}

interface DispatchModel {
    getAllCards: typeof cardManagementActions.Actions.getAllCards;
}

interface InjectedProps {
    selectedId: string;
    setSelectedId: (id: string) => void;
}

type PropsTypes = StoreModel & DispatchModel & InjectedProps;

const CardsListSelectable: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const { cardsListLoading, cardsList, getAllCards, selectedId, setSelectedId } = props;

    useEffect(() => {
        getAllCards();
    }, [getAllCards]);

    const defaultCard = useMemo(() => {
        const defaultCard = cardsList.filter(card => card.isDefault);
        return !!defaultCard.length ? defaultCard[0] : null;
    }, [cardsList]);

    const otherCards = useMemo(() => {
        return defaultCard ? cardsList.filter(card => card.paymentMethodId !== defaultCard.paymentMethodId) : cardsList;
    }, [defaultCard, cardsList]);

    const handleChange = useCallback(
        (id: string) => {
            setSelectedId(id);
        },
        [setSelectedId]
    );

    const isLoading = useMemo(() => {
        return cardsListLoading === RequestState.SENDING;
    }, [cardsListLoading]);

    return (
        <>
            {isLoading && <Loading smallSize />}
            {!isLoading && !cardsList.length && <Typography>No card available</Typography>}
            {!isLoading && !!cardsList.length && (
                <>
                    {defaultCard && (
                        <Typography variant={'button'} classes={{ root: classes.header }}>
                            Default Card
                        </Typography>
                    )}
                    {defaultCard && (
                        <CardListItem
                            card={defaultCard}
                            handleChange={handleChange}
                            selected={selectedId === defaultCard.paymentMethodId}
                            isRadio
                        />
                    )}
                    {otherCards.map(card => (
                        <CardListItem
                            key={card.paymentMethodId}
                            card={card}
                            handleChange={handleChange}
                            selected={selectedId === card.paymentMethodId}
                            isRadio
                        />
                    ))}
                </>
            )}
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        cardsList: store.cardManagement.cardsList,
        cardsListLoading: store.cardManagement.cardsListLoading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllCards: bindActionCreators(cardManagementActions.Actions.getAllCards, dispatch),
    })
)(CardsListSelectable);
