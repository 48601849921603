import React, {useCallback, useEffect, useMemo, useState} from 'react';
import BlockHeader from '../BlockHeader';
import { connect } from 'react-redux';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import * as invoiceActions from '../../../../store/actions/invoice.actions';
import * as fromRoot from '../../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import NoData from '../NoData';
import HistoryInfiniteScrollList from './HistoryInfiniteScrollList';
import Loading from '../../../shared/Loading';
import { UserModel } from '../../../../models/responses/user.response';
import { RoleOptions } from '../../../../constants/roleOptions';
import RequestState from "../../../../constants/requestState";
import {DefaultPageSize, isNoDataToFetch, shouldFetchData} from "../../../../constants/dataFetching/allEntitiesByPages";

interface StoreModel {
    user: UserModel | null;
    loading: boolean;
    invoiceRecurringArchived: InvoiceModel[];
    pageNumberArchived: number;
    totalInvoiceRecurringArchived: number | null;
    unsubscribeInvoiceRequest: RequestState;
    rejectRequest: RequestState;
}

interface DispatchModel {
    getAllRecurringArchivedPatient: typeof invoiceActions.Actions.getAllRecurringArchivedPatient;
    getAllRecurringArchivedProvider: typeof invoiceActions.Actions.getAllRecurringArchivedProvider;
    getAllRecurringArchivedProviderAllPatients: typeof invoiceActions.Actions.getAllRecurringArchivedProviderAllPatients;
    increasePageNumberRecurringArchived: typeof invoiceActions.Actions.increasePageNumberRecurringArchived;
}

interface InjectedProps {
    patientId?: number | null;
}

type PropsTypes = StoreModel & DispatchModel & InjectedProps;

const InactiveBlock: React.FC<PropsTypes> = props => {
    const {
        loading,
        pageNumberArchived,
        getAllRecurringArchivedPatient,
        getAllRecurringArchivedProvider,
        increasePageNumberRecurringArchived,
        invoiceRecurringArchived,
        totalInvoiceRecurringArchived,
        getAllRecurringArchivedProviderAllPatients,
        user,
        patientId,
        unsubscribeInvoiceRequest,
        rejectRequest,
    } = props;

    const [initialLoaded, setInitialLoaded] = useState(false);

    const loadData = useCallback ((pageNumberArchived: number, overrideRecords: boolean) => {
        if (user!.role === RoleOptions.PROVIDER) {
            if (patientId) {
                getAllRecurringArchivedProvider({
                    data: {
                        page: pageNumberArchived,
                        size: DefaultPageSize,
                        overrideRecords
                    },
                    patientId: patientId,
                });
            } else {
                getAllRecurringArchivedProviderAllPatients({
                    page: pageNumberArchived,
                    size: DefaultPageSize,
                    overrideRecords
                });
            }
        } else {
            getAllRecurringArchivedPatient({
                page: pageNumberArchived,
                size: DefaultPageSize,
                overrideRecords
            });
        }
    },[getAllRecurringArchivedPatient, getAllRecurringArchivedProviderAllPatients, getAllRecurringArchivedProvider, user, patientId]);

    useEffect(() => {
        if(!initialLoaded && (unsubscribeInvoiceRequest === RequestState.UNSENT || rejectRequest === RequestState.UNSENT)) {
            loadData(0, true);
            setInitialLoaded(true);
        }
    }, [patientId, unsubscribeInvoiceRequest, rejectRequest, loadData, initialLoaded]);

    useEffect(() => {
        if (rejectRequest === RequestState.UNSENT) {
            loadData(0, true);
            setInitialLoaded(true);
        }
    }, [rejectRequest, loadData]);

    useEffect(() => {
        if (shouldFetchData (loading, pageNumberArchived,invoiceRecurringArchived.length, initialLoaded)
        ) {
            loadData(pageNumberArchived, false);
        }
    }, [pageNumberArchived, loading, patientId, user, loadData, initialLoaded, invoiceRecurringArchived]);

    const isNoData = useMemo(() => {
        return isNoDataToFetch(totalInvoiceRecurringArchived, loading, initialLoaded);
    }, [loading, totalInvoiceRecurringArchived, initialLoaded]);

    return (
        <>
            <BlockHeader>History</BlockHeader>

            {isNoData && <NoData />}

            {!loading ? (
                <HistoryInfiniteScrollList
                    loading={loading}
                    page={pageNumberArchived}
                    allEntities={invoiceRecurringArchived}
                    increasePageNumber={increasePageNumberRecurringArchived}
                    totalEntities={totalInvoiceRecurringArchived}
                />
            ) : (
                <Loading smallSize />
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
        loading: state.invoice.loadingRecurringArchived,
        invoiceRecurringArchived: state.invoice.invoiceRecurringArchived,
        pageNumberArchived: state.invoice.pageNumberRecurringArchived,
        totalInvoiceRecurringArchived: state.invoice.totalInvoiceRecurringArchived,
        unsubscribeInvoiceRequest: state.invoice.unsubscribeInvoiceRequest,
        rejectRequest: state.invoice.rejectRequest,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllRecurringArchivedPatient: bindActionCreators(
            invoiceActions.Actions.getAllRecurringArchivedPatient,
            dispatch
        ),
        getAllRecurringArchivedProvider: bindActionCreators(
            invoiceActions.Actions.getAllRecurringArchivedProvider,
            dispatch
        ),
        increasePageNumberRecurringArchived: bindActionCreators(
            invoiceActions.Actions.increasePageNumberRecurringArchived,
            dispatch
        ),
        getAllRecurringArchivedProviderAllPatients: bindActionCreators(
            invoiceActions.Actions.getAllRecurringArchivedProviderAllPatients,
            dispatch
        ),
    })
)(InactiveBlock);
