import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HeaderHeadingWithLink from '../../../shared/HeaderHeadingWithLink';
import styled from 'styled-components';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../../constants/layout';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../../constants/device';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import addRowIcon from '../../../static/icons/addRow.svg';
import { connect } from 'react-redux';
import * as RootStateModel from '../../../store/reducers';
import RequestState from '../../../constants/requestState';
import { Actions as ProviderActions } from '../../../store/actions/provider.actions';
import { bindActionCreators, Dispatch } from 'redux';
import LoadingTransparent from '../../shared/LoadingTransparent';
import Snackbar, { SnackbarTypes } from '../../../shared/snackbars/snackbar';
import { withRouter, RouteComponentProps } from 'react-router';
import AddProviderRow from './AddProviderRow';
import fillClassInstanceWithValues from '../../../shared/utils/fillClassInstanceWithValues';
import { AddProviderDto } from '../../../models/dto/addProvider.dto';
import { validateSync } from 'class-validator';
import { ValidationErrorsMap } from '../../../models/validation/validationErrorsMap';
import CancelLink from '../../../shared/link/CancelLink';

const Wrapper = styled.div<{ height: number }>`
    overflow: scroll;
    min-height: ${props => props.height}px;
    margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const tableCols: string[] = ['First Name', 'Last Name', 'Business Name', 'Email', 'Address',  'Phone'];

export interface ProviderRow {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    businessName: string;
    address: string;
    isBeingEdited: Set<string>;
}

const initialStateRow = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    businessName: '',
    address: '',
    isBeingEdited: new Set<string>(),
};

const initialState = [initialStateRow];

const createStyles = makeStyles({
    iconRow: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
    addRow: {
        border: 'none',
    },
});

const localBottomPadding = 156;

interface StateModel {
    saveProvidersRequest: RequestState;
}

interface DispatchModel {
    saveProviders: typeof ProviderActions.saveProviders;
    resetStatusVariables: typeof ProviderActions.resetStatusVariables;
}

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const AddProviders: React.FC<PropsTypes> = props => {
    const [height, setHeight] = useState(0);
    const isLaptop = useMediaQuery(mediaQuery.laptop);
    const listRef = useRef(null);

    const [error, setError] = useState(false);

    const [table, setTable] = useState<ProviderRow[]>(initialState);

    const classes = createStyles(props);

    const { saveProviders, saveProvidersRequest, resetStatusVariables } = props;

    const changeRow = useCallback(
        (filedName: string, value: string | Date | null, id: number) => {
            setError(false);
            const currentRow = table.filter(r => r.id === id)[0];
            const index = table.indexOf(currentRow);
            const newTable = [...table];
            newTable[index] = {
                ...currentRow,
                [filedName]: value,
                isBeingEdited: currentRow.isBeingEdited.add(filedName),
            };
            setTable(newTable);
        },
        [table]
    );

    const removeRow = useCallback(
        (id: number) => {
            const currentRow = table.filter(r => r.id === id)[0];
            const index = table.indexOf(currentRow);
            setTable([...table.slice(0, index), ...table.slice(index + 1)]);
        },
        [table]
    );

    const addRow = useCallback(() => {
        const lastIndex = table[table.length - 1].id;
        const newRow = { ...initialStateRow, id: lastIndex + 1, isBeingEdited: new Set<string>() };
        setTable([...table, newRow]);
    }, [table]);

    const canRemoveRow = useMemo(() => {
        return table.length > 1;
    }, [table]);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height =
                window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top - localBottomPadding;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    const validateForm = (user: any) => {
        const item = fillClassInstanceWithValues<AddProviderDto>(AddProviderDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                map[err.property] = Object.entries(err.constraints)
                    .map(([, value]) => value)
                    .join(', ');
            });
        }
        return map;
    };

    const isFormValid = useCallback((table: ProviderRow[]) => {
        let valid = true;
        const filledProviders = table.filter(
            row =>
                row.firstName.length > 0 ||
                row.lastName.length > 0 ||
                row.email.length > 0 ||
                row.phone.length > 0
        );
        if (filledProviders.length) {
            filledProviders.forEach(row => {
                const map = validateForm(row);
                if (Object.keys(map).length !== 0) {
                    valid = false;
                }
            });
            return valid;
        }
        return false;
    }, []);

    const getFilledProviders = useCallback(() => {
        const filledProviders = table.filter(
            row =>
                row.firstName.length > 0 &&
                row.lastName.length > 0 &&
                row.email.length > 0 &&
                row.phone.length > 0
        );

        return filledProviders.map(row => ({ ...row }));
    }, [table]);

    const save = useCallback(() => {
        if (isFormValid(table)) {
            saveProviders(getFilledProviders());
        } else {
            setError(true);
        }
    }, [table, isFormValid, getFilledProviders, saveProviders]);

    useEffect(() => {
        if (saveProvidersRequest === RequestState.SENT_SUCCESS) {
            props.history.push('/admin/providers');
        }
    }, [saveProvidersRequest, props.history]);

    useEffect(() => {
        setTable([{ ...initialStateRow, isBeingEdited: new Set<string>() }]);
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    return (
        <>
            {saveProvidersRequest === RequestState.SENDING && <LoadingTransparent />}
            <HeaderHeadingWithLink>Add Providers</HeaderHeadingWithLink>
            <Wrapper ref={listRef} height={height}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {tableCols.map((item, index) => (
                                <TableCell key={index}>
                                    <Typography variant={'button'}>{item}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table.map((row, index) => (
                            <AddProviderRow
                                key={index}
                                row={row}
                                changeRow={changeRow}
                                removeRow={removeRow}
                                canRemoveRow={canRemoveRow}
                            />
                        ))}
                        <TableRow>
                            <TableCell className={classes.addRow}>
                                <img src={addRowIcon} alt="" className={classes.iconRow} onClick={addRow} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Wrapper>
            <ButtonWrapper>
                <PrimaryButton onClick={save}>Save Providers</PrimaryButton>
            </ButtonWrapper>

            <CancelLink />

            <Snackbar
                message={'Fill the whole row in the table'}
                open={error}
                variant={'error'}
                type={SnackbarTypes.ERROR}
            />
        </>
    );
};

export default connect(
    (state: RootStateModel.RootStateModel): StateModel => ({
        saveProvidersRequest: state.provider.saveProvidersRequest,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        saveProviders: bindActionCreators(ProviderActions.saveProviders, dispatch),
        resetStatusVariables: bindActionCreators(ProviderActions.resetStatusVariables, dispatch),
    })
)(withRouter(AddProviders));
