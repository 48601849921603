import React from 'react';
import styled from 'styled-components';
import { LineChart, XAxis, YAxis, CartesianGrid, Line } from 'recharts';
import { colors } from '../../../theme/colors';
import { BloodTargetInGraphModel } from '../../../models/responses/bloodTargetInGraph.model';
import { DataRange } from '../../../store/reducers/analytics';
import Legend from './Legend';

const Wrapper = styled.div`
    svg {
        overflow: scroll !important;
    }
`;

const LegendWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 12px;
    // width: ${window.innerWidth - 20}px;
    position: sticky;
    left: 0;
`;

interface InjectedProps {
    bloodTargetsList: BloodTargetInGraphModel;
    date: DataRange;
}

type PropsTypes = InjectedProps;

const BloodTargetsDiagram: React.FC<PropsTypes> = props => {
    const styles = {
        fontFamily: 'Poppins-Semibold',
        height: 'auto',
        marginBottom: '-49px',
        marginLeft: '10px',
    };

    return (
        <Wrapper>
            {props.bloodTargetsList && (
                <div>
                    <LegendWrapper>
                        {props.bloodTargetsList.targets.map((t, index) => (
                            <Legend target={t} key={index} />
                        ))}
                    </LegendWrapper>

                    <LineChart
                        width={745}
                        height={250}
                        data={props.bloodTargetsList.graphData}
                        style={styles}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            type="number"
                            dataKey="date"
                            tick={false}
                            domain={[Number(props.date.startDate), Number(props.date.endDate)]}
                        />
                        <YAxis tick={false} stroke={colors.gray2} />
                        {props.bloodTargetsList.targets.map((t, index) => (
                            <Line key={index} type="monotone" dataKey={t.name} stroke={t.color} />
                        ))}
                    </LineChart>
                </div>
            )}
        </Wrapper>
    );
};

export default BloodTargetsDiagram;
