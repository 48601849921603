import React, { useState } from 'react';
import doctor from '../../../../static/images/landing/patient/doctor.svg';
import styled from 'styled-components';
import TextInput from '../../../../shared/input/TextInput';
import { colors } from '../../../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../../../shared/button/PrimaryButton';
import { mediaQuery } from '../../../../constants/device';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    ${mediaQuery.laptop} {
        flex-direction: row;
        background-color: ${colors.redMain};
        padding: 0 5%;
        height: 480px;
    }
    ${mediaQuery.laptopL} {
        padding: 0 12%;
        height: 480px;
        justify-content: center;
    }
`;

const FormBackground = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.redMain};
    padding: 70px 20px;
    ${mediaQuery.laptop} {
        margin-left: 12%;
    }
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0;
    > div {
        width: 100%;
        margin-bottom: 12px !important;
    }
    ${mediaQuery.tablet} {
        padding: 70px 20%;
    }
    ${mediaQuery.laptop} {
        align-items: flex-start;
        width: 300px;
        padding: 0;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 36px;
`;

const emptyForm = {
    name: '',
    email: '',
    message: '',
};

const FormFields = {
    name: 'name',
    email: 'email',
    message: 'message',
};

const createStyles = makeStyles(theme => ({
    heading: {
        color: colors.white,
        fontSize: '36px',
        lineHeight: '54px',
        textAlign: 'center',
        display: 'block',
    },
    docImg: {
        marginBottom: '-116px',
        [theme.breakpoints.up('lg')]: {
            height: '590px',
            position: 'relative',
            bottom: '109px',
        },
    },
}));

const AskDocForm: React.FC = props => {
    const classes = createStyles(props);

    const [form, setForm] = useState(emptyForm);

    const changeForm = (formField: string, value: string) => {
        setForm({ ...form, [formField]: value });
    };

    return (
        <Wrapper>
            <img src={doctor} className={classes.docImg} alt="" />
            <FormBackground>
                <Typography variant={'button'} classes={{ root: classes.heading }}>
                    Ask my doc
                </Typography>
                <FormWrapper>
                    <TextInput
                        value={form.name}
                        placeholder={'Name'}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeForm(FormFields.name, evt.target.value)
                        }
                        color={colors.white}
                    />
                    <TextInput
                        value={form.email}
                        placeholder={'Email'}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeForm(FormFields.email, evt.target.value)
                        }
                        color={colors.white}
                    />
                    <TextInput
                        value={form.message}
                        placeholder={'Your message'}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            changeForm(FormFields.message, evt.target.value)
                        }
                        color={colors.white}
                    />
                </FormWrapper>
                <ButtonWrapper>
                    <PrimaryButton white>Send message</PrimaryButton>
                </ButtonWrapper>
            </FormBackground>
        </Wrapper>
    );
};

export default AskDocForm;
