import React from 'react';
import { TargetInGraph } from '../../../models/responses/bloodTargetInGraph.model';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const LegendWrapper = styled.div`
    display: flex;
    width: fit-content;
    margin: 4px;
`;
const Color = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background-color: ${props => props.color};
`;

interface InjectedProps {
    target: TargetInGraph;
}

type PropsTypes = InjectedProps;

const Legend: React.FC<PropsTypes> = ({ target }) => {
    return (
        <LegendWrapper>
            <Color color={target.color} />
            <Typography variant={'button'}>{target.name}</Typography>
        </LegendWrapper>
    );
};

export default Legend;
