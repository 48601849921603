import React, {useEffect} from 'react';
import PatientHeaderBlock from "./components/PatientHeaderBlock";
import SideIconWithText from "./components/SideIconWithText";
import compositeReportingIcon from '../../../static/images/landing/patient/compositeReportingIcon.svg';
import comprehensiveInputIcon from '../../../static/images/landing/patient/comprehensiveInputIcon.svg';
import customizedTrackingIcon from '../../../static/images/landing/patient/customizedTrackingIcon.svg';
import styled from "styled-components";
import reportingImgProviderPage from "../../../static/images/landing/provider/reportingImgProviderPage.png";
import {makeStyles, Typography} from "@material-ui/core";
import {colors} from "../../../theme/colors";
import convenienceIcon from "../../../static/images/landing/provider/convenienceIcon.svg";
import RedInfoBlock from "../main/components/RedInfoBlock";
import eliminateIcon from '../../../static/images/landing/patient/eliminateIcon.svg';
import financilIcon from '../../../static/images/landing/patient/financialIcon.svg';
import LandingBigHeading from "../../shared/LandingBigHeading";
import simplifyImg from '../../../static/images/landing/patient/simplifyImg.png';
import TextWithBackgroundBlock, {ImgType} from "../shared/TextWithBackgroundBlock";
import preferencesBackground from '../../../static/images/landing/patient/preferencesBackground.png';
import inputsBackground from '../../../static/images/landing/patient/inputsBackground.png';
import AskDocForm from "./components/AskDocForm";
import Footer from "../../../shared/Footer";
import {mediaQuery} from "../../../constants/device";
import CardWithTopImageGray, {ImgSide} from "../../../shared/card/CardWithTopImageGray";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const SideIconWithTextWrapper = styled.div`
  margin-top: 38px;
  padding: 26px 0;
  > div {
    margin-bottom: 36px;
    ${mediaQuery.laptop}{ 
    justify-content: space-between;
    }
  }
  ${mediaQuery.laptop}{
    display: flex;
    padding: 36px 5%;
  }
  ${mediaQuery.laptopL}{
    display: flex;
    padding: 36px 11%;
  }
`;

const SideIconWithTextWithBackgroundWrapper = styled.div`
  background-color: ${colors.gray2};
  ${mediaQuery.tablet}{
    padding-right: 5%;
    >div:last-child {
      padding-bottom: 65px;
    }
  }
  ${mediaQuery.laptopL}{
    padding-right: 12%;
  }
`;

const ImageWrapper = styled.div`
  background-color: ${colors.gray2};
  padding: 92px 0 60px;
  ${mediaQuery.tablet}{
    padding: 0;
  }
`;

const SimplifyImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  ${mediaQuery.tablet}{
    margin: 0;
    justify-content: flex-start;
  }
`;

const FormWrapper = styled.div`
  margin-top: 60px;
`;

const SimplifyWrapper = styled.div`
  ${mediaQuery.tablet}{
     display: flex;  
  }
`;

const SimplyfyHeaderWrapper = styled.div`
  background-color: ${colors.gray2};
  padding: 153px 0 100px 0;
`;

const TextWithBackgroundBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  ${mediaQuery.laptop}{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    width: 90%;
    margin: 0 auto;
    padding: 75px 0 136px;
  }
`;

const createStyles = makeStyles({
    heading: {
        fontSize: '48px',
        lineHeight: '36px',
        color: colors.textPrimary,
        display: 'block',
        marginBottom: '36px',
        marginTop: '28px',
    },
    simplifyImg: {
        width: '92%',
    }
});

const PatientPage: React.FC = (props) => {

    const classes = createStyles(props);

    useEffect(() => {
        window.scroll({top: 0, left: 0})
    }, []);

    const isTablet = useMediaQuery(mediaQuery.tablet);

    return (
        <>
            <PatientHeaderBlock/>
            <SideIconWithTextWrapper>
                <SideIconWithText
                    icon={customizedTrackingIcon}
                    heading={'Customized Tracking'}
                    text={'Custom inputs allow you to incorporate external supplementation' +
                    ' that you may be incorporating to give you, the patient, a better view of your total health picture.'}
                    buttonText={'Provider'}
                />
                <SideIconWithText
                    icon={comprehensiveInputIcon}
                    heading={'Comprehensive Input'}
                    text={'Include your prescribed lab results as well as any external labwork you’ve ' +
                    'had performed along the way. Our goal is to show you your entire health history in a downloadable snapshot'}
                    buttonText={'Provider'}
                />
                <SideIconWithText
                    icon={compositeReportingIcon}
                    heading={'Composite Reporting'}
                    text={'Include your prescribed lab results as well as any external labwork, along with doctor ' +
                    'designated prescriptions and self - directed supplementation to run reports on the effects of all of your ongoing health maintenance'}
                    buttonText={'Provider'}
                />
            </SideIconWithTextWrapper>
            <CardWithTopImageGray
                text="Set the parameters for your tracking needs while allowing the addition of customized  supplementation
                to your providers prescribed treatment. Gain comprehensive reporting  options allowing for better maintenance
                and ongoing continuity of treatment. See the effects of RX adjustment, and your self - directed supplementation on your
                bloodwork, and have the ability to course correct when necessary."
                img={reportingImgProviderPage}
                imgSide={ImgSide.right}
            >
                <Typography variant={'button'} classes={{root: classes.heading}}>Reporting</Typography>
            </CardWithTopImageGray>
            <RedInfoBlock
                icon={convenienceIcon}
                heading={'Convenience'}
                text={'App based with a full web interface, we make patient communication easy, with' +
                ' notifications for both provider and patient of necessary benchmarks. IE – Bloodwork, ' +
                'RX adjustments, appointments, etc. Keep track and ensure that your messages are heard and your ' +
                'patients are informed. In other words, reduce your liability.'}
            />

            {isTablet &&
            <SimplyfyHeaderWrapper>
                <LandingBigHeading>Simplify</LandingBigHeading>
            </SimplyfyHeaderWrapper>
            }
            <SimplifyWrapper>
                <ImageWrapper>
                    {!isTablet &&
                    <LandingBigHeading>Simplify</LandingBigHeading>
                    }
                    <SimplifyImageWrapper>
                        <img src={simplifyImg} className={classes.simplifyImg} alt=""/>
                    </SimplifyImageWrapper>
                </ImageWrapper>


                <SideIconWithTextWithBackgroundWrapper>
                    <SideIconWithText
                        icon={financilIcon}
                        heading={'Simplify your financial transactions'}
                        text={'Our app based pay system allows you to setup and make ongoing monthly payments, with an easy to use receipt for HSA purposes.'}
                    />
                    <SideIconWithText
                        icon={eliminateIcon}
                        heading={'Eliminate Headaches'}
                        text={'Inventory Management, RX Management, Payment Management and Customizable Reporting Options, all designed to simply your life.'}
                    />
                </SideIconWithTextWithBackgroundWrapper>
            </SimplifyWrapper>

            <TextWithBackgroundBlocksWrapper>
                <TextWithBackgroundBlock
                    heading={'Preferences'}
                    text={'Customize your interface, treatment, supplementation, reporting options and payment options. Make this app exactly what you want it to be.'}
                    buttonText={'Preferences'}
                    background={preferencesBackground}
                    path={'/'}
                    imgType={ImgType.image}
                />
                <TextWithBackgroundBlock
                    heading={'Inputs'}
                    text={'Add in any alternative supplementation you’re currently utilizing in your training regimen. It will remain private for your viewing only, and only accessible via your login.'}
                    buttonText={'Inputs'}
                    background={inputsBackground}
                    path={'/'}
                    imgType={ImgType.icon}
                />
            </TextWithBackgroundBlocksWrapper>

            <FormWrapper>
                <AskDocForm/>
            </FormWrapper>
            <Footer/>
        </>
    )
};

export default PatientPage;