import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/colors';

const Wrapper = styled.div`
    background-color: ${colors.white};
    padding: 25px;
    border-radius: 20px;
    width: 90%;
    margin: 0 auto 40px;
    box-shadow: 0 18px 35px 10px ${colors.grayBorder};
    position: relative;
`;

interface IProps {
    children?: React.ReactNode | React.ReactNode[];
    styles?: React.CSSProperties;
}

export function CardWithBigShadow ({children, styles = {}}: IProps) {
    return (
        <Wrapper style={styles}>
            {children}
        </Wrapper>
    )
}
