export interface IFormWeightRecord {
    topPressure: number;
    bottomPressure: number;
    arm: number;
    bmi: number;
    date: string;
    height: number;
    thigh: number;
    waist: number;
    weight: number;
    age: number;
}

export enum WeightRecordFormFields {
    topPressure = 'topPressure',
    bottomPressure = 'bottomPressure',
    arm = 'arm',
    bmi = 'bmi',
    date = 'date',
    height = 'height',
    thigh = 'thigh',
    waist = 'waist',
    weight = 'weight',
    age = 'age',
}
