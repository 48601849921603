import React from 'react';
import styled from 'styled-components';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../theme/colors';
import SizeControllerWrapper from './SizeControllerWrapper';

const styles = createStyles({
    backButton: {
        minWidth: '30px',
        height: '30px',
        padding: '0 !important',
        position: 'absolute',
        left: '-12px',
    },
    heading: {
        fontSize: '18px',
        color: colors.white,
        textAlign: 'center',
        lineHeight: '20px !important',
    },
});

const BackgroundWrapper = styled.div`
    width: 100%;
    height: 50px;
    background-color: ${colors.redDark};
`;

const Wrapper = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

type PropsTypes = WithStyles<typeof styles>;

const HeaderHeading: React.FC<PropsTypes> = props => {
    const { classes } = props;
    return (
        <BackgroundWrapper>
            <SizeControllerWrapper>
                <Wrapper>
                    <Typography variant="h2" classes={{ root: classes.heading }}>
                        {props.children}
                    </Typography>
                </Wrapper>
            </SizeControllerWrapper>
        </BackgroundWrapper>
    );
};

export default withStyles(styles)(HeaderHeading);
