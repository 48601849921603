import React, { useCallback } from 'react';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import { connect } from 'react-redux';
import { Actions as ProviderActions } from '../../../store/actions/provider.actions';
import { Actions as PatientActions } from '../../../store/actions/patient.actions';
import { Actions as LabActions } from '../../../store/actions/lab.actions';
import { bindActionCreators, Dispatch } from 'redux';
import * as fromRoot from '../../../store/reducers';
import { makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import HeaderSearch from '../../../shared/header/HeaderSearch';
import { RoleOptions } from '../../../constants/roleOptions';
import { PatientStepClassName } from '../../tour/constants/patientStepsClassNames';

const BackgroundWrapper = styled.div<any>`
    height: ${props => (props.hideTabs ? '70px' : '110px')};
    width: 100%;
    background-color: ${colors.gray2};
`;

type StoreModel = {
    searchQueryProvider: string;
    searchQueryLab: string;
    searchQueryPatient: string;
};

type DispatchModel = {
    setFilterQueryProvider: typeof ProviderActions.setFilterQuery;
    setFilterQueryLab: typeof LabActions.setFilterQuery;
    setFilterQueryPatient: typeof PatientActions.setFilterQueryAllPatients;
};

interface InjectedProps {
    activeTab: number;
    onChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
    hideTabs?: boolean;
    userRole: RoleOptions;
}

type PropsTypes = InjectedProps & StoreModel & DispatchModel;

const createStyles = makeStyles({
    tabText: {
        fontSize: '12px !important',
        color: colors.textSecondary,
    },
    tabs: {
        minHeight: '42px !important',
        height: '42px !important',
        marginTop: '4px',
        '& div': {
            justifyContent: 'space-between',
        },
    },
    tab: {
        height: '30px',
        minWidth: '10px',
        padding: '6px 16px',
    },
    selectedTab: {
        '& span': {
            color: colors.textPrimary,
        },
    },
    indicator: {
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: '100%',
            left: 'calc(50% - 4px)',

            borderBottom: `3px solid ${colors.redMain}`,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            width: 0,
            height: 0,
        },
    },
});

const SearchBlock: React.FC<PropsTypes> = props => {
    const {
        onChangeTab,
        setFilterQueryProvider,
        setFilterQueryPatient,
        setFilterQueryLab,
        hideTabs,
        activeTab,
        userRole,
    } = props;

    const classes = createStyles(props);

    const setSearchQuery = useCallback(
        (searchQuery: string) => {
            switch (activeTab) {
                case 0: {
                    return userRole === RoleOptions.PATIENT
                        ? setFilterQueryProvider(searchQuery)
                        : setFilterQueryPatient(searchQuery);
                }
                case 1: {
                    return setFilterQueryLab(searchQuery);
                }
                case 2: {
                    return null;
                }
                default: {
                    return null;
                }
            }
        },
        [userRole, setFilterQueryProvider, setFilterQueryPatient, setFilterQueryLab, activeTab]
    );

    return (
        <BackgroundWrapper hideTabs={hideTabs}>
            <SizeControllerWrapper>
                <HeaderSearch changeHandler={setSearchQuery} />
                {!hideTabs && (
                    <Tabs
                        value={activeTab}
                        onChange={onChangeTab}
                        indicatorColor="primary"
                        classes={{ root: classes.tabs, indicator: classes.indicator }}
                    >
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <div className={PatientStepClassName.CHOOSE_PROVIDER}>
                                    <Typography variant="button" classes={{ root: classes.tabText }}>
                                        {userRole === RoleOptions.PATIENT ? 'Providers' : 'Patients'}
                                    </Typography>
                                </div>
                            }
                        />

                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Labs
                                </Typography>
                            }
                        />
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Pharmacies
                                </Typography>
                            }
                        />
                    </Tabs>
                )}
            </SizeControllerWrapper>
        </BackgroundWrapper>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        searchQueryProvider: state.provider.searchQuery,
        searchQueryLab: state.lab.searchQuery,
        searchQueryPatient: state.patient.searchQueryAllPatients,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setFilterQueryProvider: bindActionCreators(ProviderActions.setFilterQuery, dispatch),
        setFilterQueryLab: bindActionCreators(LabActions.setFilterQuery, dispatch),
        setFilterQueryPatient: bindActionCreators(PatientActions.setFilterQueryAllPatients, dispatch),
    })
)(SearchBlock);
