import React, { useCallback, useState } from 'react';
import PrimaryButton from '../../../../shared/button/PrimaryButton';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import { RouteComponentProps, withRouter } from 'react-router';
import { useStripe } from '@stripe/react-stripe-js';

interface InjectedProps {
    invoice: InvoiceModel;
}

type PropsTypes = InjectedProps & RouteComponentProps<any>;
const ApproveButton: React.FC<PropsTypes> = props => {
    const { invoice, history } = props;
    const stripe = useStripe();
    const [loading, setLoading] = useState(false);

    const redirectToFail = useCallback(() => {
        history.push('/payment-result?status=fail');
    }, [history]);

    const redirectToSuccess = useCallback(() => {
        history.push('/payment-result?status=success');
    }, [history]);

    const approve = useCallback(
        async (evt: any) => {
            evt.stopPropagation();
            console.log(invoice, stripe);
            if (!stripe || !invoice.clientSecret) {
                return;
            }
            try {
                setLoading(true);
                const result = await stripe.confirmCardPayment(invoice.clientSecret!);
                console.log('result', result);
                setLoading(false);
                redirectToSuccess();
            } catch (error) {
                console.log('error', error);
                setLoading(false);
                redirectToFail();
            }
        },
        [stripe, invoice, redirectToFail, redirectToSuccess]
    );

    return (
        <>
            <PrimaryButton size={'small'} onClick={approve} disabled={loading}>
                Approve
            </PrimaryButton>
        </>
    );
};

export default withRouter(ApproveButton);
