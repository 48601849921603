import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import SecondaryButton from '../button/SecondaryButton';
import { colors } from '../../theme/colors';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PrimaryButton from '../button/PrimaryButton';
import { useLocation } from 'react-use';

const styles = createStyles({
    phoneNumber: {
        fontSize: '12px',
        marginBottom: '35px',
    },
    menuItem: {
        fontSize: '12px',
        textTransform: 'uppercase',
        color: colors.textPrimary,
        marginBottom: '40px',
        display: 'block',
        cursor: 'pointer',
    },
    selectedMenuItem: {
        fontSize: '12px',
        textTransform: 'uppercase',
        color: colors.redMain,
        marginBottom: '40px',
        display: 'block',
        cursor: 'pointer',
    },
});

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
`;

const ButtonWrapper = styled.span`
    &:nth-child(2) {
        margin: 20px 0 28px;
    }
`;

const DropdownOptionsWrapper = styled.div`
    padding: 56px 0 0;
    text-align: center;
`;

interface InjectedProps {
    goToPage: (path: string) => void;
}

type PropsTypes = RouteComponentProps<any> & WithStyles<typeof styles> & InjectedProps;

const SidebarMenuUnAuth: React.FC<PropsTypes> = props => {
    const { classes } = props;
    const [activeLink, setActiveLink] = useState(props.location.pathname);
    const state = useLocation();

    useEffect(() => {
        state.pathname && setActiveLink(state.pathname);
    }, [state.pathname]);

    const CustomMenuItem: React.FC<{ text: string; redirect: any; link: string }> = props => {
        const { text, redirect, link } = props;
        return (
            <Typography
                variant="button"
                classes={{ root: activeLink.trim() === link ? classes.selectedMenuItem : classes.menuItem }}
                onClick={redirect}
            >
                {text}
            </Typography>
        );
    };

    const menuItems = [
        { link: '/', text: 'home page' },
        { link: '/solutions', text: 'solutions' },
        { link: '/resources', text: 'resources' },
        { link: '/patient', text: 'patient' },
        { link: '/provider', text: 'provider' },
        { link: '/about', text: 'about us' },
    ];

    return (
        <>
            <DropdownOptionsWrapper>
                {menuItems.map((item, index) => (
                    <CustomMenuItem
                        key={index}
                        text={item.text}
                        link={item.link}
                        redirect={() => props.goToPage(item.link)}
                    />
                ))}
            </DropdownOptionsWrapper>
            <FooterWrapper>
                <ButtonWrapper>
                    <SecondaryButton size={'medium'} onClick={() => props.goToPage('/sign-in')}>
                        Sign In
                    </SecondaryButton>
                </ButtonWrapper>

                <ButtonWrapper>
                    <PrimaryButton size={'medium'} onClick={() => props.goToPage('/sign-up')}>
                        Sign Up
                    </PrimaryButton>
                </ButtonWrapper>

                <Typography variant="button" classes={{ root: classes.phoneNumber }}>
                    +1-202-555-0111
                </Typography>
            </FooterWrapper>
        </>
    );
};

export default withStyles(styles)(withRouter(SidebarMenuUnAuth));
