import React, { useCallback, useEffect, useState } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import SearchBlock from './components/SearchBlock';
import ProvidersList from './components/ProvidersList';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import { Actions as ProviderActions } from '../../store/actions/provider.actions';
import * as fromRoot from '../../store/reducers';
import { ProviderModel } from '../../models/responses/provider.response';
import { SortOptions } from '../../constants/sortOptions';
import { RoleOptions } from '../../constants/roleOptions';
import { DefaultPageSize, shouldFetchData } from '../../constants/dataFetching/allEntitiesByPages';
import { useCompare } from '../../helpers/usePreviousHelper';

type StateModel = {
    loading: boolean;
    allProviders: ProviderModel[];
    totalProviders: number | null;
    searchQuery: string;
    page: number;
};

type DispatchModel = {
    resetStatusVariables: typeof PatientActions.resetStatusVariables;
    setTabIndex: typeof PatientActions.setTabIndex;
    getAllProviders: typeof ProviderActions.getAllProviders;
    increasePageNumber: typeof ProviderActions.increasePageNumber;
    setFilterQuery: typeof ProviderActions.setFilterQuery;
};

type PropsTypes = StateModel & DispatchModel;

const ProviderLinking: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);
    const {
        getAllProviders,
        searchQuery,
        page,
        loading,
        allProviders,
        increasePageNumber,
        resetStatusVariables,
        setTabIndex,
        totalProviders,
        setFilterQuery,
    } = props;
    const isSearchQueryChanged = useCompare(searchQuery);

    const loadData = useCallback(
        (page: number, size: number, sort: SortOptions, searchQuery: string, overrideRecords: boolean) => {
            getAllProviders({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllProviders]
    );

    useEffect(() => {
        setFilterQuery('');
        resetStatusVariables();
        loadData(0, 10, SortOptions.LAST_NAME_ASCENDING, '', true);
        setInitialLoaded(true);
    }, [setFilterQuery, resetStatusVariables, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(loading, page, allProviders.length, initialLoaded, DefaultPageSize) ||
            isSearchQueryChanged
        ) {
            loadData(page, 10, SortOptions.LAST_NAME_ASCENDING, searchQuery, false);
        }
    }, [page, searchQuery, loadData, loading, initialLoaded, allProviders, isSearchQueryChanged]);

    return (
        <>
            <HeaderHeadingWithLink>Provider Linking</HeaderHeadingWithLink>
            <SearchBlock
                // active tab for providers
                activeTab={0}
                onChangeTab={(event: React.ChangeEvent<{}>, newValue: number) => {
                    setTabIndex(newValue);
                }}
                hideTabs
                userRole={RoleOptions.PATIENT}
            />

            <ProvidersList
                allProviders={allProviders}
                totalProviders={totalProviders}
                loading={loading}
                page={page}
                increasePageNumber={increasePageNumber}
            />
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        allProviders: state.provider.allProviders,
        totalProviders: state.provider.totalProviders,
        loading: state.provider.loading,
        searchQuery: state.provider.searchQuery,
        page: state.provider.page,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(PatientActions.resetStatusVariables, dispatch),
        setTabIndex: bindActionCreators(PatientActions.setTabIndex, dispatch),
        getAllProviders: bindActionCreators(ProviderActions.getAllProviders, dispatch),
        increasePageNumber: bindActionCreators(ProviderActions.increasePageNumber, dispatch),
        setFilterQuery: bindActionCreators(ProviderActions.setFilterQuery, dispatch),
    })
)(ProviderLinking);
