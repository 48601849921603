import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import { FormControl, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import halfLogo from '../../../static/images/landing/main/halfLogoBackground.svg';
import TextInput from '../../../shared/input/TextInput';
import { colors } from '../../../theme/colors';
import DatePicker from '../../../shared/input/DatePicker';
import PhoneNumberInput from '../../../shared/input/PhoneNumberInput';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { GenderOptions } from '../../../constants/genderOptions';
import DropdownInput from '../../../shared/input/DropdownInput';
import { PatientModel } from '../../../models/responses/patient.response';
import { PatientDto } from '../../../models/dto/patient.dto';
import { RouteComponentProps, withRouter } from 'react-router';
import RequestState from '../../../constants/requestState';
import { UserModel } from '../../../models/responses/user.response';
import { toTitleCase } from '../../../helpers/stringHelpers';
import { validateSync } from 'class-validator';
import { EditPatientDto } from '../../../models/dto/editPatient.dto';
import fillClassInstanceWithValues from '../../../shared/utils/fillClassInstanceWithValues';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { mediaQuery } from '../../../constants/device';
import ChangePasswordButton from '../../changePassword/ChangePasswordButton';

const useStyles = makeStyles(theme => ({
    label: {
        display: 'block',
        fontSize: '10px',
        color: colors.gray1,
        marginLeft: '12px',
        marginTop: '3px',
    },
    laptopBackground: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            position: 'absolute',
            zIndex: -1,
            right: 0,
            top: '550px',
        },
    },
}));

const BackgroundWrapper = styled.div`
    background-image: url(${halfLogo});
    background-repeat: no-repeat;
    background-position: right 45%;
    background-size: 53%;
    ${mediaQuery.laptop} {
        background: none;
    }
`;

const Wrapper = styled.div`
    padding: 20px 14px 55px;
    ${mediaQuery.laptop} {
        width: 400px;
        margin: 0 104px;
    }
`;

const InputWrapper = styled(FormControl)`
    width: 100%;
    margin: 20px auto;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;

const FormWrapper = styled.div`
    & > div {
        margin-bottom: 27px !important;
    }
    & > div:last-child {
        margin-bottom: 0 !important;
    }
`;

const EditModeLabel = styled.div<any>`
    position: absolute;
    visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

const ErrorWrapper = styled.div`
    position: absolute;
`;

interface InjectedProps {
    currentPatient: PatientModel;
    updatePatient: (patient: PatientDto) => void;
    setCurrentUser: (user: UserModel) => void;
    setEditModeOn: () => void;
    setEditModeOff: () => void;
    editMode: boolean;
    updating: RequestState;
    setIsSetupToTrue: () => void;
    resetStatusVariables: () => void;
    user: UserModel | null;
}

type PropsTypes = RouteComponentProps<any> & InjectedProps;

interface ValidationErrorsMap {
    [p: string]: string;
}

interface PatientFormType {
    email: string;
    firstName: string;
    lastName: string;
    gender: string;
    birthDate: null | Date;
    phone: string;
    streetAddress1: string;
    streetAddress2: string;
    zip: string;
    city: string;
    state: string;
    isBeingEdited: Set<string>;
}
const patientInitialState = {
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthDate: null,
    phone: '',
    streetAddress1: '',
    streetAddress2: '',
    zip: '',
    city: '',
    state: '',
    isBeingEdited: new Set<string>(),
};

const phoneInputEmptyValue = '+ (   )    .    ';
const requiredFields = ['firstName', 'lastName', 'gender', 'email'];
const optionalFields = ['phone', 'birthDate', 'streetAddress1', 'streetAddress2', 'zip', 'city', 'state'];

const ProfileInfoPatient: React.FC<PropsTypes> = props => {
    const {
        user,
        resetStatusVariables,
        history,
        updating,
        setEditModeOn,
        setIsSetupToTrue,
        setCurrentUser,
        currentPatient,
        editMode,
        updatePatient,
        setEditModeOff,
    } = props;

    const [patient, setPatient] = useState<PatientFormType>(patientInitialState);
    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    useEffect(() => {
        setPatient({
            email: currentPatient.email,
            firstName: currentPatient.firstName,
            lastName: currentPatient.lastName,
            gender: currentPatient.gender ? currentPatient.gender.toLowerCase() : '',
            birthDate: currentPatient.birthDate ? new Date(currentPatient.birthDate) : null,
            phone: currentPatient.phone ? currentPatient.phone : '',
            streetAddress1: currentPatient.streetAddress1 ? currentPatient.streetAddress1 : '',
            streetAddress2: currentPatient.streetAddress2 ? currentPatient.streetAddress2 : '',
            zip: currentPatient.zip ? currentPatient.zip : '',
            city: currentPatient.city ? currentPatient.city : '',
            state: currentPatient.state ? currentPatient.state : '',
            isBeingEdited: new Set(),
        });
    }, [currentPatient]);

    useEffect(() => {
        return () => {
            setValidationErrorsMap({});
        };
    }, []);

    useEffect(() => {
        validateForm(patient);
    }, [patient]);

    const sendForm = () => {
        if (isValid()) {
            updatePatient({
                firstName: patient.firstName,
                lastName: patient.lastName,
                gender: patient.gender,
                phone: patient.phone,
                birthDate: patient.birthDate && patient.birthDate.toISOString(),
                streetAddress1: patient.streetAddress1,
                streetAddress2: patient.streetAddress2,
                city: patient.city,
                state: patient.state,
                zip: patient.zip,
            });
        }
    };

    const isValid = () => {
        const map = validateForm(patient, true);
        let valid = true;
        requiredFields.forEach(field => {
            if (map.hasOwnProperty(field)) {
                valid = false;
            }
        });
        optionalFields.forEach(field => {
            if (map.hasOwnProperty(field) && patient.isBeingEdited.has(field)) {
                valid = false;
            }
        });
        if (valid) {
            setValidationErrorsMap({});
        }
        return valid;
    };

    const changeProfile = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newUser = {
                ...patient,
                [e.target.id]: e.target.value,
                isBeingEdited: patient.isBeingEdited.add(e.target.id),
            };
            if (!e.target.value || (e.target.value.length === 0 && optionalFields.includes(e.target.id))) {
                newUser.isBeingEdited.delete(e.target.id);
            }
            setPatient(newUser);
        },
        [patient]
    );

    const validateForm = (user: any, isOnSubmit = false) => {
        const item = fillClassInstanceWithValues<EditPatientDto>(EditPatientDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                    map[err.property] = Object.entries(err.constraints)
                        .map(([, value]) => value)
                        .join(', ');
                }
            });
            setValidationErrorsMap(map);
        }
        setValidationErrorsMap(map);
        return map;
    };

    const resetForm = useCallback(() => {
        patientInitialState.isBeingEdited = new Set();
        setPatient(patientInitialState);
    }, []);

    useEffect(() => {
        if (updating === RequestState.SENT_SUCCESS) {
            if (user && user.setup) {
                resetForm();
                setEditModeOff();
                window.scrollTo(0, 0);
                history.push('/profile');
            } else if (user) {
                setIsSetupToTrue();
                setCurrentUser({ ...user, setup: true });
                history.push(user.createdBy ? '/patient-start' : '/provider-linking');
            }
        }
    }, [updating, history, user, resetForm, setCurrentUser, setEditModeOff, setIsSetupToTrue]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    const renderError = (field: keyof ValidationErrorsMap) => {
        if (!!validationErrorsMap[field]) {
            return (
                <ErrorWrapper>
                    <FormHelperText id="component-error-text">
                        {validationErrorsMap[field].split(',')[0]}
                    </FormHelperText>
                </ErrorWrapper>
            );
        }
    };

    const getValue = useCallback(
        (value: string) => {
            return editMode ? value : value.length ? value : 'Empty';
        },
        [editMode]
    );

    const classes = useStyles(props);

    return (
        <BackgroundWrapper>
            <SizeControllerWrapper>
                <Wrapper>
                    <FormWrapper>
                        {editMode && (
                            <>
                                <InputWrapper error={!!validation.firstName}>
                                    <TextInput
                                        placeholder={'First Name'}
                                        value={patient.firstName}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                            changeProfile(evt);
                                        }}
                                        inputProps={{ id: 'firstName' }}
                                        error={!!validation.firstName}
                                        disabled={!editMode}
                                    />
                                    {!!validation.firstName ? (
                                        renderError('firstName')
                                    ) : (
                                        <EditModeLabel show={editMode}>
                                            <Typography variant="button" classes={{ root: classes.label }}>
                                                First Name
                                            </Typography>
                                        </EditModeLabel>
                                    )}
                                </InputWrapper>

                                <InputWrapper error={!!validation.lastName}>
                                    <TextInput
                                        placeholder={'Last Name'}
                                        value={patient.lastName}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                            changeProfile(evt);
                                        }}
                                        inputProps={{ id: 'lastName' }}
                                        error={!!validation.lastName}
                                        disabled={!editMode}
                                    />
                                    {!!validation.lastName ? (
                                        renderError('lastName')
                                    ) : (
                                        <EditModeLabel show={editMode}>
                                            <Typography variant="button" classes={{ root: classes.label }}>
                                                Last Name
                                            </Typography>
                                        </EditModeLabel>
                                    )}
                                </InputWrapper>
                            </>
                        )}

                        {editMode ? (
                            <InputWrapper error={!!validation.gender}>
                                <DropdownInput
                                    placeholder={'Gender'}
                                    options={GenderOptions}
                                    value={patient.gender}
                                    onChange={value => {
                                        // @ts-ignore
                                        changeProfile({ target: { id: 'gender', value: value } });
                                    }}
                                    selectedIndex={GenderOptions.indexOf(patient.gender)}
                                    error={!!validation.gender}
                                    disabled={!editMode}
                                />
                                {!!validation.gender ? (
                                    renderError('gender')
                                ) : (
                                    <EditModeLabel show={editMode}>
                                        <Typography variant="button" classes={{ root: classes.label }}>
                                            Gender
                                        </Typography>
                                    </EditModeLabel>
                                )}
                            </InputWrapper>
                        ) : (
                            <>
                                {patient && patient.gender && (
                                    <InputWrapper>
                                        <TextInput
                                            placeholder={''}
                                            value={toTitleCase(patient ? patient.gender : '')}
                                            disabled
                                        />
                                        <EditModeLabel show={editMode}>
                                            <Typography variant="button" classes={{ root: classes.label }}>
                                                Gender
                                            </Typography>
                                        </EditModeLabel>
                                    </InputWrapper>
                                )}
                            </>
                        )}

                        {(editMode || (patient && patient.phone)) && (
                            <InputWrapper error={!!validation.phone}>
                                <PhoneNumberInput
                                    placeholder={'+ (000) 000.0000'}
                                    value={patient.phone}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        if (
                                            evt.target.value !== phoneInputEmptyValue ||
                                            patient.isBeingEdited.has('phone')
                                        ) {
                                            changeProfile(evt);
                                        }
                                    }}
                                    inputProps={{ id: 'phone' }}
                                    error={!!validation.phone && patient.isBeingEdited.has('phone')}
                                    disabled={!editMode}
                                />
                                {!!validation.phone && patient.isBeingEdited.has('phone') ? (
                                    renderError('phone')
                                ) : (
                                    <EditModeLabel show={editMode}>
                                        <Typography variant="button" classes={{ root: classes.label }}>
                                            Phone
                                        </Typography>
                                    </EditModeLabel>
                                )}
                            </InputWrapper>
                        )}

                        {editMode ? (
                            <InputWrapper error={!!validation.birthDate}>
                                <DatePicker
                                    value={patient.birthDate}
                                    onChange={(date: Date | null) => {
                                        //@ts-ignore
                                        changeProfile({ target: { id: 'birthDate', value: date } });
                                    }}
                                    error={!!validation.birthDate && patient.isBeingEdited.has('birthDate')}
                                    disabled={!editMode}
                                />
                                {!!validation.birthDate && patient.isBeingEdited.has('birthDate') ? (
                                    renderError('birthDate')
                                ) : (
                                    <EditModeLabel show={editMode}>
                                        <Typography variant="button" classes={{ root: classes.label }}>
                                           Birth Date
                                        </Typography>
                                    </EditModeLabel>
                                )}
                            </InputWrapper>
                        ) : (
                            <>
                                {patient && (
                                    <InputWrapper>
                                        <TextInput
                                            placeholder={'Birth Date'}
                                            value={
                                                patient.birthDate
                                                    ? moment(patient.birthDate).format('MM.DD.YYYY')
                                                    : 'Empty'
                                            }
                                            disabled
                                            color={!patient.birthDate ? colors.grayUnderline : undefined}
                                        />
                                        <EditModeLabel show={editMode}>
                                            <Typography variant="button" classes={{ root: classes.label }}>
                                                Date
                                            </Typography>
                                        </EditModeLabel>
                                    </InputWrapper>
                                )}
                            </>
                        )}

                        <InputWrapper error={!!validation.streetAddress1}>
                            <TextInput
                                placeholder={'Address 1'}
                                value={getValue(patient.streetAddress1)}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeProfile(evt);
                                }}
                                inputProps={{ id: 'streetAddress1' }}
                                error={!!validation.streetAddress1 && patient.isBeingEdited.has('streetAddress2')}
                                disabled={!editMode}
                                color={getValue(patient.streetAddress1) === 'Empty' ? colors.grayUnderline : undefined}
                            />

                            {patient.isBeingEdited.has('streetAddress1') && !!validation.streetAddress1 ? (
                                renderError('streetAddress1')
                            ) : (
                                <EditModeLabel show={editMode}>
                                    <Typography variant="button" classes={{ root: classes.label }}>
                                        Address 1
                                    </Typography>
                                </EditModeLabel>
                            )}
                        </InputWrapper>

                        <InputWrapper error={!!validation.streetAddress2}>
                            <TextInput
                                placeholder={'Address 2'}
                                value={getValue(patient.streetAddress2)}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeProfile(evt);
                                }}
                                inputProps={{ id: 'streetAddress2' }}
                                error={!!validation.streetAddress2 && patient.isBeingEdited.has('streetAddress2')}
                                disabled={!editMode}
                                color={getValue(patient.streetAddress2) === 'Empty' ? colors.grayUnderline : undefined}
                            />
                            {patient.isBeingEdited.has('streetAddress2') && !!validation.streetAddress2 ? (
                                renderError('streetAddress2')
                            ) : (
                                <EditModeLabel show={editMode}>
                                    <Typography variant="button" classes={{ root: classes.label }}>
                                        Address 2
                                    </Typography>
                                </EditModeLabel>
                            )}
                        </InputWrapper>

                        <InputWrapper error={!!validation.city}>
                            <TextInput
                                placeholder={'City'}
                                value={getValue(patient.city)}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeProfile(evt);
                                }}
                                inputProps={{ id: 'city' }}
                                error={!!validation.city && patient.isBeingEdited.has('city')}
                                disabled={!editMode}
                                color={getValue(patient.city) === 'Empty' ? colors.grayUnderline : undefined}
                            />
                            {patient.isBeingEdited.has('city') && !!validation.city ? (
                                renderError('city')
                            ) : (
                                <EditModeLabel show={editMode}>
                                    <Typography variant="button" classes={{ root: classes.label }}>
                                        City
                                    </Typography>
                                </EditModeLabel>
                            )}
                        </InputWrapper>

                        <InputWrapper error={!!validation.state}>
                            <TextInput
                                placeholder={'State'}
                                value={getValue(patient.state)}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeProfile(evt);
                                }}
                                inputProps={{ id: 'state' }}
                                error={!!validation.state && patient.isBeingEdited.has('state')}
                                disabled={!editMode}
                                color={getValue(patient.state) === 'Empty' ? colors.grayUnderline : undefined}
                            />
                            {patient.isBeingEdited.has('state') && !!validation.state ? (
                                renderError('state')
                            ) : (
                                <EditModeLabel show={editMode}>
                                    <Typography variant="button" classes={{ root: classes.label }}>
                                        State
                                    </Typography>
                                </EditModeLabel>
                            )}
                        </InputWrapper>

                        <InputWrapper error={!!validation.zip}>
                            <TextInput
                                placeholder={'Zip'}
                                value={getValue(patient.zip)}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeProfile(evt);
                                }}
                                inputProps={{ id: 'zip' }}
                                error={!!validation.zip && patient.isBeingEdited.has('zip')}
                                disabled={!editMode}
                                color={getValue(patient.zip) === 'Empty' ? colors.grayUnderline : undefined}
                            />
                            {patient.isBeingEdited.has('zip') && !!validation.zip ? (
                                renderError('zip')
                            ) : (
                                <EditModeLabel show={editMode}>
                                    <Typography variant="button" classes={{ root: classes.label }}>
                                        {validation.zip ? '' : 'Zip'}{' '}
                                    </Typography>
                                </EditModeLabel>
                            )}
                        </InputWrapper>

                        <InputWrapper error={!!validation.email}>
                            <TextInput
                                placeholder={'Email'}
                                value={getValue(patient.email)}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeProfile(evt);
                                }}
                                inputProps={{ id: 'email' }}
                                disabled
                                color={getValue(patient.email) === 'Empty' ? colors.grayUnderline : undefined}
                            />
                            <EditModeLabel show={editMode}>
                                <Typography variant="button" classes={{ root: classes.label }}>
                                    Email
                                </Typography>
                            </EditModeLabel>
                        </InputWrapper>
                    </FormWrapper>

                    <ButtonWrapper>
                        <PrimaryButton onClick={editMode ? sendForm : setEditModeOn}>
                            {editMode ? 'Save Profile' : 'Edit Profile'}
                        </PrimaryButton>
                    </ButtonWrapper>
                    {!editMode && <ChangePasswordButton />}
                </Wrapper>
            </SizeControllerWrapper>
            <img src={halfLogo} alt="" className={classes.laptopBackground} />
        </BackgroundWrapper>
    );
};

export default withRouter(ProfileInfoPatient);
