import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { InvoiceModel } from '../../models/responses/invoice.model';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { DeleteInvoiceDto } from '../../models/dto/deleteInvoice.dto';
import { AddInvoiceDto } from '../../models/dto/addInvoice.dto';
import { EditInvoiceDto } from '../../models/dto/editInvoiceDto';
import { GenerateStateResponce } from '../../models/responses/generateState.responce';
import { ConnectOauthDto } from '../../models/dto/connectOauth.dto';
import { PaymentSessionIdResponse } from '../../models/responses/paymentSessionId.response';
import ConnectedAccountLinkResponce from '../../models/responses/connectedAccountLink.responce';
import { MerchantVerification } from '../../models/responses/merchantVerification';
import { InvocieCountResponse } from '../../models/responses/invocieCount.response';
import GetInvoiceProviderDto from '../../models/dto/getInvoiceProvider.dto';
import { PayInvoiceDto } from '../../models/dto/payInvoice.dto';
import { InvoiceSubscriptionModel } from '../../models/responses/invoiceSubscription.model';

export enum ActionNames {
    GET_ALL_ONE_TIME_REQUESTED_PATIENT = '[Invoice] Get All One Time Requested Patient',
    GET_ALL_ONE_TIME_REQUESTED_PATIENT_OVERRIDE_SUCCESS = '[Invoice] Get All One Time Requested Patient Override Success',
    GET_ALL_ONE_TIME_REQUESTED_PATIENT_UPDATE_SUCCESS = '[Invoice] Get All One Time Requested Patient Update Success',
    GET_ALL_ONE_TIME_REQUESTED_PATIENT_FAILED = '[Invoice] Get All One Time Requested Patient Failed',

    INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PATIENT = '[Invoice] Increase Page Number One Time Requested Patient',

    GET_ALL_ONE_TIME_REQUESTED_PROVIDER = '[Invoice] Get All One Time Requested Provider',
    GET_ALL_ONE_TIME_REQUESTED_PROVIDER_OVERRIDE_SUCCESS = '[Invoice] Get All One Time Requested Provider Override Success',
    GET_ALL_ONE_TIME_REQUESTED_PROVIDER_UPDATE_SUCCESS = '[Invoice] Get All One Time Requested Provider Update Success',
    GET_ALL_ONE_TIME_REQUESTED_PROVIDER_FAILED = '[Invoice] Get All One Time Requested Provider Failed',

    INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PROVIDER = '[Invoice] Increase Page Number One Time Requested Provider',

    GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS = '[Invoice] Get All One Time Requested Provider All Patients',
    GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_UPDATE_SUCCESS = '[Invoice] Get All One Time Requested Provider All Patients Update Success',
    GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_OVERRIDE_SUCCESS = '[Invoice] Get All One Time Requested Provider All Patients Override Success',
    GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_FAILED = '[Invoice] Get All One Time Requested Provider All Patients Failed',

    INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS = '[Invoice] Increase Page Number One Time Requested All Patients ',

    GET_ALL_ONE_TIME_ARCHIVED_PATIENT = '[Invoice] Get All One Time Archived Patient',
    GET_ALL_ONE_TIME_ARCHIVED_PATIENT_OVERRIDE_SUCCESS = '[Invoice] Get All One Time Archived Patient Override Success',
    GET_ALL_ONE_TIME_ARCHIVED_PATIENT_UPDATE_SUCCESS = '[Invoice] Get All One Time Archived Patient Update Success',
    GET_ALL_ONE_TIME_ARCHIVED_PATIENT_FAILED = '[Invoice] Get All One Time Archived Patient Failed',

    GET_ALL_ONE_TIME_ARCHIVED_PROVIDER = '[Invoice] Get All One Time Archived Provider',
    GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_SUCCESS = '[Invoice] Get All One Time Archived Provider Success',
    GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_FAILED = '[Invoice] Get All One Time Archived Provider Failed',

    GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS = '[Invoice] Get All One Time Archived Provider All Patients',
    GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS_SUCCESS = '[Invoice] Get All One Time Archived Provider All Patients Success',
    GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS_FAILED = '[Invoice] Get All One Time Archived Provider All Patients Failed',

    INCREASE_PAGE_NUMBER_ONE_TIME_ARCHIVED = '[Invoice] Increase Page Number One Time Archived',

    GET_ALL_RECURRING_SUBSCRIPTION_PATIENT = '[Invoice] Get All Recurring Subscription Patient',
    GET_ALL_RECURRING_SUBSCRIPTION_PATIENT_SUCCESS = '[Invoice] Get All Recurring Subscription Patient Success',
    GET_ALL_RECURRING_SUBSCRIPTION_PATIENT_FAILED = '[Invoice] Get All Recurring Subscription Patient Failed',

    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER = '[Invoice] Get All Recurring Subscription Provider',
    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_SUCCESS = '[Invoice] Get All Recurring Subscription Provider Success',
    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_FAILED = '[Invoice] Get All Recurring Subscription Provider Failed',

    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED = '[Invoice] Get All Recurring Subscription Provider All Patients Requested',
    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_OVERRIDE_SUCCESS = '[Invoice] Get All Recurring Subscription Provider All Patients Requested Override Success',
    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_UPDATE_SUCCESS = '[Invoice] Get All Recurring Subscription Provider All Patients Requested Update Success',
    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_FAILED = '[Invoice] Get All Recurring Subscription Provider All Patients Requested Failed',

    INCREASE_PAGE_NUMBER_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED = '[Invoice] Increase Page Number Recurring Subscription Provider All Patients Requested',

    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE = '[Invoice] Get All Recurring Subscription Provider All Patients Active',
    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_OVERRIDE_SUCCESS = '[Invoice] Get All Recurring Subscription Provider All Patients Active Override Success',
    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_UPDATE_SUCCESS = '[Invoice] Get All Recurring Subscription Provider All Patients Active Update Success',
    GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_FAILED = '[Invoice] Get All Recurring Subscription Provider All Patients Active Failed',

    INCREASE_PAGE_NUMBER_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE = '[Invoice] Increase Page Number Recurring Subscription Provider All Patients Active',

    GET_ALL_RECURRING_ARCHIVED_PATIENT = '[Invoice] Get All Recurring Archived Patient',
    GET_ALL_RECURRING_ARCHIVED_PATIENT_OVERRIDE_SUCCESS = '[Invoice] Get All Recurring Archived Patient Override Success',
    GET_ALL_RECURRING_ARCHIVED_PATIENT_UPDATE_SUCCESS = '[Invoice] Get All Recurring Archived Patient Update Success',
    GET_ALL_RECURRING_ARCHIVED_PATIENT_FAILED = '[Invoice] Get All Recurring Archived Patient Failed',

    GET_ALL_RECURRING_ARCHIVED_PROVIDER = '[Invoice] Get All Recurring Archived Provider',
    GET_ALL_RECURRING_ARCHIVED_PROVIDER_SUCCESS = '[Invoice] Get All Recurring Archived Provider Success',
    GET_ALL_RECURRING_ARCHIVED_PROVIDER_FAILED = '[Invoice] Get All Recurring Archived Provider Failed',

    GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS = '[Invoice] Get All Recurring Archived Provider All Patients',
    GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS_SUCCESS = '[Invoice] Get All Recurring Archived Provider All Patients Success',
    GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS_FAILED = '[Invoice] Get All Recurring Archived Provider All Patients Failed',

    INCREASE_PAGE_NUMBER_RECURRING_ARCHIVED = '[Invoice] Increase Page Number Recurring Archived',

    RESET_ARCHIVED = '[Invoice] Reset Archived',

    DELETE_INVOICE = '[Invoice] Delete Invoice',
    DELETE_INVOICE_SUCCESS = '[Invoice] Delete Invoice Success',
    DELETE_INVOICE_FAILED = '[Invoice] Delete Invoice Failed',

    RESET_STATUS_VARIABLES = '[Invoice] Reset Status Variables ',

    ADD_INVOICE = '[Invoice] Add Invoice',
    ADD_INVOICE_SUCCESS = '[Invoice] Add Invoice Success',
    ADD_INVOICE_FAILED = '[Invoice] Add Invoice Failed',

    EDIT_INVOICE = '[Invoice] Edit Invoice',
    EDIT_INVOICE_SUCCESS = '[Invoice] Edit Invoice Success',
    EDIT_INVOICE_FAILED = '[Invoice] Edit Invoice Failed',

    REJECT_INVOICE = '[Invoice] Reject Invoice',
    REJECT_INVOICE_SUCCESS = '[Invoice] Reject Invoice Success',
    REJECT_INVOICE_FAILED = '[Invoice] Reject Invoice Failed',

    GET_REQUESTED_INVOICE_BY_ID = '[Invoice] Get Requested Invoice By Id',
    GET_REQUESTED_INVOICE_BY_ID_SUCCESS = '[Invoice] Get Requested Invoice By Id Success',
    GET_REQUESTED_INVOICE_BY_ID_FAILED = '[Invoice] Get Requested Invoice By Id Failed',

    GENERATE_STATE = '[Invoice] Generate State',
    GENERATE_STATE_SUCCESS = '[Invoice] Generate State Success',
    GENERATE_STATE_FAILED = '[Invoice] Generate State Failed',

    CONNECT_OAUTH = '[Invoice] Connect OAuth',
    CONNECT_OAUTH_SUCCESS = '[Invoice] Connect OAuth Success',
    CONNECT_OAUTH_FAILED = '[Invoice] Connect OAuth Failed',

    PAY_INVOICE = '[Invoice] Pay Invoice',
    PAY_INVOICE_SUCCESS = '[Invoice] Pay Invoice Success',
    PAY_INVOICE_FAILED = '[Invoice] Pay Invoice Failed',

    SUBSCRIBE_INVOICE = '[Invoice] Subscribe Invoice',
    SUBSCRIBE_INVOICE_SUCCESS = '[Invoice] Subscribe Invoice Success',
    SUBSCRIBE_INVOICE_FAILED = '[Invoice] Subscribe Invoice Failed',

    UNSUBSCRIBE_INVOICE = '[Invoice] Unsubscribe Invoice',
    UNSUBSCRIBE_INVOICE_SUCCESS = '[Invoice] Unsubscribe Invoice Success',
    UNSUBSCRIBE_INVOICE_FAILED = '[Invoice] Unsubscribe Invoice Failed',

    GET_CONNECTED_ACCOUNT_LINK = '[Invoice] Get Connected Account Link',
    GET_CONNECTED_ACCOUNT_LINK_SUCCESS = '[Invoice] Get Connected Account Link Success',
    GET_CONNECTED_ACCOUNT_LINK_FAILED = '[Invoice] Get Connected Account Link Failed',

    VERIFY_MERCHANT = '[Invoice] Verify Merchant',
    VERIFY_MERCHANT_SUCCESS = '[Invoice] Verify Merchant Success',
    VERIFY_MERCHANT_FAILED = '[Invoice] Verify Merchant Failed',

    GET_ONE_TIME_REQUESTED_PATIENT_COUNT = '[Invoice] Get One Time Requested Patient Count',
    GET_ONE_TIME_REQUESTED_PATIENT_COUNT_SUCCESS = '[Invoice] Get One Time Requested Patient Count Success',

    GET_ALL_INVOICES_COUNT_PROVIDER_ALL_PATIENTS = '[Invoice] Get All Invoices Count Provider All Patients',
    GET_ALL_INVOICES_COUNT_PROVIDER_ALL_PATIENTS_SUCCESS = '[Invoice] Get All Invoices Count Provider All Patients Success',

    GET_ALL_INVOICES_COUNT_PROVIDER_PATIENT = '[Invoice] Get All Invoices Count Provider Patient',
    GET_ALL_INVOICES_COUNT_PROVIDER_PATIENT_SUCCESS = '[Invoice] Get All Invoices Count Provider Patient Success',

    GET_INVOICE_BY_ID = '[Invoice] Get Invoice By Id',
    GET_INVOICE_BY_ID_SUCCESS = '[Invoice] Get Invoice By Id Success',
    GET_INVOICE_BY_ID_FAILED = '[Invoice] Get Invoice By Id Failed',

    GET_INVOICE_BY_ID_PROVIDER = '[Invoice] Get Invoice By Id Provider',
    GET_INVOICE_BY_ID_PROVIDER_SUCCESS = '[Invoice] Get Invoice By Id Provider Success',
    GET_INVOICE_BY_ID_PROVIDER_FAILED = '[Invoice] Get Invoice By Id Provider Failed',
}

export const Actions = {
    getAllOneTimeRequestedPatient: (data: AllEntitiesByPagesDto) =>
        createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT, data),
    getAllOneTimeRequestedPatientOverrideSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT_OVERRIDE_SUCCESS, data),
    getAllOneTimeRequestedPatientUpdateSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT_UPDATE_SUCCESS, data),
    getAllOneTimeRequestedPatientFailed: () => createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT_FAILED),

    increasePageNumberOneTimeRequestedPatient: () =>
        createAction(ActionNames.INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PATIENT),

    getAllOneTimeRequestedProvider: (data: { data: AllEntitiesByPagesDto; patientId: number }) =>
        createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER, data),
    getAllOneTimeRequestedProviderOverrideSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_OVERRIDE_SUCCESS, data),
    getAllOneTimeRequestedProviderUpdateSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_UPDATE_SUCCESS, data),
    getAllOneTimeRequestedProviderFailed: () => createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_FAILED),

    increasePageNumberAllOneTimeRequestedProvider: () =>
        createAction(ActionNames.INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PROVIDER),

    getAllOneTimeRequestedProviderAllPatients: (data: AllEntitiesByPagesDto) =>
        createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS, data),
    getAllOneTimeRequestedProviderAllPatientsOverrideSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_OVERRIDE_SUCCESS, data),
    getAllOneTimeRequestedProviderAllPatientsUpdateSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_UPDATE_SUCCESS, data),
    getAllOneTimeRequestedProviderAllPatientsFailed: () =>
        createAction(ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_FAILED),

    increasePageNumberOneTimeRequestedProviderAllPatients: () =>
        createAction(ActionNames.INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS),

    getAllOneTimeArchivedPatient: (data: AllEntitiesByPagesDto) =>
        createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT, data),
    getAllOneTimeArchivedPatientOverrideSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT_OVERRIDE_SUCCESS, data),
    getAllOneTimeArchivedPatientUpdateSuccess: (data: { result: InvoiceModel[]; total: number; totalAmount: number }) =>
        createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT_UPDATE_SUCCESS, data),
    getAllOneTimeArchivedPatientFailed: () => createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT_FAILED),

    getAllOneTimeArchivedProvider: (data: { data: AllEntitiesByPagesDto; patientId: number }) =>
        createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER, data),
    getAllOneTimeArchivedProviderSuccess: (data: { result: InvoiceModel[]; total: number; totalAmount: number }) =>
        createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_SUCCESS, data),
    getAllOneTimeArchivedProviderFailed: () => createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_FAILED),

    getAllOneTimeArchivedProviderAllPatients: (data: AllEntitiesByPagesDto) =>
        createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS, data),
    getAllOneTimeArchivedProviderAllPatientsSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS_SUCCESS, data),
    getAllOneTimeArchivedProviderAllPatientsFailed: () =>
        createAction(ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS_FAILED),

    increasePageNumberOneTimeArchived: () => createAction(ActionNames.INCREASE_PAGE_NUMBER_ONE_TIME_ARCHIVED),

    getAllRecurringSubscriptionPatient: () => createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PATIENT),
    getAllRecurringSubscriptionPatientSuccess: (data: InvoiceModel[]) =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PATIENT_SUCCESS, data),
    getAllRecurringSubscriptionPatientFailed: () =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PATIENT_FAILED),

    getAllRecurringSubscriptionProvider: (patientId: number) =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER, patientId),
    getAllRecurringSubscriptionProviderSuccess: (data: InvoiceModel[]) =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_SUCCESS, data),
    getAllRecurringSubscriptionProviderFailed: () =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_FAILED),

    getAllRecurringSubscriptionProviderAllPatientsRequested: (data: AllEntitiesByPagesDto) =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED, data),
    getAllRecurringSubscriptionProviderAllPatientsRequestedOverrideSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_OVERRIDE_SUCCESS, data),
    getAllRecurringSubscriptionProviderAllPatientsRequestedUpdateSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_UPDATE_SUCCESS, data),
    getAllRecurringSubscriptionProviderAllPatientsRequestedFailed: () =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_FAILED),

    increasePageNumberAllRecurringSubscriptionProviderAllPatientsRequested: () =>
        createAction(ActionNames.INCREASE_PAGE_NUMBER_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED),

    getAllRecurringSubscriptionProviderAllPatientsActive: (data: AllEntitiesByPagesDto) =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE, data),
    getAllRecurringSubscriptionProviderAllPatientActiveOverrideSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_OVERRIDE_SUCCESS, data),
    getAllRecurringSubscriptionProviderAllPatientsActiveUpdateSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_UPDATE_SUCCESS, data),
    getAllRecurringSubscriptionProviderAllPatientsActiveFailed: () =>
        createAction(ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_FAILED),

    increasePageNumberAllRecurringSubscriptionProviderAllPatientsActive: () =>
        createAction(ActionNames.INCREASE_PAGE_NUMBER_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE),

    getAllRecurringArchivedPatient: (data: AllEntitiesByPagesDto) =>
        createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT, data),
    getAllRecurringArchivedPatientOverrideSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT_OVERRIDE_SUCCESS, data),
    getAllRecurringArchivedPatientUpdateSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT_UPDATE_SUCCESS, data),
    getAllRecurringArchivedPatientFailed: () => createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT_FAILED),

    getAllRecurringArchivedProvider: (data: { data: AllEntitiesByPagesDto; patientId: number }) =>
        createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER, data),
    getAllRecurringArchivedProviderSuccess: (data: { result: InvoiceModel[]; total: number; totalAmount: number }) =>
        createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_SUCCESS, data),
    getAllRecurringArchivedProviderFailed: () => createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_FAILED),

    getAllRecurringArchivedProviderAllPatients: (data: AllEntitiesByPagesDto) =>
        createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS, data),
    getAllRecurringArchivedProviderAllPatientsSuccess: (data: {
        result: InvoiceModel[];
        total: number;
        totalAmount: number;
    }) => createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS_SUCCESS, data),
    getAllRecurringArchivedProviderAllPatientsFailed: () =>
        createAction(ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS_FAILED),

    increasePageNumberRecurringArchived: () => createAction(ActionNames.INCREASE_PAGE_NUMBER_RECURRING_ARCHIVED),

    resetArchived: () => createAction(ActionNames.RESET_ARCHIVED),

    deleteInvoice: (data: DeleteInvoiceDto) => createAction(ActionNames.DELETE_INVOICE, data),
    deleteInvoiceSuccess: () => createAction(ActionNames.DELETE_INVOICE_SUCCESS),
    deleteInvoiceFailed: () => createAction(ActionNames.DELETE_INVOICE_FAILED),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),

    addInvoice: (data: AddInvoiceDto) => createAction(ActionNames.ADD_INVOICE, data),
    addInvoiceSuccess: () => createAction(ActionNames.ADD_INVOICE_SUCCESS),
    addInvoiceFailed: () => createAction(ActionNames.ADD_INVOICE_FAILED),

    editInvoice: (data: EditInvoiceDto) => createAction(ActionNames.EDIT_INVOICE, data),
    editInvoiceSuccess: () => createAction(ActionNames.EDIT_INVOICE_SUCCESS),
    editInvoiceFailed: () => createAction(ActionNames.EDIT_INVOICE_FAILED),

    rejectInvoice: (id: number) => createAction(ActionNames.REJECT_INVOICE, id),
    rejectInvoiceSuccess: () => createAction(ActionNames.REJECT_INVOICE_SUCCESS),
    rejectInvoiceFailed: () => createAction(ActionNames.REJECT_INVOICE_FAILED),

    getRequestedInvoiceById: (data: GetInvoiceProviderDto) =>
        createAction(ActionNames.GET_REQUESTED_INVOICE_BY_ID, data),
    getRequestedInvoiceByIdSuccess: (data: InvoiceModel) =>
        createAction(ActionNames.GET_REQUESTED_INVOICE_BY_ID_SUCCESS, data),
    getRequestedInvoiceByIdFailed: () => createAction(ActionNames.GET_REQUESTED_INVOICE_BY_ID_FAILED),

    generateState: () => createAction(ActionNames.GENERATE_STATE),
    generateStateSuccess: (data: GenerateStateResponce) => createAction(ActionNames.GENERATE_STATE_SUCCESS, data),
    generateStateFailed: () => createAction(ActionNames.GENERATE_STATE_FAILED),

    connectOAuth: (data: ConnectOauthDto) => createAction(ActionNames.CONNECT_OAUTH, data),
    connectOAuthSuccess: () => createAction(ActionNames.CONNECT_OAUTH_SUCCESS),
    connectOAuthFailed: () => createAction(ActionNames.CONNECT_OAUTH_FAILED),

    payInvoice: (data: PayInvoiceDto) => createAction(ActionNames.PAY_INVOICE, data),
    payInvoiceSuccess: (data: PaymentSessionIdResponse) => createAction(ActionNames.PAY_INVOICE_SUCCESS, data),
    payInvoiceFailed: () => createAction(ActionNames.PAY_INVOICE_FAILED),

    subscribeInvoice: (data: PayInvoiceDto) => createAction(ActionNames.SUBSCRIBE_INVOICE, data),
    subscribeInvoiceSuccess: (data: InvoiceSubscriptionModel) =>
        createAction(ActionNames.SUBSCRIBE_INVOICE_SUCCESS, data),
    subscribeInvoiceFailed: () => createAction(ActionNames.SUBSCRIBE_INVOICE_FAILED),

    unsubscribeInvoice: (invoiceId: number) => createAction(ActionNames.UNSUBSCRIBE_INVOICE, invoiceId),
    unsubscribeInvoiceSuccess: () => createAction(ActionNames.UNSUBSCRIBE_INVOICE_SUCCESS),
    unsubscribeInvoiceFailed: () => createAction(ActionNames.UNSUBSCRIBE_INVOICE_FAILED),

    getConnectedAccountLink: () => createAction(ActionNames.GET_CONNECTED_ACCOUNT_LINK),
    getConnectedAccountLinkSuccess: (data: ConnectedAccountLinkResponce) =>
        createAction(ActionNames.GET_CONNECTED_ACCOUNT_LINK_SUCCESS, data),
    getConnectedAccountFailed: () => createAction(ActionNames.GET_CONNECTED_ACCOUNT_LINK_FAILED),

    verifyMerchant: () => createAction(ActionNames.VERIFY_MERCHANT),
    verifyMerchantSuccess: (data: MerchantVerification) => createAction(ActionNames.VERIFY_MERCHANT_SUCCESS, data),
    verifyMerchantFailed: () => createAction(ActionNames.VERIFY_MERCHANT_FAILED),

    getOneTimeRequestedPatientCount: () => createAction(ActionNames.GET_ONE_TIME_REQUESTED_PATIENT_COUNT),
    getOneTimeRequestedPatientCountSuccess: (data: InvocieCountResponse) =>
        createAction(ActionNames.GET_ONE_TIME_REQUESTED_PATIENT_COUNT_SUCCESS, data),

    getInvoicesCountProviderAllPatients: () => createAction(ActionNames.GET_ALL_INVOICES_COUNT_PROVIDER_ALL_PATIENTS),
    getInvoicesCountProviderAllPatientsSuccess: (data: InvocieCountResponse) =>
        createAction(ActionNames.GET_ALL_INVOICES_COUNT_PROVIDER_ALL_PATIENTS_SUCCESS, data),

    getInvoicesCountProviderPatient: (patientId: number) =>
        createAction(ActionNames.GET_ALL_INVOICES_COUNT_PROVIDER_PATIENT, patientId),
    getInvoicesCountProviderPatientSuccess: (data: InvocieCountResponse) =>
        createAction(ActionNames.GET_ALL_INVOICES_COUNT_PROVIDER_PATIENT_SUCCESS, data),

    getInvoiceById: (invoiceId: number) => createAction(ActionNames.GET_INVOICE_BY_ID, invoiceId),
    getInvoiceByIdSuccess: (invoice: InvoiceModel) => createAction(ActionNames.GET_INVOICE_BY_ID_SUCCESS, invoice),
    getInvoiceByIdFailed: () => createAction(ActionNames.GET_INVOICE_BY_ID_FAILED),

    getInvoiceByIdProvider: (data: GetInvoiceProviderDto) => createAction(ActionNames.GET_INVOICE_BY_ID_PROVIDER, data),
    getInvoiceByIdProviderSuccess: (invoice: InvoiceModel) =>
        createAction(ActionNames.GET_INVOICE_BY_ID_PROVIDER_SUCCESS, invoice),
    getInvoiceByIdProviderFailed: () => createAction(ActionNames.GET_INVOICE_BY_ID_PROVIDER_FAILED),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllOneTimeRequestedPatientType = ActionsOfType<Actions, ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT>;
export type getAllOneTimeArchivedPatientType = ActionsOfType<Actions, ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT>;
export type getAllRecurringArchivedPatientType = ActionsOfType<Actions, ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT>;
export type getAllOneTimeRequestedProviderType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER
>;
export type getAllOneTimeArchivedProviderType = ActionsOfType<Actions, ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER>;
export type getAllRecurringRequestedProviderType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER
>;
export type getAllRecurringArchivedProviderType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER
>;
export type getAllOneTimeArchivedProviderAllPatientsType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS
>;
export type getAllRecurringArchivedProviderAllPatientsType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS
>;
export type getAllRecurringSubscriptionProviderAllPatientsRequestedType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED
>;
export type getAllRecurringSubscriptionProviderAllPatientsActiveType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE
>;
export type getAllOneTimeRequestedProviderAllPatientsType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS
>;
export type deleteInvoiceType = ActionsOfType<Actions, ActionNames.DELETE_INVOICE>;
export type addInvoiceType = ActionsOfType<Actions, ActionNames.ADD_INVOICE>;
export type editInvoiceType = ActionsOfType<Actions, ActionNames.EDIT_INVOICE>;
export type rejectInvoiceType = ActionsOfType<Actions, ActionNames.REJECT_INVOICE>;
export type getRequestedInvoiceByIdType = ActionsOfType<Actions, ActionNames.GET_REQUESTED_INVOICE_BY_ID>;
export type connectOAuthType = ActionsOfType<Actions, ActionNames.CONNECT_OAUTH>;
export type payInvoiceType = ActionsOfType<Actions, ActionNames.PAY_INVOICE>;
export type subscribeInvoiceType = ActionsOfType<Actions, ActionNames.SUBSCRIBE_INVOICE>;
export type unsubscribeInvoiceType = ActionsOfType<Actions, ActionNames.UNSUBSCRIBE_INVOICE>;
export type getInvoicesCountProviderPatientType = ActionsOfType<
    Actions,
    ActionNames.GET_ALL_INVOICES_COUNT_PROVIDER_PATIENT
>;
export type getInvoicesByIdType = ActionsOfType<Actions, ActionNames.GET_INVOICE_BY_ID>;
export type getInvoicesByIdProviderType = ActionsOfType<Actions, ActionNames.GET_INVOICE_BY_ID_PROVIDER>;
