import React, { useCallback, useEffect, useState } from 'react';
import ProfileHeader from './components/ProfileHeader';
import ProfileInfoProvider from './components/ProfileInfoProvider';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as ProviderActions } from '../../store/actions/provider.actions';
import { ProviderModel } from '../../models/responses/provider.response';
import Loading from '../shared/Loading';
import LoadingTransparent from '../shared/LoadingTransparent';
import providerAvatar from '../../static/images/provider/providerAvatar.svg';
import { RouteComponentProps, withRouter } from 'react-router';
import ChangeCurrentPasswordSnackbar from '../changePassword/ChnageCurrentPasswordSnackbar';

type DispatchModel = {
    getProvider: typeof ProviderActions.getProvider;
    updateProvider: typeof ProviderActions.updateProvider;
    resetStatusVariables: typeof ProviderActions.resetStatusVariables;
};

type StoreModel = {
    provider: ProviderModel | null;
    loading: boolean;
    updating: boolean;
};

type PropsTypes = RouteComponentProps<any> & StoreModel & DispatchModel;

const ProfileProvider: React.FC<PropsTypes> = props => {
    const { loading, resetStatusVariables, history, updating, provider, getProvider, updateProvider, match } = props;
    const [editMode, setEditMode] = useState(match.params.edit === 'editOn');

    useEffect(() => {
        getProvider();
    }, [getProvider]);

    const editModeOn = () => {
        history.push('/profile/editOn');
        window.scrollTo(0, 0);
        setEditMode(true);
    };

    const editModeOff = useCallback(() => {
        setEditMode(false);
    }, []);

    return (
        <>
            {updating && <LoadingTransparent />}
            {loading && <Loading />}
            {!loading && provider && (
                <>
                    <ProfileHeader
                        avatar={provider && provider.avatarThumbnailUrl ? provider.avatarThumbnailUrl : providerAvatar}
                        isDefaultAvatar={provider && !provider.avatarThumbnailUrl}
                        editMode={editMode}
                        name={`${provider.firstName} ${provider.lastName}`}
                        text={'My Provider Profile'}
                    />
                    <ProfileInfoProvider
                        provider={provider}
                        updateProvider={updateProvider}
                        editMode={editMode}
                        setEditModeOn={editModeOn}
                        setEditModeOff={editModeOff}
                        resetStatusVariables={resetStatusVariables}
                        connectedAccountLink={''}
                    />
                </>
            )}
            <ChangeCurrentPasswordSnackbar />
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        loading: state.provider.loading,
        updating: state.provider.updating,
        provider: state.provider.currentProvider,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getProvider: bindActionCreators(ProviderActions.getProvider, dispatch),
        updateProvider: bindActionCreators(ProviderActions.updateProvider, dispatch),
        resetStatusVariables: bindActionCreators(ProviderActions.resetStatusVariables, dispatch),
    })
)(withRouter(ProfileProvider));
