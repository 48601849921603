import { InputType, IRenderableFormField } from './form';
import TextInput from '../../../../shared/input/TextInput';
import React, { ReactNode, useCallback } from 'react';
import { FormControlLabel, makeStyles, Radio, RadioGroup, RadioProps } from '@material-ui/core';
import { FormConfigurationError } from './formConfiguration.error';
import SmartValueInput from '../../../../shared/input/SmartValueInput';

export interface IConfig<ValueType> {
    type: InputType;
    fieldData: IRenderableFormField;
    readonly: boolean;
    isValid?: boolean;
    onChange: (fieldValue: string) => void;
}

const useStyles = makeStyles({
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        width: 16,
        height: 16,
    },
    defaultInput: {
        width: '90%',
    },
});
function StyledRadio(props: RadioProps) {
    const classes = useStyles();

    return (
        <Radio
            classes={{
                checked: classes.checkedIcon,
            }}
            disableRipple
            color="primary"
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export function InputTypeSelector<T>(config: IConfig<T>): ReactNode {
    const { fieldData, onChange, readonly = false, isValid = true } = config;

    const onValueChange = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>) => {
            onChange(evt.target.value);
        },
        [onChange]
    );

    switch (config.type) {
        case InputType.DROPDOWN:
            return 'Not implemented yet :(';
        case InputType.STRING:
            return (
                <TextInput
                    error={!isValid}
                    disabled={readonly}
                    style={{ width: '90%' }}
                    onChange={onValueChange}
                    placeholder={fieldData.placeholder || ''}
                    value={fieldData.value}
                />
            );
        case InputType.NUMBER:
            return (
                <>
                    <SmartValueInput
                        fieldData={fieldData}
                        isValid={isValid}
                        readonly={readonly}
                        onChange={onChange}
                    />
                </>
            );
        case InputType.SELECT:
            break;
        case InputType.RADIO: {
            if (!fieldData.radioButtonValues || !fieldData.radioButtonValues.length) {
                throw new FormConfigurationError(
                    `Expected fieldData.radioButtonValues to be present, got: ${fieldData.radioButtonValues}`
                );
            }
            return (
                <RadioGroup
                    style={{ marginLeft: 20, marginTop: -5 }}
                    row
                    value={fieldData.value}
                    onChange={onValueChange}
                >
                    {fieldData.radioButtonValues.map(({ value, label }) => (
                        <FormControlLabel
                            disabled={readonly}
                            color="primary"
                            value={value}
                            control={<StyledRadio />}
                            style={{ minWidth: 70 }}
                            label={label}
                            key={value}
                        />
                    ))}
                </RadioGroup>
            );
        }
    }
}
