import React, { useCallback, useEffect, useState } from 'react';
import ProfileHeader from './components/ProfileHeader';
import ProfileInfoPatient from './components/ProfileInfoPatient';
import { PatientModel } from '../../models/responses/patient.response';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import { Actions as AuthActions } from '../../store/actions/auth.actions';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../shared/Loading';
import LoadingTransparent from '../shared/LoadingTransparent';
import RequestState from '../../constants/requestState';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserModel } from '../../models/responses/user.response';
import defaultAvatar from '../../static/icons/defaultAvatar.svg';
import ChangeCurrentPasswordSnackbar from '../changePassword/ChnageCurrentPasswordSnackbar';
import { PatientStepClassName } from '../tour/constants/patientStepsClassNames';

type StoreModel = {
    patient: PatientModel | null;
    loading: boolean;
    updating: RequestState;
    user: UserModel | null;
};

type DispatchModel = {
    getPatient: typeof PatientActions.getPatient;
    updatePatient: typeof PatientActions.updatePatient;
    setIsSetupToTrue: typeof PatientActions.setIsSetupToTrue;
    resetStatusVariables: typeof PatientActions.resetStatusVariables;
    setCurrentUser: typeof AuthActions.setCurrentUser;
};

type PropsTypes = RouteComponentProps<any> & StoreModel & DispatchModel;

const ProfilePatient: React.FC<PropsTypes> = props => {
    const {
        loading,
        resetStatusVariables,
        user,
        patient,
        getPatient,
        setCurrentUser,
        setIsSetupToTrue,
        updating,
        updatePatient,
        match,
        history,
    } = props;
    const [editMode, setEditMode] = useState(match.params.edit === 'editOn');

    useEffect(() => {
        getPatient();
    }, [getPatient]);

    const editModeOn = useCallback(() => {
        history.push('/profile/editOn');
        window.scrollTo(0, 0);
        setEditMode(true);
    }, [history]);

    const editModeOff = useCallback(() => {
        setEditMode(false);
    }, []);

    return (
        <>
            {updating === RequestState.SENDING && <LoadingTransparent />}
            {loading && <Loading />}
            {!loading && patient && (
                <>
                    <div className={PatientStepClassName.PROFILE}>
                        <ProfileHeader
                            avatar={patient && patient.avatarThumbnailUrl ? patient.avatarThumbnailUrl : defaultAvatar}
                            isDefaultAvatar={patient && !patient.avatarThumbnailUrl}
                            editMode={editMode}
                            name={`${patient.firstName} ${patient.lastName}`}
                            text={'My Patient Profile'}
                        />
                    </div>
                    <ProfileInfoPatient
                        currentPatient={patient}
                        updatePatient={updatePatient}
                        setCurrentUser={setCurrentUser}
                        editMode={editMode}
                        setEditModeOn={editModeOn}
                        setEditModeOff={editModeOff}
                        updating={updating}
                        setIsSetupToTrue={setIsSetupToTrue}
                        user={user}
                        resetStatusVariables={resetStatusVariables}
                    />
                </>
            )}
            <ChangeCurrentPasswordSnackbar />
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        loading: state.patient.loading,
        updating: state.patient.updating,
        patient: state.patient.patient,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getPatient: bindActionCreators(PatientActions.getPatient, dispatch),
        setCurrentUser: bindActionCreators(AuthActions.setCurrentUser, dispatch),
        updatePatient: bindActionCreators(PatientActions.updatePatient, dispatch),
        setIsSetupToTrue: bindActionCreators(PatientActions.setIsSetupToTrue, dispatch),
        resetStatusVariables: bindActionCreators(PatientActions.resetStatusVariables, dispatch),
    })
)(withRouter(ProfilePatient));
