import React, {useCallback, useEffect, useRef, useState} from 'react';
import ProviderListItem from './ProviderListItem';
import { ProviderModel } from '../../../models/responses/provider.response';
import { List, makeStyles, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlphabetLetter from './AlphabetLetter';
import Loading from '../../shared/Loading';
import { colors } from '../../../theme/colors';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../../constants/layout';
import {ifStringEquals} from "../../../helpers/stringFilterHelper";

const useStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '16px',
        color: colors.textSecondary,
        marginTop: '20px',
    },
});

interface InjectedProps {
    loading: boolean;
    allProviders: ProviderModel[];
    totalProviders: number | null;
    page: number;
    increasePageNumber: any;
}

type PropsTypes = InjectedProps;

const ProvidersList: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);
    let latestLetter: string | null = null;

    const {
        increasePageNumber,
        allProviders,
        loading,
        page,
        totalProviders,
    } = props;

    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    const getMoreProviders = useCallback(() => {
        increasePageNumber();
    },[increasePageNumber]);

    return (
        <>
            {!!allProviders.length && (
                <List classes={{ root: classes.list }} ref={listRef}>
                    <InfiniteScroll
                        dataLength={allProviders.length}
                        next={getMoreProviders}
                        height={height}
                        hasMore={totalProviders ? allProviders.length < totalProviders : false}
                        loader={<Loading smallSize />}
                        className={classes.scrollContainer}
                    >
                        {allProviders.map((provider, index) => {
                            let should = false;
                            if (!latestLetter || !ifStringEquals(latestLetter, provider.lastName[0])) {
                                should = true;
                                latestLetter = provider.lastName[0];
                            }
                            return (
                                <div key={index}>
                                    {should && <AlphabetLetter>{provider.lastName[0]}</AlphabetLetter>}
                                    <ProviderListItem key={index} provider={provider} />
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                </List>
            )}

            {!loading && allProviders.length === 0 && (
                <SizeControllerWrapper>
                    <Typography variant="body1" classes={{ root: classes.noData }}>
                        No data found.
                    </Typography>
                </SizeControllerWrapper>
            )}

            {loading && page === 0 && <Loading smallSize />}
        </>
    );
};

export default ProvidersList;
