import React, { useEffect, useRef } from 'react';
import Snackbar, { SnackbarTypes } from '../../shared/snackbars/snackbar';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import RequestState from '../../constants/requestState';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as AuthActions } from '../../store/actions/auth.actions';

interface StoreModel {
    changeCurrentPasswordLoading: RequestState;
}

interface DispatchModel {
    resetStatusVariables: typeof AuthActions.resetStatusVariables;
}

const ChangeCurrentPasswordSnackbar: React.FC<StoreModel & DispatchModel> = ({
    resetStatusVariables,
    changeCurrentPasswordLoading,
}) => {
    const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null);

    useEffect(() => {
        if (changeCurrentPasswordLoading === RequestState.SENT_SUCCESS) {
            timerRef.current = setTimeout(() => {
                resetStatusVariables();
            }, 5000);
        }
    }, [changeCurrentPasswordLoading, resetStatusVariables]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [resetStatusVariables]);

    return (
        <Snackbar
            message={'The password has been changed'}
            open={changeCurrentPasswordLoading === RequestState.SENT_SUCCESS}
            variant={'success'}
            type={SnackbarTypes.SUCCESS}
            onClose={resetStatusVariables}
        />
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        changeCurrentPasswordLoading: store.authorization.changeCurrentPasswordLoading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(AuthActions.resetStatusVariables, dispatch),
    })
)(ChangeCurrentPasswordSnackbar);
