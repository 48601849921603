import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import * as fromRoot from './reducers/index';
import rootSaga from "./sagas";
import thunk from 'redux-thunk';

const sagaMiddleware = createSagaMiddleware();
function configureStore(initialState?: fromRoot.RootStateModel) {

    const composeEnhancers = composeWithDevTools({});

    const enhancer = composeEnhancers(
        applyMiddleware(sagaMiddleware, thunk)
    );

    return createStore(fromRoot.reducers, initialState, enhancer);
}

const store = configureStore();
sagaMiddleware.run(rootSaga);

export default store;
