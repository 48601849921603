import { IsString, MaxLength, MinLength } from 'class-validator';

export class ChangePasswordDto {
    @IsString()
    @MinLength(8, { message: 'Password must be at least 8 characters' })
    @MaxLength(30, { message: 'Password is too long' })
    currentPassword: string;

    @IsString()
    @MinLength(8, { message: 'Password must be at least 8 characters' })
    @MaxLength(30, { message: 'Password is too long' })
    newPassword: string;

    @IsString()
    newPasswordConfirm: string;
}
