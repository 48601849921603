import React, { useCallback, useEffect, useState } from 'react';
import HeaderHeading from '../../shared/HeaderHeading';
import { GridList, GridListTile, makeStyles, Typography } from '@material-ui/core';
import providerAvatar from '../../static/icons/admin/providerAvatar.svg';
import providersActive from '../../static/icons/admin/providersActive.svg';
import providersCreated from '../../static/icons/admin/providersCreated.svg';
import patientsCreated from '../../static/icons/admin/patientsCreated.svg';
import patientsActive from '../../static/icons/admin/patientsActive.svg';
import patientAvatar from '../../static/icons/admin/patientAvatar.svg';
import bloodworkIcon from '../../static/icons/bloodwork.svg';
import dosageIcon from '../../static/icons/dosage.svg';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { Actions as AdminStatisticsActions } from '../../store/actions/adminStatistics';
import {AdminStatisticsModel, StatisticsTiles} from '../../models/responses/adminStatistics';
import { bindActionCreators, Dispatch } from 'redux';
import LoadingTransparent from '../shared/LoadingTransparent';

const TileWrapper = styled.div`
    position: relative;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Wrapper = styled.div`
    background: ${colors.gray2};
    padding: 22px 12px;
`;

const FeeBlock = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: -8px;
`;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: '100%',
        height: 'auto',
        margin: '0 !important',
    },
    gridListItem: {},
    tile: {
        backgroundColor: colors.white,
        borderRadius: '30px',
    },
    icon: {
        width: '70px',
        height: '70px',
        [theme.breakpoints.up('md')]: {
            width: '80px',
            height: '80px',
        },
    },
    quantity: {
        display: 'block',
        fontSize: '34px',
        color: colors.redMain,
    },
    quantityBig: {
        display: 'block',
        fontSize: '50px',
        color: colors.redMain,
        marginBottom: '8px',
    },
    text: {
        display: 'block',
        color: colors.textSecondary,
        marginTop: '8px',
        textTransform: 'capitalize',
    },
    textBig: {
        fontSize: '18px',
        display: 'block',
        color: colors.textSecondary,
    },
    periodText: {
        display: 'block',
        fontSize: '12px',
        marginTop: '-6px',
        color: colors.textSecondary,
    },
    gridListItemTop: {
        [theme.breakpoints.up('md')]: {
            marginBottom: '16px',
        },
    },
    topTile: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

interface StoreModel {
    statistics: AdminStatisticsModel | null;
    statisticsLoading: boolean;
}

interface DispatchModel {
    getAdminStatistics: typeof AdminStatisticsActions.getAdminStatistics;
}

type PropTypes = StoreModel & DispatchModel & RouteComponentProps<any>;


const emptyTileData : StatisticsTiles = {
    activePatients: {
        quantity: 0,
        icon: patientsActive,
        text: 'active patients',
        period: 'last 7 days',
    },
    activeProviders: {
        quantity: 0,
        icon: providersActive,
        text: 'active providers',
        period: 'last 7 days',
    },
    totalPatients: {
        quantity: 0,
        icon: patientAvatar,
        text: 'Total patients',
    },
    totalProviders: {
        quantity: 0,
        icon: providerAvatar,
        text: 'Total providers',
    },
    patientsCreated: {
        quantity: 0,
        icon: patientsCreated,
        text: 'patients created',
        period: 'last 7 days',
    },
    providersCreated: {
        quantity: 0,
        icon: providersCreated,
        text: 'providers created',
        period: 'last 7 days',
    },
    bloodWorksCreated: {
        quantity: 0,
        icon: bloodworkIcon,
        text: 'bloodworks created',
        period: 'last 7 days',
    },
    dosagesCreated: {
        quantity: 0,
        icon: dosageIcon,
        text: 'dosages created',
        period: 'last 7 days',
    },
};

const AdminStatistics: React.FC<PropTypes> = props => {
    const classes = useStyles(props);
    const { getAdminStatistics, statistics, statisticsLoading } = props;
    const [tileData, setTileData] = useState<StatisticsTiles>(emptyTileData);

    useEffect(() => {
        getAdminStatistics();
    }, [getAdminStatistics]);

    useEffect(() => {
        if (statistics && tileData === emptyTileData) {
            let tiles = { ...tileData };
            Object.keys(statistics).forEach((key : any) => {
                if (tiles[key]) {
                    // @ts-ignore
                    tiles[key].quantity = Number(statistics[key]);
                }
            });
            setTileData(tiles);
        }
    }, [statistics, tileData]);

    const isMobile = useMediaQuery(mediaQuery.mobileL);

    const goToStripe = useCallback(() => {
        window.open('https://dashboard.stripe.com/', '_blank');
    }, []);

    return (
        <>
            <HeaderHeading>Admin Dashboard</HeaderHeading>
            {statisticsLoading && <LoadingTransparent />}
            {statistics && (
                <Wrapper>
                    <GridList cellHeight={120} spacing={24} className={classes.gridList} cols={isMobile ? 2 : 1}>
                        <GridListTile classes={{ root: classes.gridListItemTop, tile: classes.topTile }}>
                            <Typography variant={'body1'} classes={{ root: classes.textBig }}>
                                Go to Stripe Statistics
                            </Typography>
                            <PrimaryButton onClick={goToStripe}>Stripe Dashboard</PrimaryButton>
                        </GridListTile>
                        <GridListTile classes={{ tile: classes.topTile }}>
                            <Typography variant={'body1'} classes={{ root: classes.textBig }}>
                                Payments Fee{' '}
                            </Typography>
                            <FeeBlock>
                                <div>
                                    <Typography variant={'body2'} classes={{ root: classes.quantityBig }}>
                                        {statistics.subscriptionPercentFee}%
                                    </Typography>
                                    <Typography variant={'body1'} classes={{ root: classes.periodText }}>
                                        Subscription
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant={'body2'} classes={{ root: classes.quantityBig }}>
                                        {statistics.oneTimePercentFee}%
                                    </Typography>
                                    <Typography variant={'body1'} classes={{ root: classes.periodText }}>
                                        One-Time
                                    </Typography>
                                </div>
                            </FeeBlock>
                        </GridListTile>
                        {Object.keys(tileData).map(key => (
                            <GridListTile
                                key={tileData[key].icon}
                                classes={{ root: classes.gridListItem, tile: classes.tile }}
                            >
                                <TileWrapper>
                                    <div>
                                        <Typography variant={'body2'} classes={{ root: classes.quantity }}>
                                            {tileData[key].quantity}
                                        </Typography>
                                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                                            {tileData[key].text}
                                        </Typography>
                                        <Typography variant={'body1'} classes={{ root: classes.periodText }}>
                                            {tileData[key].period}
                                        </Typography>
                                    </div>
                                    <img src={tileData[key].icon} className={classes.icon} alt={''} />
                                </TileWrapper>
                            </GridListTile>
                        ))}
                    </GridList>
                </Wrapper>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        statistics: state.adminStatistics.statistics,
        statisticsLoading: state.adminStatistics.loadingStatistics,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAdminStatistics: bindActionCreators(AdminStatisticsActions.getAdminStatistics, dispatch),
    })
)(withRouter(AdminStatistics));
