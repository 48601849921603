import addNewIcon from '../../static/icons/addNewIcon.svg';
import { Fab, makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../../theme/colors';
import theme from '../../theme/theme';
import { withRouter, RouteComponentProps } from 'react-router';

const createStyles = makeStyles({
    fab: {
        position: 'fixed',
        right: '15px',
        bottom: '3.5%',
        backgroundColor: colors.redMain,
        boxShadow: theme.boxShadowRed,
        '&:hover': {
            backgroundColor: colors.redMain,
        },
        '&:active': {
            backgroundColor: colors.redMain,
            boxShadow: theme.boxShadowRed,
        },
    },
});

interface InjectedProps {
    redirectUrl: string;
    activeTab?: number;
}

type PropsTypes = InjectedProps & RouteComponentProps<any>;

const FabButton: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    return (
        <Fab classes={{ root: classes.fab }} onClick={() => props.history.push(props.redirectUrl, {activeTab: props.activeTab})}>
            <img src={addNewIcon} alt="" />
        </Fab>
    );
};

export default withRouter(FabButton);
