import { put, call, takeEvery } from 'redux-saga/effects';
import * as analyticsActions from '../../actions/analytics.actions';
import AnalyticsApi from '../../../api/analytics/AnalyticsApi';
import { typedCall } from '../../../shared/utils/typedYieldWrapper';

function* getAllLabs(action: analyticsActions.getAnalyticsPatientType) {
    try {
        const value = yield call(AnalyticsApi.getAnalyticsPatient, action.payload);
        const response = typedCall(AnalyticsApi.getAnalyticsPatient, value);
        yield put({ type: analyticsActions.ActionNames.GET_ANALYTICS_PATIENT_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: analyticsActions.ActionNames.GET_ANALYTICS_PATIENT_FAILED });
    }
}

export default function* AnalyticsSagas() {
    yield takeEvery(analyticsActions.ActionNames.GET_ANALYTICS_PATIENT, getAllLabs);
}
