import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { FormControl, makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../shared/button/PrimaryButton';
import TextInput from '../../shared/input/TextInput';
import styled from 'styled-components';
import { ValidationErrorsMap } from '../../models/validation/validationErrorsMap';
import { useDebounce } from 'use-debounce';
import RenderErrorShared from '../shared/RenderErrorShared';
import fillClassInstanceWithValues from '../../shared/utils/fillClassInstanceWithValues';
import { validateSync } from 'class-validator';
import { ChangePasswordDto } from '../../models/validation/changePasswordValidation';
import RequestState from '../../constants/requestState';
import { Actions as AuthorizationActions } from '../../store/actions/auth.actions';
import LoadingTransparent from '../shared/LoadingTransparent';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { UserModel } from '../../models/responses/user.response';

const InputWrapper = styled(FormControl)`
    padding-bottom: 25px !important;
`;
const ButtonWrapper = styled.div`
    width: fit-content;
    margin: 20px auto 0;
`;

const createStyles = makeStyles({
    heading: {
        fontSize: '24px',
        textAlign: 'center',
        margin: '38px 0 18px',
    },
});

const changePasswordInitialState = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    isBeingEdited: new Set(),
};

interface StoreModel {
    changeCurrentPasswordLoading: RequestState;
    user: UserModel | null;
}

interface DispatchModel {
    changeCurrentPassword: typeof AuthorizationActions.changeCurrentPassword;
}

const ChangePassword: React.FC<StoreModel & DispatchModel & RouteComponentProps<any>> = props => {
    const classes = createStyles(props);
    const { changeCurrentPassword, changeCurrentPasswordLoading, history } = props;

    const [form, setForm] = useState(changePasswordInitialState);
    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    const changeForm = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newForm = {
                ...form,
                [e.target.id]: e.target.value,
                isBeingEdited: form.isBeingEdited.add(e.target.id),
            };
            if (e.target.value.length === 0) {
                newForm.isBeingEdited.delete(e.target.id);
            }
            setForm(newForm);
        },
        [form]
    );

    const isConfirmPasswordValid = useMemo(() => {
        if (form.newPassword.length && form.newPasswordConfirm.length) {
            return form.newPassword === form.newPasswordConfirm;
        }
        return true;
    }, [form]);

    const validateForm = useCallback(
        (data: any, isOnSubmit = false) => {
            const item = fillClassInstanceWithValues<ChangePasswordDto>(ChangePasswordDto, data);
            const validationErrors = validateSync(item);
            const map: ValidationErrorsMap = {};
            if (validationErrors.length) {
                validationErrors.forEach(err => {
                    if (isOnSubmit || data.isBeingEdited.has(err.property)) {
                        map[err.property] = Object.entries(err.constraints)
                            .map(([, value]) => value)
                            .join(', ');
                    }
                });
            }
            const newMap = isConfirmPasswordValid ? map : { ...map, newPasswordConfirm: 'Passwords should be equal' };
            setValidationErrorsMap(newMap);
            return newMap;
        },
        [isConfirmPasswordValid]
    );

    useEffect(() => {
        validateForm(form);
    }, [form, validateForm]);

    const isValid = useMemo(() => {
        const map = validateForm(form, true);
        return Object.entries(map).length === 0;
    }, [form, validateForm]);

    const sendForm = useCallback(() => {
        if (isValid) {
            changeCurrentPassword({ currentPassword: form.currentPassword, newPassword: form.newPassword });
        }
    }, [isValid, changeCurrentPassword, form]);

    useEffect(() => {
        if (changeCurrentPasswordLoading === RequestState.SENT_SUCCESS) {
            history.push('/profile');
        }
    }, [changeCurrentPasswordLoading, history]);

    useEffect(() => {
        setForm({ ...changePasswordInitialState, isBeingEdited: new Set() });
    }, []);

    return (
        <>
            {changeCurrentPasswordLoading === RequestState.SENDING && <LoadingTransparent />}

            <SizeControllerWrapper>
                <Typography variant="h2" classes={{ root: classes.heading }}>
                    Change Password
                </Typography>
                <form>
                    <InputWrapper error={!!validation.password}>
                        <TextInput
                            placeholder={'Current Password'}
                            value={form.currentPassword}
                            onChange={e => changeForm(e)}
                            inputProps={{ id: 'currentPassword' }}
                            error={!!validation.currentPassword}
                            password
                        />
                        {!!validation.currentPassword && (
                            <RenderErrorShared field={'currentPassword'} validationErrorsMap={validationErrorsMap} />
                        )}
                    </InputWrapper>
                    <InputWrapper error={!!validation.password}>
                        <TextInput
                            placeholder={'New Password'}
                            value={form.newPassword}
                            onChange={changeForm}
                            inputProps={{ id: 'newPassword' }}
                            error={!!validation.newPassword}
                            password
                        />
                        {!!validation.newPassword && (
                            <RenderErrorShared field={'newPassword'} validationErrorsMap={validationErrorsMap} />
                        )}
                    </InputWrapper>
                    <InputWrapper error={!!validation.passwordConfirm}>
                        <TextInput
                            placeholder={'Confirm New Password'}
                            value={form.newPasswordConfirm}
                            onChange={changeForm}
                            inputProps={{ id: 'newPasswordConfirm' }}
                            error={!!validation.newPasswordConfirm}
                            password
                        />
                        {!!validation.newPasswordConfirm && (
                            <RenderErrorShared field={'newPasswordConfirm'} validationErrorsMap={validationErrorsMap} />
                        )}
                    </InputWrapper>
                </form>
                <ButtonWrapper>
                    <PrimaryButton onClick={sendForm}>Update Password</PrimaryButton>
                </ButtonWrapper>
            </SizeControllerWrapper>
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        changeCurrentPasswordLoading: store.authorization.changeCurrentPasswordLoading,
        user: store.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        changeCurrentPassword: bindActionCreators(AuthorizationActions.changeCurrentPassword, dispatch),
    })
)(withRouter(ChangePassword));
