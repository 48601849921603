import React, { useCallback } from 'react';
import TextInput from '../../shared/input/TextInput';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import removeRowIcon from '../../static/icons/removeRow.svg';
import {LabRow} from "./AddLabs";
import PhoneNumberInput from "../../shared/input/PhoneNumberInput";

export enum FormFields {
    name = 'name',
    website = 'website',
    streetAddress1 = 'streetAddress1',
    streetAddress2 = 'streetAddress2',
    city = 'city',
    state = 'state',
    zip = 'zip',
    phone = 'phone',
    email = 'email',
}

const createStyles = makeStyles({
    cell: {
        minWidth: '125px',
    },
    iconRow: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
});

interface InjectedProps {
    changeRow: (filedName: string, value: string, id: number) => void;
    removeRow: (id: number) => void;
    canRemoveRow: boolean;
    row: LabRow;
}

const AddLabRow: React.FC<InjectedProps> = props => {
    const classes = createStyles(props);

    const { canRemoveRow, removeRow, changeRow, row } = props;

    const changeForm = (fieldName: string, value: string) => {
        changeRow(fieldName, value, row.id)
    };

    const remove = useCallback(() => {
        removeRow(row.id);
    }, [row, removeRow]);

    return (
        <TableRow>
            <TableCell>
                {canRemoveRow && <img src={removeRowIcon} alt="" className={classes.iconRow} onClick={remove} />}
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Name'}
                    value={row.name}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.name, evt.target.value)
                    }
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Website'}
                    value={row.website}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.website, evt.target.value)
                    }
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <PhoneNumberInput
                    placeholder={'+ (000) 000.0000'}
                    value={row.phone}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        changeForm(FormFields.phone, evt.target.value)
                    }}
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Email'}
                    value={row.email}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.email, evt.target.value)
                    }
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Street Address 1'}
                    value={row.streetAddress1}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.streetAddress1, evt.target.value)
                    }
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Street Address2'}
                    value={row.streetAddress2}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.streetAddress2, evt.target.value)
                    }
                />
            </TableCell>

            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'City'}
                    value={row.city}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.city, evt.target.value)
                    }
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'State'}
                    value={row.state}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.state, evt.target.value)
                    }
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Zip'}
                    value={row.zip}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.zip, evt.target.value)
                    }
                />
            </TableCell>
        </TableRow>
    );
};

export default AddLabRow;
