import React, { useEffect, useMemo } from 'react';
import FabButton from '../../shared/button/FabButton';
import BloodTargetsSearchPage from '../bloodTarget/BloodTargetsSearchPage';
import { connect } from 'react-redux';
import { Actions as BloodTargetActions } from '../../store/actions/bloodTarget.actions';
import * as fromRoot from '../../store/reducers/index';
import { bindActionCreators, Dispatch } from 'redux';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import SearchAndTabsShared from '../shared/SearchAndTabsShared';

interface StoreModel {
    user: UserModel | null;
}

interface DispatchModel {
    setSearchQuery: typeof BloodTargetActions.setSearchQuery;
}

type PropsTypes = StoreModel & DispatchModel;

const BloodTargets: React.FC<PropsTypes> = props => {
    const { setSearchQuery, user } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const redirectUrl = useMemo(() => {
        if (user) {
            return user.role === RoleOptions.ADMIN
                ? '/admin/bloodtargets/add-bloodtargets'
                : '/bloodtargets/add-bloodtargets';
        }
        return '';
    }, [user]);

    return (
        <>
            <SearchAndTabsShared
                setSearchQuery={setSearchQuery}
                tab={0}
                tabs={['Blood Targets']}
            />
            <BloodTargetsSearchPage />
            {user && user.role === RoleOptions.ADMIN && <FabButton redirectUrl={redirectUrl} />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setSearchQuery: bindActionCreators(BloodTargetActions.setSearchQuery, dispatch),
    })
)(BloodTargets);
