import React, { useEffect, useState } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import providerAvatar from '../../static/images/provider/providerAvatar.svg';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import { Actions as ProviderActions } from '../../store/actions/provider.actions';
import { LinkingStatusModel } from '../../models/responses/linkingStatus.response';
import Loading from '../shared/Loading';
import CustomLink from '../../shared/link/CustomLink';
import ChangeProviderDialog from './components/ChangeProviderDialog';
import LoadingTransparent from '../shared/LoadingTransparent';
import { mediaQuery } from '../../constants/device';

const createStyles = makeStyles({
    heading: {
        fontSize: '14px',
        color: colors.textSecondary,
    },
    headingContent: {
        fontSize: '24px',
        color: colors.textPrimary,
    },
    subHeadingContent: {
        fontSize: '14px',
        color: colors.textSecondary,
    },
    footerText: {
        fontSize: '14px',
        color: colors.textSecondary,
    },
    footerLink: {
        marginLeft: '4px',
        color: colors.redMain,
        textDecoration: 'underline',
        textDecorationColor: colors.redMain,
    },
});

const DecorationBlock = styled.div`
    border-radius: 30px 30px 0 0;
    background-color: ${colors.gray2};
    width: 100%;
    height: 160px;
    position: absolute;
    z-index: -1;
    ${mediaQuery.laptop} {
        border-radius: 0;
        height: 180px;
    }
`;

const ProviderWrapper = styled.div`
    height: calc(100vh - 202px);
    margin: 30px 0;
    border: 1px solid ${colors.gray1};
    border-radius: 30px;
    position: relative;

    ${mediaQuery.mobileLandscape} {
        height: auto;
    }

    ${mediaQuery.laptop} {
        margin: 0;
        border: none;
    }
`;

const HeaderWrapper = styled.div`
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px 0 0 0;

    ${mediaQuery.laptop} {
        padding: 42px 0 0 0;
    }
`;

const Avatar = styled.div<any>`
    width: 200px;
    height: 200px;
    margin-top: 16px;
    border-radius: 100px;
    background-color: ${colors.white};
    background-image: url(${props => (props.img ? props.img : providerAvatar)});
    background-size: ${props => (props.img ? 'cover' : 'auto')};
    border: 1px solid ${colors.textSecondary};
    background-repeat: no-repeat;
    background-position: center;
`;

const ContentWrapper = styled.div`
    height: calc(100% - 320px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 40px;

    ${mediaQuery.mobileLandscape} {
        height: 120px;
    }

    ${mediaQuery.laptop} {
        height: 140px;
        padding: 50px 0 40px;
    }
`;

const TextWrapper = styled.div`
    text-align: center;
`;

const FooterOptionalText = styled.div`
    width: 95%;
    margin: 8px 0 -16px 0;
    display: flex;
    align-items: baseline;
    justify-content: center;
`;

interface InjectedProps {
    linkingStatus: LinkingStatusModel;
    loading: boolean;
    updating: boolean;
    setOpenDialog: (open: boolean) => void;
    cancelRequestProvider: typeof ProviderActions.cancelRequestProvider;
}

const ProviderStatusComponent: React.FC<InjectedProps> = props => {
    const classes = createStyles(props);

    const getHeading = (status: LinkingStatusModel) => {
        if (status.currentProvider || status.pendingProvider) {
            if (status.pendingProvider) {
                return 'Provider Requested';
            } else {
                return 'Linked With';
            }
        } else {
            return 'No Provider Linked';
        }
    };

    const getAvatar = (status: LinkingStatusModel) => {
        if (status.currentProvider || status.pendingProvider) {
            if (status.pendingProvider) {
                return status.pendingProvider.avatarThumbnailUrl;
            } else {
                return status.currentProvider && status.currentProvider.avatarThumbnailUrl;
            }
        } else {
            return null;
        }
    };

    const getHeadingContent = (status: LinkingStatusModel) => {
        if (status.currentProvider || status.pendingProvider) {
            if (status.pendingProvider) {
                return `${status.pendingProvider.firstName} ${status.pendingProvider.lastName}`;
            } else {
                return (
                    status.currentProvider && `${status.currentProvider.firstName} ${status.currentProvider.lastName}`
                );
            }
        } else {
            return 'No Provider';
        }
    };

    const getSubHeadingContent = (status: LinkingStatusModel) => {
        if (status.currentProvider || status.pendingProvider) {
            return 'Doctor first Class';
        } else {
            return 'Tap to select from list';
        }
    };

    const getButton = (status: LinkingStatusModel) => {
        if (status.currentProvider || status.pendingProvider) {
            if (status.pendingProvider) {
                return <PrimaryButton onClick={() => props.cancelRequestProvider()}>Cancel</PrimaryButton>;
            } else {
                return <PrimaryButton onClick={() => props.setOpenDialog(true)}>Change Provider</PrimaryButton>;
            }
        } else {
            return (
                <CustomLink to={'/provider-linking'}>
                    <PrimaryButton>Find Provider</PrimaryButton>
                </CustomLink>
            );
        }
    };

    return (
        <ProviderWrapper>
            <DecorationBlock />
            <HeaderWrapper>
                <Typography variant="button" classes={{ root: classes.heading }}>
                    {getHeading(props.linkingStatus)}
                </Typography>
                <Avatar img={getAvatar(props.linkingStatus)} />
            </HeaderWrapper>
            <ContentWrapper>
                <TextWrapper>
                    <Typography variant="button" classes={{ root: classes.headingContent }}>
                        {getHeadingContent(props.linkingStatus)}
                    </Typography>
                    <Typography variant="body1" classes={{ root: classes.subHeadingContent }}>
                        {getSubHeadingContent(props.linkingStatus)}
                    </Typography>
                </TextWrapper>
                {getButton(props.linkingStatus)}
                {props.linkingStatus.currentProvider && props.linkingStatus.pendingProvider && (
                    <FooterOptionalText>
                        <Typography variant="body1" classes={{ root: classes.footerText }}>
                            Currently Linked with{' '}
                        </Typography>
                        <CustomLink to={`/provider/${props.linkingStatus.currentProvider.id}`}>
                            <Typography variant="button" classes={{ root: classes.footerLink }}>
                                {props.linkingStatus.currentProvider.firstName}{' '}
                                {props.linkingStatus.currentProvider.lastName}
                            </Typography>
                        </CustomLink>
                    </FooterOptionalText>
                )}
            </ContentWrapper>
        </ProviderWrapper>
    );
};

interface StoreModel {
    linkingStatus: LinkingStatusModel | null;
    loading: boolean;
    updating: boolean;
}

interface DispatchModel {
    getLinkingStatus: typeof PatientActions.getLinkingStatus;
    cancelRequestProvider: typeof ProviderActions.cancelRequestProvider;
}

type PropsTypes = StoreModel & DispatchModel;

const ProviderStatus: React.FC<PropsTypes> = props => {
    const { cancelRequestProvider, updating, loading, getLinkingStatus, linkingStatus } = props;
    useEffect(() => {
        if (!updating) {
            getLinkingStatus();
        }
    }, [updating, getLinkingStatus]);

    const [openDialog, setOpenDialog] = useState(false);

    const isLaptop = useMediaQuery(mediaQuery.laptop);

    return (
        <>
            <HeaderHeadingWithLink>Provider Linking</HeaderHeadingWithLink>
            {loading && <Loading />}
            {updating && <LoadingTransparent />}
            {!loading &&
                linkingStatus &&
                (isLaptop ? (
                    <ProviderStatusComponent
                        linkingStatus={linkingStatus}
                        updating={updating}
                        loading={loading}
                        cancelRequestProvider={cancelRequestProvider}
                        setOpenDialog={(open: boolean) => setOpenDialog(open)}
                    />
                ) : (
                    <SizeControllerWrapper>
                        <ProviderStatusComponent
                            linkingStatus={linkingStatus}
                            updating={updating}
                            loading={loading}
                            cancelRequestProvider={cancelRequestProvider}
                            setOpenDialog={(open: boolean) => setOpenDialog(open)}
                        />
                    </SizeControllerWrapper>
                ))}

            {openDialog && <ChangeProviderDialog open={openDialog} close={() => setOpenDialog(false)} />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        linkingStatus: state.patient.linkingStatus,
        loading: state.patient.loading,
        updating: state.provider.updating,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getLinkingStatus: bindActionCreators(PatientActions.getLinkingStatus, dispatch),
        cancelRequestProvider: bindActionCreators(ProviderActions.cancelRequestProvider, dispatch),
    })
)(ProviderStatus);
