import React, { useCallback } from 'react';
import LabsListSearchPage from '../patient/components/LabListSearchPage';
import { connect } from 'react-redux';
import { Actions as LabActions } from '../../store/actions/lab.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import FabButton from '../../shared/button/FabButton';
import SearchAndTabsShared from '../shared/SearchAndTabsShared';

interface DispatchModel {
    setFilterQueryLab: typeof LabActions.setFilterQuery;
}

type PropsTypes = DispatchModel & RouteComponentProps<any>;

const LabsList: React.FC<PropsTypes> = props => {
    const { setFilterQueryLab } = props;

    const redirect = useCallback(
        (labId: number) => {
            props.history.push(`/admin/lab/${labId}`);
        },
        [props.history]
    );

    return (
        <>
            <SearchAndTabsShared setSearchQuery={setFilterQueryLab} tab={0} tabs={['Labs']} />
            <LabsListSearchPage redirectToLab={redirect} />
            <FabButton redirectUrl={'/admin/labs/add-lab'} />
        </>
    );
};

export default connect(
    () => ({}),
    (dispatch: Dispatch): DispatchModel => ({
        setFilterQueryLab: bindActionCreators(LabActions.setFilterQuery, dispatch),
    })
)(withRouter(LabsList));
