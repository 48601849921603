import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import { mediaQuery } from '../../../../constants/device';

interface SpecialButtonProps {
    isActive: boolean;
    changeTab: () => void;
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    background-color: ${colors.redMain};

    width: 44%;
    height: 50px;
    border: 3px solid ${colors.gray2};
    border-radius: 25px 25px 0 0;
    ${mediaQuery.tablet} {
        width: 35%;
    }
`;

/** @type {{search: React.CSSProperties}} */
const styles = {
    active: {
        backgroundColor: `${colors.gray2}`,
    },
    passive: {
        backgroundColor: `${colors.redMain}`,
    },
};

const createStyles = makeStyles({
    activeWrapper: {
        backgroundColor: `${colors.gray2}`,
    },
    passiveWrapper: {
        backgroundColor: `${colors.redMain}`,
    },
    activeText: {
        width: '106px',
        height: '37px',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '18px',
        backgroundColor: `${colors.gray2}`,
        color: `${colors.redMain}`,
    },
    passiveText: {
        width: '106px',
        height: '37px',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '18px',
        backgroundColor: `${colors.redMain}`,
        color: `${colors.gray2}`,
    },
});

const SpecialButton: React.FC<SpecialButtonProps> = props => {
    const { isActive, changeTab, children } = props;
    const classes = createStyles();
    const styleWrapper = isActive ? styles.active : styles.passive;
    const classText = { root: isActive ? classes.activeText : classes.passiveText };
    return (
        <Wrapper style={styleWrapper} onClick={changeTab}>
            <Typography variant="button" classes={classText}>
                {children}
            </Typography>
        </Wrapper>
    );
};

export default SpecialButton;
