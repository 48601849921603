import * as providerActions from '../../actions/provider.actions';
import { ProviderModel } from '../../../models/responses/provider.response';
import RequestState from '../../../constants/requestState';

export class StateModel {
    allProviders: ProviderModel[] = [];
    page: number = 0;
    currentProvider: ProviderModel | null = null;
    totalProviders: number | null = null;
    loading: boolean = false;
    updating: boolean = false;
    updateProviderRequest: RequestState = RequestState.UNSENT;
    updatingAvatar: RequestState = RequestState.UNSENT;
    requestProviderSent: RequestState = RequestState.UNSENT;
    searchQuery: string = '';
    saveProvidersRequest: RequestState = RequestState.UNSENT;
    sendNewPasswordRequest: RequestState = RequestState.UNSENT;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: providerActions.Actions) {
    switch (action.type) {
        case providerActions.ActionNames.GET_ALL_PROVIDERS: {
            return {
                ...state,
                loading: true,
            };
        }

        case providerActions.ActionNames.GET_ALL_PROVIDERS_UPDATE_SUCCESS: {
            const isEmptyString = state.searchQuery.trim().length === 0;
            const newProvidersList = state.allProviders.concat(action.payload.result);
            const isRedundant = newProvidersList.length > action.payload.total;
            return {
                ...state,
                loading: false,
                totalProviders: action.payload.total,
                allProviders: isEmptyString && !isRedundant ? newProvidersList : action.payload.result,
            };
        }

        case providerActions.ActionNames.GET_ALL_PROVIDERS_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loading: false,
                totalProviders: action.payload.total,
                allProviders: action.payload.result,
            };
        }

        case providerActions.ActionNames.GET_ALL_PROVIDERS_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case providerActions.ActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case providerActions.ActionNames.GET_PROVIDER_BY_ID: {
            return {
                ...state,
                loading: true,
            };
        }

        case providerActions.ActionNames.GET_PROVIDER_BY_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                currentProvider: action.payload,
            };
        }

        case providerActions.ActionNames.GET_PROVIDER_BY_ID_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case providerActions.ActionNames.GET_PROVIDER: {
            return {
                ...state,
                loading: true,
            };
        }

        case providerActions.ActionNames.GET_PROVIDER_SUCCESS: {
            return {
                ...state,
                loading: false,
                currentProvider: action.payload,
            };
        }

        case providerActions.ActionNames.GET_PROVIDER_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case providerActions.ActionNames.UPDATE_PROVIDER: {
            return {
                ...state,
                updating: true,
            };
        }

        case providerActions.ActionNames.UPDATE_PROVIDER_SUCCESS: {
            return {
                ...state,
                currentProvider: action.payload,
                updating: false,
            };
        }

        case providerActions.ActionNames.UPDATE_PROVIDER_FAILED: {
            return {
                ...state,
                updating: false,
            };
        }

        case providerActions.ActionNames.UPDATE_PROVIDERS: {
            return {
                ...state,
                updateProviderRequest: RequestState.SENDING,
            };
        }

        case providerActions.ActionNames.UPDATE_PROVIDERS_SUCCESS: {
            return {
                ...state,
                updateProviderRequest: RequestState.SENT_SUCCESS,
            };
        }

        case providerActions.ActionNames.UPDATE_PROVIDERS_FAILED: {
            return {
                ...state,
                updateProviderRequest: RequestState.SENT_FAILED,
            };
        }

        case providerActions.ActionNames.REQUEST_PROVIDER: {
            return {
                ...state,
                loading: true,
                requestProviderSent: RequestState.SENDING,
            };
        }

        case providerActions.ActionNames.REQUEST_PROVIDER_SUCCESS: {
            return {
                ...state,
                loading: false,
                requestProviderSent: RequestState.SENT_SUCCESS,
            };
        }

        case providerActions.ActionNames.REQUEST_PROVIDER_FAILED: {
            return {
                ...state,
                loading: false,
                requestProviderSent: RequestState.SENT_FAILED,
            };
        }

        case providerActions.ActionNames.CANCEL_REQUEST_PROVIDER: {
            return {
                ...state,
                updating: true,
            };
        }

        case providerActions.ActionNames.CANCEL_REQUEST_PROVIDER_SUCCESS: {
            return {
                ...state,
                updating: false,
            };
        }

        case providerActions.ActionNames.CANCEL_REQUEST_PROVIDER_FAILED: {
            return {
                ...state,
                updating: false,
            };
        }

        case providerActions.ActionNames.SET_FILTER_QUERY: {
            const isEmptyString = action.payload.trim().length === 0;
            return {
                ...state,
                searchQuery: action.payload,
                loading: isEmptyString ? false : state.loading,
                page: 0,
                allProviders: isEmptyString ? [] : state.allProviders,
                totalProviders: 0,
            };
        }

        case providerActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                requestProviderSent: RequestState.UNSENT,
                updatingAvatar: RequestState.UNSENT,
                saveProvidersRequest: RequestState.UNSENT,
                updateProviderRequest: RequestState.UNSENT,
                sendNewPasswordRequest: RequestState.UNSENT,
            };
        }

        case providerActions.ActionNames.UPLOAD_AVATAR: {
            return {
                ...state,
                updatingAvatar: RequestState.SENDING,
            };
        }

        case providerActions.ActionNames.UPLOAD_AVATAR_SUCCESS: {
            return {
                ...state,
                updatingAvatar: RequestState.SENT_SUCCESS,
            };
        }

        case providerActions.ActionNames.UPLOAD_AVATAR_FAILED: {
            return {
                ...state,
                updatingAvatar: RequestState.SENT_FAILED,
            };
        }

        case providerActions.ActionNames.SAVE_PROVIDERS: {
            return {
                ...state,
                saveProvidersRequest: RequestState.SENDING,
            };
        }
        case providerActions.ActionNames.SAVE_PROVIDERS_SUCCESS: {
            return {
                ...state,
                saveProvidersRequest: RequestState.SENT_SUCCESS,
            };
        }
        case providerActions.ActionNames.SAVE_PROVIDERS_FAILED: {
            return {
                ...state,
                saveProvidersRequest: RequestState.SENT_FAILED,
            };
        }

        case providerActions.ActionNames.GET_NEW_PASSWORD_PROVIDER: {
            return{
                ...state,
                sendNewPasswordRequest: RequestState.SENDING
            }
        }

        case providerActions.ActionNames.GET_NEW_PASSWORD_PROVIDER_SUCCESS: {
            return{
                ...state,
                sendNewPasswordRequest: RequestState.SENT_SUCCESS
            }
        }

        case providerActions.ActionNames.GET_NEW_PASSWORD_PROVIDER_FAILED: {
            return{
                ...state,
                sendNewPasswordRequest: RequestState.SENT_FAILED
            }
        }

        default:
            return state;
    }
}
