import React, { useEffect } from 'react';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Actions as PatientActions } from '../../store/actions/patient.actions';
import * as fromRoot from '../../store/reducers';
import { PatientModel } from '../../models/responses/patient.response';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../shared/Loading';
import defaultAvatar from '../../static/icons/defaultAvatar.svg';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import { mediaQuery } from '../../constants/device';

const createStyles = makeStyles({
    name: {
        fontSize: '24px',
    },
    subHeading: {
        fontSize: '18px',
        color: colors.textSecondary,
    },
    rejectLink: {
        fontSize: '14px',
        color: colors.redMain,
        textDecoration: 'underline',
        textDecorationColor: colors.redMain,
    },
    backLink: {
        color: colors.redMain,
        textDecoration: 'underline',
        textDecorationColor: colors.redMain,
    },
    emailText: {
        color: colors.textSecondary,
        fontSize: '14px',
        marginTop: '16px',
    },
});
const Wrapper = styled.div`
    height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0 40px 0;

    ${mediaQuery.laptop} {
        height: calc(100vh - 255px);
    }
`;

const Avatar = styled.div<{isDefault: boolean, image: string}>`
    height: 200px;
    width: 200px;
    border-radius: 100px;
    background-color: ${colors.gray2};
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${props => props.isDefault ? 'auto' : 'cover'};
`;
const TextWrapper = styled.div`
    text-align: center;
    margin-bottom: 30%;
`;

interface StoreModel {
    patient: PatientModel | null;
    loading: boolean;
}

interface DispatchModel {
    getPatientById: typeof PatientActions.getPatientById;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const PatientInvitedPage: React.FC<PropsTypes> = props => {
    const { getPatientById, patient, loading, match, history } = props;

    useEffect(() => {
        getPatientById(match.params.id);
    }, [getPatientById, match]);

    const classes = createStyles(props);

    return (
        <>
            {loading && <Loading />}
            {!loading && patient && (
                <SizeControllerWrapper>
                    <Wrapper>
                        <Avatar image={patient.avatarThumbnailUrl ? patient.avatarThumbnailUrl : defaultAvatar} isDefault={!patient.avatarThumbnailUrl}/>
                        <TextWrapper>
                            <Typography variant="h2" classes={{ root: classes.name }}>
                                {patient.firstName} {patient.lastName}
                            </Typography>
                            <Typography variant="body1" classes={{ root: classes.subHeading }}>
                                Pending Invitation
                            </Typography>
                            <Typography variant="body1" classes={{ root: classes.emailText }}>
                                Invitation was sent to
                            </Typography>
                            <Typography variant="button">{patient.email}</Typography>
                        </TextWrapper>
                        <Typography
                            variant="button"
                            classes={{ root: classes.backLink }}
                            onClick={() => history.goBack()}
                        >
                            Back
                        </Typography>
                    </Wrapper>
                </SizeControllerWrapper>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        patient: state.patient.patient,
        loading: state.patient.loading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getPatientById: bindActionCreators(PatientActions.getPatientById, dispatch),
    })
)(withRouter(PatientInvitedPage));
