import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';

const createStyles = makeStyles(theme => ({
    heading: {
        fontSize: '12px',
        color: colors.redMain,
        textTransform: 'uppercase',
        display: 'block',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '16px',
        },
    },
}));

const LandingRedUppercaseHeading: React.FC = props => {
    const classes = createStyles(props);
    return (
        <Typography variant={'button'} classes={{ root: classes.heading }}>
            {props.children}
        </Typography>
    );
};

export default LandingRedUppercaseHeading;
