import React, { useCallback, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as InvoiceActions } from '../../../../store/actions/invoice.actions';
import * as fromRoot from '../../../../store/reducers';
import SecondaryButton from '../../../../shared/button/SecondaryButton';

interface DispatchModel {
    payInvoice: typeof InvoiceActions.payInvoice;
}

interface StoreModel {
    sessionId: string | null;
}

interface InjectedProps {
    invoiceId: number;
    payManually: boolean;
    allowManualPayment: () => void;
}

type PropsTypes = InjectedProps & DispatchModel & StoreModel;

const PayOneTimeButtonManually: React.FC<PropsTypes> = props => {
    const stripe = useStripe();

    const { payInvoice, invoiceId, sessionId, payManually, allowManualPayment } = props;

    const pay = useCallback(
        (evt: any) => {
            evt.stopPropagation();
            allowManualPayment();
            payInvoice({ invoiceId, paymentMethod: { id: null } });
        },
        [payInvoice, invoiceId, allowManualPayment]
    );

    useEffect(() => {
        if (sessionId && payManually) {
            if (!stripe) return;
            stripe.redirectToCheckout({
                sessionId,
            });
        }
    }, [sessionId, stripe, payManually]);

    return <SecondaryButton onClick={pay}>Enter card manually</SecondaryButton>;
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        sessionId: state.invoice.sessionId,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        payInvoice: bindActionCreators(InvoiceActions.payInvoice, dispatch),
    })
)(PayOneTimeButtonManually);
