import React from 'react';
import { Avatar, Button, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import forwardIcon from '../../../static/icons/forwardIcon.svg';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import CustomLink from '../../../shared/link/CustomLink';
import prescriptionIcon from '../../../static/icons/prescriptionIcon.svg';
import { GetPrescriptionByIdDto } from '../../../models/dto/getPrescriptionById.dto';
import { RoleOptions } from '../../../constants/roleOptions';

const useStyles = makeStyles({
    listItem: {
        padding: 0,
    },
    nameText: {
        fontSize: '14px',
        color: colors.textPrimary,
    },
    typeText: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'capitalize',
    },
    avatar: {
        border: `1px solid ${colors.gray1}`,
        backgroundColor: 'transparent',
        width: '38px',
        height: '38px',
    },
    avatarWrapper: {
        minWidth: 'auto',
        marginRight: '10px',
    },
    forwardIcon: {
        padding: '0 !important',
        minWidth: '25px',
        height: '25px',
    },
});

const TextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: -6px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface InjectedProps {
    prescription: GetPrescriptionByIdDto;
    role: RoleOptions | null;
    patientId: number | null;
}

type PropsTypes = InjectedProps;

const DosageListItem: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);

    return (
        <CustomLink
            to={
                props.role === RoleOptions.PATIENT
                    ? `/prescription/${props.prescription.id}`
                    : `/prescription/${props.prescription.id}/${props.patientId}`
            }
        >
            <ListItem classes={{ root: classes.listItem }} button>
                <SizeControllerWrapper>
                    <Wrapper>
                        <ListItemAvatar classes={{ root: classes.avatarWrapper }}>
                            <Avatar classes={{ root: classes.avatar }}>
                                <img src={prescriptionIcon} alt="" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <TextWrapper>
                                    <Typography variant="button" classes={{ root: classes.nameText }}>
                                        {props.prescription.name}
                                    </Typography>
                                </TextWrapper>
                            }
                            secondary={
                                <Typography variant="body1" classes={{ root: classes.typeText }}>
                                    Provider Name
                                </Typography>
                            }
                        />
                        <Button classes={{ root: classes.forwardIcon }}>
                            <img src={forwardIcon} alt="forward icon" />
                        </Button>
                    </Wrapper>
                </SizeControllerWrapper>
            </ListItem>
        </CustomLink>
    );
};

export default DosageListItem;
