import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { ProviderMyPatientActions } from '../../../store/actions/providerMyPatients.actions';
import { SortOptions } from '../../../constants/sortOptions';
import { PatientModel } from '../../../models/responses/patient.response';
import DropdownInputList from '../DropdownInputList';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { DefaultPageSize, shouldFetchData } from '../../../constants/dataFetching/allEntitiesByPages';
import { useCompare } from '../../../helpers/usePreviousHelper';

interface DispatchModel {
    getAllLinkedPatients: typeof ProviderMyPatientActions.getAllLinkedPatients;
    setFilterQuery: typeof ProviderMyPatientActions.setFilterQueryLinkedPatients;
    increasePageNumberLinkedPatients: typeof ProviderMyPatientActions.increasePageNumberLinkedPatients;
}

interface StoreModel {
    allPatients: PatientModel[];
    totalPatients: number;
    loading: boolean;
    pageAllPatients: number;
    searchQueryAllPatients: string;
}

interface InjectedProps {
    onChange: (entity: BaseDropdownItem | null) => void;
    label: string;
    error?: boolean;
    value?: BaseDropdownItem | null;
    disabled?: boolean;
}

type PropsTypes = DispatchModel & StoreModel & InjectedProps;

const DropdownInputLinkedPatientListWrapper: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);

    const {
        loading,
        value,
        increasePageNumberLinkedPatients,
        getAllLinkedPatients,
        error,
        disabled,
        allPatients,
        label,
        onChange,
        pageAllPatients,
        searchQueryAllPatients,
        setFilterQuery,
        totalPatients,
    } = props;

    const isSearchQueryChanged = useCompare(searchQueryAllPatients);

    const loadData = useCallback(
        (page: number, size: number, sort: SortOptions, searchQuery: string, overrideRecords: boolean) => {
            getAllLinkedPatients({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllLinkedPatients]
    );

    useEffect(() => {
        setFilterQuery('');
        loadData(0, DefaultPageSize, SortOptions.LAST_NAME_ASCENDING, '', true);
        setInitialLoaded(true);
    }, [setFilterQuery, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(loading, pageAllPatients, allPatients.length, initialLoaded, DefaultPageSize) ||
            isSearchQueryChanged
        ) {
            loadData(pageAllPatients, DefaultPageSize, SortOptions.LAST_NAME_ASCENDING, searchQueryAllPatients, false);
        }
    }, [pageAllPatients, searchQueryAllPatients, loadData, allPatients, initialLoaded, loading, isSearchQueryChanged]);

    const allEntities = useMemo(() => {
        return allPatients.map(patient => {
            return {
                name: `${patient.firstName} ${patient.lastName}`,
                id: patient.id,
                avatar: patient.avatarThumbnailUrl,
            };
        });
    }, [allPatients]);

    return (
        <DropdownInputList
            onChange={onChange}
            label={label}
            loading={loading}
            allEntities={allEntities}
            totalEntities={totalPatients}
            searchQuery={searchQueryAllPatients}
            increasePageNumber={increasePageNumberLinkedPatients}
            setFilterQuery={setFilterQuery}
            error={error}
            value={value}
            removeIcon
            disabled={disabled}
            debounce
        />
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        allPatients: state.providerMyPatients.myLinkedPatients,
        totalPatients: state.providerMyPatients.totalLinkedPatients,
        loading: state.providerMyPatients.loading,
        pageAllPatients: state.providerMyPatients.pageLinkedPatients,
        searchQueryAllPatients: state.providerMyPatients.searchQueryLinkedPatients,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllLinkedPatients: bindActionCreators(ProviderMyPatientActions.getAllLinkedPatients, dispatch),
        setFilterQuery: bindActionCreators(ProviderMyPatientActions.setFilterQueryLinkedPatients, dispatch),
        increasePageNumberLinkedPatients: bindActionCreators(
            ProviderMyPatientActions.increasePageNumberLinkedPatients,
            dispatch
        ),
    })
)(DropdownInputLinkedPatientListWrapper);
