import { v } from 'overshom-valid';

export const labApiValid = v.class({
    result: v.Array(
        v.Object({
            id: v.Number(),
            name: v.String(),
        })
    ),
    total: v.Number(),
});
