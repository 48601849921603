import React from 'react';
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../../theme/colors';
import { mediaQuery } from '../../../../constants/device';
import { RouteComponentProps, withRouter } from 'react-router';

const BackgroundWrapper = styled.div`
    width: 100%;
    background: ${colors.redMain};
    height: 353px;
    padding: 78px 0 40px 0;

    ${mediaQuery.mobileL} {
        padding: 20px 0;
        height: 325px;
    }
    ${mediaQuery.tablet} {
        padding: 20px 0;
    }
    ${mediaQuery.laptop} {
        padding: 110px 0;
        height: 350px;
    }
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    ${mediaQuery.mobileL} {
        height: 90%;
    }
    ${mediaQuery.tablet} {
        height: 85%;
    }
    ${mediaQuery.laptop} {
        height: 100%;
    }
`;

const TextWrapper = styled.div`
    ${mediaQuery.laptop} {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 36px;
    }
`;

const SizeWrapper = styled.div`
    padding: 0 20px;
    height: 100%;
    ${mediaQuery.tablet} {
        margin: 0 15%;
    }
    ${mediaQuery.laptop} {
        margin: 0;
    }
`;

const createStyles = makeStyles(theme => ({
    heading: {
        color: colors.white,
        fontSize: '30px',
        lineHeight: '36px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '40px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '0',
        },
    },
    text: {
        color: colors.white,
        fontSize: '16px',
        marginBottom: '40px',
        marginTop: '10px',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '0',
            marginTop: '0',
        },
    },
    learnMore: {
        color: colors.white,
        fontSize: '14px',
        cursor: 'pointer',
    },
    imgIcon: {
        height: '80px',
        width: '80px',
    },
}));

interface InjectedProps {
    icon: string;
    heading: string;
    text: string;
    link?: string;
}

type PropsTypes = InjectedProps & RouteComponentProps;

const RedInfoBlockWithLearnMore: React.FC<PropsTypes> = props => {
    const { icon, heading, text, link } = props;
    const classes = createStyles(props);
    return (
        <BackgroundWrapper>
            <SizeWrapper>
                <Wrapper>
                    <img src={icon} className={classes.imgIcon} alt="" />
                    <TextWrapper>
                        <Typography variant={'button'} classes={{ root: classes.heading }}>
                            {heading}
                        </Typography>
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            {text}
                        </Typography>
                        <Typography
                            variant={'button'}
                            classes={{ root: classes.learnMore }}
                            onClick={() => link && props.history.push(link)}
                        >
                            Learn more
                        </Typography>
                    </TextWrapper>
                </Wrapper>
            </SizeWrapper>
        </BackgroundWrapper>
    );
};

export default withRouter(RedInfoBlockWithLearnMore);
