import React from 'react';
import bloodworkWhite from "../../static/icons/bloodworkWhite.svg";
import {Fab, makeStyles} from "@material-ui/core";
import HeaderHeadingWithLink from "../../shared/HeaderHeadingWithLink";
import BloodworkReportsList from "./components/BloodworkReportsList";
import addNewIcon from '../../static/icons/addNewIcon.svg';
import {colors} from "../../theme/colors";
import theme from "../../theme/theme";
import {RouteComponentProps, withRouter} from "react-router";

const createStyles = makeStyles({
    bloodWorkIcon: {
        marginRight: '8px',
        marginBottom: '-3px',
    },
    fab: {
        position: 'fixed',
        right: '15px',
        bottom: '3.5%',
        backgroundColor: colors.redMain,
        boxShadow: theme.boxShadowRed,
        '&:hover':{
            backgroundColor: colors.redMain,
        },
        '&:active':{
            backgroundColor: colors.redMain,
            boxShadow: theme.boxShadowRed,
        },
    }
});

type PropsTypes = & RouteComponentProps<any>;


const BloodworkList:React.FC<PropsTypes> = (props) => {
    const classes = createStyles(props);
    return (
        <>
            <HeaderHeadingWithLink>
                <img src={bloodworkWhite} alt="" className={classes.bloodWorkIcon}/>
                Bloodwork
            </HeaderHeadingWithLink>
            <BloodworkReportsList/>
            <Fab classes={{root: classes.fab}} onClick={()=>props.history.push('/upload-results')}>
                <img src={addNewIcon} alt=""/>
            </Fab>
        </>
    )
};

export default withRouter(BloodworkList);