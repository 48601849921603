import React from 'react';
import styled from "styled-components";
import {mediaQuery, size} from "../constants/device";

const ContentWrapper = styled.div<{minHeight?: boolean}>`
  margin: 0 auto;
  height: ${props => props.minHeight ? 'auto' : '100%'};
 
  ${mediaQuery.lessMobileS}{
    width: 280px;
  }
  ${mediaQuery.mobileM}{
    width: 315px;
  }
  ${mediaQuery.mobileL}{
    width: 90%;
  }
  ${mediaQuery.tablet}{
    width: 90%;
  }
  ${mediaQuery.laptop}{
    width: 90%;
  }  
  ${mediaQuery.laptopL}{
    width: 77%;
  }
  ${mediaQuery.desktop}{
    width: 90%;
    max-width: ${size.laptopL}
  }
`;

type PropsTypes = {
    children: React.ReactNode,
    minHeight?: boolean,
}

const SizeControllerWrapper: React.FC<PropsTypes> = (props) => {
    return (
        <ContentWrapper minHeight={props.minHeight}>
            {props.children}
        </ContentWrapper>
    )
};

export default SizeControllerWrapper;