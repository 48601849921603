import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import { Typography, makeStyles } from '@material-ui/core';
import FounderDto from '../../../../models/dto/founder.dto';

interface propsDto {
    founder: FounderDto;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
`;

const Photo = styled.div`
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top: 20px;
`;

const createStyles = makeStyles({
    founderName: {
        fontSize: '14px',
        marginTop: '30px',
    },
    founderAbout: {
        width: '255px',
        height: '32px',
        fontSize: '12px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: `${colors.redMain}`,
    },
});

const PhotoWithDescriptionMobile = (props: propsDto) => {
    const classes = createStyles(props);

    return (
        <Wrapper>
            <Photo style={{ background: `url(${props.founder.image})` }} />
            <Typography variant="button" classes={{ root: classes.founderName }}>
                {props.founder.name}
            </Typography>
            <Typography variant="button" classes={{ root: classes.founderAbout }}>
                {props.founder.about}
            </Typography>
        </Wrapper>
    );
};

export default PhotoWithDescriptionMobile;
