import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EmptyHeaderWithLink } from '../ui/emptyHeaderWithLink';
import { WeightManagementRoutes } from '../enums/routes';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    makeStyles,
    Slide,
    Typography,
} from '@material-ui/core';
import { CardWithBigShadow } from '../../../shared/card/CardWithBigShadow';
import styled from 'styled-components';
import { Form, InputType, WeightManagementFormType } from '../ui/formBuilder/form';
import { colors } from '../../../theme/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWeightRecord,
    deleteWeightRecord,
    editWeightRecord,
    fetchWeightRecordById,
} from '../../../store/actions/weightManagement.actions';
import { CreateWeightRecord } from '../../../models/dto/createWeightRecord';
import { IFormWeightRecord } from '../dtos/weight-record.interface';
import { formatDate } from '../../../helpers/dateFormatHelper';
import { RootStateModel } from '../../../store/reducers';
import { WeightRecordResponse } from '../../../models/responses/weightRecord.response';
import { MeasurementSystem, UserModel } from '../../../models/responses/user.response';
import { WeightManagementLoadingState } from '../../../store/reducers/weight';
import Loading from '../../shared/Loading';
import { TransitionProps } from '@material-ui/core/transitions';
import { greaterThen, lessThen } from '../ui/formBuilder/validators';
import { isInteger } from '../ui/formBuilder/validators/isInteger';
import { PatientModel } from '../../../models/responses/patient.response';
import moment from 'moment';
import { UnitWeightManagement } from '../../../constants/weightManagement/unit.constants';
import binIcon from '../../../static/icons/binIconRound.svg';
import editIcon from '../../../static/icons/editIconRound.svg';
import { roundToTwoPoints } from '../../../shared/utils/converter/round';
import { Actions as PatientActions } from '../../../store/actions/patient.actions';

const createStyles = makeStyles({
    redText: {
        color: colors.redDark,
    },
    dateText: {
        color: colors.gray3,
        fontSize: '13px',
    },
    roundIcon: {
        padding: 0,
        marginLeft: '4px',
    },
});

interface IRouteParams {
    id?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {}

enum Mode {
    CREATING,
    EDITING,
    READONLY,
    UNKNOWN,
}

const Subtitle = styled.div`
    margin-bottom: 15px;
    text-align: center;
`;

const FabButtonsWrapper = styled.div`
    position: absolute;
    bottom: 15px;
    right: 15px;
    height: 50px;
`;

const BACK_LINK = `${WeightManagementRoutes.BASE_ROUTE}`;

const initialWeightRecord: IFormWeightRecord = {
    arm: 0,
    thigh: 0,
    bmi: 0,
    date: formatDate(new Date()),
    waist: 0,
    weight: 0,
    height: 0,
    age: 25,
    topPressure: 0,
    bottomPressure: 0,
};

const mapFormRecordToDto = (formRecord: IFormWeightRecord): CreateWeightRecord => ({
    ...formRecord,
    date: new Date(formRecord.date),
});

const mapDtoToFormRecord = (user: UserModel, dto: WeightRecordResponse, birthDate?: string): IFormWeightRecord => ({
    ...dto,
    age: birthDate ? moment().diff(birthDate, 'years') : 0,
    date: formatDate(dto.date),
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function CreateOrEditWeightRecord(props: IProps) {
    const {
        history,
        match: { params },
    } = props;
    const classes = createStyles(props);
    const [mode, setMode] = useState<Mode>(Mode.UNKNOWN);
    const [weightRecord, setWeightRecord] = useState<IFormWeightRecord>(initialWeightRecord);
    const [userAge, setUserAge] = useState<number>();
    const [isDeleteConfirmationShown, setDeleteConfirmationShown] = useState(false);

    const weightRecordFromStore = useSelector<RootStateModel, WeightRecordResponse | undefined>(state => {
        if (!params.id || params.id === 'new') {
            return undefined;
        }
        return state.weightManagement.weightList.find(record => record.id === parseInt(params!.id!));
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(PatientActions.getPatient());
    }, [dispatch]);

    const loadingState = useSelector<RootStateModel, WeightManagementLoadingState>(
        state => state.weightManagement.loadingState
    );

    const user = useSelector<RootStateModel, UserModel>(state => state.authorization.user!);
    const profile = useSelector<RootStateModel, PatientModel>(state => state.patient.patient!);

    const goBackToList = useCallback(
        () => history.push(`${WeightManagementRoutes.BASE_ROUTE}${WeightManagementRoutes.LIST}`),
        [history]
    );

    const tryDelete = useCallback(() => {
        setDeleteConfirmationShown(true);
    }, []);

    const doDelete = useCallback(() => {
        dispatch(deleteWeightRecord(weightRecordFromStore!.id!));
        setDeleteConfirmationShown(false);
        goBackToList();
    }, [dispatch, weightRecordFromStore, goBackToList]);

    const cancelDeletion = useCallback(() => {
        setDeleteConfirmationShown(false);
    }, []);

    useEffect(() => {
        if (user && weightRecordFromStore) {
            setWeightRecord(mapDtoToFormRecord(user, weightRecordFromStore));
        }
    }, [user, weightRecordFromStore, setWeightRecord]);

    useEffect(() => {
        if (profile) {
            setUserAge(moment().diff(profile ? profile.birthDate : 0, 'years'));
        }
    }, [setUserAge, profile]);

    useEffect(() => {
        if (loadingState === WeightManagementLoadingState.SUCCESS) {
            goBackToList();
        }
    }, [goBackToList, loadingState, dispatch]);

    const setEditable = useCallback(() => {
        setMode(Mode.EDITING);
    }, [setMode]);

    useEffect(() => {
        if (params.id && params.id === 'new') {
            setMode(Mode.CREATING);
        } else if (params.id) {
            setMode(Mode.READONLY);
            dispatch(fetchWeightRecordById(parseInt(params.id)));
        } else {
            setMode(Mode.UNKNOWN);
        }
    }, [params, dispatch]);

    const calculateBmi = useCallback((record: IFormWeightRecord): number => {
        const heightInMeters = record.height / 100;
        return parseFloat((roundToTwoPoints(record.weight) / heightInMeters ** 2).toFixed(2));
    }, []);

    const lengthUnit = useMemo(() => {
        return user.measurementSystem === MeasurementSystem.IMPERIAL
            ? UnitWeightManagement.INCH
            : UnitWeightManagement.CM;
    }, [user]);

    const weightUnit = useMemo(() => {
        return user.measurementSystem === MeasurementSystem.IMPERIAL
            ? UnitWeightManagement.LBS
            : UnitWeightManagement.KG;
    }, [user]);

    const changeRecord = useCallback(
        (record: IFormWeightRecord) => {
            setWeightRecord({
                ...record,
                bmi: record.height && record.weight ? calculateBmi(record) : record.bmi,
            });
        },
        [setWeightRecord, calculateBmi]
    );

    const submitHandler = useCallback(() => {
        if (mode === Mode.CREATING) {
            dispatch(createWeightRecord(mapFormRecordToDto(weightRecord)));
        }
        if (mode === Mode.EDITING) {
            dispatch(
                editWeightRecord({
                    ...mapFormRecordToDto(weightRecord),
                    id: weightRecordFromStore!.id,
                })
            );
        }
    }, [dispatch, mode, weightRecord, weightRecordFromStore]);

    const data: IFormWeightRecord = useMemo(
        () => ({
            ...weightRecord,
            age: userAge!,
        }),
        [weightRecord, userAge]
    );

    const IS_DATA_READY = useMemo(() => {
        const isRecordAvailable = (mode === Mode.EDITING && weightRecordFromStore) || true;
        return isRecordAvailable && userAge && weightRecord && data.age === userAge;
    }, [mode, data, weightRecord, weightRecordFromStore, userAge]);

    if (mode === Mode.UNKNOWN) {
        return null;
    }

    if (loadingState === WeightManagementLoadingState.LOADING || !IS_DATA_READY) {
        return <Loading />;
    }

    return (
        <>
            <EmptyHeaderWithLink backLink={BACK_LINK}>
                <Typography variant="h6">{mode === Mode.CREATING ? 'ADD NEW RECORD' : 'WEIGHT MANAGEMENT'}</Typography>
            </EmptyHeaderWithLink>
            {(mode === Mode.EDITING || mode === Mode.READONLY) && (
                <Subtitle>
                    <Typography variant={'body2'} className={classes.dateText}>
                        {weightRecord.date}
                    </Typography>
                </Subtitle>
            )}
            <CardWithBigShadow styles={{ width: '85%', paddingBottom: 25 }}>
                <Form<IFormWeightRecord>
                    type={WeightManagementFormType.DEFAULT}
                    data={data}
                    onChange={changeRecord}
                    onSubmit={submitHandler}
                    onCancel={goBackToList}
                    readOnly={mode === Mode.READONLY}
                    submitBtnText={mode === Mode.EDITING ? 'Edit Weight Record' : 'Save Weight Record'}
                    fields={{
                        age: {
                            index: 0,
                            label: 'Your Age',
                            readonly: true,
                            fieldType: InputType.NUMBER,
                            validators: [greaterThen(0), lessThen(100), isInteger()],
                            unit: UnitWeightManagement.YEARS,
                        },
                        height: {
                            index: 1,
                            label: 'Height',
                            fieldType: InputType.NUMBER,
                            validators: [greaterThen(0)],
                            unit: lengthUnit,
                        },
                        weight: {
                            label: 'Weight',
                            index: 2,
                            fieldType: InputType.NUMBER,
                            validators: [greaterThen(0)],
                            unit: weightUnit,
                        },
                        bmi: {
                            index: 3,
                            label: 'bmi, body fat%',
                            fieldType: InputType.NUMBER,
                            readonly: true,
                            labelInfo: (
                                <>
                                    <Typography variant="h6" className={classes.redText}>
                                        BMI Formula
                                    </Typography>
                                    <Typography variant="body1">Normal: 18%</Typography>
                                    <Typography variant="body2">Metric</Typography>
                                    <Typography variant="body1">BMI = weight (kg)/ [height(m)]</Typography>
                                    <Typography variant="body2">Imperial</Typography>
                                    <Typography style={{ fontWeight: 800 }} variant="body2">
                                        BMI = 703 * weight (lbs) / [height(m)]
                                    </Typography>
                                </>
                            ),
                            unit: UnitWeightManagement.PERCENT,
                        },
                        waist: {
                            index: 4,
                            label: 'Waist',
                            fieldType: InputType.NUMBER,
                            validators: [greaterThen(0)],
                            unit: lengthUnit,
                        },
                        arm: {
                            index: 5,
                            label: 'Arm',
                            fieldType: InputType.NUMBER,
                            validators: [greaterThen(0)],
                            unit: lengthUnit,
                        },
                        topPressure: {
                            index: 6,
                            label: 'Top Pressure',
                            fieldType: InputType.NUMBER,
                            validators: [greaterThen(0), lessThen(1000)],
                        },
                        bottomPressure: {
                            index: 7,
                            label: 'Bottom Pressure',
                            fieldType: InputType.NUMBER,
                            validators: [greaterThen(0), lessThen(1000)],
                        },
                    }}
                />
                <Dialog
                    // @ts-ignore
                    TransitionComponent={Transition}
                    open={isDeleteConfirmationShown}
                    onAbort={cancelDeletion}
                    aria-labelledby="alert-dialog-slide-delete"
                    aria-describedby="alert-dialog-slide-delete"
                >
                    <DialogTitle id="alert-dialog-slide-delete">
                        Are you sure you want to delete this record?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>Please, note that this action is irreversible.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancelDeletion} color="primary">
                            <Typography variant="h6">Cancel</Typography>
                        </Button>
                        <Button onClick={doDelete} color="primary">
                            <Typography variant="h6">Delete</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
                {mode !== Mode.CREATING && (
                    <>
                        <FabButtonsWrapper>
                            <IconButton onClick={setEditable} aria-label="edit" className={classes.roundIcon}>
                                <img src={editIcon} alt="" />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={tryDelete} className={classes.roundIcon}>
                                <img src={binIcon} alt="" />
                            </IconButton>
                        </FabButtonsWrapper>
                    </>
                )}
            </CardWithBigShadow>
        </>
    );
}
