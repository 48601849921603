import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { Actions as LabActions } from '../../../store/actions/lab.actions';
import { bindActionCreators, Dispatch } from 'redux';
import DropdownInputList from '../DropdownInputList';
import {DefaultPageSize, shouldFetchData} from "../../../constants/dataFetching/allEntitiesByPages";
import {useCompare} from "../../../helpers/usePreviousHelper";

interface InjectedProps {
    onChange: (entity: BaseDropdownItem | null) => void;
    label: string;
    error?: boolean;
    value?: BaseDropdownItem | null;
}

interface StateModel {
    loading: boolean;
    allLabs: BaseDropdownItem[];
    totalLabs: number | null;
    page: number;
    searchQuery: string;
}

interface DispatchModel {
    increasePageNumber: typeof LabActions.increasePageNumber;
    getAllLabs: typeof LabActions.getAllLabs;
    setFilterQuery: typeof LabActions.setFilterQuery;
}

type PropsTypes = InjectedProps & DispatchModel & StateModel;

const DropdownInputLabsListWrapper: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);
    const {
        loading,
        page,
        setFilterQuery,
        label,
        increasePageNumber,
        searchQuery,
        onChange,
        error,
        value,
        allLabs,
        getAllLabs,
        totalLabs,
    } = props;
    const isSearchQueryChanged = useCompare(searchQuery);

    const loadData = useCallback(
        (page: number, size: number, sort: string, searchQuery: string, overrideRecords: boolean) => {
            getAllLabs({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllLabs]
    );

    useEffect(() => {
        setFilterQuery('');
        loadData(0, DefaultPageSize, '', '', true);
        setInitialLoaded(true);
    }, [setFilterQuery, loadData]);

    useEffect(() => {
        if (shouldFetchData(loading, page, allLabs.length, initialLoaded, DefaultPageSize) || isSearchQueryChanged) {
            loadData(page, 10, '', searchQuery, false);
        }
    }, [page, searchQuery, loading, allLabs, initialLoaded, loadData, isSearchQueryChanged]);

    return (
        <DropdownInputList
            value={value}
            onChange={onChange}
            label={label}
            loading={loading}
            allEntities={allLabs}
            totalEntities={totalLabs}
            searchQuery={searchQuery}
            increasePageNumber={increasePageNumber}
            setFilterQuery={setFilterQuery}
            error={error}
            openIcon
        />
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.lab.loading,
        allLabs: state.lab.allLabs,
        totalLabs: state.lab.totalLabs,
        page: state.lab.page,
        searchQuery: state.lab.searchQuery,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllLabs: bindActionCreators(LabActions.getAllLabs, dispatch),
        increasePageNumber: bindActionCreators(LabActions.increasePageNumber, dispatch),
        setFilterQuery: bindActionCreators(LabActions.setFilterQuery, dispatch),
    })
)(DropdownInputLabsListWrapper);
