import { restAxios } from '../restAxios';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { GetAllPrescriptionsDto } from '../../models/dto/getAllPrescriptions.dto';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { CreatePrescriptionDto } from '../../models/dto/createPrescription.dto';
import { UpdatePrescriptionDto } from '../../models/dto/updatePrescription.dto';

class PrescriptionApi {
    @HandleAsyncErrors()
    static async getAllPharmacies(data: AllEntitiesByPagesDto) {
        const { size, page, sort, searchQuery } = data;
        return await restAxios.get(`private/pharmacies`, {
            params: { page, size, searchQuery, sort: sort && encodeURIComponent(sort) },
        });
    }

    @HandleAsyncErrors()
    static async getAllDrugs(data: AllEntitiesByPagesDto) {
        const { size, page, sort, searchQuery } = data;
        return await restAxios.get(`private/drugs`, {
            params: { page, size, searchQuery, sort: sort && encodeURIComponent(sort) },
        });
    }

    @HandleAsyncErrors()
    static async getAllTherapies(data: AllEntitiesByPagesDto) {
        const { size, page, sort, searchQuery } = data;
        return await restAxios.get(`private/therapies`, {
            params: { page, size, searchQuery, sort: sort && encodeURIComponent(sort) },
        });
    }

    @HandleAsyncErrors()
    static async getAllApplicationTypes(data: AllEntitiesByPagesDto) {
        const { size, page, sort, searchQuery } = data;
        return await restAxios.get(`private/drug-application-types`, {
            params: { page, size, searchQuery, sort: sort && encodeURIComponent(sort) },
        });
    }

    @HandleAsyncErrors()
    static async getAllPrescriptionsPatient(data: GetAllPrescriptionsDto) {
        const { size, isPrivate, page, patientId, sort, status } = data;
        return await restAxios.get(`private/prescriptions`, {
            params: { page, isPrivate, size, patientId, status, sort: sort && encodeURIComponent(sort) },
        });
    }

    @HandleAsyncErrors()
    static async getAllPrescriptionsProvider(data: GetAllPrescriptionsDto) {
        const { size, isPrivate, page, patientId, sort, status } = data;
        return await restAxios.get(`private/prescriptions/patient/${patientId}/all`, {
            params: { page, isPrivate, size, patientId, status, sort: sort && encodeURIComponent(sort) },
        });
    }

    @HandleAsyncErrors()
    static async addPrescriptionPatient(data: CreatePrescriptionDto) {
        const { name, pharmacyId = 1, prescriptionComponentList } = data;
        return await restAxios.post(`private/prescriptions`, {
            name,
            pharmacyId,
            prescriptionComponentList,
        });
    }

    @HandleAsyncErrors()
    static async addPrescriptionProvider(data: { data: CreatePrescriptionDto; id: number }) {
        const { name, pharmacyId = 1, prescriptionComponentList } = data.data;
        const { id } = data;
        return await restAxios.post(`private/prescriptions/patient/${id}`, {
            name,
            pharmacyId,
            prescriptionComponentList,
        });
    }

    @HandleAsyncErrors()
    static async getPrescriptionByIdPatient(id: number) {
        return await restAxios.get(`private/prescriptions/${id}`);
    }

    @HandleAsyncErrors()
    static async getPrescriptionByIdProvider(data: { patientId: number; prescriptionId: number }) {
        const { patientId, prescriptionId } = data;
        return await restAxios.get(`private/prescriptions/${prescriptionId}/patient/${patientId}`);
    }

    @HandleAsyncErrors()
    static async updatePrescriptionPatient(data: UpdatePrescriptionDto) {
        const { name, pharmacyId = 1, prescriptionComponentList, id } = data;
        return await restAxios.put(`private/prescriptions/${id}`, { name, pharmacyId, prescriptionComponentList });
    }

    @HandleAsyncErrors()
    static async updatePrescriptionProvider(data: { data: UpdatePrescriptionDto; patientId: number }) {
        const { name, pharmacyId = 1, prescriptionComponentList, id } = data.data;
        const { patientId } = data;
        return await restAxios.put(`private/prescriptions/${id}/patient/${patientId}`, {
            name,
            pharmacyId,
            prescriptionComponentList,
        });
    }

    @HandleAsyncErrors()
    static async removePrescriptionPatient(id: number) {
        return await restAxios.delete(`private/prescriptions/${id}`);
    }

    @HandleAsyncErrors()
    static async removePrescriptionProvider(data: { prescriptionId: number; patientId: number }) {
        const { prescriptionId, patientId } = data;
        return await restAxios.delete(`private/prescriptions/${prescriptionId}/patient/${patientId}`);
    }
}

export default PrescriptionApi;
