import { RoleOptions } from '../../constants/roleOptions';
import { v } from 'overshom-valid';

export enum MeasurementSystem {
    METRIC = 'METRIC',
    IMPERIAL = 'IMPERIAL'
}

const userObject = {
    id: v.Number(),
    firstName: v.String(),
    lastName: v.String(),
    email: v.String(),
    role: v.Enum(RoleOptions),
    createdBy: v.Number().optional(),
    setup: v.Boolean(),
    merchant: v.Boolean(),
    avatarThumbnailUrl: v.String().optional(),
    measurementSystem: v.Enum(MeasurementSystem),
};

export class UserModel extends v.class(userObject) {}
export const userDto = v.Object(userObject);

