import { v } from 'overshom-valid';
import { GenderOptionsEnumUpperCase } from '../../constants/genderOptions';
import { AcceptProviderStatus } from '../../constants/acceptProviderStatus';

const patient = {
    id: v.Number(),
    firstName: v.String(),
    lastName: v.String(),
    email: v.String(),
    phone: v.String().optional(),
    avatarUrl: v.String().optional(),
    avatarThumbnailUrl: v.String().optional(),
    birthDate: v.String().optional(),
    createdBy: v.Number().optional(),
};

const patientExtended = Object.assign(
    {},
    {
        ...patient,
        gender: v.Enum(GenderOptionsEnumUpperCase),
        streetAddress1: v.String().optional(),
        streetAddress2: v.String().optional(),
        city: v.String().optional(),
        state: v.String().optional(),
        zip: v.String().optional(),
        linkingStatus: v.Enum(AcceptProviderStatus).optional(),
    }
);

const provider = Object.assign(
    {},
    {
        ...patient,
        providerCompanyId: v.Number().optional(),
        providerCompanyName: v.String().optional(),
    }
);

export const GetPatientValid = v.class(patientExtended);

export const GetLinkingStatusValid = v.class({
    currentProvider: v.Object(provider).optional(),
    pendingProvider: v.Object(provider).optional(),
});

export function StringValidator(someData: unknown): string {
    return v.String().validate(someData);
}

export const GetLinkedPatientsValid = v.class({
    result: v.Array(v.Object(patient)),
    total: v.Number(),
});

export const ApprovePatientRequestValid = v.String();
