import React, { FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { InputTypeSelector } from './inputTypeSelector';
import { Button, FormControl, FormLabel, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../../theme/colors';
import { HintInfo } from './hintInfo';
import PrimaryButton from '../../../../shared/button/PrimaryButton';
import HeightFormImperialSystem from '../../components/HeightFormImperialSystem';
import { UnitWeightManagement } from '../../../../constants/weightManagement/unit.constants';

export enum WeightManagementFormType {
    DEFAULT = 'DEFAULT',
    LARGE = 'LARGE',
}

export enum InputType {
    DROPDOWN,
    STRING,
    NUMBER,
    SELECT,
    RADIO,
    DATE,
}

export interface IFormField {
    label: string;
    labelHint?: string;
    labelInfo?: ReactNode;
    placeholder?: string;
    fieldType: InputType;
    unit?: string;
    validators?: Array<(value: any) => boolean>;
    readonly?: boolean;
    index?: number;

    radioButtonValues?: {
        label: string;
        value: any;
    }[];
}

export interface IRenderableFormField extends IFormField {
    dataField: string;
    value: any;
    valid: boolean;
}

export interface IFormProps<T> {
    type: WeightManagementFormType;
    data: T;
    fields: Partial<Record<keyof T, IFormField>>;
    onChange?: (data: T) => void;
    onSubmit?: (data: T) => void;
    submitBtnText?: string;
    onCancel?: (data: T) => void;
    cancelBtnText?: string;
    readOnly?: boolean;
}

const createStyles = makeStyles({
    largeForm: {
        width: '80%',
        margin: '10px auto',
    },
    defaultForm: {
        width: '90%',
        margin: '10px auto',
    },
    formControl: {
        display: 'flex',
        alignItems: 'center',
        margin: '35px auto !important',
        flexDirection: 'row',
    },
    formControlLarge: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '45px auto !important',
        flexDirection: 'row',
    },
    labelContentWrapper: {
        display: 'flex',
    },
    labelTypography: {
        fontSize: '14px',
        color: colors.textSecondary,
        textAlign: 'right',
    },
    labelHint: {
        color: colors.gray4,
        fontSize: '12px',
        width: '80%',
    },
    inputWrapper: {
        marginTop: -20,
        width: '65%',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
    },
    inputWrapperLarge: {},
    unitText: {
        fontSize: '12px',
    },
});

const FormLabelStyled = styled(FormLabel)`
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
`;

const ButtonsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    text-align: center;
    align-items: center;
`;

const RightContentWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 35px;
    margin-top: 25px;
`;

export function Form<T>(props: IFormProps<T>) {
    const {
        data,
        submitBtnText = 'Save',
        cancelBtnText = 'Cancel',
        onCancel,
        onSubmit,
        onChange,
        fields,
        type,
        readOnly = false,
    } = props;

    const [initialState, setInitialState] = useState<Partial<T>>({});

    const classes = createStyles();
    const fieldsArray: IRenderableFormField[] = useMemo(() => {
        return (
            Object.entries(data)
                // show NOT all the fields of the passed data but only the ones passed in fields
                .filter(([key]) => fields[key as keyof T])
                .map(([key, dataFieldValue]) => {
                    const isInInitialState = initialState[key as keyof T] === dataFieldValue;
                    const EMPTY_FORM_FIELD_VALUE = '';
                    const formFieldData = fields[key as keyof T] as IFormField;
                    const isValid: boolean =
                        isInInitialState ||
                        !formFieldData.validators ||
                        !formFieldData.validators.length ||
                        !formFieldData.validators.some(validator => !validator(dataFieldValue));

                    const shouldSubstituteValue = isInInitialState && !formFieldData.readonly && dataFieldValue === 0;

                    return {
                        ...fields[key as keyof T],
                        dataField: key,
                        value: shouldSubstituteValue ? EMPTY_FORM_FIELD_VALUE : dataFieldValue,
                        valid: isValid,
                    };
                })
                .sort((r1, r2) => (r1.index || 0) - (r2.index || 0))
        );
    }, [data, initialState, fields]);

    useEffect(() => {
        setInitialState(data);
        // eslint-disable-next-line
    }, []);

    const isDefaultFormType = useMemo(() => type === WeightManagementFormType.DEFAULT, [type]);

    // since untouched inputs should not be marked as invalid initially, we need to re-run validation
    // in order to disable the form
    const isFormValid = useMemo(() => {
        return fieldsArray
            .map(field => {
                return (
                    !field.validators ||
                    !field.validators.length ||
                    !field.validators.some(validator => !validator(field.value))
                );
            })
            .some(field => !field);
    }, [fieldsArray]);

    const submitHandler = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (onSubmit && !isFormValid) {
                onSubmit(data);
            }
        },
        [onSubmit, isFormValid, data]
    );

    const isFieldReadOnly = useCallback(
        (field: IRenderableFormField) => {
            return readOnly || !!field.readonly;
        },
        [readOnly]
    );

    const shouldRenderImperialInputHeight = useCallback((field: IRenderableFormField) => {
        return field.dataField === 'height' && field.unit === UnitWeightManagement.INCH;
    }, []);

    return (
        <form onSubmit={submitHandler} className={isDefaultFormType ? classes.defaultForm : classes.largeForm}>
            {fieldsArray.map(field => {
                return (
                    <FormControl
                        className={isDefaultFormType ? classes.formControl : classes.formControlLarge}
                        key={field.dataField}
                    >
                        <FormLabelStyled>
                            <LabelWrapper>
                                <Typography className={classes.labelTypography} variant={'h6'}>
                                    {field.label}
                                </Typography>
                                {field.labelInfo && !isDefaultFormType && <HintInfo>{field.labelInfo}</HintInfo>}
                            </LabelWrapper>
                            {field.labelHint && (
                                <Typography variant={'body2'} className={classes.labelHint}>
                                    {field.labelHint}
                                </Typography>
                            )}
                        </FormLabelStyled>
                        {shouldRenderImperialInputHeight(field) ? (
                            <HeightFormImperialSystem
                                field={field}
                                onChange={(value: number) => {
                                    if (onChange) {
                                        onChange({
                                            ...data,
                                            [field.dataField]: value,
                                        });
                                    }
                                }}
                                isDefaultFormType={isDefaultFormType}
                                readonly={isFieldReadOnly(field)}
                            />
                        ) : (
                            <div className={isDefaultFormType ? classes.inputWrapper : classes.inputWrapperLarge}>
                                {InputTypeSelector<any>({
                                    fieldData: field,
                                    readonly: isFieldReadOnly(field),
                                    type: field.fieldType,
                                    isValid: field.valid,
                                    onChange: value => {
                                        if (onChange) {
                                            onChange({
                                                ...data,
                                                [field.dataField]: value,
                                            });
                                        }
                                    },
                                })}
                                {(field.unit || field.labelInfo) && (
                                    <RightContentWrapper>
                                        {field.unit && (
                                            <Typography variant={'button'} className={classes.unitText}>
                                                {field.unit}
                                            </Typography>
                                        )}
                                        {field.labelInfo && isDefaultFormType && <HintInfo>{field.labelInfo}</HintInfo>}
                                    </RightContentWrapper>
                                )}
                            </div>
                        )}
                    </FormControl>
                );
            })}
            {(!!onSubmit || !!onCancel) && (
                <ButtonsSection>
                    {onSubmit && (
                        <PrimaryButton disabled={isFormValid} style={{ margin: '10px auto' }} type="submit">
                            {submitBtnText}
                        </PrimaryButton>
                    )}
                    {/*TODO: move to separate button component*/}
                    {onCancel && (
                        <Button
                            style={{ width: '30%' }}
                            onClick={() => {
                                onCancel && onCancel(data);
                            }}
                            type="reset"
                        >
                            <Typography variant={'button'}>{cancelBtnText}</Typography>
                        </Button>
                    )}
                </ButtonsSection>
            )}
        </form>
    );
}
