import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import theme from '../../theme/theme';

type StyleType = {
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
};

const createStyles = makeStyles({
    root: (props: StyleType) => ({
        backgroundColor: colors.white,
        transition: theme.transitionBackground,
        color: props.disabled ? colors.gray1 : colors.redMain,
        border: props.disabled ? `1px solid ${colors.gray1}` : `1px solid ${colors.redMain}`,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: props.disabled ? colors.white : `${colors.redLight}`,
            color: props.disabled ? colors.gray1 : colors.white,
        },
        '&:active': {
            backgroundColor: props.disabled ? colors.white : colors.redDark,
            color: props.disabled ? colors.gray1 : colors.white,
        },
    }),
    buttonText: (props: StyleType) => ({
        fontSize: props.size === 'small' ? '12px' : '14px',
    }),
});

interface InjectedProps {
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    onClick?: any;
    otherProps?: any;
    style?: object;
    type?: 'submit' | 'reset' | 'button';
}

type PropsTypes = InjectedProps;

const SecondaryButton: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    return (
        <Button
            style={props.style}
            {...(props.otherProps ? props.otherProps : {})}
            classes={{ root: classes.root }}
            size={props.size}
            onClick={props.onClick}
            disableTouchRipple={props.disabled}
            type={props.type ? props.type : 'button'}
        >
            <Typography variant="button" classes={{ root: classes.buttonText }}>
                {props.children}
            </Typography>
        </Button>
    );
};

export default SecondaryButton;
