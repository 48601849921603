import { put, call, takeEvery } from 'redux-saga/effects';
import * as patientActions from '../../actions/patient.actions';
import * as providerMyPatientsActions from '../../actions/providerMyPatients.actions';
import PatientApi from '../../../api/patient/PatientApi';
import { typedCall } from '../../../shared/utils/typedYieldWrapper';

function* getAllPatients(action: patientActions.getALLPatientsType) {
    try {
        const value = yield call(PatientApi.getAllPatients, action.payload);
        const response = typedCall(PatientApi.getAllPatients, value);
        if (action.payload.overrideRecords) {
            yield put({
                type: patientActions.ActionNames.GET_ALL_PATIENTS_OVERRIDE_SUCCESS,
                payload: {
                    data: response.result,
                    total: response.total,
                },
            });
        } else {
            yield put({
                type: patientActions.ActionNames.GET_ALL_PATIENTS_UPDATE_SUCCESS,
                payload: {
                    data: response.result,
                    total: response.total,
                },
            });
        }
    } catch (e) {
        yield put({ type: patientActions.ActionNames.GET_ALL_PATIENTS_FAILED });
    }
}

function* getAllMyLinkedPatients(action: providerMyPatientsActions.getAllLinkedPatientsType) {
    try {
        const value = yield call(PatientApi.getLinkedPatients, action.payload);
        const response = typedCall(PatientApi.getLinkedPatients, value);
        if (action.payload.overrideRecords) {
            yield put({
                type:
                    providerMyPatientsActions.ProviderMyPatientsActionNames
                        .PROVIDER_GET_ALL_MY_PATIENTS_OVERRIDE_SUCCESS,
                payload: {
                    data: response.result,
                    total: response.total,
                },
            });
        } else {
            yield put({
                type:
                    providerMyPatientsActions.ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS_UPDATE_SUCCESS,
                payload: {
                    data: response.result,
                    total: response.total,
                },
            });
        }
    } catch (e) {
        yield put({
            type: providerMyPatientsActions.ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS_FAILED,
        });
    }
}

function* getMyPendingPatients(action: providerMyPatientsActions.getAllPendingPatientsType) {
    try {
        const value = yield call(PatientApi.getPendingPatients, action.payload);
        const response = typedCall(PatientApi.getPendingPatients, value);
        if (action.payload.overrideRecords) {
            yield put({
                type:
                    providerMyPatientsActions.ProviderMyPatientsActionNames
                        .PROVIDER_GET_MY_PENDING_PATIENTS_OVERRIDE_SUCCESS,
                payload: {
                    data: response.result,
                    total: response.total,
                },
            });
        } else {
            yield put({
                type:
                    providerMyPatientsActions.ProviderMyPatientsActionNames
                        .PROVIDER_GET_MY_PENDING_PATIENTS_UPDATE_SUCCESS,
                payload: {
                    data: response.result,
                    total: response.total,
                },
            });
        }
    } catch (e) {
        yield put({
            type: providerMyPatientsActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS_FAILED,
        });
    }
}

function* getMyInvitedPatients(action: providerMyPatientsActions.getAllInvitedPatientsType) {
    try {
        const value = yield call(PatientApi.getInvitedPatients, action.payload);
        const response = typedCall(PatientApi.getInvitedPatients, value);
        if (action.payload.overrideRecords) {
            yield put({
                type:
                    providerMyPatientsActions.ProviderMyPatientsActionNames
                        .PROVIDER_GET_MY_INVITED_PATIENTS_OVERRIDE_SUCCESS,
                payload: {
                    data: response.result,
                    total: response.total,
                },
            });
        } else {
            yield put({
                type:
                    providerMyPatientsActions.ProviderMyPatientsActionNames
                        .PROVIDER_GET_MY_INVITED_PATIENTS_UPDATE_SUCCESS,
                payload: {
                    data: response.result,
                    total: response.total,
                },
            });
        }
    } catch (e) {
        yield put({
            type: providerMyPatientsActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS_FAILED,
        });
    }
}

function* getPatient() {
    try {
        const value = yield call(PatientApi.getPatient);
        const response = typedCall(PatientApi.getPatient, value);
        yield put({ type: patientActions.ActionNames.GET_PATIENT_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: patientActions.ActionNames.GET_PATIENT_FAILED });
    }
}

function* getPatientById(action: patientActions.getPatientByIdType) {
    try {
        const value = yield call(PatientApi.getPatientById, action.payload);
        const response = typedCall(PatientApi.getPatientById, value);
        yield put({ type: patientActions.ActionNames.GET_PATIENT_BY_ID_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: patientActions.ActionNames.GET_PATIENT_BY_ID_FAILED });
    }
}

function* updatePatient(action: patientActions.updatePatientType) {
    try {
        const value = yield call(PatientApi.updatePatient, action.payload);
        const response = typedCall(PatientApi.updatePatient, value);
        yield put({ type: patientActions.ActionNames.UPDATE_PATIENT_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: patientActions.ActionNames.UPDATE_PATIENT_FAILED });
    }
}

function* uploadAvatar(action: patientActions.uploadAvatarType) {
    try {
        yield call(PatientApi.uploadAvatar, action.payload);
        yield put({ type: patientActions.ActionNames.UPLOAD_AVATAR_SUCCESS });
    } catch (e) {
        yield put({ type: patientActions.ActionNames.UPLOAD_AVATAR_FAILED });
    }
}

function* getLinkingStatus() {
    try {
        const value = yield call(PatientApi.getLinkingStatus);
        const response = typedCall(PatientApi.getLinkingStatus, value);
        yield put({ type: patientActions.ActionNames.GET_LINKING_STATUS_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: patientActions.ActionNames.GET_LINKING_STATUS_FAILED });
    }
}

function* setIsSetupToTrue() {
    yield call(PatientApi.setIsSetup);
}

function* rejectPatientRequest(action: patientActions.rejectPatientRequestType) {
    try {
        yield call(PatientApi.rejectPatientRequest, action.payload);
        yield put({ type: patientActions.ActionNames.REJECT_PATIENT_REQUEST_SUCCESS });
    } catch (e) {
        yield put({ type: patientActions.ActionNames.REJECT_PATIENT_REQUEST_FAILED });
    }
}

function* approvePatientRequest(action: patientActions.approvePatientRequestType) {
    try {
        yield call(PatientApi.approvePatientRequest, action.payload);
        yield put({ type: patientActions.ActionNames.APPROVE_PATIENT_REQUEST_SUCCESS });
    } catch (e) {
        yield put({ type: patientActions.ActionNames.APPROVE_PATIENT_REQUEST_FAILED });
    }
}

function* setupUnitsSystem(action: patientActions.setupMeasurementSystem) {
    try {
        yield call(PatientApi.setupMeasurementSystem, action.payload);
        yield put({ type: patientActions.ActionNames.SETUP_MEASUREMENT_SYSTEM_SUCCESS });
    } catch (e) {
        yield put({ type: patientActions.ActionNames.SETUP_MEASUREMENT_SYSTEM_FAILED });
    }
}

export default function* PatientSagas() {
    yield takeEvery(
        providerMyPatientsActions.ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS,
        getAllMyLinkedPatients
    );
    yield takeEvery(
        providerMyPatientsActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS,
        getMyPendingPatients
    );
    yield takeEvery(
        providerMyPatientsActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS,
        getMyInvitedPatients
    );
    yield takeEvery(patientActions.ActionNames.GET_ALL_PATIENTS, getAllPatients);
    yield takeEvery(patientActions.ActionNames.GET_PATIENT, getPatient);
    yield takeEvery(patientActions.ActionNames.GET_LINKING_STATUS, getLinkingStatus);
    yield takeEvery(patientActions.ActionNames.GET_PATIENT_BY_ID, getPatientById);
    yield takeEvery(patientActions.ActionNames.UPDATE_PATIENT, updatePatient);
    yield takeEvery(patientActions.ActionNames.UPLOAD_AVATAR, uploadAvatar);
    yield takeEvery(patientActions.ActionNames.SET_IS_SETUP_TO_TRUE, setIsSetupToTrue);
    yield takeEvery(patientActions.ActionNames.REJECT_PATIENT_REQUEST, rejectPatientRequest);
    yield takeEvery(patientActions.ActionNames.APPROVE_PATIENT_REQUEST, approvePatientRequest);
    yield takeEvery(patientActions.ActionNames.SETUP_MEASUREMENT_SYSTEM, setupUnitsSystem);
}
