import {PatientModel} from "../../../models/responses/patient.response";
import * as providerMyPatientActions from "../../actions/providerMyPatients.actions";
import {AcceptProviderStatus} from "../../../constants/acceptProviderStatus";

export class ProviderMyPatientsState {
    myLinkedPatients: PatientModel[] = [];
    totalLinkedPatients: number = 0;
    pageLinkedPatients: number = 0;

    myPendingPatients: PatientModel[] = [];
    totalPendingPatients: number = 0;
    pagePendingPatients: number = 0;

    myInvitedPatients: PatientModel[] = [];
    totalInvitedPatients: number = 0;
    pageInvitedPatients: number = 0;

    loading: boolean = false;

    searchQueryPendingPatients: string = '';
    searchQueryLinkedPatients: string = '';
    searchQueryInvitedPatients: string = '';

    patientTabIndex: number = 1;
}

const initialState = new ProviderMyPatientsState();

export function providerMyPatientsReducer(state = initialState, action: providerMyPatientActions.ProviderMyPatientActions): ProviderMyPatientsState {
    switch (action.type) {
        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS: {
            return {
                ...state,
                loading: true
            }

        }
        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS_UPDATE_SUCCESS: {
            const myAnotherLinkedPatients = action.payload.data.map((p: PatientModel) => {
                return {
                    ...p,
                    linkingStatus: AcceptProviderStatus.APPROVED
                }
            });

            const isEmptyString = state.searchQueryLinkedPatients.trim().length === 0;
            const newPatientsList = state.myLinkedPatients.concat(myAnotherLinkedPatients);
            const isRedundant = newPatientsList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalLinkedPatients: action.payload.total,
                myLinkedPatients: isEmptyString && !isRedundant ? newPatientsList : myAnotherLinkedPatients,
            };
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS_OVERRIDE_SUCCESS:
            const myLinkedPatients = action.payload.data.map((p: PatientModel) => {
                return {
                    ...p,
                    linkingStatus: AcceptProviderStatus.APPROVED
                }
            });
            return {
                ...state,
                myLinkedPatients,
                totalLinkedPatients: action.payload.total,
                loading: false,
            };

        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_ALL_MY_PATIENTS_FAILED: {
            return {
                ...state,
                loading: false
            }
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.SET_FILTER_QUERY_LINKED_PATIENTS: {
            const isEmptyString = action.payload.trim().length === 0;

            return {
                ...state,
                searchQueryLinkedPatients: action.payload,
                loading: isEmptyString ? false : state.loading,
                pageLinkedPatients: 0,
                myLinkedPatients: isEmptyString ? [] : state.myLinkedPatients,
                totalLinkedPatients: 0,
            };
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.SET_FILTER_QUERY_PENDING_PATIENTS: {
            const isEmptyString = action.payload.trim().length === 0;

            return {
                ...state,
                searchQueryPendingPatients: action.payload,
                loading: isEmptyString ? false : state.loading,
                pagePendingPatients: 0,
                myPendingPatients: isEmptyString ? [] : state.myPendingPatients,
                totalPendingPatients: 0,
            };
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.SET_FILTER_QUERY_INVITED_PATIENTS: {
            const isEmptyString = action.payload.trim().length === 0;

            return {
                ...state,
                searchQueryInvitedPatients: action.payload,
                loading: isEmptyString ? false : state.loading,
                pageInvitedPatients: 0,
                myInvitedPatients: isEmptyString ? [] : state.myInvitedPatients,
                totalInvitedPatients: 0,
            };
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.INCREASE_PAGE_NUMBER_LINKED_PATIENTS: {
            return {
                ...state,
                pageLinkedPatients: state.pageLinkedPatients + 1,
            }
        }


        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS: {
            return {
                ...state,
                loading: true
            }
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS_OVERRIDE_SUCCESS: {
            const myPendingPatients = action.payload.data.map((p: PatientModel) => {
                return {
                    ...p,
                    linkingStatus: AcceptProviderStatus.PENDING
                }
            });
            return {
                ...state,
                myPendingPatients,
                totalPendingPatients: action.payload.total,
                loading: false,
            }

        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS_UPDATE_SUCCESS: {
            const myPendingPatients = action.payload.data.map((p: PatientModel) => {
                return {
                    ...p,
                    linkingStatus: AcceptProviderStatus.PENDING
                }
            });

            const isEmptyString = state.searchQueryPendingPatients.trim().length === 0;
            const newPatientsList = state.myPendingPatients.concat(myPendingPatients);
            const isRedundant = newPatientsList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalPendingPatients: action.payload.total,
                myPendingPatients: isEmptyString && !isRedundant ? newPatientsList : myPendingPatients,
            };
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_PENDING_PATIENTS_FAILED: {
            return {
                ...state,
                loading: false
            }
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.INCREASE_PAGE_NUMBER_PENDING_PATIENTS: {
            return {
                ...state,
                pagePendingPatients: state.pagePendingPatients + 1,
            }
        }


        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS: {
            return {
                ...state,
                loading: true
            }
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS_OVERRIDE_SUCCESS: {
            const myInvitedPatients = action.payload.data.map((p: PatientModel) => {
                return {
                    ...p,
                    linkingStatus: null,
                }
            });
            return {
                ...state,
                myInvitedPatients,
                totalInvitedPatients: action.payload.total,
                loading: false,
            }

        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS_UPDATE_SUCCESS: {
            const myInvitedPatients = action.payload.data.map((p: PatientModel) => {
                return {
                    ...p,
                    linkingStatus: null
                }
            });

            const isEmptyString = state.searchQueryInvitedPatients.trim().length === 0;
            const newPatientsList = state.myInvitedPatients.concat(myInvitedPatients);
            const isRedundant = newPatientsList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalInvitedPatients: action.payload.total,
                myInvitedPatients: isEmptyString && !isRedundant ? newPatientsList : myInvitedPatients,
            };
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.PROVIDER_GET_MY_INVITED_PATIENTS_FAILED: {
            return {
                ...state,
                loading: false
            }
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.INCREASE_PAGE_NUMBER_INVITED_PATIENTS: {
            return {
                ...state,
                pageInvitedPatients: state.pageInvitedPatients + 1,
            }
        }

        case providerMyPatientActions.ProviderMyPatientsActionNames.SET_PATIENT_PAGE_TAB_INDEX: {
            return {
                ...state,
                patientTabIndex: action.payload,
            }
        }

        default:
            return state;
    }
}
