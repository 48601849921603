import React, { useCallback, useEffect, useState } from 'react';
import Footer from '../../../shared/Footer';
import OurWhyMobile from './components/OurWhyMobile';
import PhotoWithDescriptionMobile from './components/PhotoWithDescriptionMobile';
import JCR from '../../../static/images/landing/about/JCR.jpg';
import JS from '../../../static/images/landing/about/JS.jpg';
import FounderDto from '../../../models/dto/founder.dto';
import ServicesMobile from './components/ServicesMobile';
import TermsMobile from './components/TermsMobile';
import MapMobile from './components/MapMobile';
import styled from 'styled-components';
import SpecialButton from './components/SpecialButton';
import Privacy from './components/Privacy';
import { mediaQuery } from '../../../constants/device';

const FirstFounder: FounderDto = {
    image: JCR,
    name: 'JC Ramey',
    about: `Co-founder, software entrepreneur, problem solver and a wrestler`,
};

let SecondFounder: FounderDto = {
    image: JS,
    name: `Josh Swearingen`,
    about: `Co-Founder, Workout Fanatic, and Anti-aging Enthusiast`,
};

const leftButtonText = `Terms & Conditions`;
const rightButtonText = `Privacy Policy`;

const FlexWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${mediaQuery.tablet} {
        flex-direction: row;
    }
`;

const TermsWrap = styled.div`
    margin-top: -200px;

    ${mediaQuery.tablet} {
        position: relative;
        padding-left: 90px;
        margin-top: -310px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 53px;
    margin-top: auto;
    overflow: hidden;

    ${mediaQuery.tablet} {
        justify-content: end;
        padding-left: 50px;
    }
`;

const AboutPage = (): JSX.Element => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const leftIndex = 0;
    const rightIndex = 1;

    const [activeTab, setActiveTab] = useState(leftIndex);

    const leftButtonHandler = useCallback(() => setActiveTab(leftIndex), [leftIndex]);
    const rightButtonHandler = useCallback(() => setActiveTab(rightIndex), [rightIndex]);

    return (
        <>
            <OurWhyMobile />
            <FlexWrap>
                <PhotoWithDescriptionMobile founder={FirstFounder} />
                <PhotoWithDescriptionMobile founder={SecondFounder} />
            </FlexWrap>
            <ServicesMobile />
            <TermsWrap>
                <ButtonWrapper>
                    <SpecialButton isActive={activeTab === leftIndex} changeTab={leftButtonHandler}>
                        {leftButtonText}
                    </SpecialButton>
                    <SpecialButton isActive={activeTab === rightIndex} changeTab={rightButtonHandler}>
                        {rightButtonText}
                    </SpecialButton>
                </ButtonWrapper>
                {activeTab === 0 ? <TermsMobile /> : <Privacy />}
            </TermsWrap>

            <MapMobile />
            <Footer />
        </>
    );
};

export default AboutPage;
