import React, { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import Header from '../shared/header/Header';
import { CardWithBigShadow } from '../shared/card/CardWithBigShadow';
import { Typography } from '@material-ui/core';
import PrimaryButton from '../shared/button/PrimaryButton';
import styled from 'styled-components';
import CancelLink from '../shared/link/CancelLink';
import { withRouter, RouteComponentProps } from 'react-router';
import { colors } from '../theme/colors';

interface Props extends RouteComponentProps<any> {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    eventId: string;
}

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 16px;
`;

const InfoWrapper = styled.div`
    text-align: center;
    margin-top: 24px;

    p {
        font-size: 14px;
        color: ${colors.gray4};
    }
`;

// TODO
// Replace class component with function one
// when getDerivedStateFromError and componentDidCatch methods
// are available in hooks

// Error is being shown, but in dev mode there is still an overlay window.
//
// The create-react-app package has a tool called the react-overlay-error.
// This shows error messages from the console as an overlay over your app so you can easily check the stack trace and debug.
// This won't show up in production mode, it's just a development tool duplicating the normal browser console.
// You can hide this by pressing Escape to see your overlay again.

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        eventId: '',
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, eventId: '' };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    public redirectToRoot = () => {
        this.setState({ hasError: false, eventId: '' });
        this.props.history.push('/');
    };

    public render() {
        if (this.state.hasError) {
            return (
                <>
                    <Header />
                    <CardWithBigShadow styles={{ marginTop: 54, width: '75%', paddingTop: 36 }}>
                        <Typography variant={'h2'} style={{ textAlign: 'center' }}>
                            Something went wrong.
                        </Typography>

                        <InfoWrapper>
                            <Typography variant={'body1'}>Seems like some bug occured.</Typography>
                            <Typography variant={'body1'}>
                                You can just refresh the page or leave your feedback about the bug.
                            </Typography>
                        </InfoWrapper>

                        <ButtonWrapper>
                            <PrimaryButton onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
                                Send Feedback
                            </PrimaryButton>
                            <CancelLink onClick={this.redirectToRoot} />
                        </ButtonWrapper>
                    </CardWithBigShadow>
                </>
            );
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
