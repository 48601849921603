import { restAxios } from '../restAxios';
import HandleAsyncErrors from '../../helpers/decorators/handleAsyncErrors';
import { BloodTargetRow } from '../../pages/bloodTarget/AddBloodTargets';
import { BloodtargetReponce } from '../../models/responses/bloodtarget.reponce';

class BloodTargetApi {
    @HandleAsyncErrors()
    static async getBloodTargetById(id: number) {
        const res = await restAxios.get(`private/blood-targets/${id}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async addBloodTargetsAdmin(data: BloodTargetRow[]) {
        const res = await restAxios.post(`private/blood-targets`, data);
        return res.data;
    }

    @HandleAsyncErrors()
    static async editBloodTargetAdmin(data: BloodtargetReponce[]) {
        const res = await restAxios.put(`private/blood-targets`, data);
        return res.data;
    }

    @HandleAsyncErrors()
    static async removeBloodTargetAdmin(id: number) {
        const res = await restAxios.delete(`private/blood-targets/${id}`);
        return res.data;
    }

    @HandleAsyncErrors()
    static async addBloodTargetsUsers(data: BloodTargetRow[]) {
        const res = await restAxios.post(`private/blood-targets/users`, data);
        return res.data;
    }

    @HandleAsyncErrors()
    static async editBloodTargetUsers(data: BloodtargetReponce[]) {
        const res = await restAxios.put(`private/blood-targets/users`, data);
        return res.data;
    }

    @HandleAsyncErrors()
    static async removeBloodTargetUsers(id: number) {
        const res = await restAxios.delete(`private/blood-targets/${id}/users`);
        return res.data;
    }
}

export default BloodTargetApi;
