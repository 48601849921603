import React from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import { BloodtargetReponce } from '../../../models/responses/bloodtarget.reponce';
import { GenderOptionsEnum } from '../../../constants/genderOptions';

interface InjectedProps {
    targetList: BloodtargetReponce[];
    patientGender: string;
}

const createStyles = makeStyles({
    head: {
        backgroundColor: colors.gray1,
    },
    darkText: {
        fontSize: '12px',
        color: colors.textPrimary,
    },
    lightText: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
    lightTextLabel: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'capitalize',
    },
    tableCell: {
        padding: 0,
        borderBottom: 'none',
        '&:last-child': {
            padding: 0,
        },
    },
    headerTableRow: {
        width: '90%',
        padding: '0 2.5%',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: '20% 40% 30% 10%',
    },
    bodyTableRow: {
        width: '90%',
        padding: '0 2.5%',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: '20% 40% 30% 10%',
        borderBottom: `1px solid ${colors.gray2}`,
    },
});

type PropsTypes = InjectedProps;

const ReferenceRangeTable: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    return (
        <Table>
            <TableHead classes={{ root: classes.head }}>
                <TableRow classes={{ root: classes.headerTableRow }}>
                    <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1" classes={{ root: classes.darkText }}>
                            Label
                        </Typography>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1" classes={{ root: classes.darkText }}>
                            Target
                        </Typography>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1" classes={{ root: classes.darkText }}>
                            Ref. Range
                        </Typography>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1" classes={{ root: classes.darkText }}>
                            Units
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.targetList.map((target, index) => (
                    <TableRow classes={{ root: classes.bodyTableRow }} key={index}>
                        <TableCell classes={{ root: classes.tableCell }}>
                            <Typography variant="body1" classes={{ root: classes.darkText }}>
                                {target.label}
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }}>
                            <Typography variant="body1" classes={{ root: classes.lightTextLabel }}>
                                {target.target}
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }}>
                            <Typography variant="body1" classes={{ root: classes.darkText }}>
                                {props.patientGender.toLowerCase() === GenderOptionsEnum.MALE
                                    ? `${typeof target.maleMinRange === 'number' ? target.maleMinRange : '-'} - ${typeof target.maleMaxRange === 'number' ? target.maleMaxRange : '-'}`
                                    : `${typeof target.femaleMinRange === 'number' ? target.femaleMinRange : '-'} - ${typeof target.femaleMaxRange === 'number' ? target.femaleMaxRange : '-'}`}
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }}>
                            <Typography variant="body1" classes={{ root: classes.lightText }}>
                                {target.unit}
                            </Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default ReferenceRangeTable;
