import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import styled from 'styled-components';

const createStyles = makeStyles({
    button: {
        width: '170px',
        minWidth: '170px',
        height: '170px',
        padding: '0 !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: `${colors.white}`,
        borderRadius: '30px',

    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 0 20px 0 !important',
        height: '-webkit-fill-available',
        justifyContent: 'space-between',
    },
    text: {
        color: colors.textSecondary,
        fontSize: '18px',
    },
    disabled: {
        '& span': {
            opacity: 0.2,
        },
    },
    badgeText: {
        color: colors.textSecondary,
        fontSize: '17px',
    },
});

interface InjectedProps {
    img: string;
    disabled?: boolean;
    onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
const Wrapper = styled.div`
    position: relative;
`;

const Badge = styled.div`
    position: absolute;
    left: 11%;
    top: 40%;
    transform: rotate(11deg);
    background-color: rgba(225, 225, 225, 0.8);
    padding: 8px;
    border-radius: 12px;
`;

type PropsTypes = InjectedProps;

const BigMenuButton: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    return (
        <Wrapper>
            <Button
                onClick={props.onClick}
                classes={{ root: classes.button, label: classes.label, disabled: classes.disabled }}
                disabled={props.disabled}
            >
                <img src={props.img} alt="" />
                <Typography variant="h2" classes={{ root: classes.text }}>
                    {props.children}
                </Typography>
            </Button>
            {props.disabled && (
                <Badge>
                    <Typography variant={'button'} classes={{ root: classes.badgeText }}>
                        Coming soon
                    </Typography>
                </Badge>
            )}
        </Wrapper>
    );
};

export default BigMenuButton;
