import React from 'react';
import styled from 'styled-components';
import LandingBigHeading from '../../../shared/LandingBigHeading';
import RegularText from '../../shared/RegularText';
import PrimaryButton from '../../../../shared/button/PrimaryButton';
import theme from '../../../../theme/theme';
import { colors } from '../../../../theme/colors';
import { mediaQuery } from '../../../../constants/device';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import patientHeaderBackground from '../../../../static/images/patient/patientHeaderBackground.png';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mediaQuery.laptop} {
        height: 100%;
        flex-direction: row-reverse;
        padding: 0 5%;
        justify-content: space-between;
    }
    ${mediaQuery.laptopL} {
        height: 100%;
        padding: 0 6% 0 11.5%;
    }
`;

const TextWrapper = styled.div`
    padding: 12px 20px 56px 20px;
    text-align: justify;
    ${mediaQuery.laptop} {
        padding: 12px 0 56px 0;
    }
`;

const HeadingWrapper = styled.div`
    margin-top: 12px;
`;

const VideoWrapper = styled.div`
    box-shadow: ${theme.boxShadowGray};
    ${mediaQuery.laptop} {
        box-shadow: none;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mediaQuery.laptop} {
        align-items: flex-start;
        width: 45%;
    }
    ${mediaQuery.laptopL} {
        width: 33%;
    }
`;

const BackgroundWrapper = styled.div`
    ${mediaQuery.laptop} {
        height: 500px;
        background-size: 140%;
        background-repeat: no-repeat;
        background-image: url(${patientHeaderBackground});
    }
    ${mediaQuery.laptopL} {
        height: 600px;
    }
    ${mediaQuery.desktop} {
        height: 800px;
    }
`;

const VideoBackgroundWrapper = styled.div`
    padding: 12px;
    background-image: linear-gradient(#dedede, ${colors.white});

    ${mediaQuery.tablet} {
        padding: 24px;
    }

    ${mediaQuery.laptop} {
        width: 50%;
        padding: 0;
        margin-top: 60px;
        background-image: none;
    }
`;

const PatientHeaderBlock: React.FC = () => {
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    return (
        <BackgroundWrapper>
            <Wrapper>
                <VideoBackgroundWrapper>
                    <VideoWrapper>
                        <video controls width="100%'">
                            <source src={require('../../../../static/UPmed_Patient.mp4')} type="video/mp4" />
                        </video>
                    </VideoWrapper>
                </VideoBackgroundWrapper>
                <Content>
                    <HeadingWrapper>
                        <LandingBigHeading> Are you {isLaptop ? '' : <br />} frustrated?</LandingBigHeading>
                    </HeadingWrapper>
                    <TextWrapper>
                        <RegularText>
                            {' '}
                            Uniting a fragmented medical provider system, a frustrated patient base and a lack of
                            technological support for an alternative health model that bucks the trend of traditional
                            “sickcare” medicine.
                        </RegularText>
                    </TextWrapper>
                    <PrimaryButton>Your Community Awaits</PrimaryButton>
                </Content>
            </Wrapper>
        </BackgroundWrapper>
    );
};

export default PatientHeaderBlock;
