import React from 'react';
import styled from 'styled-components';
import {colors} from '../../theme/colors';
import {RoleOptions} from '../../constants/roleOptions';
import providerAvatar from '../../static/images/provider/providerAvatar.svg';
import defaultAvatar from '../../static/icons/defaultAvatar.svg';
import {UserModel} from '../../models/responses/user.response';
import {makeStyles} from '@material-ui/core';

const Avatar = styled.div<any>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    background-color: ${colors.textSecondary};
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100px;
    border: 1px solid ${colors.gray3};
    margin-top: -5px;
`;

const createStyles = makeStyles({
    avatarImg: {
        width: '100%',
        height: '100%',
        borderRadius: '20px',
    },
    defaultAvatarImg: {
        width: '26px',
        height: '26px',
        borderRadius: '20px',
    },
});

const HeaderAvatar: React.FC<{ user: UserModel }> = (props) => {
    const {user} = props;
    const classes = createStyles(props);

    return (
        <Avatar>
            <img
                src={
                    user && user.avatarThumbnailUrl
                        ? user.avatarThumbnailUrl
                        : user.role === RoleOptions.PROVIDER
                        ? providerAvatar
                        : defaultAvatar
                }
                alt=""
                className={user && !user.avatarThumbnailUrl ? classes.defaultAvatarImg : classes.avatarImg}
            />
        </Avatar>
    );
};

export default HeaderAvatar;
