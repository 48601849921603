import * as pharmaciesActions from '../../actions/pharmacies.actions';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';

export class StateModel {
    allPharmacies: BaseDropdownItem[] = [];
    page: number = 0;
    totalPharmacies: number | null = null;
    loading: boolean = false;
    searchQuery: string = '';
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: pharmaciesActions.Actions) {
    switch (action.type) {
        case pharmaciesActions.ActionNames.GET_ALL_PHARMACIES: {
            return {
                ...state,
                loading: true,
            };
        }

        case pharmaciesActions.ActionNames.GET_ALL_PHARMACIES_UPDATE_SUCCESS: {
            const isEmptyString = state.searchQuery.trim().length === 0;
            const newLabsList = state.allPharmacies.concat(action.payload.result);
            const isRedundant = newLabsList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalPharmacies: action.payload.total,
                allPharmacies: isEmptyString && !isRedundant ? newLabsList : action.payload.result,
            };
        }

        case pharmaciesActions.ActionNames.GET_ALL_PHARMACIES_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loading: false,
                totalPharmacies: action.payload.total,
                allPharmacies: action.payload.result,
            };
        }

        case pharmaciesActions.ActionNames.GET_ALL_PHARMACIES_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case pharmaciesActions.ActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case pharmaciesActions.ActionNames.SET_SEARCH_QUERY: {
            const isEmptyString = action.payload.trim().length === 0;
            return {
                ...state,
                searchQuery: action.payload,
                loading: isEmptyString ? false : state.loading,
                page: 0,
                allPharmacies: isEmptyString ? [] : state.allPharmacies,
                totalPharmacies: 0,
            };
        }

        default:
            return state;
    }
}
