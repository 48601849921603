import * as invoiceActions from '../../actions/invoice.actions';
import { InvoiceModel } from '../../../models/responses/invoice.model';
import RequestState from '../../../constants/requestState';

export class StateModel {
    countOneTimeRequested: number;
    countSubscriptionRequested: number;

    invoiceOneTimeRequested: InvoiceModel[] = [];
    loadingOneTimeRequested: boolean;
    pageNumberInvoiceOneTimeRequested: number = 0;
    totalInvoiceOneTimeRequested: number | null = null;
    totalAmountInvoiceOneTimeRequested: number = 0;

    invoiceOneTimeArchived: InvoiceModel[] = [];
    pageNumberOneTimeArchived: number = 0;
    totalInvoiceOneTimeArchived: number | null = null;
    totalPaidInvoiceOneTimeArchived: number = 0;
    loadingOneTimeArchived: boolean;

    recurringSubscriptionPatient: InvoiceModel[] = [];
    totalAmountRecurringSubscriptionPatient: number = 0;

    recurringSubscriptionAllPatientsRequested: InvoiceModel[] = [];
    pageNumberRecurringSubscriptionAllPatientsRequested: number = 0;
    totalInvoiceRecurringSubscriptionAllPatientsRequested: number | null = null;
    totalAmountInvoiceRecurringSubscriptionAllPatientsRequested: number = 0;
    loadingRecurringSubscription: boolean;

    recurringSubscriptionAllPatientsActive: InvoiceModel[] = [];
    pageNumberRecurringSubscriptionAllPatientsActive: number = 0;
    totalInvoiceRecurringSubscriptionAllPatientsActive: number | null = null;
    totalAmountInvoiceRecurringSubscriptionAllPatientsActive: number = 0;
    loadingRecurringSubscriptionActive: boolean;

    invoiceRecurringArchived: InvoiceModel[] = [];
    pageNumberRecurringArchived: number = 0;
    totalInvoiceRecurringArchived: number | null = null;
    loadingRecurringArchived: boolean;

    currentInvoice: InvoiceModel | null = null;
    loadingCurrentInvoice: boolean;

    stateString: string | null = null;

    sessionId: string | null;

    connectedAccountLink: string | null = null;
    connectedAccountLinkLoading: boolean;

    isMerchantVerified: boolean;

    deleteRequest: RequestState = RequestState.UNSENT;
    addRequest: RequestState = RequestState.UNSENT;
    editRequest: RequestState = RequestState.UNSENT;
    rejectRequest: RequestState = RequestState.UNSENT;
    generateStringRequest: RequestState = RequestState.UNSENT;
    connectOAuthRequest: RequestState = RequestState.UNSENT;
    payInvoiceRequest: RequestState = RequestState.UNSENT;
    subscribeInvoiceRequest: RequestState = RequestState.UNSENT;
    unsubscribeInvoiceRequest: RequestState = RequestState.UNSENT;
    isMerchantVerifiedRequest: RequestState = RequestState.UNSENT;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: invoiceActions.Actions) {
    switch (action.type) {
        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT: {
            return {
                ...state,
                loadingOneTimeRequested: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loadingOneTimeRequested: false,
                invoiceOneTimeRequested: action.payload.result,
                totalInvoiceOneTimeRequested: action.payload.total,
                totalAmountInvoiceOneTimeRequested: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT_UPDATE_SUCCESS: {
            return {
                ...state,
                loadingOneTimeRequested: false,
                invoiceOneTimeRequested: [...state.invoiceOneTimeRequested, ...action.payload.result],
                totalInvoiceOneTimeRequested: action.payload.total,
                totalAmountInvoiceOneTimeRequested: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PATIENT: {
            return {
                ...state,
                pageNumberInvoiceOneTimeRequested: state.pageNumberInvoiceOneTimeRequested + 1,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PATIENT_FAILED: {
            return {
                ...state,
                loadingOneTimeRequested: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER: {
            return {
                ...state,
                loadingOneTimeRequested: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loadingOneTimeRequested: false,
                invoiceOneTimeRequested: action.payload.result,
                totalInvoiceOneTimeRequested: action.payload.total,
                totalAmountInvoiceOneTimeRequested: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_UPDATE_SUCCESS: {
            return {
                ...state,
                loadingOneTimeRequested: false,
                invoiceOneTimeRequested: [...state.invoiceOneTimeRequested, ...action.payload.result],
                totalInvoiceOneTimeRequested: action.payload.total,
                totalAmountInvoiceOneTimeRequested: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PROVIDER: {
            return {
                ...state,
                pageNumberInvoiceOneTimeRequested: state.pageNumberInvoiceOneTimeRequested + 1,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_FAILED: {
            return {
                ...state,
                loadingOneTimeRequested: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS: {
            return {
                ...state,
                loadingOneTimeRequested: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loadingOneTimeRequested: false,
                invoiceOneTimeRequested: action.payload.result,
                totalInvoiceOneTimeRequested: action.payload.total,
                totalAmountInvoiceOneTimeRequested: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_UPDATE_SUCCESS: {
            return {
                ...state,
                loadingOneTimeRequested: false,
                invoiceOneTimeRequested: [...state.invoiceOneTimeRequested, ...action.payload.result],
                totalInvoiceOneTimeRequested: action.payload.total,
                totalAmountInvoiceOneTimeRequested: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.INCREASE_PAGE_NUMBER_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS: {
            return {
                ...state,
                pageNumberInvoiceOneTimeRequested: state.pageNumberInvoiceOneTimeRequested + 1,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_REQUESTED_PROVIDER_ALL_PATIENTS_FAILED: {
            return {
                ...state,
                loadingOneTimeRequested: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT: {
            return {
                ...state,
                loadingOneTimeArchived: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT_UPDATE_SUCCESS: {
            return {
                ...state,
                loadingOneTimeArchived: false,
                invoiceOneTimeArchived: [...state.invoiceOneTimeArchived, ...action.payload.result],
                totalInvoiceOneTimeArchived: action.payload.total,
                totalPaidInvoiceOneTimeArchived: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loadingOneTimeArchived: false,
                invoiceOneTimeArchived: action.payload.result,
                totalInvoiceOneTimeArchived: action.payload.total,
                totalPaidInvoiceOneTimeArchived: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PATIENT_FAILED: {
            return {
                ...state,
                loadingOneTimeArchived: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER: {
            return {
                ...state,
                loadingOneTimeArchived: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_SUCCESS: {
            return {
                ...state,
                loadingOneTimeArchived: false,
                invoiceOneTimeArchived: [...state.invoiceOneTimeArchived, ...action.payload.result],
                totalInvoiceOneTimeArchived: action.payload.total,
                totalPaidInvoiceOneTimeArchived: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_FAILED: {
            return {
                ...state,
                loadingOneTimeArchived: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS: {
            return {
                ...state,
                loadingOneTimeArchived: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS_SUCCESS: {
            const newList = state.invoiceOneTimeArchived.concat(action.payload.result);
            const isRedundant = newList.length > action.payload.total;

            return {
                ...state,
                loadingOneTimeArchived: false,
                invoiceOneTimeArchived: isRedundant ? state.invoiceOneTimeArchived : newList,
                totalInvoiceOneTimeArchived: action.payload.total,
                totalPaidInvoiceOneTimeArchived: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_ONE_TIME_ARCHIVED_PROVIDER_ALL_PATIENTS_FAILED: {
            return {
                ...state,
                loadingOneTimeArchived: false,
            };
        }

        case invoiceActions.ActionNames.INCREASE_PAGE_NUMBER_ONE_TIME_ARCHIVED: {
            return {
                ...state,
                pageNumberOneTimeArchived: state.pageNumberOneTimeArchived + 1,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PATIENT: {
            return {
                ...state,
                loadingRecurringSubscription: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PATIENT_SUCCESS: {
            return {
                ...state,
                loadingRecurringSubscription: false,
                recurringSubscriptionPatient: action.payload,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PATIENT_FAILED: {
            return {
                ...state,
                loadingRecurringSubscription: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER: {
            return {
                ...state,
                loadingRecurringSubscription: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_SUCCESS: {
            return {
                ...state,
                loadingRecurringSubscription: false,
                recurringSubscriptionPatient: action.payload,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_FAILED: {
            return {
                ...state,
                loadingRecurringSubscription: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED: {
            return {
                ...state,
                loadingRecurringSubscription: true,
            };
        }

        case invoiceActions.ActionNames
            .GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loadingRecurringSubscription: false,
                recurringSubscriptionAllPatientsRequested: action.payload.result,
                totalInvoiceRecurringSubscriptionAllPatientsRequested: action.payload.total,
                totalAmountInvoiceRecurringSubscriptionAllPatientsRequested: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_UPDATE_SUCCESS: {
            return {
                ...state,
                loadingRecurringSubscription: false,
                recurringSubscriptionAllPatientsRequested: [
                    ...state.recurringSubscriptionAllPatientsRequested,
                    ...action.payload.result,
                ],
                totalInvoiceRecurringSubscriptionAllPatientsRequested: action.payload.total,
                totalAmountInvoiceRecurringSubscriptionAllPatientsRequested: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED_FAILED: {
            return {
                ...state,
                loadingRecurringSubscription: false,
            };
        }

        case invoiceActions.ActionNames.INCREASE_PAGE_NUMBER_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_REQUESTED: {
            return {
                ...state,
                pageNumberRecurringSubscriptionAllPatientsRequested:
                    state.pageNumberRecurringSubscriptionAllPatientsRequested + 1,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE: {
            return {
                ...state,
                loadingRecurringSubscriptionActive: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loadingRecurringSubscriptionActive: false,
                recurringSubscriptionAllPatientsActive: action.payload.result,
                totalInvoiceRecurringSubscriptionAllPatientsActive: action.payload.total,
                totalAmountInvoiceRecurringSubscriptionAllPatientsActive: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_UPDATE_SUCCESS: {
            return {
                ...state,
                loadingRecurringSubscriptionActive: false,
                recurringSubscriptionAllPatientsActive: [
                    ...state.recurringSubscriptionAllPatientsActive,
                    ...action.payload.result,
                ],
                totalInvoiceRecurringSubscriptionAllPatientsActive: action.payload.total,
                totalAmountInvoiceRecurringSubscriptionAllPatientsActive: action.payload.totalAmount,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE_FAILED: {
            return {
                ...state,
                loadingRecurringSubscriptionActive: false,
            };
        }

        case invoiceActions.ActionNames.INCREASE_PAGE_NUMBER_RECURRING_SUBSCRIPTION_PROVIDER_ALL_PATIENTS_ACTIVE: {
            return {
                ...state,
                pageNumberRecurringSubscriptionAllPatientsActive:
                    state.pageNumberRecurringSubscriptionAllPatientsActive + 1,
            };
        }
        ////

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT: {
            return {
                ...state,
                loadingRecurringArchived: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT_UPDATE_SUCCESS: {
            return {
                ...state,
                loadingRecurringArchived: false,
                invoiceRecurringArchived: [...state.invoiceRecurringArchived, ...action.payload.result],
                totalInvoiceRecurringArchived: action.payload.total,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loadingRecurringArchived: false,
                invoiceRecurringArchived: action.payload.result,
                totalInvoiceRecurringArchived: action.payload.total,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PATIENT_FAILED: {
            return {
                ...state,
                loadingRecurringArchived: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER: {
            return {
                ...state,
                loadingRecurringArchived: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_SUCCESS: {
            return {
                ...state,
                loadingRecurringArchived: false,
                invoiceRecurringArchived: [...state.invoiceRecurringArchived, ...action.payload.result],
                totalInvoiceRecurringArchived: action.payload.total,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_FAILED: {
            return {
                ...state,
                loadingRecurringArchived: false,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS: {
            return {
                ...state,
                loadingRecurringArchived: true,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS_SUCCESS: {
            return {
                ...state,
                loadingRecurringArchived: false,
                invoiceRecurringArchived: [...state.invoiceRecurringArchived, ...action.payload.result],
                totalInvoiceRecurringArchived: action.payload.total,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_RECURRING_ARCHIVED_PROVIDER_ALL_PATIENTS_FAILED: {
            return {
                ...state,
                loadingRecurringArchived: false,
            };
        }

        case invoiceActions.ActionNames.INCREASE_PAGE_NUMBER_RECURRING_ARCHIVED: {
            return {
                ...state,
                pageNumberRecurringArchived: state.pageNumberRecurringArchived + 1,
            };
        }

        case invoiceActions.ActionNames.RESET_ARCHIVED: {
            return {
                ...state,
                invoiceOneTimeArchived: [],
                pageNumberOneTimeArchived: 0,
                totalInvoiceOneTimeArchived: null,
                totalPaidInvoiceOneTimeArchived: 0,
                invoiceRecurringArchived: [],
                pageNumberRecurringArchived: 0,
                totalInvoiceRecurringArchived: null,
            };
        }

        case invoiceActions.ActionNames.DELETE_INVOICE: {
            return {
                ...state,
                deleteRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.DELETE_INVOICE_SUCCESS: {
            return {
                ...state,
                deleteRequest: RequestState.SENT_SUCCESS,
            };
        }

        case invoiceActions.ActionNames.DELETE_INVOICE_FAILED: {
            return {
                ...state,
                deleteRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.ADD_INVOICE: {
            return {
                ...state,
                addRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.ADD_INVOICE_SUCCESS: {
            return {
                ...state,
                addRequest: RequestState.SENT_SUCCESS,
            };
        }

        case invoiceActions.ActionNames.ADD_INVOICE_FAILED: {
            return {
                ...state,
                addRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.EDIT_INVOICE: {
            return {
                ...state,
                editRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.EDIT_INVOICE_SUCCESS: {
            return {
                ...state,
                editRequest: RequestState.SENT_SUCCESS,
            };
        }

        case invoiceActions.ActionNames.EDIT_INVOICE_FAILED: {
            return {
                ...state,
                editRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.REJECT_INVOICE: {
            return {
                ...state,
                rejectRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.REJECT_INVOICE_SUCCESS: {
            return {
                ...state,
                rejectRequest: RequestState.SENT_SUCCESS,
            };
        }

        case invoiceActions.ActionNames.REJECT_INVOICE_FAILED: {
            return {
                ...state,
                rejectRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                deleteRequest: RequestState.UNSENT,
                addRequest: RequestState.UNSENT,
                editRequest: RequestState.UNSENT,
                rejectRequest: RequestState.UNSENT,
                generateStringRequest: RequestState.UNSENT,
                connectOAuthRequest: RequestState.UNSENT,
                payInvoiceRequest: RequestState.UNSENT,
                subscribeInvoiceRequest: RequestState.UNSENT,
                unsubscribeInvoiceRequest: RequestState.UNSENT,
                isMerchantVerifiedRequest: RequestState.UNSENT,
                stateString: null,
                sessionId: null,
            };
        }

        case invoiceActions.ActionNames.GET_REQUESTED_INVOICE_BY_ID: {
            return {
                ...state,
                loadingCurrentInvoice: true,
            };
        }

        case invoiceActions.ActionNames.GET_REQUESTED_INVOICE_BY_ID_SUCCESS: {
            return {
                ...state,
                currentInvoice: action.payload,
                loadingCurrentInvoice: false,
            };
        }

        case invoiceActions.ActionNames.GET_REQUESTED_INVOICE_BY_ID_FAILED: {
            return {
                ...state,
                loadingCurrentInvoice: false,
            };
        }

        case invoiceActions.ActionNames.GENERATE_STATE: {
            return {
                ...state,
                generateStringRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.GENERATE_STATE_SUCCESS: {
            return {
                ...state,
                generateStringRequest: RequestState.SENT_SUCCESS,
                stateString: action.payload.state,
            };
        }

        case invoiceActions.ActionNames.GENERATE_STATE_FAILED: {
            return {
                ...state,
                generateStringRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.CONNECT_OAUTH: {
            return {
                ...state,
                connectOAuthRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.CONNECT_OAUTH_SUCCESS: {
            return {
                ...state,
                connectOAuthRequest: RequestState.SENT_SUCCESS,
            };
        }

        case invoiceActions.ActionNames.CONNECT_OAUTH_FAILED: {
            return {
                ...state,
                connectOAuthRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.PAY_INVOICE: {
            return {
                ...state,
                payInvoiceRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.PAY_INVOICE_SUCCESS: {
            return {
                ...state,
                sessionId: action.payload.id,
                payInvoiceRequest: RequestState.SENT_SUCCESS,
            };
        }

        case invoiceActions.ActionNames.PAY_INVOICE_FAILED: {
            return {
                ...state,
                payInvoiceRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.SUBSCRIBE_INVOICE: {
            return {
                ...state,
                subscribeInvoiceRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.SUBSCRIBE_INVOICE_SUCCESS: {
            return {
                ...state,
                subscribeInvoiceRequest: RequestState.SENT_SUCCESS,
                sessionId: action.payload.paymentIntentSecret,
            };
        }

        case invoiceActions.ActionNames.SUBSCRIBE_INVOICE_FAILED: {
            return {
                ...state,
                subscribeInvoiceRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.UNSUBSCRIBE_INVOICE: {
            return {
                ...state,
                unsubscribeInvoiceRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.UNSUBSCRIBE_INVOICE_SUCCESS: {
            return {
                ...state,
                unsubscribeInvoiceRequest: RequestState.SENT_SUCCESS,
            };
        }

        case invoiceActions.ActionNames.UNSUBSCRIBE_INVOICE_FAILED: {
            return {
                ...state,
                unsubscribeInvoiceRequest: RequestState.SENT_FAILED,
            };
        }

        case invoiceActions.ActionNames.GET_CONNECTED_ACCOUNT_LINK: {
            return {
                ...state,

                connectedAccountLinkLoading: true,
            };
        }

        case invoiceActions.ActionNames.GET_CONNECTED_ACCOUNT_LINK_SUCCESS: {
            return {
                ...state,
                connectedAccountLink: action.payload.url,
                connectedAccountLinkLoading: false,
            };
        }

        case invoiceActions.ActionNames.GET_CONNECTED_ACCOUNT_LINK_FAILED: {
            return {
                ...state,
                connectedAccountLinkLoading: false,
            };
        }

        case invoiceActions.ActionNames.VERIFY_MERCHANT: {
            return {
                ...state,
                isMerchantVerifiedRequest: RequestState.SENDING,
            };
        }

        case invoiceActions.ActionNames.VERIFY_MERCHANT_SUCCESS: {
            return {
                ...state,
                isMerchantVerified: action.payload.merchantVerified,
                isMerchantVerifiedRequest: RequestState.SENT_SUCCESS,
            };
        }

        case invoiceActions.ActionNames.VERIFY_MERCHANT_FAILED: {
            return {
                ...state,
                isMerchantVerifiedRequest: RequestState.UNSENT,
            };
        }

        case invoiceActions.ActionNames.GET_ONE_TIME_REQUESTED_PATIENT_COUNT_SUCCESS: {
            return {
                ...state,
                countOneTimeRequested: action.payload.countOneTime,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_INVOICES_COUNT_PROVIDER_ALL_PATIENTS_SUCCESS: {
            return {
                ...state,
                countOneTimeRequested: action.payload.countOneTime,
                countSubscriptionRequested: action.payload.countSubscriptions,
            };
        }

        case invoiceActions.ActionNames.GET_ALL_INVOICES_COUNT_PROVIDER_PATIENT_SUCCESS: {
            return {
                ...state,
                countOneTimeRequested: action.payload.countOneTime,
            };
        }

        case invoiceActions.ActionNames.GET_INVOICE_BY_ID: {
            return {
                ...state,
                loadingCurrentInvoice: true,
            };
        }

        case invoiceActions.ActionNames.GET_INVOICE_BY_ID_SUCCESS: {
            return {
                ...state,
                loadingCurrentInvoice: false,
                currentInvoice: action.payload,
            };
        }

        case invoiceActions.ActionNames.GET_INVOICE_BY_ID_FAILED: {
            return {
                ...state,
                loadingCurrentInvoice: false,
            };
        }

        case invoiceActions.ActionNames.GET_INVOICE_BY_ID_PROVIDER: {
            return {
                ...state,
                loadingCurrentInvoice: true,
            };
        }

        case invoiceActions.ActionNames.GET_INVOICE_BY_ID_PROVIDER_SUCCESS: {
            return {
                ...state,
                loadingCurrentInvoice: false,
                currentInvoice: action.payload,
            };
        }

        case invoiceActions.ActionNames.GET_INVOICE_BY_ID_PROVIDER_FAILED: {
            return {
                ...state,
                loadingCurrentInvoice: false,
            };
        }

        default:
            return state;
    }
}
