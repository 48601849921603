import TextInputSearch from '../input/TextInputSearch';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
`;

interface Props {
    changeHandler: (value: string) => void;
}

const HeaderSearch: React.FC<Props> = props => {
    const { changeHandler } = props;

    const changeInput = useCallback(
        (value: string) => {
            changeHandler(value);
        },
        [changeHandler]
    );

    return (
        <InputWrapper>
            <TextInputSearch onChange={changeInput} placeholder="Search" />
        </InputWrapper>
    );
};

export default HeaderSearch;
