import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import carouselDot from '../../../static/images/landing/main/carouselDot.svg';
import carouselDotActive from '../../../static/images/landing/main/carouselDotActive.svg';
import CarouselItem from '../main/components/CarouselItem';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import arrowCarouselLeft from '../../../static/images/landing/main/arrowCarouselLeft.svg';
import arrowCarouselRight from '../../../static/images/landing/main/arrowCarouselRight.svg';

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: calc(50% - 30px);
`;

const ButtonWrapper = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border: 1px solid ${colors.gray1};
    display: flex;
    justify-content: center;
`;

const Wrapper = styled.div<{ backgroundImg: string | undefined }>`
    position: relative;
    background-image: url(${props => props.backgroundImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

interface Options {
    text: string;
    authorName?: string;
    img?: string;
    avatar?: string;
}

interface InjectedProps {
    items: Options[];
    isTablet?: boolean;
    backgroundImg?: string;
    textFontSize: number;
    itemsInDesktop?: number;
    partialVisibility?: boolean;
    centeredDesktop?: boolean;
}

type PropsTypes = InjectedProps;

const CustomDot = ({ onClick, ...rest }: any) => {
    const { active } = rest;
    return (
        <button
            className={active ? 'active' : 'inactive'}
            onClick={() => onClick()}
            style={{ border: 'none', backgroundColor: 'transparent' }}
        >
            {active ? <img src={carouselDotActive} alt="" /> : <img src={carouselDot} alt="" />}
        </button>
    );
};

const ArrowLeft = (props: { onClick: () => void; className?: string }) => {
    return (
        <ButtonWrapper onClick={props.onClick} className={props.className}>
            <img src={arrowCarouselLeft} alt="" />
        </ButtonWrapper>
    );
};

const ArrowRight = (props: { onClick: () => void }) => {
    return (
        <ButtonWrapper onClick={props.onClick}>
            <img src={arrowCarouselRight} alt="" />
        </ButtonWrapper>
    );
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    const {
        carouselState: { currentSlide },
    } = rest;
    return (
        <ButtonsWrapper className="carousel-button-group" style={{ position: 'absolute' }}>
            <ArrowLeft className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} />
            <ArrowRight onClick={() => next()} />
        </ButtonsWrapper>
    );
};

const SharedCarousel: React.FC<PropsTypes> = props => {
    const { items, isTablet, centeredDesktop, itemsInDesktop, partialVisibility } = props;

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: itemsInDesktop ? itemsInDesktop : 1,
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: centeredDesktop ? 1 : 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 350 },
            items: 1,
            partialVisibilityGutter: partialVisibility ? 70 : 0,
        },
        mobileS: {
            breakpoint: { max: 350, min: 0 },
            items: 1,
        },
    };

    return (
        <Wrapper backgroundImg={props.backgroundImg}>
            <Carousel
                swipeable
                draggable
                showDots
                arrows={false}
                infinite
                customDot={<CustomDot />}
                responsive={responsive}
                renderButtonGroupOutside={isTablet}
                customButtonGroup={isTablet ?  <ButtonGroup /> : <></>}
                partialVisible={props.partialVisibility}
            >
                {items.map((option, index) => (
                    <CarouselItem
                        key={index}
                        text={option.text}
                        authorName={option.authorName}
                        textFontSize={props.textFontSize}
                        image={option.img}
                        avatar={option.avatar}
                        partialVisible={!!props.partialVisibility}
                        centeredDesktop={!!props.centeredDesktop}
                    />
                ))}
            </Carousel>
        </Wrapper>
    );
};

export default SharedCarousel;
