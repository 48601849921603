import React from 'react';
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import { mediaQuery } from '../../constants/device';

const createStyles = makeStyles(theme => ({
    redHeading: {
        fontSize: '12px',
        lineHeight: '18px',
        color: colors.redMain,
        textTransform: 'uppercase',
        display: 'block',
        marginBottom: '24px',
    },
    text: {
        fontSize: '16px',
        lineHeight: '24px',
        color: colors.textSecondary,
    },
    imgLeft: (props: { imageMargin?: boolean | undefined; horizontal?: boolean | undefined }) => ({
        width: '100%',
        gridColumn: props.horizontal ? '1/3' : props.imageMargin ? '2/3' : '2/4',
        zIndex: 2,
        gridRow: '1/3',
        [theme.breakpoints.up('md')]: {
            gridColumn: '1/2',
            gridRow: '1',
        },
    }),
    imgRight: (props: { imageMargin?: boolean | undefined; horizontal?: boolean | undefined }) => ({
        width: '100%',
        gridColumn: props.horizontal ? '1/3' : props.imageMargin ? '2/3' : '2/4',
        zIndex: 2,
        gridRow: '1/3',
        [theme.breakpoints.up('md')]: {
            gridRow: '2/4',
            gridColumn: '2/4',
        },
    }),
}));

const WrapperImgLeft = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr 0.1fr;
    grid-template-rows: 0.5fr 0.48fr 1.5fr;
    background-color: ${colors.gray2};

    ${mediaQuery.tablet} {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 0.8fr;
        margin-left: 12%;
        padding-right: 12%;
        background-color: ${colors.gray2};
    }
`;

const WrapperImgRight = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr 0.1fr;
    grid-template-rows: 0.5fr 0.48fr 1.5fr;
    background-color: ${colors.gray2};

    ${mediaQuery.tablet} {
        grid-template-rows: 0.1fr 1fr 0.1fr;
        grid-template-columns: 1fr 0.1fr 1fr;
        margin-left: 0;
        padding-right: 0;
        background-color: transparent;
    }
`;

const TextWrapperLeft = styled.div`
    padding: 40px 20px;
    background-color: ${colors.gray2};
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 3;
    height: auto;

    ${mediaQuery.tablet} {
        padding: 13% 0 13% 12%;
        grid-row: 1;
        grid-column: 2;
    }
`;

const TextWrapperRight = styled.div`
    padding: 40px 20px;
    background-color: ${colors.gray2};
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 3;
    height: auto;

    ${mediaQuery.tablet} {
        padding: 21% 22% 13% 12%;
        grid-row: 2;
        grid-column: 1;
    }
    ${mediaQuery.laptopL} {
        padding: 21% 21% 13% 25%;
        grid-row: 2;
        grid-column: 1;
    }
`;

const BackgroundLeft = styled.div`
    background-color: ${colors.gray2};
    grid-row: 2/3;
    grid-column: 1/4;
`;

const BackgroundRight = styled.div`
    background-color: ${colors.gray2};
    grid-row: 1/3;
    grid-column: 1/3;
`;

export enum ImgSide {
    'right',
    'left',
}

interface InjectedProps {
    redHeading?: string;
    text: string;
    img: string;
    imgSide: ImgSide;
    backgroundColor?: string;
    imageMargin?: boolean | undefined;
    horizontal?: boolean;
}

type PropsTypes = InjectedProps;

const CardWithTopImageGray: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    return (
        <>
            {props.imgSide === ImgSide.left ? (
                <WrapperImgLeft>
                    <img src={props.img} className={classes.imgLeft} alt="" />
                    <BackgroundLeft />
                    <TextWrapperLeft>
                        {props.redHeading && (
                            <Typography variant={'button'} classes={{ root: classes.redHeading }}>
                                {props.redHeading}
                            </Typography>
                        )}
                        {props.children}
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            {props.text}
                        </Typography>
                    </TextWrapperLeft>
                </WrapperImgLeft>
            ) : (
                <WrapperImgRight>
                    <img src={props.img} className={classes.imgRight} alt="" />
                    <BackgroundRight />
                    <TextWrapperRight>
                        {props.redHeading && (
                            <Typography variant={'button'} classes={{ root: classes.redHeading }}>
                                {props.redHeading}
                            </Typography>
                        )}
                        {props.children}
                        <Typography variant={'body1'} classes={{ root: classes.text }}>
                            {props.text}
                        </Typography>
                    </TextWrapperRight>
                </WrapperImgRight>
            )}
        </>
    );
};

export default CardWithTopImageGray;
