import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import styled from 'styled-components';
import { mediaQuery } from '../../../constants/device';
import providerAvatar from '../../../static/images/provider/providerAvatar.svg';
import { PatientStepClassName } from '../constants/patientStepsClassNames';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { connect } from 'react-redux';
import { RoleOptions } from '../../../constants/roleOptions';
import * as fromRoot from '../../../store/reducers';
import { UserModel } from '../../../models/responses/user.response';
import { withRouter, RouteComponentProps } from 'react-router';

const createStyles = makeStyles({
    heading: {
        fontSize: '14px',
        color: colors.textSecondary,
    },
    headingContent: {
        fontSize: '24px',
        color: colors.textPrimary,
    },
    subHeadingContent: {
        fontSize: '14px',
        color: colors.textSecondary,
    },
});

const DecorationBlock = styled.div`
    border-radius: 30px 30px 0 0;
    background-color: ${colors.gray2};
    width: 100%;
    height: 160px;
    position: absolute;
    z-index: -1;
    ${mediaQuery.laptop} {
        border-radius: 0;
        height: 180px;
    }
`;

const ProviderWrapper = styled.div`
    height: calc(100vh - 202px);
    margin: 30px 0;
    border: 1px solid ${colors.gray1};
    border-radius: 30px;
    position: relative;

    ${mediaQuery.mobileLandscape} {
        height: auto;
    }

    ${mediaQuery.laptop} {
        margin: 0;
        border: none;
    }
`;

const HeaderWrapper = styled.div`
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px 0 0 0;

    ${mediaQuery.laptop} {
        padding: 42px 0 0 0;
    }
`;

const Avatar = styled.div<any>`
    width: 200px;
    height: 200px;
    margin-top: 16px;
    border-radius: 100px;
    background-color: ${colors.white};
    background-image: url(${props => (props.img ? props.img : providerAvatar)});
    background-size: ${props => (props.img ? 'cover' : 'auto')};
    border: 1px solid ${colors.textSecondary};
    background-repeat: no-repeat;
    background-position: center;
`;

const ContentWrapper = styled.div`
    height: calc(100% - 320px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 40px;

    ${mediaQuery.mobileLandscape} {
        height: 120px;
    }

    ${mediaQuery.laptop} {
        height: 140px;
        padding: 50px 0 40px;
    }
`;

const TextWrapper = styled.div`
    text-align: center;
`;

interface StoreModel {
    isTourVisible: boolean;
    user: UserModel | null;
}

const MockProviderStatusForTutorial: React.FC<StoreModel & RouteComponentProps<any>> = props => {
    const classes = createStyles(props);
    const { isTourVisible, user, history } = props;

    useEffect(() => {
        if (user && user.role === RoleOptions.PATIENT && !isTourVisible) {
            history.push('/');
        }
    }, [user, isTourVisible, history]);

    return (
        <ProviderWrapper>
            <DecorationBlock />
            <HeaderWrapper>
                <Typography variant="button" classes={{ root: classes.heading }}>
                    No Provider Linked
                </Typography>
                <Avatar img={null} />
            </HeaderWrapper>
            <ContentWrapper>
                <TextWrapper>
                    <Typography variant="button" classes={{ root: classes.headingContent }}>
                        No Provider
                    </Typography>
                    <Typography variant="body1" classes={{ root: classes.subHeadingContent }}>
                        Tap to select from list
                    </Typography>
                </TextWrapper>
                <div className={PatientStepClassName.VIEW_PROVIDER}>
                    <PrimaryButton>Find Provider</PrimaryButton>
                </div>
            </ContentWrapper>
        </ProviderWrapper>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        isTourVisible: store.tour.isTourVisible,
        user: store.authorization.user,
    }),
    () => ({})
)(withRouter(MockProviderStatusForTutorial));
