import React, { useEffect } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import PrimaryButton from '../../shared/button/PrimaryButton';
import CustomLink from '../../shared/link/CustomLink';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as ProviderActions } from '../../store/actions/provider.actions';
import Card from '../../shared/card/Card';
import * as fromRoot from '../../store/reducers';
import { RouteComponentProps, withRouter } from 'react-router';
import { ProviderModel } from '../../models/responses/provider.response';
import { AcceptProviderStatus } from '../../constants/acceptProviderStatus';
import Loading from '../shared/Loading';
import { SearchRoutingForPatient } from '../../constants/tabRouting/routing';

const BackgroundWrapper = styled.div`
    height: calc(100vh - 277px);
    padding: 52px 0 85px;
`;

const createStyles = makeStyles({
    heading: {
        fontSize: '24px',
        color: colors.textPrimary,
    },
    text: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
});

type DispatchModel = {
    resetStatusVariables: typeof ProviderActions.resetStatusVariables;
    getProviderById: typeof ProviderActions.getProviderById;
};

type StoreModel = {
    loading: boolean;
    currentProvider: ProviderModel | null;
};

type PropsTypes = RouteComponentProps<any> & DispatchModel & StoreModel;

const ProviderRequestLinkSent: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const { loading, getProviderById, currentProvider, resetStatusVariables, match, history } = props;

    useEffect(() => {
        resetStatusVariables();
        getProviderById(match.params.id);
    }, [resetStatusVariables, getProviderById, match]);

    useEffect(() => {
        if (currentProvider && currentProvider.status !== AcceptProviderStatus.PENDING) {
            history.push(`/provider/${currentProvider.id}`);
        }
    }, [currentProvider, history]);

    return (
        <>
            <HeaderHeadingWithLink goBackLink={SearchRoutingForPatient[0].path}>Request link</HeaderHeadingWithLink>
            {loading && <Loading />}
            {!loading && currentProvider && (
                <SizeControllerWrapper>
                    <BackgroundWrapper>
                        <Card>
                            <Typography variant="h2" classes={{ root: classes.heading }}>
                                Request Sent
                            </Typography>
                            <Typography variant="body1" classes={{ root: classes.text }}>
                                {currentProvider.firstName + ' ' + currentProvider.lastName} received your
                                request and will reply to you shortly.
                            </Typography>
                            <CustomLink to={'/provider-status'}>
                                <PrimaryButton>Close</PrimaryButton>
                            </CustomLink>
                        </Card>
                    </BackgroundWrapper>
                </SizeControllerWrapper>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        loading: state.provider.loading,
        currentProvider: state.provider.currentProvider,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(ProviderActions.resetStatusVariables, dispatch),
        getProviderById: bindActionCreators(ProviderActions.getProviderById, dispatch),
    })
)(withRouter(ProviderRequestLinkSent));
