import React, { useState } from 'react';
import { Collapse, Fade, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import expandUpWhite from '../../../static/icons/expandUpWhite.svg';
import expandDropdownGrayDown from '../../../static/icons/expandDropdownGrayDown.svg';
import { referenceRange } from '../../../constants/bloodWork/referenceRange';
import ReferenceRangeTable from './ReferenceRangeTable';
import { PanelResponse } from '../../../models/responses/panel.response';
import ResultTable from './ResultTable';
import ChangeTable from './ChangeTable';
import { BloodworkTabs } from '../../../constants/tabRouting/bloodworkTabs';

const createStyles = makeStyles({
    rowNameGray: {
        color: colors.textSecondary,
        fontSize: '16px',
    },
    rowNameWhite: {
        color: colors.white,
        fontSize: '16px',
    },
    listItem: {},
    noData: {
        fontSize: '14px',
        color: colors.textSecondary,
        marginTop: '20px',
    },
});

const RowWrapper = styled.div<{ isOpen: boolean; isDark: boolean }>`
    background-color: ${props => (props.isOpen ? colors.textSecondary : props.isDark ? colors.gray1 : colors.gray2)};
`;

const Row = styled.div<{ height: string }>`
    height: ${props => props.height};
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
`;

interface InjectedProps {
    panel: PanelResponse;
    patientGender: string;
    index: number;
    tabName: BloodworkTabs;
    reportDate: string;
    previousReportDate: string;
}

type PropsTypes = InjectedProps;

const Panel: React.FC<PropsTypes> = props => {
    const [expanded, setExpanded] = useState(false);

    const getRowHeight = () => {
        const height = window.innerHeight - 200;
        return height / referenceRange.length + 'px';
    };

    const classes = createStyles(props);

    const { previousReportDate, tabName, panel, index, patientGender, reportDate } = props;

    return (
        <>
            <RowWrapper isDark={index % 2 === 0} isOpen={expanded} onClick={() => setExpanded(!expanded)}>
                <SizeControllerWrapper>
                    <Row height={getRowHeight()}>
                        <Typography
                            variant="body1"
                            classes={{ root: expanded ? classes.rowNameWhite : classes.rowNameGray }}
                        >
                            {panel.type}
                        </Typography>
                        {expanded ? <img src={expandUpWhite} alt="" /> : <img src={expandDropdownGrayDown} alt="" />}
                    </Row>
                </SizeControllerWrapper>
            </RowWrapper>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {tabName === BloodworkTabs.REF_RANGE && (
                    <Fade in={tabName === BloodworkTabs.REF_RANGE} timeout={{ enter: 500, exit: 500 }}>
                        <ReferenceRangeTable targetList={panel.targetList} patientGender={patientGender} />
                    </Fade>
                )}

                {(tabName === BloodworkTabs.CURRENT || tabName === BloodworkTabs.PREVIOUS) && (
                    <Fade
                        in={tabName === BloodworkTabs.CURRENT || tabName === BloodworkTabs.PREVIOUS}
                        timeout={{ enter: 500, exit: 500 }}
                    >
                        <ResultTable
                            panel={panel}
                            patientGender={patientGender}
                            reportDate={reportDate}
                            status={tabName === BloodworkTabs.CURRENT ? 'cur' : 'prev'}
                            previousReportDate={previousReportDate}
                        />
                    </Fade>
                )}

                {tabName === BloodworkTabs.CHANGE && (
                    <Fade in={tabName === BloodworkTabs.CHANGE} timeout={{ enter: 500, exit: 500 }}>
                        <ChangeTable panel={panel} patientGender={patientGender} />
                    </Fade>
                )}
            </Collapse>
        </>
    );
};
export default Panel;
