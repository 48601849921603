import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';

const createStyles = makeStyles(theme => ({
    largeHeading: {
        fontSize: '36px',
        lineHeight: '50px',
        color: colors.textPrimary,
        display: 'block',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: '38px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '40px',
        },
    },
    smallHeading: {
        fontSize: '30px',
        lineHeight: '50px',
        color: colors.textPrimary,
        display: 'block',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: '32px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '46px',
        },
    },
}));

enum Size {
    small = 'small',
    large = 'large',
}

const LandingBigHeading: React.FC<{ size?: string }> = props => {
    const { size } = props;
    const classes = createStyles(props);
    return (
        <Typography
            variant={'button'}
            classes={{ root: size === Size.small ? classes.smallHeading : classes.largeHeading }}
        >
            {props.children}
        </Typography>
    );
};

export default LandingBigHeading;
