import React, { useEffect, useMemo } from 'react';
import CardListItem from './CardListItem';
import { CardModel } from '../../../../models/responses/card.model';
import RequestState from '../../../../constants/requestState';
import * as cardManagementActions from '../../../../store/actions/cardManagement.actions';
import { connect } from 'react-redux';
import * as fromRoot from '../../../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';

interface StoreModel {
    cardsList: CardModel[];
    cardsListLoading: RequestState;
    cardDeleteLoading: RequestState;
    cardAddLoading: RequestState;
    cardSetDefaultLoading: RequestState;
}

interface DispatchModel {
    getAllCards: typeof cardManagementActions.Actions.getAllCards;
    resetStatusVariables: typeof cardManagementActions.Actions.resetStatusVariables;
}

type PropsTypes = StoreModel & DispatchModel;

const CardsList: React.FC<PropsTypes> = props => {
    const {
        cardsListLoading,
        cardsList,
        getAllCards,
        cardDeleteLoading,
        cardAddLoading,
        resetStatusVariables,
        cardSetDefaultLoading,
    } = props;

    useEffect(() => {
        getAllCards();
    }, [getAllCards]);

    const defaultCard = useMemo(() => {
        const defaultCard = cardsList.filter(card => card.isDefault);
        return !!defaultCard.length ? defaultCard[0] : null;
    }, [cardsList]);

    const otherCards = useMemo(() => {
        return defaultCard ? cardsList.filter(card => card.paymentMethodId !== defaultCard.paymentMethodId) : cardsList;
    }, [defaultCard, cardsList]);

    const isLoading = useMemo(() => {
        return cardsListLoading === RequestState.SENDING;
    }, [cardsListLoading]);

    useEffect(() => {
        if (
            cardDeleteLoading === RequestState.SENT_SUCCESS ||
            cardAddLoading === RequestState.SENT_SUCCESS ||
            cardSetDefaultLoading === RequestState.SENT_SUCCESS
        ) {
            getAllCards();
            resetStatusVariables();
        }
    }, [cardDeleteLoading, cardAddLoading, cardSetDefaultLoading, getAllCards, resetStatusVariables]);

    return (
        <>
            {!isLoading && !!cardsList.length && (
                <>
                    {defaultCard && <CardListItem card={defaultCard} />}
                    {otherCards.map(card => (
                        <CardListItem key={card.paymentMethodId} card={card} />
                    ))}
                </>
            )}
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        cardsList: store.cardManagement.cardsList,
        cardsListLoading: store.cardManagement.cardsListLoading,
        cardDeleteLoading: store.cardManagement.cardDeleteLoading,
        cardAddLoading: store.cardManagement.cardAddLoading,
        cardSetDefaultLoading: store.cardManagement.cardSetDefaultLoading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllCards: bindActionCreators(cardManagementActions.Actions.getAllCards, dispatch),
        resetStatusVariables: bindActionCreators(cardManagementActions.Actions.resetStatusVariables, dispatch),
    })
)(CardsList);
