import { IsEmail, IsString, MaxLength, MinLength } from 'class-validator';

export class SignInDto {
    @IsEmail({}, { message: 'Email has invalid format' })
    @MinLength(1, { message: 'Email cannot be empty' })
    @MaxLength(30, { message: 'Email is too long' })
    email: string;

    @IsString()
    @MinLength(8, { message: 'Password must be at least 8 characters' })
    @MaxLength(30, { message: 'Password is too long' })
    password: string;
}
