import React, { useCallback, useMemo } from 'react';
import logoSmall from '../../static/images/upmedHeaderSmallLogo.svg';
import logoBig from '../../static/images/upmedHeaderLogo.svg';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { Button, Divider, makeStyles } from '@material-ui/core';
import notification from '../../static/icons/notification.svg';
import SizeControllerWrapper from '../SizeControllerWrapper';
import MenuButton from '../button/MenuButton';
import CustomLink from '../link/CustomLink';
import { UserModel } from '../../models/responses/user.response';
import * as fromRoot from '../../store/reducers';
import { RoleOptions } from '../../constants/roleOptions';
import { PatientModel } from '../../models/responses/patient.response';
import { ProviderModel } from '../../models/responses/provider.response';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as SidebarActions } from '../../store/actions/sidebar.actions';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import HeaderAvatar from './HeaderAvatar';
import MenuNavDesktop from '../nav/MenuNavDesktop';
import { MenuNavItems } from '../../constants/menuNavItems';
import { PatientStepClassName } from '../../pages/tour/constants/patientStepsClassNames';

const createStyles = makeStyles({
    phoneNumber: {
        fontSize: '12px',
        marginRight: '10px',
    },
    icon: {
        width: '19px',
        height: '19px',
        marginBottom: '5px',
    },
    dividerRed: {
        height: '10px',
        backgroundColor: colors.redMain,
    },
    roundButton: {
        width: '60px',
        height: '60px',
        minWidth: '60px',
        borderRadius: '30px',
    },
});

const ContentWrapper = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.white};
`;

const RightSide = styled.div`
    width: calc(100% - 39px);
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MenuButtonWrapper = styled.div`
    margin-left: 35px;
    position: absolute;
    right: 10px;
    ${mediaQuery.tablet} {
        right: 46px;
    }
    ${mediaQuery.laptopL} {
        right: 13%;
    }
`;

const NotificationDot = styled.div`
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: ${colors.redMain};
    border-radius: 5px;
    top: 2px;
    right: 0;
`;

const RelativeWrapper = styled.div`
    position: relative;
    height: 24px;
`;

const MenuNavWrapper = styled.div`
    display: none;
    align-items: center;
    position: absolute;
    right: 0;
    width: 60%;
    z-index: 2;
    ${mediaQuery.laptop} {
        display: flex;
    }
    ${mediaQuery.laptopL} {
        width: 50%;
    }
`;

type StateModel = {
    user?: UserModel | null;
    patient?: PatientModel | null;
    provider?: ProviderModel | null;
    loadingPatient?: boolean;
    loadingProvider?: boolean;
    hasUnreadNotification: boolean;
    openSidebar: boolean;
};

type DispatchModel = {
    toggleSidebar: typeof SidebarActions.toggleSidebar;
};

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const HeaderAuth: React.FC<PropsTypes> = (props: PropsTypes) => {
    const { user, openSidebar, toggleSidebar } = props;

    const getRedirectLink = () => {
        if (props.user && props.user.role === RoleOptions.PROVIDER) {
            return '/provider/my-links';
        }
        if (props.user && props.user.role === RoleOptions.PROVIDER) {
            return props.user.setup ? '/patient-start' : '/welcome';
        }
        return '/';
    };

    const closeSidebar = useCallback(() => {
        if (openSidebar) {
            toggleSidebar();
        }
    }, [openSidebar, toggleSidebar]);

    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const logo = isLaptop ? logoBig : logoSmall;

    const isLandingPage = useMemo(() => {
        return !!MenuNavItems.filter(item => item.link === props.history.location.pathname).length;
    }, [props.history.location.pathname]);

    const profileRedirectLink = useMemo(() => {
        if (user) {
            return user.role === RoleOptions.ADMIN ? '/admin/profile' : '/profile';
        }
        return '';
    }, [user]);

    const classes = createStyles(props);
    return (
        <>
            {props.user && (
                <>
                    <SizeControllerWrapper>
                        <ContentWrapper>
                            <RightSide>
                                <CustomLink to={getRedirectLink()}>
                                    <img src={logo} alt="" onClick={closeSidebar} />
                                </CustomLink>
                            </RightSide>
                            <MenuButtonWrapper>
                                <Button
                                    classes={{ root: classes.roundButton }}
                                    style={{ margin: '0 -5px 0 -15px' }}
                                    onClick={closeSidebar}
                                >
                                    <div className={PatientStepClassName.NOTIFICATION}>
                                        <Link to="/notifications">
                                            <RelativeWrapper>
                                                <img src={notification} alt="" className={classes.icon} />
                                                {props.hasUnreadNotification && <NotificationDot />}
                                            </RelativeWrapper>
                                        </Link>
                                    </div>
                                </Button>

                                <CustomLink to={profileRedirectLink}>
                                    <Button classes={{ root: classes.roundButton }} onClick={closeSidebar}>
                                        <HeaderAvatar user={props.user} />
                                    </Button>
                                </CustomLink>
                                {!isLaptop && (
                                    <MenuButton
                                        open={isLaptop ? false : props.openSidebar}
                                        onClickMenu={isLaptop ? () => {} : () => props.toggleSidebar()}
                                    />
                                )}
                            </MenuButtonWrapper>
                        </ContentWrapper>
                    </SizeControllerWrapper>
                    <Divider classes={{ root: classes.dividerRed }} />

                    {isLandingPage && (
                        <MenuNavWrapper>
                            <MenuNavDesktop items={MenuNavItems} />
                        </MenuNavWrapper>
                    )}
                </>
            )}
        </>
    );
};

const mapStateToProps = (store: fromRoot.RootStateModel): StateModel => ({
    hasUnreadNotification: store.notifications.hasUnreadNotifications,
    user: store.authorization.user,
    provider: store.provider.currentProvider,
    patient: store.patient.patient,
    loadingPatient: store.patient.loading,
    loadingProvider: store.provider.loading,
    openSidebar: store.sidebar.open,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchModel => ({
    toggleSidebar: bindActionCreators(SidebarActions.toggleSidebar, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderAuth));
