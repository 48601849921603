import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import PrescriptionForm from './components/PrescriptionForm';
import { connect } from 'react-redux';
import * as prescriptionActions from '../../store/actions/prescription.actions';
import * as fromRoot from '../../store/reducers/index';
import { bindActionCreators, Dispatch } from 'redux';
import { CreatePrescriptionComponentDto } from '../../models/dto/createPrescriptionComponent.dto';
import PrescriptionInfoBlock from './components/PrescriptionInfoBlock';
import add from '../../static/icons/addPrescriptionComponet.svg';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { RouteComponentProps, withRouter } from 'react-router';
import RequestState from '../../constants/requestState';
import LoadingTransparent from '../shared/LoadingTransparent';
import forwardIcon from '../../static/icons/forwardIcon.svg';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import { PrescriptionComponentModel } from '../../models/responses/prescriptionComponentModel.responce';
import { DosageRouting } from '../../constants/tabRouting/routing';
import TextInput from '../../shared/input/TextInput';
import { formatDate } from '../../helpers/dateFormatHelper';
import CancelLink from "../../shared/link/CancelLink";

const Wrapper = styled.div`
    padding: 48px 0 37px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const HeaderWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const InputWrapper = styled.div`
    width: 100%;
    padding: 8px 30px 36px;
`;

const createStyles = makeStyles({
    header: {
        fontSize: '24px',
        color: colors.textPrimary,
    },
    date: {
        fontSize: '14px',
        color: colors.grayUnderline,
        margin: '10px 0',
    },
    addIcon: {
        margin: '22px 0',
    },
    newDrug: {
        fontSize: '14px',
        color: colors.grayUnderline,
        marginBottom: '-8px',
        marginTop: '34px',
    },
    backIcon: {
        position: 'absolute',
        left: '0',
        top: '10px',
        transform: 'rotate(180deg)',
    },
});

interface StoreModel {
    newPrescription: PrescriptionComponentModel[];
    prescriptionAddingStatus: RequestState;
    user: UserModel | null;
}

interface DispatchModel {
    addPrescriptionComponentNew: typeof prescriptionActions.Actions.addPrescriptionComponentNew;
    removePrescriptionComponentNew: typeof prescriptionActions.Actions.removePrescriptionComponentNew;
    editPrescriptionComponentNew: typeof prescriptionActions.Actions.editPrescriptionComponentNew;
    saveEditedPrescriptionComponentNew: typeof prescriptionActions.Actions.saveEditedPrescriptionComponentNew;
    cancelEditPrescriptionComponentNew: typeof prescriptionActions.Actions.cancelEditPrescriptionComponentNew;
    addPrescriptionPatient: typeof prescriptionActions.Actions.addPrescriptionPatient;
    addPrescriptionProvider: typeof prescriptionActions.Actions.addPrescriptionProvider;
    resetStatusVariables: typeof prescriptionActions.Actions.resetStatusVariables;
}

type PropsTypes = RouteComponentProps<any> & StoreModel & DispatchModel;

const AddPrescriptionPage: React.FC<PropsTypes> = props => {
    const [addFormOpen, setAddFormOpen] = useState(true);
    const [editFormOpen, setEditFormOpen] = useState(false);

    const [name, setName] = useState('');

    const {
        resetStatusVariables,
        history,
        user,
        addPrescriptionComponentNew,
        addPrescriptionPatient,
        addPrescriptionProvider,
        cancelEditPrescriptionComponentNew,
        editPrescriptionComponentNew,
        newPrescription,
        prescriptionAddingStatus,
        removePrescriptionComponentNew,
        saveEditedPrescriptionComponentNew,
        match
    } = props;

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    useEffect(() => {
        if (
            prescriptionAddingStatus !== RequestState.UNSENT &&
            prescriptionAddingStatus !== RequestState.SENDING
        ) {
            prescriptionAddingStatus === RequestState.SENT_SUCCESS &&
                user &&
                user.role === RoleOptions.PATIENT &&
                history.push(DosageRouting[0].path);
            prescriptionAddingStatus === RequestState.SENT_SUCCESS &&
                user &&
                user.role === RoleOptions.PROVIDER &&
                history.push(`/profile-patient/${match.params.patientId}`);
        }
    }, [prescriptionAddingStatus, history, match, user]);

    const getPrescriptionDisplayModel = (prescription: PrescriptionComponentModel) => {
        return {
            dosage: `${prescription.dosage.toString()} ${prescription.dosageUnit}`,
            drugApplicationType: prescription.drugApplicationType!.name.toString(),
            drug: prescription.drug!.name.toString(),
            date: `${formatDate(prescription.startDate)} ${prescription.endDate ? '-' : ''} ${
                prescription.endDate ? formatDate(prescription.endDate) : ''
            }`,
            frequency: `${prescription.frequencyValue.toString()} ${prescription.frequencyType}`,
            potency: `${prescription.potency.toString()} ${prescription.potencyUnit}`,
            private: prescription.private,
            quantity: `${prescription.quantity.toString()} ${prescription.quantityUnit}`,
            therapies: prescription.therapyList.map(t => t.name).join(', '),
            id: prescription.id,
        };
    };

    const onCancelClick = (id?: number) => {
        if (newPrescription.length > 0) {
            editFormOpen && cancelEditComponent(id);
            setAddFormOpen(false);
        } else {
            history.push(
                user && user.role === RoleOptions.PATIENT
                    ? DosageRouting[0].path
                    : `/profile-patient/${match.params.patientId}`
            );
        }
    };

    const editComponent = (id: number) => {
        setEditFormOpen(true);
        editPrescriptionComponentNew(id);
    };

    const cancelEditComponent = (id: number | undefined) => {
        setEditFormOpen(false);
        id && cancelEditPrescriptionComponentNew(id);
    };

    const saveEditedComponent = (newPrescriptionComponent: PrescriptionComponentModel) => {
        saveEditedPrescriptionComponentNew(newPrescriptionComponent);
        setEditFormOpen(false);
    };

    const savePrescription = () => {
        if (newPrescription.length > 0 && name.length > 0) {
            const prescriptionToSave: CreatePrescriptionComponentDto[] = newPrescription.map(p => {
                return {
                    ...p,
                    drugId: p.drug!.id,
                    drugApplicationTypeId: p.drugApplicationType!.id,
                    therapies: p.therapyList.map(t => {
                        return t.id;
                    }),
                    startDate: new Date(p.startDate).toISOString(),
                    endDate: p.endDate ? new Date(p.endDate).toISOString() : null,
                };
            });
            if (user && user.role === RoleOptions.PATIENT) {
                addPrescriptionPatient({
                    name: name,
                    pharmacyId: 1,
                    prescriptionComponentList: prescriptionToSave,
                });
            }
            if (user && user.role === RoleOptions.PROVIDER) {
                addPrescriptionProvider({
                    data: {
                        name: name,
                        pharmacyId: 1,
                        prescriptionComponentList: prescriptionToSave,
                    },
                    id: match.params.patientId,
                });
            }
        }
    };

    const getId = () => {
        if (newPrescription.length === 0) {
            return 1;
        }
        const lastIndex = newPrescription[newPrescription.length - 1].id;
        return lastIndex + 1;
    };

    const removeComponent = (id: number) => {
        removePrescriptionComponentNew(id);
    };

    const canSave = useMemo(() => {
        return !!name.length;
    }, [name]);

    const classes = createStyles(props);
    return (
        <>
            {prescriptionAddingStatus === RequestState.SENDING && <LoadingTransparent />}
            <SizeControllerWrapper>
                <Wrapper>
                    <HeaderWrapper>
                        <img
                            src={forwardIcon}
                            alt=""
                            onClick={() => history.goBack()}
                            className={classes.backIcon}
                        />
                        <Typography variant="h2" classes={{ root: classes.header }}>
                            Prescription
                        </Typography>
                    </HeaderWrapper>

                    {newPrescription.length > 0 && (
                        <Typography variant="button" classes={{ root: classes.date }}>
                            {formatDate(new Date())}
                        </Typography>
                    )}

                    <InputWrapper>
                        <TextInput
                            placeholder={'Prescription Name'}
                            value={name}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                setName(evt.target.value);
                            }}
                        />
                    </InputWrapper>

                    {user &&
                        newPrescription.map((prescription, index) => {
                            if (prescription.isBeingEdited) {
                                return (
                                    <PrescriptionForm
                                        key={index}
                                        addPrescriptionComponent={addPrescriptionComponentNew}
                                        onCancelClick={onCancelClick}
                                        getId={getId}
                                        prescription={prescription}
                                        saveEditedComponent={saveEditedComponent}
                                        role={user!.role}
                                        isBeingEdited
                                    />
                                );
                            } else {
                                return (
                                    <PrescriptionInfoBlock
                                        key={index}
                                        prescription={getPrescriptionDisplayModel(prescription)}
                                        removeComponent={removeComponent}
                                        editComponent={editComponent}
                                        role={user!.role}
                                        ableToEdit
                                    />
                                );
                            }
                        })}

                    {!addFormOpen && newPrescription.length > 0 && !editFormOpen && (
                        <img src={add} alt="" className={classes.addIcon} onClick={() => setAddFormOpen(true)} />
                    )}

                    {(newPrescription.length === 0 || addFormOpen) && user && (
                        <>
                            {newPrescription.length > 0 && (
                                <Typography variant="button" classes={{ root: classes.newDrug }}>
                                    New Drug
                                </Typography>
                            )}
                            <PrescriptionForm
                                addPrescriptionComponent={(data: PrescriptionComponentModel) => {
                                    addPrescriptionComponentNew(data);
                                    setAddFormOpen(false);
                                }}
                                getId={getId}
                                onCancelClick={onCancelClick}
                                saveEditedComponent={saveEditedComponent}
                                role={user!.role}
                            />
                        </>
                    )}

                    {!addFormOpen && newPrescription.length > 0 && !editFormOpen && (
                        <>
                            <PrimaryButton disabled={!canSave} onClick={savePrescription}>
                                Save Prescription
                            </PrimaryButton>
                            <CancelLink link={DosageRouting[0].path}/>
                        </>
                    )}
                </Wrapper>
            </SizeControllerWrapper>
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        newPrescription: state.prescription.newPrescription,
        prescriptionAddingStatus: state.prescription.prescriptionAddingStatus,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        addPrescriptionComponentNew: bindActionCreators(
            prescriptionActions.Actions.addPrescriptionComponentNew,
            dispatch
        ),
        addPrescriptionPatient: bindActionCreators(prescriptionActions.Actions.addPrescriptionPatient, dispatch),
        addPrescriptionProvider: bindActionCreators(prescriptionActions.Actions.addPrescriptionProvider, dispatch),
        resetStatusVariables: bindActionCreators(prescriptionActions.Actions.resetStatusVariables, dispatch),
        removePrescriptionComponentNew: bindActionCreators(
            prescriptionActions.Actions.removePrescriptionComponentNew,
            dispatch
        ),
        editPrescriptionComponentNew: bindActionCreators(
            prescriptionActions.Actions.editPrescriptionComponentNew,
            dispatch
        ),
        saveEditedPrescriptionComponentNew: bindActionCreators(
            prescriptionActions.Actions.saveEditedPrescriptionComponentNew,
            dispatch
        ),
        cancelEditPrescriptionComponentNew: bindActionCreators(
            prescriptionActions.Actions.cancelEditPrescriptionComponentNew,
            dispatch
        ),
    })
)(withRouter(AddPrescriptionPage));
