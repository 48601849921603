import React from 'react';
import styled from 'styled-components';
import RegularText from '../../shared/RegularText';
import { colors } from '../../../../theme/colors';
import PrimaryButton from '../../../../shared/button/PrimaryButton';
import { withRouter, RouteComponentProps } from 'react-router';
import { mediaQuery } from '../../../../constants/device';

const BackgroundWrapper = styled.div<{ background: string }>`
    background-color: ${colors.gray2};
    background-image: url(${props => props.background});
    background-position: top;
    background-repeat: no-repeat;
    padding: 20px;
    text-align: center;
    ${mediaQuery.tablet} {
        width: 100%;
    }
`;

const Wrapper = styled.div`
    margin-top: 25%;
    ${mediaQuery.tablet} {
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
`;

const TextWrapper = styled.div`
    margin: 24px 0;
`;

interface InjectedProps {
    path: string;
    text: string;
    buttonText: string;
    icon: string;
}

type PropsTypes = RouteComponentProps & InjectedProps;

const TextWithBackgroundBlockGray: React.FC<PropsTypes> = props => {
    const { path, text, buttonText, icon } = props;

    return (
        <BackgroundWrapper background={icon}>
            <Wrapper>
                {props.children}
                <TextWrapper>
                    <RegularText>{text}</RegularText>
                </TextWrapper>
                <PrimaryButton size={'large'} onClick={() => props.history.push(path)}>
                    {buttonText}
                </PrimaryButton>
            </Wrapper>
        </BackgroundWrapper>
    );
};

export default withRouter(TextWithBackgroundBlockGray);
