import {ActionsOfType, ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {PanelTypeModel} from "../../models/responses/panelType.model";
import {EditPanelType} from "../../models/dto/editPanelType";
import {AddPanelTypeDto} from "../../models/dto/addPanelType.dto";

export enum ActionNames {

    GET_PANEL_TYPES = '[Panel Type] Get Panel Types',
    GET_PANEL_TYPES_SUCCESS = '[Panel Type] Get Panel Types Success',
    GET_PANEL_TYPES_FAILED = '[Panel Type] Get Panel Types Failed',

    GET_PANEL_TYPES_WITH_TARGETS = '[Panel Type] Get Panel Types With Targets',
    GET_PANEL_TYPES_WITH_TARGETS_SUCCESS = '[Panel Type] Get Panel Types With Targets Success',
    GET_PANEL_TYPES_WITH_TARGETS_FAILED = '[Panel Type] Get Panel Types With Targets Failed',

    EDIT_PANEL_TYPE = '[Panel Type] Edit Panel Type',
    EDIT_PANEL_TYPE_SUCCESS = '[Panel Type] Edit Panel Type Success',
    EDIT_PANEL_TYPE_FAILED = '[Panel Type] Edit Panel Type Failed',

    REMOVE_PANEL_TYPE = '[Panel Type] Remove Panel Type',
    REMOVE_PANEL_TYPE_SUCCESS = '[Panel Type] Remove Panel Type Success',
    REMOVE_PANEL_TYPE_FAILED = '[Panel Type] Remove Panel Type Failed',

    ADD_PANEL_TYPE = '[Panel Type] Add Panel Type',
    ADD_PANEL_TYPE_SUCCESS = '[Panel Type] Add Panel Type Success',
    ADD_PANEL_TYPE_FAILED = '[Panel Type] Add Panel Type Failed',

    RESET_STATUS_VARIABLES = '[Panel Type] Reset Status Variables',
}

export const Actions = {
    getPanelType: () => createAction(ActionNames.GET_PANEL_TYPES),
    getPanelTypeSuccess: (data: PanelTypeModel[]) => createAction(ActionNames.GET_PANEL_TYPES_SUCCESS, data),
    getPanelTypeFailed: () => createAction(ActionNames.GET_PANEL_TYPES_FAILED),

    getPanelTypeWithTargets: () => createAction(ActionNames.GET_PANEL_TYPES_WITH_TARGETS),
    getPanelTypeWithTargetsSuccess: (data: PanelTypeModel[]) => createAction(ActionNames.GET_PANEL_TYPES_WITH_TARGETS_SUCCESS, data),
    getPanelTypeWithTargetsFailed: () => createAction(ActionNames.GET_PANEL_TYPES_WITH_TARGETS_FAILED),

    editPanelType: (data: EditPanelType) => createAction(ActionNames.EDIT_PANEL_TYPE, data),
    editPanelTypeSuccess: () => createAction(ActionNames.EDIT_PANEL_TYPE_SUCCESS),
    editPanelTypeFailed: () => createAction(ActionNames.EDIT_PANEL_TYPE_FAILED),

    removePanelType: (id: number) => createAction(ActionNames.REMOVE_PANEL_TYPE, id),
    removePanelTypeSuccess: () => createAction(ActionNames.REMOVE_PANEL_TYPE_SUCCESS),
    removePanelTypeFailed: () => createAction(ActionNames.REMOVE_PANEL_TYPE_FAILED),

    addPanelType: (data: AddPanelTypeDto) => createAction(ActionNames.ADD_PANEL_TYPE, data),
    addPanelTypeSuccess: (data: PanelTypeModel) => createAction(ActionNames.ADD_PANEL_TYPE_SUCCESS, data),
    addPanelTypeFailed: () => createAction(ActionNames.ADD_PANEL_TYPE_FAILED),
    
    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),
};

export type Actions = ActionsUnion<typeof Actions>;

export type editPanelTypeType = ActionsOfType<Actions, ActionNames.EDIT_PANEL_TYPE>;
export type removePanelTypeType = ActionsOfType<Actions, ActionNames.REMOVE_PANEL_TYPE>;
export type addPanelTypeType = ActionsOfType<Actions, ActionNames.ADD_PANEL_TYPE>;