import React, {useState} from 'react';
import HeaderHeadingWithLink from "../../shared/HeaderHeadingWithLink";
import dosageWhiteIcon from '../../static/icons/dosageWhiteIcon.svg';
import {makeStyles} from "@material-ui/styles";
import FabButton from "../../shared/button/FabButton";
import DosageTabs from "./components/DosageTabs";

const createStyles = makeStyles({
    headerIcon: {
        marginRight: '8px',
        marginBottom: '-3px',
        height: '21px !important',
        width: '21px !important',
    },
});

const DosagePage = (props: any) => {
    const classes = createStyles(props);

    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <HeaderHeadingWithLink>
                <img src={dosageWhiteIcon} className={classes.headerIcon} alt=""/>
                Dosage
            </HeaderHeadingWithLink>

            <DosageTabs
                activeTab={activeTab}
                setActiveTab={(index: number) => setActiveTab(index)}
            />

            <FabButton redirectUrl={'/add-prescription'}/>
        </>
    )
};

export default DosagePage;