import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import TextInput from '../../shared/input/TextInput';
import PrimaryButton from '../../shared/button/PrimaryButton';
import CustomLink from '../../shared/link/CustomLink';
import { isEmail } from '../../validators/email.validator';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as AuthActions } from '../../store/actions/auth.actions';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import LoadingTransparent from './SignUp';
import RequestState from '../../constants/requestState';
import { useDebounce } from 'use-debounce';
import FullScreenAuthFormWrapper from './components/FullScreenAuthFormWrapper';
import { mediaQuery } from '../../constants/device';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Footer from '../../shared/Footer';
import {TYPED_ENV} from "../../helpers/typedEnv";

const styles = createStyles({
    heading: {
        fontSize: '24px',
    },
    hintText: {
        fontSize: '14px',
        color: colors.textSecondary,
        marginTop: '28px',
    },
    registerText: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    forgotPasswordLink: {
        textDecorationColor: colors.textPrimary,
    },
    registerLinkText: {
        color: colors.redMain,
        fontSize: '14px',
        marginLeft: '5px',
        '&:hover': {
            color: colors.redDark,
        },
    },
    error: {
        fontSize: '12px',
        color: '#f44336',
    },
});

const BackgroundWrapper = styled.div`
    padding: 80px 0 57px;
    height: calc(100vh - 227px);

    ${mediaQuery.mobileLandscape} {
        height: calc(100vh - 146px);
    }

    ${mediaQuery.tablet} {
        width: 60%;
        margin: 0 auto;
    }

    ${mediaQuery.laptop} {
        height: 390px;
        width: 350px;
        padding: 0;
    }
`;

const Wrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 7% 35% 33% 25%;
    align-items: end;
    margin: 0 auto;
    text-align: center;

    ${mediaQuery.laptop} {
        grid-template-rows: 7% 38% 30% 25%;
    }
`;

const InputWrapper = styled.div``;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ButtonWrapper = styled.div`
    width: fit-content;
    margin: 0 auto;
`;

const ErrorWrapper = styled.div`
    position: absolute;
`;

type StateModel = {
    loading: boolean;
    resetPasswordEmailSent: RequestState;
};

type DispatchModel = {
    resetPassword: typeof AuthActions.resetPassword;
};

type PropsTypes = DispatchModel & StateModel & WithStyles<typeof styles> & RouteComponentProps<any>;

const RecoverPassword: React.FC<PropsTypes> = props => {
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState<boolean | null>(null);
    const [validation] = useDebounce(emailValid, 1000);

    useEffect(() => {
        if (
            props.resetPasswordEmailSent !== RequestState.UNSENT &&
            props.resetPasswordEmailSent !== RequestState.SENDING
        ) {
            props.resetPasswordEmailSent === RequestState.SENT_SUCCESS && props.history.push('/reset-password-sent');
        }
    }, [props.resetPasswordEmailSent, props.history]);

    const isEmailValid = (email: string) => {
        return isEmail(email);
    };

    const validateEmail = () => {
        setEmailValid(isEmailValid(email));
    };

    const sendForm = () => {
        validateEmail();

        if (emailValid) {
            props.resetPassword({
                email,
                redirectUrl: `${TYPED_ENV.REACT_APP_BASE_URL}/change-password`,
            });
        }
    };

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13) {
            sendForm();
        }
    };

    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const { classes } = props;
    return (
        <>
            <FullScreenAuthFormWrapper>
                <BackgroundWrapper>
                    <Wrapper>
                        <Typography variant="h2" classes={{ root: classes.heading }}>
                            Recover Password
                        </Typography>
                        <InputWrapper>
                            <TextInput
                                placeholder={'Email'}
                                value={email}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmail(evt.target.value);
                                    setEmailValid(isEmailValid(evt.target.value));
                                }}
                                onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => handleEnter(evt)}
                                error={validation !== null && !validation}
                            />
                            {validation !== null && !validation && (
                                <ErrorWrapper>
                                    <Typography
                                        variant="button"
                                        classes={{ root: classes.error }}
                                        style={{ color: '#f44336' }}
                                    >
                                        Email is not valid
                                    </Typography>
                                </ErrorWrapper>
                            )}
                            <Typography variant="body1" classes={{ root: classes.hintText }}>
                                Please enter your Email and we’ll send you instructions to reset your password.
                            </Typography>
                        </InputWrapper>
                        <ButtonWrapper>
                            <PrimaryButton onClick={sendForm}>Submit</PrimaryButton>
                        </ButtonWrapper>

                        <LinkWrapper>
                            <Typography variant="body2" classes={{ root: classes.registerText }}>
                                Don't Have An Account?
                            </Typography>
                            <CustomLink to={'/sign-up'} underlineColor={colors.redMain}>
                                <Typography variant="button" classes={{ root: classes.registerLinkText }}>
                                    Sign Up
                                </Typography>
                            </CustomLink>
                        </LinkWrapper>
                    </Wrapper>
                </BackgroundWrapper>
            </FullScreenAuthFormWrapper>

            {isLaptop && <Footer />}

            {props.loading && <LoadingTransparent />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.authorization.loading,
        resetPasswordEmailSent: state.authorization.resetPasswordEmailSent,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        resetPassword: bindActionCreators(AuthActions.resetPassword, dispatch),
    })
)(withStyles(styles)(withRouter(RecoverPassword)));
