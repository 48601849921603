import React from 'react';
import styled from 'styled-components';
import solutionHeaderMobile from '../../../../static/images/landing/solutions/solutionHeaderMobile.png';
import solutionHeaderDesktop from '../../../../static/images/landing/solutions/solutionsHeaderDesktop.png';
import { makeStyles, Typography } from '@material-ui/core';
import RegularText from '../../shared/RegularText';
import { colors } from '../../../../theme/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../../../constants/device';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.gray2};
`;

const TextWrapper = styled.div`
    text-align: center;
`;

const ContentWrapper = styled.div`
    padding: 0 20px 20px;
    margin-top: -42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mediaQuery.tablet} {
        margin-top: -82px;
        width: 60%;
    }
    ${mediaQuery.laptop} {
        margin-top: -160px;
        width: 500px;
    }
`;

const createStyles = makeStyles({
    heading: {
        fontSize: '48px',
        lineHeight: '72px',
    },
    img: {
        width: '100%',
    },
});

const SolutionsHeaderBlock: React.FC = props => {
    const classes = createStyles(props);
    const isTablet = useMediaQuery(mediaQuery.tablet);
    const solutionHeader = isTablet ? solutionHeaderDesktop : solutionHeaderMobile;
    return (
        <Wrapper>
            <img src={solutionHeader} className={classes.img} alt="" />
            <ContentWrapper>
                <Typography variant={'button'} classes={{ root: classes.heading }}>
                    Solutions
                </Typography>
                <TextWrapper>
                    <RegularText>
                        UPdent addresses multiple challenges within the alternative medicine/HRT industry
                    </RegularText>
                </TextWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default SolutionsHeaderBlock;
