import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../../constants/device';
import { RoleOptions } from '../../../constants/roleOptions';
import { makeStyles } from '@material-ui/core';
import theme from '../../../theme/theme';
import { ImgSide } from '../../../shared/card/CardWithTopImageGray';

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    margin-bottom: -150px;
`;

const RedCircleWrapper = styled.div`
    position: relative;
`;

const RedCircle = styled.div`
    position: relative;
    height: 123vw;
    width: 123vw;
    background: #9e0000;
    mix-blend-mode: multiply;
    border-radius: 100%;
    margin: -160px -12px 0;

    ${mediaQuery.mobileL} {
        height: 400px;
        width: 400px;
        margin: -76% -18px -12px;
    }

    ${mediaQuery.tablet} {
        height: 500px;
        width: 500px;
        margin: 15% 0 0 7%;
    }

    ${mediaQuery.laptop} {
        height: 635px;
        width: 635px;
        margin: 15% 0 0 7%;
    }
`;

const ImgWrapper = styled.div<{ side: ImgSide }>`
    ${mediaQuery.tablet} {
        position: absolute;
        display: flex;
        justify-content: ${props => (props.side === ImgSide.right ? 'flex-end' : 'flex-start')};
    }
`;

const TextWrapper = styled.div`
    padding: 23% 6% 0 14%;

    ${mediaQuery.mobileS} {
        padding: 16% 8% 0 14%;
    }
    ${mediaQuery.mobileM} {
        padding: 23% 8% 0 14%;
    }
    ${mediaQuery.mobileL} {
        width: 300px;
        padding: 19% 6% 0 10%;
    }
    ${mediaQuery.tablet} {
        margin: 150px 0 0 42%;
        width: 350px;
        padding: 0;
    }
    ${mediaQuery.laptop} {
        width: 535px;
        margin: 36% 0 0 26%;
        padding: 0;
    }
    ${mediaQuery.laptopL} {
        width: 535px;
        margin: 36% 0 0 35%;
        padding: 0;
    }
    ${mediaQuery.desktop} {
        width: 535px;
        margin: 38% 0 0 45%;
        padding: 0;
    }
`;

const ContentWrapper = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const VideoWrapper = styled.div`
    top: -150px;
    position: relative;
    padding: 12px 9px;

    ${mediaQuery.mobileL} {
        top: -120px;
    }

    ${mediaQuery.tablet} {
        top: -200px;
        margin: 8% 8% 0;
        padding: 0;
        box-shadow: ${theme.boxShadowCard};
    }

    ${mediaQuery.laptop} {
        top: -350px;
        margin: 13% 13% 0;
        padding: 0;
        box-shadow: ${theme.boxShadowCard};
    }

    ${mediaQuery.laptopL} {
        top: -410px;
        margin: 13% 13% 0;
        padding: 0;
        box-shadow: ${theme.boxShadowCard};
    }
`;

const createStyles = makeStyles(theme => ({
    img: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '75%',
        },
    },
}));

interface InjectedProps {
    img: string;
    role: RoleOptions;
    imgSide: ImgSide;
}

type PropsTypes = InjectedProps;

const InfoWithVideoLink: React.FC<PropsTypes> = props => {
    const { img, role } = props;
    const classes = createStyles(props);

    return (
        <Wrapper>
            <ImgWrapper side={props.imgSide}>
                <img src={img} alt="" className={classes.img} />
            </ImgWrapper>

            <RedCircleWrapper>
                <RedCircle />
                <ContentWrapper>
                    <TextWrapper>{props.children}</TextWrapper>
                </ContentWrapper>
            </RedCircleWrapper>

            <VideoWrapper>
                <video controls width="100%'">
                    <source
                        src={
                            role === RoleOptions.PATIENT
                                ? require('../../../static/UPmed_Patient.mp4')
                                : require('../../../static/UPmed_Doctor.mp4')
                        }
                        type="video/mp4"
                    />
                </video>
            </VideoWrapper>
        </Wrapper>
    );
};

export default InfoWithVideoLink;
