import React, { useCallback, useMemo, useState } from 'react';
import NoCard from './NoCard';
import styled from 'styled-components';
import halfLogoBackground from '../../../static/images/landing/main/halfLogoBackground.svg';
import { colors } from '../../../theme/colors';
import AddCard from './AddCard';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import { CardModel } from '../../../models/responses/card.model';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import RequestState from '../../../constants/requestState';
import Loading from '../../shared/Loading';
import CardsList from './components/CardsList';

const Wrapper = styled.div`
    min-height: calc(100vh - 262px);
    background-image: url(${halfLogoBackground});
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-position: right 35%;
    background-size: 140px;
    overflow: scroll;
`;

const CardsListWrapper = styled.div`
    margin: 0 0 16px;
`;

const AddCardWrapper = styled.div`
    margin: 18px 30px;
`;

interface StoreModel {
    cardsList: CardModel[];
    cardsListLoading: RequestState;
}

type PropsTypes = StoreModel;

const SettingsPatient: React.FC<PropsTypes> = ({ cardsList, cardsListLoading }) => {
    const [addCardForm, setAddCardForm] = useState(false);

    const toggleShowCard = useCallback(() => {
        setAddCardForm(!addCardForm);
    }, [addCardForm]);

    const isLoading = useMemo(() => {
        return cardsListLoading === RequestState.SENDING;
    }, [cardsListLoading]);

    return (
        <Wrapper>
            <CardsListWrapper>
                <CardsList />
            </CardsListWrapper>
            {isLoading && <Loading smallSize />}
            {!addCardForm && !cardsList.length && !isLoading && <NoCard onClick={toggleShowCard} />}
            {!addCardForm && !!cardsList.length && !isLoading && (
                <AddCardWrapper>
                    <PrimaryButton onClick={toggleShowCard} size={'small'}>
                        Add Card
                    </PrimaryButton>
                </AddCardWrapper>
            )}
            {addCardForm && <AddCard onClose={toggleShowCard} />}
        </Wrapper>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        cardsList: store.cardManagement.cardsList,
        cardsListLoading: store.cardManagement.cardsListLoading,
    }),
    () => ({})
)(SettingsPatient);
