import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { CardModel } from '../../models/responses/card.model';
import RequestState from '../../constants/requestState';

export enum ActionNames {
    GET_CLIENT_SECRET = '[Card Management] Get Client Secret',
    GET_CLIENT_SECRET_SUCCESS = '[Card Management] Get Client Secret Success',
    GET_CLIENT_SECRET_FAILED = '[Card Management] Get Client Secret Failed',

    SET_ADD_CARD_LOADING_STATE = '[Card Management] Set Add Card Loading State',

    GET_ALL_CARDS = '[Card Management] Get All Cards',
    GET_ALL_CARDS_SUCCESS = '[Card Management] Get All Cards Success',
    GET_ALL_CARDS_FAILED = '[Card Management] Get All Cards Failed',

    DELETE_CARD = '[Card Management] Delete Card',
    DELETE_CARD_SUCCESS = '[Card Management] Delete Card Success',
    DELETE_CARD_FAILED = '[Card Management] Delete Card Failed',

    MAKE_CARD_DEFAULT = '[Card Management] Make Card Default',
    MAKE_CARD_DEFAULT_SUCCESS = '[Card Management] Make Card Default Success',
    MAKE_CARD_DEFAULT_FAILED = '[Card Management] Make Card Default Failed',

    RESET_STATUS_VARIABLES = '[Card Management] Reset Status Variables ',
}

export type Actions = ActionsUnion<typeof Actions>;

export const Actions = {
    getClientSecret: () => createAction(ActionNames.GET_CLIENT_SECRET),
    getClientSecretSuccess: (data: { clientSecret: string }) =>
        createAction(ActionNames.GET_CLIENT_SECRET_SUCCESS, data),
    getClientSecretFailed: () => createAction(ActionNames.GET_CLIENT_SECRET_FAILED),

    getAllCards: () => createAction(ActionNames.GET_ALL_CARDS),
    getAllCardsSuccess: (cards: CardModel[]) => createAction(ActionNames.GET_ALL_CARDS_SUCCESS, cards),
    getAllCardsFailed: () => createAction(ActionNames.GET_ALL_CARDS_FAILED),

    deleteCard: (id: string) => createAction(ActionNames.DELETE_CARD, id),
    deleteCardSuccess: () => createAction(ActionNames.DELETE_CARD_SUCCESS),
    deleteCardFailed: () => createAction(ActionNames.DELETE_CARD_FAILED),

    makeCardDefault: (id: string) => createAction(ActionNames.MAKE_CARD_DEFAULT, id),
    makeCardDefaultSuccess: () => createAction(ActionNames.MAKE_CARD_DEFAULT_SUCCESS),
    makeCardDefaultFailed: () => createAction(ActionNames.MAKE_CARD_DEFAULT_FAILED),

    setAddCardLoadingState: (requestState: RequestState) =>
        createAction(ActionNames.SET_ADD_CARD_LOADING_STATE, requestState),

    resetStatusVariables: () => createAction(ActionNames.RESET_STATUS_VARIABLES),
};

export type deleteCardType = ActionsOfType<Actions, ActionNames.DELETE_CARD>;
export type makeCardDefaultType = ActionsOfType<Actions, ActionNames.MAKE_CARD_DEFAULT>;
