import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';
import { Actions as ApplicationTypesActions } from '../../../store/actions/applicationType.actions';
import { bindActionCreators, Dispatch } from 'redux';
import DropdownInputList from '../DropdownInputList';
import { DefaultPageSize, shouldFetchData } from '../../../constants/dataFetching/allEntitiesByPages';
import { useCompare } from '../../../helpers/usePreviousHelper';

interface InjectedProps {
    onChange: (entity: BaseDropdownItem | null) => void;
    label: string;
    value?: BaseDropdownItem | null;
}

interface StateModel {
    loading: boolean;
    allApplicationTypes: BaseDropdownItem[];
    totalApplicationTypes: number | null;
    page: number;
    searchQuery: string;
}

interface DispatchModel {
    increasePageNumber: typeof ApplicationTypesActions.increasePageNumber;
    getAllApplicationTypes: typeof ApplicationTypesActions.getAllApplicationTypes;
    setFilterQuery: typeof ApplicationTypesActions.setSearchQuery;
}

type PropsTypes = InjectedProps & DispatchModel & StateModel;

const DropdownInputApplicationTypesListWrapper: React.FC<PropsTypes> = props => {
    const [initialLoaded, setInitialLoaded] = useState(false);

    const {
        loading,
        page,
        value,
        onChange,
        increasePageNumber,
        label,
        setFilterQuery,
        searchQuery,
        allApplicationTypes,
        getAllApplicationTypes,
        totalApplicationTypes,
    } = props;

    const isSearchQueryChanged = useCompare(searchQuery);

    const loadData = useCallback(
        (page: number, size: number, sort: string, searchQuery: string, overrideRecords: boolean) => {
            getAllApplicationTypes({
                page,
                size,
                sort,
                searchQuery,
                overrideRecords,
            });
        },
        [getAllApplicationTypes]
    );

    useEffect(() => {
        setFilterQuery('');
        loadData(0, DefaultPageSize, '', '', true);
        setInitialLoaded(true);
    }, [setFilterQuery, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(loading, page, allApplicationTypes.length, initialLoaded, DefaultPageSize) ||
            isSearchQueryChanged
        ) {
            loadData(page, DefaultPageSize, '', searchQuery, false);
        }
    }, [page, searchQuery, loading, allApplicationTypes, initialLoaded, loadData, isSearchQueryChanged]);

    return (
        <DropdownInputList
            value={value}
            onChange={onChange}
            label={label}
            loading={loading}
            allEntities={allApplicationTypes}
            totalEntities={totalApplicationTypes}
            searchQuery={searchQuery}
            increasePageNumber={increasePageNumber}
            setFilterQuery={setFilterQuery}
        />
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.applicationType.loading,
        allApplicationTypes: state.applicationType.allApplicationTypes,
        totalApplicationTypes: state.applicationType.totalApplicationTypes,
        page: state.applicationType.page,
        searchQuery: state.applicationType.searchQuery,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllApplicationTypes: bindActionCreators(ApplicationTypesActions.getAllApplicationTypes, dispatch),
        increasePageNumber: bindActionCreators(ApplicationTypesActions.increasePageNumber, dispatch),
        setFilterQuery: bindActionCreators(ApplicationTypesActions.setSearchQuery, dispatch),
    })
)(DropdownInputApplicationTypesListWrapper);
