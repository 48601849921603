import { IsDefined, IsEmail, IsOptional, IsString, MaxLength, MinLength } from 'class-validator';
import { registerDecorator, ValidationOptions } from 'class-validator';

export function isPhoneNumberValid(validationOptions?: ValidationOptions) {
    return function(object: Object, propertyName: string) {
        registerDecorator({
            name: 'isPhoneNumberValid',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: {
                validate(value: string) {
                    return (value.trim().match(/ /g) || []).length === 2 && value.trim().length === 16;
                },
            },
        });
    };
}

export function isBirthValid(validationOptions?: ValidationOptions) {
    return function(object: Object, propertyName: string) {
        registerDecorator({
            name: 'isBirthValid',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: {
                validate(value: string | Date | null) {
                    return !!value && value !== 'Invalid Date' && value <= new Date();
                },
            },
        });
    };
}

export class EditPatientDto {
    @IsEmail({}, { message: 'Email has invalid format' })
    @MinLength(1, { message: 'Email cannot be empty' })
    @MaxLength(30, { message: 'Email is too long' })
    email: string;

    @IsString()
    @MinLength(1, { message: 'First Name cannot be empty' })
    @MaxLength(30, { message: 'First Name is too long' })
    firstName: string;

    @IsString()
    @MinLength(1, { message: 'Last Name cannot be empty' })
    @MaxLength(30, { message: 'Last Name is too long' })
    lastName: string;

    @IsDefined()
    @MinLength(1, { message: 'Gender cannot be empty' })
    gender: string;

    @IsOptional()
    @IsString()
    @isPhoneNumberValid({ message: 'Phone is not valid' })
    phone?: string;

    @IsOptional()
    @isBirthValid({ message: 'Birth Date is not valid' })
    birthDate?: Date;

    @IsOptional()
    @IsString()
    @MinLength(1, { message: 'City cannot be empty' })
    @MaxLength(30, { message: 'City is too long' })
    city?: string;

    @IsOptional()
    @IsString()
    @MinLength(1, { message: 'Zip cannot be empty' })
    @MaxLength(30, { message: 'Zip is too long' })
    zip?: string;

    @IsOptional()
    @IsString()
    @MinLength(1, { message: 'Address 1 cannot be empty' })
    @MaxLength(30, { message: 'Address 1 is too long' })
    streetAddress1?: string;

    @IsOptional()
    @IsString()
    @MinLength(1, { message: 'Address 2 cannot be empty' })
    @MaxLength(30, { message: 'Address 2 is too long' })
    streetAddress2?: string;

    @IsOptional()
    @IsString()
    @MinLength(1, { message: 'State cannot be empty' })
    @MaxLength(30, { message: 'State is too long' })
    state?: string;
}
