import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';
import addCard from '../../../../static/icons/payment/add-card.svg';
import { colors } from '../../../../theme/colors';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const createStyles = makeStyles({
    text: {
        color: colors.redText,
        fontSize: '12px',
        marginLeft: '8px',
    },
});

const AddCardLink: React.FC<{ onClick: () => void }> = props => {
    const classes = createStyles(props);
    const { onClick } = props;
    return (
        <Wrapper onClick={onClick}>
            <img src={addCard} alt="" />
            <Typography variant={'button'} classes={{ root: classes.text }}>
                Add new card
            </Typography>
        </Wrapper>
    );
};

export default AddCardLink;
