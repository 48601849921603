import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FabButton from '../../shared/button/FabButton';
import PanelTypesTabs from '../panelType/PanelTypesTabs';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as PanelTypeActions } from '../../store/actions/panelType.actions';
import { PanelTypeModel } from '../../models/responses/panelType.model';
import LoadingTransparent from '../shared/LoadingTransparent';
import PanelTypeItem from '../panelType/PanelTypeItem';
import { BloodtargetReponce } from '../../models/responses/bloodtarget.reponce';
import { SortBaseDropdownItem } from '../../helpers/arrayHelpers';
import RequestState from '../../constants/requestState';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router';

const Wrapper = styled.div`
    height: 100%;
    position: relative;
`;

interface StoreModel {
    panelTypes: PanelTypeModel[];
    panelTypesLoading: boolean;
    editPanelTypeRequest: RequestState;
    removePanelTypeRequest: RequestState;
}

interface DispatchModel {
    getPanelTypeWithTargets: typeof PanelTypeActions.getPanelTypeWithTargets;
    resetStatusVariables: typeof PanelTypeActions.resetStatusVariables;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const PanelTypes: React.FC<PropsTypes> = props => {
    const {
        panelTypesLoading,
        getPanelTypeWithTargets,
        panelTypes,
        editPanelTypeRequest,
        removePanelTypeRequest,
        resetStatusVariables,
    } = props;

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (props.match.params.id && panelTypes) {
            const activeIndex = panelTypes.findIndex(p => p.id === Number(props.match.params.id));
            if (activeIndex !== -1) {
                setActiveTab(activeIndex);
            }
        }
    }, [props.match, panelTypes]);

    useEffect(() => {
        getPanelTypeWithTargets();
    }, [getPanelTypeWithTargets]);

    const setTab = useCallback(
        (value: number) => {
            props.history.push(`/admin/panel-types/${panelTypes[value].id}`);
        },
        [panelTypes, props.history]
    );

    const isLoading = useMemo(() => {
        return (
            panelTypesLoading ||
            editPanelTypeRequest === RequestState.SENDING ||
            removePanelTypeRequest === RequestState.SENDING
        );
    }, [panelTypesLoading, removePanelTypeRequest, editPanelTypeRequest]);

    const activePanel = useMemo(() => {
        return panelTypes ? panelTypes[activeTab] : null;
    }, [activeTab, panelTypes]);

    const allBloodTargets = useMemo(() => {
        return activePanel && activePanel.bloodTargets
            ? SortBaseDropdownItem(
                  panelTypes
                      .reduce((r: any, { bloodTargets }) => [...r, ...bloodTargets], [])
                      .map((t: BloodtargetReponce) => {
                          return { id: t.id, name: t.target };
                      })
              )
            : [];
    }, [activePanel, panelTypes]);

    const bloodTargetValues = useMemo(() => {
        return activePanel && activePanel.bloodTargets
            ? SortBaseDropdownItem(
                  activePanel.bloodTargets.map(t => {
                      return { id: t.id, name: t.target };
                  })
              )
            : [];
    }, [activePanel]);

    useEffect(() => {
        if (editPanelTypeRequest === RequestState.SENT_SUCCESS) {
            getPanelTypeWithTargets();
        }
    }, [getPanelTypeWithTargets, editPanelTypeRequest]);

    useEffect(() => {
        if (removePanelTypeRequest === RequestState.SENT_SUCCESS) {
            resetStatusVariables();
            getPanelTypeWithTargets();
            setActiveTab(0);
        }
        if (removePanelTypeRequest === RequestState.SENT_FAILED) {
            resetStatusVariables();
        }
    }, [removePanelTypeRequest, resetStatusVariables, getPanelTypeWithTargets]);

    return (
        <Wrapper>
            {isLoading && <LoadingTransparent />}
            {panelTypes && <PanelTypesTabs tabs={panelTypes} tab={activeTab} setTab={setTab} />}

            {activePanel && panelTypes && (
                <PanelTypeItem
                    activePanel={activePanel}
                    panelTypes={panelTypes}
                    allBloodTargets={allBloodTargets}
                    bloodTargetValues={bloodTargetValues}
                />
            )}

            <FabButton redirectUrl={'/admin/panel-types/add-panel-types'} />
        </Wrapper>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        panelTypes: store.panelType.panelTypes,
        panelTypesLoading: store.panelType.panelTypesLoading,
        editPanelTypeRequest: store.panelType.editPanelTypeRequest,
        removePanelTypeRequest: store.panelType.removePanelTypeRequest,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getPanelTypeWithTargets: bindActionCreators(PanelTypeActions.getPanelTypeWithTargets, dispatch),
        resetStatusVariables: bindActionCreators(PanelTypeActions.resetStatusVariables, dispatch),
    })
)(withRouter(PanelTypes));
