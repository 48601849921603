import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';

const Background = styled.div`
    height: 46px;
    background-color: ${colors.textSecondary};
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const createStyles = makeStyles({
    text: {
        color: colors.white,
        fontSize: '15px',
        marginTop: '4px',
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
});

const BlockHeader: React.FC = props => {
    const classes = createStyles(props);

    return (
        <Background>
            <SizeControllerWrapper>
                <Wrapper>
                    <Typography variant={'button'} classes={{ root: classes.text }}>
                        {props.children}
                    </Typography>
                </Wrapper>
            </SizeControllerWrapper>
        </Background>
    );
};

export default BlockHeader;
