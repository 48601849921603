import * as therapiesActions from '../../actions/therapies.actions';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';

export class StateModel {
    allTherapies: BaseDropdownItem[] = [];
    page: number = 0;
    totalTherapies: number | null = null;
    loading: boolean = false;
    searchQuery: string = '';
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: therapiesActions.Actions) {
    switch (action.type) {
        case therapiesActions.ActionNames.GET_ALL_THERAPIES: {
            return {
                ...state,
                loading: true,
            };
        }

        case therapiesActions.ActionNames.GET_ALL_THERAPIES_UPDATE_SUCCESS: {
            const isEmptyString = state.searchQuery.trim().length === 0;
            const newLabsList = state.allTherapies.concat(action.payload.result);
            const isRedundant = newLabsList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalTherapies: action.payload.total,
                allTherapies: isEmptyString && !isRedundant ? newLabsList : action.payload.result,
            };
        }

        case therapiesActions.ActionNames.GET_ALL_THERAPIES_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loading: false,
                totalTherapies: action.payload.total,
                allTherapies: action.payload.result,
            };
        }

        case therapiesActions.ActionNames.GET_ALL_THERAPIES_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case therapiesActions.ActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case therapiesActions.ActionNames.SET_SEARCH_QUERY: {
            const isEmptyString = action.payload.trim().length === 0;
            return {
                ...state,
                searchQuery: action.payload,
                loading: isEmptyString ? false : state.loading,
                page: 0,
                allTherapies: isEmptyString ? [] : state.allTherapies,
                totalTherapies: 0,
            };
        }

        default:
            return state;
    }
}
