import {toTitleCase} from "../../helpers/stringHelpers";

export const nameForDisplaying = (typeName: string) => {
    return typeName.replace('_', ' - ');
};

export const nameForSending = (typeName: string) => {
    return typeName.replace(' - ', '_');
};

export const displayingInvoiceType = (str: string) => {
    return nameForDisplaying(toTitleCase((str.toLowerCase())));
};
