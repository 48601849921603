import Axios from 'axios';
import { TYPED_ENV } from '../helpers/typedEnv';
import StorageHelper from '../helpers/storageHelper';
import { HttpRequestError } from '../errors/errors';

export const restAxios = Axios.create({
    baseURL: TYPED_ENV.REACT_APP_API_URL,
});

restAxios.interceptors.request.use(
    async resp => {
        const jwtToken = StorageHelper.token();
        if (jwtToken) {
            resp.headers['Authorization'] = `bearer ${jwtToken}`;
        } else {
            StorageHelper.clear();
        }
        return resp;
    },
    error => {
        if (error.response && error.response.status === 401) {
            StorageHelper.clear();
        } else {
        }
        throw new HttpRequestError(error.response.status, error.response.statusText || '');
    }
);

restAxios.interceptors.response.use(
    response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        throw new HttpRequestError(error.response.data.status || error.status, error.response.data.message || '');
    }
);
