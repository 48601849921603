import React from 'react';
import homeheadermobile from '../../../../static/images/landing/main/homeheadermobile.jpg';
import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import SizeControllerWrapper from '../../../../shared/SizeControllerWrapper';
import PrimaryButton from '../../../../shared/button/PrimaryButton';
import { makeStyles, Typography } from '@material-ui/core';
import { mediaQuery } from '../../../../constants/device';
import { Link } from 'react-scroll';

const createStyles = makeStyles(theme => ({
    heading: {
        fontSize: '36px',
        lineHeight: '46px',
        marginBottom: '20px',
        background: '#ffffffbf',
        padding: '20px 0',
        [theme.breakpoints.up('md')]: {
            fontSize: '48px',
            padding: '36px',
        },
    },
}));

const BackgroundWrapper = styled.div`
    height: 100vh;
    background-image: url(${homeheadermobile});
    background-repeat: no-repeat;
    background-color: ${colors.gray2};
    background-position: center center;
    background-size: cover;
`;

const Content = styled.div`
    position: absolute;
    bottom: 40px;
    left: 0;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;

    ${mediaQuery.tablet} {
        top: 60%;
    }

    ${mediaQuery.laptop} {
        top: 50%;
        width: 100%;
    }
`;

const RelativeContainer = styled.div`
    position: relative;
    height: 100%;
`;

const FirstScreen: React.FC = props => {
    const classes = createStyles(props);
    return (
        <BackgroundWrapper>
            <RelativeContainer>
                <SizeControllerWrapper>
                    <Content>
                        <Typography variant="h1" classes={{ root: classes.heading }}>
                            Your Partner in Alternative Supplementation Blood Tracking
                        </Typography>
                        <Link
                            activeClass="active"
                            to="requestConsultationBlock"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            <PrimaryButton white size={'large'}>
                                Find out More
                            </PrimaryButton>
                        </Link>
                    </Content>
                </SizeControllerWrapper>
            </RelativeContainer>
        </BackgroundWrapper>
    );
};

export default FirstScreen;
