import React, { useCallback, useEffect } from 'react';
import HeaderHeadingWithLink from '../../../shared/HeaderHeadingWithLink';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import { makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers';
import { Actions as InvoiceActions } from '../../../store/actions/invoice.actions';
import { Actions as AuthActions } from '../../../store/actions/auth.actions';
import { UserModel } from '../../../models/responses/user.response';
import { bindActionCreators, Dispatch } from 'redux';
import RequestState from '../../../constants/requestState';
import LoadingTransparent from '../../shared/LoadingTransparent';
import { withRouter, RouteComponentProps } from 'react-router';
import { TYPED_ENV } from '../../../helpers/typedEnv';
import { PaymentRoutingProvider } from '../../../constants/tabRouting/routing';

const createStyles = makeStyles({
    text: {
        marginTop: '20vh',
        marginBottom: '40px',
        fontSize: '16px',
    },
    boldText: {
        fontSize: '16px',
    },
    resentText: {
        color: colors.textSecondary,
        fontSize: '14px',
        marginTop: '18px',
    },
    linkSendAgain: {
        fontSize: '20px',
        textDecoration: 'underline',
        textDecorationColor: colors.textSecondary,
        cursor: 'pointer',
    },
});

const Wrapper = styled.div`
    text-align: center;
`;

const RelativeWrapper = styled.div`
    position: relative;
    height: 100%;
`;

interface StoreModel {
    user: UserModel | null;
    stateString: string | null;
    generateStringRequest: RequestState;
}

interface DispatchModel {
    generateState: typeof InvoiceActions.generateState;
    resetStatusVariables: typeof InvoiceActions.resetStatusVariables;
    getCurrentUser: typeof AuthActions.getCurrentUser;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const CreateMerchant: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);
    const { user, generateState, resetStatusVariables, stateString, generateStringRequest, getCurrentUser } = props;

    const redirectToCreateAccount = useCallback(() => {
        generateState();
    }, [generateState]);

    useEffect(() => {
        getCurrentUser();
    }, [getCurrentUser]);

    useEffect(() => {
        user && user.merchant && props.history.push(PaymentRoutingProvider[0].path);
    }, [user, props.history]);

    useEffect(() => {
        if (stateString && user) {
            window.open(
                `https://connect.stripe.com/express/oauth/authorize?client_id=${TYPED_ENV.REACT_APP_CLIENT_ID}&state=${stateString}&suggested_capabilities[]=transfers&suggested_capabilities[]=card_payments&stripe_user[email]=${user.email}`,
                '_blank'
            );
            resetStatusVariables();
        }
    }, [stateString, user, resetStatusVariables]);

    return (
        <RelativeWrapper>
            {generateStringRequest === RequestState.SENDING && <LoadingTransparent />}
            <HeaderHeadingWithLink>Payments</HeaderHeadingWithLink>
            <SizeControllerWrapper>
                <Wrapper>
                    <Typography variant={'body1'} classes={{ root: classes.text }}>
                        To work with payments you <br /> need to set up{' '}
                        <Typography variant={'button'} classes={{ root: classes.boldText }}>
                            {' '}
                            payments with Stripe
                        </Typography>
                        .
                    </Typography>
                    <PrimaryButton onClick={redirectToCreateAccount}>Set Up</PrimaryButton>
                </Wrapper>
            </SizeControllerWrapper>
        </RelativeWrapper>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
        stateString: state.invoice.stateString,
        generateStringRequest: state.invoice.generateStringRequest,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        generateState: bindActionCreators(InvoiceActions.generateState, dispatch),
        resetStatusVariables: bindActionCreators(InvoiceActions.resetStatusVariables, dispatch),
        getCurrentUser: bindActionCreators(AuthActions.getCurrentUser, dispatch),
    })
)(withRouter(CreateMerchant));
