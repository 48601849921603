import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BlockHeader from '../BlockHeader';
import { connect } from 'react-redux';
import * as fromRoot from '../../../../store/reducers';
import * as invoiceActions from '../../../../store/actions/invoice.actions';
import { InvoiceModel } from '../../../../models/responses/invoice.model';
import { bindActionCreators, Dispatch } from 'redux';
import HistoryInfiniteScrollList from './HistoryInfiniteScrollList';
import NoData from '../NoData';
import Loading from '../../../shared/Loading';
import { UserModel } from '../../../../models/responses/user.response';
import { RoleOptions } from '../../../../constants/roleOptions';
import RequestState from '../../../../constants/requestState';
import {
    DefaultPageSize,
    isNoDataToFetch,
    shouldFetchData,
} from '../../../../constants/dataFetching/allEntitiesByPages';
import { convertCentsToDollars } from '../../../../helpers/convertMoney';
import { templateDisplayingDollar } from '../../../../shared/utils/templateDisplayingDollar';

interface StoreModel {
    user: UserModel | null;
    loading: boolean;
    invoiceOneTimeArchived: InvoiceModel[];
    pageNumberArchived: number;
    totalInvoiceOneTimeArchived: number | null;
    rejectRequest: RequestState;
    totalPaidInvoiceOneTimeArchived: number;
}

interface DispatchModel {
    getAllOneTimeArchivedPatient: typeof invoiceActions.Actions.getAllOneTimeArchivedPatient;
    getAllOneTimeArchivedProvider: typeof invoiceActions.Actions.getAllOneTimeArchivedProvider;
    getAllOneTimeArchivedProviderAllPatients: typeof invoiceActions.Actions.getAllOneTimeArchivedProviderAllPatients;
    increasePageNumberArchived: typeof invoiceActions.Actions.increasePageNumberOneTimeArchived;
}

interface InjectedProps {
    patientId?: number | null;
}

type PropsTypes = StoreModel & DispatchModel & InjectedProps;

const HistoryBlock: React.FC<PropsTypes> = props => {
    const {
        loading,
        totalInvoiceOneTimeArchived,
        pageNumberArchived,
        invoiceOneTimeArchived,
        getAllOneTimeArchivedPatient,
        getAllOneTimeArchivedProvider,
        increasePageNumberArchived,
        getAllOneTimeArchivedProviderAllPatients,
        user,
        patientId,
        rejectRequest,
        totalPaidInvoiceOneTimeArchived,
    } = props;

    const [initialLoaded, setInitialLoaded] = useState(false);

    const loadData = useCallback(
        (pageNumberArchived: number, overrideRecords: boolean) => {
            if (user!.role === RoleOptions.PROVIDER) {
                if (patientId) {
                    getAllOneTimeArchivedProvider({
                        data: {
                            page: pageNumberArchived,
                            size: DefaultPageSize,
                            overrideRecords,
                        },
                        patientId: patientId,
                    });
                } else {
                    getAllOneTimeArchivedProviderAllPatients({
                        page: pageNumberArchived,
                        size: DefaultPageSize,
                        overrideRecords,
                    });
                }
            } else {
                getAllOneTimeArchivedPatient({
                    page: pageNumberArchived,
                    size: DefaultPageSize,
                    overrideRecords,
                });
            }
        },
        [
            getAllOneTimeArchivedProvider,
            getAllOneTimeArchivedProviderAllPatients,
            getAllOneTimeArchivedPatient,
            user,
            patientId,
        ]
    );

    useEffect(() => {
        if (rejectRequest === RequestState.UNSENT) {
            loadData(0, true);
            setInitialLoaded(true);
        }
    }, [rejectRequest, loadData]);

    useEffect(() => {
        if (
            shouldFetchData(loading, pageNumberArchived, invoiceOneTimeArchived.length, initialLoaded, DefaultPageSize)
        ) {
            loadData(pageNumberArchived, false);
        }
    }, [pageNumberArchived, loading, patientId, user, initialLoaded, invoiceOneTimeArchived, loadData]);

    const isNoData = useMemo(() => {
        return isNoDataToFetch(totalInvoiceOneTimeArchived, loading, initialLoaded);
    }, [loading, totalInvoiceOneTimeArchived, initialLoaded]);

    return (
        <>
            <BlockHeader>
                <div>History</div>
                <div>
                    Total paid : {templateDisplayingDollar(convertCentsToDollars(totalPaidInvoiceOneTimeArchived))}
                </div>
            </BlockHeader>

            {isNoData && <NoData />}

            {loading && !initialLoaded && <Loading smallSize />}

            <HistoryInfiniteScrollList
                loading={loading}
                page={pageNumberArchived}
                allEntities={invoiceOneTimeArchived}
                increasePageNumber={increasePageNumberArchived}
                totalEntities={totalInvoiceOneTimeArchived}
                patientId={patientId}
            />
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
        loading: state.invoice.loadingOneTimeArchived,
        invoiceOneTimeArchived: state.invoice.invoiceOneTimeArchived,
        pageNumberArchived: state.invoice.pageNumberOneTimeArchived,
        totalInvoiceOneTimeArchived: state.invoice.totalInvoiceOneTimeArchived,
        rejectRequest: state.invoice.rejectRequest,
        totalPaidInvoiceOneTimeArchived: state.invoice.totalPaidInvoiceOneTimeArchived,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getAllOneTimeArchivedPatient: bindActionCreators(invoiceActions.Actions.getAllOneTimeArchivedPatient, dispatch),
        getAllOneTimeArchivedProvider: bindActionCreators(
            invoiceActions.Actions.getAllOneTimeArchivedProvider,
            dispatch
        ),
        getAllOneTimeArchivedProviderAllPatients: bindActionCreators(
            invoiceActions.Actions.getAllOneTimeArchivedProviderAllPatients,
            dispatch
        ),
        increasePageNumberArchived: bindActionCreators(
            invoiceActions.Actions.increasePageNumberOneTimeArchived,
            dispatch
        ),
    })
)(HistoryBlock);
