import React from 'react';
import styled from 'styled-components';
import theme from '../../theme/theme';
import { colors } from '../../theme/colors';
import halfLogoBackground from '../../static/images/landing/main/halfLogoBackground.svg';
import { mediaQuery } from '../../constants/device';

const Wrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 38% 25% 37%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 19px;
    border-radius: 30px;
    box-shadow: ${theme.boxShadowCard};
    background-image: url(${halfLogoBackground});
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-position: right 48%;
    background-size: 36%;

    ${mediaQuery.tablet} {
        height: 75%;
    }
    ${mediaQuery.laptop} {
        height: 400px;
        width: 60%;
        margin-top: 70px;
        background-size: 25%;
    }
`;

const Card: React.FC = props => {
    return <Wrapper>{props.children}</Wrapper>;
};

export default Card;
