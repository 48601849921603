import {
    IsNotEmpty,
    IsString,
    MaxLength,
    MinLength,
    IsOptional,
} from 'class-validator';


export class AddBloodTargetDto {
    @IsNotEmpty({message: 'Panel Type cannot be empty'})
    bloodTargetPanelTypeId: number;

    @IsString()
    @IsOptional()
    label: string;

    @IsString()
    @MinLength(1, {message: 'Target cannot be empty'})
    @MaxLength(30, {message: 'Target is too long'})
    target: string;

    @IsString()
    @MinLength(1, {message: 'Target cannot be empty'})
    @MaxLength(30, {message: 'Target is too long'})
    unit: string;

    @IsString()
    @IsOptional()
    femaleMinRange: number;

    @IsString()
    @IsOptional()
    femaleMaxRange: number;

    @IsString()
    @IsOptional()
    maleMinRange: number;

    @IsString()
    @IsOptional()
    maleMaxRange: number;
}
