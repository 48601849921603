import {put, call, takeEvery} from 'redux-saga/effects';
import * as labworkActions from '../../actions/labwork.actions';
import * as bloodTargetActions from '../../actions/bloodTarget.actions';
import LabWorkApi from "../../../api/labWork/LabWorkApi";

function* getAllBloodLabworksPatient(action: labworkActions.getAllBloodLabWorkPatientType) {
    try {
        const response = yield call(LabWorkApi.getAllBloodWorksPatient, action.payload);
        yield put({type: labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_FAILED});
    }
}

function* getAllBloodLabworksProvider(action: labworkActions.getAllBloodLabWorkProviderType) {
    try {
        const response = yield call(LabWorkApi.getAllBloodWorksByPatientIdProvider, action.payload);
        yield put({type: labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_FAILED})
    }
}

function* getBloodworkPatient(action: labworkActions.getBloodLabworkPatientType) {
    try {
        const response = yield call(LabWorkApi.getBloodWorkByIdPatient, action.payload);
        yield put({type: labworkActions.ActionNames.GET_BLOOD_LABWORK_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: labworkActions.ActionNames.GET_BLOOD_LABWORK_FAILED})
    }
}

function* getBloodworkProvider(action: labworkActions.getBloodLabworkProviderType) {
    try {
        const response = yield call(LabWorkApi.getBloodWorkByIdProvider, action.payload);
        yield put({type: labworkActions.ActionNames.GET_BLOOD_LABWORK_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: labworkActions.ActionNames.GET_BLOOD_LABWORK_FAILED})
    }
}

function* uploadBloodworkPatient(action: labworkActions.uploadBloodworkPatientType) {
    try {
        const response = yield call(LabWorkApi.uploadBloodWorkPatient, action.payload);
        if (action.payload.file) {
            yield call(LabWorkApi.uploadBloodWorkFilePatient, {...action.payload, id: response.data.labWorkId});
        }
        yield put({type: labworkActions.ActionNames.UPLOAD_BLOODWORK_PATIENT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: labworkActions.ActionNames.UPLOAD_BLOODWORK_PATIENT_FAILED});
    }
}

function* uploadBloodworkProvider(action: labworkActions.uploadBloodworkProviderType) {
    try {
        const response = yield call(LabWorkApi.uploadBloodWorkProvider, action.payload);
        if (action.payload.file) {
            yield call(LabWorkApi.uploadBloodWorkFileProvider, {...action.payload, id: response.data.labWorkId});
        }
        yield put({type: labworkActions.ActionNames.UPLOAD_BLOODWORK_PROVIDER_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: labworkActions.ActionNames.UPLOAD_BLOODWORK_PROVIDER_FAILED})
    }
}

function* getBloodTargets(action: bloodTargetActions.getAllBloodTargetsType) {
    try {
        const response = yield call(LabWorkApi.getBloodTargets, action.payload);
        if (action.payload.overrideRecords) {
            yield put({
                type: bloodTargetActions.ActionNames.GET_ALL_BLOOD_TARGETS_OVERRIDE_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({type: bloodTargetActions.ActionNames.GET_ALL_BLOOD_TARGETS_UPDATE_SUCCESS, payload: response.data});
        }
    } catch (e) {
        yield put({type: bloodTargetActions.ActionNames.GET_ALL_BLOOD_TARGETS_FAILED})
    }
}

function* updateBloodworkPatient(action: labworkActions.updateBloodLabworkPatientType) {
    try {
        const response = yield call(LabWorkApi.updateBloodWorkPatient, action.payload);
        // if (action.payload.shouldUpdateFile) {
        //     yield call(LabWorkApi.uploadBloodWorkFilePatient, {...action.payload, id: response.data.labWorkId});
        // }
        yield put({type: labworkActions.ActionNames.UPDATE_BLOODWORK_PATIENT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: labworkActions.ActionNames.UPDATE_BLOODWORK_PATIENT_FAILED})
    }
}

export default function* LabworkSagas() {
    yield takeEvery(labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_PATIENT, getAllBloodLabworksPatient);
    yield takeEvery(labworkActions.ActionNames.GET_ALL_BLOOD_LABWORKS_PROVIDER, getAllBloodLabworksProvider);
    yield takeEvery(labworkActions.ActionNames.GET_BLOOD_LABWORK_PATIENT, getBloodworkPatient);
    yield takeEvery(labworkActions.ActionNames.GET_BLOOD_LABWORK_PROVIDER, getBloodworkProvider);
    yield takeEvery(labworkActions.ActionNames.UPLOAD_BLOODWORK_PATIENT, uploadBloodworkPatient);
    yield takeEvery(labworkActions.ActionNames.UPLOAD_BLOODWORK_PROVIDER, uploadBloodworkProvider);
    yield takeEvery(bloodTargetActions.ActionNames.GET_ALL_BLOOD_TARGETS, getBloodTargets);
    yield takeEvery(labworkActions.ActionNames.UPDATE_BLOODWORK_PATIENT, updateBloodworkPatient);
}