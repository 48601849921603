import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HeaderHeadingWithLink from '../../shared/HeaderHeadingWithLink';
import styled from 'styled-components';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../constants/layout';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import addRowIcon from '../../static/icons/addRow.svg';
import LoadingTransparent from '../shared/LoadingTransparent';
import Snackbar, { SnackbarTypes } from '../../shared/snackbars/snackbar';
import { RouteComponentProps } from 'react-router';
import CancelLink from '../../shared/link/CancelLink';
import AddBloodTargetRow from './AddBloodTargetRow';
import fillClassInstanceWithValues from '../../shared/utils/fillClassInstanceWithValues';
import { validateSync } from 'class-validator';
import { ValidationErrorsMap } from '../../models/validation/validationErrorsMap';
import { connect } from 'react-redux';
import * as rootStateModel from '../../store/reducers/index';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as BloodTargetActions } from '../../store/actions/bloodTarget.actions';
import { Actions as PanelTypeActions } from '../../store/actions/panelType.actions';
import { PanelTypeModel } from '../../models/responses/panelType.model';
import { AddBloodTargetDto } from '../../models/dto/AddBloodTarget.dto';
import RequestState from '../../constants/requestState';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import rangeValid from '../../shared/utils/validation/range-validation';

const Wrapper = styled.div<{ height: number }>`
    overflow: scroll;
    min-height: ${props => props.height}px;
    margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const tableCols: string[] = ['Target', 'Label', 'Panel Type', 'Female Range', 'Male Range', 'Unit'];

export interface BloodTargetRow {
    bloodTargetPanelType: string;
    bloodTargetPanelTypeId: number | null;
    femaleMinRange: string;
    femaleMaxRange: string;
    maleMinRange: string;
    maleMaxRange: string;
    id: number;
    label: string;
    target: string;
    unit: string;
    isBeingEdited: Set<string>;
}

const initialStateRow = {
    bloodTargetPanelType: '',
    bloodTargetPanelTypeId: null,
    femaleMinRange: '',
    femaleMaxRange: '',
    maleMinRange: '',
    maleMaxRange: '',
    id: 0,
    label: '',
    target: '',
    unit: '',
    isBeingEdited: new Set<string>(),
};

const initialState = [initialStateRow];

const createStyles = makeStyles({
    iconRow: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
    addRow: {
        border: 'none',
    },
});

const localBottomPadding = 156;

interface StateModel {
    panelTypes: PanelTypeModel[];
    panelTypesLoading: boolean;
    addBloodTargetsRequest: RequestState;
    user: UserModel | null;
}

interface DispatchModel {
    getPanelType: typeof PanelTypeActions.getPanelType;
    addBloodTargets: typeof BloodTargetActions.addBloodTargets;
    resetStatusVariables: typeof BloodTargetActions.resetStatusVariables;
}

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const AddBloodTargets: React.FC<PropsTypes> = props => {
    const [height, setHeight] = useState(0);
    const isLaptop = useMediaQuery(mediaQuery.laptop);
    const listRef = useRef(null);

    const [errorMessage, setErrorMessage] = useState('');

    const [table, setTable] = useState<BloodTargetRow[]>(initialState);

    const classes = createStyles(props);

    const {
        panelTypes,
        getPanelType,
        panelTypesLoading,
        addBloodTargets,
        addBloodTargetsRequest,
        resetStatusVariables,
        user,
    } = props;

    const changeRow = useCallback(
        (filedName: string, value: string | number | number[] | PanelTypeModel | null, id: number) => {
            setErrorMessage('');
            const currentRow = table.filter(r => r.id === id)[0];
            const index = table.indexOf(currentRow);
            const newTable = [...table];
            newTable[index] = {
                ...currentRow,
                [filedName]: value,
                isBeingEdited: currentRow.isBeingEdited.add(filedName),
            };
            setTable(newTable);
        },
        [table]
    );

    const removeRow = useCallback(
        (id: number) => {
            const currentRow = table.filter(r => r.id === id)[0];
            const index = table.indexOf(currentRow);
            setTable([...table.slice(0, index), ...table.slice(index + 1)]);
        },
        [table]
    );

    const addRow = useCallback(() => {
        const lastIndex = table[table.length - 1].id;
        const newRow = { ...initialStateRow, id: lastIndex + 1 };
        setTable([...table, newRow]);
    }, [table]);

    const canRemoveRow = useMemo(() => {
        return table.length > 1;
    }, [table]);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height =
                window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top - localBottomPadding;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    const getFilledTargets = useCallback(() => {
        return table.filter(row => row.target.length > 0 && row.unit.length > 0 && !!row.bloodTargetPanelTypeId);
    }, [table]);

    const validMinMaxValues = useCallback((target: BloodTargetRow) => {
        return !(
            !rangeValid(target.femaleMinRange, target.femaleMaxRange) ||
            !rangeValid(target.maleMinRange, target.maleMaxRange)
        );
    }, []);

    const validateForm = (user: any) => {
        const item = fillClassInstanceWithValues<AddBloodTargetDto>(AddBloodTargetDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                map[err.property] = Object.entries(err.constraints)
                    .map(([, value]) => value)
                    .join(', ');
            });
        }
        return map;
    };

    const isFormValid = useCallback(
        (table: BloodTargetRow[]) => {
            let valid = true;
            const filled = table.filter(
                row => row.target.length > 0 || row.unit.length > 0 || row.bloodTargetPanelType.length > 0
            );
            if (filled.length) {
                filled.forEach(row => {
                    const map = validateForm(row);
                    if (Object.keys(map).length !== 0 || !validMinMaxValues(row)) {
                        valid = false;
                    }
                });
                return valid;
            }
            return false;
        },
        [validMinMaxValues]
    );

    const save = useCallback(() => {
        if (isFormValid(table)) {
            addBloodTargets(getFilledTargets());
        } else {
            setErrorMessage('Fill the whole row in the table');
        }
    }, [table, addBloodTargets, getFilledTargets, isFormValid]);

    useEffect(() => {
        if (addBloodTargetsRequest === RequestState.SENT_SUCCESS && user) {
            props.history.push(user.role === RoleOptions.ADMIN ? '/admin/bloodtargets' : '/bloodtargets');
        }
    }, [addBloodTargetsRequest, props.history, user]);

    useEffect(() => {
        getPanelType();
    }, [getPanelType]);

    useEffect(() => {
        setTable([{ ...initialStateRow, isBeingEdited: new Set<string>() }]);
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    return (
        <>
            {panelTypesLoading && <LoadingTransparent />}
            <HeaderHeadingWithLink>Add Blood Targets</HeaderHeadingWithLink>
            <Wrapper ref={listRef} height={height}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {tableCols.map((item, index) => (
                                <TableCell key={index}>
                                    <Typography variant={'button'} key={index}>
                                        {item}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table.map((row, index) => (
                            <AddBloodTargetRow
                                key={index}
                                row={row}
                                changeRow={changeRow}
                                removeRow={removeRow}
                                canRemoveRow={canRemoveRow}
                                panelTypes={panelTypes}
                            />
                        ))}
                        <TableRow>
                            <TableCell className={classes.addRow}>
                                <img src={addRowIcon} alt="" className={classes.iconRow} onClick={addRow} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Wrapper>
            <ButtonWrapper>
                <PrimaryButton onClick={save}>Save Blood Targets</PrimaryButton>
            </ButtonWrapper>

            <CancelLink />

            <Snackbar
                message={errorMessage}
                open={!!errorMessage.length}
                variant={'error'}
                type={SnackbarTypes.ERROR}
            />
        </>
    );
};

export default connect(
    (state: rootStateModel.RootStateModel): StateModel => ({
        panelTypes: state.panelType.panelTypes,
        panelTypesLoading: state.panelType.panelTypesLoading,
        addBloodTargetsRequest: state.bloodTarget.addBloodTargetsRequest,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        getPanelType: bindActionCreators(PanelTypeActions.getPanelType, dispatch),
        addBloodTargets: bindActionCreators(BloodTargetActions.addBloodTargets, dispatch),
        resetStatusVariables: bindActionCreators(BloodTargetActions.resetStatusVariables, dispatch),
    })
)(AddBloodTargets);
