import * as applicationTypesActions from '../../actions/applicationType.actions';
import { BaseDropdownItem } from '../../../models/responses/baseModel.responce';

export class StateModel {
    allApplicationTypes: BaseDropdownItem[] = [];
    page: number = 0;
    totalApplicationTypes: number | null = null;
    loading: boolean = false;
    searchQuery: string = '';
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: applicationTypesActions.Actions) {
    switch (action.type) {
        case applicationTypesActions.ActionNames.GET_ALL_APPLICATION_TYPES: {
            return {
                ...state,
                loading: true,
            };
        }

        case applicationTypesActions.ActionNames.GET_ALL_APPLICATION_TYPES_UPDATE_SUCCESS: {
            const isEmptyString = state.searchQuery.trim().length === 0;
            const newLabsList = state.allApplicationTypes.concat(action.payload.result);
            const isRedundant = newLabsList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalApplicationTypes: action.payload.total,
                allApplicationTypes: isEmptyString && !isRedundant ? newLabsList : action.payload.result,
            };
        }

        case applicationTypesActions.ActionNames.GET_ALL_APPLICATION_TYPES_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loading: false,
                totalApplicationTypes: action.payload.total,
                allApplicationTypes: action.payload.result,
            };
        }

        case applicationTypesActions.ActionNames.GET_ALL_APPLICATION_TYPES_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case applicationTypesActions.ActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case applicationTypesActions.ActionNames.SET_SEARCH_QUERY: {
            const isEmptyString = action.payload.trim().length === 0;
            return {
                ...state,
                searchQuery: action.payload,
                loading: isEmptyString ? false : state.loading,
                page: 0,
                allApplicationTypes: isEmptyString ? [] : state.allApplicationTypes,
                totalApplicationTypes: 0,
            };
        }

        default:
            return state;
    }
}
