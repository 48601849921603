import React from 'react';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../../shared/SizeControllerWrapper';
import { colors } from '../../../../theme/colors';
import testScheme from '../../../../static/images/landing/main/testScheme.svg';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import CustomLink from '../../../../shared/link/CustomLink';
import { mediaQuery } from '../../../../constants/device';
import mainPageArrow from '../../../../static/images/landing/main/mainPageArrow.svg';

const styles = createStyles({
    heading: {
        fontSize: '20px',
        lineHeight: '36px',
        color: colors.textPrimary,
    },
    scheme: {
        marginTop: '25px',
    },
    link: {
        fontSize: '20px',
        lineHeight: '36px',
        color: colors.textPrimary,
        marginRight: '8px',
        textDecoration: 'underline',
        textDecorationColor: colors.textPrimary,
    },
});

const BackgroundWrapper = styled.div<any>`
    height: 350px;
    background-color: ${colors.gray1};

    ${mediaQuery.laptop} {
        height: 220px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 41px 0 30px;

    ${mediaQuery.laptop} {
        flex-direction: row;
        justify-content: space-between;
        padding: 23px 0 23px;
    }
`;

const ImageWrapper = styled.div`
    height: 145px;
    width: 107%;
    background-size: contain;
    background-position: center;
    background-image: url(${testScheme});
    background-repeat: no-repeat;
    margin-top: 28px;
    ${mediaQuery.laptop} {
        width: 400px;
    }
`;

const TextWrapper = styled.div`
    ${mediaQuery.laptop} {
        text-align: left;
    }
`;

const Arrow = styled.div`
    display: none;
    ${mediaQuery.laptop} {
        display: block;
    }
`;

const NewLine = styled.div`
    display: block;
    height: 0;
    ${mediaQuery.laptop} {
        display: none;
    }
`;

type PropsTypes = WithStyles<typeof styles>;

const RequestConsultation: React.FC<PropsTypes> = props => {
    const { classes } = props;
    return (
        <BackgroundWrapper name={'requestConsultationBlock'}>
            <SizeControllerWrapper>
                <Content>
                    <TextWrapper>
                        <Typography variant="button" classes={{ root: classes.heading }}>
                            <CustomLink to={'/sign-up'} onClick={() => window.scrollTo(0,0)}>
                                <Typography variant="button" classes={{ root: classes.link }}>
                                    Request a consultation
                                </Typography>
                                <NewLine>
                                    <br />
                                </NewLine>
                            </CustomLink>
                            with one of our <br />
                            partner medical facilities
                        </Typography>
                    </TextWrapper>
                    <Arrow>
                        <img src={mainPageArrow} alt="" />
                    </Arrow>
                    <ImageWrapper />
                </Content>
            </SizeControllerWrapper>
        </BackgroundWrapper>
    );
};

export default withStyles(styles)(RequestConsultation);
