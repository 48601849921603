import React, { useCallback, useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import ProfilePatient from '../pages/profile/ProfilePatient';
import ProfileProvider from '../pages/profile/ProfileProvider';
import SelectAvatar from '../pages/profile/SelectAvatar';
import Search from '../pages/patient/Search';
import ProviderRequestLink from '../pages/patient/ProviderRequestLink';
import ProviderRequestLinkSent from '../pages/patient/ProviderRequestLinkSent';
import { RoleOptions } from '../constants/roleOptions';
import Welcome from '../pages/patient/Welcome';
import InvitePatient from '../pages/patient/InvitePatient';
import { UserModel } from '../models/responses/user.response';
import ProviderMyLinks from '../pages/provider/ProviderMyLinks';
import ProviderLinking from '../pages/patient/ProviderLinking';
import BloodWork from '../pages/bloodwork/BloodWork';
import AddBloodwork from '../pages/bloodwork/AddBloodwork';
import Notifications from '../pages/notifications/Notifications';
import Start from '../pages/patient/Start';
import BloodworkList from '../pages/bloodwork/BloodworkList';
import PatientPage from '../pages/provider/PatientPage';
import ProviderStatus from '../pages/patient/ProviderStatus';
import PatientSentRequestPage from '../pages/provider/PatientSentRequestPage';
import PatientInvitedPage from '../pages/provider/PatientInvitedPage';
import DosagePage from '../pages/prescription/DosagePage';
import PrescriptionPage from '../pages/prescription/PrescriptionPage';
import AddPrescriptionPage from '../pages/prescription/AddPrescriptionPage';
import AnalyticsPage from '../pages/analytics/AnalyticsPage';
import AboutPage from '../pages/landing/about/AboutPage';
import ProviderPage from '../pages/landing/provider/ProviderPage';
import Solutions from '../pages/landing/solutions/Solutions';
import Resources from '../pages/landing/resources/Resources';
import PateintPage from '../pages/landing/patient/PateintPage';
import Header from '../shared/header/Header';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../constants/device';
import SidebarMenu from '../shared/sidebar/SidebarMenu';
import SizeControllerWrapperDesktopOnly from '../shared/SizeControllerWrapperDesktopOnly';
import { colors } from '../theme/colors';
import PaymentPatient from '../pages/payment/PaymentPatient';
import PaymentProvider from '../pages/payment/PaymentProvider';
import AddPayment from '../pages/payment/merchant-management/AddPayment';
import CreateMerchant from '../pages/payment/merchant-management/CreateMerchant';
import CreatedMerchant from '../pages/payment/merchant-management/CreatedMerchant';
import PaymentResult from '../pages/payment/PaymentResult';
import SetupStripeMerchant from '../pages/payment/merchant-management/SetupStripeMerchant';
import InvoiceView from '../pages/payment/InvoiceView';
import LabsList from '../pages/admin/LabsList';
import LabInfoPage from '../pages/lab/LabInfoPage';
import AddLabs from '../pages/lab/AddLabs';
import ProvidersList from '../pages/admin/ProvidersList';
import AddProviders from '../pages/provider/admin/AddProviders';
import EditLab from '../pages/lab/EditLab';
import ProviderInfo from '../pages/provider/admin/ProviderInfo';
import ProfileInfoAdmin from '../pages/profile/ProfileAdmin';
import BloodTargets from '../pages/admin/BloodTargets';
import AddBloodTargets from '../pages/bloodTarget/AddBloodTargets';
import BloodTargetInfoPage from '../pages/bloodTarget/BloodTargetInfoPage';
import AdminStatistics from '../pages/admin/AdminStatistics';
import PanelTypes from '../pages/admin/PanelTypes';
import AddPanelTypes from '../pages/panelType/AddPanelTypes';
import { WeightManagementRoutes } from '../pages/weightManagement/enums/routes';
import ChangePassword from '../pages/changePassword/ChangePassword';
import Settings from '../pages/settings/Settings';
import { WeightRecordsList } from '../pages/weightManagement/components/weightRecordsList';
import { CreateOrEditWeightRecord } from '../pages/weightManagement/components/createOrEditWeightRecord';
import TourRoot from '../pages/tour/TourRoot';
import MockProviderForTutorial from '../pages/tour/components/MockProviderForTutorial';
import MockProviderStatusForTutorial from '../pages/tour/components/MockProviderStatusForTutorial';
import ProfileInfoPatientView from '../pages/profile/components/ProfileInfoPatientView';

interface Props {
    user: UserModel;
    sidebarOpen: boolean;
    toggleSidebar: () => void;
}

interface AuthorizedRoute {
    exact: boolean;
    path: string;
    component: any;
}

type PropsTypes = RouteComponentProps<any> & Props;

const landingRoutes: AuthorizedRoute[] = [
    { exact: true, path: '/about', component: AboutPage },
    { exact: true, path: '/provider', component: ProviderPage },
    { exact: true, path: '/patient', component: PateintPage },
    { exact: true, path: '/solutions', component: Solutions },
    { exact: true, path: '/resources', component: Resources },
];

const commonAuthorizedRoutes: AuthorizedRoute[] = [{ exact: true, path: '/notifications', component: Notifications }];

const patientRoutes: AuthorizedRoute[] = [
    ...commonAuthorizedRoutes,
    ...landingRoutes,
    { exact: true, path: '/profile/:edit?', component: ProfilePatient },
    { exact: true, path: '/patient-start', component: Start },
    { exact: true, path: '/welcome', component: Welcome },
    { exact: true, path: '/select-avatar', component: SelectAvatar },
    { exact: true, path: '/search/:tab?', component: Search },
    { exact: false, path: '/provider/:id', component: ProviderRequestLink },
    { exact: false, path: '/provider-link-sent/:id', component: ProviderRequestLinkSent },
    { exact: true, path: '/bloodwork-reports', component: BloodworkList },
    { exact: true, path: '/bloodwork/:id', component: BloodWork },
    { exact: true, path: '/bloodwork-edit/:id', component: AddBloodwork },
    { exact: true, path: '/upload-results', component: AddBloodwork },
    { exact: true, path: '/provider-linking', component: ProviderLinking },
    { exact: true, path: '/provider-status', component: ProviderStatus },
    { exact: true, path: '/dosage/:tab?', component: DosagePage },
    { exact: true, path: '/prescription/:id', component: PrescriptionPage },
    { exact: true, path: '/add-prescription', component: AddPrescriptionPage },
    { exact: true, path: '/analytics', component: AnalyticsPage },
    { exact: true, path: '/payments/:tab?', component: PaymentPatient },
    { exact: true, path: '/payment-result', component: PaymentResult },
    { exact: true, path: '/payments/view/:id', component: InvoiceView },
    { exact: true, path: '/bloodtargets', component: BloodTargets },
    { exact: true, path: '/bloodtarget/:id', component: BloodTargetInfoPage },
    { exact: true, path: '/change-password', component: ChangePassword },
    { exact: true, path: '/lab/:id', component: LabInfoPage },
    { exact: true, path: '/settings', component: Settings },

    {
        exact: true,
        path: `${WeightManagementRoutes.BASE_ROUTE}${WeightManagementRoutes.LIST}`,
        component: WeightRecordsList,
    },
    {
        exact: true,
        path: `${WeightManagementRoutes.BASE_ROUTE}${WeightManagementRoutes.EDIT}`,
        component: CreateOrEditWeightRecord,
    },
    {
        exact: true,
        path: `${WeightManagementRoutes.BASE_ROUTE}${WeightManagementRoutes.CREATE}`,
        component: CreateOrEditWeightRecord,
    },

    { exact: true, path: '/send-request-to-provider', component: MockProviderForTutorial },
    { exact: true, path: '/see-provider-status', component: MockProviderStatusForTutorial },
    { exact: true, path: '/edit-your-profile', component: ProfileInfoPatientView },
];

const providerRoutes: AuthorizedRoute[] = [
    ...commonAuthorizedRoutes,
    ...landingRoutes,
    { exact: true, path: '/profile/:edit?', component: ProfileProvider },
    { exact: true, path: '/select-avatar', component: SelectAvatar },
    { exact: true, path: '/provider/invite-patient', component: InvitePatient },
    { exact: true, path: '/provider/:tab?', component: ProviderMyLinks },
    { exact: true, path: '/search/:tab?', component: Search },
    { exact: true, path: '/profile-patient/:id', component: PatientPage },
    { exact: true, path: '/patient-request/:id', component: PatientSentRequestPage },
    { exact: true, path: '/patient-invited/:id', component: PatientInvitedPage },
    { exact: true, path: '/provider/patient-status/:patientId', component: () => <div>Nothing here yet</div> },
    { exact: true, path: '/upload-results/:patientId', component: AddBloodwork },
    { exact: true, path: '/bloodwork/:id/:patientId', component: BloodWork },
    { exact: true, path: '/prescription/:prescriptionId/:patientId', component: PrescriptionPage },
    { exact: true, path: '/add-prescription/:patientId', component: AddPrescriptionPage },
    { exact: true, path: '/payments/:tab?', component: PaymentProvider },
    { exact: true, path: '/add-payment/:id?/:patientId?', component: AddPayment },
    { exact: true, path: '/create-merchant', component: CreateMerchant },
    { exact: true, path: '/created-merchant', component: CreatedMerchant },
    { exact: true, path: '/setup-merchant', component: SetupStripeMerchant },
    { exact: true, path: '/payments/view/:id/:patientId', component: InvoiceView },
    { exact: true, path: '/change-password', component: ChangePassword },
    { exact: true, path: '/lab/:id', component: LabInfoPage },
];

const adminRoutes: AuthorizedRoute[] = [
    ...landingRoutes,
    { exact: true, path: '/admin/dashboard', component: AdminStatistics },
    { exact: true, path: '/admin/labs', component: LabsList },
    { exact: true, path: '/admin/lab/:id', component: LabInfoPage },
    { exact: true, path: '/admin/lab-edit/:id', component: EditLab },
    { exact: true, path: '/admin/labs/add-lab', component: AddLabs },
    { exact: true, path: '/admin/providers', component: ProvidersList },
    { exact: true, path: '/admin/provider/:id', component: ProviderInfo },
    { exact: true, path: '/admin/provider-edit/:id', component: ProviderInfo },
    { exact: true, path: '/admin/providers/add-provider', component: AddProviders },
    { exact: true, path: '/admin/profile', component: ProfileInfoAdmin },
    { exact: true, path: '/admin/bloodtargets', component: BloodTargets },
    { exact: true, path: '/admin/bloodtargets/add-bloodtargets', component: AddBloodTargets },
    { exact: true, path: '/admin/bloodtarget/:id', component: BloodTargetInfoPage },
    { exact: true, path: '/admin/bloodtarget-edit/:id', component: BloodTargetInfoPage },
    { exact: true, path: '/admin/panel-types/add-panel-types', component: AddPanelTypes },
    { exact: true, path: '/admin/panel-types/:id?', component: PanelTypes },
    { exact: true, path: '/change-password', component: ChangePassword },
];

const LayoutWrapper = styled.div`
    ${mediaQuery.laptop} {
        display: flex;
    }
`;

const PageWrapper = styled.div<{ isLanding: boolean }>`
    ${mediaQuery.laptop} {
        width: 100%;
        border: ${props => (props.isLanding ? 'none' : `1px solid ${colors.grayUnderline}`)};
        border-radius: ${props => (props.isLanding ? 0 : '30px')};
        margin: ${props => (props.isLanding ? 0 : '30px 0 30px 30px')};
        overflow: ${props => (props.isLanding ? 'inherit' : 'hidden')};
        position: ${props => (props.isLanding ? 'inherit' : 'relative')};
    }
`;

const AuthorizedRoutes = (props: PropsTypes) => {
    const isLaptop = useMediaQuery(mediaQuery.laptop);
    const { sidebarOpen, toggleSidebar } = props;

    const isLanding = useMemo(() => {
        return landingRoutes.some(route => route.path === props.history.location.pathname);
    }, [props.history.location.pathname]);

    const shouldShowDesktopContentWrapper = useMemo(() => {
        const location = props.history.location.pathname;
        return isLanding || location.includes('/profile');
    }, [props.history.location.pathname, isLanding]);

    const showSidebar = useMemo(() => {
        if (isLaptop) {
            return !isLanding;
        } else {
            return sidebarOpen;
        }
    }, [isLanding, isLaptop, sidebarOpen]);

    const renderRoutes = useCallback(() => {
        switch (props.user.role) {
            case RoleOptions.PATIENT: {
                return (
                    <Switch>
                        {patientRoutes.map(route => (
                            <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
                        ))}
                        <Route render={() => <Redirect to={props.user.setup ? '/patient-start' : '/welcome'} />} />
                    </Switch>
                );
            }
            case RoleOptions.PROVIDER: {
                return (
                    <Switch>
                        {providerRoutes.map(route => (
                            <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
                        ))}
                        <Route render={() => <Redirect to="/provider/my-links" />} />
                    </Switch>
                );
            }
            case RoleOptions.ADMIN: {
                return (
                    <Switch>
                        {adminRoutes.map(route => (
                            <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
                        ))}
                        <Route render={() => <Redirect to="/admin/dashboard" />} />
                    </Switch>
                );
            }
            default:
                return;
        }
    }, [props.user]);

    return (
        <>
            <Header />
            <SizeControllerWrapperDesktopOnly isLanding={isLanding}>
                <LayoutWrapper>
                    {showSidebar && <SidebarMenu open={sidebarOpen || isLaptop} closeSidebar={toggleSidebar} />}
                    <PageWrapper isLanding={shouldShowDesktopContentWrapper}>{renderRoutes()}</PageWrapper>
                </LayoutWrapper>
            </SizeControllerWrapperDesktopOnly>
            <TourRoot user={props.user} />
        </>
    );
};

export default withRouter(AuthorizedRoutes);
