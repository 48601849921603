import {restAxios} from "../restAxios";
import {AllEntitiesByPagesDto} from "../../models/dto/allEntitiesByPages.dto";
import {ProviderDto} from "../../models/dto/provider.dto";
import {UploadAvatarDto} from "../../models/dto/uploadAvatar.dto";
import HandleAsyncErrors from "../../helpers/decorators/handleAsyncErrors";
import {SaveProviderDto} from "../../models/dto/saveProvider.dto";

class ProviderApi {
    @HandleAsyncErrors()
    static async getAllProviders(data: AllEntitiesByPagesDto) {
        return await restAxios.get(`private/providers/all?page=${data.page}&size=${data.size}&sort=${data.sort && encodeURIComponent(data.sort)}&searchQuery=${data.searchQuery}`);
    }

    @HandleAsyncErrors()
    static async getProviderById(id: number) {
        return await restAxios.get(`private/providers/${id}`);
    }

    @HandleAsyncErrors()
    static async getProvider() {
        return await restAxios.get('private/providers');
    }

    @HandleAsyncErrors()
    static async updateProvider(data: ProviderDto) {
        return await restAxios.patch('private/providers', data);
    }

    @HandleAsyncErrors()
    static async updateProviders(data: ProviderDto[]) {
        return await restAxios.put('private/providers', data);
    }

    @HandleAsyncErrors()
    static async requestProvider(id: number) {
        return await restAxios.post(`private/providers/${id}/request`);
    }

    @HandleAsyncErrors()
    static async cancelRequestProvider() {
        return await restAxios.post(`private/providers/cancel-request`);
    }

    @HandleAsyncErrors()
    static async uploadAvatar(data: UploadAvatarDto) {
        const formData = new FormData();
        formData.append('file', data.file);
        return await restAxios.patch(`private/providers/upload-avatar?height=${data.height}&width=${data.width}&x=${data.x}&y=${data.y}`, formData);
    }

    @HandleAsyncErrors()
    static async saveProviders(data: SaveProviderDto[]) {
        return await restAxios.post('private/providers', data);
    }

    @HandleAsyncErrors()
    static async getNewPasswordProvider(id: number) {
        return await restAxios.post(`private/providers/${id}/new-password`);
    }
}

export default ProviderApi;
