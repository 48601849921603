import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../../constants/device';
import authBackground from '../../../static/images/authBackground.png';
import { colors } from '../../../theme/colors';
import theme from '../../../theme/theme';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';

const BackgroundWrapper = styled.div`
    ${mediaQuery.laptop} {
        background-image: url(${authBackground});
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: -80px;
        padding-top: 90px;
        height: calc(100vh - 100px);
    }
`;

const Wrapper = styled.div`
    ${mediaQuery.laptop} {
        width: 60%;
        display: flex;
        justify-content: center;
        padding: 90px 0 40px;
        margin: 100px auto 30px;
        background-color: ${colors.white};
        box-shadow: ${theme.authCardBoxShadow};
    }
`;

const FullScreenAuthFormWrapper: React.FC = props => {
    return (
        <BackgroundWrapper>
            <SizeControllerWrapper minHeight>
                <Wrapper>{props.children}</Wrapper>
            </SizeControllerWrapper>
        </BackgroundWrapper>
    );
};

export default FullScreenAuthFormWrapper;
