import React from 'react';
import SizeControllerWrapper from '../SizeControllerWrapper';
import { createStyles, List, Typography, withStyles, WithStyles } from '@material-ui/core';
import Loading from '../../pages/shared/Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlphabetLetter from '../../pages/patient/components/AlphabetLetter';
import { colors } from '../../theme/colors';
import ScrollableListItem from './ScrollableListItem';
import styled from 'styled-components';
import {ifStringEquals} from "../../helpers/stringFilterHelper";

const styles = createStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '16px',
        color: colors.textSecondary,
        padding: '20px 0',
    },
});

const ListItemWrapper = styled.div<any>`
    margin: ${props => (props.extraMargin ? '10px 0' : 0)};
`;

interface Props extends WithStyles<typeof styles> {
    isLoading: boolean;
    totalRecordsNumber: number;
    data: any[];
    height: number;
    type: 'provider' | 'patient';
    hideAlphabetLetters?: boolean;
    showStatusIcons?: boolean;

    nextAction(): void;
}

const ScrollableList: React.FC<Props> = props => {
    let latestLetter: string | null = null;

    return (
        <>
            {!props.isLoading && props.data.length === 0 && (
                <SizeControllerWrapper>
                    <Typography variant="body1" classes={{ root: props.classes.noData }}>
                        No data found.
                    </Typography>
                </SizeControllerWrapper>
            )}

            {props.data.length > 0 && (
                <List classes={{ root: props.classes.list }}>
                    <InfiniteScroll
                        dataLength={props.data.length}
                        next={props.nextAction}
                        height={props.height}
                        hasMore={props.totalRecordsNumber ? props.data.length < props.totalRecordsNumber : false}
                        loader={<Loading smallSize />}
                        className={props.classes.scrollContainer}
                    >
                        {props.data.map((record, index) => {
                            let should = false;
                            if (!latestLetter || !ifStringEquals(latestLetter, record.lastName[0])) {
                                should = true;
                                latestLetter = record.lastName[0];
                            }
                            return (
                                <ListItemWrapper key={index} extraMargin={props.hideAlphabetLetters}>
                                    {should && !props.hideAlphabetLetters && (
                                        <AlphabetLetter>{record.lastName[0]}</AlphabetLetter>
                                    )}
                                    <ScrollableListItem
                                        entity={record}
                                        key={index}
                                        type={props.type}
                                        showStatusIcons={props.showStatusIcons}
                                    />
                                </ListItemWrapper>
                            );
                        })}
                    </InfiniteScroll>
                </List>
            )}
        </>
    );
};

export default withStyles(styles)(ScrollableList);
