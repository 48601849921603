import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import TextInput from '../../shared/input/TextInput';
import styled from 'styled-components';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { colors } from '../../theme/colors';
import CustomLink from '../../shared/link/CustomLink';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { Actions as AuthActions } from '../../store/actions/auth.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import RequestState from '../../constants/requestState';
import LoadingTransparent from '../shared/LoadingTransparent';
import { ValidationErrorsMap } from '../../models/validation/validationErrorsMap';
import fillClassInstanceWithValues from '../../shared/utils/fillClassInstanceWithValues';
import { validateSync } from 'class-validator';
import { SignInDto } from '../../models/validation/signInValidation';
import { FormControl, FormHelperText } from '@material-ui/core';
import { useDebounce } from 'use-debounce';
import Loading from '../shared/Loading';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import Footer from '../../shared/Footer';
import FullScreenAuthFormWrapper from './components/FullScreenAuthFormWrapper';

const createStyles = makeStyles(theme => ({
    heading: {
        fontSize: '36px',
        margin: '0 auto',
        width: 'fit-content',
        marginBottom: '25px',
    },
    registerLinkText: {
        color: colors.redMain,
        fontSize: '14px',
        marginRight: '5px',
        '&:hover': {
            color: colors.redDark,
        },
    },
    registerText: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    form: {
        margin: '80px auto 37px',
        [theme.breakpoints.up('md')]: {
            width: '60%',
            margin: '80px auto 0',
        },
        [theme.breakpoints.up('lg')]: {
            width: '350px',
            margin: '0 auto',
        },
    },
}));

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0 0;
`;

const CenterWrapper = styled.div`
    height: 200px;
    margin: 20px 0 37px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InputContainer = styled.div`
    & div:last-child {
        margin-bottom: 0 !important;
    }
`;

const ErrorWrapper = styled.div`
    position: absolute;
`;

const InputWrapper = styled(FormControl)`
    padding-bottom: 25px !important;
`;

type StateModel = {
    loading: boolean;
    signedIn: RequestState;
};

type DispatchModel = {
    signIn: typeof AuthActions.signIn;
};

type PropsTypes = StateModel & DispatchModel & RouteComponentProps<any>;

const userInitialState = {
    email: '',
    password: '',
    isBeingEdited: new Set(),
};

const SignIn: React.FC<PropsTypes> = props => {
    const [user, setUser] = useState(userInitialState);
    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    useEffect(() => {
        validateForm(user);
    }, [user]);

    useEffect(() => {
        return () => {
            setValidationErrorsMap({});
            userInitialState.isBeingEdited = new Set();
        };
    }, []);

    const validateForm = (user: any, isOnSubmit = false) => {
        const item = fillClassInstanceWithValues<SignInDto>(SignInDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                    map[err.property] = Object.entries(err.constraints)
                        .map(([, value]) => value)
                        .join(', ');
                }
            });
            setValidationErrorsMap(map);
        }

        setValidationErrorsMap(map);
        return map;
    };

    const isValid = () => {
        const map = validateForm(user, true);
        return Object.entries(map).length === 0;
    };

    const changeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUser = {
            ...user,
            [e.target.id]: e.target.value,
            isBeingEdited: user.isBeingEdited.add(e.target.id),
        };
        if (e.target.value.length === 0) {
            newUser.isBeingEdited.delete(e.target.id);
        }
        setUser(newUser);
    };

    const sendForm = () => {
        if (isValid()) {
            props.signIn({
                email: user.email,
                password: user.password,
            });
        }
    };

    const handleEnter = (event: any) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const formArray = [...event.target.form];
            const index = formArray.indexOf(event.target);
            //check if next element is button
            if (index === formArray.length - 2) {
                sendForm();
            } else {
                form.elements[index + 1].focus();
            }
            event.preventDefault();
        }
    };

    const renderError = (field: keyof ValidationErrorsMap) => {
        if (!!validationErrorsMap[field]) {
            return (
                <ErrorWrapper>
                    <FormHelperText id="component-error-text">
                        {validationErrorsMap[field].split(',')[0]}
                    </FormHelperText>
                </ErrorWrapper>
            );
        }
    };

    const isLaptop = useMediaQuery(mediaQuery.laptop);

    const classes = createStyles(props);
    return (
        <>
            {props.signedIn === RequestState.SENDING && <LoadingTransparent />}
            {props.signedIn === RequestState.SENT_SUCCESS && <Loading />}

            <FullScreenAuthFormWrapper>
                <form className={classes.form}>
                    <Typography variant="h2" classes={{ root: classes.heading }}>
                        Sign In
                    </Typography>
                    <InputContainer>
                        <InputWrapper error={!!validation.email}>
                            <TextInput
                                placeholder={'Email'}
                                value={user.email}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    //@ts-ignore
                                    changeUser({ target: { id: 'email', value: evt.target.value.trim() } });
                                }}
                                inputProps={{ id: 'email' }}
                                onKeyDown={(evt: any) => handleEnter(evt)}
                                error={!!validation.email}
                            />
                            {!!validation.email && renderError('email')}
                        </InputWrapper>

                        <InputWrapper error={!!validation.password}>
                            <TextInput
                                placeholder={'Password'}
                                value={user.password}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    changeUser(evt);
                                }}
                                inputProps={{ id: 'password' }}
                                onKeyDown={(evt: any) => handleEnter(evt)}
                                error={!!validation.password}
                                password
                            />
                            {!!validation.password && renderError('password')}
                        </InputWrapper>
                    </InputContainer>

                    <CenterWrapper>
                        <CustomLink to={'/recover-password'} underlineColor={colors.textPrimary}>
                            <Typography variant="body1">Forgot Password?</Typography>
                        </CustomLink>
                        <ButtonsWrapper>
                            <PrimaryButton size="large" onClick={sendForm}>
                                Sign In
                            </PrimaryButton>
                        </ButtonsWrapper>
                        <LinkWrapper>
                            <CustomLink to={'/sign-up'} underlineColor={colors.redMain}>
                                <Typography variant="button" classes={{ root: classes.registerLinkText }}>
                                    Sign Up
                                </Typography>
                            </CustomLink>
                            <Typography variant="body2" classes={{ root: classes.registerText }}>
                                for UpMed
                            </Typography>
                        </LinkWrapper>
                    </CenterWrapper>
                </form>
            </FullScreenAuthFormWrapper>

            {props.loading && <LoadingTransparent />}

            {isLaptop && <Footer />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StateModel => ({
        loading: state.authorization.loading,
        signedIn: state.authorization.signedIn,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        signIn: bindActionCreators(AuthActions.signIn, dispatch),
    })
)(withRouter(SignIn));
