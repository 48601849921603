import {all, fork} from 'redux-saga/effects';
import AuthorizationSagas from './auth/index';
import PatientSagas from './patient/index';
import ProviderSagas from './provider/index';
import LabSagas from './lab/index';
import LabworkSagas from './labwork/index';
import NotificationsSagas from './notifications';
import PrescriptionSagas from './prescription';
import AnalyticsSagas from './analytics';
import InvoiceSagas from './invoice';
import BloodTargetSagas from './bloodTarget';
import PanelTypeSagas from './panelType';
import AdminStatisticsSagas from './adminStatistics';

export default function* rootSaga() {
    yield all([
        fork(AuthorizationSagas),
        fork(PatientSagas),
        fork(ProviderSagas),
        fork(LabSagas),
        fork(LabworkSagas),
        fork(NotificationsSagas),
        fork(PrescriptionSagas),
        fork(AnalyticsSagas),
        fork(InvoiceSagas),
        fork(BloodTargetSagas),
        fork(PanelTypeSagas),
        fork(AdminStatisticsSagas),
    ]);
}