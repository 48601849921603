import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { colors } from '../../theme/colors';
import PrescriptionForm from './components/PrescriptionForm';
import { connect } from 'react-redux';
import * as prescriptionActions from '../../store/actions/prescription.actions';
import * as fromRoot from '../../store/reducers/index';
import { bindActionCreators, Dispatch } from 'redux';
import { CreatePrescriptionComponentDto } from '../../models/dto/createPrescriptionComponent.dto';
import PrescriptionInfoBlock from './components/PrescriptionInfoBlock';
import add from '../../static/icons/addPrescriptionComponet.svg';
import PrimaryButton from '../../shared/button/PrimaryButton';
import { RouteComponentProps, withRouter } from 'react-router';
import RequestState from '../../constants/requestState';
import LoadingTransparent from '../shared/LoadingTransparent';
import { Prescription } from '../../store/reducers/prescription';
import Loading from '../shared/Loading';
import forwardIcon from '../../static/icons/forwardIcon.svg';
import RemovePrescriptionDialog from './components/RemovePrescriptionDialog';
import { UserModel } from '../../models/responses/user.response';
import { RoleOptions } from '../../constants/roleOptions';
import { PrescriptionComponentModel } from '../../models/responses/prescriptionComponentModel.responce';
import { DosageRouting } from '../../constants/tabRouting/routing';
import TextInput from '../../shared/input/TextInput';
import { formatDate } from '../../helpers/dateFormatHelper';
import CancelLink from '../../shared/link/CancelLink';
import deleteIcon from '../../static/icons/binIconRound.svg';

const Wrapper = styled.div`
    min-height: calc(100vh - 177px);
    padding: 48px 0 37px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const HeaderWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const ControlsBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

const TopWrapper = styled.div`
    width: 100%;
`;

const InputWrapper = styled.div`
    width: 100%;
`;

const createStyles = makeStyles({
    header: {
        fontSize: '24px',
        color: colors.textPrimary,
    },
    date: {
        fontSize: '14px',
        color: colors.grayUnderline,
        margin: '10px 0 20px',
        display: 'block',
    },
    addIcon: {
        margin: '22px 0',
        cursor: 'pointer',
    },
    newDrug: {
        fontSize: '14px',
        color: colors.grayUnderline,
        marginBottom: '-8px',
        marginTop: '34px',
    },
    backIcon: {
        position: 'absolute',
        left: '0',
        top: '10px',
        transform: 'rotate(180deg)',
        cursor: 'pointer',
        padding: '12px 14px',
        borderRadius: '30px',
        backgroundColor: 'rgba(128, 128, 128, 0.1)',
    },
    deleteIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: '0',
        top: '2px',
        padding: 0,
    },
    cantUpdateText: {
        fontSize: '11px',
        color: colors.textSecondary,
    },
    dialogText: {
        fontSize: '16px',
        lineHeight: '24px',
    },
});

interface StoreModel {
    prescriptionAddingStatus: RequestState;
    currentPrescription: Prescription | null;
    loading: boolean;
    user: UserModel | null;
}

interface DispatchModel {
    addPrescriptionComponentUpdate: typeof prescriptionActions.Actions.addPrescriptionComponentUpdate;
    removePrescriptionComponentUpdate: typeof prescriptionActions.Actions.removePrescriptionComponentUpdate;
    editPrescriptionComponentUpdate: typeof prescriptionActions.Actions.editPrescriptionComponentUpdate;
    saveEditedPrescriptionComponentUpdate: typeof prescriptionActions.Actions.saveEditedPrescriptionComponentUpdate;
    cancelEditPrescriptionComponentUpdate: typeof prescriptionActions.Actions.cancelEditPrescriptionComponentUpdate;
    updatePrescriptionPatient: typeof prescriptionActions.Actions.updatePrescriptionPatient;
    updatePrescriptionProvider: typeof prescriptionActions.Actions.updatePrescriptionProvider;
    removePrescriptionPatient: typeof prescriptionActions.Actions.removePrescriptionPatient;
    removePrescriptionProvider: typeof prescriptionActions.Actions.removePrescriptionProvider;
    resetStatusVariables: typeof prescriptionActions.Actions.resetStatusVariables;
    getPrescriptionByIdPatient: typeof prescriptionActions.Actions.getPrescriptionByIdPatient;
    getPrescriptionByIdProvider: typeof prescriptionActions.Actions.getPrescriptionByIdProvider;
}

type PropsTypes = RouteComponentProps<any> & StoreModel & DispatchModel;

const PrescriptionPage: React.FC<PropsTypes> = props => {
    const [addFormOpen, setAddFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [name, setName] = useState('');

    const {
        history,
        match,
        loading,
        resetStatusVariables,
        user,
        prescriptionAddingStatus,
        currentPrescription,
        addPrescriptionComponentUpdate,
        cancelEditPrescriptionComponentUpdate,
        editPrescriptionComponentUpdate,
        getPrescriptionByIdPatient,
        getPrescriptionByIdProvider,
        removePrescriptionComponentUpdate,
        removePrescriptionPatient,
        removePrescriptionProvider,
        saveEditedPrescriptionComponentUpdate,
        updatePrescriptionPatient,
        updatePrescriptionProvider,
    } = props;

    useEffect(() => {
        if (currentPrescription) {
            setName(currentPrescription.name);
        }
    }, [currentPrescription]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
        };
    }, [resetStatusVariables]);

    useEffect(() => {
        if (prescriptionAddingStatus !== RequestState.UNSENT && prescriptionAddingStatus !== RequestState.SENDING) {
            prescriptionAddingStatus === RequestState.SENT_SUCCESS &&
                user &&
                user.role === RoleOptions.PATIENT &&
                history.push(DosageRouting[0].path);
            prescriptionAddingStatus === RequestState.SENT_SUCCESS &&
                user &&
                user.role === RoleOptions.PROVIDER &&
                history.push(`/profile-patient/${match.params.patientId}`);
        }
    }, [prescriptionAddingStatus, history, match.params.patientId, user]);

    useEffect(() => {
        if (user) {
            if (user.role === RoleOptions.PATIENT) {
                getPrescriptionByIdPatient(match.params.id);
            } else {
                getPrescriptionByIdProvider({
                    prescriptionId: match.params.prescriptionId,
                    patientId: match.params.patientId,
                });
            }
        }
    }, [getPrescriptionByIdPatient, user, getPrescriptionByIdProvider, match]);

    const getPrescriptionDisplayModel = (prescription: PrescriptionComponentModel) => {
        return {
            dosage: `${prescription.dosage.toString()} ${prescription.dosageUnit}`,
            drugApplicationType: prescription.drugApplicationType!.name.toString(),
            drug: prescription.drug!.name.toString(),
            date: `${formatDate(prescription.startDate)} ${prescription.endDate ? `-` : ''} ${
                prescription.endDate ? formatDate(prescription.endDate) : ''
            }`,
            frequency: `${prescription.frequencyValue.toString()} ${prescription.frequencyType}`,
            potency: `${prescription.potency.toString()} ${prescription.potencyUnit}`,
            private: prescription.private,
            quantity: `${prescription.quantity.toString()} ${prescription.quantityUnit}`,
            therapies: prescription.therapyList.map(t => t.name).join(', '),
            id: prescription.id,
        };
    };

    const onCancelClick = (id?: number) => {
        if (currentPrescription && currentPrescription.prescriptionComponentList.length > 0) {
            editFormOpen && cancelEditComponent(id);
            setAddFormOpen(false);
        } else {
            history.push(
                user && user.role === RoleOptions.PATIENT
                    ? DosageRouting[0].path
                    : `/profile-patient/${match.params.patientId}`
            );
        }
    };

    const editComponent = (id: number) => {
        setEditFormOpen(true);
        editPrescriptionComponentUpdate(id);
    };

    const cancelEditComponent = (id: number | undefined) => {
        setEditFormOpen(false);
        id && cancelEditPrescriptionComponentUpdate(id);
    };

    const saveEditedComponent = (newPrescriptionComponent: PrescriptionComponentModel) => {
        saveEditedPrescriptionComponentUpdate(newPrescriptionComponent);
        setEditFormOpen(false);
    };
    const savePrescription = () => {
        if (currentPrescription && currentPrescription.prescriptionComponentList.length > 0) {
            const newPrescription: CreatePrescriptionComponentDto[] = currentPrescription.prescriptionComponentList.map(
                p => {
                    return {
                        id: p.id,
                        drug: p.drug,
                        drugId: p.drug!.id,
                        quantity: p.quantity,
                        quantityUnit: p.quantityUnit,
                        potency: p.potency,
                        potencyUnit: p.potencyUnit,
                        private: p.private,
                        frequencyValue: p.frequencyValue,
                        frequencyType: p.frequencyType,
                        dosage: p.dosage,
                        dosageUnit: p.dosageUnit,
                        drugApplicationTypeId: p.drugApplicationType!.id,
                        therapies: p.therapyList.map(t => {
                            return t.id;
                        }),
                        startDate: new Date(p.startDate).toISOString(),
                        endDate: p.endDate ? new Date(p.endDate).toISOString() : null,
                    };
                }
            );

            if (user && user.role === RoleOptions.PATIENT) {
                updatePrescriptionPatient({
                    id: currentPrescription.id,
                    name: name,
                    pharmacyId: 1,
                    prescriptionComponentList: newPrescription,
                });
            }
            if (user && user.role === RoleOptions.PROVIDER) {
                updatePrescriptionProvider({
                    data: {
                        id: currentPrescription.id,
                        name: name,
                        pharmacyId: 1,
                        prescriptionComponentList: newPrescription,
                    },
                    patientId: match.params.patientId,
                });
            }
        }
    };

    const getId = () => {
        const prescriptionComponentList = currentPrescription && currentPrescription.prescriptionComponentList;
        if (prescriptionComponentList) {
            if (prescriptionComponentList.length === 0) {
                return 1;
            }
            const lastIndex = prescriptionComponentList[prescriptionComponentList.length - 1].id;
            return lastIndex + 1;
        }
        return -1;
    };

    const removeComponent = (id: number) => {
        removePrescriptionComponentUpdate(id);
    };

    const removePrescription = () => {
        setOpenDialog(false);
        if (currentPrescription) {
            user && user.role === RoleOptions.PATIENT
                ? removePrescriptionPatient(currentPrescription.id)
                : removePrescriptionProvider({
                      prescriptionId: currentPrescription.id,
                      patientId: match.params.patientId,
                  });
        }
    };

    const ableToEdit = useMemo(() => {
        return currentPrescription && user && currentPrescription.createdBy === user.id;
    }, [currentPrescription, user]);

    const prescriptionComponentList = currentPrescription && currentPrescription.prescriptionComponentList;

    const classes = createStyles(props);
    return (
        <>
            {prescriptionAddingStatus === RequestState.SENDING && <LoadingTransparent />}
            <SizeControllerWrapper>
                <Wrapper>
                    <TopWrapper>
                        <HeaderWrapper>
                            <img
                                src={forwardIcon}
                                alt=""
                                onClick={() => history.goBack()}
                                className={classes.backIcon}
                            />
                            <Typography variant="h2" classes={{ root: classes.header }}>
                                Prescription
                            </Typography>

                            {ableToEdit && (
                                <IconButton onClick={() => setOpenDialog(true)} className={classes.deleteIcon}>
                                    <img src={deleteIcon} alt="" />
                                </IconButton>
                            )}
                        </HeaderWrapper>

                        {currentPrescription && (
                            <>
                                <Typography variant="button" classes={{ root: classes.date }}>
                                    {formatDate(currentPrescription.startDate)}
                                    {!!currentPrescription.endDate && <> - {formatDate(currentPrescription.endDate)}</>}
                                </Typography>
                            </>
                        )}
                    </TopWrapper>

                    <InputWrapper>
                        <TextInput
                            placeholder={'Prescription Name'}
                            value={name}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                setName(evt.target.value);
                            }}
                        />
                    </InputWrapper>
                    {loading && <Loading smallSize />}
                    <div>
                        {prescriptionComponentList &&
                            user &&
                            prescriptionComponentList.map((prescription, index) => {
                                if (prescription.isBeingEdited) {
                                    return (
                                        <PrescriptionForm
                                            key={index}
                                            addPrescriptionComponent={addPrescriptionComponentUpdate}
                                            onCancelClick={onCancelClick}
                                            getId={getId}
                                            prescription={prescription}
                                            saveEditedComponent={saveEditedComponent}
                                            role={user!.role}
                                            isBeingEdited
                                        />
                                    );
                                } else {
                                    return (
                                        <PrescriptionInfoBlock
                                            key={index}
                                            prescription={getPrescriptionDisplayModel(prescription)}
                                            removeComponent={removeComponent}
                                            editComponent={editComponent}
                                            role={user!.role}
                                            ableToEdit={!!ableToEdit}
                                        />
                                    );
                                }
                            })}

                        {!addFormOpen &&
                            prescriptionComponentList &&
                            prescriptionComponentList.length > 0 &&
                            !editFormOpen &&
                            ableToEdit && (
                                <img
                                    src={add}
                                    alt=""
                                    className={classes.addIcon}
                                    onClick={() => setAddFormOpen(true)}
                                />
                            )}
                    </div>

                    {((prescriptionComponentList && user && prescriptionComponentList.length === 0) || addFormOpen) && (
                        <>
                            {prescriptionComponentList && prescriptionComponentList.length > 0 && (
                                <Typography variant="button" classes={{ root: classes.newDrug }}>
                                    New Drug
                                </Typography>
                            )}
                            <PrescriptionForm
                                addPrescriptionComponent={(data: PrescriptionComponentModel) => {
                                    addPrescriptionComponentUpdate(data);
                                    setAddFormOpen(false);
                                }}
                                getId={getId}
                                onCancelClick={onCancelClick}
                                saveEditedComponent={saveEditedComponent}
                                role={user!.role}
                            />
                        </>
                    )}

                    {!ableToEdit && (
                        <Typography variant={'body1'} classes={{ root: classes.cantUpdateText }}>
                            You can't update this prescription.
                        </Typography>
                    )}

                    {!addFormOpen &&
                        prescriptionComponentList &&
                        prescriptionComponentList.length > 0 &&
                        !editFormOpen && (
                            <ControlsBlock>
                                <PrimaryButton
                                    disabled={!ableToEdit}
                                    onClick={ableToEdit ? () => savePrescription() : null}
                                >
                                    Update Prescription
                                </PrimaryButton>
                                <CancelLink />
                            </ControlsBlock>
                        )}
                </Wrapper>
            </SizeControllerWrapper>

            {openDialog && (
                <RemovePrescriptionDialog
                    open={openDialog}
                    close={() => setOpenDialog(false)}
                    removePrescriptionPatient={removePrescription}
                >
                    <Typography variant="button" classes={{ root: classes.dialogText }}>
                        Are you sure you want <br />
                        to remove this prescription?
                    </Typography>
                </RemovePrescriptionDialog>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        loading: state.prescription.loading,
        currentPrescription: state.prescription.currentPrescription,
        prescriptionAddingStatus: state.prescription.prescriptionAddingStatus,
        user: state.authorization.user,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        addPrescriptionComponentUpdate: bindActionCreators(
            prescriptionActions.Actions.addPrescriptionComponentUpdate,
            dispatch
        ),
        resetStatusVariables: bindActionCreators(prescriptionActions.Actions.resetStatusVariables, dispatch),
        removePrescriptionComponentUpdate: bindActionCreators(
            prescriptionActions.Actions.removePrescriptionComponentUpdate,
            dispatch
        ),
        editPrescriptionComponentUpdate: bindActionCreators(
            prescriptionActions.Actions.editPrescriptionComponentUpdate,
            dispatch
        ),
        saveEditedPrescriptionComponentUpdate: bindActionCreators(
            prescriptionActions.Actions.saveEditedPrescriptionComponentUpdate,
            dispatch
        ),
        cancelEditPrescriptionComponentUpdate: bindActionCreators(
            prescriptionActions.Actions.cancelEditPrescriptionComponentUpdate,
            dispatch
        ),
        updatePrescriptionPatient: bindActionCreators(prescriptionActions.Actions.updatePrescriptionPatient, dispatch),
        updatePrescriptionProvider: bindActionCreators(
            prescriptionActions.Actions.updatePrescriptionProvider,
            dispatch
        ),
        removePrescriptionPatient: bindActionCreators(prescriptionActions.Actions.removePrescriptionPatient, dispatch),
        removePrescriptionProvider: bindActionCreators(
            prescriptionActions.Actions.removePrescriptionProvider,
            dispatch
        ),
        getPrescriptionByIdPatient: bindActionCreators(
            prescriptionActions.Actions.getPrescriptionByIdPatient,
            dispatch
        ),
        getPrescriptionByIdProvider: bindActionCreators(
            prescriptionActions.Actions.getPrescriptionByIdProvider,
            dispatch
        ),
    })
)(withRouter(PrescriptionPage));
