import React, { useEffect } from 'react';
import Loading from '../shared/Loading';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { Dispatch, bindActionCreators } from 'redux';
import { Actions as AuthActions } from '../../store/actions/auth.actions';
import VerificationState from '../../constants/emailVerificationState';

interface StoreModel {
    loading: boolean;
    emailVerificationStatus: VerificationState;
}

interface DispatchModel {
    verifyEmail: typeof AuthActions.verifyEmail;
}

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any>;

const EmailVerificationPage: React.FC<PropsTypes> = props => {
    const { history, location, emailVerificationStatus, verifyEmail } = props;

    useEffect(() => {
        const token = queryString.parse(location.search).verificationToken;
        if (!!token && typeof token === 'string') {
            verifyEmail({ verificationToken: token });
        }
    }, [verifyEmail, location]);

    useEffect(() => {
        if (
            emailVerificationStatus !== VerificationState.CHECKING &&
            emailVerificationStatus !== VerificationState.UNCHECKED &&
            emailVerificationStatus === VerificationState.VERIFIED &&
            history.push('/')
        ) {
        }
    }, [emailVerificationStatus, history]);

    return (
        <>
            {(emailVerificationStatus === VerificationState.CHECKING ||
                emailVerificationStatus === VerificationState.UNCHECKED) && <Loading />}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        loading: state.authorization.loading,
        emailVerificationStatus: state.authorization.emailVerificationStatus,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        verifyEmail: bindActionCreators(AuthActions.verifyEmail, dispatch),
    })
)(withRouter(EmailVerificationPage));
