import React from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import { PanelResponse } from '../../../models/responses/panel.response';
import greenTriangle from '../../../static/icons/greenTriangle.svg';
import redTriangle from '../../../static/icons/redTriangle.svg';
import { GenderOptionsEnum } from '../../../constants/genderOptions';
import styled from 'styled-components';

interface InjectedProps {
    panel: PanelResponse;
    patientGender: string;
}

const createStyles = makeStyles({
    head: {
        backgroundColor: colors.gray1,
    },
    darkText: {
        fontSize: '12px',
        color: colors.textPrimary,
    },
    lightText: {
        fontSize: '12px',
        color: colors.textSecondary,
    },
    lightTextLabel: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'uppercase',
    },
    tableCell: {
        padding: 0,
        borderBottom: 'none',
        '&:last-child': {
            padding: 0,
        },
    },
    headerTableRow: {
        width: '90%',
        padding: '0 2.5%',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: '30% 30% 30% 10%',
    },
    bodyTableRow: {
        width: '90%',
        padding: '0 2.5%',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: '30% 30% 30% 10%',
        borderBottom: `1px solid ${colors.gray2}`,
    },
});

const ChangeWrapper = styled.span<any>`
    & img {
        transform: rotate(${props => (props.isIncrease ? 0 : '180deg')});
    }
`;

type PropsTypes = InjectedProps;

const ChangeTable: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    const changeStatus = (key: string) => {
        const referenceRange = props.panel.targetList.filter(target => target.target === key)[0];
        const minValue =
            props.patientGender.toLowerCase() === GenderOptionsEnum.MALE
                ? referenceRange.maleMinRange
                : referenceRange.femaleMinRange;
        const maxValue =
            props.patientGender.toLowerCase() === GenderOptionsEnum.MALE
                ? referenceRange.maleMaxRange
                : referenceRange.femaleMaxRange;
        const currentValue = props.panel.currentResult[key];
        const prevValue = props.panel.previousResult[key];

        const isHealthyChange =
            (typeof maxValue === 'number' && typeof minValue === 'number') ?
                currentValue >= minValue && currentValue <= maxValue
                : true;
        const isIncrease = currentValue > prevValue;

        return { isHealthyChange, isIncrease };
    };

    return (
        <Table>
            <TableHead classes={{ root: classes.head }}>
                <TableRow classes={{ root: classes.headerTableRow }}>
                    <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1" classes={{ root: classes.darkText }}>
                            Label
                        </Typography>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1" classes={{ root: classes.darkText }}>
                            Change
                        </Typography>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1" classes={{ root: classes.darkText }}>
                            Change %
                        </Typography>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1" classes={{ root: classes.darkText }}/>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(props.panel.change).map(key => {
                    return (
                        <TableRow classes={{ root: classes.bodyTableRow }} key={key}>
                            <TableCell classes={{ root: classes.tableCell }}>
                                <Typography variant="body1" classes={{ root: classes.lightTextLabel }}>
                                    {key}
                                </Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCell }}>
                                <Typography variant="body1" classes={{ root: classes.darkText }}>
                                    {props.panel.change[key] === 0 ? '-' : props.panel.change[key]}
                                </Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCell }}>
                                <Typography variant="body1" classes={{ root: classes.darkText }}>
                                    {props.panel.percentageChange[key]}
                                </Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCell }}>
                                <Typography variant="body1" classes={{ root: classes.lightText }}>
                                    {props.panel.change[key] !== 0 && (
                                        <ChangeWrapper isIncrease={changeStatus(key).isIncrease}>
                                            <img
                                                src={changeStatus(key).isHealthyChange ? greenTriangle : redTriangle}
                                                alt=""
                                            />
                                        </ChangeWrapper>
                                    )}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default ChangeTable;
