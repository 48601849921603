import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { colors } from '../../theme/colors';
import searchIcon from '../../static/icons/searchIcon.svg';
import { useDebounce } from 'use-debounce';
import deleteIcon from '../../static/icons/deleteIcon.svg';

const useStyles = makeStyles({
    root: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            padding: '15px 12px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
    },
    underline: {
        '&:after': {
            borderBottom: `2px solid ${colors.gray1} !important`,
        },
        '&:before': {
            borderBottom: `2px solid ${colors.gray1} !important`,
        },
        '&:hover:not($error):before': {
            borderBottom: `2px solid ${colors.gray1} !important`,
        },
    },
    endAdornment: {
        marginRight: '8px',
    },
    imgRemove: {
        cursor: 'pointer',
    },
});

interface InjectedProps {
    placeholder: string;
    onChange: (value: string) => void;
}

type PropsTypes = InjectedProps;

const TextInputSearch: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);
    const { onChange, placeholder } = props;

    const [searchString, setSearchString] = useState('');
    const [filterString] = useDebounce(searchString, 200);

    useEffect(() => {
        onChange(filterString);
    }, [filterString, onChange]);

    const removeInput = useCallback(() => {
        setSearchString('');
    }, []);

    return (
        <TextField
            placeholder={placeholder}
            InputProps={{
                classes: {
                    root: classes.root,
                    underline: classes.underline,
                },
                endAdornment: (
                    <InputAdornment position="end" classes={{ root: classes.endAdornment }}>
                        <img
                            src={!searchString.length ? searchIcon : deleteIcon}
                            alt=""
                            onClick={removeInput}
                            className={!searchString.length ? '' : classes.imgRemove}
                        />
                    </InputAdornment>
                ),
            }}
            fullWidth
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                setSearchString(evt.target.value);
            }}
            value={searchString}
        />
    );
};

export default TextInputSearch;
