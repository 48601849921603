import React, { useCallback } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableRow, Typography, InputBase } from '@material-ui/core';
import { colors } from '../../theme/colors';
import { BloodtargetReponce } from '../../models/responses/bloodtarget.reponce';
import { Row } from '../bloodwork/AddBloodwork';
import { TargetLayout } from '../../models/dto/uploadLabWorkPatient.dto';

const createStyles = makeStyles({
    noStylesCell: {
        borderBottom: 'none',
    },
    styledCell: {
        padding: '4px',
        borderBottom: `1px solid ${colors.gray1}`,
    },
    unitCell: {
        padding: '0',
        width: '50px',
        borderBottom: `1px solid ${colors.gray1}`,
    },
    deleteCell: {
        border: 'none',
        padding: '0 0 0 4px',
    },
    headerCell: {
        border: `1px solid ${colors.gray1}`,
    },
    row: {
        height: '50px',
    },
    placeholderUnit: {
        fontSize: '12px',
        color: colors.gray1,
    },
    valueUnit: {
        color: colors.textSecondary,
        fontSize: '12px',
        textAlign: 'center',
        display: 'block',
    },
    inputBaseTarget: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            padding: '3px 10px',
            margin: '0 auto',
            width: '100%',
        },
    },
    inputBaseValue: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            borderLeft: `1px solid ${colors.gray1}`,
            padding: '3px 10px',
            margin: '0 auto',
            width: '55px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
    },
    removeIcon: {
        marginBottom: '-8px',
    },
    noStylesRow: {
        border: 'none',
    },
    addIconDisabled: {
        opacity: 0.5,
        marginBottom: '-8px',
    },
    iconCell: {
        borderBottom: `1px solid ${colors.gray1}`,
        padding: '12px 16px',
    },
});

interface InjectedProps {
    table: Row[];
    targetsToShow: Row[];
    allBloodTargets: BloodtargetReponce[];
    isFilteringNow: boolean;
    values: TargetLayout[];
    setValues: (value: TargetLayout[]) => void;
}

type PropsTypes = InjectedProps;

const CustomReportTable: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    const { table, isFilteringNow, targetsToShow, values, setValues } = props;

    const changeRow = useCallback(
        (target: Row, value: string) => {
            const newValue = { value, id: target.id, target: target.target };
            const index = values.findIndex(t => t.id === target.id);
            if (index === -1) {
                setValues([...values, newValue]);
            } else {
                const newValues = values.map(t => {
                    return t.id === target.id ? { ...t, value } : t;
                });
                setValues(newValues);
            }
        },
        [values, setValues]
    );

    const includesTarget = useCallback(
        (target: Row) => {
            return isFilteringNow ? targetsToShow.includes(target) : true;
        },
        [isFilteringNow, targetsToShow]
    );

    const getTargetValue = useCallback(
        (row: Row) => {
            const targetInValues = values.find(t => t.id === row.id);
            return !!targetInValues ? targetInValues.value : '';
        },
        [values]
    );

    return (
        <>
            <Table>
                <TableBody>
                    {table.map(
                        (row, index) =>
                            includesTarget(row) && (
                                <TableRow key={index} classes={{ root: classes.row }}>
                                    <TableCell classes={{ root: classes.styledCell }}>
                                        <InputBase
                                            classes={{ root: classes.inputBaseTarget }}
                                            value={row.target}
                                            disabled
                                        />
                                    </TableCell>
                                    <TableCell classes={{ root: classes.unitCell }}>
                                        {row.unit && (
                                            <Typography variant="body1" classes={{ root: classes.valueUnit }}>
                                                ({row.unit})
                                            </Typography>
                                        )}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.styledCell }}>
                                        <InputBase
                                            classes={{ root: classes.inputBaseValue }}
                                            placeholder={'Value'}
                                            value={getTargetValue(row)}
                                            type={'number'}
                                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                                changeRow(row, evt.target.value)
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default CustomReportTable;
