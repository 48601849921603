export const referenceRange = [
    'Complete Blood Count',
    'Prothrombin Time',
    'Basic Metabolic Panel',
    'Comprehensive Metabolic Panel',
    'Lipid Panel',
    'Liver Panel',
    'Thyroid Stimulating Hormone',
    'Hemoglobin A1C',
    'Urinalysis',
    'Cultures',
];
