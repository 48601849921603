import { combineReducers, Reducer } from 'redux';
import * as fromPatient from './patient/index';
import * as fromProvider from './provider/index';
import * as fromLab from './lab/index';
import * as fromLabwork from './labwork/index';
import * as fromPrescription from './prescription/index';
import * as fromPharmacy from './pharmacies/index';
import * as fromTherapies from './therapy/index';
import * as fromApplicationType from './applicationType/index';
import * as fromDrugs from './drug/index';
import * as fromBloodTarget from './bloodTarget/index';
import * as fromNotifications from './notifications/index';
import * as fromAnalytics from './analytics/index';
import * as fromInvoice from './invoice/index';
import * as fromSidebar from './sidebar/index';
import * as fromPanelType from './panelType/index';
import * as fromAdminStatistics from './adminStatistics/index';
import * as fromCardManagement from './cardManagement/index';
import * as fromTour from './tour/index';
import { authReducer, AuthStateModel } from './auth';
import { providerMyPatientsReducer, ProviderMyPatientsState } from './providerMyPatients/providerMyPatients.reducer';
import snackbarsReducer, { SnackbarReducerModel } from './common/snackbars';
import WeightManagementReducer, { WeightStateModel } from './weight';

export interface RootStateModel {
    authorization: AuthStateModel;
    patient: fromPatient.StateModel;
    provider: fromProvider.StateModel;
    lab: fromLab.StateModel;
    labwork: fromLabwork.StateModel;
    providerMyPatients: ProviderMyPatientsState;
    notifications: fromNotifications.StateModel;
    prescription: fromPrescription.StateModel;
    pharmacy: fromPharmacy.StateModel;
    bloodTarget: fromBloodTarget.StateModel;
    therapy: fromTherapies.StateModel;
    drug: fromDrugs.StateModel;
    applicationType: fromApplicationType.StateModel;
    analytics: fromAnalytics.StateModel;
    invoice: fromInvoice.StateModel;
    sidebar: fromSidebar.StateModel;
    panelType: fromPanelType.StateModel;
    adminStatistics: fromAdminStatistics.StateModel;
    snackbars: SnackbarReducerModel;
    weightManagement: WeightStateModel;
    cardManagement: fromCardManagement.StateModel;
    tour: fromTour.StateModel;
}

export const reducers: Reducer<RootStateModel> = combineReducers({
    authorization: authReducer,
    patient: fromPatient.reducer,
    provider: fromProvider.reducer,
    lab: fromLab.reducer,
    labwork: fromLabwork.reducer,
    providerMyPatients: providerMyPatientsReducer,
    notifications: fromNotifications.reducer,
    prescription: fromPrescription.reducer,
    applicationType: fromApplicationType.reducer,
    drug: fromDrugs.reducer,
    pharmacy: fromPharmacy.reducer,
    therapy: fromTherapies.reducer,
    bloodTarget: fromBloodTarget.reducer,
    analytics: fromAnalytics.reducer,
    invoice: fromInvoice.reducer,
    sidebar: fromSidebar.reducer,
    panelType: fromPanelType.reducer,
    adminStatistics: fromAdminStatistics.reducer,
    cardManagement: fromCardManagement.reducer,
    snackbars: snackbarsReducer,
    weightManagement: WeightManagementReducer,
    tour: fromTour.reducer,
});
