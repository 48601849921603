import {ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {Snackbar} from "../reducers/common/snackbars";

export enum SnackbarActionNames {
    SNACKBAR_ADD = 'Snackbars/SNACKBARS_ADD',
    SNACKBAR_REMOVE = 'Snackbars/SNACKBARS_REMOVE'
}

export const SnackbarActions = {
    addSnackbar: (snackbar: Snackbar) => createAction(SnackbarActionNames.SNACKBAR_ADD, snackbar),
    removeSnackbar: (id: number) => createAction(SnackbarActionNames.SNACKBAR_REMOVE, id)
};

export type SnackbarActions = ActionsUnion<typeof SnackbarActions>;
