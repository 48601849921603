import React, { useEffect, useRef, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../constants/layout';
import { List, makeStyles, Typography } from '@material-ui/core';
import Loading from '../shared/Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import NotificationListItem from './NotificationListItem';
import { NotificationModel } from '../../models/responses/notification.responce';
import { colors } from '../../theme/colors';

const useStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '16px',
        color: colors.textSecondary,
        marginTop: '20px',
    },
});

interface InjectedProps {
    loading: boolean;
    allNotifications: NotificationModel[];
    total: number | null;
    page: number;
    increasePageNumber: () => void;
    markOneAsRead: (id: number) => void;
}

const NotificationList: React.FC<InjectedProps> = props => {
    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);
    const classes = useStyles(props);

    const { loading, increasePageNumber, page, allNotifications, total, markOneAsRead } = props;

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    const getMore = () => {
        increasePageNumber();
    };

    return (
        <>
            {!loading && allNotifications.length === 0 && (
                <SizeControllerWrapper>
                    <Typography variant="body1" classes={{ root: classes.noData }}>
                        No data found.
                    </Typography>
                </SizeControllerWrapper>
            )}

            {loading && page === 0 && <Loading smallSize />}

            {!!allNotifications.length && !loading && (
                <List classes={{ root: classes.list }} ref={listRef}>
                    <InfiniteScroll
                        dataLength={allNotifications.length}
                        next={getMore}
                        height={height}
                        hasMore={total ? allNotifications.length < total : false}
                        loader={<Loading smallSize />}
                        className={classes.scrollContainer}
                    >
                        {allNotifications.map((notification, index) => {
                            return (
                                <div key={index}>
                                    <NotificationListItem notification={notification} markOneAsRead={markOneAsRead} />
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                </List>
            )}
        </>
    );
};

export default NotificationList;
