import React from 'react';
import { BloodworkResponse } from '../../../models/responses/bloodwork.response';
import Panel from './Panel';
import {BloodworkTabs} from "../../../constants/tabRouting/bloodworkTabs";

interface InjectedProps {
    currentBloodwork: BloodworkResponse;
    patientGender: string;
    tabName: BloodworkTabs;
    reportDate: string;
    previousReportDate: string;
}

type PropsTypes = InjectedProps;

const AllPanels: React.FC<PropsTypes> = props => {
    return (
        <>
            {props.currentBloodwork.panels.map((panel, index) => (
                <Panel
                    panel={panel}
                    patientGender={props.patientGender}
                    reportDate={props.reportDate}
                    previousReportDate={props.previousReportDate}
                    tabName={props.tabName}
                    key={index}
                    index={index}
                />
            ))}
        </>
    );
};

export default AllPanels;
