import React, { useEffect, useRef, useState } from 'react';
import { List, makeStyles, Typography } from '@material-ui/core';
import BloodTargetListItem from './BloodTargetListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { colors } from '../../theme/colors';
import SizeControllerWrapper from '../../shared/SizeControllerWrapper';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { mediaQuery } from '../../constants/device';
import { SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP, SCROLLABLE_LIST_BOTTOM_GAP_MOBILE } from '../../constants/layout';
import Loading from '../shared/Loading';
import AlphabetLetter from '../patient/components/AlphabetLetter';
import { BloodtargetReponce } from '../../models/responses/bloodtarget.reponce';
import {ifStringEquals} from "../../helpers/stringFilterHelper";

const useStyles = makeStyles({
    list: {
        overflow: 'scroll',
        margin: '5px 0 0 !important',
    },
    scrollContainer: {
        '& > div:last-child': {
            marginBottom: '20px',
        },
    },
    noData: {
        fontSize: '16px',
        color: colors.textSecondary,
        marginTop: '20px',
    },
});

interface InjectedProps {
    loading: boolean;
    allItems: BloodtargetReponce[];
    total: number | null;
    page: number;
    searchQuery: string;
    increasePageNumber: () => void;
}

type PropsTypes = InjectedProps;

const BloodTargetList: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);
    let latestLetter: string | null = null;

    const [height, setHeight] = useState(0);
    const listRef = useRef(null);
    const isLaptop = useMediaQuery(mediaQuery.laptop);

    useEffect(() => {
        if (listRef && listRef.current) {
            const height = window.innerHeight - (listRef.current! as HTMLElement).getBoundingClientRect().top;
            setHeight(
                isLaptop ? height - SCROLLABLE_LIST_BOTTOM_GAP_DESKTOP : height - SCROLLABLE_LIST_BOTTOM_GAP_MOBILE
            );
        }
    }, [listRef, isLaptop]);

    const getMore = () => {
        props.increasePageNumber();
    };

    return (
        <>
            {!!props.allItems.length && (
                <List classes={{ root: classes.list }} ref={listRef}>
                    <InfiniteScroll
                        dataLength={props.allItems.length}
                        next={getMore}
                        height={height}
                        hasMore={props.total ? props.allItems.length < props.total : false}
                        loader={<Loading smallSize />}
                        className={classes.scrollContainer}
                    >
                        {props.allItems.map((item, index) => {
                            let should = false;
                            if (!latestLetter || !ifStringEquals(latestLetter, item.target[0])) {
                                should = true;
                                latestLetter = item.target[0];
                            }
                            return (
                                <div key={index}>
                                    {should && <AlphabetLetter>{item.target[0]}</AlphabetLetter>}
                                    <BloodTargetListItem key={index} target={item}/>
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                </List>
            )}

            {!props.loading && props.allItems.length === 0 && (
                <SizeControllerWrapper>
                    <Typography variant="body1" classes={{ root: classes.noData }}>
                        No data found.
                    </Typography>
                </SizeControllerWrapper>
            )}

            {props.loading && props.page === 0 && <Loading smallSize />}
        </>
    );
};

export default BloodTargetList;
