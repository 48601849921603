import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { colors } from '../../theme/colors';
import InputMask from 'react-input-mask';

const useStyles = makeStyles({
    root: {
        '& input': {
            fontSize: '12px',
            color: colors.textPrimary,
            padding: '15px 12px',
            '&::placeholder': {
                color: colors.textSecondary,
                opacity: 1,
            },
            '&:hover': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
    },
    underline: {
        '&:after': {
            borderBottom: `1px solid ${colors.grayUnderline} !important`,
        },
        '&:before': {
            borderBottom: `1px solid ${colors.grayUnderline} !important`,
        },
        '&:hover:not($error):before': {
            borderBottom: `1px solid ${colors.grayUnderline} !important`,
        },
    },
    underlineSuccess: {
        '&:after': {
            borderBottom: `1px solid ${colors.success}`,
        },
        '&:before': {
            borderBottom: `1px solid ${colors.success}`,
        },
        '&:hover:not($error):before': {
            borderBottom: `1px solid ${colors.success} !important`,
        },
    },
    error: {
        '&:after': {
            borderBottom: `1px solid ${colors.redLight} !important`,
        },
    },
    disabled: {
        color: `${colors.textSecondary} !important`,
    },
});

interface InjectedProps {
    placeholder: string;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    onBlur?: () => void;
    error?: boolean | null;
    success?: boolean | null;
    password?: boolean;
    disabled?: boolean;
    inputProps?: any;
    onKeyDown?: any;
}

type PropsTypes = InjectedProps;

const PhoneNumberInput: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);
    return (
        <InputMask
            mask="+ (999) 999.9999"
            maskChar=" "
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            value={props.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
        >
            {(inputProps: any) => (
                <TextField
                    {...inputProps}
                    InputProps={{
                        ...(props.inputProps ? props.inputProps : {}),
                        classes: {
                            root: classes.root,
                            underline: classes.underline,
                            error: classes.error,
                            disabled: classes.disabled,
                        },
                    }}
                    error={!!props.error}
                    disabled={props.disabled}
                />
            )}
        </InputMask>
    );
};

export default PhoneNumberInput;
