import React from 'react';
import styled from 'styled-components';
import noPatients from '../../static/images/provider/noPatientsImg.svg';
import { makeStyles, Typography } from '@material-ui/core';
import PatientTypes from '../../constants/patientTypes';
import { toTitleCase } from '../../helpers/stringHelpers';
import { colors } from '../../theme/colors';

const createStyles = makeStyles({
    text: {
        fontSize: '14px',
        color: colors.gray1,
        marginTop: '16px',
        textAlign: 'center',
    },
});

const Wrapper = styled.div<{height: number}>`
    height: ${props => props.height};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
`;

interface InjectedProps {
    type: PatientTypes;
    height: number;
}

type PropsTypes = InjectedProps;

const NoPatients: React.FC<PropsTypes> = props => {
    const classes = createStyles(props);

    return (
        <Wrapper height={props.height}>
            <img src={noPatients} alt="" />
            <Typography variant="button" classes={{ root: classes.text }}>
                No {toTitleCase(props.type)} <br /> Patients
            </Typography>
        </Wrapper>
    );
};

export default NoPatients;
