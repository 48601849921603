import { put, call, takeEvery } from 'redux-saga/effects';
import * as labActions from '../../actions/lab.actions';
import LabApi from '../../../api/lab/LabApi';
import { typedCall } from '../../../shared/utils/typedYieldWrapper';

function* getAllLabs(action: labActions.getAllLabsType) {
    try {
        const value = yield call(LabApi.getAllLabs, action.payload);
        const response = typedCall(LabApi.getAllLabs, value);
        if (action.payload.overrideRecords) {
            yield put({ type: labActions.ActionNames.GET_ALL_LABS_OVERRIDE_SUCCESS, payload: response });
        } else {
            yield put({ type: labActions.ActionNames.GET_ALL_LABS_UPDATE_SUCCESS, payload: response });
        }
    } catch (e) {
        yield put({ type: labActions.ActionNames.GET_ALL_LABS_FAILED });
    }
}

function* getLabById(action: labActions.getLabByIdType) {
    try {
        const value = yield call(LabApi.getLabById, action.payload);
        const response = typedCall(LabApi.getLabById, value);
        yield put({ type: labActions.ActionNames.GET_LAB_BY_ID_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: labActions.ActionNames.GET_LAB_BY_ID_FAILED });
    }
}

function* saveLabs(action: labActions.saveLabsType) {
    try {
        const value = yield call(LabApi.saveLabs, action.payload);
        yield put({ type: labActions.ActionNames.SAVE_LABS_SUCCESS, payload: value });
    } catch (e) {
        yield put({ type: labActions.ActionNames.SAVE_LABS_FAILED });
    }
}

function* updateLabs(action: labActions.updateLabsType) {
    try {
        const value = yield call(LabApi.updateLabs, action.payload);
        yield put({ type: labActions.ActionNames.UPDATE_LABS_SUCCESS, payload: value });
    } catch (e) {
        yield put({ type: labActions.ActionNames.UPDATE_LABS_FAILED });
    }
}
export default function* LabSagas() {
    yield takeEvery(labActions.ActionNames.GET_ALL_LABS, getAllLabs);
    yield takeEvery(labActions.ActionNames.GET_LAB_BY_ID, getLabById);
    yield takeEvery(labActions.ActionNames.SAVE_LABS, saveLabs);
    yield takeEvery(labActions.ActionNames.UPDATE_LABS, updateLabs);
}
