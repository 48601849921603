import React, { useCallback, useEffect, useState } from 'react';
import HeaderHeading from '../../shared/HeaderHeading';
import { connect } from 'react-redux';
import * as fromRoot from '../../store/reducers';
import { UserModel } from '../../models/responses/user.response';
import styled from 'styled-components';
import elipsBackground from '../../static/images/elipsBackground.svg';
import BigMenuButton from './components/BigMenuButton';
import bloodwork from '../../static/icons/bloodwork.svg';
import dosage from '../../static/icons/dosage.svg';
import weight from '../../static/icons/weight.svg';
import analytics from '../../static/icons/analytics.svg';
// import { makeStyles } from '@material-ui/core';
// import wizard from '../../static/icons/wizard.svg';
// import { colors } from '../../theme/colors';
import { mediaQuery } from '../../constants/device';
import { RouteComponentProps, withRouter } from 'react-router';
import AuthApi from '../../api/auth/AuthApi';
import Loading from '../shared/Loading';
import { Actions } from '../../store/actions/auth.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { DosageRouting } from '../../constants/tabRouting/routing';

// const createStyles = makeStyles({
//     wizardText: {
//         color: colors.textSecondary,
//         marginRight: '20px',
//         fontSize: '14px',
//         lineHeight: '18px',
//     },
// });

const BackgroundWrapper = styled.div`
    height: calc(100vh - 140px);
    background-image: url(${elipsBackground});
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center 250%;

    ${mediaQuery.mobileLandscape} {
        height: auto;
    }
    ${mediaQuery.mobileL} {
        background-position: center 140%;
    }
    ${mediaQuery.tablet} {
        background-position: center 120%;
    }

    ${mediaQuery.laptop} {
        background-position: center 130%;
        height: calc(100% - 50px);
    }
    ${mediaQuery.laptopL} {
        background-position: center 110%;
        height: calc(100% - 50px);
    }
`;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 3.6vh;
    grid-gap: 3vw;
    width: fit-content;
    margin: 0 auto;
`;

// const WizardWrapper = styled.div`
//     width: 90%;
//     margin: 0 auto;
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     padding-bottom: 16px;
// `;

type StoreModel = {
    user: UserModel | null;
    loading: boolean;
};

type DispatchModel = {
    setCurrentUser: typeof Actions.setCurrentUser;
};

type PropsTypes = RouteComponentProps<any> & DispatchModel & StoreModel;

const Start: React.FC<PropsTypes> = props => {
    const [loaded, setLoaded] = useState(false);
    const {user, setCurrentUser, loading, history } = props;

    useEffect(() => {
        AuthApi.getCurrentUser().then(({ data }) => {
            setCurrentUser(data);
            if (!data.setup) {
                history.push('/welcome');
            }
            setLoaded(true);
        });
    }, [setCurrentUser, history]);

    const getName = () => {
        const welcomeMessage = `Welcome ${user && user.firstName + ' ' + user.lastName}`;
        if (welcomeMessage.length > 32) {
            return `Welcome ${user && user.firstName}`;
        }
        return welcomeMessage;
    };

    const goToPage = useCallback(
        (link: string) => {
            history.push(link);
        },
        [history]
    );

    return (
        <>
            {!loaded && loading ? (
                <Loading />
            ) : (
                <>
                    <HeaderHeading>{getName()}</HeaderHeading>
                    <BackgroundWrapper>
                        <Wrapper>
                            <BigMenuButton img={bloodwork} onClick={() => goToPage('/bloodwork-reports')}>
                                Bloodwork
                            </BigMenuButton>

                            <BigMenuButton img={dosage} onClick={() => goToPage(DosageRouting[0].path)}>
                                Dosage
                            </BigMenuButton>

                            <BigMenuButton img={analytics} onClick={() => goToPage('/analytics')}>
                                Analytics
                            </BigMenuButton>

                            <BigMenuButton img={weight} onClick={() => goToPage('/weight-management')}>
                                Weight
                            </BigMenuButton>
                        </Wrapper>
                        {/*<WizardWrapper>*/}
                        {/*<Typography variant="body1" classes={{ root: classes.wizardText }}>*/}
                        {/*I am Wizard. <br />I can help you!*/}
                        {/*</Typography>*/}
                        {/*<img src={wizard} alt="" />*/}
                        {/*</WizardWrapper>*/}
                    </BackgroundWrapper>
                </>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
        loading: state.authorization.loading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        setCurrentUser: bindActionCreators(Actions.setCurrentUser, dispatch),
    })
)(withRouter(Start));
