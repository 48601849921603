import React, { useEffect } from 'react';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import styled from 'styled-components';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import CustomLink from '../../../shared/link/CustomLink';
import { Actions as AuthActions } from '../../../store/actions/auth.actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as fromRoot from '../../../store/reducers';
import LoadingTransparent from '../../shared/LoadingTransparent';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserModel } from '../../../models/responses/user.response';
import FullScreenAuthFormWrapper from '../components/FullScreenAuthFormWrapper';
import { mediaQuery } from '../../../constants/device';
import upmedSymbol from '../../../static/images/upmedSymbol.svg';

const styles = createStyles({
    heading: {
        fontSize: '24px',
    },
    text: {
        color: colors.textSecondary,
        fontSize: '14px',
    },
    linkSendAgain: {
        fontSize: '20px',
        textDecoration: 'underline',
        textDecorationColor: colors.textSecondary,
        cursor: 'pointer',
    },
    backLink: {
        color: colors.redMain,
    },
});

const Wrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 7% 27% 27% 39%;
    align-items: end;
    margin: 0 auto;
    text-align: center;

    ${mediaQuery.mobileLandscape} {
        grid-template-rows: 13% 41% 32% 11%;
    }
`;

const BackgroundWrapper = styled.div`
    padding: 80px 0 57px;
    height: calc(100vh - 227px);

    ${mediaQuery.mobileLandscape} {
        height: calc(100vh - 114px);
        padding: 12px 0 12px;
    }

    ${mediaQuery.laptop} {
        height: 390px;
        width: 350px;
        padding: 0;
        background-color: ${colors.white};
        background-image: url(${upmedSymbol});
        background-repeat: no-repeat;
        background-position: top;
    }
`;

const BackgroundColor = styled.div`
    background-color: ${colors.gray2};
    ${mediaQuery.laptop} {
        background-color: ${colors.white};
    }
`;

const EmailWrapper = styled.span`
    font-weight: bold;
    color: ${colors.textPrimary};
    margin-left: 2px;
`;

type StoreModel = {
    user: UserModel | null;
    loading: boolean;
};

type DispatchModel = {
    resetStatusVariables: typeof AuthActions.resetStatusVariables;
    resendVerificationLink: typeof AuthActions.resendVerificationLink;
};

type PropsTypes = StoreModel & DispatchModel & RouteComponentProps<any> & WithStyles<typeof styles>;

const VerificationEmailLinkSent: React.FC<PropsTypes> = props => {
    const { resetStatusVariables } = props;
    useEffect(() => {
        resetStatusVariables();
    }, [resetStatusVariables]);

    const resentSignUp = () => {
        if (props.user && props.user.email) {
            props.resendVerificationLink(props.user.email);
        } else {
            props.history.push('/sign-in');
        }
    };

    const { classes } = props;
    return (
        <>
            {props.loading && <LoadingTransparent />}
            <BackgroundColor>
                <FullScreenAuthFormWrapper>
                    <BackgroundWrapper>
                        <SizeControllerWrapper>
                            <Wrapper>
                                <Typography variant="h2" classes={{ root: classes.heading }}>
                                    Thank you
                                </Typography>

                                <Typography variant="body1" classes={{ root: classes.text }}>
                                    An email was sent with verification link <br />
                                    to <EmailWrapper>{props.user && props.user.email}</EmailWrapper> <br />
                                    To complete your registration <br />
                                    please follow the link.
                                </Typography>

                                <div>
                                    <Typography variant="body1" classes={{ root: classes.text }}>
                                        If Not
                                    </Typography>

                                    <Typography
                                        variant="h2"
                                        classes={{ root: classes.linkSendAgain }}
                                        onClick={resentSignUp}
                                    >
                                        Send Again
                                    </Typography>
                                </div>

                                <CustomLink to={'/'} underlineColor={colors.redMain}>
                                    <Typography variant="button" classes={{ root: classes.backLink }}>
                                        Back to UPMed
                                    </Typography>
                                </CustomLink>
                            </Wrapper>
                        </SizeControllerWrapper>
                    </BackgroundWrapper>
                </FullScreenAuthFormWrapper>
            </BackgroundColor>
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        user: store.authorization.user,
        loading: store.authorization.loading,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        resetStatusVariables: bindActionCreators(AuthActions.resetStatusVariables, dispatch),
        resendVerificationLink: bindActionCreators(AuthActions.resendVerificationLink, dispatch),
    })
)(withStyles(styles)(withRouter(VerificationEmailLinkSent)));
