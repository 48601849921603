import React, { useCallback, useEffect, useState } from 'react';
import TextInput from '../../../shared/input/TextInput';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import removeRowIcon from '../../../static/icons/removeRow.svg';
import { ProviderRow } from './AddProviders';
import PhoneNumberInput from '../../../shared/input/PhoneNumberInput';
import { ValidationErrorsMap } from '../../../models/validation/validationErrorsMap';
import { useDebounce } from 'use-debounce';
import fillClassInstanceWithValues from '../../../shared/utils/fillClassInstanceWithValues';
import { validateSync } from 'class-validator';
import { AddProviderDto } from '../../../models/dto/addProvider.dto';

export enum FormFields {
    id = 'id',
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    phone = 'phone',
    address = 'address',
    businessName = 'businessName',
}

const createStyles = makeStyles({
    cell: {
        minWidth: '145px',
        position: 'relative',
    },
    iconRow: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
});


interface InjectedProps {
    changeRow: (filedName: string, value: string | Date | null, id: number) => void;
    removeRow: (id: number) => void;
    canRemoveRow: boolean;
    row: ProviderRow;
}

const AddProviderRow: React.FC<InjectedProps> = props => {
    const classes = createStyles(props);

    const [validationErrorsMap, setValidationErrorsMap] = useState<ValidationErrorsMap>({});
    const [validation] = useDebounce(validationErrorsMap, 1000);

    const { canRemoveRow, removeRow, changeRow, row } = props;

    useEffect(() => {
        validateForm(row);
    }, [row]);

    const validateForm = (user: any, isOnSubmit = false) => {
        const item = fillClassInstanceWithValues<AddProviderDto>(AddProviderDto, user);
        const validationErrors = validateSync(item);
        const map: ValidationErrorsMap = {};
        if (validationErrors.length) {
            validationErrors.forEach(err => {
                if (isOnSubmit || user.isBeingEdited.has(err.property)) {
                    map[err.property] = Object.entries(err.constraints)
                        .map(([, value]) => value)
                        .join(', ');
                }
            });
            setValidationErrorsMap(map);
        }
        setValidationErrorsMap(map);
        return map;
    };

    const changeForm = (fieldName: string, value: string | Date | null) => {
        changeRow(fieldName, value, row.id);
    };

    const remove = useCallback(() => {
        removeRow(row.id);
    }, [row, removeRow]);

    return (
        <TableRow>
            <TableCell>
                {canRemoveRow && <img src={removeRowIcon} alt="" className={classes.iconRow} onClick={remove} />}
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'First Name'}
                    value={row.firstName}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.firstName, evt.target.value)
                    }
                    error={!!validation.firstName}
                />
            </TableCell>
            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Last Name'}
                    value={row.lastName}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.lastName, evt.target.value)
                    }
                    error={!!validation.lastName}
                />
            </TableCell>

            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Business Name'}
                    value={row.businessName}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.businessName, evt.target.value)
                    }
                    error={!!validation.businessName}
                />
            </TableCell>

            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Email'}
                    value={row.email}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.email, evt.target.value)
                    }
                    error={!!validation.email}
                />
            </TableCell>

            <TableCell classes={{ root: classes.cell }}>
                <TextInput
                    placeholder={'Address'}
                    value={row.address}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        changeForm(FormFields.address, evt.target.value)
                    }
                    error={!!validation.address}
                />
            </TableCell>

            <TableCell classes={{ root: classes.cell }}>
                <PhoneNumberInput
                    placeholder={'+ (000) 000.0000'}
                    value={row.phone}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        changeForm(FormFields.phone, evt.target.value);
                    }}
                    error={!!validation.phone}
                />
            </TableCell>
        </TableRow>
    );
};

export default AddProviderRow;
