import {
    IsEmail,
    IsOptional,
    IsString,
    MaxLength,
    MinLength,
    registerDecorator,
    ValidationOptions
} from 'class-validator';

export function isPhoneNumberValid(validationOptions?: ValidationOptions) {
    return function(object: Object, propertyName: string) {
        registerDecorator({
            name: 'isPhoneNumberValid',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: {
                validate(value: string) {
                    return (value.trim().match(/ /g) || []).length === 2 && value.trim().length === 16;
                },
            },
        });
    };
}

export function isBirthValid(validationOptions?: ValidationOptions) {
    return function(object: Object, propertyName: string) {
        registerDecorator({
            name: 'isBirthValid',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: {
                validate(value: string | Date | null) {
                    return !!value && value !== 'Invalid Date' && value <= new Date();
                },
            },
        });
    };
}


export class AddProviderDto {
    @IsEmail({}, { message: 'Email has invalid format' })
    @MinLength(1, { message: 'Email cannot be empty' })
    @MaxLength(30, { message: 'Email is too long' })
    email: string;

    @IsString()
    @MinLength(1, { message: 'First Name cannot be empty' })
    @MaxLength(30, { message: 'First Name is too long' })
    firstName: string;

    @IsString()
    @MinLength(1, { message: 'Last Name cannot be empty' })
    @MaxLength(30, { message: 'Last Name is too long' })
    lastName: string;

    @IsOptional()
    @IsString()
    @isPhoneNumberValid({ message: 'Phone is not valid' })
    phone: string;

    @IsOptional()
    @MinLength(1, { message: 'Address cannot be empty' })
    @MaxLength(50, { message: 'Address is too long' })
    address: string;

    @IsOptional()
    @MinLength(1, { message: 'Business Name cannot be empty' })
    @MaxLength(30, { message: 'Business Name is too long' })
    businessName: string;
}
