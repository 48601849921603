import * as patientActions from '../../actions/patient.actions';
import { PatientModel } from '../../../models/responses/patient.response';
import RequestState from '../../../constants/requestState';
import { LinkingStatusModel } from '../../../models/responses/linkingStatus.response';

export class StateModel {
    allPatients: PatientModel[] = [];
    totalPatients: number = 0;
    pageAllPatients: number = 0;
    searchQueryAllPatients: string = '';

    patient: PatientModel | null = null;
    linkingStatus: LinkingStatusModel | null = null;
    loading: boolean = false;
    updating: RequestState = RequestState.UNSENT;
    updatingAvatar: RequestState = RequestState.UNSENT;
    tabIndex: number = 0;
    patientRequestSet: boolean = false;
    loadingSetupMeasurementSystem: RequestState = RequestState.UNSENT;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: patientActions.Actions) {
    switch (action.type) {
        case patientActions.ActionNames.GET_ALL_PATIENTS: {
            return {
                ...state,
                loading: true,
            };
        }

        case patientActions.ActionNames.GET_ALL_PATIENTS_OVERRIDE_SUCCESS: {
            return {
                ...state,
                allPatients: action.payload.data,
                totalPatients: action.payload.total,
                loading: false,
            };
        }

        case patientActions.ActionNames.GET_ALL_PATIENTS_UPDATE_SUCCESS: {
            const isEmptyString = state.searchQueryAllPatients.trim().length === 0;
            const newPatientsList = state.allPatients.concat(action.payload.data);
            const isRedundant = newPatientsList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalPatients: action.payload.total,
                allPatients: isEmptyString && !isRedundant ? newPatientsList : action.payload.data,
            };
        }
        case patientActions.ActionNames.GET_ALL_PATIENTS_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case patientActions.ActionNames.INCREASE_PAGE_NUMBER_ALL_PATIENTS: {
            return {
                ...state,
                pageAllPatients: state.pageAllPatients + 1,
            };
        }

        case patientActions.ActionNames.SET_FILTER_QUERY_ALL_PATIENTS: {
            const isEmptyString = action.payload.trim().length === 0;

            return {
                ...state,
                searchQueryAllPatients: action.payload,
                loading: isEmptyString ? false : state.loading,
                pageAllPatients: 0,
                allPatients: isEmptyString ? [] : state.allPatients,
                totalPatients: 0,
            };
        }

        case patientActions.ActionNames.GET_PATIENT: {
            return {
                ...state,
                loading: true,
            };
        }

        case patientActions.ActionNames.GET_PATIENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                patient: action.payload,
            };
        }

        case patientActions.ActionNames.GET_PATIENT_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case patientActions.ActionNames.GET_PATIENT_BY_ID: {
            return {
                ...state,
                loading: true,
            };
        }

        case patientActions.ActionNames.GET_PATIENT_BY_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                patient: action.payload,
            };
        }

        case patientActions.ActionNames.GET_PATIENT_BY_ID_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case patientActions.ActionNames.GET_LINKING_STATUS: {
            return {
                ...state,
                loading: true,
            };
        }

        case patientActions.ActionNames.GET_LINKING_STATUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                linkingStatus: action.payload,
            };
        }

        case patientActions.ActionNames.GET_LINKING_STATUS_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case patientActions.ActionNames.UPDATE_PATIENT: {
            return {
                ...state,
                updating: RequestState.SENDING,
            };
        }

        case patientActions.ActionNames.UPDATE_PATIENT_SUCCESS: {
            return {
                ...state,
                updating: RequestState.SENT_SUCCESS,
                patient: action.payload,
            };
        }

        case patientActions.ActionNames.UPDATE_PATIENT_FAILED: {
            return {
                ...state,
                updating: RequestState.SENT_FAILED,
            };
        }

        case patientActions.ActionNames.UPLOAD_AVATAR: {
            return {
                ...state,
                updatingAvatar: RequestState.SENDING,
            };
        }

        case patientActions.ActionNames.UPLOAD_AVATAR_SUCCESS: {
            return {
                ...state,
                updatingAvatar: RequestState.SENT_SUCCESS,
            };
        }

        case patientActions.ActionNames.UPLOAD_AVATAR_FAILED: {
            return {
                ...state,
                updatingAvatar: RequestState.SENT_FAILED,
            };
        }

        case patientActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                updating: RequestState.UNSENT,
                updatingAvatar: RequestState.UNSENT,
                loadingSetupMeasurementSystem: RequestState.UNSENT,
                patientRequestSet: false,
            };
        }

        case patientActions.ActionNames.PATIENT_SET_TAB_INDEX: {
            return {
                ...state,
                tabIndex: action.payload,
            };
        }

        case patientActions.ActionNames.REJECT_PATIENT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case patientActions.ActionNames.REJECT_PATIENT_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                patientRequestSet: true,
            };
        }

        case patientActions.ActionNames.REJECT_PATIENT_REQUEST_FAILED: {
            return {
                ...state,
                loading: false,
                patientRequestSet: false,
            };
        }

        case patientActions.ActionNames.APPROVE_PATIENT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case patientActions.ActionNames.APPROVE_PATIENT_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                patientRequestSet: true,
            };
        }

        case patientActions.ActionNames.APPROVE_PATIENT_REQUEST_FAILED: {
            return {
                ...state,
                loading: false,
                patientRequestSet: false,
            };
        }

        case patientActions.ActionNames.SETUP_MEASUREMENT_SYSTEM: {
            return {
                ...state,
                loadingSetupMeasurementSystem: RequestState.SENDING,
            };
        }

        case patientActions.ActionNames.SETUP_MEASUREMENT_SYSTEM_SUCCESS: {
            return {
                ...state,
                loadingSetupMeasurementSystem: RequestState.SENT_SUCCESS,
            };
        }

        case patientActions.ActionNames.SETUP_MEASUREMENT_SYSTEM_FAILED: {
            return {
                ...state,
                loadingSetupMeasurementSystem: RequestState.SENT_FAILED,
            };
        }

        default:
            return state;
    }
}
