import React, { useCallback, useEffect } from 'react';
import { Fade, Tab, Tabs, Typography } from '@material-ui/core';
import { DosageStatus } from '../../../constants/dosageStatus';
import DosageListCurrentStatus from './DosageListCurrentStatus';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme/theme';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import { connect } from 'react-redux';
import * as fromRoot from '../../../store/reducers/index';
import { UserModel } from '../../../models/responses/user.response';
import { RoleOptions } from '../../../constants/roleOptions';
import { RouteComponentProps, withRouter } from 'react-router';
import { DosageRouting } from '../../../constants/tabRouting/routing';

const createStyles = makeStyles({
    headerIcon: {
        marginRight: '8px',
        marginBottom: '-3px',
    },
    fab: {
        position: 'fixed',
        right: '15px',
        bottom: '3.5%',
        backgroundColor: colors.redMain,
        boxShadow: theme.boxShadowRed,
        '&:hover': {
            backgroundColor: colors.redMain,
        },
        '&:active': {
            backgroundColor: colors.redMain,
            boxShadow: theme.boxShadowRed,
        },
    },
    tabs: {
        height: '42px !important',
        minHeight: '42px !important',
        paddingTop: '4px',
        '& div': {
            justifyContent: 'space-between',
        },
    },
    tab: {
        height: '30px',
        minWidth: '10px',
        padding: '6px 16px',
    },
    selectedTab: {
        '& span': {
            color: colors.textPrimary,
        },
    },
    indicator: {
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: '100%',
            left: 'calc(50% - 4px)',
            borderBottom: `3px solid ${colors.redMain}`,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            width: 0,
            height: 0,
        },
    },
    tabText: {
        fontSize: '12px !important',
        color: colors.textSecondary,
    },
});

const TabsWrapper = styled.div`
    background-color: ${colors.gray2};
    height: 60px;
`;

interface InjectedProps {
    activeTab: number;
    setActiveTab: (index: number) => void;
}

interface StoreModel {
    user: UserModel | null;
}

type PropsTypes = StoreModel & RouteComponentProps<any> & InjectedProps;

const DosageTabs: React.FC<PropsTypes> = props => {
    const { activeTab, setActiveTab, user, history, match } = props;

    const classes = createStyles(props);

    useEffect(() => {
        switch (history.location.pathname) {
            case DosageRouting[0].path: {
                setActiveTab(DosageRouting[0].tab);
                break;
            }
            case DosageRouting[1].path: {
                setActiveTab(DosageRouting[1].tab);
                break;
            }
            case DosageRouting[2].path: {
                setActiveTab(DosageRouting[2].tab);
                break;
            }
            default: {
                setActiveTab(0);
            }
        }
    }, [history, setActiveTab]);

    const getLocationValue = useCallback((value: number) => {
        switch (value) {
            case DosageRouting[0].tab: {
                return DosageRouting[0].path;
            }
            case DosageRouting[1].tab: {
                return DosageRouting[1].path;
            }
            case DosageRouting[2].tab: {
                return DosageRouting[2].path;
            }
            default:
                return DosageRouting[0].path;
        }
    }, []);

    return (
        <>
            <TabsWrapper>
                <SizeControllerWrapper>
                    <Tabs
                        value={activeTab}
                        onChange={(evt, newValue) => {
                            setActiveTab(newValue);
                            user && user.role === RoleOptions.PATIENT && history.push(getLocationValue(newValue));
                        }}
                        indicatorColor="primary"
                        classes={{ root: classes.tabs, indicator: classes.indicator }}
                    >
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Active
                                </Typography>
                            }
                        />
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Future
                                </Typography>
                            }
                        />
                        <Tab
                            classes={{ root: classes.tab, selected: classes.selectedTab }}
                            label={
                                <Typography variant="button" classes={{ root: classes.tabText }}>
                                    Past
                                </Typography>
                            }
                        />
                    </Tabs>
                </SizeControllerWrapper>
            </TabsWrapper>

            {activeTab === 0 && user && (
                <Fade in={activeTab === 0} timeout={{ enter: 500, exit: 500 }}>
                    <DosageListCurrentStatus
                        dosageStatus={DosageStatus.ACTIVE}
                        patientId={user.role === RoleOptions.PATIENT ? user.id : match.params.id}
                    />
                </Fade>
            )}

            {activeTab === 1 && user && (
                <Fade in={activeTab === 1} timeout={{ enter: 500, exit: 500 }}>
                    <DosageListCurrentStatus
                        dosageStatus={DosageStatus.SOON}
                        patientId={user.role === RoleOptions.PATIENT ? user.id : match.params.id}
                    />
                </Fade>
            )}

            {activeTab === 2 && user && (
                <Fade in={activeTab === 2} timeout={{ enter: 500, exit: 500 }}>
                    <DosageListCurrentStatus
                        dosageStatus={DosageStatus.PAST}
                        patientId={user.role === RoleOptions.PATIENT ? user.id : match.params.id}
                    />
                </Fade>
            )}
        </>
    );
};

export default connect(
    (state: fromRoot.RootStateModel): StoreModel => ({
        user: state.authorization.user,
    }),
    () => ({})
)(withRouter(DosageTabs));
