import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import styled from 'styled-components';
import CardRadioList from './components/CardsListSelectable';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import AddCardLink from './components/AddCardLink';
import AddCard from './AddCard';
import { InvoiceModel } from '../../../models/responses/invoice.model';
import { InvoiceType } from '../../../constants/invoice/invoiceType';
import PayOneTimeButtonManually from '../components/pay-buttons/PayOneTimeButtonManually';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as InvoiceActions } from '../../../store/actions/invoice.actions';
import { CardModel } from '../../../models/responses/card.model';
import * as fromRoot from '../../../store/reducers';
import RequestState from '../../../constants/requestState';
import LoadingTransparent from '../../shared/LoadingTransparent';
import { useStripe } from '@stripe/react-stripe-js';

const createStyles = makeStyles({
    dialogPaper: {
        width: '75%',
        maxWidth: '770px',
        padding: '0 !important',
        display: 'flex',
        alignItems: 'center',
    },
    addCardDialogPaper: {
        width: '40%',
        maxWidth: '580px',
        padding: '50px 50px 25px',
        display: 'flex',
        alignItems: 'center',
    },
    header: {
        fontSize: '24px',
        margin: '50px 0 40px',
    },
    info: {
        fontSize: '12px',
        color: colors.textSecondary,
        marginTop: '18px',
    },
    dividerText: {
        fontSize: '16px',
    },
});

interface InjectedProps {
    onClose: () => void;
    open: boolean;
    invoice: InvoiceModel;
    redirectToSuccess: () => void;
    redirectToFail: () => void;
}

interface DispatchModel {
    payInvoice: typeof InvoiceActions.payInvoice;
    subscribeInvoice: typeof InvoiceActions.subscribeInvoice;
    resetStatusVariables: typeof InvoiceActions.resetStatusVariables;
}

interface StoreModel {
    cardsList: CardModel[];
    payInvoiceRequest: RequestState;
    subscribeInvoiceRequest: RequestState;
    sessionId: string | null;
}

const DividerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 32px 0 28px;
`;

const DividerLine = styled.div`
    width: 35%;
    height: 16px;
    border-bottom: 1px solid ${colors.whiteSmoke};
`;

const CardListWrapper = styled.div`
    width: 100%;
    margin-bottom: 24px;
    height: 210px;
    overflow: scroll;
`;

const BottomWrapper = styled.div`
    width: -webkit-fill-available;
    padding: 0 32px 50px;
`;

type PropsTypes = InjectedProps & DispatchModel & StoreModel;

export const PayDialog: React.FC<PropsTypes> = props => {
    const {
        open,
        onClose,
        invoice,
        payInvoice,
        cardsList,
        payInvoiceRequest,
        sessionId,
        redirectToSuccess,
        redirectToFail,
        subscribeInvoice,
        subscribeInvoiceRequest,
        resetStatusVariables,
    } = props;
    const classes = createStyles(props);

    const [addCardForm, setAddCardForm] = useState(false);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('');
    const [justAddedPaymentMethodId, setJustAddedPaymentMethodId] = useState<string | null>(null);
    const [payManually, setPayManually] = useState(false);

    const [loading, setLoading] = useState(false);

    const stripe = useStripe();

    const defaultCard = useMemo(() => {
        const defaultCard = cardsList.filter(card => card.isDefault);
        return !!defaultCard.length ? defaultCard[0] : null;
    }, [cardsList]);

    useEffect(() => {
        setSelectedPaymentMethodId(
            defaultCard ? defaultCard.paymentMethodId : cardsList.length ? cardsList[0].paymentMethodId : ''
        );
    }, [setSelectedPaymentMethodId, cardsList, defaultCard]);

    const toggleShowCard = useCallback(() => {
        setAddCardForm(!addCardForm);
    }, [addCardForm]);

    const handleChange = (id: string) => {
        setSelectedPaymentMethodId(id);
    };

    const payForInvoice = useCallback(
        (paymentMethodId?: string) => {
            setPayManually(false);
            const chosenPaymentMethodId = !!paymentMethodId ? paymentMethodId : selectedPaymentMethodId;
            if (invoice.type === InvoiceType.ONE_TIME) {
                payInvoice({ invoiceId: invoice.id, paymentMethod: { id: chosenPaymentMethodId } });
            } else {
                subscribeInvoice({ invoiceId: invoice.id, paymentMethod: { id: chosenPaymentMethodId } });
            }
        },
        [payInvoice, invoice, subscribeInvoice, selectedPaymentMethodId]
    );

    const payForInvoiceFromSelectedCard = useCallback(() => {
        payForInvoice();
    }, [payForInvoice]);

    const allowManualPayment = useCallback(() => {
        setPayManually(true);
    }, []);

    const confirmCard = useCallback(async () => {
        if (!stripe || payManually) {
            return;
        }

        try {
            setLoading(true);
            const result = await stripe.confirmCardPayment(sessionId!, {
                payment_method: selectedPaymentMethodId,
            });
            console.log('result', result);
            redirectToSuccess();
            setLoading(false);
        } catch (error) {
            console.log('error', error);
            redirectToFail();
            setLoading(false);
        }
    }, [sessionId, payManually, selectedPaymentMethodId, stripe, redirectToSuccess, redirectToFail]);

    useEffect(() => {
        if (payManually) {
            return;
        }
        if (payInvoiceRequest === RequestState.SENT_SUCCESS || subscribeInvoiceRequest === RequestState.SENT_SUCCESS) {
            if (sessionId) {
                confirmCard();
            } else {
                redirectToSuccess();
            }
        }
        if (payInvoiceRequest === RequestState.SENT_FAILED || subscribeInvoiceRequest === RequestState.SENT_FAILED) {
            redirectToFail();
        }
    }, [
        payManually,
        sessionId,
        redirectToSuccess,
        redirectToFail,
        payInvoiceRequest,
        subscribeInvoiceRequest,
        confirmCard,
    ]);

    const isLoading = useMemo(() => {
        return payInvoiceRequest === RequestState.SENDING || subscribeInvoiceRequest === RequestState.SENDING;
    }, [payInvoiceRequest, subscribeInvoiceRequest]);

    useEffect(() => {
        return () => {
            resetStatusVariables();
            setJustAddedPaymentMethodId(null);
        };
    }, [resetStatusVariables]);

    const onAddCardAndImmediatePayment = useCallback((paymentMethodId: string) => {
        setJustAddedPaymentMethodId(paymentMethodId);
    }, []);

    useEffect(() => {
        if (justAddedPaymentMethodId) {
            payForInvoice(justAddedPaymentMethodId);
        }
    }, [justAddedPaymentMethodId, payForInvoice]);

    return (
        <>
            <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
                {isLoading && <LoadingTransparent />}

                <Typography variant={'h2'} classes={{ root: classes.header }}>
                    Select Card to Pay
                </Typography>

                {invoice.type === InvoiceType.ONE_TIME && (
                    <>
                        <PayOneTimeButtonManually
                            invoiceId={invoice.id}
                            payManually={payManually}
                            allowManualPayment={allowManualPayment}
                        />

                        <Typography variant={'body1'} classes={{ root: classes.info }}>
                            Card Won’t Be Saved
                        </Typography>

                        <DividerWrapper>
                            <DividerLine />
                            <Typography variant={'h2'} classes={{ root: classes.dividerText }}>
                                or
                            </Typography>
                            <DividerLine />
                        </DividerWrapper>
                    </>
                )}
                <CardListWrapper>
                    <CardRadioList selectedId={selectedPaymentMethodId} setSelectedId={handleChange} />
                </CardListWrapper>

                <BottomWrapper>
                    <AddCardLink onClick={toggleShowCard} />
                    <PrimaryButton
                        style={{ minWidth: '110px', marginTop: '12px' }}
                        onClick={payForInvoiceFromSelectedCard}
                        disabled={loading}
                    >
                        Pay
                    </PrimaryButton>
                </BottomWrapper>
            </Dialog>

            {addCardForm && (
                <Dialog open={addCardForm} onClose={toggleShowCard} classes={{ paper: classes.addCardDialogPaper }}>
                    <AddCard onClose={toggleShowCard} onAddCardAndImmediatePayment={onAddCardAndImmediatePayment} />
                </Dialog>
            )}
        </>
    );
};

export default connect(
    (store: fromRoot.RootStateModel): StoreModel => ({
        cardsList: store.cardManagement.cardsList,
        payInvoiceRequest: store.invoice.payInvoiceRequest,
        subscribeInvoiceRequest: store.invoice.subscribeInvoiceRequest,
        sessionId: store.invoice.sessionId,
    }),
    (dispatch: Dispatch): DispatchModel => ({
        payInvoice: bindActionCreators(InvoiceActions.payInvoice, dispatch),
        subscribeInvoice: bindActionCreators(InvoiceActions.subscribeInvoice, dispatch),
        resetStatusVariables: bindActionCreators(InvoiceActions.resetStatusVariables, dispatch),
    })
)(PayDialog);
