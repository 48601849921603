import * as sidebarActions from "../../actions/sidebar.actions";

export class StateModel {
    open: boolean
}

export const initialState = new StateModel();


export function reducer(state = initialState, action: sidebarActions.Actions) {
    switch (action.type) {
        case sidebarActions.ActionNames.TOGGLE_SIDEBAR: {
            return {
                ...state,
                open: !state.open,
            };
        }
        default:
            return state;
    }

}