import * as tourActions from '../../actions/tour.actions';

export class StateModel {
    isTourVisible: boolean = false;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: tourActions.Actions) {
    switch (action.type) {
        case tourActions.ActionNames.SET_TOUR_VISIBLE: {
            return {
                ...state,
                isTourVisible: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
