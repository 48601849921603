import React from 'react';
import { Button, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../theme/colors';
import forwardIcon from '../../../static/icons/forwardIcon.svg';
import styled from 'styled-components';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import CustomLink from '../../../shared/link/CustomLink';
import { PatientModel } from '../../../models/responses/patient.response';
import { AcceptProviderStatus } from '../../../constants/acceptProviderStatus';
import providerLinkedIcon from '../../../static/icons/provider/providerLinkedIcon.svg';
import providerPendingIcon from '../../../static/icons/provider/providerPendingIcon.svg';
import AvatarShared from '../../shared/AvatarShared';

const useStyles = makeStyles({
    listItem: {
        padding: 0,
    },
    nameText: {
        fontSize: '14px',
        color: colors.textPrimary,
    },
    typeText: {
        fontSize: '12px',
        color: colors.textSecondary,
        textTransform: 'capitalize',
    },
    avatarWrapper: {
        minWidth: 'auto',
        marginRight: '10px',
        position: 'relative',
    },
    forwardIcon: {
        padding: '0 !important',
        minWidth: '25px',
        height: '25px',
    },
});

const TextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: -6px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ApprovedIconWrapper = styled.div`
    width: 14px;
    height: 14px;
    background: ${colors.redMain};
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
`;

const PendingIconWrapper = styled.div`
    width: 14px;
    height: 14px;
    background: ${colors.textSecondary};
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
`;

interface InjectedProps {
    patient: PatientModel;
    showStatusIcons?: boolean;
}

type PropsTypes = InjectedProps;

const PatientListItem: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);

    const getRedirectLink = () => {
        switch (props.patient.linkingStatus) {
            case AcceptProviderStatus.APPROVED: {
                return `/profile-patient/${props.patient.id}`;
            }
            case AcceptProviderStatus.PENDING: {
                return `/patient-request/${props.patient.id}`;
            }
            default:
                return `/patient-invited/${props.patient.id}`;
        }
    };

    return (
        <CustomLink to={getRedirectLink()}>
            <ListItem classes={{ root: classes.listItem }} button>
                <SizeControllerWrapper>
                    <Wrapper>
                        <ListItemAvatar classes={{ root: classes.avatarWrapper }}>
                            <>
                                <AvatarShared img={props.patient.avatarThumbnailUrl} />
                                {props.patient.linkingStatus === AcceptProviderStatus.APPROVED &&
                                    props.showStatusIcons && (
                                        <ApprovedIconWrapper>
                                            <img src={providerLinkedIcon} alt="" />
                                        </ApprovedIconWrapper>
                                    )}
                                {props.patient.linkingStatus === AcceptProviderStatus.PENDING && props.showStatusIcons && (
                                    <PendingIconWrapper>
                                        <img src={providerPendingIcon} alt="" />
                                    </PendingIconWrapper>
                                )}
                            </>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <TextWrapper>
                                    <Typography variant="button" classes={{ root: classes.nameText }}>
                                        {props.patient.firstName} {props.patient.lastName}{' '}
                                    </Typography>
                                </TextWrapper>
                            }
                            secondary={
                                <Typography variant="body1" classes={{ root: classes.typeText }}>
                                    some patient info
                                </Typography>
                            }
                        />

                        <Button classes={{ root: classes.forwardIcon }}>
                            <img src={forwardIcon} alt="" />
                        </Button>
                    </Wrapper>
                </SizeControllerWrapper>
            </ListItem>
        </CustomLink>
    );
};

export default PatientListItem;
