import * as bloodTargetActions from '../../actions/bloodTarget.actions';
import {BloodtargetReponce} from "../../../models/responses/bloodtarget.reponce";
import RequestState from "../../../constants/requestState";

export class StateModel {
    allBloodTargets: BloodtargetReponce[] = [];
    page: number = 0;
    totalBloodTargets: number | null = null;
    loading: boolean = false;
    searchQuery: string = '';
    currentTarget: BloodtargetReponce | null = null;
    currentTargetLoading: boolean;
    addBloodTargetsRequest: RequestState;
    editBloodTargetsRequest: RequestState;
    removeBloodTargetsRequest: RequestState;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: bloodTargetActions.Actions) {
    switch (action.type) {

        case bloodTargetActions.ActionNames.GET_ALL_BLOOD_TARGETS: {
            return {
                ...state,
                loading: true,
            }
        }

        case bloodTargetActions.ActionNames.GET_ALL_BLOOD_TARGETS_UPDATE_SUCCESS: {

            const isEmptyString = state.searchQuery.trim().length === 0;
            const newList = state.allBloodTargets.concat(action.payload.result);
            const isRedundant = newList.length > action.payload.total;

            return {
                ...state,
                loading: false,
                totalBloodTargets: action.payload.total,
                allBloodTargets: isEmptyString && !isRedundant ? newList : action.payload.result,
            }
        }

        case bloodTargetActions.ActionNames.GET_ALL_BLOOD_TARGETS_OVERRIDE_SUCCESS: {
            return {
                ...state,
                loading: false,
                totalBloodTargets: action.payload.total,
                allBloodTargets: action.payload.result,
            }
        }

        case bloodTargetActions.ActionNames.GET_ALL_BLOOD_TARGETS_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }

        case bloodTargetActions.ActionNames.INCREASE_PAGE_NUMBER: {
            return {
                ...state,
                page: state.page + 1,
            }
        }

        case bloodTargetActions.ActionNames.SET_SEARCH_QUERY: {
            const isEmptyString = action.payload.trim().length === 0;
            return {
                ...state,
                searchQuery: action.payload,
                loading: isEmptyString ? false : state.loading,
                page: 0,
                allBloodTargets: isEmptyString ? [] : state.allBloodTargets,
                totalBloodTargets: 0,
            }
        }


        case bloodTargetActions.ActionNames.ADD_BLOOD_TARGETS: {
            return {
                ...state,
                addBloodTargetsRequest: RequestState.SENDING,
            }
        }

        case bloodTargetActions.ActionNames.ADD_BLOOD_TARGETS_SUCCESS: {
            return {
                ...state,
                addBloodTargetsRequest: RequestState.SENT_SUCCESS,
            }
        }

        case bloodTargetActions.ActionNames.ADD_BLOOD_TARGETS_FAILED: {
            return {
                ...state,
                addBloodTargetsRequest: RequestState.SENT_FAILED,
            }
        }

        case bloodTargetActions.ActionNames.RESET_STATUS_VARIABLES: {
            return {
                ...state,
                addBloodTargetsRequest: RequestState.UNSENT,
                editBloodTargetsRequest: RequestState.UNSENT,
                removeBloodTargetsRequest: RequestState.UNSENT,
            }
        }

        case bloodTargetActions.ActionNames.GET_BLOOD_TARGET_BY_ID: {
            return {
                ...state,
                currentTargetLoading: true,
            }
        }

        case bloodTargetActions.ActionNames.GET_BLOOD_TARGET_BY_ID_SUCCESS: {
            return {
                ...state,
                currentTargetLoading: false,
                currentTarget: action.payload,
            }
        }

        case bloodTargetActions.ActionNames.GET_BLOOD_TARGET_BY_ID_FAILED: {
            return {
                ...state,
                currentTargetLoading: false,
            }
        }

        case bloodTargetActions.ActionNames.EDIT_BLOOD_TARGET: {
            return {
                ...state,
                editBloodTargetsRequest: RequestState.SENDING,
            }
        }

        case bloodTargetActions.ActionNames.EDIT_BLOOD_TARGET_SUCCESS: {
            return {
                ...state,
                editBloodTargetsRequest: RequestState.SENT_SUCCESS,
            }
        }

        case bloodTargetActions.ActionNames.EDIT_BLOOD_TARGET_FAILED: {
            return {
                ...state,
                editBloodTargetsRequest: RequestState.SENT_FAILED,
            }
        }

        case bloodTargetActions.ActionNames.REMOVE_BLOOD_TARGET: {
            return {
                ...state,
                removeBloodTargetsRequest: RequestState.SENDING,
            }
        }

        case bloodTargetActions.ActionNames.REMOVE_BLOOD_TARGET_SUCCESS: {
            return {
                ...state,
                removeBloodTargetsRequest: RequestState.SENT_SUCCESS,
            }
        }

        case bloodTargetActions.ActionNames.REMOVE_BLOOD_TARGET_FAILED: {
            return {
                ...state,
                removeBloodTargetsRequest: RequestState.SENT_FAILED,
            }
        }

        default:
            return state;
    }
}