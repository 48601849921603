import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import SizeControllerWrapper from '../../../shared/SizeControllerWrapper';
import { makeStyles, Typography } from '@material-ui/core';
import {mediaQuery} from "../../../constants/device";
import AvatarProfileBigShared from "../../shared/AvatarProfileBigShared";

const useStyles = makeStyles({
    defaultText: {
        fontSize: '24px',
        marginTop: '20px',
    },
    name: {
        fontSize: '24px',
        marginTop: '50px',
        textAlign: 'center',
    },
});

const BackgroundWrapper = styled.div<any>`
    height: ${props => (props.editMode ? '330px' : '450px')};
    background-color: ${props => (props.background ? props.background : props.editMode ? colors.gray1 : colors.white)};
    position: relative;
   ${mediaQuery.laptop} {
    width: calc(100% + 5vw);
    }
    ${mediaQuery.laptopL} {
     width: calc(100% + 11.5vw);
    }
    ${mediaQuery.desktop} {
     width: calc(100% + 5vw);
    }
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 44px;
`;

const CenterWrapper = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface InjectedProps {
    avatar: string | null;
    isDefaultAvatar: boolean;
    editMode: boolean;
    text: string;
    name: string;
    background?: string | null;
}

type PropsTypes = InjectedProps;

const ProfileHeader: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);

    return (
        <>
            <BackgroundWrapper editMode={props.editMode} background={props.background}>
                <SizeControllerWrapper>
                    <Wrapper>
                        <CenterWrapper>
                            <AvatarProfileBigShared editMode={props.editMode} isDefaultAvatar={props.isDefaultAvatar} avatar={props.avatar}/>
                            <Typography
                                variant="h2"
                                classes={{ root: props.editMode ? classes.defaultText : classes.name }}
                            >
                                {props.editMode ? props.text : props.name}
                            </Typography>
                        </CenterWrapper>
                    </Wrapper>
                </SizeControllerWrapper>
            </BackgroundWrapper>
        </>
    );
};

export default ProfileHeader;
