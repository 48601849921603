import { ActionsOfType, ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { AllEntitiesByPagesDto } from '../../models/dto/allEntitiesByPages.dto';
import { BaseDropdownItem } from '../../models/responses/baseModel.responce';

export enum ActionNames {
    GET_ALL_DRUGS = '[Drugs] Get all Drugs',
    GET_ALL_DRUGS_UPDATE_SUCCESS = '[Drugs] Get all Drugs Update Success',
    GET_ALL_DRUGS_OVERRIDE_SUCCESS = '[Drugs] Get all Drugs Override Success',
    GET_ALL_DRUGS_FAILED = '[Drugs] Get all Drugs Failed',
    SET_SEARCH_QUERY = '[Drugs] Set Search Query Drugs',

    INCREASE_PAGE_NUMBER = '[Drugs] Increase Page Number',
}

export const Actions = {
    getAllDrugs: (data: AllEntitiesByPagesDto) => createAction(ActionNames.GET_ALL_DRUGS, data),
    getAllDrugsUpdateSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_DRUGS_UPDATE_SUCCESS, data),
    getAllDrugsOverrideSuccess: (data: { result: BaseDropdownItem[]; total: number }) =>
        createAction(ActionNames.GET_ALL_DRUGS_OVERRIDE_SUCCESS, data),
    getAllDrugsFailed: () => createAction(ActionNames.GET_ALL_DRUGS_FAILED),
    setSearchQuery: (query: string) => createAction(ActionNames.SET_SEARCH_QUERY, query),

    increasePageNumber: () => createAction(ActionNames.INCREASE_PAGE_NUMBER),
};

export type Actions = ActionsUnion<typeof Actions>;

export type getAllDrugsType = ActionsOfType<Actions, ActionNames.GET_ALL_DRUGS>;
