import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../theme/colors';
import theme from '../../theme/theme';

type StyleType = {
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
};

const useStyles = makeStyles({
    root: (props: StyleType) => ({
        backgroundColor: props.disabled ? colors.gray1 : colors.redMain,
        transition: theme.transitionBackground,
        border: props.disabled ? `1px solid ${colors.gray1}` : `1px solid ${colors.redMain}`,
        color: colors.white,
        cursor: props.disabled ? 'default' : 'pointer',
        '&:active': {
            backgroundColor: props.disabled ? colors.gray1 : colors.redDark,
            border: props.disabled ? `1px solid ${colors.gray1}` : `1px solid ${colors.redDark}`,
        },
        '@media (max-width:1024px)': {
            '&:hover': {
                backgroundColor: props.disabled ? colors.gray1 : colors.redMain,
                border: props.disabled ? `1px solid ${colors.gray1}` : `1px solid ${colors.redMain}`,
            },
        },
        '@media (min-width:1024px)': {
            '&:hover': {
                backgroundColor: props.disabled ? colors.gray1 : colors.redLight,
                border: props.disabled ? `1px solid ${colors.gray1}` : `1px solid ${colors.redLight}`,
                color: colors.white,
            },
        },
    }),
    whiteRoot: {
        backgroundColor: colors.white,
        transition: theme.transitionBackground,
        border: `1px solid ${colors.white}`,
        color: colors.redMain,
        '&:active': {
            backgroundColor: colors.redDark,
            border: `1px solid ${colors.redDark}`,
            color: colors.white,
        },
        '@media (max-width:1024px)': {
            '&:hover': {
                backgroundColor: colors.white,
                border: `1px solid ${colors.white}`,
                color: colors.redMain,
            },
        },
        '@media (min-width:1024px)': {
            '&:hover': {
                backgroundColor: colors.redMain,
                border: `1px solid ${colors.white}`,
                color: colors.white,
            },
        },
    },
    buttonText: (props: StyleType) => ({
        fontSize: props.size === 'small' ? '12px' : '14px',
    }),
});

interface InjectedProps {
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    onClick?: any;
    white?: boolean;
    style?: object;
    type?: 'submit' | 'reset' | 'button';
}

type PropsTypes = InjectedProps;

const PrimaryButton: React.FC<PropsTypes> = props => {
    const classes = useStyles(props);
    return (
        <Button
            className={props.white ? classes.whiteRoot : classes.root}
            disableTouchRipple={props.disabled}
            size={props.size}
            onClick={props.onClick}
            style={props.style}
            type={props.type ? props.type : 'button'}
        >
            <Typography variant="button" classes={{ root: classes.buttonText }}>
                {props.children}
            </Typography>
        </Button>
    );
};

export default PrimaryButton;
